import { Component, OnInit, ViewChild, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { ApiService } from '../../services/api.service';
import { HttpClient } from '@angular/common/http';
import { SharedataService } from "../../services/sharedata.service";
import { Subscription } from 'rxjs';
import { _ } from 'underscore';
import { FormControl } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from "../../services/common.service";
import { Router } from "@angular/router"
import { OwlCarousel } from 'ngx-owl-carousel';
import { Configuration } from '../../app.constant';
declare var L: any;
declare var $: any;
var locations = [];
@Component({
  selector: 'app-user-outlier',
  templateUrl: './user-outlier.component.html',
  styleUrls: ['./user-outlier.component.css']
})
export class UserOutlierComponent implements OnInit {
  @ViewChildren('iuslist') iuslist: QueryList<any>;
  @ViewChild('owlElement') owlElement: OwlCarousel
  iusList = new FormControl();
  IUS;
  selectedValue: '% of Drop Out'
  mySelections: string[];
  owl: any;
  geoJson;
  marker;
  map = null;
  customIcon;
  subscription: Subscription;
  validationError: any;
  areaID = null;
  iusID = [];
  mapLevel = 1;
  mapLink;
  areaList;
  availableArea;
  interval = null;
  sliderIndex;
  mapData;
  infoData;
  infoindicator;
  indicators = [];
  areaLength;
  itemLength = 5;
  iusRecords = [];
  data = [];
  selectedIus = [];
  IUSList = [];
  filteredTimeperiod = [];
  filteredIUS = [];
  iusData = [];
  outlierTimeperiod = [];
  outlierName = [];
  selectedTimeperiod = [];
  selectedName = [];
  childData = [];
  childDataAll = [];
  filteredChildIus = [];
  filteredChildTimeperiod = [];
  parentArea = [];
  mainTableHeader: any = '';
  childTableHeader: any = '';
  subSector;
  outlierPercent: any;
  private url: any;
  sideBarClicked = 0;
  changeTimePeriodList = 1;
  mapOptions = {
    zoom: 3.7,
    zoomSnap: 0.08,
    center: L.latLng(21.7679, 78.8718),
    zoomControl: false,
    scrollWheelZoom: false
  };
  areaLevel:any;
  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private http: HttpClient,
    private form: FormBuilder,
    private shareData: SharedataService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private router: Router,
    public configuration: Configuration,
    private cdr: ChangeDetectorRef
  ) {
    this.url = configuration.server;
    this.subscription = this.shareData.getData().subscribe(message => {
      //receive notification when first time sidebar is loaded
      if (message.data.ctype == 'sidebar' && message.data.data.length <= 0) {
      }
      let that = this;

      switch (message.data.ctype) {

        case 'sidebar':
          
          $(".loaders ol li").fadeIn();
          
          this.sideBarClicked = 1;
          this.subSector = message.data.data.level2;

          $("#my_breadcrumb1").html(message.data.data.parentData.level1);
          $("#my_breadcrumb2").html(message.data.data.level2);

          this.selectedIus = [];
          this.iusID = [];
          this.outlierTimeperiod = [];
         

          this.iusID = this.commonService.extractIus(message.data.data);
    
          

          if (this.iusID.length == 0) {
            this.selectedIus = [];    
          }else{          
            this.iusID.forEach(element => {
              element.selected = '';
            });  
          
            const defaultIus: any[] = this.iusID.slice(0, 1);
            defaultIus.forEach(element => {
              element.selected = 'selected';
              if (this.selectedIus.indexOf(element.id) == -1) {
                this.selectedIus.push(element.id);
              }
            });

          }
        
          // this.selectedIus =this.iusID[0]
          this.areaID = this.commonService.getAreaID();
          this.mapLevel = Number(this.commonService.getAreaLevel());

          setTimeout(function () {
            $('.outlier_name').multiselect('rebuild');
            that.getOutlier();
            that.getOutlierTimeperiods();
          }, 500);
          break;

        case 'header':

        this.areaLevel = localStorage.getItem("areaLevel")     
          if (this.iusID.length == 0) {
           
            this.selectedIus = [];           
           
          }else{
            this.areaID = message.data.data.code;
            this.mapLevel = message.data.data.level;
            this.sideBarClicked = 0;
           
            
            setTimeout(function () {
              $('.outlier_name').multiselect('rebuild');
              that.getOutlier();
              that.getOutlierTimeperiods();
            }, 500);
          }
          
          break;
        default:
          break;
      }
    });
  }
  ngOnInit() {
    $(".loaders ol li").fadeIn();
    this.headerService.setTitle('Outlier Monitoring');
    this.headerService.setLogo('assets/lib/images/svg/header-menu/outlier-monitoring.svg');
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.areaLevel = userAreaDetail.area_level
    this.getAllIndicators();
  }
  getAllIndicators() {
    this.dataService.getAll('api/gallery/getsetindicator').subscribe((response: any) => {
      this.data = response.data;
    })
  }
  ngAfterViewChecked(){
    this.cdr.detectChanges();
  }
  ngAfterViewInit() {
  
    this.iuslist.changes.subscribe(t => {
      this.ngForRendred();
    })
    let areaDetails = JSON.parse(localStorage.getItem('area_details'));
    let authDetails = JSON.parse(localStorage.getItem('user_details'));
    this.mainTableHeader = areaDetails.area_name;
    if (authDetails.role > 2) {
      if (areaDetails !== null) {
        this.mapLevel = areaDetails.area_level;
        this.areaID = areaDetails.area_code;
      }
    }
    let obj = this;
    $(document).ready(function () {
      var owl = $(".owl-theme").owlCarousel();
      owl.on('changed.owl.carousel', function (e) {
        var current = e.item.index;
        var index = $(e.target).find(".owl-item").eq(current).find('.item').data('index');
        
        if(index == undefined) {
          index = 0;
        }
        obj.sliderLastIndex(index);
      });
    });
    $(document).on('click', '.map_area_name', function () {
      $('#loaders_data_table2 ol li').fadeIn();
      let tempIus = null;
      let tempTimeperiod = null;
      obj.parentArea = $(this).data('area');
      obj.childTableHeader = $(this).data('area_name');
      if (obj.selectedIus.length > 0) {
        // tempIus = obj.selectedIus.join(',');
        tempIus = obj.selectedIus;
      }

      obj.childData = [];

      let tempFilteredChildIus = [];
      tempTimeperiod = obj.selectedTimeperiod;
      obj.dataService.getAll('api/outlier/area-child-data/' + tempIus + '/' + $(this).data('area') + '/' + tempTimeperiod + '/' + 2).subscribe((response: any) => {
        obj.childDataAll = response.data.records;
        obj.outlierPercent = response.data.outlierPercent;


        /*response.data.records.forEach(element => {
          obj.childData.push({
            'area_name': element.area_name,
            'outlierValue': element.outlier_value,
            'outlierCondition': element.outlier_condition,
            'value': element.value,
            'outlierMin': element.outlier_min,
            'outlierMax': element.outlier_max
          })
        });*/
        // $('#loaders_data_table2 ol li').fadeOut();


        response.data.records.forEach(element => {
          let IUS = element.indicator + ' | ' + element.unit + ' | ' + element.subgroup;
          if (tempFilteredChildIus.indexOf(IUS) == -1) {
            obj.filteredChildIus.push({ 'id': element.ius_id, 'ius': IUS });
            tempFilteredChildIus.push(IUS);
          }
          if (obj.filteredChildTimeperiod.indexOf(element.time_period) == -1) {
            obj.filteredChildTimeperiod.push(element.time_period);
          }

          obj.childData.push({
            'area_name': element.area_name,
            'outlierValue': element.outlier_value,
            'outlierCondition': element.outlier_condition,
            'value': element.value,
            'outlierMin': element.outlier_min,
            'outlierMax': element.outlier_max
          });

        });

        $('#loaders_data_table2 ol li').fadeOut();
        obj.playPauseSlider(2);
        setTimeout(function () {
          $('#outlier-child-table_modal').modal('show');
        }, 50);
        
       /* if (obj.filteredChildIus.length > 0) {
          obj.fetchChildData(obj.filteredChildIus[0].id, 1);
        }*/
      });
    });

    $(document).on('click', '.owl-item', function () {
      let index = $(this).find('.area-link').data('index');
      $(".owl-item").css("background-color", "#ccc");
      $("[data-index='" + index +"']").parent('.owl-item').css("background-color", "#f8b0b0");
      obj.playPauseSlider(2);
      obj.addMarker(locations[index]);
    });
  }
  

  sliderLastIndex(param) {
    var index = param;
    $(".owl-item").css("background-color", "#ccc");
    $("[data-index='" + index +"']").parent('.owl-item').css("background-color", "#f8b0b0");
    this.iusRecords = _.uniq(locations[index].facility, 'ius_id');  
    // this.addMarker(locations[index]);
  }


  getLevelMap() {
    switch (this.mapLevel) {
      case 1:
        this.mapLink = 'assets/lib/map/IND_l02_2011_Census.json';
        break;
      case 2:
        // this.mapLink = 'assets/lib/map/IND_l03_2011_Census.json';
        this.mapLink = 'assets/lib/map/newJson_India-district_2023.json';
        break;
      case 3:
        this.mapLink = 'assets/lib/map/IND_l04_2020.json';//load block level map
        break;
      default:
        this.mapLink = 'assets/lib/map/IND_l02_2011_Census.json';
        break;
    }
  }
  extractGeoJson(geojson) {
    //geojson extraction not required for level 1 as it loads all area in level2 map
    if (this.mapLevel == 1) return geojson;
    let extractedJson = [];
    for (var i = 0; i < geojson.features.length; i++) {
      if (geojson.features[i].properties.code.indexOf(this.areaID) !== -1) {
        extractedJson.push(geojson.features[i]);
      }
    }
    return extractedJson;
  }
  renderGeojson() {
    this.getLevelMap();
    return this.http.get(this.mapLink).subscribe((response: any) => {
      this.geoJson = this.extractGeoJson(response);
      this.loadMap();
    });
  }

  loadMap() {
    let obj = this;
    if (this.map !== null) {
      this.map.off();
      this.map.remove();
    }
    locations = [];
    this.map = L.map('map', { crs: L.CRS.EPSG4326, attributionControl: false, zoomSnap: 0.25, zoomControl: false })
      .setView([21.7679, 78.8718], 2, false);
    var loadMap = L.geoJSON(this.geoJson, {
      style: function (feature) { // Style option
        return {
          'color': '#b6effd',
          // 'fillColor': 'yellow'
        }
      },
      onEachFeature: function popUp(f, l) {
        if (f.properties) {
          //consider only areas available in records 
         /* if (obj.mapLevel == 3) {
            locations.push({
              'latitude': l.getBounds().getCenter().lat,
              'longitude': l.getBounds().getCenter().lng,
              'properties': f.properties,
              'name': f.properties.name,
              'code': f.properties.code,
              'facility': obj.mapData[Object.keys(obj.mapData)[0]]
            });
          } else {*/
            
            if(obj.IUSList.length>0){
              if (obj.mapData[f.properties.code] !== undefined) {
                locations.push({
                  'latitude': l.getBounds().getCenter().lat,
                  'longitude': l.getBounds().getCenter().lng,
                  'properties': f.properties,
                  'name': f.properties.name,
                  'code': f.properties.code,
                  'facility': obj.mapData[f.properties.code]
                });
              }  
            }else{
              locations = [];
            }
           
          // }
        }
      }
    }).addTo(this.map);
    if (this.geoJson != '') {
      this.map.fitBounds(loadMap.getBounds());
    }
    this.flashData();
    this.addMarker(locations);
    $(".loaders ol li").fadeOut();
  }

  flashData() {
    this.customIcon = L.icon({
      iconUrl: 'assets/lib/js/leaflet/images/marker-icon.gif',
      iconSize: [35, 35], // size of the icon
      iconAnchor:   [15, 65],//changed marker icon position
      popupAnchor:  [2, -41]//changed popup position
    });
    let obj = this;
    locations.sort(() => Math.random() - 0.5);
    if (locations.length <= 0) {
      this.areaList = ['No area available'];
      this.itemLength = 1;
      return;
    }
    this.areaList = locations;
    this.areaLength = this.areaList.length;
    if (this.areaLength == 1) {
      this.areaList.push(this.areaList[0]);
    }
    if (this.areaList.length <= 5) {
      this.itemLength = this.areaList.length - 1;
    } else {
      this.itemLength = 5;
    }
  }
  addMarker(cords) {
    let that = this;
    if (this.marker) {
      this.map.removeLayer(this.marker);
      this.marker = null;
    }
    for (var i = 0; i < locations.length; i++) {
      this.iusRecords = _.uniq(locations[i].facility, 'ius_id');
      this.marker = L.marker([locations[i].latitude, locations[i].longitude], { icon: this.customIcon })
        .on('click', function (e) {
          that.router.navigate(['/facility/locator', locations[i].code]);
        })
        .addTo(this.map)
        .bindPopup('<div><span class="map_area_name" data-area="' + this.iusRecords[0].area_code + '" data-area_name="' + this.iusRecords[0].area_name + '">' + this.iusRecords[0].area_name + '</span></div>', { closeOnClick: false, autoClose: false })
        .openPopup()
      // this.marker =  L.marker([locations[i][1], locations[i][2]])
      //   .bindPopup(locations[i][0])
      //   .addTo(this.map);
    }
    if (typeof cords != 'undefined') {
      // this.iusRecords = _.uniq(cords.facility, 'ius_id');
      // this.marker = L.marker([cords.latitude, cords.longitude], { icon: this.customIcon })
      //   .on('click', function (e) {
      //     that.router.navigate(['/facility/locator', cords.code]);
      //   })
      //   .addTo(this.map)
      //   .bindPopup('<div><span class="map_area_name" data-area="' + this.iusRecords[0].area_code + '" data-area_name="' + this.iusRecords[0].area_name + '">' + this.iusRecords[0].area_name + '</span></div>', { closeOnClick: false, autoClose: false })
      //   .openPopup();
      if (this.mapLevel == 1) {
        this.map.setView([21.7679, 78.8718], 3.5, { animation: true });
        return;
      }
      this.map.setView(this.marker.getLatLng(), this.map.getZoom(), { animation: true });
      return;
    }
  }
  playSlider() {
    var owl = $(".owl-theme").owlCarousel();
    owl.trigger('play.owl.autoplay');
  }
  pauseSlider() {
    var owl = $(".owl-theme").owlCarousel();
    owl.trigger('stop.owl.autoplay');
  }

  parentdata: any;
  indiadata: any;
  getData() {
    $(".loaders ol li").fadeIn();
    let tempIus = null;
    let tempTimeperiod = null;
    let tempName = null;
    // if (this.selectedIus.length > 0) {
      tempIus = this.selectedIus
    // }
    tempName = this.selectedName;
    tempTimeperiod = this.selectedTimeperiod
    if(this.selectedIus.length==0){
      $('.mm-p').css('display', 'none')
      this.toastr.error('No data available');
      this.infoData = '';
      this.infoindicator = '';
      this.iusRecords = [];
      this.mapData= [];
      this.IUSList = [];
      $('.outlier_ius').hide();
      $('.outlier_time_period').hide();
      $('#play_pause_div').hide();
      $('#view_data_table').hide();
     
      this.renderGeojson();
    
    }else{
      $('.outlier_ius').show();
     $('.outlier_time_period').show();
     $('#play_pause_div').show();
     $('#view_data_table').show();

    }
    this.dataService
      .getAll<any[]>('api/outlier/data/' + tempIus + '/' + this.areaID + '/' +  tempName + '/' + tempTimeperiod + '/' + 2 + '/' + this.sideBarClicked)
      .subscribe(
        (res: any) => {
          this.filteredTimeperiod = [];
          this.filteredIUS = [];
          this.parentdata = [];
          this.indiadata = [];
          //area id and ius id is blank in first page load
          //set area id and ius id if null in first page load
          if (!this.areaID) { this.areaID = res.data.areaID }
          // if (!this.iusID) { this.iusID = res.data.iusID }
          if (!this.iusID) { this.iusID = res.data.iusID }
          this.availableArea = res.data.areaIds;
          this.mapData = this.groupData(res.data.records, 'area_code');
          this.IUSList = res.data.records;
          $('.mm-p').css('display', 'block')
          let tempFilteredIus = [];
          let tempDataTimeperiod = '';
          this.IUSList.forEach(element => {
            let IUS = element.indicator + ' | ' + element.unit + ' | ' + element.subgroup
            if (this.filteredIUS.find(function (el) { return el.ius == IUS }) == undefined) {
              this.filteredIUS.push({ 'id': element.ius_id, 'ius': IUS });
            }

            let pacode = "IND"
            if (element.parent_id == pacode ) {
              this.parentdata.push({ 'id': element.ius_id, 'value': element.value, 'parent_id': pacode,'area_name': element.area_name});
            }
          
            if (element.area_code == "IND" ) {
              this.indiadata.push({ 'value': element.value, 'area_name': element.area_name });
            }
           if (this.sideBarClicked == 1) {
              tempDataTimeperiod = element.time_period;
            }
            if (this.filteredTimeperiod.indexOf(element.time_period) == -1) {
              this.filteredTimeperiod.push(element.time_period);
            }
          });
          if (tempDataTimeperiod != '') {
            $('.outlier_time').val(tempDataTimeperiod);
            $('.outlier_time').multiselect('refresh');
            $('#sel2').val(tempDataTimeperiod);
            $('#child-timeperiod-drop-down').val(tempDataTimeperiod);
            this.selectedTimeperiod = [];
            this.selectedTimeperiod[0] = tempDataTimeperiod;
          } else {
            $('#sel2').val($('.outlier_time').val());
            $('#child-timeperiod-drop-down').val($('.outlier_time').val());
          }
          if (this.filteredIUS.length > 0) {
            this.fetchIusData(this.filteredIUS[0].id, 1);
          }
          if (res.data.records.length <= 0) {
            $('.mm-p').css('display', 'none')
            this.toastr.error('No data available');
            this.infoData = '';
            this.infoindicator = '';
            this.iusRecords = [];

            $('.outlier_time_period').hide();
            $('#play_pause_div').hide();
            $('#view_data_table').hide();
          }else{
            $('.outlier_time_period').show();
            $('#play_pause_div').show();
            $('#view_data_table').show();
          }
          this.renderGeojson();
        },
        error => () => {
          console.log('Error')
        },
        () => {
        }
      );
  }
  groupData(data, key) {
    return _.groupBy(data, key);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  fetchIusData(iusValue, type) {
    $('#loaders_data_table1 ol li').show();
    this.iusData = [];
    let iusMatchValue;
    let timeperiodMatchValue;
    if (type == 1) {
      iusMatchValue = iusValue;
      if ($('#sel2').val() != null) {
        timeperiodMatchValue = $('#sel2').val();
      } else {
        timeperiodMatchValue = this.filteredTimeperiod[0];
      }

      this.IUSList.forEach(element => {
        let hasData=0;

        let IUS = element.ius_id;
        if (iusMatchValue == IUS && element.time_period == timeperiodMatchValue) {
          if(hasData==0){
            element.ius_id;
          }
          this.iusData.push({
            'ius_id': element.ius_id,
            'area_name': element.area_name,
            'outlierValue': element.outlier_value,
            'outlierCondition': element.outlier_condition,
            'value': element.value,
            'outlierMin': element.outlier_min,
            'outlierMax': element.outlier_max,
            'outlier_name': element.outlier_name
          })
        }
      });

    $('#loaders_data_table1 ol li').fadeOut();


    } else if (type == 2) {


      iusMatchValue = $('#sel1').val();
      


      this.dataService
        .getAll<any[]>('api/outlier/data/' + iusMatchValue + '/' + this.areaID + '/' + iusValue+ '/' + 2+ '/' + this.sideBarClicked)
        .subscribe(
          (res: any) => {
            res.data.records.forEach(element => {
              this.iusData.push({
                'ius_id': element.ius_id,
                'area_name': element.area_name,
                'outlierValue': element.outlier_value,
                'outlierCondition': element.outlier_condition,
                'value': element.value,
                'outlierMin': element.outlier_min,
                'outlierMax': element.outlier_max,
                'time_period': element.time_period,
                'outlier_name': element.outlier_name
              })
            });
            $('#loaders_data_table1 ol li').fadeOut();
           
          },
          error => () => {
            console.log('Error');
            $('#loaders_data_table1 ol li').fadeOut();

          },
          () => {
          }
        );
    }

  }

  fetchChildData(iusValue, type) {
    $('#loaders_data_table2 ol li').fadeIn();

    this.childData = [];
    let iusMatchValue;
    let timeperiodMatchValue;
    if (type == 1) {
      iusMatchValue = iusValue;
      if ($('#child-timeperiod-drop-down').val() != null) {
        timeperiodMatchValue = $('#child-timeperiod-drop-down').val();
      } else {
        timeperiodMatchValue = this.filteredChildTimeperiod[0];
      }

      this.childDataAll.forEach(element => {
        let IUS = element.ius_id;
        if (iusMatchValue == IUS && element.time_period == timeperiodMatchValue) {
          this.childData.push({
            'area_name': element.area_name,
            'outlierValue': element.outlier_value,
            'outlierCondition': element.outlier_condition,
            'value': element.value,
            'outlierMin': element.outlier_min,
            'outlierMax': element.outlier_max
          });
        }
        $('#loaders_data_table2 ol li').fadeOut();

        setTimeout(function () {
          $('#outlier-child-table_modal').modal('show');
        }, 50);
      });


    } else if (type == 2) {
      iusMatchValue = $('#child-indicator-drop-down').val();
      // timeperiodMatchValue = iusValue;

        // iusMatchValue = $('#sel1').val();
        


        /*this.dataService
          .getAll<any[]>('api/outlier/data/' + iusMatchValue + '/' + this.areaID + '/' + iusValue+ '/' + 2+ '/' + this.sideBarClicked)
          .subscribe(
            (res: any) => {
              res.data.records.forEach(element => {
                this.childData.push({
                  'area_name': element.area_name,
                  'outlierValue': element.outlier_value,
                  'outlierCondition': element.outlier_condition,
                  'value': element.value,
                  'outlierMin': element.outlier_min,
                  'outlierMax': element.outlier_max
                })
              });
              $('#loaders_data_table1 ol li').fadeOut();
             
            },
            error => () => {
              console.log('Error');
              $('#loaders_data_table1 ol li').fadeOut();

            },
            () => {
            }
          );*/

          let obj =this;
          
          obj.dataService.getAll('api/outlier/area-child-data/' + iusMatchValue + '/' + obj.parentArea  + '/' + iusValue + '/' + 2).subscribe((res: any) => {
                  res.data.records.forEach(element => {
                    this.childData.push({
                      'area_name': element.area_name,
                      'outlierValue': element.outlier_value,
                      'outlierCondition': element.outlier_condition,
                      'value': element.value,
                      'outlierMin': element.outlier_min,
                      'outlierMax': element.outlier_max
                    })
                  });
                  $('#loaders_data_table2 ol li').fadeOut();
                });
      
    }
    
  }

  playPauseSlider(type) {
    if (type == 1) {
      $('#pauseSlider').show();
      $('#playSlider').hide();
      this.playSlider();
    } else if (type == 2) {
      $('#pauseSlider').hide();
      $('#playSlider').show();
      this.pauseSlider();
    }
  }
  downloadExcel() {
    let tempIus = null;
    let tempTimeperiod = null;
    if (this.selectedIus.length > 0) {
      // tempIus = this.selectedIus.join(',')
      tempIus = this.selectedIus
    }
    if (this.outlierTimeperiod.length > 0) {
      // tempTimeperiod = this.outlierTimeperiod.join(',');
      tempTimeperiod = this.outlierTimeperiod;
    }
    this.sideBarClicked = 0;
    this.dataService
      .getAll<any[]>('api/outlier/data/' + tempIus + '/' + this.areaID + '/' + tempTimeperiod + '/' + 1 + '/' + this.sideBarClicked)
      .subscribe(
        (res: any) => {
          if (res.data.file_download_path != '') {
            window.location.href = this.url + '/api/download-file/' + res.data.file_download_path;
          }
        });
  }
  downloadSingleIusExcel() {
    let tempIus = $('#sel1').val();
    this.sideBarClicked = 0;
    this.dataService
      .getAll<any[]>('api/outlier/data/' + tempIus + '/' + this.areaID + '/' + $('#sel2').val() + '/' + 1 + '/' + this.sideBarClicked)
      .subscribe(
        (res: any) => {
          if (res.data.file_download_path != '') {
            window.location.href = this.url + 'api/download-file/' + res.data.file_download_path;
          }
        });
  }

  ngForRendred() {
    let that = this;
    // $('.ius-list').multiselect('refresh');
    $('.ius-list').multiselect(
      {
        nonSelectedText: 'Indicator',
        allSelectedText: "Indicator",
        enableFiltering: true,
        numberDisplayed: 1,
        nSelectedText: '-Indicator',
        enableCaseInsensitiveFiltering: true,
        onChange: function (option, checked, select) {
          
          $('#sel1').val(option[0].value);
          // that.fetchChildData($event.target.value, 1)
          if ($('.ius-list').val().length <= 5) {
            that.selectedIus = $('.ius-list').val();

            that.sideBarClicked = 0;
            that.getOutlierTimeperiods();
          }
          else {
            let last_value = $('.ius-list').val().pop();
            $('.ius-list').multiselect('deselect', [last_value]);
            that.toastr.warning("You can select only 5 indicators");
          }
        }
      })
    $('.outlier_time').multiselect({
      nonSelectedText: "Time Period",
      onChange: function (option, checked, select) {
        that.selectedTimeperiod = $('.outlier_time').val();
        that.sideBarClicked = 0;
        that.getData();
      }
    });
    $('.outlier_name').multiselect({
      nonSelectedText: "Outlier Name",
      onChange: function (option, checked, select) {
        that.selectedName = $('.outlier_name').val();
        that.sideBarClicked = 0;
        that.getData();
        that.getOutlierTimeperiods();
      }
    });
    $('.owl-stage > .active').on('click', function () {
      var owl = $(".owl-theme").owlCarousel();
      owl.trigger('stop.owl.autoplay')
    });
  }
  getOutlier(){  
    let tempName = null;   

    tempName = this.selectedName;
    // +'/'+this.areaID
    this.dataService.getAll('api/outlier/get-outlier' ).subscribe((response: any) => {
     
      this.outlierName = response.data.outlierName;
    
      let that = this;
      setTimeout(function () {    
        $('.outlier_name').multiselect('rebuild');
        that.selectedName = $('.outlier_name').val();
        that.getOutlierTimeperiods();
      }, 50);
    });
  }
  getOutlierTimeperiods() {
    let tempIus = null;
    let tempName = null;
    if (this.selectedIus.length > 0) {
      // tempIus = this.selectedIus.join(',');
      tempIus = this.selectedIus;
    }

    tempName = this.selectedName;
    // +'/'+this.areaID
    this.dataService.getAll('api/outlier/get-timeperiod/' + tempIus +'/' + tempName + '/' +this.areaID).subscribe((response: any) => {
      this.outlierTimeperiod = response.data.outlierTimeperiod;
    
      let that = this;
      setTimeout(function () {
        $('.outlier_time').multiselect('rebuild');
        $('.outlier_name').multiselect('rebuild');
        that.selectedTimeperiod = $('.outlier_time').val();
        that.selectedName = $('.outlier_name').val();
        that.getData();
      }, 50);
    });
  }
  // goToArea(obj, location) {
  //   $(obj).parents('.active').css("background-color", "#f8b0b0");
  //   $(obj).parents('.active').siblings('.active').css("background-color", "#ccc");
  //   this.playPauseSlider(2);
  //   this.addMarker(location);
  // }
  
  downloadChildDataExcel() {
    let tempIus = '';
    /*if (this.filteredChildIus.length > 0) {
      this.filteredChildIus.forEach(element => {
        tempIus += element.id + ',';
      });
      tempIus = tempIus.substring(0, tempIus.length - 1);
    }*/

    if (this.selectedIus.length > 0) {
      // tempIus = this.selectedIus.join(',');
      tempIus = this.selectedIus[0];
    }


    if (tempIus == '') {
      tempIus = null;
    }
    this.dataService
      .getAll<any[]>('api/outlier/area-child-data/' + tempIus + '/' + this.parentArea + '/' + $('#child-timeperiod-drop-down').val() + '/' + 1)
      .subscribe(
        (res: any) => {
          if (res.data.file_download_path != '') {
            window.location.href = 'api/download-file/' + res.data.file_download_path;
          }
        });
  }

  downloadSingleIusChildDataExcel(){
    
  }
  
  closeChildDataModal() {
    $('#outlier-child-table_modal').modal('hide');
    this.playPauseSlider(1);
  }

  //implementation of table download from client side
  downloadTableDataExcel() {
    this.export_table_to_csv();
  }

  getDate(){
    let d = new Date();
    let datestring = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear() + " " +
    d.getHours() + ":" + d.getMinutes();
    return (datestring.replace(':','.'))
}

  export_table_to_csv() {
    var csv = [];
    var tempTableData : any;
    var container = document.querySelector("#table");
    var rows = container.querySelectorAll('table tr');
      for (var i = 0; i < rows.length; i++) {
          var row = [], cols = rows[i].querySelectorAll("td, th");
          for (var j = 0; j < cols.length; j++){
            tempTableData = cols[j]
            row.push(tempTableData.innerText);
          } 
        csv.push(row.join(","));
      }
      // Download CSV
      this.download_csv(csv.join("\n"), 'Outlier Data -'+ $('#sel1 option:selected').attr('data-ius')+' '+$('#sel2 option:selected').attr('data-timeperiod')+" "+ this.getDate()+'.csv');
  }


  download_csv(csv, filename) {
    let csvFile;
    let downloadLink;

    // CSV FILE
    csvFile = new Blob([csv], {type: "text/csv"});

    // Download link
    downloadLink = document.createElement("a");

    // File name
    downloadLink.download = filename;

    // We have to create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Make sure that the link is not displayed
    downloadLink.style.display = "none";

    // Add the link to your DOM
    document.body.appendChild(downloadLink);

    // Lanzamos
    downloadLink.click();
  }

  getFormatedTimeperiod(timePeriod){
    return this.commonService.covertTimePeriodChar(timePeriod);
  }

}



