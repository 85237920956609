import { Component, OnInit, Input } from "@angular/core";
import * as Highcharts from "highcharts";
import { VisualizationRendererService } from "../../../services/visualization-renderer";

@Component({
  selector: "app-dashboard-graph",
  templateUrl: "./dashboard-graph.component.html",
  styleUrls: ["./dashboard-graph.component.css"],
})
export class DashboardGraphComponent implements OnInit {
  @Input() item: any;
  @Input() chartId: any;
  @Input() type: any;
  @Input() color: any;

  chart: any;
  category = [];
  datas = [];
  penta: any;
  barColor = [];
  areaID = "IND";
  timePeriod: any;

  constructor(public vizService: VisualizationRendererService) {
    //this.url = configuration.server;
  }

  ngOnInit() {}

  ngOnChanges() {
    let that = this;
    if (that.item) {
      this.item;
      let mr2 = [];
      let indicator;
      that.item.forEach((row, index) => {
        let value = parseFloat(row[1]);
        indicator = row[3];
        if (index == 0) {
          mr2.push({
            name: row[0],
            y: value,
            color: "#002060",
          });
        } else if (value < 0) {
          mr2.push({
            name: row[0],
            y: value,
            color: "#595959",
          });
        } else {
          mr2.push({
            name: row[0],
            y: value,
          });
        }
      });
      that.options.title = {
        text: indicator,
        useHTML: true,
        style: {
          border: "1px solid red",
        },
      };

      that.options.chart.type = that.type;

      that.barColor = that.color;
      if (that.options.chart.type == "bar") {
        that.options.colors = ["#BC0000"];
        that.options.plotOptions.series = {
          negativeColor: "#595959",
          dataLabels: {
            format: "{point.y:.1f}", // one decimal
            style: {
              fontSize: "10px",
              fontFamily: "Verdana, sans-serif",
            },
          },
        };
      }

      that.options.series = [
        {
          data: mr2,
          showInLegend: false,
          dataSorting: {
            enabled: true,
            sortKey: "y",
          },
          dataLabels: {
            enabled: true,
         
          },
        },
      ];
      try {
        that.chart = new Highcharts.Chart(that.chartId, that.options);
      } catch (e) {}
    }
  }

  options: any = {
    chart: {
      type: "column",
    },
    colors: ["#D9D9D9"],

    xAxis: {
      title: {
        enabled: true,
        text: "",
      },
      type: "category",
      labels: {
        enabled: true,
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      stackLabels: {
        enabled: true,
      },
    },
    legend: {
      enabled: false,
    },

    plotOptions: {
      colors: ["#D9D9D9"],
      series: {
        animation: false,
        groupPadding: 0.2,
        pointPadding: 0.1,
        borderWidth: 1,
        borderColor: "black",
        colorByPoint: true,
        dataSorting: {
          enabled: true,
          sortKey: "y",
        },
        type: "column",
        dataLabels: {
          enabled: true,
          // format: "{point.y:.1f}", // one decimal
          format: "{point.y:.0f}", // zero decimal
          y: -20, // 10 pixels down from the top
          rotation: -90,
          style: {
            fontSize: "9px",
            fontFamily: "Verdana, sans-serif",
          },
          matchByName: true,
        },
      },
    },
    title: {
      text: "",
    },
    exporting: {
      enabled: false,
    },
    credits: { enabled: false },
    series: [
      {
        data: [0],
      },
    ],
  };
}
