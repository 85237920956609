import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../services/header.service';

@Component({
  selector: 'app-indicator-management',
  templateUrl: './indicator-management.component.html',
  styleUrls: ['./indicator-management.component.css']
})
export class IndicatorManagementComponent implements OnInit {
  status: boolean = false;
  displayedColumns = ['id','progress','name','color']
clickEvent(){
    this.status = !this.status;       
}
  constructor(private headerService: HeaderService) { }

  ngOnInit() {
  	this.headerService.setTitle('Manage Indicator');
  }

}
