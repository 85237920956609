import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { Component, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { HeaderService } from '../../services/header.service';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DialogMaterialComponent } from '../../dialog-material/dialog-material.component';
import { SelectionModel } from '@angular/cdk/collections';
import { TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions } from 'angular-tree-component';
import { SharedataService } from "../../services/sharedata.service";
import { _ } from 'underscore';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { NgModel } from '@angular/forms'
import { element } from '@angular/core/src/render3';
import { CustomFilterPipePipe } from '../../services/custom-filter-pipe.pipe'
import { CommonService } from "../../services/common.service";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective
} from 'ngx-perfect-scrollbar';

declare var $: any;

@Component({
  selector: 'app-outlier',
  templateUrl: './outlier.component.html',
  styleUrls: ['./outlier.component.css']
})

export class OutlierComponent implements OnInit {
  addForm: FormGroup;
  areaIds = [];
  iusIds = [];
  searchItem = '';
  timeperiodIds = [];
  selected: any = '';
  outlierRowId: any;
  outlierIusList = [];
  outlierAreaList = [];
  selectedTimePeriodList:any = 0;
  selectedIusList:any = 0 ;
  selectedAreaList:any = 0;
  threshold: any;
  outlierData: any;
  checkedTimePeriodItems = [];
  timeperiodList = [];
  outlierName = '';
  rows: any = [];
  checkTimeLength = []
  count = 0;
  selectedItems = [];
  filterData = [];

  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  constructor(private dataService: ApiService,
    private headerService: HeaderService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private shareData: SharedataService,
    private commonService : CommonService
  ) {
  }

  showThresholdValueCtrl: boolean = true;
  showThresholdMinMaxValueCtrl: boolean = false;
  timePeriodList = [];
  dashboardId;
  form_open: boolean = false;
  indicatorshow1: boolean = false;
  indicatorshow: boolean = true;
  dashboardRecords = [];
  ourFile: File;
  base64Image = '';
  showEdit: boolean = false;
  titlePage: string;
  validationError: any;
  sectionId: number;
  selection = new SelectionModel<any>(true, []);

  // displayedColumns = ['select', 'id', 'outlier_name', 'created_at'];
  // columns = [
  //   { 
  //     name: 'Facility Name',
  //     prop: 'area'
  //   },
  //   { 
  //     name: 'Facilty Centre',
  //     prop: 'indicator'
  //   },
  //   { 
  //     name: 'State',
  //     prop: 'unit' 
  //   },
  //   { 
  //     name: 'District',
  //     prop: 'subgroup'
  //   },
  //   { 
  //     name: 'Sub District',
  //     prop: 'source'
  //   },
  //   { 
  //     name: 'Latitude',
  //     prop: 'time_period' 
  //   },
  //   { 
  //     name: 'Longitude',
  //     prop: 'value'
  //   },
  //   {
  //     name: 'Created On',
  //     prop: 'id'
  //   },
  //   {
  //     name: 'Options'
  //   }
  // ];
  dataSource: MatTableDataSource<any>;
  list = [];
  iusList = [];
  areaIdsNew = [];
  areaIdsIus = [];
  outlier_name;
  authDetails;
  outlierUsers: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  ngOnInit() {

    this.getAll();
    this.getAreaList();
    this.getIusList();
    this.getTimePeriod();
    this.addForm = this.formBuilder.group({
      outlierName: ['', Validators.required],
      areaIds: [''],
      iusIds: [''],
      arithmatic_operation: ['', Validators.required,],
      threshold_value: [''],
      threshold_min: [''],
      threshold_max: [''],
      timePeriod: ['']
    }, { validator: [this.validateMinMax] });
    var element = (<HTMLInputElement[]><any>document.getElementsByName("iusIds"));
    for (let i = 0; i < element.length; i++) {
      if (element[i].checked) {
        this.iusIds.push(element[i].value);
      }
    }

    $(document).ready(function () {
      $('.time-period').multiselect({
        enableCollapsibleOptGroups: true,
        collapseOptGroupsByDefault: true,
        maxHeight: 200,
        nonSelectedText: 'Time Period selected',
        nSelectedText: 'Time Period'
      });
      $('.time-period').multiselect('rebuild');

    });
    $(document).on({
      click: function () {
        $('body').toggleClass('fcd-ie8'); //For the stupid ie8
        $(this).toggleClass('open');
        return false;
      }
    }, ".cbxTree-swicth");
    
    $(document).on({
      change: function () {
        $('[data-childid=' + $(this).data('childid') + ']').prop({ checked: $(this).is(':checked') });
        if ($(this).is(':checked')) {
          $('[data-childid=' + $(this).data('childid') + ']').parents('.cbxTree-node').removeClass('not-selected-area-level');
        } else {
          $('[data-childid=' + $(this).data('childid') + ']').parents('.cbxTree-node').addClass('not-selected-area-level');
        }
      }
    }, ".selectChild");
    $('.tree li.parent > span').click(function () {
      $(this).parent().toggleClass('active');
      $(this).parent().children('ul').slideToggle('fast');
    });
    this.route.params.subscribe(params => {
      this.dashboardId = params['id'];
    });

    this.headerService.setTitle('Outlier Management');
    this.headerService.setLogo('assets/lib/images/svg/header-menu/outlier-monitoring.svg');
    //this.headerService.setTitle('Data Summary');
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('need-head');
    // this.getAll();
    this.route.params.subscribe(params => {
      this.dashboardId = params['id'];
    });

    
  }
  getAreaList() {
    this.dataService.getAll('api/get-area-list').subscribe((response: any) => {
      this.list = response.data.area_list;
    })
  }
  getIusList() {
    this.shareData.getAll('api/get-indicator-classifications').subscribe((response: any) => {
      this.iusList = response.data
    })
  }
  /**
     * method that execute on changing the value of matInput
     * @param filterValue 
     */
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  /**
   * method that executes  to intiliaze the table with data
   */
  getAll() {
  	/**
     * api call to get all the records from dataService
     */
    $(".loaders ol li").fadeIn();
    this.dataService
      .getAll<any[]>('api/outlier/list')
      .subscribe(
        (res: any) => {
          $(".loaders ol li").fadeOut();
          let dataOutlier = [];
          this.rows = res.data.records;
          this.filterData = [...res.data.records];

          res.data.records.forEach(element => {

            element.count = this.count + 1;

            let tempObj = {};
            tempObj['id'] = element.id;
            tempObj['outlier_name'] = element.outlier_name;
            tempObj['outlier_created_date'] = element.created_at;
            tempObj['outlier_area'] = [];
            tempObj['outlier_ius'] = [];
            tempObj['outlier_timeperiod'] = [];
            tempObj['outlier_combinations'] = element.outlier_combinations;
            element.outlier_combinations.forEach(element1 => {
              let tempObjArea = {};
              let tempObjIus = {};
              let tempObjTimeperiod = {};
              tempObjArea['area_id'] = element1.area_id;
              tempObjArea['area'] = element1.area;
              if (tempObj['outlier_area'].find(function (el) { return el.area_id === element1.area_id }) == undefined) {
                tempObj['outlier_area'].push(tempObjArea);
              }
              tempObjIus['ius_id'] = element1.ius_id;
              tempObjIus['ius'] = element1.ius;
              if (tempObj['outlier_ius'].find(function (el) { return el.ius_id === element1.ius_id }) == undefined) {
                tempObj['outlier_ius'].push(tempObjIus);
              }
              tempObjTimeperiod['timeperiod'] = element1.time_period;
              if (tempObj['outlier_timeperiod'].find(function (el) { return el.time_period === element1.time_period }) == undefined) {
                tempObj['outlier_timeperiod'].push(tempObjTimeperiod);
              }
              tempObj['threshold_value'] = element1.threshold_value;
              tempObj['threshold_min'] = element1.threshold_min;
              tempObj['threshold_max'] = element1.threshold_max;
              tempObj['arithmatic_operation'] = element1.arithmatic_operation;
            });
            dataOutlier.push(tempObj);
          });
          // this.dataSource = new MatTableDataSource(dataOutlier);
          // this.dataSource.sort = this.sort;
          // this.dataSource.paginator = this.paginator;
          // this.dashboardRecords = res.data.records;
        },
        error => () => {
          console.log('Error')
        },
        () => {
        }
      );
  }

  /**
   *  method that executes on submitting form
   */
  add() {
    //api call to add a dashboard
    this.areaIds = [];
    this.iusIds = [];
    this.timeperiodIds = [];
    let timeperiod = this.addForm.get('timePeriod').value;
    var element = (<HTMLInputElement[]><any>document.getElementsByName("iusIds"));
    for (let i = 0; i < element.length; i++) {
      if (element[i].checked) {
        if (this.iusIds.indexOf(element[i].value) == -1) {
          this.iusIds.push(element[i].value);
        }
      }
    }

    var element = (<HTMLInputElement[]><any>document.getElementsByName("areaIds"));
    for (let i = 0; i < element.length; i++) {
      if (element[i].checked) {
        this.areaIds.push(element[i].value);
      }
    }

    var element = (<HTMLInputElement[]><any>document.getElementsByName("timeperiodIds"));
    for (let i = 0; i < element.length; i++) {
      if (element[i].checked) {
        this.timeperiodIds.push(element[i].value);
      }
    }

    let formData = {
      'outlierName': this.addForm.value.outlierName,
      'areaIds': this.areaIds.join(','),
      'iusIds': this.iusIds.join(','),
      'timeperiod': this.timeperiodIds.join(','),
      'threshold_value': this.addForm.value.threshold_value,
      'arithmatic_operation': this.addForm.value.arithmatic_operation,
      'threshold_min': this.addForm.value.threshold_min,
      'threshold_max': this.addForm.value.threshold_max
    };
    this.validationError = [];
    this.dataService
      .add<any[]>('api/outlier/add', formData)
      .subscribe(
        (res: any) => {

          if (res.status == 0) {
            this.validationError = res.error;
          } else {
            // toaster message on successfully submitting data to the table
            this.toastr.success('Outlier added successfully');

            // reset the form after submitting form data
            this.addForm.reset();
            this.clearFormGroupDirective();


            // close the form modal
            this.form_open = !this.form_open;
            // get all the data  in the table
            this.getAll();

          }
        },
        error => () => {
          console.log('Error')
        },
        () => {
        }
      );
  }
  deleteUserData(id) {
    const dialog = this.dialog.open(DialogMaterialComponent, {
      width: '350px',
      data: { messageDialog: 'Are you sure to delete outlier?', delete: true }
    });
    dialog.afterClosed()
      .subscribe(selection => {
        if (selection) {
          this.dataService.delete('api/outlier/delete', id).subscribe((response: any) => {
            // show toaster message on successfully deleting the user
            this.toastr.success(response.message);
            // get all the users after deleting the user
            this.getAll();
          })
        } else {
          // User clicked 'Cancel' or clicked outside the dialog
        }
      });

  }

  //expand select area tree
  expandArea(expandCode) {
    if (expandCode == '') return;
    if ($('[data-selfcode=' + expandCode + ']').length) {
      $('[data-selfcode=' + expandCode + ']').addClass('open');
      return this.expandArea($('[data-selfcode=' + expandCode + ']').data('parentcode'));
    }
    return
  }

  openForm(rowData) {
    // this.checkedTimePeriodItems = [];
    let that = this;
    this.validationError = [];

    if(this.form_open==false){
      $('#viewSelectedCheckbox').prop('checked',false);
      $('.not-selected-ius').show();
      $('.not-selected-ius-subsector').show();
      $('.not-selected-ius-sector').show();
      $('.not-selected-area-level').show();
      $('.not-selected-timeperiod').show();
    }

    this.form_open = !this.form_open;


    if (rowData !== undefined) {
      this.outlier_name = rowData.outlier_name;
      if (rowData.threshold_value == null) {
        this.showThresholdMinMaxValueCtrl = true;
        this.showThresholdValueCtrl = false;
      }
      else {
        this.showThresholdMinMaxValueCtrl = false;
        this.showThresholdValueCtrl = true;
      }

      this.titlePage = 'Edit'
      this.showEdit = true;

      let areaIds = [];
      let iusIds = [];
      let tempParentCode = [];
      let tempParentIusCode = [];

      /*rowData.outlier_area.forEach(row => {
        $('[data-areaid=' + row.area_id + ']').prop({ checked: true });
        if($('[data-areaid=' + row.area_id + ']').data('parentid') != ''){
          tempParentCode.push($('[data-areaid=' + row.area_id + ']').data('parentid'));
        }
      });*/
      let temp_ius = [];
      let temp_area = [];

      rowData.outlier_combinations.forEach(row => {

        if (temp_ius.indexOf(row.ius.id) == -1) {
          $('#subgroup_' + row.ius.id).prop({ checked: true });
          $('#subgroup_' + row.ius.id).parents('.ius').removeClass('not-selected-ius').find('a').eq(0).addClass('open');
          $('#subgroup_' + row.ius.id).parents('.ius-subsector').removeClass('not-selected-ius-subsector').find('a').eq(0).addClass('open');
          $('#subgroup_' + row.ius.id).parents('.ius-sector').removeClass('not-selected-ius-sector').find('a').eq(0).addClass('open');
        } /*else if (obj.checked == false) {
          temp_ius.push(row.ius.id);
        }*/

        if (temp_area.indexOf(row.area.id) == -1) {
          $('[data-areaid=' + row.area_id + ']').prop({ checked: true });
          $('[data-areaid=' + row.area_id + ']').parents('.cbxTree-node').removeClass('not-selected-area-level').find('a').eq(0).addClass('open');
          temp_area.push(row.area.id);
        }


        this.timePeriodList.forEach(item => {
          if (item.time_period == row.time_period) {
            item.checked = true;
            $('input[value="' + item.time_period + '"]').parents('.timeperiod-list').removeClass('not-selected-timeperiod');
          }

        });




      });

      //expand selected checkboxes parent
      //  tempParentCode = _.uniq(tempParentCode);
      //       if(tempParentCode.length > 0){
      //         for (var i = 0; i < tempParentCode.length; i++) {
      //           $('[data-selfcode=' + tempParentCode[i] + ']').addClass('open');
      //           this.expandArea($('[data-selfcode=' + tempParentCode[i] + ']').data('parentcode'));
      //         }
      //       }

      /*
            rowData.outlier_ius.forEach(row => {
              $('[data-iusid=' + row.ius_id + ']').prop({ checked: true });
              tempParentIusCode.push($('[data-iusid=' + row.ius_id + ']').data('parentid'));
            });*/
      // tempParentIusCode = _.uniq(tempParentIusCode);
      // for (var i = 0; i < tempParentIusCode.length; i++) {
      //   $('[data-selfcode=' + tempParentIusCode[i] + ']').addClass('open');
      //   this.expandArea($('[data-selfcode=' + tempParentIusCode[i] + ']').data('parentcode'));
      // }
      /*
            for (var i = 0; i < tempParentIusCode.length; i++) {
              // $('[data-selfcode=' + tempParentIusCode[i] + ']').addClass('open');
              let iuid = $('[data-iusid=' + tempParentIusCode[i] + ']').data('parentid');
              let subsector_id = $('[data-iusid=' + tempParentIusCode[i] + ']').data('subsectorid');
              let sector_id = $('[data-iusid=' + tempParentIusCode[i] + ']').data('sectorid');
              $('[data-selfcode_iu=' + iuid + ']').addClass('open');
              $('[data-selfcode_subsector=' + subsector_id + ']').addClass('open');
              $('[data-selfcode_sector=' + sector_id + ']').addClass('open');
              // this.expandArea($('[data-selfcode=' + tempParentIusCode[i] + ']').data('parentcode'));
            }*/

      /* rowData.outlier_timeperiod.forEach(element => {
 
         if (this.checkedTimePeriodItems.indexOf(element.timeperiod) == -1) {
           this.checkedTimePeriodItems.push(element.timeperiod);
 
         }
         this.timePeriodList.forEach(item => {
           if (this.checkedTimePeriodItems.indexOf(item.time_period) != -1) {
             item.checked = true;
           }
 
         })
 
       });*/
      this.addForm.patchValue({
        id: rowData.id,
        // areaIds: this.areaIdsNew ,
        // iusIds: this.areaIdsIus ,
        threshold_value: rowData.threshold_value,
        arithmatic_operation: rowData.arithmatic_operation,
        threshold_min: rowData.threshold_min,
        threshold_max: rowData.threshold_max,
        outlierName: rowData.outlier_name,

      });

      this.outlierRowId = rowData.id;

    }
    else {
      $('#outlier_name').focus();
      this.titlePage = 'New';
      this.outlierName = '';
      this.showEdit = false;
      this.addForm.reset();
      this.clearFormGroupDirective();
      this.timePeriodList.forEach(item => {
        item.checked = false;
      })
      $('input[name="iusIds"]').prop("checked", false).parents('.ius-sector').find('a').removeClass('open');
      $('input[name="areaIds"]').prop("checked", false).parents('.area-level1-parent').find('a').removeClass('open');
      $('input[name="timeperiodIds"]').prop("checked", false);
    }
  }

  indicatorList() {
    this.indicatorshow = this.indicatorshow;
  }
  byIndicatorList() {
    this.indicatorshow = !this.indicatorshow;
  }

  public confirmDeleteAll() {
   

    let selectedRows = {
      selectedIds: this.selectedItems
    }
    const dialog = this.dialog.open(DialogMaterialComponent, {
      width: '350px',
      data: { messageDialog: 'Are you sure to delete outlier?', delete: true }
    });
    dialog.afterClosed()
      .subscribe(selection => {
        if (selection) {
          this.dataService.add('api/outlier/delete/all', selectedRows).subscribe((response: any) => {
            if (response.status == "1") {
              this.toastr.success("outlier deleted successfully")
            }
            this.selectedItems = [];
            setTimeout(() => {
              this.ngOnInit();
            }, 200);

          }, (error: any) => {
            console.log(error);
          });
        } else {
          // User clicked 'Cancel' or clicked outside the dialog
        }
      });
  }
  update() {
    this.areaIds = [];
    this.iusIds = [];
    this.timeperiodIds = [];
    let timeperiod = this.addForm.get('timePeriod').value;
    var element = (<HTMLInputElement[]><any>document.getElementsByName("areaIds"));
    for (let i = 0; i < element.length; i++) {
      if (element[i].checked) {
        this.areaIds.push(element[i].value);
      }
    }
    var element = (<HTMLInputElement[]><any>document.getElementsByName("iusIds"));
    for (let i = 0; i < element.length; i++) {
      if (element[i].checked) {
        if (this.iusIds.indexOf(element[i].value) == -1) {
          this.iusIds.push(element[i].value);
        }
      }
    }
    var element = (<HTMLInputElement[]><any>document.getElementsByName("timeperiodIds"));
    for (let i = 0; i < element.length; i++) {
      if (element[i].checked) {
        this.timeperiodIds.push(element[i].value);
      }
    }
    let formData = {
      'outlierName': this.addForm.value.outlierName,
      'areaIds': this.areaIds.join(','),
      'iusIds': this.iusIds.join(','),
      'threshold_value': this.addForm.value.threshold_value,
      'arithmatic_operation': this.addForm.value.arithmatic_operation,
      'timeperiod': this.timeperiodIds.join(','),
      'threshold_min': this.addForm.value.threshold_min,
      'threshold_max': this.addForm.value.threshold_max
    };
    this.validationError = [];
    this.dataService.update('api/outlier/update', this.outlierRowId, formData).subscribe((response: any) => {
      this.toastr.success('Outlier updated sucessfully');
      // reset the form after submitting data
      this.addForm.reset();
      this.clearFormGroupDirective();
      setTimeout(() => {
        this.form_open = !this.form_open;
      }, 2000);
      //function to get the list of users after adding a user

      this.getAll();
    })

  }
  openModal(data) {
    this.outlierIusList = [];
    this.outlierAreaList = [];
    this.timeperiodList = [];
    this.outlierData = data
    let that = this
    this.outlierData.outlier_combinations.forEach(element => {
      if (that.outlierIusList.indexOf(element.ius.indicator + ' | ' + element.ius.unit + ' | ' + element.ius.subgroup) == -1) {
        that.outlierIusList.push(element.ius.indicator + ' | ' + element.ius.unit + ' | ' + element.ius.subgroup);
      }
      if (that.outlierAreaList.indexOf(element.area.area_name) == -1) {
        that.outlierAreaList.push(element.area.area_name)
      }
      if (that.outlierAreaList.indexOf(element.area.area_name) == -1) {
        that.outlierAreaList.push(element.area.area_name)
      }
      if (that.timeperiodList.indexOf(element.time_period) == -1) {
        that.timeperiodList.push(element.time_period)
      }


    });
    // this.outlierIusList = data.outlier_ius;
    // this.outlierAreaList = data.outlier_area;
    if (data.arithmatic_operation != 'between') {
      this.threshold = data.arithmatic_operation + '' + data.threshold_value
    } else {
      this.threshold = data.arithmatic_operation + ' ' + data.threshold_min + ' - ' + data.threshold_max
    }

  }
  checkValue(value) {
    let thresholdMinVal = this.addForm.get('threshold_min');
    let thresholdMaxVal = this.addForm.get('threshold_max');
    let thresholdVal = this.addForm.get('threshold_value');
    if (value == 'between') {
      thresholdVal.clearValidators();
      thresholdMinVal.setValidators(Validators.required);
      thresholdMaxVal.setValidators(Validators.required);
      this.showThresholdValueCtrl = false;
      this.showThresholdMinMaxValueCtrl = true

    }
    else {
      thresholdMaxVal.clearValidators();
      thresholdVal.clearValidators();
      thresholdVal.setValidators(Validators.required);
      this.showThresholdValueCtrl = true;
      this.showThresholdMinMaxValueCtrl = false;
    }
    thresholdMinVal.updateValueAndValidity();
    thresholdMaxVal.updateValueAndValidity();
    thresholdVal.updateValueAndValidity();

  }
  getTimePeriod() {
    this.dataService.getAll('api/time-period-list').subscribe((res: any) => {
      this.timePeriodList = res.data;

      this.timePeriodList.forEach(element => {
        element.checked = false;

      });

    });
  }
  checkItems(event) {
  }
  clearFormGroupDirective() {
    this.formGroupDirective.resetForm();
  }
  selectedTimePeriod(data, event) {
    //   console.log(event,data, 'cbcvnv')
    //   if(data.checked == false){
    //     data.checked = true
    //   if(this.selectedTimePeriodList.indexOf(data.time_period) == -1){
    //     this.selectedTimePeriodList.push(data)
    //   }
    // }
    // else{
    //   data.checked = false;
    //   this.selectedTimePeriodList.splice()



    // }


  }
  checkSelectedvalue(event) {
  }
  ngAfterViewInit() {
    // multiselect
    $(document).ready(function () {
      $('.time-period').multiselect({
        enableCollapsibleOptGroups: true,
        collapseOptGroupsByDefault: true,
        maxHeight: 200,
        nonSelectedText: 'Time Period',
        nSelectedText: 'timeperiod selected',
      }); $('.time-period').multiselect('rebuild');

    });
  }

  checkTimeperiod(event) {
    if (event.target.checked) {    
      if (this.checkedTimePeriodItems.indexOf(event.target.value) == -1) {
        this.checkedTimePeriodItems.push(event.target.value);
      }
      $(event.target).parents('.timeperiod-list').removeClass('not-selected-timeperiod');
    }
    else {    
      let indexOfPopedElem = this.checkedTimePeriodItems.indexOf(event.target.value);
      this.checkedTimePeriodItems.splice(indexOfPopedElem, 1);
      $(event.target).parents('.timeperiod-list').addClass('not-selected-timeperiod');
    }


    if ($('.outlier_timeperiod_list:checked').length == 0) {
             this.selectedTimePeriodList = 0;
           }else{
             this.selectedTimePeriodList = 1;      
           }




  }
  public validateMinMax(group: FormGroup): any {
    if (group.controls.threshold_max.value != null) {
      const invalid = group.controls.threshold_min.value > group.controls.threshold_max.value;
      return invalid ? { 'invalidValue': true } : {};
    }
  }

  checkSelectAll(checked) {
    

    if (checked) {
      $(".cbxTree-node").find("input.area-list").prop({ checked: true });
      $("input.area-list").parents('.cbxTree-node').removeClass('not-selected-area-level');
    }
    else {
      $(".cbxTree-node").find("input.area-list").prop({ checked: false });
      $("input.area-list").parents('.cbxTree-node').addClass('not-selected-area-level');
    }

    if ($('.area_check_list').find('.area-list:checked').length == 0) {
             this.selectedAreaList = 0;
           }else{
             this.selectedAreaList = 1;      
           }


  }

  checkSelectAllIus(checked) {
    



    if (checked) {
      $(".cbxTree-node").find("input.ius-list").prop({ checked: true });
      $(".cbxTree-node").find("input.ius-list").parents('.ius').removeClass('not-selected-ius');
      $(".cbxTree-node").find("input.ius-list").parents('.ius-subsector').removeClass('not-selected-ius-subsector');
      $(".cbxTree-node").find("input.ius-list").parents('.ius-sector').removeClass('not-selected-ius-sector');
    }
    else {
      $(".cbxTree-node").find("input.ius-list").prop({ checked: false });
      $(".cbxTree-node").find("input.ius-list").parents('.ius').addClass('not-selected-ius');
      $(".cbxTree-node").find("input.ius-list").parents('.ius-subsector').addClass('not-selected-ius-subsector');
      $(".cbxTree-node").find("input.ius-list").parents('.ius-sector').addClass('not-selected-ius-sector');
    }

    if ($('.ius-subsector').find('.ius-list:checked').length == 0) {
         this.selectedIusList = 0;
       }else{
         this.selectedIusList = 1;      
       }
  }

  checkSelectAllTimeperiod(checked) {
    if (checked) {
      $(".outlier_timeperiod_list").prop({ checked: true });
      this.selectedTimePeriodList=1;
    }
    else {
      $(".outlier_timeperiod_list").prop({ checked: false });
      this.selectedTimePeriodList=0;

    }
    
  }

  searchIndicatorList(value) {
    $('.elem-visible').each(function () {
      $(this).find('a:first-child').removeClass('open');
      $(this).removeAttr("style");
    });

    $('.parentClass').removeClass('.elem-visible');
    $('.parentClass').removeAttr("style");

    let str2 = value;
    if (str2.length <= 3) {
      return;
    }

    let str1 = '';

    $('#indicator_check_list .search-li').each(function () {
      let obj = $(this).find('input[type="checkbox"]');
      str1 = obj.attr('data-level1') + ' ' + obj.attr('data-level2') + ' ' + obj.attr('data-indicator') + ' ' + obj.attr('data-unit') + ' ' + obj.attr('data-subgroup');
      if (str1.toLowerCase().indexOf(str2.toLowerCase()) != -1) {
        $(this).show();
        $(this).parents('.parentClass').addClass('elem-visible');
      } else {
        $(this).parent().hide();
      }

    });

    $('.elem-visible').each(function () {
      $(this).find('a:first-child').addClass('open');
      $(this).css({ 'display': 'block' });
    });
  }

  viewSelected(checked) {
    // $('.cbxTree-cbx:checked')
    $('.cbxTree-cbx').each(function (e) {
      if (e.is(':checked')) {
      } else {

      }
    })

  }



  sendOutlierNotification(data) {
    $(".loaders ol li").fadeIn();
    let users = [];
    let area = [];
    let area_user = [];
    $('.user-mail:checked').each(function () {
      if (users.indexOf($(this).val()) == -1) {
        users.push($(this).val());
      }

      if (area.indexOf($(this).attr('data-area')) == -1) {
        area.push($(this).attr('data-area'));
      }

      // if (area_user.indexOf($(this).attr('data-area')+"|"+$(this).attr('data-email')) == -1) {
        area_user.push({area_id:$(this).attr('data-area'),email:$(this).attr('data-email')});
      // }

    });
    data['outlier_area'] = area;
    data['users'] = users;
    data['area_user'] = area_user;
    this.dataService.add('api/outlier/send-notification', data).subscribe((response: any) => {
          if(response.status) {
            this.toastr.success('Mail sent successfully');
          } else {
            this.toastr.success(response.error);
          }
          $(".loaders ol li").fadeOut();
    });
  }

  sendMailData: any = {};
  getOutlierUsers(data) {
    this.outlierUsers = [];
    $(".loaders ol li").fadeIn();
    this.sendMailData = data;
    this.dataService.add('api/outlier/get-outlier-users', data).subscribe((response: any) => {
      this.outlierUsers = response.data;
      $(".loaders ol li").fadeOut();
    });
  }

  showHideIusClass(obj) {

    if ($(obj).parents('.ius-subsector').find('.ius-list:checked').length == 0) {
      this.selectedIusList = 0;
    }else{
      this.selectedIusList = 1;      
    }


    if (obj.checked == true) {
      // $(obj).parents('.ius-subgroup').removeClass('not-selected-ius-subgroup');
      $(obj).parents('.ius').removeClass('not-selected-ius');
      $(obj).parents('.ius-subsector').removeClass('not-selected-ius-subsector');
      $(obj).parents('.ius-sector').removeClass('not-selected-ius-sector');
    } else if (obj.checked == false) {
      // $(obj).parents('.ius-subgroup').removeClass('not-selected-ius-subgroup');
      $(obj).parents('.ius').addClass('not-selected-ius');
      if ($(obj).parents('.ius-subsector').find('.ius-list:checked').length == 0) {
        $(obj).parents('.ius-subsector').addClass('not-selected-ius-subsector');
        $(obj).parents('.ius-sector').addClass('not-selected-ius-sector');
      }
    }
  }

  showHideAreaClass(obj) {
    let areaLevel = $(obj).data('arealevel');
     if ($('.area_check_list').find('.area-list:checked').length == 0) {
         this.selectedAreaList = 0;
       }else{
         this.selectedAreaList = 1;      
       }
    
    if (obj.checked == true) {
      for (let i = 1; i <= areaLevel; i++) {
        $(obj).parents('.area-level' + i + '-parent').removeClass('not-selected-area-level');
      }
    } else if (obj.checked == false) {
      $(obj).parents('.area-level' + areaLevel + '-parent').addClass('not-selected-area-level');
      for (let i = 1; i <= areaLevel; i++) {
        if ($(obj).parents('.area-level' + i + '-parent').find($('input[data-arealevel="' + areaLevel + '"]:checked')).length == 0) {
          $(obj).parents('.area-level' + i + '-parent').addClass('not-selected-area-level');
        }
      }
    }
  }

  showSelected(obj) {
    if (obj.checked == true) {
      // $('.not-selected-ius-subgroup').hide();
      $('.not-selected-ius').hide();
      $('.not-selected-ius-subsector').hide();
      $('.not-selected-ius-sector').hide();
      $('.not-selected-area-level').hide();
      $('.not-selected-timeperiod').hide();
    } else if (obj.checked == false) {
      // $('.not-selected-ius-subgroup').show();
      $('.not-selected-ius').show();
      $('.not-selected-ius-subsector').show();
      $('.not-selected-ius-sector').show();
      $('.not-selected-area-level').show();
      $('.not-selected-timeperiod').show();
    }
  }
  onSelect({ selected }) {
    this.selectedItems = [];    
    this.selectedItems.splice(0, this.selectedItems.length);
    if (selected.length == 1) {
      this.selectedItems.push(selected[0].id);
    } else if (selected.length > 1) {
      for (let i = 0; i < selected.length; i++) {
        this.selectedItems.push(selected[i].id);
      }
    }
  }

  public onScrollEvent(event: any): void {
  }
  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.filterData.filter(function (d) {
      return d.outlier_name.toLowerCase().indexOf(val) !== -1 || !val;
      // return Object.keys(d).some(function (key) {

      //   return d[key].toString().toLowerCase().indexOf(val) !== -1 || !val;
      // });
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  showWhereDataExistsIus(checked) {
    if (checked) {
      let ius_array_advanced_search = [];
      let area_array_advanced_search = [];
      let timeperiod_array_advanced_search = [];
      if ($('#indicator_check_list input[name="iusIds"]:checked').length > 0) {
        $('#indicator_check_list input[name="iusIds"]:checked').each(function () {
          ius_array_advanced_search.push($(this).data('iusid'));
        });
      }
      if ($('#area-check-list input[name="areaIds"]:checked').length > 0) {
        $('#area-check-list input[name="areaIds"]:checked').each(function () {
          area_array_advanced_search.push($(this).data('areaid'));
        });
      }
      if ($('#timeperiod-check-list input[name="timeperiodIds"]:checked').length > 0) {
        $('#timeperiod-check-list input[name="timeperiodIds"]:checked').each(function () {
          timeperiod_array_advanced_search.push($(this).val());
        });
      }

      let passData = {
        "parameter": "ius",
        "parameter_value_1": JSON.stringify(area_array_advanced_search),
        "parameter_value_2": JSON.stringify(timeperiod_array_advanced_search),
      };

      this.dataService.add('api/outlier/get-other-parameters', passData).subscribe((response: any) => {
        $('#indicator_check_list input[name="iusIds"]').attr('disabled', true).prop('checked', false).closest('div.ius').addClass('disabled').find('a:first-child').addClass('disabled');
        response.data.records.forEach(element => {
          let ius_returned = element.ius_id;
          if (ius_array_advanced_search.length > 0) {
            if (ius_array_advanced_search.indexOf(ius_returned) != -1) {
              $('#indicator_check_list input[data-iusid="' + ius_returned + '"]').attr('disabled', false).prop('checked', true).closest('div.ius').removeClass('disabled').find('a:first-child').removeClass('disabled');
            }
            else {
              $('#indicator_check_list input[data-iusid="' + ius_returned + '"]').attr('disabled', false).closest('div.ius').removeClass('disabled').find('a:first-child').removeClass('disabled');
            }
          } else {
            $('#indicator_check_list input[data-iusid="' + ius_returned + '"]').attr('disabled', false).closest('div.ius').removeClass('disabled').find('a:first-child').removeClass('disabled');
          }
        });
      });
    } else {
      $('#indicator_check_list input[name="iusIds"]').attr('disabled', false).closest('div.ius').removeClass('disabled').find('a:first-child').removeClass('disabled');
    }
  }

  showWhereDataExistsArea(checked) {
    if (checked) {
      let ius_array_advanced_search = [];
      let area_array_advanced_search = [];
      let timeperiod_array_advanced_search = [];

      if ($('#area-check-list input[name="areaIds"]:checked').length > 0) {
        $('#area-check-list input[name="areaIds"]:checked').each(function () {
          area_array_advanced_search.push($(this).data('areaid'));
        });
      }
      if ($('#indicator_check_list input[name="iusIds"]:checked').length > 0) {
        $('#indicator_check_list input[name="iusIds"]:checked').each(function () {
          ius_array_advanced_search.push($(this).data('iusid'));
        });
      }
      if ($('#timeperiod-check-list input[name="timeperiodIds"]:checked').length > 0) {
        $('#timeperiod-check-list input[name="timeperiodIds"]:checked').each(function () {
          timeperiod_array_advanced_search.push($(this).val());
        });
      }

      let passData = {
        "parameter": "ius",
        "parameter_value_1": JSON.stringify(ius_array_advanced_search),
        "parameter_value_2": JSON.stringify(timeperiod_array_advanced_search),
      };

      this.dataService.add('api/outlier/get-other-parameters', passData).subscribe((response: any) => {
        $('#area-check-list input[name="areaIds"]').attr('disabled', true).prop('checked', false).parent('label').addClass('disabled').closest('div.cbxTree-node').parent('div.cbxTree-node').find('input.selectChild').attr('disabled', true).parent('label').addClass('disabled');
        response.data.records.forEach(element => {
          let area_id_returned = element.area_id;
          if (area_array_advanced_search.length > 0) {
            if ($.inArray(area_id_returned, area_array_advanced_search) != -1) {
              $('#area-check-list input[data-areaid="' + area_id_returned + '"]').attr('disabled', false).prop('checked', true).parent('label').removeClass('disabled').closest('div.cbxTree-node').parent('div.cbxTree-node').find('input.selectChild').attr('disabled', false).parent('label').removeClass('disabled');
            }
            else {
              $('#area-check-list input[data-areaid="' + area_id_returned + '"]').attr('disabled', false).parent('label').removeClass('disabled').closest('div.cbxTree-node').parent('div.cbxTree-node').find('input.selectChild').attr('disabled', false).parent('label').removeClass('disabled');
            }
          } else {
            $('#area-check-list input[data-areaid="' + area_id_returned + '"]').attr('disabled', false).parent('label').removeClass('disabled').closest('div.cbxTree-node').parent('div.cbxTree-node').find('input.selectChild').attr('disabled', false).parent('label').removeClass('disabled');
          }
        });
      })

    } else {
      $('#area-check-list input[name="areaIds"]').attr('disabled', false).parent('label').removeClass('disabled').closest('div.cbxTree-node').parent('div.cbxTree-node').find('input.selectChild').attr('disabled', false).parent('label').removeClass('disabled');
    }
  }

  getFormatedTimeperiod(timePeriod){
   
		return this.commonService.covertTimePeriod(timePeriod);
	}
}


