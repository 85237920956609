import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GalleryVisualizationTitleService {

  constructor() { }


  chartTitleadd:any='';
  generateTitleCase1(obj) { 
         
          return this.chartTitleadd =  obj['ius'][0];
  }
 generateTitleCase2(obj) {    
        return this.chartTitleadd =  obj['ius'][0];    
         
  }


  /*
  generateTitleCase3(obj) {    
      
          let ius = obj['ius'][0];
        


          if(obj['timeperiod']=='MRD' || obj['timeperiod']=='mrd') {
              let chartTitleadd = ius+', '+obj['timeperiod'];  
          } else {
              let chartTitleadd = ius;  
              //chartTitleadd = $obj+', '+$("[name='time_period_all']:checked").val()+', '+$type;  
          } 
          //console.log(chartTitleadd);       
  }
 
  generateTitleCase4(type) {    
          var startTime = new Date().getTime();
      
          $obj = $('#'+whichModal+" [name='all_ius']:checked").val();
          
          chartTitleadd = $obj;      
          //console.log(chartTitleadd);
  }
*/
  generateTitleCase5(obj) {    
      
        return this.chartTitleadd =  obj['ius'][0];   
  }

// //   generateTitleCase6(type) {    
      
// //           $obj = $('#'+whichModal+" [name='all_ius']:checked").val();
          
// //           chartTitleadd = $obj;  
// //           //console.log(chartTitleadd);    
// //   }

// //   generateTitleCase7(type) {    
      
// //           $obj = $('#'+whichModal+" [name='all_ius']:checked").val();

          
          
// //           chartTitleadd = $obj; 
// //           //console.log(chartTitleadd);     
// //   }

// //   generateTitleCase8(type) {    
      
// //           $obj = $('#'+whichModal+" [name='all_ius']:checked");
// //           var array = $($obj).val().split(',');
// //           subgroup = array.pop();
// //           unit = array.pop();
          
// //           chartTitleadd = unit+', '+subgroup;
// //           //console.log(chartTitleadd);      
          
// //   }

// //   generateTitleCase9(type) {    
      
// //           $obj = $('#'+whichModal+" [name='all_ius']:checked");
// //           var array = $($obj).val().split(',');
// //           subgroup = array.pop();
          
// //           chartTitleadd = subgroup;  
// //           //console.log(chartTitleadd);    
// //   }

// //   generateTitleCase10(type) {    
      
// //           $obj = $('#'+whichModal+" [name='all_ius']:checked");
// //           chartTitleadd = 'Data';
// //           //console.log(chartTitleadd);
// //   }

//   generateTitleCase11(type) {    
      
//           $obj = $('#'+whichModal+" [name='all_ius']:checked");
//           var split_iu = $($obj).val().split(",");
//           indicator = split_iu[0];
//           unit = split_iu[1];
//           subgroup = split_iu[2];

//           chartTitleadd = indicator+', '+subgroup;  
//           //console.log(chartTitleadd); 

//   }



  generateTitleCase12(obj) {        
          var split_iu =obj['ius'][0].split("|");    
          return this.chartTitleadd = split_iu[0]+', '+split_iu[2]; 
         
  }

  generateTitleCase13(obj) {         
          var split_iu =obj['ius'][0].split("|");                
          return this.chartTitleadd = split_iu[0]+', '+split_iu[2];
  }


  generateTitleCase14(obj) {   
      
          var split_iu =obj['ius'][0].split("|");    
          return this.chartTitleadd = split_iu[1]+', '+split_iu[2];    
  }

  generateTitleCase15(obj) {         
          var split_iu =obj['ius'][0].split("|");    
          return this.chartTitleadd = split_iu[0];   
  }


  generateTitleCase16(obj) {   
      
        var split_iu =obj['ius'][0].split("|");    
          return this.chartTitleadd = split_iu[2];      
  }



  generateTitleCase17(obj) {   
      var split_iu =obj['ius'][0].split("|");    
      return this.chartTitleadd = split_iu[0]+', '+split_iu[1];   
  }


  generateTitleCase18(obj) {   
      
         var split_iu =obj['ius'][0].split("|");    
         return this.chartTitleadd = split_iu[1]+', '+split_iu[2];  
  }

  //   generateTitleCase19(type) {   
      
  //           $obj = $('#'+whichModal+" [name='all_ius']:checked");
      
  //           var split_iu = $($obj).val().split(",");
  //           indicator = split_iu[0];
  //           unit = split_iu[1];
  //           subgroup = split_iu[2];

          
  //           chartTitleadd = unit+', '+subgroup;
  //           //console.log(chartTitleadd);    
  //   }

  generateTitleCase20(obj) {   
      
         var split_iu =obj['ius'][0].split("|");    
         return this.chartTitleadd = split_iu[1];     
  }


  //   generateTitleCase21(type) {   
        
  //           $obj = $('#'+whichModal+" [name='all_ius']:checked");
        
  //           var split_iu = $($obj).val().split(",");
  //           indicator = split_iu[0];
  //           unit = split_iu[1];
  //           subgroup = split_iu[2];

            
  //           chartTitleadd = indicator;
  //           //console.log(chartTitleadd);    
  //   }
  //   generateTitleCase22(type) {   
        
  //           $obj = $('#'+whichModal+" [name='all_ius']:checked");
        
  //           var split_iu = $($obj).val().split(",");
  //           indicator = split_iu[0];
  //           unit = split_iu[1];
  //           subgroup = split_iu[2];

            
  //           chartTitleadd = indicator+', '+subgroup;
  //           //console.log(chartTitleadd);    
  //   }
    generateTitleCase23(obj) {   
         var split_iu =obj['ius'][0].split("|");    
         return this.chartTitleadd = split_iu[0];     
            //console.log(chartTitleadd);    
    }
  //   generateTitleCase24(type) {   
        
  //           $obj = $('#'+whichModal+" [name='all_ius']:checked");
        
  //           var split_iu = $($obj).val().split(",");
  //           indicator = split_iu[0];
  //           unit = split_iu[1];
  //           subgroup = split_iu[2];

            
  //           chartTitleadd = indicator;
  //           //console.log(chartTitleadd);    
  //   }

  //   generateTitleCase25(type) {   
        
  //           $obj = $('#'+whichModal+" [name='all_ius']:checked");
        
  //           var split_iu = $($obj).val().split(",");
  //           indicator = split_iu[0];
  //           unit = split_iu[1];
  //           subgroup = split_iu[2];

            
  //           chartTitleadd = unit;
  //           //console.log(chartTitleadd);    
  //   }

  //   generateTitleCase26(type) {   
        
  //           $obj = $('#'+whichModal+" [name='all_ius']:checked");
        
  //           var split_iu = $($obj).val().split(",");
  //           indicator = split_iu[0];
  //           unit = split_iu[1];
  //           subgroup = split_iu[2];

            
  //           chartTitleadd = unit;
  //           //console.log(chartTitleadd);    
  //   }


    generateTitleCase27(obj) {   
         var split_iu =obj['ius'][0].split("|");    
         return this.chartTitleadd = split_iu[1];               
    }


    generateTitleCase28(obj) {   
        
            var split_iu =obj['ius'][0].split("|");    
         return this.chartTitleadd = split_iu[2];   
    }


  //   generateTitleCase29(type) {   
        
  //           $obj = $('#'+whichModal+" [name='all_ius']:checked");
        
  //           var split_iu = $($obj).val().split(",");
  //           indicator = split_iu[0];
  //           unit = split_iu[1];
  //           subgroup = split_iu[2];

            
  //           chartTitleadd = subgroup;
  //           //console.log(chartTitleadd);    
  //   }

    generateTitleCase30(obj) {   
        
           var split_iu =obj['ius'][0].split("|");    
         return this.chartTitleadd = split_iu[0]+', '+split_iu[1]+', '+split_iu[2];   
            //console.log(chartTitleadd);    
    }
  //   generateTitleCase31(type) {   
        
  //           $obj = $('#'+whichModal+" [name='all_ius']:checked");
  //            var split_iu = $($obj).val().split(",");
  //           indicator = split_iu[0];
  //           unit = split_iu[1];
  //           subgroup = split_iu[2];

  //           chartTitleadd = indicator+', '+unit+', '+subgroup;
  //           //console.log(chartTitleadd);    
  //   }
    generateTitleCase32(obj) {   
        
            var split_iu =obj['ius'][0].split("|");    
         return this.chartTitleadd = split_iu[0]+', '+split_iu[1]+', '+split_iu[2];   
            //console.log(chartTitleadd);    
    }
}
