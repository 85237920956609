import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../services/header.service';
@Component({
  selector: 'app-cold-chain',
  templateUrl: './cold-chain.component.html',
  styleUrls: ['./cold-chain.component.css']
})
export class ColdChainComponent implements OnInit {

  constructor(private headerService: HeaderService) { }

  ngOnInit() {
  	this.headerService.setTitle('Coverage');
  }

}
