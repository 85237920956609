import { Component, OnInit } from "@angular/core";
import { HeaderService } from "../../services/header.service";
import { ApiService } from "../../services/api.service";
import * as Highcharts from "highcharts";
import { VisualizationRendererService } from "../../services/visualization-renderer";
import { SharedataService } from "../../services/sharedata.service";
import { CommonService } from "../../services/common.service";
import { Subscription } from "rxjs";
import { UseCaseService } from "../../services/use-case.service";
import { GalleryVisualizationTitleService } from "../../services/gallery-visualization-title.service";
import { GetCaseColumnService } from "../../services/get-case-column.service";
import { ToastrService } from "ngx-toastr";
import { _ } from "underscore";
import { title } from "process";
declare var $: any;

@Component({
  selector: "app-rank",
  templateUrl: "./rank.component.html",
  styleUrls: ["./rank.component.css"],
})
export class RankComponent implements OnInit {
  mapLevel = 1;
  subSector;
  subscription: Subscription;
  iusDropDown = [];
  selectedIndicatorUnit;
  selectedSubgroup = "Stratifier";
  selectedTimeperiod = "mrd";
  selectedChartType = "column";
  sourceOfMap: any;
  sourceData = [];
  tempIusList = [];
  iusselect: any;
  areaID: any = "";
  sent_time_period: any = "";
  recentTimeperiodList = [];
  recentStratifierList = [];
  selectionType = 1; //1 - area, sidebar and indicator, 2- subgroup and timeperiod
  iusList = [];
  chartContainer: any;
  all_indicator: any;
  all_unit: any;
  all_subgroup: any;
  all_time_period: any;
  all_area: any;
  all_ius: any = [];
  all_iu: any = [];
  all_dataSource: any = [];
  dataSource: any;
  fileterIusList: any;
  changed_from_to_timeperiod: any = [];
  iusGeneratTile = [];
  recentTimeperiodListFromTo = [];
  resIus = [];
  mapValueCalculationType = ["Yearly", "Monthly"];
  selectedValueCalculationType = "Yearly";

  titleTamp: any;
  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private vizService: VisualizationRendererService,
    private shareData: SharedataService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private useCase: UseCaseService,
    private columnUseCase: GetCaseColumnService,
    public vizTitle: GalleryVisualizationTitleService
  ) {
    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "sidebar":
          // $(".loaders ol li").fadeIn();
          // this.subSector = message.data.data.level2;
          // this.iusDropDown = this.commonService.extractIus(message.data.data);
          // this.iusselect = this.iusDropDown[0].id;
          // this.tempIusList = this.iusDropDown;

          // this.sent_time_period = "mrd";
          // this.sourceData = [];
          // this.selectionType = 1;
          // this.areaID = this.commonService.getAreaID();
          // this.getIusList()
          // this.getRankChart();
          break;
        case "header":
          $(".loaders ol li").fadeIn();
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          // this.selectionType = 1;
          // this.getIusList()
          this.getRankChart();
          break;
        default:
          break;
      }
    });
  }
  xaxis: any = [];
  yaxis: any = "";
  seriesData: any = [];
  vizData: any = {};
  ngOnInit() {
    $(".loaders ol li").fadeIn();
    this.headerService.setTitle("Data visualization / Graphs");
    this.headerService.setLogo(
      "assets/lib/images/svg/header-menu/area-dashboard.svg"
    );
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getIusList();
  }
  changeValueCalculationType(event, type) {
    //['Year','Monthly']

    this.selectedValueCalculationType = event.value;
    this.getRankChart();
  }
  
  getIusList() {
    this.changed_from_to_timeperiod["from"] = "";
    this.changed_from_to_timeperiod["to"] = "";

    this.shareData
      .getAll("api/get-indicator-classifications")
      .subscribe((response: any) => {
        this.iusList = response.data;
        this.fileterIusList = this.iusList;

        // this.iusList.forEach((element, key) => {
        //   this.elemnId = key;
        // });
        // this.subSector = response.data[0].leveltwo[0].level2;
        this.iusselect = response.data[0].id;
        this.sent_time_period = "mrd";
        this.getRankChart();
      });
  }

  chnageStratifier(event) {
    //this.iusselect = event.value;
    this.selectionType = 2;
    this.getRankChart();
  }

  chnageTimePeriod(event) {
    this.selectionType = 1;
    this.sent_time_period = event.value;
    this.getRankChart();
  }

  getRankChart() {
    $(".loaders ol li").fadeIn();

    if (
      this.changed_from_to_timeperiod["from"] != "" &&
      typeof this.changed_from_to_timeperiod["from"] != "undefined" &&
      this.changed_from_to_timeperiod["to"] != "" &&
      typeof this.changed_from_to_timeperiod["to"] != "undefined"
    ) {
      this.sent_time_period = 0;
    } else if (
      this.changed_from_to_timeperiod["from"] == "" &&
      this.changed_from_to_timeperiod["to"] == "" &&
      this.sent_time_period == ""
    ) {
      $("#reportViz").html("<span class='no-data'>No data avialable</span>");
      $(".loaders ol li").fadeOut();
      return;
    } else {
      this.changed_from_to_timeperiod["from"] = undefined;
      this.changed_from_to_timeperiod["to"] = undefined;
    }

    this.dataService
      .getAll(
        "api/get-rank-chart-data/" +
          this.iusselect +
          "/" +
          this.areaID +
          "/" +
          this.sent_time_period +
          "/" +
          this.selectionType +
          "/" +
          this.changed_from_to_timeperiod["from"] +
          "/" +
          this.changed_from_to_timeperiod["to"] +
          "/" +
          this.selectedValueCalculationType
      )
      .subscribe((response: any) => {
        $(".loaders ol li").fadeOut();

        this.xaxis = [];
        this.yaxis = "";
        this.seriesData = [];
        let objData = [];

        let array1= response.data.mostRecentData;
        let array2 = response.data1.ev;

        let rankData = array1.concat(array2);

        let subgroupTemp = [];

        this.all_ius = [];
		this.all_iu = [];
        this.all_indicator = [];

        this.all_unit = [];
        this.all_subgroup = [];
        this.all_area = [];
        this.all_time_period = [];
        this.all_dataSource = [];
        //this.all_seriesData = [];

        this.sourceData = [];
        this.sourceOfMap = "";
        let that = this;

        if (rankData.length == 0) {
          $("#reportViz").html(
            "<span class='no-data'>No data avialable</span>"
          );
        }

        let prop = "time_period";
        // if (this.changed_from_to_timeperiod['from'] != '' && typeof this.changed_from_to_timeperiod['from'] != 'undefined' && this.changed_from_to_timeperiod['to'] != '' && typeof this.changed_from_to_timeperiod['to'] != 'undefined') {

        // }
        // else {
        // 	rankData = rankData.sort(function (a, b) {

        // 		// return a[prop] < b[prop];

        // 		return a[prop].localeCompare(b[prop]);

        // 	});
        // }

        //	response.data.mostRecentData.forEach( elem => {elem.time_period = this.commonService.covertTimePeriod(elem.time_period);})
        /*	if (this.changed_from_to_timeperiod['from'] != '' && typeof this.changed_from_to_timeperiod['from'] != 'undefined' && this.changed_from_to_timeperiod['to'] != '' && typeof this.changed_from_to_timeperiod['to'] != 'undefined') {

			  let pass_data2 = [];
			  let that = this;
			  let dataVal;
			  let groupedData = _.groupBy(rankData, function (d) {
			    return d.area_id + '-' + d.ius_id;
			  });
			  _.each(groupedData, function ($row) {
			    let tempData = [];
			    let dataAddVal = 0;
			    let denominator = 0;
			    let numerator = 0;
			    _.each($row, function ($val) {
			      if ($val.unit == 'Percent' || $val.unit == 'percent') {
			        denominator = denominator + Number($val.denominator);
			        numerator = numerator + Number($val.numerator);
			      } else if ($val.unit == 'Number' || $val.unit == 'number') {
			        dataAddVal = dataAddVal + Number($val.value);
			      }
			    });
			    if (dataAddVal > 0) {
			      dataVal = dataAddVal;
			    } else if (denominator > 0 && numerator > 0) {
			      dataVal = (numerator / denominator) * 100;
			      dataVal = dataVal.toFixed(2);
			    } else {
			      dataVal = '';
			    }
			    pass_data2.push({
			      area_code: $row[0].area_code,
			      area_id: $row[0].area_id,
			      area_name: $row[0].area_name,
			      id: $row[0].id,
			      indicator: $row[0].indicator,
			      ius_id: $row[0].ius_id,
			      source: $row[0].source,
			      subgroup: $row[0].subgroup,
			      unit: $row[0].unit,
			      ius: $row[0].indicator + '|' + $row[0].unit + '|' + $row[0].subgroup,
			      time_period: that.changed_from_to_timeperiod['from'] + '-' + that.changed_from_to_timeperiod['to'],
			      value: dataVal,
			    });
			  });
			  rankData = pass_data2;
			}
				*/

        //Amit Namde0

        if (
          this.changed_from_to_timeperiod["from"] != "" &&
          typeof this.changed_from_to_timeperiod["from"] != "undefined" &&
          this.changed_from_to_timeperiod["to"] != "" &&
          typeof this.changed_from_to_timeperiod["to"] != "undefined"
        ) {
          let pass_data2 = [];

          rankData.forEach((element) => {
            let dataVal;
            if (element.unit == "Percent" || element.unit == "percent") {
              dataVal = (element.numerator * 100) / element.denominator;
            } else {
              dataVal = element.value;
            }
            pass_data2.push({
              area_code: element.area_code,
              area_id: element.area_id,
              area_name: element.area_name,
              id: element.id,
              indicator: element.indicator,
              ius_id: element.ius_id,
              source: element.source,
              subgroup: element.subgroup,
              unit: element.unit,
              numerator: element.numerator,
              ius:
                element.indicator + "|" + element.unit + "|" + element.subgroup,
              time_period:
                this.getFormatedTimeperiod(
                  that.changed_from_to_timeperiod["from"]
                ) +
                "-" +
                this.getFormatedTimeperiod(
                  that.changed_from_to_timeperiod["to"]
                ),
              value: Math.round(dataVal),
            });
          });

          rankData = pass_data2;
        } else {
          let pass_data2 = [];

          let val;
          rankData.forEach((element) => {
            let dataVal;

            if (element.unit == "Percent" || element.unit == "percent") {
              dataVal = (element.numerator * 100) / element.denominator;
            } else {
              dataVal = element.value;
            }
  

            // Code for block level data in Number
            if (this.mapLevel == 4) {
              val = element.numerator;
            } else {
              val = element.value;
            }

            pass_data2.push({
              area_code: element.area_code,
              area_id: element.area_id,
              area_name: element.area_name,
              id: element.id,
              indicator: element.indicator,
              ius_id: element.ius_id,
              source: element.source,
              subgroup: element.subgroup,
              unit: element.unit,
              numerator: element.numerator,
              ius:
                element.indicator + "|" + element.unit + "|" + element.subgroup,
              time_period: this.getFormatedTimeperiod(element.time_period),
              // value: element.value,
              value: val,
            });
          });

          rankData = pass_data2;
        }

        rankData.forEach((element) => {
          if (this.sourceData.indexOf(element.source) == -1) {
            this.sourceData.push(element.source);
            this.sourceOfMap = this.sourceData.join("");
          }

          if (this.xaxis.indexOf(element.area_name) == -1) {
            this.xaxis.push(element.area_name);
            if (
              this.changed_from_to_timeperiod["from"] != "" &&
              typeof this.changed_from_to_timeperiod["from"] != "undefined" &&
              this.changed_from_to_timeperiod["to"] != "" &&
              typeof this.changed_from_to_timeperiod["to"] != "undefined"
            ) {
              this.seriesData.push(
                (Number(element.numerator) * 100) / Number(element.denominator)
              );
            } else {
              this.seriesData.push(Number(element.value));
            }
          }
          if (that.all_iu.indexOf(element.indicator) == -1) {
            that.all_iu.push(element.indicator);
          }
          element.iu = element.indicator;
          that.iusGeneratTile.push(element.indicator);
          if (
            that.all_ius.indexOf(
              element.indicator + "|" + element.unit + "|" + element.subgroup
            ) == -1
          ) {
            that.all_ius.push(
              element.indicator + "|" + element.unit + "|" + element.subgroup
            );
          }
          element.ius =
            element.indicator + "|" + element.unit + "|" + element.subgroup;
          that.iusGeneratTile.push(
            element.indicator + "|" + element.unit + "|" + element.subgroup
          );
          if (that.all_indicator.indexOf(element.indicator) == -1) {
            that.all_indicator.push(element.indicator);
          }
          if (that.all_dataSource.indexOf(element.source) == -1) {
            that.all_dataSource.push(element.source);
            that.dataSource = that.all_dataSource.join("");
          }
          if (that.all_unit.indexOf(element.unit) == -1) {
            that.all_unit.push(element.unit);
          }
          if (that.all_subgroup.indexOf(element.subgroup) == -1) {
            that.all_subgroup.push(element.subgroup);
          }
          if (that.all_area.indexOf(element.area_name) == -1) {
            that.all_area.push(element.area_name);
          }

          if (
            this.changed_from_to_timeperiod["from"] != "" &&
            typeof this.changed_from_to_timeperiod["from"] != "undefined" &&
            this.changed_from_to_timeperiod["to"] != "" &&
            typeof this.changed_from_to_timeperiod["to"] != "undefined"
          ) {
            that.all_time_period.push(element.time_period);
          } else {
            that.all_time_period.push(element.time_period);
          }
          if (that.resIus.indexOf(element.ius_id) == -1) {
            that.resIus.push(element.ius_id);
          }
        });

        if (this.selectionType == 1) {
          if (this.selectedValueCalculationType == "Monthly") {
            this.recentTimeperiodList = response.mm;
           
            this.recentTimeperiodList.forEach(function (e) {
              e.selected = true;
            });
          } else {
            this.recentTimeperiodList = response.financial_year;
            this.recentTimeperiodList.forEach(function (e) {
              e.selected = true;
            });
          }
          this.recentTimeperiodListFromTo = response.mm;
          this.recentStratifierList = response.statifier;
          this.selectedSubgroup = this.iusselect;
          this.yaxis = response.data.ius.unit;
        }

        setTimeout(function () {
          $(".timeperiod-list").multiselect("rebuild");
        }, 1000);

        let yaxis = "";
        if (this.all_unit.length == 1) {
          yaxis = this.all_unit[0];
        } else {
          yaxis = "";
        }

        objData["funcName"] = this.useCase.findUseCase(
          "column",
          this.all_indicator.length,
          this.all_unit.length,
          this.all_subgroup.length,
          this.all_time_period.length,
          this.all_area.length
        );
        objData["chartType"] = "column";

        let func_name = this.useCase.findUseCase(
          "generateTitle",
          this.all_indicator.length,
          this.all_unit.length,
          this.all_subgroup.length,
          this.all_time_period.length,
          this.all_area.length
        );

        // generate title for chart
        let objData2: any = [];
        objData2["timeperiod"] = this.getFormatedTimeperiod(
          this.all_time_period
        );
        objData2["ius"] = this.all_ius;
		objData2["iu"]=this.all_iu;
		//let viztitle = this.vizTitle[func_name](objData2);
	
		let viztitle;

	  if(this.all_indicator.length > 3){
          viztitle =
            this.all_iu[0] +
            "," +
            this.all_iu[1] +
            " ," +
            this.all_iu[3] +
            ", ...";
        } else {
          viztitle = objData2["iu"];
	  }
		
        //let viztitle="generateTitle";

        objData["time_period"] = this.getFormatedTimeperiod(
          this.all_time_period
        );
        objData["area"] = this.all_area;
        objData["data"] = rankData;
        objData["ius"] = this.all_ius;
        objData["id"] = "reportViz";
        objData["subgroup"] = subgroupTemp.length == 1 ? subgroupTemp[0] : "";
        let tempData = this.getVizData(objData);
        let vizData = [];
        vizData["renderId"] = "reportViz";
        vizData["data"] = tempData["data"];
        vizData["legend"] = true;
        vizData["label"] = true;
        vizData["chartType"] = "column";

        vizData["ius"] = tempData["ius"];
        vizData["time_period"] = tempData["time_period"];
        vizData["subgroup"] = tempData["subgroup"];
        vizData["title"] = viztitle;
        vizData["moduleType"] = "rank";
        // vizData['data_source'] = $("#data_source_" + containerId).html();
        vizData["data_source"] = this.all_dataSource;
        vizData["unit"] = yaxis;
        this.chartContainer = new Highcharts.Chart(
          this.vizService[tempData["vizFunc"]](vizData)
        );
        Highcharts.setOptions({ lang: { thousandsSep: "," } });
        /*
			this.vizData['data'] = {};
			this.vizData['data']['xaxis'] = this.xaxis;
			this.vizData['data']['yaxis'] = this.yaxis;
			this.vizData['data']['series'] = [{ name: '', data: this.seriesData }];
			this.vizData['renderId'] = 'reportViz';
			this.vizData['data']['type'] = 'column';
			let chart_render = new Highcharts.Chart(this.vizService.loadRankChart(this.vizData));*/
        if (rankData.length == 0) {
          this.chartContainer.update({
            xAxis: {
              // type: "category",
              visible: false,
            },
            yAxis: {
              title: {
                text: "",
              },
              visible: false,
            },
          });
        }
        if (objData["ius"].length > 1) {
          //  $('#reportViz').html(vizData['data']);
          this.toggleGrouping('reportViz');
        } else {
          $(".loaders ol li").fadeOut();
        }
      });
  }

  changeIus(event) {
    this.selectionType = 1;
    this.sourceOfMap = "";
    this.iusselect = event.value;
    this.getRankChart();
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  filterListCareUnit(val) {
    this.iusDropDown = this.tempIusList.filter(
      (item) =>
        (item.indicator + "|" + item.unit)
          .toLowerCase()
          .indexOf(val.toLowerCase()) > -1
    );
  }

  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodChar(timePeriod);
  }
  getFormatedTimem(timePeriod) {
    return this.commonService.convertToMonth(timePeriod);
  }

  submitIus() {
    this.iusselect = [];
    this.selectionType = 1;
    let tempIus = [];
    $(".cheked_ius").each(function () {
      if ($(this).is(":checked")) {
        tempIus.push($(this).val());
      }
    });
    if (tempIus.length <= 0) {
      this.toastr.error("Please select atleast one indicator");
      return;
    } else {
      this.iusselect = tempIus.join(",");
      this.getRankChart();
    }
  }

  ngAfterViewInit() {
    let that = this;

    $(".timeperiod-list").multiselect({
      numberDisplayed: 1,
      allSelectedText: "All Time Period",
      includeSelectAllOption: true,
      nonSelectedText: "0 Time Period",
      nSelectedText: " Time Periods",
      onSelectAll: function () {
        that.sent_time_period = $(".timeperiod-list").val();

        that.selectionType = 0;

        that.changed_from_to_timeperiod["from"] = "";
        that.changed_from_to_timeperiod["to"] = "";

        that.getRankChart();
      },
      onDeselectAll: function () {
        that.selectionType = 2;
        that.changed_from_to_timeperiod["from"] = "";
        that.changed_from_to_timeperiod["to"] = "";
        that.sent_time_period = "";

        that.getRankChart();
      },
      onChange: function (option, checked, select) {
        that.sent_time_period = $(".timeperiod-list").val();
        that.changed_from_to_timeperiod["from"] = "";
        that.changed_from_to_timeperiod["to"] = "";
        that.selectionType = 0;
        if (that.sent_time_period.length == 0) {
          that.selectionType = 1;
          that.sent_time_period = "mrd";
        }

        that.getRankChart();
      },
    });

    $(document).ready(function () {
      $(".ius_list_0").prop("checked", true);
      $(document).on("click", ".tree-open", function () {
        if ($(this).next("ul").hasClass("in")) {
          $(this).next("ul").removeClass("in pl-3");
          $(this).removeClass("imp_img");
        } else {
          $(this).next("ul").addClass("in pl-3");
          $(this).addClass("imp_img");
        }
      });
    });
  }

  getVizData(vizObj) {
    let vizData = [];

    switch (vizObj["chartType"]) { 
      case "line":
      case "bar":
      case "column":
      case "stackedcolumn":
      case "stackedcolumnpercent":
      case "stackedbar":
      case "stackedbarpercent":
      case "area":
      case "stackedarea":
      case "stackedareapercent":
        vizData["vizFunc"] = "loadColumnChart";
        vizData["data"] = this.columnUseCase[vizObj["funcName"]](vizObj);

        break;
      case "pie":
        vizData["vizFunc"] = "loadPieChart";
        // vizData['data'] = this.pieUseCase[vizObj['funcName']](vizObj);
        break;
      case "treemap":
      case "tree":
        vizData["vizFunc"] = "loadTreeMap";
        // vizData['data'] = this.pieUseCase[vizObj['funcName']](vizObj);
        break;
      case "table":
        vizObj["vizFunc"] = "";
        // vizData['data'] = this.tableUseCase[vizObj['funcName']](vizObj);
        break;
      default:
        break;
    }
    vizData["chartType"] = vizObj["chartType"];
    // vizData['indicator'] = vizObj['indicator'];
    // vizData['unit'] = vizObj['unit'];
    vizData["ius"] = vizObj["ius"];
    vizData["id"] = vizObj["id"];
    vizData["time_period"] = vizObj["time_period"];
    vizData["subgroup"] = vizObj["subgroup"];
    return vizData;
  }

  change_from_to(time_period, type) {
    if (type == "from") {
      this.changed_from_to_timeperiod["from"] = time_period;
    } else if (type == "to") {
      this.changed_from_to_timeperiod["to"] = time_period;
    }
  }

  from_to_button() {
    $(".loaders ol li").fadeIn();
    if (
      this.changed_from_to_timeperiod["from"] == "" ||
      typeof this.changed_from_to_timeperiod["from"] == "undefined" ||
      this.changed_from_to_timeperiod["to"] == "" ||
      typeof this.changed_from_to_timeperiod["to"] == "undefined"
    ) {
      this.toastr.error("Please select From date and To date.");
      $(".loaders ol li").fadeOut();
    } else if (
      this.changed_from_to_timeperiod["from"] >
      this.changed_from_to_timeperiod["to"]
    ) {
      this.toastr.error("To date must be greater than the From date.");
    $(".loaders ol li").fadeOut();
    } else {
      this.selectionType = 0;
      $(".timeperiod-list").multiselect("clearSelection");
      $("#myModal").modal("hide");
      this.getRankChart();
    }
  }

  searchIus(obj) {
    let searchfor = obj.target.value.toLowerCase();

    if (searchfor.length == 0) {
      $(".find-ius li").removeClass("hide in pl-3");
      $(".find-ius ul").removeClass("in pl-3");
      $(".find-ius ul").removeClass("in");
      $(".find-ius ul").parents("ul").removeClass("in");
      return false;
    }

    if (searchfor.length < 3) {
      return false;
    }
    /*$('.search-ius').children('ul').removeClass('in pl-3');
		$('.search-ius').parent('ul').removeClass('in pl-3');
		$('.search-ius').parent().parent().parent('ul').removeClass('in pl-3'); 


		$('.search-ius').each(function(){
			console.log(searchfor+" = ====  "+$(this).data('indicator'));

	        var patt = new RegExp(searchfor.toLowerCase());
	        var res = patt.test($(this).data('indicator').toLowerCase());
	        if(res==true){
	        	$(this).children('ul').addClass('in pl-3');
	        	$(this).parent('ul').addClass('in pl-3');
	        	$(this).parent().parent().parent('ul').addClass('in pl-3');
	        }else{
	        	$(this).children('ul').removeClass('in pl-3');
	        	$(this).parent('ul').removeClass('in pl-3');
	        	$(this).parent().parent().parent('ul').removeClass('in pl-3');         
	        }
		});*/

    $(".find-ius li").addClass("hide");
    $(".find-ius li").filter(function () {
      if ($(this).text().toLowerCase().indexOf(searchfor) > -1) {
        $(this).removeClass("hide").addClass("in pl-3");
        $(this).parents("li").removeClass("hide").addClass("in pl-3");
        $(this).parents("ul").addClass("in");
        $(this).find("ul").addClass("in pl-3");
        $(this).find("li").removeClass("hide");
      }
    });
  }

  addRemoveSelect(event) {
    let checked = event.target.checked;
    if (checked == true) {
      $(this).addClass("selected-ius");
    } else {
      $(this).removeClass("selected-ius");
    }
  }

  selectAllIndicator(event) {
    let checked = event.target.checked;
    if (checked == true) {
      $(".cheked_ius").prop("checked", true).addClass("selected-ius");
    } else {
      $(".cheked_ius").prop("checked", false).removeClass("selected-ius");
    }
  }

  viewSelectedFilter(event) {
    let checked = event.target.checked;

    $('.cheked_ius').each(function(){
    if (checked == true) {
      $(".selected-ius").show();
    } else {
      $(".selected-ius").hide();
    }
    })
  }

  checkedSubgroup(id) {
    if (this.resIus.map(Number).indexOf(id) != -1) {
      return true;
    } else {
      return false;
    }
  }

  downloadChart() {
    this.chartContainer.exportChartLocal({
      type: "image/jpeg",
    });
  }

  toggleGrouping(containerId) {
    $("#loaders_" + containerId + " ol li").fadeIn();
    let newLabels = [];
    let newCategories = [];
    let newData = [];
    /*let swapCardType = $("#viz_container_" + containerId).data('charttype')
	  if (swapCardType == 'treemap' || swapCardType == 'pie' || swapCardType == 'map' || swapCardType == 'pyramid' || swapCardType == 'bubble' || swapCardType == 'table' || swapCardType == '') {
	    $("#loaders_" + containerId + " ol li").fadeOut();
	    this.toastr.error('Swap not allowed for this chart type.');
	    return false;
	  }*/
    var chart = this.chartContainer;
    setTimeout(function () {
      let seriez = chart.series;
      $.each(chart.xAxis[0].categories, function (i, name) {
        newLabels.push(name);
      });
      $.each(seriez, function (x, serie) {
        newCategories.push(serie.name);
        $.each(serie.data, function (z, point) {
          if (newData[z] == undefined) {
            newData[z] = [];
          }
          if (newData[z][x] == undefined) {
            newData[z][x] = "";
          }
          newData[z][x] = point.y == undefined ? "" : point.y;
        });
      });
      while (chart.series.length > 0) {
        chart.series[0].remove(true);
      }
      chart.xAxis[0].setCategories(newCategories, false);
      $.each(newData, function (key, newSeries) {
        chart.addSeries(
          {
            name: newLabels[key],
            data: newSeries,
          },
          false
        );
      });
      chart.redraw();
       $(".loaders ol li").fadeOut();
    }, 0);
  }

}