import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  // public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor() { }

  extractIus(data) {
    let iusDropDown = [];
    if (data.ius_classification.length > 0) {
      for (var i = 0; i < data.ius_classification.length; i++) {
        for (var j = 0; j < data.ius_classification[i].ius.length; j++) {
        
          iusDropDown.push(data.ius_classification[i].ius[j]);
        }
      }
    } else {
      iusDropDown = [];
    }

    return iusDropDown;
  }

  getAreaID() {
    return localStorage.getItem("areaCode")
  }

  getAreaLevel() {
    return localStorage.getItem("areaLevel")
  }

  getAreaName() {
    return localStorage.getItem("areaName")
  }

  covertTimePeriodChar(timePeriod) {
    try {
      if (timePeriod.indexOf('-') > -1) {
        let index = timePeriod.indexOf("-");
        let firstDate = timePeriod.substr(0, index).split(".").reverse().join(".");
        let secondDate = timePeriod.substr(index + 1).split(".").reverse().join(".");
        return (firstDate + "-" + secondDate);
      }
      else {
        var data = timePeriod.split(".").reverse();
        return this.convertToMonth(data[0]) + '-' + data[1];
      }
    } catch (e) {
    }
    return timePeriod;
  }

  covertTimePeriod(timePeriod) {

    if (timePeriod.indexOf('-') > -1) {
      let index = timePeriod.indexOf("-");
      let firstDate = timePeriod.substr(0, index).split(".").reverse().join(".");
      let secondDate = timePeriod.substr(index + 1).split(".").reverse().join(".");
      return (firstDate + "-" + secondDate);
    }
    else {
      return timePeriod.split(".").reverse().join(".")
    }
  }

  getFinancialYear(timePeriod) {
    try {
      if (timePeriod.indexOf('-') > -1) {
        let index = timePeriod.indexOf("-");
        let firstDate = timePeriod.substr(0, index).split(".").reverse().join(".");
        let secondDate = timePeriod.substr(index + 1).split(".").reverse().join(".");
        return (firstDate + "-" + secondDate);
      }
      else {
        var data = timePeriod.split(".").reverse();
        return this.getYear(data[0],data[1]);
      }
    } catch (e) {
    }
    return timePeriod;
  }

  getFinancialFirstYear(timePeriod) {
    try {
      if (timePeriod.indexOf('-') > -1) {
        let index = timePeriod.indexOf("-");
        let firstDate = timePeriod.substr(0, index).split(".").reverse().join(".");
        let secondDate = timePeriod.substr(index + 1).split(".").reverse().join(".");
        return (firstDate + "-" + secondDate);
      }
      else {
        var data = timePeriod.split(".").reverse();
        return this.getFirstYear(data[0],data[1]);
      }
    } catch (e) {
    }
    return timePeriod;
  }

  getFirstYear(month,year){

    let current_month= parseInt(month)

    if(current_month<=3){
      return (parseInt(year)-1)
    }else{
      return year;
    }
  }
  getYear(month,year){

    let current_month= parseInt(month)

    if(current_month<=3){
      return (parseInt(year)-1) +'-'+ year 
    }else{
      return year +'-'+(parseInt(year)+1)
    }
  }


  //Amit Namdeo
  convertToMonth(month) {
    var monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'];
    return monthNames[parseInt(month) - 1].substring(0, 3);
  }

  convertToFullMonth(month) {
    var monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'];
    return monthNames[parseInt(month) - 1];
  }

  covertTimePeriodFullChar(timePeriod) {
    try {
      if (timePeriod.indexOf('-') > -1) {
        let index = timePeriod.indexOf("-");
        let firstDate = timePeriod.substr(0, index).split(".").reverse().join(".");
        let secondDate = timePeriod.substr(index + 1).split(".").reverse().join(".");     
        return (firstDate + " " + secondDate);
      }
      else {
        var data = timePeriod.split(".").reverse();
        return this.convertToFullMonth(data[0]) + ' ' + data[1];
      }
    } catch (e) {
    }
    return timePeriod;
  }



}
