import { Component, OnInit, Input } from "@angular/core";
import { HeaderService } from "src/app/services/header.service";
import { Subscription } from "rxjs";
import { Configuration } from "../../../../app.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: 'app-hmistable',
  templateUrl: './hmistable.component.html',
  styleUrls: ['./hmistable.component.css']
})
export class HmistableComponent implements OnInit {
  @Input() timePeriod: any;
  subscription: Subscription;
  private url: any;
  public penta = [];
  public TD10 = [];
  public TD16 = [];
  public Hep_B = [];
 
  loading =true;
  public mapLevel = 1;
  public source = 1;
  public mapRecentTimePeriod = [];
  PW = {};
  areaID = "IND";
  area_name = "IND";

  constructor(
    private headerService: HeaderService,

    private dataService: ApiService,
    private configuration: Configuration,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.url = configuration.server;

    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "header":
          this.source = 1;
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.getTable();
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {}

  ngOnChanges() {   
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getTable();
  }

  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodChar(timePeriod);
  }
  getFinancialYear(timePeriod) {
    return this.commonService.getFinancialYear(timePeriod);
  }
  getRoundValue(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  getPerValue(numerator, denominator) {
    if(denominator == 0){
      return 0
    }
    var value = this.getRoundValue((numerator / denominator) * 100, 1);
    if (value) {
      return value;
    }   
    return 0;
  }

  getTable() {
    let that = this;
    this.dataService
      .getAll("api/getHMISTable1/" + this.timePeriod + "/" + this.areaID + "/")
      .subscribe((response: any) => {
        that.loading =false;
        that.penta = response.data;
        that.mapRecentTimePeriod = response.time_period;
        that.area_name = that.penta[0].area_name;
        response.PW.forEach((row) => {
          that.PW[row.area_name] = row.target;
        });
        let TD10 = [];
        let TD16 = [];
        let Hep_B = [];

        that.penta.forEach((row) => {
          TD10.push([
            row.area_name,
            that.getPerValue(row.TD10_num, row.TD10_dem),
            row.area_code,
            'Td1 Coverage'
          ]);
          TD16.push([
            row.area_name,
            that.getPerValue(row.TD16_num, row.TD16_dem),
            row.area_code,'Td2 Coverage'
          ]);
          Hep_B.push([
            row.area_name,
            that.getPerValue(row.Hep_B_num, row.Hep_B_dem),
            row.area_code,'% Hep B Birth Dose'
          ]);         
        });
        this.TD10 = TD10;
        this.TD16 = TD16;
        this.Hep_B = Hep_B;
       
        if (!that.timePeriod || that.timePeriod == "mrd") {
          that.timePeriod = this.mapRecentTimePeriod[0].time_period;
        }
      });
  }
  arrpw: any;
  getpw(area_name) {
    if (this.PW[area_name]) {
      this.arrpw = this.PW[area_name];
      return this.arrpw;
    } else {
      return "NA";
    }
  }
  getObjectKey(object) {
    if (object) {
      return Object.keys(object);
    }
    return [];
  }
}
