import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { HeaderService } from "src/app/services/header.service";
import { HttpClient } from "@angular/common/http";
import { _ } from "underscore";

import { Subscription } from "rxjs";
import { Configuration } from "../../../../app.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-mr-page2",
  templateUrl: "./mr-page2.component.html",
  styleUrls: ["./mr-page2.component.css"],
})
export class MrPage2Component implements OnInit {
  @Input() timePeriod: any;

  subscription: Subscription;
  private url: any;
  areaID = "IND";
  area_name = "";
  // timePeriod: any;
  public mapRecentTimePeriod = [];
  public categories = [];
  public series = [];

  public categories2 = [];
  public series2 = [];

  public categories3 = [];
  public series3 = [];

  public categories4 = [];
  public series4 = [];

  public mr1hmis;
  public mr2hmis;
  public mr1nfhs;
  public mapLevel = 1;
  loading=true;
  constructor(
    private headerService: HeaderService,
    private configuration: Configuration,
    private dataService: ApiService,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.url = configuration.server;
    // this.penta=[];
    this.subscription = this.shareData.getData().subscribe((message) => {
      //  console.log(message);
      switch (message.data.ctype) {
        case "header":
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.area_name = message.data.data.name;
          this.getGraphData();
          break;
        default:
          break;
      }
    });
  }
  ngOnInit() {}

  ngOnChanges() {
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getGraphData();
  }
  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodChar(timePeriod);
  }
  changeTimePeriod(event, type) {
    this.timePeriod = event.value;
    this.getGraphData();
  }
  getValue(data, indicator, area_name) {
    let value = "0";
    data.forEach((row) => {
      if (row.indicator == indicator && row.area_name == area_name) {
        value = row.value;
        return;
      }
    });
    return parseFloat(value);
  }

  getSeries(data, indicators, category) {
    let that = this;
    let series = [];
    indicators.forEach((indicator) => {
      let temp = [];
      category.forEach((area_name) => {
        temp.push(that.getValue(data, indicator, area_name));
      });
      series.push({
        name: indicator,
        data: temp,
      });
    });

    return series;
  }
  getnumValue(data, timePeriod, area_name) {
    let value = "0";
    data.forEach((row) => {
      if (row.time_period == timePeriod && row.area_name == area_name) {
        value = row.value;
        return;
      }
    });
    return parseFloat(value);
  }

  getnumSeries(data, indicators, category) {
    let that = this;
    let series = [];
    indicators.forEach((timePeriod) => {
      let temp = [];
      category.forEach((area_name) => {
        temp.push(that.getnumValue(data, timePeriod, area_name));
      });
      series.push({
        name: timePeriod,
        data: temp,
      });
    });

    return series;
  }
  getGraphData() {
    let that = this;

    this.dataService
      .getAll("api/page72/" + this.timePeriod + "/" + this.areaID + "/")
      .subscribe((response: any) => {
        this.loading=false;
        this.mapRecentTimePeriod = response.time_period;

        // graph 1
        let category = [];
        let indicators = [];
        response.datanfhs.forEach((row) => {
          if (category.indexOf(row.area_name) == -1) {
            category.push(row.area_name);
          }
          if (indicators.indexOf(row.time_period) == -1) {
            indicators.push(row.time_period);
          }
        });
        that.series = that.getnumSeries(
          response.datanfhs,
          indicators,
          category
        );
        that.categories = category;

        // graph 2
        let category3 = [];
        let indicators3 = [];
        response.datamr2nfhs.forEach((row) => {
          if (category3.indexOf(row.area_name) == -1) {
            category3.push(row.area_name);
          }
          if (indicators3.indexOf(row.time_period) == -1) {
            indicators3.push(row.time_period);
          }
        });
        that.series3 = that.getnumSeries(
          response.datamr2nfhs,
          indicators3,
          category3
        );
        that.categories3 = category3;

        // graph 3
        let category2 = [];
        let indicators2 = [];
        response.data.forEach((row) => {
          if (category2.indexOf(row.area_name) == -1) {
            category2.push(row.area_name);
          }
          if (indicators2.indexOf(row.indicator) == -1) {
            indicators2.push(row.indicator);
          }
        });
        that.series2 = that.getSeries(response.data, indicators2, category2);
        that.categories2 = category2;

        that.mr2hmis = that.series2[0].data[0]
        that.mr1hmis = that.series2[1].data[0]
        that.mr1nfhs= that.series[1].data[0];
        if (!that.timePeriod || that.timePeriod == "mrd") {
          that.timePeriod = this.mapRecentTimePeriod[0].time_period;
        }
      });
  }
}
