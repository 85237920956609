import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { HeaderService } from "src/app/services/header.service";
import { HttpClient } from "@angular/common/http";
import { _ } from "underscore";

import { Subscription } from "rxjs";
import { Configuration } from "../../../../app.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-graph-chart-page2",
  templateUrl: "./graph-chart-page2.component.html",
  styleUrls: ["./graph-chart-page2.component.css"],
})
export class GraphChartPage2Component implements OnInit {
  @Input() timePeriod: any;
  subscription: Subscription;
  private url: any;
  areaID = "IND";
  area_name = "";
  // timePeriod: any;
  public mapRecentTimePeriod = [];
  public categories = [];
  public series = [];

  public categories2 = [];
  public series2 = [];

  public categories3 = [];
  public series3 = [];

  public categories4 = [];
  public series4 = [];
  loading=true;
  public mapLevel = 1;
  constructor(
    private headerService: HeaderService,
    private configuration: Configuration,
    private dataService: ApiService,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.url = configuration.server;

    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "header":
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.area_name = message.data.data.name;
          this.getGraphData();
          break;
        default:
          break;
      }
    });
  }
  ngOnInit() {}

  ngOnChanges() {
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getGraphData();
  }
  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodChar(timePeriod);
  }
  changeTimePeriod(event, type) {
    this.timePeriod = event.value;
    this.getGraphData();
  }

  getnumValue(data, timePeriod, area_name) {
    let value = "0";
    data.forEach((row) => {
      if (row.financial_year == timePeriod && row.month == area_name) {
        value = row.value;

        return;
      }
    });
    return parseFloat(value);
  }

  hearderTime: String;
gettime(){
  let that = this;
  let time = [];
  that.mapRecentTimePeriod.forEach((row) => {
    time.push(row +"-"+ ++row)
  });
  that.hearderTime = time.toString();
}

  getnumSeries(data, indicators, category) {
    let that = this;
    let series = [];
    that.mapRecentTimePeriod = indicators
    that.gettime();
    indicators.forEach((timePeriod) => {
      let temp = [];
      category.forEach((area_name) => {
        temp.push(that.getnumValue(data, timePeriod, area_name));
      });
      series.push({
        name: timePeriod,
        data: temp,
      });
    });

    return series;
  }
  getGraphData() {
    let that = this;
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ]
    this.dataService
      .getAll("api/page24/" + this.timePeriod + "/" + this.areaID + "/")
      .subscribe((response: any) => {

        this.loading=false;
        let category = [];
        let indexlabel =[];
        let indicators = [];
        response.data.forEach((row) => {

          if (indexlabel.indexOf(monthNames[row.month - 1]) == -1) {
            indexlabel.push(monthNames[row.month - 1]);
          }
          if (category.indexOf(row.month) == -1) {
            category.push(row.month);
          }
          if (indicators.indexOf(row.financial_year) == -1) {
            indicators.push(row.financial_year);
          }
        });
        that.series = that.getnumSeries(response.data, indicators, category);
        that.categories = indexlabel;
      });
  }
}
