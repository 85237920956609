import { ApiService } from "../../services/api.service";
import {
  Component,
  ViewChild,
  OnInit,
  QueryList,
  ViewChildren
} from "@angular/core";
import {
  MatPaginator,
  MatSort,
  MatTableDataSource,
  MatDialog
} from "@angular/material";
import { HeaderService } from "../../services/header.service";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormGroupDirective
} from "@angular/forms";
import { DialogMaterialComponent } from "../../dialog-material/dialog-material.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { SelectionModel } from "@angular/cdk/collections";
import { SharedataService } from "../../services/sharedata.service";
import { DatatableComponent } from "@swimlane/ngx-datatable";
declare var $: any;

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.css"]
})
export class GalleryComponent implements OnInit {
  displayedColumns = ["select", "s_no", "name", "date_time", "visualizations"];
  displayedColumnsIndicator = ["indicator", "showhide"];

  galleryadd: FormGroup;
  submitted = false;
  tabs = ["Coverage", "Communication", "Cold Chain"];
  indicators = [];
  dataSource: MatTableDataSource<any>;
  dataSourceIndicator: MatTableDataSource<any>;
  form_open: boolean = false;
  showEdit: boolean = false;
  titlePage: string = "Add";
  validationError: any;
  selection = new SelectionModel<any>(true, []);
  galleryRowId;
  setIndicatorListDataSet = [];
  selectedRows: any = [];
  selectedItems = [];

  rows: any = [];
  columns = [
    {
      name: "S.No",
      prop: "id"
    },
    {
      name: "Name",
      prop: "name"
    },
    {
      name: "Created On",
      prop: "created_at"
    },
    {
      name: "Options"
    }
  ];

  filterData: any = [];

  @ViewChildren("allIndicators") all_indicators: QueryList<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(
    private dataService: ApiService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private headerService: HeaderService,
    private toastr: ToastrService,
    private router: Router,
    private shareData: SharedataService
  ) {}

  ngOnInit() {
    $(document).on("click", ".dir", function() {
      $(this)
        .next("ul")
        .slideToggle();
    });


    (this.isAdmin() ? this.headerService.setTitle("Report Gallery Management") :
    this.headerService.setTitle("Report Gallery"))
    this.headerService.setLogo(
      "assets/lib/images/svg/header-menu/gallery-color.svg"
    );
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("need-head");
    this.galleryadd = this.formBuilder.group({
      gallery_name: ["", Validators.required]
    });
    this.loadGallery();
  }

  loadGallery() {

    $(".loaders ol li").fadeIn();
    this.dataService.getAll<any[]>("api/gallery/all").subscribe(
      (res: any) => {
        let tempArr = [];
        $(".loaders ol li").fadeOut();

        this.filterData = [...res.data.records];
        this.rows = res.data.records;
        this.rows.forEach(element => {
          element.showSaveIcon = false;
        });
      },
      error => () => {
        console.log("Error");
      },
      () => {}
    );
  }

  deleteGallery(id) {
    const dialog = this.dialog.open(DialogMaterialComponent, {
      width: "350px",
      data: {
        messageDialog: "Are you sure to delete the gallery?",
        delete: true
      }
    });
    dialog.afterClosed().subscribe(selection => {
      if (selection) {
        this.dataService.delete<any[]>("api/gallery/delete", id).subscribe(
          (res: any) => {
            this.toastr.success("Gallery deleted successfully");
            this.loadGallery();
          },
          error => () => {
            console.log("Error");
          },
          () => {}
        );
      } else {
      }
    });
  }

  addGalleryData(obj) {
    this.validationError = "";

    this.dataService
      .add<any[]>("api/gallery/addnew", obj)
      .subscribe((res: any) => {
        if (res.status == 0) {
          this.toastr.error(res.error);
          this.validationError = res.error;
        } else {
          this.toastr.success("Gallery added successfully");
          // reset the form after submitting data
          this.galleryadd.reset();
          document.getElementById("close-button").click();
          setTimeout(() => {
            this.form_open = !this.form_open;
          }, 1000);
          this.loadGallery();
        }
      });

    // obj.galleryadd
  }
  updategallery(formval) {
    console.log(formval, "xcbcv");
    this.dataService
      .update("api/gallery/update", this.galleryRowId, formval)
      .subscribe((response: any) => {
        this.toastr.success("Gallery updated successfully");
        // reset the form after submitting data
        this.galleryadd.reset();
        document.getElementById("edit-close-button").click();
        // setTimeout(() => {
        //   this.form_open = !this.form_open;
        // }, 2000);
        //function to get the list of users after adding a user
        
        setTimeout(() => {
          this.form_open = !this.form_open;
        }, 1000);
        this.loadGallery();
      });
  }

  ngAfterViewInit() {
    console.log(this.all_indicators, "xcbvcb");
    this.all_indicators.changes.subscribe(t => {
      this.ngForRendred();
    });


    $('.clearAddForm').on('click',function(){
      $('#addNewGallery').val('');
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  getAllIndicators() {
    this.shareData.getAll("api/get-ius-list/1").subscribe((res: any) => {
      this.setIndicatorListDataSet = res.data;
    });
  }
  updateIndicatorStatus(id) {
    
    this.dataService
      .update<any[]>("api/gallery/updateindicatorstatus", id, status)
      .subscribe(
        (res: any) => {
          this.toastr.success("Indicator status updated successfully.");
          // this.getAllIndicators();
        },
        error => () => {
          console.log("Error");
        },
        () => {}
      );
  }
  /**
   * function to open the form popup to edit the selected the user
   * @param id // id of selected user
   */
  openForm(rowData) {
    this.rows.forEach(element => {
      element.showSaveIcon = false;
    });
    rowData.showSaveIcon = true;

    this.titlePage = "Edit";
    $("gal_name_" + rowData.id).val(rowData.name);

    this.galleryRowId = rowData.id;
    this.showEdit = true;
  }

  navigateByUrl(row) {
    this.router.navigate([
      "gallery-management/visualization/",
      row.id,
      row.name
    ]);
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  onSelect({ selected }) {
    this.selectedItems = [];
    this.selectedItems.splice(0, this.selectedItems.length);
    if (selected.length == 1) {
      this.selectedItems.push(selected[0].id);
    } else if (selected.length > 1) {
      for (let i = 0; i < selected.length; i++) {
        this.selectedItems.push(selected[i].id);
      }
    }
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    const temp = this.filterData.filter(function(d) {
      return Object.keys(d).some(function(key) {
        if (d[key] != null) {
          return (
            d[key]
              .toString()
              .toLowerCase()
              .indexOf(val) !== -1 || !val
          );
        }
      });
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }
  // public confirmDeleteAll() {
  //   let selectedRows = {
  //     selectedIds: this.selectedItems
  //   };
  //   const dialog = this.dialog.open(DialogMaterialComponent, {
  //     width: "350px",
  //     data: {
  //       messageDialog: "Are you sure you want to delete all selected records ?",
  //       delete: true
  //     }
  //   });
  //   dialog.afterClosed().subscribe(selection => {
  //     if (selection) {
  //       this.dataService
  //         .add("api/user/delete-all-user", selectedRows)
  //         .subscribe(
  //           (response: any) => {
  //             if (response.status == 1) {
  //               this.toastr.success("record deleted successfully");
  //             }
  //             this.selectedItems = [];
  //             setTimeout(() => {
  //               this.ngOnInit();
  //             }, 200);
  //           },
  //           (error: any) => {
  //             console.log(error);
  //           }
  //         );
  //     } else {
  //       // User clicked 'Cancel' or clicked outside the dialog
  //     }
  //   });
  // }
  ngForRendred() {
    $("#list_tab_0").trigger("click");
  }
  // onFocus() {

  // 	this.showSaveIcon = true;
  // }

  onBlur(rowData) {
    rowData.showSaveIcon = false;
  }
  isAdmin(): boolean {
    let authDetails = JSON.parse(localStorage.getItem("user_details"));
    return authDetails.role == 1 ? true : false;
  }


}
