import { Component, Input, AfterViewInit } from "@angular/core";
import { HeaderService } from "src/app/services/header.service";
import { Subscription } from "rxjs";
import { Configuration } from "../../../../app.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: 'app-district-count-table',
  templateUrl: './district-count-table.component.html',
  styleUrls: ['./district-count-table.component.css']
})
export class DistrictCountTableComponent implements AfterViewInit {

  @Input() timePeriod: any;
  subscription: Subscription;
  private url: any;

  public penta = [];
  public mapLevel = 1;
  public source = 1;
  loading=true;
  areaID = "IND";

  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private configuration: Configuration,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.url = configuration.server;
    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "header":
          this.source = 1;
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.getTable();
          break;
        default:
          break;
      }
    });
  }

  ngOnChanges() {
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getTable();
  }
  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodChar(timePeriod);
  }

  getTable() {
    let that = this;
    this.dataService
      .getAll("api/getdistrictCount/" + this.timePeriod + "/")
      .subscribe((response: any) => {
        that.loading =false;
        that.penta = response.data;    
      });
  }
  ngAfterViewInit(): void {}
}
