import { Component, Input, Injectable, AfterViewInit } from "@angular/core";
import { HeaderService } from "src/app/services/header.service";
import { Subscription } from "rxjs";
import { Configuration } from "../../../../app.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-comparisan-table",
  templateUrl: "./comparisan-table.component.html",
  styleUrls: ["./comparisan-table.component.css"],
})
export class ComparisanTableComponent implements AfterViewInit {
  @Input() timePeriod: any;
  subscription: Subscription;
  private url: any;

  public penta = [];
  public mapLevel = 1;
  public source = 1;
  ficnfhs = {};
  mr1nfhs = {};
  mr2nfhs = {};
  BCGnfhs = {};
  Penta3nfhs={};
  loading=true;
  areaID = "IND";

  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private configuration: Configuration,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.url = configuration.server;
    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "header":
          this.source = 1;
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.getTable();
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {}

  ngOnChanges() {
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getTable();
  }
  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodChar(timePeriod);
  }

  getFinancialYear(timePeriod) {
    return this.commonService.getFinancialYear(timePeriod);
  }
  getRoundValue(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  getPerValue(numerator, denominator) {
    var value = this.getRoundValue((numerator / denominator) * 100, 1);
    if (value) {
      return value;
    }
    return 0;
  }

  getTable() {
    let that = this;
    this.dataService
      .getAll("api/getNFHS5/" + this.timePeriod + "/" + this.areaID + "/")
      .subscribe((response: any) => {

        this.loading=false;
        that.penta = response.data;
        response.mr1nfhs.forEach((row) => {
          that.mr1nfhs[row.area_name] = row.value;
        });

        response.mr2nfhs.forEach((row) => {
          that.mr2nfhs[row.area_name] = row.value;
        });

        response.ficnfhs.forEach((row) => {
          that.ficnfhs[row.area_name] = row.value;
        });

        response.BCGnfhs.forEach((row) => {
          that.BCGnfhs[row.area_name] = row.value;
        });

        response.Penta3nfhs.forEach((row) => {
          that.Penta3nfhs[row.area_name] = row.value;
        });
      });
  }
  getfic_nfhs(area_name) {
    if (this.ficnfhs[area_name]) {
      return this.ficnfhs[area_name];
    }
    return "NA";
  }
  getmr1_nfhs(area_name) {
    if (this.mr1nfhs[area_name]) {
      return this.mr1nfhs[area_name];
    }
    return "NA";
  }
  getmr2_nfhs(area_name) {
    if (this.mr2nfhs[area_name]) {
      return this.mr2nfhs[area_name];
    }
    return "NA";
  }
  getbcg_nfhs(area_name) {
    if (this.BCGnfhs[area_name]) {
      return this.BCGnfhs[area_name];
    }
    return "NA";
  }
  getpenta_nfhs(area_name) {
    if (this.Penta3nfhs[area_name]) {
      return this.Penta3nfhs[area_name];
    }
    return "NA";
  }
  getObjectKey(object) {
    if (object) {
      return Object.keys(object);
    }
    return [];
  }
  ngAfterViewInit(): void {}
}
