import { Injectable } from '@angular/core';
import { _ } from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class GetCaseColumnService {
  xaxis = [];
  seriesData = [];
  seriesTemp = [];
  seriesName = [];

  constructor(
  ) { }
  // this.getCaseColumnService[func](data, indicator, unit, subgroup, area, time_period);
  public columnCase1<T>(objData): any {
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];
    let sum;
    let returnData;
    this.seriesData = [];
    this.xaxis = [];
    this.xaxis.push(area[0]);
    returnData = data.filter(item => item.area_name == area[0] && item.ius == ius[0] && item.time_period == time_period[0]);
    if (returnData.length >= 1) {
      returnData.forEach(element => {
        sum = Number(element.value);
        this.seriesData.push({ name: time_period[0], data: [sum] });
      });

    }
    return { series: this.seriesData, xaxis: this.xaxis };
  }
  public columnCase2<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];
    let arr = [];
    for (var i = 0; i < time_period.length; i++) {
      var exist = 0;
      var area_name;
      var sum;
      for (var j = 0, len = data.length; j < len; j++) {
        if (data[j].area_name == area[0]
          && data[j].ius == ius
          && data[j].time_period == time_period[i]) {

          exist = 1;//return myArray[i]; // Return as soon as the object is found
          break;
        }
      }
      this.xaxis.push(time_period[i]);
      if (exist) {
        if (data[j].value != null && data[j].value != '') {
          sum = Number(data[j].value);
        } else {
          sum = null;
        }
      }

      // arr.push(sum);
      arr.push({name:time_period[i],y:sum});
    }
    this.seriesData.push({ name: area[0], data: arr });
    return { series: this.seriesData, xaxis: this.xaxis };

  }


  public columnCase3<T>(objData: any) {

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];
    let valueType = objData['type'];
    this.xaxis = [];
    this.seriesData = [];
    //create empty array for dataset

    for (var i = 0; i < area.length; i++) {
      var exist = 0;
      var area_name = area[i];
      var sum;
      // this.xaxis.push(area[i]);
      for (var j = 0, len = data.length; j < len; j++) {
        if (data[j].area_name == area[i] && data[j].ius == ius[0] && data[j].time_period == time_period[0]) {
          // this.xaxis.push( area[i]);
          exist = 1;//return myArray[i]; // Return as soon as the object is found

          break;
        }
      }
      //copy empty array
      if (exist) {
        // this.xaxis.push( area[i])
         if(valueType == 'percent'){
        if (data[j].value != null && data[j].value != '') {
          sum = Number(data[j].value);
            area_name = data[j].area_name

        } else {
          sum = null;
        }
        }else {
          if (data[j].numerator != null && data[j].numerator != '') {
            sum = Number(data[j].numerator);
            area_name = data[j].area_name
          } else {
            sum = null;
          }
        }
       // tempData.push({name:data[j].area_name, y:Number(data[j].numerator),value:Number(data[j].numerator)});
        //$arr.push(sum);
      }
      this.xaxis.push(area_name);
      this.seriesData.push({name:area_name,y:sum});
    };
    return { series: [{ name: time_period[0], data: this.seriesData,  }], xaxis: this.xaxis };
  }

  public columnCase4<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    let valueType = objData['type'];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];
    
    for (let i = 0; i < area.length; i++) {
      let mData = [];
      for (let k = 0; k < time_period.length; k++) {
        if (this.xaxis.indexOf(time_period[k]) == -1) {
          this.xaxis.push(time_period[k]);
        }
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++) {

          if (data[j].area_name == area[i]
            && data[j].ius == ius
            && data[j].time_period == time_period[k]) {
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }

        if (exist) {
         if(valueType == 'percent'){
          if (data[j].value != null && data[j].value != '') {
            mData.push(Number(data[j].value));
          } else {
            mData.push(null);
          }
        }else{
          if (data[j].numerator != null && data[j].numerator != '') {
            mData.push(Number(data[j].numerator));
          } else {
            mData.push(null);
          }
        }
        }
        else {
          mData.push(null);
        }


      }
      this.seriesData.push({ name: area[i], data: mData });
    }

    this.xaxis.sort();
    return { series: this.seriesData, xaxis: this.xaxis };
  }
  public columnCase5<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    let ind_unit = [];
    let ind_unit_subgroup = [];

    for (let l = 0; l < ius.length; l++) {

      var ius_spilt = ius[l].split('|');

      if (ind_unit.indexOf(ius_spilt[0] + '|' + ius_spilt[1]) == -1) {
        ind_unit.push(ius_spilt[0] + '|' + ius_spilt[1]);
      }
      if (ind_unit_subgroup.indexOf(ius_spilt[2])) {
        ind_unit_subgroup.push(ius_spilt[2]);
      }
    }
    this.xaxis.push(area[0]);
    let a = area[0];
    let y = time_period[0];
    if (ind_unit.length == 1 && ind_unit_subgroup.length > 1) {
      for (var i = 0; i < ind_unit_subgroup.length; i++) {
        var exist = 0;
        var value = 0;
        for (let j = 0, len = data.length; j < len; j++) {
          if (data[j].area_name == a && data[j].ius == ind_unit + '|' + ind_unit_subgroup[i] && data[j].time_period == y) {
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            value = data[j].value;
            break;
          }
        }
        var array = ind_unit_subgroup[i];
        if (exist) {
          this.seriesData.push({ name: array, data: [Number(value)] });
        }
      }
    }
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase6<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var ind_unit = [];
    var ind_unit_subgroup = [];
    var area_length = area.length;

    for (let l = 0; l < ius.length; l++) {
      var ius_spilt = ius[l].split('|');
      if (ind_unit.indexOf(ius_spilt[0] + '|' + ius_spilt[1]) == -1) {
        ind_unit.push(ius_spilt[0] + '|' + ius_spilt[1]);
      }
      if (ind_unit_subgroup.indexOf(ius_spilt[2])) {
        ind_unit_subgroup.push(ius_spilt[2]);
      }
    }
    let y = time_period[0];
    if (ind_unit.length == 1 && ind_unit_subgroup.length > 1) {
      for (var i = 0; i < ind_unit_subgroup.length; i++) {
        let tempData = [];
        var array = ind_unit_subgroup[i];
        for (let m = 0; m < area.length; m++) {
          if (this.xaxis.indexOf(area[m]) == -1) {
            this.xaxis.push(area[m]);
          }
          let exist = 0;
          for (var j = 0, len = data.length; j < len; j++) {
            if (data[j].area_name == area[m] && data[j].ius == ind_unit + '|' + ind_unit_subgroup[i] && data[j].time_period == y) {
              exist = 1;//return myArray[i]; // Return as soon as the object is found
              break;
            }
          }
          if (exist) {
            tempData.push(Number(data[j].value));
          }
        }
        this.seriesData.push({ name: array, data: tempData });
      }
    }
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase7<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var ind_unit = [];
    var ind_unit_subgroup = [];

    var area_length = area.length;
    var time_period_length = time_period.length;

    for (let l = 0; l < ius.length; l++) {
      var ius_spilt = ius[l].split('|');
      if (ind_unit.indexOf(ius_spilt[0] + '|' + ius_spilt[1]) == -1) {
        ind_unit.push(ius_spilt[0] + '|' + ius_spilt[1]);
      }
      if (ind_unit_subgroup.indexOf(ius_spilt[2])) {
        ind_unit_subgroup.push(ius_spilt[2]);
      }
    }
    for (let i = 0; i < ind_unit_subgroup.length; i++) {
      let tempData = [];
      for (let m = 0; m < area.length; m++) {
        for (let k = 0; k < time_period.length; k++) {
          if (this.xaxis.indexOf(area[m] + ', ' + time_period[k]) == -1) {
            this.xaxis.push(area[m] + ', ' + time_period[k]);
          }
          let exist = 0;
          for (var j = 0, len = data.length; j < len; j++) {
            if (data[j].area_name == area[m] && data[j].ius == ind_unit + '|' + ind_unit_subgroup[i] && data[j].time_period == time_period[k]) {
              exist = 1;//return myArray[i]; // Return as soon as the object is found
              break;
            }
          }
          if (exist) {
            tempData.push(Number(data[j].value));
          } else {
            tempData.push('');
          }
        }
      }
      var array = ind_unit_subgroup[i];
      this.seriesData.push({ name: array, data: tempData });
    }
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase8<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    for (let i = 0; i < ius.length; i++) {
      let tempData = [];
      let val = ius[i];
      for (let m = 0; m < area.length; m++) {
        for (let k = 0; k < time_period.length; k++) {
          if (this.xaxis.indexOf(area[m] + ', ' + time_period[k]) == -1) {
            this.xaxis.push(area[m] + ', ' + time_period[k]);
          }
          let exist = 0;
          for (var j = 0, len = data.length; j < len; j++) {
            if (data[j].area_name == area[m] && data[j].ius == val && data[j].time_period == time_period[k]) {
              exist = 1;//return myArray[i]; // Return as soon as the object is found
              break;
            }
          }
          if (exist) {
            tempData.push(Number(data[j].value));
          } else {
            tempData.push('');
          }
        }
      }
      var array = val.split('|');
      this.seriesData.push({ name: array[0], data: tempData });
    }
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase9<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');
    let subgroup = array.pop();

    var indicator_table = [];
    var unit_table = [];
    var subgroup_table = [];
    var main_iu = [];
    var main_ius = [];

    for (let l = 0; l < ius.length; l++) {
      var ius_spilt = ius[l].split('|');
      if (main_iu.indexOf(ius_spilt[0] + '|' + ius_spilt[1])) {
        main_iu.push(ius_spilt[0] + '|' + ius_spilt[1]);
      }
      if (main_ius.indexOf(ius_spilt[2])) {
        main_ius.push(ius_spilt[2]);
      }
    }

    for (var i = 0; i < main_iu.length; i++) {
      let tempData = [];
      for (let m = 0; m < area.length; m++) {
        for (let k = 0; k < time_period.length; k++) {
          if (this.xaxis.indexOf(area[m] + ', ' + time_period[k]) == -1) {
            this.xaxis.push(area[m] + ', ' + time_period[k]);
          }
          let exist = 0;
          for (var j = 0, len = data.length; j < len; j++) {
            if (data[j].area_name == area[m] && data[j].ius == main_iu[i] + '|' + subgroup && data[j].time_period == time_period[k]) {
              exist = 1;//return myArray[i]; // Return as soon as the object is found
              break;
            }
          }
          if (exist) {
            tempData.push(Number(data[j].value));
          } else {
            tempData.push('');
          }
        }
      }
      this.seriesData.push({ name: main_iu[i], data: tempData });
    }

    console.log(this.seriesData, this.xaxis)
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase10<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    for (let i = 0; i < ius.length; i++) {
      let tempData = [];
      let val = ius[i];
      for (let m = 0; m < area.length; m++) {
        for (let k = 0; k < time_period.length; k++) {
          if (this.xaxis.indexOf(area[m] + ', ' + time_period[k]) == -1) {
            this.xaxis.push(area[m] + ', ' + time_period[k]);
          }
          let exist = 0;
          for (var j = 0, len = data.length; j < len; j++) {
            if (data[j].area_name == area[m] && data[j].ius == val && data[j].time_period == time_period[k]) {
              exist = 1;//return myArray[i]; // Return as soon as the object is found
              break;
            }
          }
          if (exist) {
            tempData.push(Number(data[j].value));
          } else {
            tempData.push('');
          }
        }
      }
      this.seriesData.push({ name: val, data: tempData });
    }
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase11<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');
    let subgroup = array.pop();

    var indicator_table = [];
    var unit_table = [];
    var subgroup_table = [];
    var main_iu = [];
    var main_ius = [];

    for (let l = 0; l < ius.length; l++) {
      var ius_spilt = ius[l].split('|');
      if (main_iu.indexOf(ius_spilt[0] + '|' + ius_spilt[1])) {
        main_iu.push(ius_spilt[0] + '|' + ius_spilt[1]);
      }
      if (main_ius.indexOf(ius_spilt[2])) {
        main_ius.push(ius_spilt[2]);
      }
    }

    for (let i = 0; i < main_iu.length; i++) {
      let tempData = [];
      for (let m = 0; m < area.length; m++) {
        for (let k = 0; k < time_period.length; k++) {
          if (this.xaxis.indexOf(area[m] + ', ' + time_period[k]) == -1) {
            this.xaxis.push(area[m] + ', ' + time_period[k]);
          }
          let exist = 0;
          for (var j = 0, len = data.length; j < len; j++) {
            if (data[j].area_name == area[m] && data[j].ius == main_iu[i] + '|' + subgroup && data[j].time_period == time_period[k]) {
              exist = 1;//return myArray[i]; // Return as soon as the object is found
              break;
            }
          }
          if (exist) {
            tempData.push(Number(data[j].value));
          } else {
            tempData.push('');
          }
        }
      }
      var unit_split = main_iu[i];
      this.seriesData.push({ name: unit_split, data: tempData });
    }

    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase12<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');
    let subgroup = array.pop();

    var indicator_table = [];
    var unit_table = [];
    var subgroup_table = [];
    var main_iu = [];
    var main_ius = [];

    for (let l = 0; l < ius.length; l++) {
      var ius_spilt = ius[l].split('|');
      if (main_iu.indexOf(ius_spilt[0] + '|' + ius_spilt[1])) {
        main_iu.push(ius_spilt[0] + '|' + ius_spilt[1]);
      }
      if (main_ius.indexOf(ius_spilt[2])) {
        main_ius.push(ius_spilt[2]);
      }
    }

    for (var i = 0; i < main_iu.length; i++) {
      let tempData = [];
      for (let k = 0; k < time_period.length; k++) {
        if (this.xaxis.indexOf(time_period[k]) == -1) {
          this.xaxis.push(time_period[k]);
        }
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++) {
          if (data[j].area_name == area[0] && data[j].ius == main_iu[i] + '|' + subgroup && data[j].time_period == time_period[k]) {
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if (exist) {
          tempData.push(Number(data[j].value));
        } else {
          tempData.push('');
        }
      }
      var unit_split = main_iu[i];
      this.seriesData.push({ name: unit_split, data: tempData });
    }

    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase13<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');
    let subgroup = array.pop();

    var indicator_table = [];
    var unit_table = [];
    var subgroup_table = [];
    var main_iu = [];
    var main_ius = [];

    for (let l = 0; l < ius.length; l++) {
      var ius_spilt = ius[l].split('|');
      if (main_iu.indexOf(ius_spilt[0] + '|' + ius_spilt[1])) {
        main_iu.push(ius_spilt[0] + '|' + ius_spilt[1]);
      }
      if (main_ius.indexOf(ius_spilt[2])) {
        main_ius.push(ius_spilt[2]);
      }
    }

    for (var i = 0; i < main_iu.length; i++) {
      let tempData = [];
      if (this.xaxis.indexOf(area[0]) == -1) {
        this.xaxis.push(area[0]);
      }
      let exist = 0;
      for (var j = 0, len = data.length; j < len; j++) {
        if (data[j].area_name == area[0] && data[j].ius == main_iu[i] + '|' + subgroup && data[j].time_period == time_period[0]) {
          exist = 1;//return myArray[i]; // Return as soon as the object is found
          break;
        }
      }
      if (exist) {
        tempData.push(Number(data[j].value));
      } else {
        tempData.push('');
      }

      var unit_split = main_iu[i];
      this.seriesData.push({ name: unit_split, data: tempData });
    }

    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase14<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    this.seriesTemp = [];
    this.seriesName = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');

    let select_unit = array[1];
    let subgroup = array[2];

    let select_time = time_period[0];

    var main_ius = [];


    for (let i = 0; i < ius.length; i++) {
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];

      if (main_ius.indexOf(ius[i]) == -1) {
        main_ius.push(ius[i]);
      }
    }

    for (let m = 0; m < area.length; m++) {
      if (this.seriesName.indexOf(area[m]) == -1) {
        this.seriesName.push(area[m]);
      }
      for (var k = 0; k < main_ius.length; k++) {
        var array = main_ius[k].split('|');
        if (this.xaxis.indexOf(array[0]) == -1) {
          this.xaxis.push(array[0]);
        }
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++) {
          if (data[j].area_name == area[m] && data[j].ius == main_ius[k] && data[j].time_period == select_time) {
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if (exist) {
          this.seriesTemp.push({ axis: array[0] + ', ' + area[m], name: area[m], value: Number(data[j].value), area_name: area[m] });
        }
      }
    }

    this.seriesData = this.makeColumnseriesData();

    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase15<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    this.seriesTemp = [];
    this.seriesName = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');

    let ind = array[0];

    let select_time = time_period[0];
    var main_ius = [];

    for (let i = 0; i < ius.length; i++) {
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];

      if (main_ius.indexOf(ius[i]) == -1) {
        main_ius.push(ius[i]);
      }
    }

    for (let m = 0; m < area.length; m++) {
      if (this.seriesName.indexOf(area[m]) == -1) {
        this.seriesName.push(area[m]);
      }
      for (var k = 0; k < main_ius.length; k++) {
        var array = main_ius[k].split('|');
        if (this.xaxis.indexOf(array[1] + ',' + array[2]) == -1) {
          this.xaxis.push(array[1] + ',' + array[2]);
        }
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++) {
          if (data[j].area_name == area[m] && data[j].ius == main_ius[k] && data[j].time_period == time_period[0]) {
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if (exist) {
          this.seriesTemp.push({ axis: array[1] + ',' + array[2] + ', ' + area[m], name: area[m], value: Number(data[j].value), area_name: area[m] });
        }
      }
    }

    this.seriesData = this.makeColumnseriesData();

    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase16<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    this.seriesTemp = [];
    this.seriesName = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');

    let subgroup = array[2];

    let select_time = time_period;

    var main_ius = [];

    for (let i = 0; i < ius.length; i++) {
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];

      if (main_ius.indexOf(ius[i]) == -1) {
        main_ius.push(ius[i]);
      }
    }

    for (let m = 0; m < area.length; m++) {
      if (this.seriesName.indexOf(area[m]) == -1) {
        this.seriesName.push(area[m]);
      }
      for (var k = 0; k < main_ius.length; k++) {
        var array = main_ius[k].split('|');
        if (this.xaxis.indexOf(array[0] + ',' + array[1]) == -1) {
          this.xaxis.push(array[0] + ',' + array[1]);
        }
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++) {
          if (data[j].area_name == area[m] && data[j].ius == main_ius[k] && data[j].time_period == time_period[0]) {
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if (exist) {
          this.seriesTemp.push({ axis: array[0] + ',' + array[1] + ', ' + area[m], name: area[m], value: Number(data[j].value), area_name: area[m] });
        }
      }
    }

    this.seriesData = this.makeColumnseriesData();
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase17<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    this.seriesTemp = [];
    this.seriesName = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');

    let subgroup = array[2];

    let select_time = time_period;

    var main_ius = [];

    var indicator_table = [];
    var unit_table = [];
    var main_subgroup = [];

    var main_iu = [];
    var main_ius = [];

    var tempData1 = '';
    var time_name = '';

    for (let i = 0; i < ius.length; i++) {
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];

      if (main_subgroup.indexOf(subgroup) == -1) {
        main_subgroup.push(subgroup);
      }
      if (main_iu.indexOf(ind + '|' + unit) == -1) {
        main_iu.push(ind + '|' + unit);
      }
      if (main_ius.indexOf(ius[i]) == -1) {
        main_ius.push(ius[i]);
      }
    }
    for (var k = 0; k < main_ius.length; k++) {
      var array = main_ius[k].split('|');
      if (this.xaxis.indexOf(array[2]) == -1) {
        this.xaxis.push(array[2]);
      }
      for (let m = 0; m < time_period.length; m++) {
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++) {
          if (data[j].area_name == area[0] && data[j].ius == main_ius[k] && data[j].time_period == time_period[m]) {
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if (exist) {
          if (this.seriesName.indexOf(data[j].time_period) == -1) {
            this.seriesName.push(data[j].time_period);
          }
          this.seriesTemp.push({ axis: array[2] + ', ' + data[j].time_period, name: data[j].time_period, value: Number(data[j].value), area_name: area[0] });
        }
      }
    }

    this.seriesData = this.makeColumnseriesData();
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase18<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    this.seriesTemp = [];
    this.seriesName = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');

    let unit = array[1];
    let subgroup = array[2];

    var select_area = area[0];
    var indicator_table = [];
    var unit_table = [];
    var main_subgroup = [];

    var main_iu = [];
    var main_ius = [];
    var tempData1 = '';
    var time_name = '';

    for (let i = 0; i < ius.length; i++) {
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];

      if (main_subgroup.indexOf(subgroup) == -1) {
        main_subgroup.push(subgroup);
      }
      if (main_iu.indexOf(ind + '|' + unit) == -1) {
        main_iu.push(ind + '|' + unit);
      }
      if (main_ius.indexOf(ius[i]) == -1) {
        main_ius.push(ius[i]);
      }
    }

    for (var k = 0; k < main_ius.length; k++) {
      var array = main_ius[k].split('|');
      if (this.xaxis.indexOf(array[0]) == -1) {
        this.xaxis.push(array[0]);
      }
      for (let m = 0; m < time_period.length; m++) {
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++) {
          
          if (data[j].area_name == area[0] && data[j].ius == main_ius[k] && data[j].time_period == time_period[m]) {
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }

        if (exist) {
         
          if (this.seriesName.indexOf(data[j].time_period) == -1) {
            this.seriesName.push(data[j].time_period);
          }
          this.seriesTemp.push({ axis: array[0] + ', ' + data[j].time_period, name: data[j].time_period, value: Number(data[j].value), area_name: area[0] });
        }
      }
    }

    this.seriesData = this.makeColumnseriesData();
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase19<T>(objData: any) {
    console.log(objData, 'mnxcvnc')
    this.seriesData = [];
    this.xaxis = [];
    this.seriesTemp = [];
    this.seriesName = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');

    let unit = array[1];
    let subgroup = array[2];

    let select_time = time_period[0];
    var main_ius = [];

    for (let i = 0; i < ius.length; i++) {
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if (main_ius.indexOf(ius[i]) == -1) {
        main_ius.push(ius[i]);
      }
    }

    for (let m = 0; m < area.length; m++) {
      if (this.seriesName.indexOf(area[m]) == -1) {
        this.seriesName.push(area[m]);
      }
      for (var k = 0; k < main_ius.length; k++) {
        var array = main_ius[k].split('|');
        if (this.xaxis.indexOf(array[0]) == -1) {
          this.xaxis.push(array[0]);
        }
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++) {
          if (data[j].area_name == area[m] && data[j].ius == main_ius[k] && data[j].time_period == time_period[0]) {
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if (exist) {
          this.seriesTemp.push({ axis: array[0] + ', ' + area[m], name: area[m], value: Number(data[j].value), area_name: area[m] });
        }
      }
    }

    this.seriesData = this.makeColumnseriesData();
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase20<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    this.seriesTemp = [];
    this.seriesName = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');
    let unit = array[1];
    let subgroup = array[2];

    let select_time = time_period[0];

    var indicator_table = [];
    var unit_table = [];
    var main_subgroup = [];

    var main_iu = [];
    var main_ius = [];

    for (let i = 0; i < ius.length; i++) {
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];

      if (main_subgroup.indexOf(subgroup) == -1) {
        main_subgroup.push(subgroup);
      }
      if (main_iu.indexOf(ind + '|' + unit) == -1) {
        main_iu.push(ind + '|' + unit);
      }
      if (main_ius.indexOf(ius[i]) == -1) {
        main_ius.push(ius[i]);
      }
    }
    for (var k = 0; k < main_iu.length; k++) {
      var array = main_iu[k].split('|');
      if (this.xaxis.indexOf(array[0]) == -1) {
        this.xaxis.push(array[0]);
      }
      for (var i = 0; i < main_subgroup.length; i++) {
        if (this.seriesName.indexOf(main_subgroup[i]) == -1) {
          this.seriesName.push(main_subgroup[i]);
        }

        let iusMerge = main_iu[k] + '|' + main_subgroup[i];
        let exist = 0;
        if (main_ius.indexOf(iusMerge) !== -1) {
          for (var j = 0, len = data.length; j < len; j++) {
            if (data[j].area_name == area[0] && data[j].ius == iusMerge && data[j].time_period == time_period[0]) {
              exist = 1;//return myArray[i]; // Return as soon as the object is found
              break;
            }
          }
        }
        if (exist) {
          this.seriesTemp.push({ axis: array[0] + ', ' + main_subgroup[i], name: main_subgroup[i], value: Number(data[j].value), area_name: area[0] });
        }
      }
    }

    this.seriesData = this.makeColumnseriesData();
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase21<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    this.seriesTemp = [];
    this.seriesName = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');

    let ind = array[0];
    var indicator_table = [];
    var unit_table = [];
    var main_subgroup = [];

    var main_iu = [];
    var main_ius = [];

    for (let i = 0; i < ius.length; i++) {
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];

      if (main_subgroup.indexOf(subgroup) == -1) {
        main_subgroup.push(subgroup);
      }
      if (main_iu.indexOf(ind + '|' + unit) == -1) {
        main_iu.push(ind + '|' + unit);
      }
      if (main_ius.indexOf(ius[i]) == -1) {
        main_ius.push(ius[i]);
      }
    }

    for (let m = 0; m < area.length; m++) {
      for (var k = 0; k < main_ius.length; k++) {
        var array = main_ius[k].split('|');
        if (this.xaxis.indexOf(array[1] + ',' + array[2]) == -1) {
          this.xaxis.push(array[1] + ',' + array[2]);
        }
        for (let l = 0; l < time_period.length; l++) {
          let exist = 0;
          for (var j = 0, len = data.length; j < len; j++) {
            if (data[j].area_name == area[m] && data[j].ius == main_ius[k] && data[j].time_period == time_period[l]) {
              exist = 1;//return myArray[i]; // Return as soon as the object is found
              break;
            }
          }
          if (exist) {
            this.seriesTemp.push({ axis: array[1] + ',' + array[2] + ', ' + area[m] + ', ' + data[j].time_period, name: area[m] + ', ' + data[j].time_period, value: Number(data[j].value), area_name: area[m] });
            if (this.seriesName.indexOf(area + ', ' + data[j].time_period) == -1) {
              this.seriesName.push(area + ', ' + data[j].time_period);
            }

          }
        }
      }
    }

    this.seriesData = this.makeColumnseriesData();
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase22<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    this.seriesTemp = [];
    this.seriesName = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');

    let select_ind = array[0];
    let select_subgroup = array[2];

    let select_time = time_period[0];

    var main_ius = [];

    for (let i = 0; i < ius.length; i++) {
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if (main_ius.indexOf(ius[i]) == -1) {
        main_ius.push(ius[i]);
      }
    }

    for (var k = 0; k < main_ius.length; k++) {
      var array = main_ius[k].split('|');
      if (this.xaxis.indexOf(array[1]) == -1) {
        this.xaxis.push(array[1]);
      }

      let tempData = [];

      let area_name = '';

      for (let m = 0; m < area.length; m++) {
        if (this.seriesName.indexOf(area[m]) == -1) {
          this.seriesName.push(area[m]);
        }

        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++) {
          if (data[j].area_name == area[m] && data[j].ius == main_ius[k] && data[j].time_period == time_period[0]) {
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if (exist) {
          this.seriesTemp.push({ axis: array[1] + ', ' + area[m], name: area[m], value: Number(data[j].value), area_name: area[m] });
        }
      }
    }

    this.seriesData = this.makeColumnseriesData();
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase23<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    this.seriesTemp = [];
    this.seriesName = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');

    let ind = array[0];
    var select_area = area[0];
    var indicator_table = [];
    var unit_table = [];
    var main_subgroup = [];

    var main_iu = [];
    var main_ius = [];

    var tempData1 = '';
    var time_name = '';
    for (let i = 0; i < ius.length; i++) {
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];

      if (main_subgroup.indexOf(subgroup) == -1) {
        main_subgroup.push(subgroup);
      }
      if (main_iu.indexOf(ind + '|' + unit) == -1) {
        main_iu.push(ind + '|' + unit);
      }
      if (main_ius.indexOf(ius[i]) == -1) {
        main_ius.push(ius[i]);
      }
    }

    for (var k = 0; k < main_ius.length; k++) {
      var array = main_ius[k].split('|');
      if (this.xaxis.indexOf(array[1] + ',' + array[2]) == -1) {
        this.xaxis.push(array[1] + ',' + array[2]);
      }
      for (let m = 0; m < time_period.length; m++) {
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++) {
          if (data[j].area_name == select_area && data[j].ius == main_ius[k] && data[j].time_period == time_period) {
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if (exist) {
          if (this.seriesName.indexOf(data[j].time_period) == -1) {
            this.seriesName.push(data[j].time_period);
          }
          this.seriesTemp.push({ axis: array[1] + ',' + array[2] + ', ' + data[j].time_period, name: data[j].time_period, value: Number(data[j].value), area_name: area[0] });

        }
      }
    }

    this.seriesData = this.makeColumnseriesData();
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase24<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    this.seriesTemp = [];
    this.seriesName = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');

    let select_ind = array[0];
    let select_subgroup = array[2];

    let select_time = time_period[0];
    var main_ius = [];

    for (let i = 0; i < ius.length; i++) {
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if (main_ius.indexOf(ius[i]) == -1) {
        main_ius.push(ius[i]);
      }
    }

    for (let m = 0; m < area.length; m++) {
      if (this.seriesName.indexOf(area[m]) == -1) {
        this.seriesName.push(area[m]);
      }
      for (var k = 0; k < main_ius.length; k++) {
        var array = main_ius[k].split('|');
        if (this.xaxis.indexOf(array[1] + ',' + array[2]) == -1) {
          this.xaxis.push(array[1] + ',' + array[2]);
        }
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++) {
          if (data[j].area_name == area[m] && data[j].ius == main_ius[k] && data[j].time_period == select_time) {
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if (exist) {
          this.seriesTemp.push({ axis: array[1] + ',' + array[2] + ', ' + area[m], name: area[m], value: Number(data[j].value), area_name: area[m] });
        }
      }
    }

    this.seriesData = this.makeColumnseriesData();
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase25<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    this.seriesTemp = [];
    this.seriesName = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');

    let select_unit = array[1];

    let select_time = time_period[0];

    var main_ius = [];

    for (let i = 0; i < ius.length; i++) {
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if (main_ius.indexOf(ius[i]) == -1) {
        main_ius.push(ius[i]);
      }
    }

    for (let m = 0; m < area.length; m++) {
      if (this.seriesName.indexOf(area[m]) == -1) {
        this.seriesName.push(area[m]);
      }
      for (var k = 0; k < main_ius.length; k++) {
        var array = main_ius[k].split('|');
        if (this.xaxis.indexOf(array[0] + ',' + array[2]) == -1) {
          this.xaxis.push(array[0] + ',' + array[2]);
        }
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++) {
          if (data[j].area_name == area[m] && data[j].ius == main_ius[k] && data[j].time_period == select_time) {
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if (exist) {
          this.seriesTemp.push({ axis: array[0] + ',' + array[2] + ', ' + area[m], name: area[m], value: Number(data[j].value), area_name: area[m] });
        }
      }
    }

    this.seriesData = this.makeColumnseriesData();
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase26<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    this.seriesTemp = [];
    this.seriesName = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');

    let unit = array[1];

    var indicator_table = [];
    var unit_table = [];
    var main_subgroup = [];

    var main_iu = [];
    var main_ius = [];

    for (let i = 0; i < ius.length; i++) {
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];

      if (main_subgroup.indexOf(subgroup) == -1) {
        main_subgroup.push(subgroup);
      }
      if (main_iu.indexOf(ind + '|' + unit) == -1) {
        main_iu.push(ind + '|' + unit);
      }
      if (main_ius.indexOf(ius[i]) == -1) {
        main_ius.push(ius[i]);
      }
    }

    for (let m = 0; m < area.length; m++) {
      for (var k = 0; k < main_ius.length; k++) {
        var array = main_ius[k].split('|');
        if (this.xaxis.indexOf(array[0] + ',' + array[2]) == -1) {
          this.xaxis.push(array[0] + ',' + array[2]);
        }
        for (let l = 0; l < time_period.length; l++) {
          let exist = 0;
          for (var j = 0, len = data.length; j < len; j++) {
            if (data[j].area_name == area[m] && data[j].ius == main_ius[k] && data[j].time_period == time_period[l]) {
              exist = 1;//return myArray[i]; // Return as soon as the object is found
              break;
            }
          }
          if (exist) {
            this.seriesTemp.push({ axis: array[0] + ',' + array[2] + ', ' + area[m] + ', ' + data[j].time_period, name: area[m] + ', ' + data[j].time_period, value: Number(data[j].value), area_name: area[m] });
            if (this.seriesName.indexOf(area[m] + ', ' + data[j].time_period) == -1) {
              this.seriesName.push(area[m] + ', ' + data[j].time_period);
            }
          }
        }
      }
    }

    this.seriesData = this.makeColumnseriesData();
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase27<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    this.seriesTemp = [];
    this.seriesName = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');

    let unit = array[1];
    var select_area = area[0];
    var indicator_table = [];
    var unit_table = [];
    var main_subgroup = [];

    var main_iu = [];
    var main_ius = [];

    var tempData1 = '';
    var time_name = '';
    for (let i = 0; i < ius.length; i++) {
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];

      if (main_subgroup.indexOf(subgroup) == -1) {
        main_subgroup.push(subgroup);
      }
      if (main_iu.indexOf(ind + '|' + unit) == -1) {
        main_iu.push(ind + '|' + unit);
      }
      if (main_ius.indexOf(ius[i]) == -1) {
        main_ius.push(ius[i]);
      }
    }

    for (var k = 0; k < main_ius.length; k++) {
      var array = main_ius[k].split('|');
      if (this.xaxis.indexOf(array[0] + ',' + array[2]) == -1) {
        this.xaxis.push(array[0] + ',' + array[2]);
      }
      for (let m = 0; m < time_period.length; m++) {
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++) {
          if (data[j].area_name == select_area && data[j].ius == main_ius[k] && data[j].time_period == time_period[m]) {
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if (exist) {
          if (this.seriesName.indexOf(data[j].time_period) == -1) {
            this.seriesName.push(data[j].time_period);
          }
          this.seriesTemp.push({ axis: array[0] + ',' + array[2] + ', ' + data[j].time_period, name: data[j].time_period, value: Number(data[j].value), area_name: select_area });
        }
      }
    }

    this.seriesData = this.makeColumnseriesData();
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase28<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    this.seriesTemp = [];
    this.seriesName = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');

    let subgroup = array[2];
    var select_area = area[0];

    var indicator_table = [];
    var unit_table = [];
    var main_subgroup = [];

    var main_iu = [];
    var main_ius = [];
    for (let i = 0; i < ius.length; i++) {
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];

      if (main_subgroup.indexOf(subgroup) == -1) {
        main_subgroup.push(subgroup);
      }
      if (main_iu.indexOf(ind + '|' + unit) == -1) {
        main_iu.push(ind + '|' + unit);
      }
      if (main_ius.indexOf(ius[i]) == -1) {
        main_ius.push(ius[i]);
      }
    }

    for (var k = 0; k < main_ius.length; k++) {
      var array = main_ius[k].split('|');
      if (this.xaxis.indexOf(array[0] + ',' + array[1]) == -1) {
        this.xaxis.push(array[0] + ',' + array[1]);
      }
      for (let m = 0; m < time_period.length; m++) {
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++) {
          if (data[j].area_name == select_area && data[j].ius == main_ius[k] && data[j].time_period == time_period[m]) {
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if (exist) {
          if (this.seriesName.indexOf(data[j].time_period) == -1) {
            this.seriesName.push(data[j].time_period);
          }
          this.seriesTemp.push({ axis: array[0] + ',' + array[1] + ', ' + data[j].time_period, name: data[j].time_period, value: Number(data[j].value), area_name: select_area });
        }
      }
    }

    this.seriesData = this.makeColumnseriesData();
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase29<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    this.seriesTemp = [];
    this.seriesName = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');

    let select_unit = array[1];
    let select_time = time_period[0];
    var main_ius = [];

    for (let i = 0; i < ius.length; i++) {
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if (main_ius.indexOf(ius[i]) == -1) {
        main_ius.push(ius[i]);
      }
    }

    for (let m = 0; m < area.length; m++) {
      if (this.seriesName.indexOf(area[m]) == -1) {
        this.seriesName.push(area[m]);
      }
      for (var k = 0; k < main_ius.length; k++) {
        var array = main_ius[k].split('|');
        if (this.xaxis.indexOf(array[0] + ',' + array[1]) == -1) {
          this.xaxis.push(array[0] + ',' + array[1]);
        }
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++) {
          if (data[j].area_name == area[m] && data[j].ius == main_ius[k] && data[j].time_period == select_time) {
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if (exist) {
          this.seriesTemp.push({ axis: array[0] + ',' + array[1] + ', ' + area[m], name: area[m], value: Number(data[j].value), area_name: area[m] });
        }
      }
    }

    this.seriesData = this.makeColumnseriesData();
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase30<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    this.seriesTemp = [];
    this.seriesName = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');

    let select_time = time_period[0];

    var main_ius = [];
    for (let i = 0; i < ius.length; i++) {
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if (main_ius.indexOf(ius[i]) == -1) {
        main_ius.push(ius[i]);
      }
    }

    for (let m = 0; m < area.length; m++) {
      if (this.seriesName.indexOf(area[m]) == -1) {
        this.seriesName.push(area[m]);
      }
      for (var k = 0; k < main_ius.length; k++) {
        var array = main_ius[k].split('|');
        if (this.xaxis.indexOf(array[0] + ',' + array[1] + ',' + array[2]) == -1) {
          this.xaxis.push(array[0] + ',' + array[1] + ',' + array[2]);
        }
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++) {
          if (data[j].area_name == area && data[j].ius == main_ius[k] && data[j].time_period == select_time) {
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if (exist) {
          this.seriesTemp.push({ axis: array[0] + ',' + array[1] + ',' + array[2] + ', ' + area[m], name: area[m], value: Number(data[j].value), area_name: area[m] });
        }
      }
    }

    this.seriesData = this.makeColumnseriesData();
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase31<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    this.seriesTemp = [];
    this.seriesName = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');
    let select_time = time_period[0];
    var main_ius = [];
    for (let i = 0; i < ius.length; i++) {
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if (main_ius.indexOf(ius[i]) == -1) {
        main_ius.push(ius[i]);
      }
    }

    for (let m = 0; m < area.length; m++) {
      if (this.seriesName.indexOf(area[m]) == -1) {
        this.seriesName.push(area[m]);
      }
      for (var k = 0; k < main_ius.length; k++) {
        var array = main_ius[k].split('|');
        if (this.xaxis.indexOf(array[0] + ', ' + array[1] + ', ' + array[2]) == -1) {
          this.xaxis.push(array[0] + ', ' + array[1] + ', ' + array[2]);
        }
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++) {
          if (data[j].area_name == area[m] && data[j].ius == main_ius[k] && data[j].time_period == time_period[0]) {
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if (exist) {
          this.seriesTemp.push({ axis: array[0] + ', ' + array[1] + ', ' + array[2] + ', ' + area[m], name: area[m], value: Number(data[j].value), area_name: area[m] });
        }
      }
    }

    this.seriesData = this.makeColumnseriesData();
    return { series: this.seriesData, xaxis: this.xaxis };
  }

  public columnCase32<T>(objData: any) {
    this.seriesData = [];
    this.xaxis = [];
    this.seriesTemp = [];
    this.seriesName = [];

    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    var array = ius[0].split('|');
    var indicator_table = [];
    var unit_table = [];
    var main_subgroup = [];

    var main_iu = [];
    var main_ius = [];

    for (let i = 0; i < ius.length; i++) {
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];

      if (main_subgroup.indexOf(subgroup) == -1) {
        main_subgroup.push(subgroup);
      }
      if (main_iu.indexOf(ind + '|' + unit) == -1) {
        main_iu.push(ind + '|' + unit);
      }
      if (main_ius.indexOf(ius[i]) == -1) {
        main_ius.push(ius[i]);
      }
    }

    for (let m = 0; m < area.length; m++) {
      for (var k = 0; k < main_ius.length; k++) {
        var array = main_ius[k].split('|');
        if (this.xaxis.indexOf(array[0] + ',' + array[1] + ',' + array[2]) == -1) {
          this.xaxis.push(array[0] + ',' + array[1] + ',' + array[2]);
        }
        for (let l = 0; l < time_period.length; l++) {
          let exist = 0;
          for (var j = 0, len = data.length; j < len; j++) {
            if (data[j].area_name == area[m] && data[j].ius == main_ius[k] && data[j].time_period == time_period[l]) {
              exist = 1;//return myArray[i]; // Return as soon as the object is found
              break;
            }
          }
          if (exist) {
            this.seriesTemp.push({ axis: array[0] + ',' + array[1] + ',' + array[2] + ', ' + area[m] + ', ' + data[j].time_period, name: area[m] + ', ' + data[j].time_period, value: Number(data[j].value), area_name: area[m] });
            if (this.seriesName.indexOf(area[m] + ', ' + data[j].time_period) == -1) {
              this.seriesName.push(area[m] + ', ' + data[j].time_period);
            }
          }
        }
      }
    }

    this.seriesData = this.makeColumnseriesData();
    return { series: this.seriesData, xaxis: this.xaxis };
  }


  makeColumnseriesData() {
    let seriesDataTemp = [];
    let grouppedArray = _.groupBy(this.seriesTemp, 'axis');

    for (let i = 0; i < this.seriesName.length; i++) {

      let tempData = [];

      for (let j = 0; j < this.xaxis.length; j++) {

        let key = this.xaxis[j] + ', ' + this.seriesName[i];
        if (grouppedArray[key] !== undefined) {
          // tempData.push({name:this.xaxis[j],y:grouppedArray[key][0].value});
          tempData.push(grouppedArray[key][0].value);
        } else {
          tempData.push('');
        }
      }

      seriesDataTemp.push({ name: this.seriesName[i], data: tempData });
    }
    return seriesDataTemp;
  }

}
