import { Component, OnInit, Input } from "@angular/core";
import { AuthService } from "../services/auth/auth.service";
import { Router } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../services/api.service";
import { HeaderService } from "../services/header.service";
import { Configuration } from "../app.constant";
declare var window: any;
declare var $: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  @Input() isLogin: boolean;
  loginForm: FormGroup;
  forgetPasswordForm: FormGroup;
  model: any = {};
  error: string = "";
  myStyle: object = {};
  myParams: object = {};
  width: number = 100;
  height: number = 100;
  showLoader: boolean = false;
  baseUrl: any;
  code: any = "";
  rememberChecked: boolean = false;
  constructor(
    private auth: AuthService,
    private route: Router,
    public formBuilder: FormBuilder,
    private toastr: ToastrService,
    private dataService: ApiService,
    private headerService: HeaderService,
    public configuration: Configuration
  ) {
    this.baseUrl = configuration.domainName;
  }

  login() {
  
    $(".loaders ol li").fadeIn();
    let email = this.loginForm.get("email").value;
    let passwordString = this.makeid(10) + this.loginForm.get("password").value;
    let password = btoa(passwordString);
    let rememberMe = this.loginForm.get("rememberUser").value;
    var obj = this; //assigning object to access in setTimeout
    
      this.auth.login(email, password, rememberMe).subscribe(
        (resp: any) => {
          this.isLogin = true;
          $(".loaders ol li").fadeOut();
          // localStorage.setItem("remember_me", rememberMe);
          localStorage.setItem("auth_token", resp.success.token);
          localStorage.setItem("user_details",JSON.stringify(resp.success.details) );
          localStorage.setItem("area_details", JSON.stringify(resp.success.area_details));
          localStorage.setItem("areaCode", resp.success.area_details.area_code);
          localStorage.setItem("areaLevel", resp.success.area_details.area_level);
          localStorage.setItem("areaName", resp.success.area_details.area_name);
          localStorage.setItem("login_time_row", resp.success.login_time_row);   
          
         // console.log(resp.success.area_details.area_code);
         
          this.headerService.setUsername(resp.success.details.name);
          // if (resp.success.details.role > 1) {
          //   obj.route.navigate(["outlier/monitoring"]);
          //   return;
          // }
          obj.route.navigate(["home"]);
        },
        error => {
          $(".loaders ol li").fadeOut();
          this.error = error.error.error;
          this.toastr.error("Invalid login");
          this.createCaptcha("captcha");
        },
        () => {
          this.error = "";
        }
      );

  
      // localStorage.setItem("remember_me", rememberMe);
      //     localStorage.setItem("auth_token", null);
      //     localStorage.setItem( "user_details",null );
      //     localStorage.setItem( "area_details", null)
    
  }

  ngOnInit() {
    //   console.log(localStorage, "storagelogin");
    //  let rememberUserVal = localStorage.getItem("remember_me")
    // if(rememberUserVal){
    //   this.route.navigate(["/home"]);
    // }
   
    //particle js
    this.myStyle = {
      position: "relative",
      width: "100%",
      height: "100vh",
      top: 0,
      left: 0,
      "z-index": "1"
    };

    this.myParams = {
      particles: {
        number: {
          value: 60
        },
        color: {
          value: "#ffffff"
        },
        shape: {
          type: "circle"
        }
      }
    };
    this.loginForm = this.formBuilder.group(
      {
        email: [
          "",
          [
            Validators.required,
            Validators.pattern(
              "[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})"
            )
          ]
        ],
        password: ["", Validators.required],
        rememberUser: false,
        captcha: ["", Validators.required]
      },
      { validator: this.captchaMatchValidator.bind(this) }
    );
    this.forgetPasswordForm = this.formBuilder.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})"
          )
        ]
      ]
    });

    if (this.auth.isLoggednIn()) {
      this.route.navigate(["home"]);
    }
  }

  captchaMatchValidator(group: FormGroup): any {
    if (group.value.captcha != this.code) {
      return { captchaMisMatch: true };
    } else {
      return { captchaMisMatch: false };
    }
  }

  ngAfterViewInit() {
    if (!this.auth.isLoggednIn()) {
      $("#login").css("display", "block");
      $("#login").addClass("in");
    }
    $(document).ready(function() {
      $(".loaders ol li")
        .delay(1000)
        .fadeOut();
      var $slider = $(".slider"),
        $slideBGs = $(".slide__bg"),
        diff = 0,
        curSlide = 0,
        numOfSlides = $(".slide").length - 1,
        animating = false,
        animTime = 500,
        autoSlideTimeout,
        autoSlideDelay = 6000,
        $pagination = $(".slider-pagi");

      function createBullets() {
        for (var i = 0; i < numOfSlides + 1; i++) {
          var $li = $("<li class='slider-pagi__elem'></li>");
          $li.addClass("slider-pagi__elem-" + i).data("page", i);
          if (!i) $li.addClass("active");
          $pagination.append($li);
        }
      }

      createBullets();

      function manageControls() {
        $(".slider-control").removeClass("inactive");
        if (!curSlide) $(".slider-control.left").addClass("inactive");
        if (curSlide === numOfSlides)
          $(".slider-control.right").addClass("inactive");
      }

      function autoSlide() {
        autoSlideTimeout = setTimeout(function() {
          curSlide++;
          if (curSlide > numOfSlides) curSlide = 0;
          changeSlides(1);
        }, autoSlideDelay);
      }

      autoSlide();

      function changeSlides(instant) {
        if (!instant) {
          animating = true;
          manageControls();
          $slider.addClass("animating");
          $slider.css("top");
          $(".slide").removeClass("active");
          $(".slide-" + curSlide).addClass("active");
          setTimeout(function() {
            $slider.removeClass("animating");
            animating = false;
          }, animTime);
        }
        window.clearTimeout(autoSlideTimeout);
        $(".slider-pagi__elem").removeClass("active");
        $(".slider-pagi__elem-" + curSlide).addClass("active");
        $slider.css("transform", "translate3d(" + -curSlide * 100 + "%,0,0)");
        $slideBGs.css("transform", "translate3d(" + curSlide * 50 + "%,0,0)");
        diff = 0;
        autoSlide();
      }

      function navigateLeft() {
        if (animating) return;
        if (curSlide > 0) curSlide--;
        changeSlides(1);
      }

      function navigateRight() {
        if (animating) return;
        if (curSlide < numOfSlides) curSlide++;
        changeSlides(1);
      }

      $(document).on("mousedown touchstart", ".slider", function(e) {
        if (animating) return;
        window.clearTimeout(autoSlideTimeout);
        var startX = e.pageX || e.originalEvent.touches[0].pageX,
          winW = $(window).width();
        diff = 0;

        $(document).on("mousemove touchmove", function(e) {
          var x = e.pageX || e.originalEvent.touches[0].pageX;
          diff = ((startX - x) / winW) * 70;
          if ((!curSlide && diff < 0) || (curSlide === numOfSlides && diff > 0))
            diff /= 2;
          $slider.css(
            "transform",
            "translate3d(" + (-curSlide * 100 - diff) + "%,0,0)"
          );
          $slideBGs.css(
            "transform",
            "translate3d(" + (curSlide * 50 + diff / 2) + "%,0,0)"
          );
        });
      });

      $(document).on("mouseup touchend", function(e) {
        $(document).off("mousemove touchmove");
        if (animating) return;
        if (!diff) {
          changeSlides(true);
          return;
        }
        if (diff > -8 && diff < 8) {
          changeSlides(1);
          return;
        }
        if (diff <= -8) {
          navigateLeft();
        }
        if (diff >= 8) {
          navigateRight();
        }
      });

      $(document).on("click", ".slider-control", function() {
        if ($(this).hasClass("left")) {
          navigateLeft();
        } else {
          navigateRight();
        }
      });

      $(document).on("click", ".slider-pagi__elem", function() {
        curSlide = $(this).data("page");
        changeSlides(1);
      });

      $("#createCaptcha").click();
    });
  }
  resetPassword() {
    let data = {};
    data["email"] = this.forgetPasswordForm.get("email").value;
    data["url"] = this.baseUrl + "/#" + "/reset-password";

    console.log(data);
    this.dataService.add("api/password/email", data).subscribe(
      (res: any) => {
        this.toastr.success(
          "Password reset link has been sent succesfully to your email address."
        );
      },
      error => {
        // second parameter is to listen for error
        this.toastr.error("Email id is not found");
      }
    );
  }

  makeid(length) {
    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  createCaptcha(divId) {
    //clear the contents of captcha div first
    document.getElementById(divId).innerHTML = "";
    var charsArray =
      "23456789abcdefghijkmnpqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ@#$%^&*";
    var lengthOtp = 6;
    var captcha = [];

    for (var i = 0; i < lengthOtp; i++) {
      //below code will not allow Repetition of Characters
      var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
      if (captcha.indexOf(charsArray[index]) == -1)
        captcha.push(charsArray[index]);
      else i--;
    }

    var canv = document.createElement("canvas");
    canv.id = divId;
    canv.width = 130;
    canv.height = 70;
    var ctx = canv.getContext("2d");

    //storing captcha so that can validate you can save it somewhere else according to your specific requirements
    var imageObj = new Image();
    imageObj.onload = function() {
      ctx.drawImage(imageObj, 0, 10);
      ctx.rotate((Math.PI * 2) / (i * 5));
      //ctx.font = "40pt Calibri";
      //ctx.fillText("My TEXT!", 20, 20);
      ctx.font = "25px Georgia";

      ctx.beginPath();
      ctx.moveTo(0, 30);
      ctx.bezierCurveTo(140, 10, 388, 10, 388, 170);
      ctx.lineWidth = 2;
      // line color
      ctx.strokeStyle = "black";
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(0, 65);
      ctx.bezierCurveTo(140, 10, 388, 10, 388, 170);
      ctx.lineWidth = 1;

      // line color
      ctx.strokeStyle = "black";
      ctx.stroke();
      canv.setAttribute("dir", "ltr");

      ctx.strokeText(captcha.join(""), 15, 40);
    };

    imageObj.src = "assets/lib/images/captchabg.png";
    this.code = captcha.join("");
    document.getElementById(divId).appendChild(canv); // adds the canvas to the body element
  }
}
