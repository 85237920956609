import { Component, OnInit ,Input} from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-immu-dashboard-lastpage',
  templateUrl: './immu-dashboard-lastpage.component.html',
  styleUrls: ['./immu-dashboard-lastpage.component.css']
})
export class ImmuDashboardLastpageComponent implements OnInit {
  @Input() timePeriod: any;
  constructor(
    private commonService: CommonService,
  ) { }

  ngOnInit() {
  }

  getYear(timePeriod) {
    return this.commonService.getFinancialFirstYear(timePeriod);
  }
 
  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodFullChar(timePeriod);
  }

}
