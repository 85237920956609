import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class Configuration {

  // test
  //public domainName = location.hostname === 'localhost' ? "http://localhost:4200": "http://209.126.127.118:9091";
  //public server = location.hostname === 'localhost' ?  "http://localhost:8080" :  "http://209.126.127.118:9091";

   public domainName = location.hostname === 'localhost' ? "http://localhost": "https://idap.in";
   public server = location.hostname === 'localhost' ?  "http://localhost" :  "https://idap.in";

  public apiUrl = '/api/';
  public serverWithApiUrl = this.server + this.apiUrl;

  public location_center = {
    IND: [20.5937, 78.9629],
    IND001: [33.64427729500007, 75.11492050430796],
    IND002: [31.79575950000003, 77.27341000000007],
    IND003: [31.01663000000005, 75.41909000000007],
    IND005: [30.076549000000057, 79.31477700000005],
    IND006: [29.28060400000004, 76.03985150000005],
    IND007: [28.638562000000064, 77.11400150000006],
    IND008: [26.629743000000047, 73.87691450000005],
    IND009: [27.142971500000044, 80.86348650000005],
    IND010: [25.901365500000054, 85.79706150000004],
    IND011: [27.606806500000033, 88.47312500000007],
    IND012: [28.048346000000038, 94.50257050000008],
    IND013: [26.11034052750003, 94.28216500000008],
    IND014: [24.759693000000055, 93.86612650000006],
    IND015: [23.225600063500053, 92.84503144200008],
    IND016: [23.743607150500026, 91.74080170700006],
    IND017: [25.554717500000038, 91.30832600000005],
    IND018: [26.062112938500064, 92.84633950000006],
    IND019: [24.36539250000004, 87.83193550000004],
    IND020: [23.66492450000004, 85.63256800000005],
    IND021: [20.183800500000046, 84.45140050000003],
    IND022: [20.954334000000074, 82.30321050000006],
    IND023: [23.962811000000045, 78.40828300000007],
    IND024: [22.415163500000034, 71.32661400000006],
    IND027: [18.811206000000027, 76.76590650000006],
    IND028: [15.897431500000067, 80.74427000000006],
    IND029: [15.009373500000066, 76.32659100000006],
    IND030: [15.345343500000041, 74.01159600000005],
    IND031: [10.87655471100004, 72.89060007000003],
    IND032: [10.540906000000035, 76.12436650000006],
    IND033: [10.809451500000051, 78.28664350000005],
    IND034: [13.979519850500026, 78.92209393850007],
    IND035: [10.213039000000038, 93.25909100000007],
    IND036: [17.85595170350004, 79.29136898566522],
    IND037: [34.719888000000054, 76.41208950000006]
  }
}
