import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { HeaderService } from "src/app/services/header.service";
import { Subscription } from "rxjs";
import { Configuration } from "../../../../app.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-hmistable3",
  templateUrl: "./hmistable3.component.html",
  styleUrls: ["./hmistable3.component.css"],
})
export class Hmistable3Component implements OnInit {
  @Input() timePeriod: any;
  subscription: Subscription;
  private url: any;  
  public penta = [];
  loading = true;
  public mapLevel = 1;
  public source = 1;
  infant = {};
  mr1nfhs = {};
  Target5Yr = {};
  Target10Yr = {};
  Target16Yr = {};
  areaID = "IND";
  area_name = "IND";
  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private configuration: Configuration,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.url = configuration.server;
    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "header":
          this.source = 1;
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.getTable();
          break;
         default:
          break;
      }
    });
  }

  ngOnInit() {}

  ngOnChanges() {
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getTable();
  }

  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodChar(timePeriod);
  }
  getFinancialYear(timePeriod) {
    return this.commonService.getFinancialYear(timePeriod);
  }
  getRoundValue(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  getPerValue(numerator, denominator) {
    if (denominator == 0) {
      return 0;
    }
    var value = this.getRoundValue((numerator / denominator) * 100, 1);
    if (value) {
      return value;
    }
    return 0;
   }

  getTable() {
    let that = this;
    this.dataService
      .getAll("api/getHIMSTable3/" + this.timePeriod + "/" + this.areaID + "/")
      .subscribe((response: any) => {
        that.loading = false;
        that.penta = response.data;
        that.area_name = that.penta[0].area_name;

        response.infant.forEach((row) => {
          that.infant[row.area_name] = row.target;
        });
        response.Target16Yr.forEach((row) => {
          that.Target16Yr[row.area_name] = row.target;
        });
        response.Target10Yr.forEach((row) => {
          that.Target10Yr[row.area_name] = row.target;
        });
        response.Target5Yr.forEach((row) => {
          that.Target5Yr[row.area_name] = row.target;
        });
      });
  }
  arr: any;
  getinfant(area_name) {
    if (this.infant[area_name]) {
      this.arr = this.infant[area_name];
      return this.arr;
    } else {
      return "NA";
    }
  }
  arr5Yr: any;
  get5Yr(area_name) {
    if (this.Target5Yr[area_name]) {
      this.arr5Yr = this.Target5Yr[area_name];
      return this.arr5Yr;
    } else {
      return "NA";
    }
  }
  arr10Yr: any;
  get10Yr(area_name) {
    if (this.Target10Yr[area_name]) {
      this.arr10Yr = this.Target10Yr[area_name];
      return this.arr10Yr;
    } else {
      return "NA";
    }
  }
  arr16Yr: any;
  get16Yr(area_name) {
    if (this.Target16Yr[area_name]) {
      this.arr16Yr = this.Target16Yr[area_name];
      return this.arr16Yr;
    } else {
      return "NA";
    }
  }
  getObjectKey(object) {
    if (object) {
      return Object.keys(object);
    }
    return [];
  }
}
