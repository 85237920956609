import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import * as d3 from 'd3-3';
import { CommonService } from "../../services/common.service";
import {
  range as d3_range,
  select as d3_select,
  scaleLinear as d3_scaleLinear,
} from 'd3v4';
import { ApiService } from '../../services/api.service';
import { SharedataService } from "../../services/sharedata.service";
import { Subscription } from 'rxjs';
import * as Highcharts from 'highcharts';
import { VisualizationRendererService } from '../../services/visualization-renderer';
import exporting from 'highcharts/modules/exporting';
exporting(Highcharts);
declare var $: any;
@Component({
  selector: 'app-classification-information',
  templateUrl: './classification-information.component.html',
  styleUrls: ['./classification-information.component.css']
})
export class ClassificationInformationComponent implements OnInit {
  mapLevel = 1;
  treeData;
  areaID = '';
  areaName = '';
  sectorId;
  subsectorId;
  subSector;
  subscription: Subscription;
  xaxis: any = [];
  yaxis: any = '';
  seriesData: any = [];
  vizData: any = {};
  sourceOfMap: any;
  sourceData = [];
  chartTitle: any = '';
  chart_render: any;
  nodeType: any ;
  constructor(private headerService: HeaderService,
    private dataService: ApiService,
    private shareData: SharedataService,
    private commonService: CommonService,
    private vizService: VisualizationRendererService,
  ) {

    // subscribe to home component messages
    this.subscription = this.shareData.getData().subscribe(message => {

      switch (message.data.ctype) {
        case 'sidebar':
          this.subSector = message.data.data.level2;
          this.sectorId = message.data.data.parent_id;
          this.subsectorId = message.data.data.id;
          this.areaID = this.commonService.getAreaID();
          this.areaName = this.commonService.getAreaName();
          this.getTreeData();
          break;
        case 'header':
          this.areaID = message.data.data.code;
          this.areaName = message.data.data.name;
          this.mapLevel = message.data.data.level;
          this.getTreeData();
       
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {

    this.headerService.setTitle('Data visualization / Classification');
    this.headerService.setLogo('assets/lib/images/svg/header-menu/area-dashboard.svg');
    let userAreaDetail = JSON.parse(localStorage.getItem('area_details'));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    // this.treecollapse();
  }
  treecollapse() {
    var that = this;
    that.nodeType = that.subSector
    var margin = {
      top: 20,
      right: 120,
      bottom: 20,
      left: 120,
      g_trans: 100
    };

    $('#render').html('');
    var width = $('#render').width() - margin.right - margin.left;
    var height = $('#render').height() - margin.top - margin.bottom;

    var i = 0,
      duration = 750,
      root;

    var tree = d3.layout.tree().size([height, width]);
    var diagonal = d3.svg.diagonal()
      .projection(function (d) {
        return [d.y, d.x];
      });

    var svg = d3.select("#render").append("svg")
      .attr("width", width + margin.right + margin.left)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
    root = this.treeData;
    root.x0 = height / 2;
    root.y0 = 0;

    let subsectorindex = this.treeData.children.findIndex(ele => ele.treeId == this.subsectorId);

    root.children.forEach(collapse);
    toggle(root.children[subsectorindex]);
    //toggle(root.children[subsectorindex].children[0]);
    toggle(root.children[subsectorindex].children[0]);
    // 
    update(root);
    function toggle(d) {
      if (d.children) {
        d._children = d.children;
        d.children = null;
      } else {
        d.children = d._children;
        d._children = null;
      }
    }

    function collapse(d) {
      if (d.children) {
        d._children = d.children;
        d._children.forEach(collapse);
        d.children = null;
      }
    }
    function wrap(text, width) {
      var tspan;

      text.each(function (d) {

        var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 0.9, // ems
          y = text.attr("y"),
          dy = parseFloat(text.attr("dy"))
        tspan = text.text(null).append("tspan").attr("x", -30).attr("y", y).attr("dy", dy + "em");
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(" "));

          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append("tspan").attr("x", -30).attr("y", y).attr("dy", lineHeight + dy + "em").text(word);
          }
        }
      })
    }
    function update(source) {
      var newHeight = Math.max(tree.nodes(root).reverse().length * 20, height),
        textlength = 60;
      d3.select("#render svg")
        .attr("width", width + margin.right + margin.left)
        .attr("height", newHeight + margin.top + margin.bottom)
      // .attr('viewBox', '0 0 ' + width + ' ' + height)
      // .attr("preserveAspectRatio", "xMinYMin meet");

      tree = d3.layout.tree().size([newHeight, width]);

      var nodes = tree.nodes(root).reverse(),
        links = tree.links(nodes);

      nodes.forEach(function (d) {
        switch (d.depth) {
          case 2:
            d.y = d.depth * 350;
            break;
          case 3:
            d.y = d.depth * 270;
            break;
          default:
            d.y = d.depth * 220;
        }
      });

      var node = svg.selectAll("g.node")
        .data(nodes, function (d) {
          return d.id || (d.id = ++i);
        });

      // Enter any new modes at the parent's previous position.

      var nodeEnter = node.enter().append('g')
        .attr('class', function (d) {

          let className = 'node';
          if (d.depth == 0) {
            className += " rootNode";
          }
          return className;

        })
        .attr("transform", function (d) {
          return "translate(" + (source.y0) + "," + source.x0 + ")";
        })
        .on('click', click);

      // Add Circle for the nodes
      nodeEnter.append('circle')
        .attr('class', 'node')
        .attr('r', 6)
        .style("fill", function (d) {
          return d._children ? "lightsteelblue" : "#fff";
        });

      setTimeout(function () {
        nodeEnter.select("svg g g.node.rootNode text").call(wrap, 70);
      }, 700);
          
      nodeEnter.append("text")
        .attr("x", function (d) {
          return d.children || d._children ? -18 : 18;
        })
        .attr("dy", ".35em")
        .style("font-size", "10px")
        .attr("text-anchor", function (d) {
          return d.children || d._children ? "end" : "start";
        })
        .append("tspan")
        .text(function (d) {
          let str = d.name;
          if(str == "TOTAL"){
             str = "Monthly Coverage"
          }
          switch (d.depth) {
            case 0:
              break;
            case 2: // Indicator + Unit
              str = str.split('|');
              let indicator = str[0].trim().substring(0, textlength);
              indicator += (d.name.length > textlength) ? '..' : '';
              str = indicator + ' | ' + str[1].trim();
          }
          return str;
        })
        .style("fill-opacity", 1)
        .style("cursor", "pointer");

      nodeEnter.append('title')
        .text(function (d) {
          return d.name;
        });

      var nodeUpdate = node.transition()
        .duration(duration)
        .attr("transform", function (d) {
          return "translate(" + d.y + "," + d.x + ")";
        });

      nodeUpdate.select("circle")
        .attr("r", 6)
        .style("fill", function (d) {
          return d._children ? "#fff" : "#f44336";
        });


      nodeUpdate.select("text")
        .style("fill-opacity", 1);

      var nodeExit = node.exit().transition()
        .duration(duration)
        .attr("transform", function (d) {
          return "translate(" + source.y + "," + source.x + ")";
        })
        .remove();

      nodeExit.select("circle")
        .attr("r", 6);

      nodeExit.select("text")
        .style("fill-opacity", 1e-6);

      var link = svg.selectAll("path.link")
        .data(links, function (d) {
          return d.target.id;
        });

      link.enter().insert("path", "g")
        .attr("class", "link")
        .attr("d", function (d) {
          var o = {
            x: source.x0,
            y: source.y0
          };
          return diagonal({
            source: o,
            target: o
          });
        });

      link.transition()
        .duration(duration)
        .attr("d", diagonal);

      link.exit().transition()
        .duration(duration)
        .attr("d", function (d) {
          var o = {
            x: source.x,
            y: source.y
          };
          return diagonal({
            source: o,
            target: o
          });
        })
        .remove();

      nodes.forEach(function (d) {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    }

    var clicked = 0;

    function click(d) {
      clicked++;
      if (clicked == 1) {
        if (typeof d.children == 'undefined' && d.depth == 3) {
          // $(this).find('g.data').remove();
          that.innerSparkChart(d3.select(this), d);
        }
        if (d.children) {
          d._children = d.children;
          d.children = null;
        } else {
          d.children = d._children;
          d._children = null;
        }
        if (d.parent) { //add here, 
          d.parent.children.forEach(function (element) {
            if (d !== element) {
              collapse(element);
            }
          });
        }
        update(d);

        if (d.depth == 1) {
          setTimeout(function () {
            $("#render").animate({ scrollTop: Number($("#render").find('svg').attr('height')) / 3 });
          }, 1000);
        }
      }
      
       if(d.depth == 1){
          that.nodeType = d.name
      }else if(d.depth == 2){
           that.nodeType = d.parent.name
     }else{
         that.nodeType = d.parent.parent.name
     }
      setTimeout(function () {
        clicked = 0;
      }, 300);

    }

  }

  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodChar(timePeriod);
  }

  innerSparkChart(subgroupNode, d) {
    $("#loaders_rankdata ol li").fadeIn();
    this.dataService.getAll('api/get-datavalue/' + d.treeId + '/' + this.areaID).subscribe((response: any) => {
      let that = this;
      let data_value;
      let data = [];
      let width = 75;
      let height = 17;
      let year;
      if (response.data.length > 0) {
       

        year = response.data[0].time_period;

      if(this.mapLevel == 4){
       
        data_value = Number(response.data[0].numerator).toLocaleString();
          // year = response.data[0].time_period;
        response.data[0].record.forEach(element => {
          data.push(Number(element.numerator));

        });
        }else{
          data_value = Number(response.data[0].value).toLocaleString();
          response.data[0].record.forEach(element => {
            data.push(Number(element.value));
          });
      }
      } else {
        data_value = 'No data';
        data = [];
      }

      
      subgroupNode.selectAll('.data').remove();


      let nodeData = subgroupNode.append('g').attr('class', 'data');
      nodeData.append('text').attr('class', 'data_value_class').attr("dy", ".35em")
        .attr("x", function (d) {
          return subgroupNode.node().getBoundingClientRect().width;
        }).text(function (d) {
          return data_value;
        });
      if (data_value != 'No data') {
        
        nodeData.append('g').attr('class', 'data_timeperiod')
          .append('text')
          .attr("dy", ".35em")
          .attr("x", function (d) {
            return subgroupNode.node().getBoundingClientRect().width;
          }).text(function (d) {
            let formatedYear = that.getFormatedTimeperiod(year)
            return ' ( ' + formatedYear + ' ) ';
          });
      }

           if(this.mapLevel == 4){
            if (data.length > 0) {
              let ius_obj = d;
              let time_period = response.data[0].record.map(function (d) {
                return d.time_period;
              })
              let maxValue = d3.max(response.data[0].record, function (d) {
                return Number(d.numerator);
              });
              let minValue = d3.min(response.data[0].record, function (d) {
                return Number(d.numerator);
              });
             
              width = minValue == maxValue ? 5 : width;
              minValue = minValue == maxValue ? 0 : minValue;
              let data_count = data.length;
              let bar_width = (width - data_count) / data_count;
              let x = d3_scaleLinear().domain([0, data_count]).range([0, 0.9 * width]);
              let y = d3_scaleLinear().domain([minValue, maxValue]).range([height, 0]).nice();
              let that = this;
              let node_width = subgroupNode.node().getBoundingClientRect().width + 2;
              nodeData.append('g').attr('width', width)
                .attr('height', height).attr('class', 'spark_chart')
                .attr("transform", "translate(" + node_width + ", -10)")
                .selectAll('.bar').data(response.data[0].record)
                .enter()
                .append('rect')
                .attr('class', 'bar')
                .attr('x', (d, i) => x(i))
                .attr('y', function (d) {
                 
                  if (Number(d.numerator) >= 0) {
                    return y(Number(d.numerator));
                  } else {
                    return y(0);
                  }                 
                })
                .attr('width', bar_width)
                .attr('height', function (d) {
                  if (Number(d.numerator) >= 0) {
                    if (y(0) != height) {
                      return height - y(Number(d.numerator));
                    } else {
                      return y(0) - y(Number(d.numerator));
                    }
                  } else {
                    return y(Number(d.numerator)) - y(0);
                  }
                })
                .attr('fill', '#333333')
                .on('click', function (d) {
                  that.showSparkChart(ius_obj);
                }).append('title')
                .text(function (d) {
                  return d.numerator + " (" + that.commonService.covertTimePeriod(d.time_period) + ")";
                });
            }
           }
else{
      if (data.length > 0) {
        let ius_obj = d;
        let time_period = response.data[0].record.map(function (d) {
          return d.time_period;
        })
        let maxValue = d3.max(response.data[0].record, function (d) {
          return Number(d.value);
        });
        let minValue = d3.min(response.data[0].record, function (d) {
          return Number(d.value);
        });
        width = minValue == maxValue ? 5 : width;
        minValue = minValue == maxValue ? 0 : minValue;
        let data_count = data.length;
        let bar_width = (width - data_count) / data_count;
        let x = d3_scaleLinear().domain([0, data_count]).range([0, 0.9 * width]);
        let y = d3_scaleLinear().domain([minValue, maxValue]).range([height, 0]).nice();
        let that = this;
        let node_width = subgroupNode.node().getBoundingClientRect().width + 2;
        nodeData.append('g').attr('width', width)
          .attr('height', height).attr('class', 'spark_chart')
          .attr("transform", "translate(" + node_width + ", -10)")
          .selectAll('.bar').data(response.data[0].record)
          .enter()
          .append('rect')
          .attr('class', 'bar')
          .attr('x', (d, i) => x(i))
          .attr('y', function (d) {
            if (Number(d.value) >= 0) {
              return y(Number(d.value));
            } else {
              return y(0);
            }
       
          })
          .attr('width', bar_width)
          .attr('height', function (d) {
            if (Number(d.value) >= 0) {
              if (y(0) != height) {
                return height - y(Number(d.value));
              } else {
                return y(0) - y(Number(d.value));
              }
            } else {
              return y(Number(d.value)) - y(0);
            }
          })
          .attr('fill', '#333333')
          .on('click', function (d) {
            that.showSparkChart(ius_obj);
          }).append('title')
          .text(function (d) {
            return d.value + " (" + that.commonService.covertTimePeriod(d.time_period) + ")";
          });
      }
}

     



      // block code  
      // if (this.mapLevel == 4) {
      //   let ius_obj = d;
      //   let time_period = response.data[0].record.map(function (d) {
      //     return d.time_period;
      //   })
      //   let maxValue = d3.max(response.data[0].record, function (d) {
      //     return Number(d.numerator);
      //   });
      //   let minValue = d3.min(response.data[0].record, function (d) {
      //     return Number(d.numerator);
      //   });
      //   width = minValue == maxValue ? 5 : width;
      //   minValue = minValue == maxValue ? 0 : minValue;
      //   let data_count = data.length;
      //   let bar_width = (width - data_count) / data_count;
      //   let x = d3_scaleLinear().domain([0, data_count]).range([0, 0.9 * width]);
      //   let y = d3_scaleLinear().domain([minValue, maxValue]).range([height, 0]).nice();
      //   let that = this;
      //   let node_width = subgroupNode.node().getBoundingClientRect().width + 2;
      //   nodeData.append('g').attr('width', width)
      //     .attr('height', height).attr('class', 'spark_chart')
      //     .attr("transform", "translate(" + node_width + ", -10)")
      //     .selectAll('.bar').data(response.data[0].record)
      //     .enter()
      //     .append('rect')
      //     .attr('class', 'bar')
      //     .attr('x', (d, i) => x(i))
      //     .attr('y', function (d) {
      //       if (Number(d.numerator) >= 0) {
      //         return y(Number(d.numerator));
      //       } else {
      //         return y(0);
      //       }
      //     })
      //     .attr('width', bar_width)
      //     .attr('height', function (d) {
      //       if (Number(d.numerator) >= 0) {
      //         if (y(0) != height) {
      //           return height - y(Number(d.numerator));
      //         } else {
      //           return y(0) - y(Number(d.numerator));
      //         }
      //       } else {
      //         return y(Number(d.numerator)) - y(0);
      //       }
      //     })
      //     .attr('fill', '#333333')
      //     .on('click', function (d) {
      //       that.showSparkChart(ius_obj);
      //     }).append('title')
      //     .text(function (d) {

      //       return d.numerator + " (" + that.commonService.covertTimePeriod(d.time_period) + ")";
      //     });
      // }
    });
  }

  getTreeData() {
    $(".loaders ol li").fadeIn();
    this.dataService.getAll('api/get-tree-chart-parameters/' + this.sectorId +"/"+ this.mapLevel).subscribe((response: any) => {
      $(".loaders ol li").fadeOut();
      this.treeData = response.data;
      this.treecollapse();

    })
    setTimeout(function () {
      $("#render").animate({ scrollTop: Number($("#render").find('svg').attr('height')) / 3 });
    }, 1000);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  showSparkChart(ius_obj) {
    $("#loaders_rankdata ol li").fadeIn();
    let str = ius_obj.parent.name.split('|');
    $('#chartContainerCollapsibleTree').html('');
    this.chartTitle = this.areaName + ' - ' + str[0] + ' | ' + str[1] + ' | ' + ius_obj.name;
    this.xaxis = [];
    this.yaxis = '';
    this.sourceData = [];
    this.sourceOfMap = '';
    this.seriesData = [];
    this.dataService.getAll('api/get-datavalue/' + ius_obj.treeId + '/' + this.areaID).subscribe((response: any) => {
      response.data.forEach(elem => {
        elem.time_period = this.commonService.covertTimePeriod(elem.time_period);
      })

      if(this.mapLevel == 4){
      response.data[0].record.forEach(element => {
        if (this.sourceData.indexOf(element.source) == -1) {
          this.sourceData.push(element.source)
          this.sourceOfMap = this.sourceData.join('');
        }
        this.yaxis = response.data[0].ius.unit;
        let tempTimePeriod = this.commonService.covertTimePeriod(element.time_period);
        if (this.xaxis.indexOf(tempTimePeriod) == -1) {
          this.xaxis.push(tempTimePeriod);
            this.seriesData.push(Number(element.numerator));
        
          }
        })
      }else{
        response.data[0].record.forEach(element => {
          if (this.sourceData.indexOf(element.source) == -1) {
            this.sourceData.push(element.source)
            this.sourceOfMap = this.sourceData.join('');
          }
          this.yaxis = response.data[0].ius.unit;
          let tempTimePeriod = this.commonService.covertTimePeriod(element.time_period);
          if (this.xaxis.indexOf(tempTimePeriod) == -1) {
            this.xaxis.push(tempTimePeriod);
            this.seriesData.push(Number(element.value));
          
        }
      })
      }
    
      this.vizData['data'] = {};
      this.vizData['indicator'] = this.chartTitle;
      this.vizData['data_source'] = this.sourceOfMap;
      this.vizData['data']['xaxis'] = this.xaxis;
      this.vizData['data']['yaxis'] = this.yaxis;
      this.vizData['data']['series'] = [{
        name: '',
        data: this.seriesData
      }];
      this.vizData['renderId'] = 'chartContainerCollapsibleTree';
      this.vizData['data']['type'] = 'column';
      this.chart_render = new Highcharts.Chart(this.vizService.loadRankChart(this.vizData));
      $('#spark_chart_modal').modal('show');
      $("#loaders_rankdata ol li").fadeOut();
    });
  }

  // download chart
  downloadColumnChart() {
    this.chart_render.exportChart({
      type: "image/jpeg",
    })
  }

}