import { Component, OnInit, Input } from "@angular/core";
import { HeaderService } from "src/app/services/header.service";
import { _ } from "underscore";

import { Subscription } from "rxjs";
import { Configuration } from "../../../../app.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { CommonService } from "src/app/services/common.service";

declare var L: any;
declare var $: any;

@Component({
  selector: "app-graph-page2",
  templateUrl: "./graph-page2.component.html",
  styleUrls: ["./graph-page2.component.css"],
})
export class GraphPage2Component implements OnInit {
  @Input() timePeriod: any;
  subscription: Subscription;
  private url: any;

  public penta = [];
  public bcg = [];
  public penta1 = [];
  public penta3 = [];
  public mapLevel = 1;
  public source = 1;
  public mapRecentTimePeriod = [];

  areaID = "IND";
  area_name = "IND";
  // timePeriod: any;
  bcg_value: [];
  penta1_value: [];
  penta3_value: [];
  loading=true;
  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private configuration: Configuration,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.url = configuration.server;

    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "header":
          this.source = 1;
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.getTable();
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {}

  ngOnChanges() {
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getTable();
  }
  getRoundValue(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  getPerValue(numerator, denominator) {
    var value = this.getRoundValue((numerator / denominator) * 100, 1);

    if (value) {
      return value;
    }

    return 0;
  }

  getTable() {
    let that = this;

    this.dataService
      .getAll("api/page11/" + this.timePeriod + "/" + this.areaID + "/")
      .subscribe((response: any) => {
        this.loading=false;
        that.penta = response.data;
        that.area_name = that.penta[0].area_name;

        let bcg = [];
        let penta1 = [];
        let penta3 = [];

        that.penta.forEach((row) => {
          bcg.push([
            row.area_name,
            that.getPerValue(row.BCG_num, row.BCG_dem),
            row.area_code, 'BCG Coverage'
          ]);
          penta1.push([
            row.area_name,
            that.getPerValue(row.penta1_num, row.penta1_dem),
            row.area_code, 'Pentavalent 1st Dose'
          ]);
          penta3.push([
            row.area_name,
            that.getPerValue(row.penta3_num, row.penta3_dem),
            row.area_code, 'Pentavalent 3rd Dose'
          ]);
        });

        this.bcg = bcg;
        this.penta1 = penta1;
        this.penta3 = penta3;

        for (let i = 0; i < this.bcg.length; i++) {
          let val = this.bcg[0];
          this.bcg_value = val[1];
        }

        for (let i = 0; i < this.penta1.length; i++) {
          let val = this.penta1[0];
          this.penta1_value = val[1];
        }

        for (let i = 0; i < this.penta3.length; i++) {
          let val = this.penta3[0];
          this.penta3_value = val[1];
        }
      });
  }

  getObjectKey(object) {
    if (object) {
      return Object.keys(object);
    }

    return [];
  }
  ngAfterViewInit(): void {}
}
