import { ActivatedRoute } from "@angular/router";
import { ApiService } from "../../services/api.service";
import {
  Component,
  ViewChild,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit
} from "@angular/core";
import {
  MatPaginator,
  MatSort,
  MatTableDataSource,
  MatDialog
} from "@angular/material";
import { HeaderService } from "../../services/header.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { DialogMaterialComponent } from "../../dialog-material/dialog-material.component";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { link } from 'fs';
import { Fileupload } from "blueimp-file-upload/js/jquery.fileupload";
import { ChangeEvent } from "@ckeditor/ckeditor5-angular/ckeditor.component";
import * as html2canvas from "html2canvas";
import { ViewEncapsulation } from "@angular/core";
import { UseCaseService } from "../../services/use-case.service";
import { GetCaseColumnService } from "../../services/get-case-column.service";
import { GetCasePieService } from "../../services/get-case-pie.service";
import { VisualizationRendererService } from "../../services/visualization-renderer";
import * as Highcharts from "highcharts";
import { chart } from "highcharts";
import * as Highmaps from "highcharts/highmaps";

declare var $: any;

@Component({
  selector: "app-report-mgm",
  templateUrl: "./report-mgm.component.html",
  styleUrls: ["./report-mgm.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class ReportMgmComponent implements OnInit {
  constructor(
    private dataService: ApiService,
    private headerService: HeaderService,
    private form: FormBuilder,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private useCase: UseCaseService,
    private columnUseCase: GetCaseColumnService,
    private pieUseCase: GetCasePieService,
    public vizService: VisualizationRendererService
  ) {}

  dashboardId;
  form_open: boolean = false;
  addForm: FormGroup;
  dashboardRecords = [];
  ourFile: File;
  base64Image = "";
  showEdit: boolean = false;
  titlePage: string;
  validationError: any;
  sectionId: number;
  sequence: number = 0;
  editorHtml: string;
  displayedColumns = ["id", "section_name", "status", "created_at", "actions"];
  dataSource: MatTableDataSource<any>;
  reportType: number;
  reportImage: string;
  report_data;
  reportDataObject;
  report_id: any = "";
  object_id: any = "";
  selectedItem: any;
  clear: number;
  map_data = [];
  dataArr = [];
  pageNo: any = "";
  narrationPageTwoData: any = "";

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(ClassicEditor) editorDirective;

  public Editor = ClassicEditor;
  public model = {
    editorData: "<p>You can paste or write your narration here</p>"
  };

  ngOnInit() {
    this.showPageContent(1);
    /***upload specific***/

    /***upload specific***/
    let that = this;
    // $(document).on("click", ".com_thing", function() {

    // });

    $(document).on("click", ".delete", function() {
      $(".com_thing.active").hide();
    });
    /*** add thumbnail removed as per requirement
    $(document).on("click",".add_thumbnail",function(){
      $("#sortable li.active").after("<li class='ui-state-default com_thing new_li'></li>");
    }); */

    // $(document).on("click", "#add_new_page", function(){
    //   console.log('li:eq('+(this.no_of_pages-1)+')');
    //   console.log($('#sortable li:eq('+(this.no_of_pages-1)+')'));
    //   $('<li class="ui-state-default com_thing">'+this.no_of_pages+'</li>').insertAfter('#sortable li:eq('+(this.no_of_pages-1)+')');
    // });

    /***sorting***/
    // $(function() {
    //   $("#sortable").sortable();
    // });
    /***sorting***/

    this.route.params.subscribe(params => {
      this.dashboardId = params["id"];
    });

    this.headerService.setTitle("Dashboard Management");
    this.headerService.setLogo("assets/lib/images/svg/report-mgm.svg");
    //this.headerService.setTitle('Data Summary');
    const body = document.getElementsByTagName("body")[0];
    body.classList.add("need-head");

    this.addForm = this.form.group({
      section_name: ["", Validators.required]
    });

    $(function() {
      "use strict";
      // Change this to the location of your server-side upload handler:
      var url = "api/report/image/upload";
      $("#file")
        .fileupload({
          maxChunkSize: 2000000,
          acceptFileTypes: /(\.|\/)(png)$/i,
          url: url,
          dataType: "json",
          formData: {
            import_for: 1
          },
          // add: function (e, data) {
          //   $("#upload").off('click').on('click', function () {
          //       data.submit();
          //   });
          // },
          beforeSend: function(xhr, data) {
            xhr.setRequestHeader("Accept", "application/json");
            xhr.setRequestHeader(
              "Authorization",
              `Bearer ${localStorage.getItem("auth_token")}`
            );
          },
          done: function(e, data) {
            that.reportType = 2;
            that.reportImage = data.result.src_path;
            // that.object_id = '';
            $("#myModal_pop").modal("hide");
            $(".size_a4")
              .html("")
              .append(
                '<img src="' +
                  data.result.src_path +
                  '" style="width:100%; height:100%">'
              );
          },
          progressall: function(e, data) {
            var loaded: number = data.loaded;
            var total: number = data.total;
            var tempCalc = (loaded / total) * 100;

            var progress = parseInt(tempCalc.toString(), 10);

            $("#progress .progress-bar").css("width", progress + "%");
          }
        })
        .prop("disabled", !$.support.fileInput)
        .parent()
        .addClass($.support.fileInput ? undefined : "disabled");

      $("#flipbook_pdf")
        .fileupload({
          maxChunkSize: 2000000,
          acceptFileTypes: /(\.|\/)(pdf)$/i,
          url: "api/report/upload-flipbook",
          dataType: "json",
          beforeSend: function(xhr, data) {
            xhr.setRequestHeader("Accept", "application/json");
            xhr.setRequestHeader(
              "Authorization",
              `Bearer ${localStorage.getItem("auth_token")}`
            );
          },
          done: function(e, data) {},
          progressall: function(e, data) {
            var loaded: number = data.loaded;
            var total: number = data.total;
            var tempCalc = (loaded / total) * 100;

            var progress = parseInt(tempCalc.toString(), 10);

            $("#progress .progress-bar").css("width", progress + "%");
          }
        })
        .prop("disabled", !$.support.fileInput)
        .parent()
        .addClass($.support.fileInput ? undefined : "disabled");
    });

    // $(".loaders ol li").fadeIn();
    $(".loaders ol li").fadeOut();
    // this.dataService.getAll('api/report/all').subscribe((response:any) => {
    //   $(".loaders ol li").fadeOut();
    //   this.report_data = response.data.report_details.report_sequence;
    //   // this.sequence = response.data.report_details.report_sequence[(response.data.report_details.report_sequence.length-1)].sequence;
    //   this.report_id = response.data.report_details.id;
    //   $('#sortable').sortable();
    // });
  }

  ngAfterViewInit() {
    // this.dataService.getAll('api/report/get-page-content-admin/1').subscribe((response: any) => {
    //   $('#reportViz').html('<img src="'+response.data.reportObject.image+'" alt="Cover Image" class="img-responsive"/>');
    //   console.log(response.data.reportObject.image , "preeti")
    // });

    $("#sortable li:first-child").click();
  }
  // uploadFile(event) {
  //   document.getElementById("file-name").innerHTML = event.target.files[0].name;
  // }

  getReportObject(data) {
    // $(".com_thing").removeClass("active");
    //   $(this).addClass("active");
    this.selectedItem = data;
    this.reportType = data.report_sequence_objects.type;
    this.dataService
      .add("api/report/get-page-object", {
        report: data.report_id,
        object: data.report_sequence_objects.id,
        type: data.report_sequence_objects.type
      })
      .subscribe((response: any) => {
        this.map_data = [];
        this.dataArr = [];
        this.report_id = response.data.reportPageObject.report_id;
        this.object_id = response.data.reportPageObject.id;
        this.sequence = response.data.reportPageObject.report_sequence.sequence;
        if (this.reportType == 1) {
          this.reportDataObject = response.data.report_object;
          for (let i = 0; i < response.data.report_object.length; i++) {
            let report_chart_type = response.data.report_object[i].chartType;
            let reportData = response.data.report_object[i].data;
            let ius = [];
            let indicator = [];
            let unit = [];
            let subgroup = [];
            let area = [];
            let time_period = [];
            let visualizationData = {};

            reportData.forEach(element => {
              if (
                ius.indexOf(
                  element.indicator +
                    "|" +
                    element.unit +
                    "|" +
                    element.subgroup
                ) == -1
              ) {
                ius.push(
                  element.indicator +
                    "|" +
                    element.unit +
                    "|" +
                    element.subgroup
                );
              }
              element["ius"] =
                element.indicator + "|" + element.unit + "|" + element.subgroup;
              if (indicator.indexOf(element.indicator) == -1) {
                indicator.push(element.indicator);
              }
              if (unit.indexOf(element.unit) == -1) {
                unit.push(element.unit);
              }
              if (subgroup.indexOf(element.subgroup) == -1) {
                subgroup.push(element.subgroup);
              }
              if (area.indexOf(element.area_name) == -1) {
                area.push(element.area_name);
              }
              if (time_period.indexOf(element.time_period) == -1) {
                time_period.push(element.time_period);
              }
              this.dataArr.push(Number(element.value));
              this.map_data.push({
                code: element.area_code,
                value: Number(element.value)
              });
            });

            let indicator_count = indicator.length;
            let unit_count = unit.length;
            let subgroup_count = subgroup.length;
            let area_count = area.length;
            let time_period_count = time_period.length;

            if (report_chart_type != "map") {
              let useCase = this.useCase.findUseCase(
                report_chart_type,
                indicator_count,
                unit_count,
                subgroup_count,
                time_period_count,
                area_count
              );
              visualizationData["ius"] = ius;
              visualizationData["area"] = area;
              visualizationData["time_period"] = time_period;
              visualizationData["data"] = reportData;
              visualizationData["useCase"] = useCase;
              visualizationData["chartType"] = report_chart_type;

              let report_chart_data = this.getVizData(visualizationData);
              report_chart_data["data"] = {};
              report_chart_data["data"]["xaxis"] = report_chart_data["xaxis"];
              report_chart_data["data"]["series"] = report_chart_data["series"];
              report_chart_data["vizid"] = response.data.report_object[i].id;
              report_chart_data["chartType"] =
                response.data.report_object[i].chartType;
              // report_chart_data['data']['type'] = response.data.report_object[i].chartType;
              //let chart_render = new Highcharts.Chart(this.vizService.loadColumnChart(report_chart_data));
              this.createViz(report_chart_data);
            } else {
              this.renderMap();
            }
          }
        } else {
          $("#reportViz").html(response.data.report_object);
        }
      });
  }

  showPageContent(page_no) {
    $(".loaders ol  li").fadeIn();
    this.pageNo = page_no;
    console.log(this.reportType);
    switch (page_no) {
      case 1:
        this.dataService
          .getAll("api/report/get-page-content-admin/" + page_no)
          .subscribe((response: any) => {
            $("#reportViz").html(
              '<img src="' +
                response.data.reportObject.image +
                '" alt="Cover Image" class="img-responsive"/>'
            );
            $(".loaders ol  li").fadeOut();
          });
        this.reportType = 4;
        break;
      case 2:
        this.dataService
          .getAll("api/report/get-page-content-admin/" + page_no)
          .subscribe((response: any) => {
            this.model.editorData = response.data.reportObject.narration;
            $("#reportViz").html(response.data.reportObject.narration);
            $(".loaders ol  li").fadeOut();
          });
        this.reportType = 3;
        break;
      case 3:
        this.dataService
          .getAll("api/report/get-page-content-admin/" + page_no)
          .subscribe((response: any) => {
            this.model.editorData = response.data.reportObject.narration;
            $("#reportViz").html(response.data.reportObject.narration);
            $(".loaders ol  li").fadeOut();
          });
        this.reportType = 3;
        break;
      case 4:
        this.dataService
          .getAll("api/report/get-page-content-admin/" + page_no)
          .subscribe((response: any) => {
            this.model.editorData = response.data.reportObject.narration;
            $("#reportViz").html(response.data.reportObject.narration);
            $(".loaders ol  li").fadeOut();
          });
        this.reportType = 3;
        break;
      case 36:
        this.dataService
          .getAll("api/report/get-page-content-admin/" + page_no)
          .subscribe((response: any) => {
            $("#reportViz").html(
              '<img src="' +
                response.data.reportObject.image +
                '" alt="Cover Image" class="img-responsive"/>'
            );
            $(".loaders ol  li").fadeOut();
          });
        this.reportType = 5;
        break;
    }
  }

  getVizData(vizObj) {
    let vizData;
    switch (vizObj.chartType) {
      case "line":
        vizData = this.columnUseCase[vizObj.useCase](vizObj);
        break;
      case "bar":
        vizData = this.columnUseCase[vizObj.useCase](vizObj);
        break;
      case "column":
        vizData = this.columnUseCase[vizObj.useCase](vizObj);
        break;
      case "pie":
        vizData = this.pieUseCase[vizObj.useCase](vizObj);

      default:
        // code...
        break;
    }

    return vizData;
  }

  addNewPage() {
    $(
      '<li class="ui-state-default com_thing">' + this.sequence + "</li>"
    ).insertAfter("#sortable li:eq(" + (this.sequence - 1) + ")");
    this.sequence++;
    $("#sortable").sortable("refresh");
  }

  /**
   * method that execute on changing the value of matInput
   * @param filterValue
   */
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  /**
   * method that executes  to intiliaze the table with data
   */
  getAll() {
    /**
     * api call to get all the records from dataService
     */
    this.dataService
      .getById<any[]>("api/dashboard/section", this.dashboardId)
      .subscribe(
        (res: any) => {
          this.dataSource = new MatTableDataSource(res.data.records);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.dashboardRecords = res.data.records;
        },
        error => () => {
          console.log("Error");
        },
        () => {
          //this.toasterService.pop('success', 'Complete', 'Getting all values complete');
          //this.slimLoadingBarService.complete();
        }
      );
  }

  /**
   *  method that executes on submitting form
   */
  add() {
    //api call to add a dashboard
    this.addForm.value.dashboard_id = this.dashboardId;

    this.validationError = [];
    this.dataService
      .add<any[]>("api/dashboard/section/add", this.addForm.value)
      .subscribe(
        (res: any) => {
          if (res.status == 0) {
            this.validationError = res.error;
          } else {
            // toaster message on successfully submitting data to the table
            this.toastr.success("Section added successfully");

            // reset the form after submitting form data
            this.addForm.reset();
            // close the form modal
            this.form_open = !this.form_open;
            // get all the data  in the table
            this.getAll();
          }
        },
        error => () => {
          console.log("Error");
        },
        () => {
          //this.toasterService.pop('success', 'Complete', 'Getting all values complete');
          //this.slimLoadingBarService.complete();
        }
      );
  }

  /**
   *  method that executes on submitting form
   */
  update() {
    //api call to add a dashboard
    this.addForm.value.dashboard_id = this.dashboardId;

    this.validationError = [];
    this.dataService
      .update<any[]>(
        "api/dashboard/section/update",
        this.sectionId,
        this.addForm.value
      )
      .subscribe(
        (res: any) => {
          if (res.status == 0) {
            this.validationError = res.error;
          } else {
            // toaster message on successfully submitting data to the table
            this.toastr.success("Section updated successfully");

            // reset the form after submitting form data
            this.addForm.reset();
            // close the form modal
            this.form_open = !this.form_open;
            // get all the data  in the table
            this.getAll();
          }
        },
        error => () => {
          console.log("Error");
        },
        () => {
          //this.toasterService.pop('success', 'Complete', 'Getting all values complete');
          //this.slimLoadingBarService.complete();
        }
      );
  }

  /**
   *  method that trigger the click event on input type file on button click
   */
  openInput() {
    document.getElementById("logo").click();
  }

  /**
   * method that executes on  deletion of record
   * @param id // id of deleted record
   */
  deleteDashboard(id) {
    const dialog = this.dialog.open(DialogMaterialComponent, {
      width: "350px",
      data: {
        messageDialog: "Are you sure to delete record?",
        delete: true
      }
    });
    dialog.afterClosed().subscribe(selection => {
      if (selection) {
        this.dataService
          .delete("api/user/delete", id)
          .subscribe((response: any) => {
            // show toaster message on successfully deleting the user
            this.toastr.success("Report gallery deleted successfully");
            // get all the users after deleting the user
            this.getAll();
          });
      } else {
        // User clicked 'Cancel' or clicked outside the dialog
      }
    });
  }

  openForm(rowData) {
    this.validationError = [];

    this.form_open = !this.form_open;
    if (rowData !== undefined) {
      this.titlePage = "Edit";
      this.showEdit = true;
      this.addForm.patchValue({
        section_name: rowData.name
      });

      this.sectionId = rowData.id;
    } else {
      this.titlePage = "New";
      this.showEdit = false;
    }
  }

  public onChange({ editor }: ChangeEvent) {
    this.editorHtml = "";
    const data = editor.getData();
    this.editorHtml = data;
  }

  saveNarration() {
    this.reportType = 3;
    // this.object_id = '';
    $("#myModal_pop").modal("hide");
    $("#narration_modal").modal("hide");
    $("#reportViz")
      .html("")
      .html(this.editorHtml);
  }

  saveReportPage() {
    let data = $(".size_a4").html();
    var thumbnail = "";
    this.dataService
      .add("api/report/update-report-component", {
        data: data,
        report_id: this.report_id,
        page_no: this.pageNo,
        type: this.reportType,
        thumbnail: this.reportImage
      })
      .subscribe((response: any) => {
        if (response.status == 1) {
        }
      });
    var that = this;
    if(this.object_id == ''){
      this.sequence = this.sequence+1;
    }
    // if(this.reportType == 3 || this.reportType == 1){
    //   let screenshot = html2canvas($('.size_a4').get(0)).then(function(canvas) {
    //     that.dataService.add('api/report/add-objects', {'data':data, 'report_id': that.report_id, 'object_id': that.object_id, 'thumbnail' : canvas.toDataURL('image/png'), 'type':that.reportType, 'sequence':that.sequence}).subscribe((response: any) => {
    //       if(response.status == 1){
    //         if(that.object_id == ''){
    //           $('#sortable').append('<li class="ui-state-default com_thing" data-report_id="'+response.data.report_id+'" data-object_id="'+response.data.object_id+'" data-sequence_id="'+response.data.sequence_id+'" data-type="'+response.data.type+'"><img class="cover_img" src="'+response.data.image+'"></li>');
    //           // $('<li class="ui-state-default com_thing" data-report_id="'+response.data.report_id+'" data-object_id="'+response.data.object_id+'" data-sequence_id="'+response.data.sequence_id+'"><img src="'+response.data.thumbnail+'"></li>').insertAfter('#sortable li:eq('+(that.sequence-1)+')');
    //           $("#sortable").sortable();
    //         }else{
    //           $('#sortable').find('li:nth-child('+that.sequence+')').html('<img class="cover_img" src="'+response.data.image+'">');
    //         }
    //       }
    //     });
    //   });
    // }
    // else if(this.reportType == 2 || this.reportType == 4 || this.reportType == 5){
    //   this.dataService.add('api/report/add-objects', {'data' : this.reportImage, 'report_id': this.report_id, 'object_id': this.object_id, 'thumbnail' : this.reportImage, 'type' : this.reportType, 'sequence':this.sequence}).subscribe((response: any ) => {
    //     if(this.object_id == ''){
    //       $('#sortable').append('<li class="ui-state-default com_thing" data-report_id="'+response.data.report_id+'" data-object_id="'+response.data.object_id+'" data-sequence_id="'+response.data.sequence_id+'" data-type="'+response.data.type+'"><img class="cover_img" src="'+response.data.image+'"></li>');
    //       // $('<li class="ui-state-default com_thing" data-report_id="'+response.data.report_id+'" data-object_id="'+response.data.object_id+'" data-sequence_id="'+response.data.sequence_id+'"><img src="'+response.data.thumbnail+'"></li>').insertAfter('#sortable li:eq('+(this.sequence-1)+')');
    //       $("#sortable").sortable();
    //     }else{
    //       $('#sortable').find('li:nth-child('+this.sequence+')').html('<img class="cover_img" src="'+response.data.image+'">');
    //     }
    //   });
    // }
  }

  createViz(vizData) {
    $("#reportViz").html("");
    let reportViz = document.getElementById("reportViz");
    let e = document.createElement("div");
    var chart_render;
    e.setAttribute("id", "chart_container_" + vizData["vizid"]);
    reportViz.appendChild(e);

    vizData["renderId"] = document.getElementById(
      "chart_container_" + vizData["vizid"]
    );
    switch (vizData["chartType"]) {
      case "line":
        chart_render = new Highcharts.Chart(
          this.vizService.loadColumnChart(vizData)
        );
        break;
      case "bar":
        chart_render = new Highcharts.Chart(
          this.vizService.loadColumnChart(vizData)
        );
        break;
      case "column":
        chart_render = new Highcharts.Chart(
          this.vizService.loadColumnChart(vizData)
        );
        break;
      case "pie":
        chart_render = new Highcharts.Chart(
          this.vizService.loadPieChart(vizData)
        );

      default:
        // code...
        break;
    }
  }

  showNarrationModal(clear_value) {
    if (clear_value == 1) {
      console.log(clear_value, "cvvbvn");
      // this.report_id = '';
      this.object_id = "";
    }
    if (this.pageNo == 1 || this.pageNo == 36) {
      $("#myModal_pop").modal("show");
    } else {
      $("#narration_modal").modal("show");
    }
  }

  renderMap() {
    $("#reportViz").html("");
    let reportViz = document.getElementById("reportViz");
    let e = document.createElement("div");
    var chart_render;
    e.setAttribute("id", "chart_container_1");
    reportViz.appendChild(e);
    let chartTarget = document.getElementById("chart_container_1");

    let obj = this;
    $.getJSON("assets/lib/map/IND_l02_2011_Census.json", function(geojson) {
      //this.http.get('assets/lib/map/IND_l02_2011_Census.json').subscribe((response: any) => {
      // Load the drilldown map
      let map = geojson;
      let opts: any = {
        credits: {
          enabled: false
        },
        chart: {
          map: geojson
        },

        title: {
          text: ""
        },

        subtitle: {
          text: ""
        },

        mapNavigation: {
          enabled: true,
          buttonOptions: {
            verticalAlign: "bottom"
          }
        },

        colorAxis: {
          min: Math.min.apply(Math, obj.dataArr),
          minColor: "#b6effd",
          maxColor: "#1c84a0"
        },

        series: [
          {
            data: obj.map_data,
            mapData: map,
            joinBy: "code",
            name: "India",
            // states: {
            //   hover: {
            //     color: '#BADA55'
            //   }
            // },
            dataLabels: {
              enabled: true,
              format: "{point.name}"
            }
          }
        ]
      };

      Highmaps.mapChart(chartTarget, opts);
    });
  }

  showFileUpload() {
    $("#flipbook_pdf").click();
  }

  uploadFlipbookPdf(obj) {
    console.log(obj);
    let flipbook_pdf: File = obj.target.files[0];
    console.log(flipbook_pdf);
    var formData: FormData = new FormData();
    formData.append("upload", flipbook_pdf);
    console.log(formData);
    this.dataService
      .add<any[]>("api/report/upload-flipbook", formData)
      .subscribe((response: any) => {
        console.log(response);
      });
  }
}
