import { Component, OnInit, Injectable, AfterViewInit } from "@angular/core";
import { HeaderService } from "src/app/services/header.service";
import { HttpClient } from "@angular/common/http";
import { _ } from "underscore";

import { Subscription } from "rxjs";
import { Configuration } from "../../app.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { CommonService } from "src/app/services/common.service";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
declare var L: any;
declare var $: any;
var locations = [];

@Component({
  selector: "app-mr-sheet",
  templateUrl: "./mr-sheet.component.html",
  styleUrls: ["./mr-sheet.component.css"],
})
export class MrSheetComponent implements AfterViewInit {
  chart: any;
  mapOptions = {
    zoom: 13,
    zoomSnap: 0.15,
    center: L.latLng(2.74031066894531, 11.102173805236816),
  };
  map: any;
  subscription: Subscription;
  private url: any;
  district = "NA";
  nfhs5fic = "NA";
  nfhs5mcv1 = "NA";
  nfhs5mcv2 = "NA";

  data = [];
  public penta = [];
  public penta1 = [];
  public mr1 = [];
  public mr2 = [];
  public dtp1 = [];
  public immu = [];
  public penta_mr1 = [];
  public mr1_mr2 = [];
  public mr2_dtp1 = [];
  public mapLevel = 1;
  public source = 1;
  public mapRecentTimePeriod = [];
  mr1nfhs = {};
  infant = {};
  selectedtp: "mrd";
  areaID = "IND";
  area_name = "IND";
  timePeriod: any;
  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private configuration: Configuration,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.getTimeperiods();
    this.url = configuration.server;
    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "header":
          this.source = 1;
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.getAllPenta();
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {
    this.headerService.setTitle("Measles-Rubella Vaccination Fact Sheet");
    this.headerService.setLogo("assets/lib/images/svg/sidebar/data-import.svg");
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getAllPenta();
  }
  ngAfterViewInit(): void {}

  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodChar(timePeriod);
  }

  getFinancialYear(timePeriod) {
    return this.commonService.getFinancialYear(timePeriod);
  }

  getRoundValue(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  getPerValue(numerator, denominator) {
    var value = this.getRoundValue((numerator / denominator) * 100, 1);
    if (value) {
      return value;
    }
    return 0;
  }

  changeTimePeriod(event, type) {
    this.timePeriod = event.value;
    this.getAllPenta();
  }

  getAllPenta() {
    let that = this;
    $(".loaders ol li").fadeIn();
    this.dataService
      .getAll("api/fact1/" + this.timePeriod + "/" + this.areaID + "/")
      .subscribe((response: any) => {
        $(".loaders ol li").fadeOut();
        that.penta = response.data;
        this.mapRecentTimePeriod = response.time_period;
        that.area_name = that.penta[0].area_name;
        let penta1 = [];
        let mr1 = [];
        let mr2 = [];
        let dtp1 = [];
        let immu = [];
        let penta_mr1 = [];
        let mr1_mr2 = [];
        let mr2_dtp1 = [];

        response.infant.forEach((row) => {
          that.infant[row.area_name] = row.target;
        });

        that.penta.forEach((row) => {
          var dem = that.infant[row.area_name];
          penta1.push([
            row.area_name, that.getPerValue(row.penta1_num, dem), row.area_code,
          ]);
          mr1.push([
            row.area_name,that.getPerValue(row.mr1_num, dem),row.area_code,
          ]);
          mr2.push([
            row.area_name,that.getPerValue(row.mr2_num, dem), row.area_code,
          ]);
          dtp1.push([
            row.area_name,that.getPerValue(row.dtp1_num, dem), row.area_code,
          ]);
          immu.push([
            row.area_name, that.getPerValue(row.immu_num, dem), row.area_code,
          ]);
          penta_mr1.push([
            row.area_name,that.getPerValue(row.penta1_to_mcv1_num, dem), row.area_code,
          ]);
          mr1_mr2.push([
            row.area_name, that.getPerValue(row.mcv1_to_mcv2_num, row.mcv1_to_mcv2_dem), row.area_code,
          ]);
          mr2_dtp1.push([
            row.area_name, that.getPerValue(row.mcv2_dtp_num, row.mcv2_dtp_dem), row.area_code,
          ]);
        });

        this.penta1 = penta1;
        this.mr1 = mr1;
        this.mr2 = mr2;
        this.dtp1 = dtp1;
        this.immu = immu;
        this.mr1_mr2 = mr1_mr2;
        this.penta_mr1 = penta_mr1;
        this.mr2_dtp1 = mr2_dtp1;

        if (!that.timePeriod || that.timePeriod == "mrd") {
          that.timePeriod = this.mapRecentTimePeriod[0].time_period;
        }

        response.mr1nfhs.forEach((row) => {
          that.mr1nfhs[row.area_name] = row.value;
        });

        try {
          that.district = response.district[0].district;
          that.nfhs5fic = response.nfhs5fic[0].value;
          that.nfhs5mcv1 = response.nfhs5mcv1[0].value;
          that.nfhs5mcv2 = response.nfhs5mcv2[0].value;
        } catch (e) {
          console.log(e);
        }
      });
  }

  getColorClass(value) {
    if (value < 75) {
      return "#ff0000";
    }
    if (value >= 75 && value < 80) {
      return "#fac08f";
    }
    if (value >= 80 && value < 85) {
      return "#ffff00";
    }
    if (value >= 85 && value < 90) {
      return "#92d050";
    }
    return "#548235";
  }

  getmr1_nfhs(area_name) {
    if (this.mr1nfhs[area_name]) {
      return this.mr1nfhs[area_name];
    }
    return "NA";
  }

  arr: any;
  getinfant(area_name) {
    if (this.infant[area_name]) {
      this.arr = this.infant[area_name];
      return this.arr;
    } else {
      return "NA";
    }
  }
  getObjectKey(object) {
    if (object) {
      return Object.keys(object);
    }
    return [];
  }

  public captureScreen() {
    let downLoadSection = document.getElementById("mrpdf") as HTMLElement;
    html2canvas(downLoadSection, { scale: 1 }).then(function (canvas) {
      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + top_left_margin * 2;
      let PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      canvas.getContext("2d");
      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
      pdf.addImage(
        imgData,
        "JPG",
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height
      );
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], "p");
        pdf.addImage(
          imgData,
          "JPG",
          top_left_margin,
          -(PDF_Height * i) + top_left_margin * 4,
          canvas_image_width,
          canvas_image_height
        );
      }
      pdf.save("MrSheet.pdf");
    });
  }

  getTimeperiods() {
    this.dataService.getAll("api/month/" + this.areaID + "/").subscribe((response: any) => {
        this.mapRecentTimePeriod = response.time_periods;
        this.selectedtp = this.mapRecentTimePeriod[0].time_period;
      });
  }
}
