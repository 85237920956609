import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class HeaderService {
  public title = new BehaviorSubject('Title');
  public logo_img = new BehaviorSubject('Logo_img');
  public loggedUserName = new BehaviorSubject('LoggedUserName');
  public loggedUserAreaName = new BehaviorSubject('India')

  constructor() { }

  setTitle(title) {
    this.title.next(title);
  }
  setLogo(logo_img) {
    this.logo_img.next(logo_img);
  }
  setUsername(loggedUserName) {
    this.loggedUserName.next(loggedUserName);
  }
  setLoggedUserAreaName(loggedUserAreaName) {
    this.loggedUserAreaName.next(loggedUserAreaName);
  }
}