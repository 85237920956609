import { Component, OnInit, Input } from "@angular/core";
import * as Highcharts from "highcharts";
import { VisualizationRendererService } from "../../../services/visualization-renderer";

@Component({
  selector: "app-dashboard-line-graph",
  templateUrl: "./dashboard-line-graph.component.html",
  styleUrls: ["./dashboard-line-graph.component.css"],
})
export class DashboardLineGraphComponent implements OnInit {
  @Input() item: any;
  @Input() chartId: any;
  @Input() type: any;
  @Input() color: any;

  chart: any;
  category = [];
  datas = [];
  penta: any;
  barColor = [];

  areaID = "IND";
  private url: any;
  timePeriod: any;

  constructor(public vizService: VisualizationRendererService) {}

  ngOnInit() {}

  ngOnChanges() {
    let that = this;

    if (that.item) {
      this.item;

      let mr2 = [];
      let indicator;
      that.item.forEach((row) => {
        let value = parseFloat(row[1]);
        indicator = row[3];
        mr2.push({
          name: row[0],
          y: value,
        });
      });
    
      that.options.title = {
        text: indicator,
        useHTML: true,
        style: {
          border: "1px solid red",
        },
      };
      that.options.series = [
        {
          data: mr2,
          lineWidth: 2,
          dashStyle: "Dash",
          lineColor: "black",
          showInLegend: false,
          dataSorting: {
            enabled: false,
            sortKey: "name",
          },
          dataLabels: {
            enabled: true,
          },
        },
      ];

      try {
        that.chart = new Highcharts.Chart(that.chartId, that.options);
      } catch (e) {}
    }
  }

  options: any = {
    chart: {
      type: "line",
    },
    colors: false,

    xAxis: {
      title: {
        enabled: true,
        text: "",
      },

      type: "category",
      labels: {
        enabled: true,
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      stackLabels: {
        enabled: true,
      },
    },
    legend: {
      enabled: false,
    },

    plotOptions: {
      series: {
        animation: false,
        groupPadding: 0.1,
        pointPadding: 0.1,
        lineWidth: 2,
        borderColor: "black",
        colorByPoint: true,
        dataSorting: {
          enabled: false,
          sortKey: "name",
        },
        type: "line",
        dataLabels: {
          enabled: false,
          format: "{point.y:.1f}", // one decimal
          y: -20, // 20 pixels down from the top
          style: {
            fontSize: "9px",
            fontFamily: "Verdana, sans-serif",
          },
          matchByName: true,
        },
      },
    },
    title: {
      text: "",
    },
    exporting: {
      enabled: false,
    },
    credits: { enabled: false },
    series: [
      {
        data: [0],
      },
    ],
  };
}
