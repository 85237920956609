import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { HeaderService } from "src/app/services/header.service";

import { _ } from "underscore";

import { Subscription } from "rxjs";
import { Configuration } from "../../../../app.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-concurrent-mon-page3",
  templateUrl: "./concurrent-mon-page3.component.html",
  styleUrls: ["./concurrent-mon-page3.component.css"],
})
export class ConcurrentMonPage3Component implements OnInit {
  @Input() timePeriod: any;
  subscription: Subscription;
  private url: any;
  areaID = "IND";
  area_name = "";
  // timePeriod: any;
  public mapRecentTimePeriod = [];
  public categories = [];
  public series = [];

  public categories2 = [];
  public series2 = [];
  loading=true;

  public mapLevel = 1;
  constructor(
    private headerService: HeaderService,
    private configuration: Configuration,
    private dataService: ApiService,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.url = configuration.server;

    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "header":
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.area_name = message.data.data.name;
          this.getTable();
          break;
        default:
          break;
      }
    });
  }
  ngOnInit() {}

  ngOnChanges() {
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getTable();
  }
  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodChar(timePeriod);
  }
 
  getYear(timePeriod) {
    return this.commonService.getFinancialFirstYear(timePeriod);
  }
  changeTimePeriod(event, type) {
    this.timePeriod = event.value;
    this.getTable();
  }
  getValue(data, indicator, area_name) {
    let value = "0";
    data.forEach((row) => {
      if (row.indicator == indicator && row.area_name == area_name) {
        value = row.value;
        return;
      }
    });
    return parseFloat(value);
  }

  getSeries(data, indicators, category) {
    let that = this;
    let series = [];
    indicators.forEach((indicator) => {
      let temp = [];
      category.forEach((area_name) => {
        temp.push(that.getValue(data, indicator, area_name));
      });
      series.push({
        name: indicator,
        data: temp,
      });
    });

    return series;
  }

  getTable() {
    let that = this;

    this.dataService
      .getAll("api/page20/" + this.timePeriod + "/" + this.areaID + "/")
      .subscribe((response: any) => {
        this.loading=false;
        this.mapRecentTimePeriod = response.time_period;

        // graph 2
        let category = [];
        let indicators = [];
        response.datastate.forEach((row) => {
          if (category.indexOf(row.area_name) == -1) {
            category.push(row.area_name);
          }
          if (indicators.indexOf(row.indicator) == -1) {
            indicators.push(row.indicator);
          }
        });
        that.series = that.getSeries(response.datastate, indicators, category);
        that.categories = category;
        // graph 1
        let category2 = [];
        let indicators2 = [];
        response.data.forEach((row) => {
          if (category2.indexOf(row.area_name) == -1) {
            category2.push(row.area_name);
          }
          if (indicators2.indexOf(row.indicator) == -1) {
            indicators2.push(row.indicator);
          }
        });
       
        // that.series2 = that.getSeries1(response.data, indicators2, category2);
        that.series2 = response.data;
        that.categories2 = indicators2;
       
      });
  }


  
}
