import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../services/api.service';
declare var $: any;


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  myStyle: object = {};
  myParams: object = {};
  width: number = 100;
  height: number = 100;
  token: any;

  constructor(
    public formBuilder: FormBuilder,
    private auth: AuthService,
    private dataService: ApiService,
    public activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    public router: Router,
  ) { }

  ngOnInit() {

    this.token = window.location.href.substr(window.location.href.lastIndexOf('/') + 1)

    this.myStyle = {
      'position': 'relative',
      'width': '100%',
      'height': '100vh',
      'top': 0,
      'left': 0,
      'z-index': '9999999',
    };
    this.myParams = {
      particles: {
        number: {
          value: 60,
        },
        color: {
          value: '#ffffff'
        },
        shape: {
          type: 'circle',
        },
      }
    };
    this.resetForm = this.formBuilder.group({
      email: ['', [Validators.required,
      Validators.pattern('[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})')]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      password_confirmation: ['', [Validators.required, Validators.minLength(6)]],
      token: this.token
    }, { validator: this.passwordMatchValidator });
  }
  ngAfterViewInit() {
    if (!this.auth.isLoggednIn()) {
      $('#login').css('display', 'block');
      $('#login').addClass('in');
    }
    $(document).ready(function () {
      $(".loaders ol li").delay(1000).fadeOut();
      var $slider = $(".slider"),
        $slideBGs = $(".slide__bg"),
        diff = 0,
        curSlide = 0,
        numOfSlides = $(".slide").length - 1,
        animating = false,
        animTime = 500,
        autoSlideTimeout,
        autoSlideDelay = 6000,
        $pagination = $(".slider-pagi");

      function createBullets() {
        for (var i = 0; i < numOfSlides + 1; i++) {
          var $li = $("<li class='slider-pagi__elem'></li>");
          $li.addClass("slider-pagi__elem-" + i).data("page", i);
          if (!i) $li.addClass("active");
          $pagination.append($li);
        }
      };

      createBullets();

      function manageControls() {
        $(".slider-control").removeClass("inactive");
        if (!curSlide) $(".slider-control.left").addClass("inactive");
        if (curSlide === numOfSlides) $(".slider-control.right").addClass("inactive");
      };

      function autoSlide() {
        autoSlideTimeout = setTimeout(function () {
          curSlide++;
          if (curSlide > numOfSlides) curSlide = 0;
          changeSlides(1);
        }, autoSlideDelay);
      };

      autoSlide();

      function changeSlides(instant) {
        if (!instant) {
          animating = true;
          manageControls();
          $slider.addClass("animating");
          $slider.css("top");
          $(".slide").removeClass("active");
          $(".slide-" + curSlide).addClass("active");
          setTimeout(function () {
            $slider.removeClass("animating");
            animating = false;
          }, animTime);
        }
        window.clearTimeout(autoSlideTimeout);
        $(".slider-pagi__elem").removeClass("active");
        $(".slider-pagi__elem-" + curSlide).addClass("active");
        $slider.css("transform", "translate3d(" + -curSlide * 100 + "%,0,0)");
        $slideBGs.css("transform", "translate3d(" + curSlide * 50 + "%,0,0)");
        diff = 0;
        autoSlide();
      }

      function navigateLeft() {
        if (animating) return;
        if (curSlide > 0) curSlide--;
        changeSlides(1);
      }

      function navigateRight() {
        if (animating) return;
        if (curSlide < numOfSlides) curSlide++;
        changeSlides(1);
      }

      $(document).on("mousedown touchstart", ".slider", function (e) {
        if (animating) return;
        window.clearTimeout(autoSlideTimeout);
        var startX = e.pageX || e.originalEvent.touches[0].pageX,
          winW = $(window).width();
        diff = 0;

        $(document).on("mousemove touchmove", function (e) {
          var x = e.pageX || e.originalEvent.touches[0].pageX;
          diff = (startX - x) / winW * 70;
          if ((!curSlide && diff < 0) || (curSlide === numOfSlides && diff > 0)) diff /= 2;
          $slider.css("transform", "translate3d(" + (-curSlide * 100 - diff) + "%,0,0)");
          $slideBGs.css("transform", "translate3d(" + (curSlide * 50 + diff / 2) + "%,0,0)");
        });
      });

      $(document).on("mouseup touchend", function (e) {
        $(document).off("mousemove touchmove");
        if (animating) return;
        if (!diff) {
          changeSlides(true);
          return;
        }
        if (diff > -8 && diff < 8) {
          changeSlides(1);
          return;
        }
        if (diff <= -8) {
          navigateLeft();
        }
        if (diff >= 8) {
          navigateRight();
        }
      });

      $(document).on("click", ".slider-control", function () {
        if ($(this).hasClass("left")) {
          navigateLeft();
        } else {
          navigateRight();
        }
      });

      $(document).on("click", ".slider-pagi__elem", function () {
        curSlide = $(this).data("page");
        changeSlides(1);
      });

    });
  }

  passwordMatchValidator(group: FormGroup): any {
    if (group.value.password != group.value.password_confirmation) {
      const invalid = group.value.password != group.value.password_confirmation;
      return invalid ? { 'passwordMisMatch': true } : {};
    }
   

  }
  resetNewPassword(formValue) {
    this.dataService.add("api/password/reset", formValue).subscribe((response: any) => {
      if (response.response) {
        this.toastr.error(response.response);
      }
      else if (response.status == 1) {
        this.toastr.success(response.data);
        this.router.navigate(['/login']);

      }
    }, error => {
      this.toastr.error(error);
    })

  }

}
