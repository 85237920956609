import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy
} from "@angular/core";
import { element } from "protractor";
import { HeaderService } from "../../services/header.service";
import { ApiService } from "../../services/api.service";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { SharedataService } from "../../services/sharedata.service";
import { UseCaseService } from "../../services/use-case.service";
import { VisualizationRendererService } from "../../services/visualization-renderer";
import { HttpClient } from "@angular/common/http";
import {
  TREE_ACTIONS,
  KEYS,
  IActionMapping,
  ITreeOptions
} from "angular-tree-component";
import * as Highcharts from "highcharts";
import { chart } from "highcharts";
import * as Highmaps from "highcharts/highmaps";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { GetCaseColumnService } from "../../services/get-case-column.service";
import { GetCasePieService } from "../../services/get-case-pie.service";
import { GalleryVisualizationTitleService } from "../../services/gallery-visualization-title.service";
//import * as html2canvas from "html2canvas";
import html2canvas from 'html2canvas';
import noData from "highcharts/modules/no-data-to-display";
import { CustomFilterPipePipe } from "../../services/custom-filter-pipe.pipe";
import offline from 'highcharts/modules/offline-exporting';
import exporting from 'highcharts/modules/exporting';
import { CommonService } from "../../services/common.service";

exporting(Highcharts);
offline(Highcharts);

noData(Highcharts);
Highcharts.setOptions({ lang: { noData: "No data avialable." } });

// import { Subscription } from 'rxjs';

import { _ } from "underscore";
import { DialogMaterialComponent } from "src/app/dialog-material/dialog-material.component";
import { MatDialog } from "@angular/material";

declare var $: any;
var chartObj: { [k: string]: any } = {};

@Component({
  selector: "app-visualization",
  templateUrl: "./visualization.component.html",
  styleUrls: ["./visualization.component.css"]
})
export class VisualizationComponent implements OnInit {
  @ViewChild("chartTarget") chartTarget: ElementRef;
  @ViewChild("chartTargetUseCase") chartTargetUseCase: ElementRef;
  selectedIndex = 0;
  disabledAddTitle = false;
  seriesData = [];
  activeUrl;
  tabIndex = 0;
  loggedUserArea;
  valOfFrom;
  valOfTo;
  searchkey = "";
  all_seriesData = [];
  all_indicator: any = [];
  all_unit: any = [];
  allUnit: any = [];
  all_subgroup: any = [];
  all_area: any = [];
  all_ius: any = [];
  vizualization_name = "";
  add_timePeriod_checkbox = [];
  add_timePeriod_most_recent: any;
  visualizationObj: any;
  viz_type: any;
  showIndicatorList: boolean = false;
  showIndicatorAllList: boolean = true;
  checkedIusList = [];
  all_time_period: any = [];
  timePeriodNew: any;
  areaLevel = "";
  nodes = [];
  visUpdateId;
  addGallery: boolean = false;
  updateGallery: boolean = false;
  chart: Highcharts.Chart;
  // charts = ['Line', 'Column', 'Bar', 'Pie', 'Radar', 'Tree Map', 'Map'];
  data: any;
  resTimePeriod = [];
  resIus = [];
  galleryId: any;
  visualization_type: any;
  shareUrlLink: any;
  visualizationList: any = [];
  timePeriodList: any = [];
  viewTimePeriodList: any = [];
  iusClassification = [];
  getIusList = [];
  areaList: any;
  newArray: any;
  hideme = {};
  chartVal: any;
  selectedAll: boolean = false;
  subGroup = [];
  hideIcon = {};
  getAllIus = [];
  chartType: string;
  areaDetails;
  galleryName: any;
  selectedTimePeriodlist: any;
  add_time_period_most_recent = false;
  add_time_period_list = false;
  selectedXaxis: boolean = false;
  selectedYaxis: boolean = false;
  selectedLabel: boolean = false;
  add_title_of_title: any = "";
  add_title_of_subtitle: any = "";
  add_title_of_xAxis: any = "";
  add_title_of_yAxis: any = "";
  addTimePeriodType: any;
  LegendBottom=false;
  LegendRight=true;
  show_viz_ius: any;
  show_viz_time_period: any;
  addtitleIus = [];

  gelleryApiResponse: any;
  allSubgroup = [];
  allunit = [];

  viewResultObj: any;
  changed_from_timeperiod = "";
  changed_to_timeperiod = "";

  myLegend: any = {
    enabled: false,
    align: "bottom",
    verticalAlign: "top",
    layout: "vertical",
    itemMarginTop: 0,
    itemMarginBottom: 2
  };
  addGalleryClassification = [];
  all_indicators = [];
  
  isAddNewViz = 1; //1-yes, 0-No
  newVizTab = {0:0,1:0,2:0,3:0,4:0}; //1-yes, 0-No
  options: ITreeOptions = {
    actionMapping: {
      mouse: {
        click: (tree, node, $event) => {
          this.sendData(node.data);

          $("#myModal")
            .find(".loaders >  ol > li")
            .fadeIn();
          $("#select_area").html(node.data.name);
          $(".dropdown").removeClass("open");

          this.view_viz_area = node.data.id;
          this.changed_from_timeperiod = "";
          this.changed_to_timeperiod = "";

          this.show_data_by_ius_area_tp(this.view_viz_Ius_id, node.data.id);

          /*
          $('#select_area').html(node.data.name);
          $('.dropdown').removeClass('open');
          this.show_viz_by_area_change(node.data.id);*/
        }
      }
    }
  };
  mapData = [
    ["in-py", 0],
    ["in-ld", 1],
    ["in-wb", 2],
    ["in-or", 3],
    ["in-br", 4],
    ["in-sk", 5],
    ["in-ct", 6],
    ["in-tn", 7],
    ["in-mp", 8],
    ["in-2984", 9],
    ["in-ga", 10],
    ["in-nl", 11],
    ["in-mn", 12],
    ["in-ar", 13],
    ["in-mz", 14],
    ["in-tr", 15],
    ["in-3464", 16],
    ["in-dl", 17],
    ["in-hr", 18],
    ["in-ch", 19],
    ["in-hp", 20],
    ["in-jk", 21],
    ["in-kl", 22],
    ["in-ka", 23],
    ["in-dn", 24],
    ["in-mh", 25],
    ["in-as", 26],
    ["in-ap", 27],
    ["in-ml", 28],
    ["in-pb", 29],
    ["in-rj", 30],
    ["in-up", 31],
    ["in-ut", 32],
    ["in-jh", 33]
  ];

  chartOptions;

  tabs = ["All", "coverage", "communication", "coldchain"];

  isChecked: boolean = false;
  charts = [
    {
      key: 1,
      value: "Line",
      name: "line",
      checked: true
    },
    {
      key: 2,
      value: "Column",
      name: "column",
      checked: false
    },
    {
      key: 3,
      value: "Bar",
      name: "bar",
      checked: false
    },
    {
      key: 4,
      value: "Pie",
      name: "pie",
      checked: false
    },
    {
      key: 5,
      value: "Radar",
      name: "radar",
      checked: false
    },
    {
      key: 6,
      value: "Tree Map",
      name: "treemap",
      checked: false
    }
    // {
    //   key: 7,
    //   value: 'Map',
    //   name: 'map',
    //   checked: false
    // }
  ];

  areaVariableLevels = [
    {
      key: 1,
      value: "Level",
      checked: false
    },
    {
      key: 2,
      value: "Sub Level(All)",
      checked: false
    },
    {
      key: 3,
      value: "Sub level(Top 5)",
      checked: false
    },
    {
      key: 4,
      value: "Sub level(Bottom 5)",
      checked: false
    }
  ];

  areaFixedLevels = [
    {
      key: 5,
      value: "Country",
      checked: false
    },
    {
      key: 6,
      value: "State",
      checked: false
    },
    {
      key: 7,
      value: "District",
      checked: false
    }
  ];
  timeperiodRadio = [
    {
      key: 1,
      title: "Most recent time period",
      value: "mrd",
      selected: false
    },
    {
      key: 2,
      title: "Last 2 time period",
      value: "last_2",
      selected: false
    },
    {
      key: 3,
      title: "Last 5 time period",
      value: "last_5",
      selected: false
    }
  ];

  constructor(
    private headerService: HeaderService,
    public dataService: ApiService,
    public formBuilder: FormBuilder,
    private shareData: SharedataService,
    public useCase: UseCaseService,
    public visualizationRenderService: VisualizationRendererService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private toastr: ToastrService,
    public columnUseCase: GetCaseColumnService,
    public pieUseCase: GetCasePieService,
    public vizTitle: GalleryVisualizationTitleService,
    public router: Router,
    public dialog: MatDialog,
    private commonService : CommonService
  ) {
    this.activeUrl = router.url;
    this.hideme = {};
    this.activatedRoute.params.subscribe(params => {
      if (params) {
        this.galleryId = parseInt(params.id);
        this.galleryName = params.name;
      }
    });
  }

  ngOnInit() {
    $(document).on("click", ".not-hide", function (e) {
      // e.stopPropagation();
      if ($(this).hasClass("rotate")) {
        $(this).attr("src", "assets/lib/images/caret-right.svg");
      } else {
        $(this).attr("src", "assets/lib/images/caret-right-hover.svg");
      }
      $(this)
        .siblings("ul")
        .slideToggle();
      $(this).toggleClass("rotate");
    });



    $(".open-modal").click("show.bs.modal", function () {
      $(".add-visualization").modal("show");
    });

    /*this.dataService
      .getAll("api/get-indicator-classifications")
      .subscribe((res: any) => {
        this.getIusList = res.data;
      });*/
    this.getAllIusData();
    this.getTimePeriod();

    // this.createMapForm();
    this.getAllVisualization();
    if (this.isAdmin()) {
      this.headerService.setTitle("Gallery");
    } else {
      this.headerService.setTitle("Report Gallery");
    }
    this.headerService.setLogo(
      "assets/lib/images/svg/header-menu/gallery-color.svg"
    );

    this.data = {};
    this.data.isAllSelected = false;
    this.data.isAllCollapsed = false;
    this.data.ParentChildchecklist = [
      {
        id: 1,
        value: "Elenor Anderson",
        isSelected: false,
        isClosed: false,
        childList: [
          {
            id: 1,
            parent_id: 1,
            value: "child 1",
            isSelected: false
          },
          {
            id: 2,
            parent_id: 1,
            value: "child 2",
            isSelected: false
          }
        ]
      },
      {
        id: 2,
        value: "Caden Kunze",
        isSelected: false,
        isClosed: false,
        childList: [
          {
            id: 1,
            parent_id: 1,
            value: "child 1",
            isSelected: false
          },
          {
            id: 2,
            parent_id: 1,
            value: "child 2",
            isSelected: false
          }
        ]
      },
      {
        id: 3,
        value: "Ms. Hortense Zulauf",
        isSelected: false,
        isClosed: false,
        childList: [
          {
            id: 1,
            parent_id: 1,
            value: "child 1",
            isSelected: false
          },
          {
            id: 2,
            parent_id: 1,
            value: "child 2",
            isSelected: false
          }
        ]
      }
    ];

    $(document).on("click", "input[type=checkbox]", function () {
      // children checkboxes depend on current checkbox
      $(this)
        .next()
        .find("input[type=checkbox]")
        .prop("checked", this.checked);
      // go up the hierarchy - and check/uncheck depending on number of children checked/unchecked
      $(this)
        .parents("ul")
        .prev("input[type=checkbox]")
        .prop("checked", function () {
          return $(this)
            .next()
            .find(":checked").length;
        });
    });


  }


  ngAfterViewInit() {
    $(document).ready(function(){
      $('.dropdown-submenu').on("click", function(e){
        $(this).next('ul').toggle();
        e.stopPropagation();
        e.preventDefault();
      });
    });

   

    $(document).ready(function(){

			$(document).on('click','.tree-open',function(){
			  
			  
				if($(this).siblings('ul').hasClass('in')) {
					$(this).siblings('ul').removeClass("in pl-3");
					$(this).removeClass("imp_img");
				} else {
					$(this).siblings('ul').addClass("in pl-3");	
					$(this).addClass("imp_img");			
				}
	
			});


			
		  });

  }
  addnewviz() {
    // to remove legend
    this.myLegend.enabled = false;
    $('#legend_position_div').css("display", "none");
    this.isAddNewViz = 1;
    this.chartType = 'line';
    this.newVizTab = {0:0,1:0,2:0,3:0,4:0}; //1-yes, 0-No

    $('#add_viz_new').on('hidden.bs.modal', function (e) { 
      $(this)
        .find("input[type=checkbox], input[type=radio]")
           .prop("checked", '')
           .end();
    })


    // $('#charts_1').click();
    this.tabIndex = 0;
    $("#tree")
      .find("input[type=checkbox]")
      .prop("checked", false);

      $("#tree")
      .find("input[type=checkbox]") .find("input[type=checkbox]")
      .prop("checked", false);


    $(".level1").prop("checked", false);
    $(".level2").prop("checked", false);
    $(".level3").prop("checked", false);
    $(".level4").prop("checked", false);

    let tempChart  = this.charts;
    tempChart.forEach(element=>{
      if(element.key==1){
        element.checked = true;
      }else{
        element.checked = false;        
      }
    });
    this.charts = tempChart;


    
    this.timeperiodRadio.forEach(element => {
      if (element.selected == true) {
        element.selected = false;
      }
    });
    this.timePeriodList.forEach(element => {
      if (element.selected == true) {
        element.selected = false;
      }
    });

    this.areaFixedLevels.forEach(element => {
      if (element.checked == true) {
        element.checked = false;
      }
    });
    this.areaFixedLevels.forEach(element => {
      if (element.checked == true) {
        element.checked = false;
      }
    });
    


    this.add_title_of_title= '';
    this.add_title_of_subtitle= '';
    this.add_title_of_xAxis= '';
    this.add_title_of_yAxis = ''; 
    // this.areaLevel['checked']=false;

    this.xgridline = false;
    this.ygridline = false;
    this.myLabelshow = false;

    /*this.charts.forEach(element => {
      element.checked = false;
    });
    this.charts[0].checked = true;*/
    let e = { value: 1 };
    this.radioChange(e, "Line");
  }

  parentCheck(parentObj) {
    for (var i = 0; i < parentObj.childList.length; i++) {
      parentObj.childList[i].isSelected = parentObj.isSelected;
    }
  }

  childCheck(parentObj, childObj) {
    parentObj.isSelected = childObj.every(function (itemChild: any) {
      return itemChild.isSelected == true;
    });
  }

  /***hover specific js***/
  mouseoverVizList() {
    $(".share-dropdown").show();
  }

  mouseoutVizList() {
    $(".share-dropdown").hide(6000);
  }
  /***hover specific js***/

  getAllVisualization() {
    $(".loaders ol li").fadeIn();
    let that = this;
    let time_post_fix = '';

    this.dataService.getAll("api/gallery/list/" + this.galleryId).subscribe(
      (res: any) => {
        $(".loaders ol li").fadeOut();
        // this.areaList = res.data.area[0];
        this.addGalleryClassification = res.data.classification;
        this.all_indicators = res.data.all_indicators;
        res.data.visualization_list.forEach(element => {
          that.viz_type = element.visualization_type;
          if (that.viz_type == "line") {
            element.visualizationUrl = "mi_line";
          } else if (that.viz_type == "bar") {
            element.visualizationUrl = "mi_bar";
          } else if (that.viz_type == "pie") {
            element.visualizationUrl = "mi_pie";
          } else if (that.viz_type == "radar") {
            element.visualizationUrl = "mi_radar";
          } else if (that.viz_type == "column") {
            element.visualizationUrl = "mi_column";
          } else if (that.viz_type == "treemap") {
            element.visualizationUrl = "mi_tree-map";
          } else if (that.viz_type == "map") {
            element.visualizationUrl = "india";
          }
        });
        this.visualizationList = res.data.visualization_list;
        this.visualizationList.forEach(element => {
          element.showSaveIcon = false;


          if (element.time_period == 'mrd') {
            element.box_time_period = 'Most recent time period';
          } else if (element.time_period == 'last_2') {
            element.box_time_period = 'Last 2 time period';
          } else if (element.time_period == 'last_  5') {
            element.box_time_period = 'Last 5 time period';
          } else {
            element.box_time_period = element.time_period;
          }


        });
        if (this.visualizationList.length == 0) {
          $("#no_data_vaialable").show();
        }else{
          $("#no_data_vaialable").hide();
        }
      },
      error => () => {
        console.log("Error");
      }
    );
  }

  selectUnselectAll(obj) {
    obj.isAllSelected = !obj.isAllSelected;
    for (var i = 0; i < obj.ParentChildchecklist.length; i++) {
      obj.ParentChildchecklist[i].isSelected = obj.isAllSelected;
      for (var j = 0; j < obj.ParentChildchecklist[i].childList.length; j++) {
        obj.ParentChildchecklist[i].childList[j].isSelected = obj.isAllSelected;
      }
    }
  }
  expandCollapse(obj) {
    obj.isClosed = !obj.isClosed;
  }
  expandCollapseAll(obj) {
    for (var i = 0; i < obj.ParentChildchecklist.length; i++) {
      obj.ParentChildchecklist[i].isClosed = !obj.isAllCollapsed;
    }
    obj.isAllCollapsed = !obj.isAllCollapsed;
  }
  stringify(obj) {
    return JSON.stringify(obj);
  }

  sendData(data): void {
  }

  radioChange(e, val) {
    this.chartVal = e.value;
    (this.visualization_type = val.replace(/ /g, "").toLowerCase()), "cvnvb";
    switch (e.value) {
      case 1:
        this.chartType = "line";
        this.renderColumn();
        break;

      case 2:
        this.chartType = "column";
        this.renderColumn();
        break;

      case 3:
        this.chartType = "bar";
        this.renderColumn();
        break;

      case 4:
        this.chartType = "pie";
        this.renderPie();
        break;

      case 5:
        this.chartType = "radar";
        this.renderRadar();
        break;

      case 6:
        this.chartType = "treemap";
        this.renderTree();
        break;

      default:
        this.chartType = "map";
        this.renderMap();
    }
  }

  ngOnDestroy() {
    if (typeof this.chart != "undefined") {
      this.chart.destroy();
    }
    // this.subscription.unsubscribe();
  }

  renderPie() {
    let opts: any = {
      credits: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      exporting:{
        enabled:false
      },
      plotOptions: {
        pie: {
            showInLegend: true
        }
      },
      series: [
        {
          name: "Value",
          colorByPoint: true,

          data: [
            {
              name: "Delhi",
              y: 61.41,

            },
            {
              name: "Punjab",
              y: 11.84
            },
            {
              name: "Rajstan",
              y: 10.85
            },
            {
              name: "Manipur",
              y: 4.67
            },
            {
              name: "Assam",
              y: 4.18
            },

          ]
        }
      ]
    };

    if (this.chartTarget && this.chartTarget.nativeElement) {
      opts.chart = {
        type: this.chartType,
        renderTo: this.chartTarget.nativeElement
      };

      this.chart = new Highcharts.Chart(opts);
    }
  }

  renderTree() {
    let opts: any = {
      credits: {
        enabled: false
      },
      exporting:{
        enabled:false
      },
      series: [
        {
          type: this.chartType,

          layoutAlgorithm: "stripes",
          alternateStartingDirection: true,

          data: [
            {
              id: 'A',
              name: 'India',
              color: "#EC2500"
            }, {
              id: 'B',
              name: 'Bananas',
              color: "#ECE100"
            }, {
              id: 'O',
              name: 'Oranges',
              color: '#EC9800'
            }, {
              name: 'Delhi',
              parent: 'A',
              value: 5
            }, {
              name: 'Manipur',
              parent: 'A',
              value: 3
            }, {
              name: 'Bihar',
              parent: 'A',
              value: 4
            }, {
              name: 'Goa',
              parent: 'B',
              value: 4
            }, {
              name: 'Gujarat',
              parent: 'B',
              value: 8
            }, {
              name: 'Bihar',
              parent: 'B',
              value: 5
            }
          ]
        }
      ]
    };

    if (this.chartTarget && this.chartTarget.nativeElement) {
      opts.chart = {
        type: this.chartType,
        renderTo: this.chartTarget.nativeElement
      };

      this.chart = new Highcharts.Chart(opts);
    }
  }

  renderColumn() {
    let opts: any = {
      credits: {
        enabled: false
      },
      exporting:{
        enabled:false
      },
      legend: {
        enabled: false
      },
      series: [
        {
          name: "Delhi",
          data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175]
        },
        {
          name: "Punjab",
          data: [24916, 24064, 29742, 29851, 32490, 30282, 38121, 40434]
        },
        {
          name: "Rajstan",
          data: [11744, 17722, 16005, 19771, 20185, 24377, 32147, 39387]
        },
        {
          name: "Manipur",
          data: [null, null, 7988, 12169, 15112, 22452, 34400, 34227]
        },
        {
          name: "Assam",
          data: [12908, 5948, 8105, 11248, 8989, 11816, 18274, 18111]
        }
      ]
    };

    if (this.chartTarget && this.chartTarget.nativeElement) {
      if (this.chartType == "radar") {
        opts.chart = {
          type: "line",
          renderTo: this.chartTarget.nativeElement,
          polar: true
        };
      } else {
        opts.chart = {
          type: this.chartType,
          renderTo: this.chartTarget.nativeElement
        };
      }

      this.chart = new Highcharts.Chart(opts);
    }
  }

  renderRadar() {
    let opts: any = {
      credits: {
        enabled: false
      },
      exporting:{
        enabled:false
      },
      legend: {
        enabled: false
      },

      title: {
        text: "Radar Chart"
      },

      subtitle: {
        text: "Also known as Radar Chart"
      },

      xAxis: {
        categories: [
          "2014",
          "2015",
          "2016",
          "2017",
          "2018",
          "2019"
        ],
        tickmarkPlacement: "on",
        lineWidth: 0
      },
      yAxis: {
        gridLineInterpolation: "polygon",
        lineWidth: 0,
        min: 0
      },

      series: [
        {
          name: "Delhi",
          data: [43000, 19000, 60000, 35000, 17000, 10000]
        },
        {
          name: "Punjab",
          data: [50000, 39000, 42000, 31000, 26000, 14000]
        }
      ]
    };

    if (this.chartTarget && this.chartTarget.nativeElement) {
      opts.chart = {
        polar: true,
        type: "line",
        renderTo: this.chartTarget.nativeElement
      };

      this.chart = new Highcharts.Chart(opts);
    }
  }

  renderMap() {
    let obj = this;
    $.getJSON("assets/lib/map/IND_l02_2011_Census.json", function (geojson) {
      //this.http.get('assets/lib/map/IND_l02_2011_Census.json').subscribe((response: any) => {
      // Load the drilldown map
      let map = geojson;
      let opts: any = {
        credits: {
          enabled: false
        },
        chart: {
          map: "countries/in/in-all"
        },

        title: {
          text: "Highmaps basic demo"
        },

        subtitle: {
          text:
            'Source map: <a href="http://code.highcharts.com/mapdata/countries/in/in-all.js">India</a>'
        },

        mapNavigation: {
          enabled: true,
          buttonOptions: {
            verticalAlign: "bottom"
          }
        },

        colorAxis: {
          min: 0
        },

        series: [
          {
            data: [],
            mapData: map,
            joinBy: "code",
            name: "India",
            states: {
              hover: {
                color: "#BADA55"
              }
            },
            dataLabels: {
              enabled: true,
              format: "{point.name}"
            }
          }
        ]
      };

      Highmaps.mapChart(obj.chartTarget.nativeElement, opts);
      //stockChart.mapChart(obj.chartTarget.nativeElement,opts)
    });
  }
  getAllIusData_old() {
    this.showIndicatorAllList = true;
    this.dataService
      .getAll("api/get-ius-subgroup-list")
      .subscribe((res: any) => {
        this.getAllIus = res.data;
        this.getAllIus.forEach(element => {
          element.selected = false;
          element.hideIcon = false;
          element.ius_subgroup.forEach(elementIus => {
            elementIus.selected = false;
          });
        });
      });
  }

  getAllIusData() {
    this.shareData
      .getAll("api/get-indicator-classifications")
      .subscribe((response: any) => {
        this.getIusList = response.data;
      });
  }

  selectAll(event, index, iuslist) {
    if (event.target.checked) {
      this.getAllIus.forEach(element => {
        if (element.id == index + 1) {
          element.ius_subgroup.forEach(elementIus => {
            elementIus.selected = true;
            this.getSubGroup(elementIus);
          });
        }
      });
    } else {
      this.getAllIus.forEach(element => {
        this.getAllIus.forEach(element => {
          if (element.id == index + 1) {
            element.ius_subgroup.forEach(elementIus => {
              elementIus.selected = false;
              this.getSubGroup(elementIus);
            });
          }
        });
      });
    }
  }
  checkAll(event, index) {
    if (event.target.checked) {
      this.getIusList.forEach(element => {
        if (element.id == index + 1) {
          element.ius_subgroup.forEach(elementIus => {
            elementIus.selected = true;
          });
        }
      });
    } else {
      this.getIusList.forEach(element => {
        this.getAllIus.forEach(element => {
          if (element.id == index + 1) {
            element.ius_subgroup.forEach(elementIus => {
              elementIus.selected = false;
            });
          }
        });
      });
    }
  }
  showSubSectorIus(data) {
    this.showIndicatorList = true;
    this.showIndicatorAllList = false;
    this.iusClassification = data.ius_classification;
    this.iusClassification.forEach(element => {
      element.ius.forEach(element => {
        element.selected = false;
      });
    });
  }
  getTimePeriod() {
    this.dataService.getAll("api/time-period-list").subscribe((res: any) => {
      this.timePeriodList = res.data;

      this.timePeriodList.forEach(element => {
        element.selected = false;
      });
    });
  }
  checkAllIndicators(event) {
    if (event.target.checked) {
      this.getAllIus.forEach(element => {
        element.selected = true;
        element.ius_subgroup.forEach(elementIus => {
          elementIus.selected = true;
          this.getSubGroup(elementIus);
        });
      });
    } else {
      this.getAllIus.forEach(element => {
        element.selected = false;
        element.ius_subgroup.forEach(elementIus => {
          elementIus.selected = false;
          this.getSubGroup(elementIus);
        });
      });
    }
  }
  // selectAll() {
  
  //   this.iusClassification = data.ius_classification;
  //   this.iusClassification.forEach(element => {
  //     element.ius.forEach(element => {
  //       element.selected = false;

  //     });

  //   });
  // }

  viewSelectedInd(event) {
    let e = event;
    if (e.target.checked == true) {
     
    } else {
     
    }
  }

  getSubGroup(subgroup) {
    this.checkedIusList = [];
    let ius =
      subgroup.indicator + "|" + subgroup.unit + "|" + subgroup.subgroup;
    if (this.checkedIusList.indexOf(subgroup.id) == -1) {
      if (subgroup.selected) {
        this.checkedIusList.push(subgroup.id);

        this.addtitleIus.push(ius);
       
      }
    } else {
      let indexOfPopedElem = this.checkedIusList.indexOf(subgroup.id);
      this.checkedIusList.splice(indexOfPopedElem, 1);
      let indexOfPopedElement = this.addtitleIus.indexOf(ius);
      this.addtitleIus.splice(indexOfPopedElement, 1);
    }
  }
  areaChange(event) {
    this.areaLevel = event.value;
  }

  addCheckTimePeriod(event, val, type) {
    if (type == "checkbox") {
      this.addTimePeriodType = 1;

      this.timeperiodRadio.forEach(element => {
        if (element.selected == true) {
          element.selected = false;
        }
      });

      if (event.checked) {
        if (this.add_timePeriod_checkbox.indexOf(val.time_period) == -1) {
          val.selected = true;
          this.add_timePeriod_checkbox.push(val.time_period);
        }
      } else {
        let indexOfPopedElem = this.add_timePeriod_checkbox.indexOf(
          val.time_period
        );
        this.add_timePeriod_checkbox.splice(indexOfPopedElem, 1);
      }

      this.add_timePeriod_most_recent = "";
    } else {
      // radio condition

      this.addTimePeriodType = 2;

      this.add_timePeriod_most_recent = event.value;

      this.timeperiodRadio.forEach(element => {
        if (element.value == event.value) {
          element.selected = true;
        } else {
          element.selected = false;
        }
      });

      this.timePeriodList.forEach(element => {
        if (element.selected == true) {
          element.selected = false;
        }
      });
      /* 
      $('.addTimeList').each(function(el) {

        el.prop('checked',false);
      })*/

      this.add_timePeriod_checkbox = [];
    }
  }

  add_all_indicator: any = [];
  add_allUnit: any = [];
  add_allSubgroup: any = [];

  addVisualization(type) {
    
    // findUseCase(addchartType);

   /* subtitle: {
        text: this.add_title_of_subtitle,
        style: this.subTitleStyle
      },*/


    let myChartSetting: any = {
      title: {
        text: this.add_title_of_title,
        style: this.titleStyle
      },

      subtitle: {
        text: '',
        style: this.subTitleStyle
      },

      xAxis: {
        title: {
          text: this.add_title_of_xAxis,
          style: this.xAxisTitleStyle
        },
        labels: {
          style: this.xAxisLabelStyle
        },
        gridLineWidth: this.xgridline
      },

      yAxis: {
        title: {
          text: this.add_title_of_yAxis,
          style: this.yAxisTitleStyle
        },
        labels: {
          style: this.yAxisLabelStyle
        },
        gridLineWidth: this.ygridline
      },
      legend: this.myLegend
    };

    if (this.visualization_type == "pie") {
      myChartSetting.plotOptions = {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: this.myLabelshow,
            
          },
         showInLegend: true
        }
      };
    } else {
      myChartSetting.plotOptions = {
        series: {
          dataLabels: {
            enabled: this.myLabelshow
          }
        }
      };
    }


    myChartSetting = JSON.stringify(myChartSetting);
    this.newArray = [];

    this.newArray = this.checkedIusList.join(",");

    let timePeriodLength;
    this.timePeriodNew = "";
    if (this.addTimePeriodType == 1) {
      timePeriodLength = this.add_timePeriod_checkbox.length;
      this.timePeriodNew = this.add_timePeriod_checkbox.join(",");
    } else if (this.addTimePeriodType == 2) {
      this.timePeriodNew = this.add_timePeriod_most_recent;
      timePeriodLength = 1;
    }

    this.add_all_indicator = [];
    this.add_allUnit = [];
    this.add_allSubgroup = [];

    this.addtitleIus.forEach(element => {
      let elem: any = element.split("|");

      if (this.add_all_indicator.indexOf(elem[0]) == -1) {
        this.add_all_indicator.push(elem[0]);
      }

      if (this.add_allUnit.indexOf(elem[1]) == -1) {
        this.add_allUnit.push(elem[1]);
      }

      if (this.add_allSubgroup.indexOf(elem[2]) == -1) {
        this.add_allSubgroup.push(elem[2]);
      }
    });

    if (this.timePeriodNew == "") {
      this.toastr.error("Select at least one Time Period.");
      return;
    } else if (this.newArray == "") {
      this.toastr.error("Select at least one Indicator");
      return;
    } else if (this.areaLevel == "") {
      this.toastr.error("Select at least one Area option.");
      return;
    }else if(this.add_title_of_title==''){
      this.toastr.error("Chart title field is required.");
      return;
    }

    if (type == "add") {
   
      let func_name = this.useCase.findUseCase(
        "generateTitle",
        this.add_all_indicator.length,
        this.add_allUnit.length,
        this.add_allSubgroup.length,
        timePeriodLength,
        1
      );

      // let func_name = this.useCase.findUseCase('generateTitle', 1,1,1,1, 1);

      let objData: any = [];
      objData["timeperiod"] = this.timePeriodNew;
      objData["ius"] = this.addtitleIus;

      let vizTitle = this.vizTitle[func_name](objData);



      this.visualizationObj = {
        gallery_id: this.galleryId,
        visualization_type: this.visualization_type,
        visualization_name: vizTitle,
        ius: this.newArray,
        area: this.areaLevel,
        time_period: this.timePeriodNew,
        visualization_data: myChartSetting
      };

      this.dataService
        .add("api/gallery/list/add", this.visualizationObj)
        .subscribe((res: any) => {
          if (res.status == 0) {
            this.toastr.error(res.error);

          } else {
            this.toastr.success("Visualization added successfully");
            // reset the form after submitting data
            setTimeout(() => {
              document.getElementById("close-visualization").click();
            }, 2000);
            //function to get the list of users after adding a user
            this.getAllVisualization();
          }

        });
    } else if (type == "update") {
      this.visualizationObj = {
        visualization_type: this.visualization_type,
        // 'visualization_name': this.use_case_get_data,
        ius: this.newArray,
        area: this.areaLevel,
        time_period: this.timePeriodNew,
        visualization_data: myChartSetting
      };

      this.dataService
        .update(
          "api/gallery/list/update",
          this.visUpdateId,
          this.visualizationObj
        )
        .subscribe((res: any) => {

          if (res.status == 0) {
            this.toastr.error(res.error);
          } else {
            this.toastr.success("Visualization updated successfully");
            // reset the form after submitting data
            setTimeout(() => {
              document.getElementById("close-visualization").click();
            }, 2000);
            //function to get the list of users after adding a user
            this.getAllVisualization();
          }
        });
    }
  }

  generateCarousalForIndicator(){
  
      function checkArrows() {
        
        return;
        var totalWidth = $('#resource-slider .resource-slider-item').length * $('.resource-slider-item').width();
        var frameWidth = $('.resource-slider-frame').width();
        var itemWidth = $('.resource-slider-item').width();
        var totalScroll = $('.resource-slider-frame').scrollLeft();
        
        if ( ((totalWidth - frameWidth) - totalScroll) < itemWidth ) {
          $(".next").css("visibility", "hidden");
        }
        else {
          $(".next").css("visibility", "visible");
        }
        if ( totalScroll < itemWidth ) {
          $(".prev").css("visibility", "hidden");
        }
        else {
          $(".prev").css("visibility", "visible");
        }

      }
      
      $('.arrow').on('click', function() {
        var $this = $(this),
          width = $('.resource-slider-item').width(),
          speed = 500;
        if ($this.hasClass('prev')) {
          $('.resource-slider-frame').animate({
            scrollLeft: '-=' + width
          }, speed, function() {
            // check arrows
            checkArrows();
          });
        } else if ($this.hasClass('next')) {
          $('.resource-slider-frame').animate({
            scrollLeft: '+=' + width
          }, speed, function() {
            // check arrows
            checkArrows();
          });
        }
      }); // end on arrow click
      
     // $(window).on("load resize", function() {
        checkArrows();
        $('.resource-slider-item').each(function(i) {
       
          var $this = $(this),
            left = $this.width() * i;
          $this.css({
            left: left
          })
      }); // end each
      //}); // end window resize/load
      
      
  }
  
  clearInputFields(tabIndex) {
      $('.tab'+tabIndex).find("input[type=checkbox], input[type=radio]")
           .prop("checked", false)
           .end();
  }
  indicatorTabClick(event) {
    
    if(this.isAddNewViz) {
      if(this.newVizTab[event.index] == 0) {
        this.clearInputFields(event.index);
        this.newVizTab[event.index] = 1
       
      } 
    }

    if (event.index == 1) {

      $('.selectpicker').selectpicker();
      this.generateCarousalForIndicator();

      $(".level3").each(function () {
        if (
          $(this)
            .parent()
            .find(".checked-sg")
            .filter(":checked").length
        ) {
          $(this).prop("checked", true);
          $(this)
            .parent()
            .addClass("rotate");
          $(this)
            .siblings("ul")
            .show();
        }
      });

      $(".level2").each(function () {
        if (
          $(this)
            .parent()
            .find(".level3")
            .filter(":checked").length
        ) {
          $(this).prop("checked", true);
          $(this)
            .parent()
            .addClass("rotate");
          $(this)
            .siblings("ul")
            .show();
        }
      });

      $(".level1").each(function () {
        if (
          $(this)
            .parent()
            .find(".level2")
            .filter(":checked").length
        ) {
          $(this).prop("checked", true);
          $(this)
            .parent()
            .addClass("rotate");
          $(this)
            .siblings("ul")
            .show();
        }
      });
    }
  }
  checkedSubgroup(id) {
    
    if (this.resIus.map(Number).indexOf(id) != -1) {
     /* if(this.checkedIusList.indexOf(id) == -1){
        this.checkedIusList.push(id);
      }*/

       $(".ind-subgroup-"+id).parent().parent().parent().addClass('in pl-3');
       $(".ind-subgroup-"+id).parent().parent().parent().parent().addClass('open');
      return true;
    } else {
      /*var index = this.checkedIusList.indexOf(id);
      if (index > -1) {
        this.checkedIusList.splice(index, 1);
      }*/

      $(".ind-subgroup-"+id).addClass("non-selected-ius");
      return false;
    }
  }
  editVisualizationById(id) {
   
    this.visUpdateId = id;
  
    this.isAddNewViz=0
    this.tabIndex = 0;
    let that = this;
    this.checkedIusList = [];
    this.addGallery = true;
    this.updateGallery = true;
    this.timePeriodList.forEach(element => {
      element.selected = false;
    });
    // this.resTimePeriod = [];
    this.resIus = [];
    $("#tree")
      .find("input[type=checkbox]")
      .prop("checked", false);
    this.dataService
      .getById("api/gallery-viz/edit", id)
      .subscribe((res: any) => {
        /* this.visualizationObj = {
         'gallery_id': res.data.galleryId,
         'visualization_type': res.data.visualization_type,
         'visualization_name': res.data.visualization_name,
         'ius': res.data.ius,
         'area': res.data.area,
         'time_period': res.data.time_period
       }*/

        this.visUpdateId = id;

        this.resIus = res.data.ius.split(",");
        this.checkedIusList = this.resIus;

        // chart start

        this.charts.forEach(element => {
          if (element.name == res.data.visualization_type) {
            element.checked = true;
            let e = { value: element.key };
            this.radioChange(e, element.name);
          } else {
            element.checked = false;
          }
        });
        // chart end

        // ius start
        /* this.checkedIusList = [];
       this.getIusList.forEach(element => {
         element.ius_subgroup.forEach(item => {
           if (this.resIus.map(Number).indexOf(item.id) != -1) {
             item.selected = true;
             this.checkedIusList.push(item.id);
 
           } else {
             item.selected = false;
             
 
           }
 
         });
       });*/

        // ius end

        // timeperiod start
        this.add_timePeriod_checkbox = [];

        if (
          res.data.time_period != "mrd" &&
          res.data.time_period != "last_2" &&
          res.data.time_period != "last_5"
        ) {
          this.addTimePeriodType = 1;

          this.resTimePeriod = res.data.time_period.split(",");

          this.timeperiodRadio.forEach(element => {
            element.selected = false;
          });

          for (let i = 0; i < this.resTimePeriod.length; i++) {
            this.timePeriodList.forEach(element => {
              if (this.resTimePeriod.indexOf(element.time_period) != -1) {
                element.selected = true;
                this.add_timePeriod_checkbox.push(element.time_period);
              } else {
                element.selected = false;
                let indexOfPopedElem = this.add_timePeriod_checkbox.indexOf(
                  element.time_period
                );
                this.add_timePeriod_checkbox.splice(indexOfPopedElem, 1);
              }
            });
          }

          this.add_timePeriod_most_recent = "";
        } else {
          this.addTimePeriodType = 2;

          this.timePeriodList.forEach(element => {
            element.selected = false;
          });

          this.timeperiodRadio.forEach(element => {
            if (element.value == res.data.time_period) {
              element.selected = true;
              this.add_timePeriod_most_recent = res.data.time_period;
            } else {
              element.selected = false;
            }
          });

          this.add_timePeriod_checkbox = [];
        }
        // timeperiod end

        // check area start
        if (
          res.data.area == 1 ||
          res.data.area == 2 ||
          res.data.area == 3 ||
          res.data.area == 4
        ) {
          this.areaFixedLevels.forEach(element => {
            element.checked = false;
          });

          this.areaVariableLevels.forEach(element => {
            if (element.key == res.data.area) {
              element.checked = true;
              this.areaLevel = res.data.area;
            } else {
              element.checked = false;
            }
          });
        } else if (
          res.data.area == 5 ||
          res.data.area == 6 ||
          res.data.area == 7
        ) {
          this.areaVariableLevels.forEach(element => {
            element.checked = false;
          });

          this.areaFixedLevels.forEach(element => {
            if (element.key == res.data.area) {
              element.checked = true;
              this.areaLevel = res.data.area;
            } else {
              element.checked = false;
            }
          });
        }

        // check area end

        // visualization setting start
        let visualizationData = JSON.parse(res.data.visualization_data);

        if (visualizationData.xAxis.gridLineWidth == 1) {
          this.xgridline = true;
        } else {
          this.xgridline = false;
        }

        if (visualizationData.yAxis.gridLineWidth == 1) {
          this.ygridline = true;
        } else {
          this.ygridline = false;
        }

        if (visualizationData.plotOptions.series.dataLabels.enabled) {
          this.myLabelshow = true;
        } else {
          this.myLabelshow = false;
        }

        this.add_title_of_title = visualizationData.title.text;
        $("#addSettingTitle").val(this.add_title_of_title);

        this.titleStyle.fontSize = visualizationData.title.style.fontSize;
        $("#addSettingFontSize").val(this.titleStyle.fontSize);

        this.add_title_of_subtitle = visualizationData.subtitle.text;

        this.subTitleStyle.fontSize = visualizationData.subtitle.style.fontSize;

        this.add_title_of_xAxis = visualizationData.xAxis.title.text;
        this.xAxisTitleStyle.fontSize =
          visualizationData.xAxis.title.style.fontSize;

        this.add_title_of_yAxis = visualizationData.yAxis.title.text;
        this.yAxisTitleStyle.fontSize =
          visualizationData.yAxis.title.style.fontSize;

        this.xAxisLabelStyle.fontSize =
          visualizationData.xAxis.title.style.fontSize;
        this.yAxisLabelStyle.fontSize =
          visualizationData.yAxis.title.style.fontSize;

        let that = this;
        this.chart.update({
          title: {
            text: this.add_title_of_title,
            style: this.titleStyle
          },

          subtitle: {
            text: that.add_title_of_subtitle,
            style: that.subTitleStyle
          },

          xAxis: {
            title: {
              text: that.add_title_of_xAxis,
              style: that.xAxisTitleStyle
            },
            labels: {
              style: that.xAxisLabelStyle
            },
            gridLineWidth: that.xgridline
          },

          yAxis: {
            title: {
              text: that.add_title_of_yAxis,
              style: that.yAxisTitleStyle
            },
            labels: {
              style: that.yAxisLabelStyle
            },
            gridLineWidth: that.ygridline
          },
          legend: {
            enabled: true,

            verticalAlign: "bottom",
            layout: "vertical",
            itemMarginTop: 0,
            itemMarginBottom: 2
          }
        });
      });
  }
  deleteVisualization(id) {
    const dialog = this.dialog.open(DialogMaterialComponent, {
      width: "350px",
      data: {
        messageDialog: "Are you sure to delete the visualization?",
        delete: true
      }
    });
    dialog.afterClosed().subscribe(selection => {
      if (selection) {
        this.dataService
          .delete("api/gallery/list/delete", id)
          .subscribe((response: any) => {
            // show toaster message on successfully deleting the user
            this.toastr.success("Vizualization deleted successfully");
            // get all the users after deleting the user
            this.getAllVisualization();
          });
      } else {
        // User clicked 'Cancel' or clicked outside the dialog
      }
    });
  }
  getAreaList() {
    this.dataService.getAll("api/get-area-list").subscribe((response: any) => {
      this.nodes = response.data.area_list;
    });
  }

  //Add new chart setting
  ///////////////////////////*********************///////////////////////////
  ///////////////////////////*********************///////////////////////////

  add_set_label: any = false;
  selected_set_obj = 1;
  xgridline: any;
  ygridline: any;
  myLabelshow: any = false;

  titleStyle: any = { fontSize: 16 };
  subTitleStyle: any = { fontSize: 16 };
  xAxisTitleStyle: any = { fontSize: 16 };
  xAxisLabelStyle: any = { fontSize: 16 };
  yAxisTitleStyle: any = { fontSize: 16 };
  yAxisLabelStyle: any = { fontSize: 16 };

  addSettingX(e) {
    if (e.checked == true) {
      this.xgridline = 1;
    } else {
      this.xgridline = 0;
    }
    this.chart.xAxis[0].update({ gridLineWidth: this.xgridline });
  }

  addSettingY(e) {
    if (e.checked == true) {
      this.ygridline = 1;
    } else {
      this.ygridline = 0;
    }
    this.chart.yAxis[0].update({ gridLineWidth: this.ygridline });
  }

  addSettingLabel(e) {
    this.add_set_label = e.checked;
    if (this.add_set_label == true) {
      var label = true;
      this.myLabelshow = true;
    } else {
      this.myLabelshow = false;
      var label = false;
    }

    /*if(charttype == 'treemap') {        
        $("#edit_visualization_modal .highcharts-text-outline").next("tspan").remove(); 
        $gallerycharts.update({
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: label
                    }
                }
            }
        });
    }else {*/

    this.chart.update({
      plotOptions: {
        series: {
          dataLabels: {
            enabled: label
          }
        }
      }
    });
    // }
  }

  handleLegend(event){
    let check_legend = event.checked;
    let obj=this;
    if(check_legend==true){
      this.LegendRight = true;
      this.myLegend.enabled = true;
      obj.myLegend = { 
                enabled:true,
                align: 'right',
                verticalAlign: 'top',
                layout: 'vertical',
                itemMarginTop: 0,
                itemMarginBottom: 2
                };
      this.chart.update({ legend: obj.myLegend}); 
      $('#legend_position_div').show();
    }else{
      obj.myLegend = {enabled:true};    
      $('#legend_position_div').hide();
      this.chart.update({ legend: { enabled: false }}); 
    }
  }


  add_legend_position(val){
      let legend_position = val;  
      if(this.myLegend.enabled==true){
          if(legend_position=='right')  {
            this.myLegend =  {
                  enabled:true,
                  align: 'right',
                  verticalAlign: 'top',
                  layout: 'vertical',
                  itemMarginTop: 0,
                  itemMarginBottom: 2,
                  y:30
              };
          }else{
            
            this.myLegend = {enabled:true,
              align: 'bottom',
              verticalAlign: 'bottom',
              layout: 'horizontal',
              itemMarginTop: 0,
              itemMarginBottom: 0};
          }    
          
          let obj = this;
         
          this.chart.update({ legend: obj.myLegend}); 
      }
      
  }

  add_set_object = [
    {
      key: 1,
      title: "Chart Title"
    },
    /*{
      key: 2,
      title: "Chart Subtitle"
    },*/
    {
      key: 3,
      title: "X Axis Title"
    },
    {
      key: 4,
      title: "X Axis Label"
    },
    {
      key: 5,
      title: "Y Axis Title"
    },
    {
      key: 6,
      title: "Y Axis Label"
    }
  ];

  add_set_font_size = [
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31
  ];

  addSetObjChange(e) {
    this.selected_set_obj = e.target.value;

    console.log(e);

    // $('#addSettingTitle').style('disabled',false);
    let obj_case = "obj_" + this.selected_set_obj;
    switch (obj_case) {
      case "obj_1":
        $("#addSettingTitle").val(this.add_title_of_title);
        $("#addSettingFontSize").val(this.titleStyle.fontSize);
        this.disabledAddTitle = false;

        break;
      case "obj_2":
        $("#addSettingTitle").val(this.add_title_of_subtitle);
        $("#addSettingFontSize").val(this.subTitleStyle.fontSize);
        this.disabledAddTitle = false;
        break;
      case "obj_3":
        $("#addSettingTitle").val(this.add_title_of_xAxis);
        $("#addSettingFontSize").val(this.xAxisTitleStyle.fontSize);
        this.disabledAddTitle = false;
        break;
      case "obj_4":
        $("#addSettingTitle").val("");
        this.disabledAddTitle = true;
        $("#addSettingFontSize").val(this.xAxisLabelStyle.fontSize);
        break;

      case "obj_5":
        $("#addSettingTitle").val(this.add_title_of_yAxis);
        $("#addSettingFontSize").val(this.yAxisTitleStyle.fontSize);
        this.disabledAddTitle = false;
        break;
      case "obj_6":
        $("#addSettingTitle").val("");
        this.disabledAddTitle = true;

        $("#addSettingFontSize").val(this.yAxisLabelStyle.fontSize);
        break;
      default:
        alert("Select any option.");
        break;
    }
  }

  addSetTextbox(e) {
    let val = e.target.value;

    let obj_case = "obj_" + this.selected_set_obj;
    switch (obj_case) {
      case "obj_1":
        this.add_title_of_title = val;
        this.chart.update({
          title: {
            text: val
          }
        });
        break;
      case "obj_2":
        this.add_title_of_subtitle = val;
        this.chart.update({
          subtitle: {
            text: val
          }
        });
        break;
      case "obj_3":
        this.add_title_of_xAxis = val;
        this.chart.update({
          xAxis: {
            title: {
              text: val
            }
          }
        });
        break;
      case "obj_5":
        this.add_title_of_yAxis = val;
        this.chart.update({
          yAxis: {
            title: {
              text: val
            }
          }
        });
        break;
      default:
        break;
    }
  }

  addSetFontSize(e) {
    let val = e.target.value;

    let obj_case = "obj_" + this.selected_set_obj;
    switch (obj_case) {
      case "obj_1":
        this.titleStyle.fontSize = val;
        this.chart.update({
          title: {
            style: {
              fontSize: val
            }
          }
        });
        break;
      case "obj_2":
        this.subTitleStyle.fontSize = val;
        this.chart.update({
          subtitle: {
            style: {
              fontSize: val
            }
          }
        });
        break;
      case "obj_3":
        this.xAxisTitleStyle.fontSize = val;
        this.chart.update({
          xAxis: {
            title: {
              style: {
                fontSize: val
              }
            }
          }
        });
        break;
      case "obj_4":
        this.xAxisLabelStyle.fontSize = val;
        this.chart.update({
          xAxis: {
            labels: {
              style: {
                fontSize: val
              }
            }
          }
        });
        break;

      case "obj_5":
        this.yAxisTitleStyle.fontSize = val;
        this.chart.update({
          yAxis: {
            title: {
              style: {
                fontSize: val
              }
            }
          }
        });
        break;
      case "obj_6":
        this.yAxisLabelStyle.fontSize = val;
        this.chart.update({
          yAxis: {
            labels: {
              style: {
                fontSize: val
              }
            }
          }
        });
        break;
      default:
        alert("Select any option.");
        break;
    }
  }

  /////////////////////////////////////////////
  ////////////VIEW VISUALIZATION///////////////
  /////////////////////////////////////////////

  view_viz_Ius_id: any = "";
  view_viz_time_period: any = "";
  view_viz_area: any = "";
  // multi_dropdown_timeperiod = 0;
  visualizationType;

  viz_show_hide_area: any = false;
  use_case_get_data: any;
  galleryTimeperiod = 0;

  openViewVizModal(viz_id, viz_name) {

    this.valOfFrom = undefined;
    this.valOfTo = undefined;
    this.vizualization_name = viz_name;
    $("#myModal")
      .find(".loaders >  ol > li")
      .fadeIn();
    $("#visualization_container").html("");
    $("#select_area").html("India");

    this.view_viz_Ius_id = viz_id;
    let areaDetails = JSON.parse(localStorage.getItem('area_details'));
    let authDetails = JSON.parse(localStorage.getItem('user_details'));
    this.loggedUserArea = areaDetails.area_name;
    if (areaDetails !== null) {
      this.view_viz_area = authDetails.area_id;
    }



    // this.view_viz_area = 1;
    this.viz_show_hide_area = false;
    this.seriesData = [];
    this.gelleryApiResponse = "";
    this.changed_from_timeperiod = "";
    this.changed_to_timeperiod = "";

    this.galleryTimeperiod = 0;
    this.viewResultObj = {};

    this.view_viz_time_period = "";

    this.checkedRecivedTp = "";
    this.send_tp = [];
    this.all_time_period = [];

    // multi_dropdown_timeperiod = 0;

    this.getAreaList();
    this.show_data_by_ius_area_tp(viz_id, this.view_viz_area);
  }

  show_viz_by_area_change(node) {
    $("#myModal")
      .find(".loaders >  ol > li")
      .fadeIn();
    this.view_viz_area = node.data.id;
    this.changed_from_timeperiod = "";
    this.changed_to_timeperiod = "";

    this.show_data_by_ius_area_tp(this.view_viz_Ius_id, node);
  }

  send_tp: any = [];

  show_viz_by_tp_change(e) {
    this.valOfFrom = undefined;
    this.valOfTo = undefined;
    $("#myModal")
      .find(".loaders >  ol > li")
      .fadeIn();
    this.view_viz_time_period = "";

    this.checkedRecivedTp = "not_mrd";
    this.send_tp = [];
    this.all_time_period = [];

    this.changed_from_timeperiod = "";
    this.changed_to_timeperiod = "";

    let that = this;
    $(".viz_time_period:checkbox:checked").each(function () {
      that.send_tp.push($(this).val());
      that.all_time_period.push($(this).val());
    });

    if (this.send_tp.length > 0) {
      this.view_viz_time_period = this.send_tp.toString();
    } else {
      this.view_viz_time_period = "";
    }

    // return;

    this.show_data_by_ius_area_tp(this.view_viz_Ius_id, this.view_viz_area);
  }

  checkedRecivedTp = "";
  show_data_by_ius_area_tp(viz_id, area_id) {
    let that = this;
    let data_set: any = {};

    let series_data_source = [];
    let all_time_period = [];

    if (that.view_viz_time_period != "") {
      //alert(view_viz_time_period);
      data_set["dropdown_time_period"] = that.view_viz_time_period;
      data_set["customfilter"] = 1;
      // multi_dropdown_timeperiod =1;
     
    } else {
      // multi_dropdown_timeperiod =0;
    }

    if (
      this.changed_from_timeperiod != "" &&
      this.changed_to_timeperiod != ""
    ) {
      //alert(from_time_period+' - '+to_time_period);
      data_set["dropdown_from_time_period"] = this.changed_from_timeperiod;
      data_set["dropdown_to_time_period"] = this.changed_to_timeperiod;
      data_set["customfilter"] = 1;
    
    }

    data_set["viz_id"] = viz_id;
    data_set["area_id"] = area_id;
    // data_set['time_period'] =time_period;
    
    this.seriesData = [];
    this.dataService
      .add("api/gallery/list/getvisulization", data_set)
      .subscribe((response: any) => {
        // this.timePeriodList = res.data.view_tp_dd;
        response.data.series_data.forEach( elem => {
          elem.time_period = this.commonService.covertTimePeriod(elem.time_period);
        })

        this.seriesData = response.data.series_data;
        this.visualizationType = response.data.record.visualization_type;

        this.gelleryApiResponse = response;

        if (this.checkedRecivedTp == "") {
          this.checkedRecivedTp = response.data.record.time_period;
        }

        if (
          response.data.record.area == 5 ||
          response.data.record.area == 6 ||
          response.data.record.area == 7
        ) {
          this.viz_show_hide_area = false;
        } else {
          this.viz_show_hide_area = true;
        }

        if (response.data.series_data.length > 0) {
          response.data.series_data.forEach(element => {
            if (series_data_source.indexOf(element.source) == -1) {
              series_data_source.push(element.source);
            }
            
            if (all_time_period.indexOf(element.time_period) == -1) {
              all_time_period.push(element.time_period);
            }

            let series_data_source_str = series_data_source.join(",");

            if (series_data_source_str.length > 70) {
              series_data_source_str =
                series_data_source_str.substr(0, 70) + "...";
            }
          });
        } else {
          $("#visualization_container").html(
            "<span style='position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);'>No data avialable.</span>"
          );
          $("#myModal")
            .find(".loaders >  ol > li")
            .fadeOut();
          return;
        }

        if (
          this.changed_from_timeperiod != "" &&
          this.changed_to_timeperiod != ""
        ) {
         
          let pass_data2 = [];
          let that = this;
          let dataVal;

          let groupedData = _.groupBy(that.seriesData, function (d) {
            return d.area_id + "-" + d.ius_id;
          });

          _.each(groupedData, function ($row) {
            let tempData = [];
            let dataAddVal = 0;
            let denominator = 0;
            let numerator = 0;
            _.each($row, function ($val) {
              if (
                $val.allius.unit == "Percent" ||
                $val.allius.unit == "percent"
              ) {
                denominator = denominator + Number($val.denominator);
                numerator = numerator + Number($val.numerator);
               
              } else if (
                $val.allius.unit == "Number" ||
                $val.allius.unit == "number"
              ) {
                dataAddVal = dataAddVal + Number($val.value);
               
              }
              //$tempData.push($val);
            });

            if (dataAddVal > 0) {
              dataVal = dataAddVal;
            } else if (denominator > 0 && numerator > 0) {
              dataVal = (numerator / denominator) * 100;

              dataVal = dataVal.toFixed(2);

             
            } else {
              dataVal = "";
            }

           

            pass_data2.push({
              allius: $row[0].allius,
              area: $row[0].area,
              area_code: $row[0].area.area_code,
              area_id: $row[0].area.id,
              area_level: $row[0].area.area_level,
              area_name: $row[0].area.area_name,
              parent_id: $row[0].area.parent_id,
              id: $row[0].id,
              indicator: $row[0].allius.indicator,
              unit: $row[0].allius.unit,
              subgroup: $row[0].allius.subgroup,
              ius:
                $row[0].allius.indicator +
                "|" +
                $row[0].allius.unit +
                "|" +
                $row[0].allius.subgroup,
              ius_id: $row[0].allius.id,
              time_period:
                that.changed_from_timeperiod + "-" + that.changed_to_timeperiod,
              value: dataVal
            });

           
          });

        
          that.seriesData = pass_data2;

        
        }

        if (this.galleryTimeperiod == 0) {
          this.galleryTimeperiod = 1;

          response.data.view_tp_dd.forEach(element => {
            if (all_time_period.indexOf(element.time_period) != -1) {
              element.selected = true;
              this.send_tp.push(element.time_period);
            } else {
              element.selected = false;

              let indexOfPopedElem = this.checkedIusList.indexOf(
                element.time_period
              );
              this.send_tp.splice(indexOfPopedElem, 1);
            }

            // this.view_viz_time_period = this.send_tp.toString();
          });

          this.viewTimePeriodList = response.data.view_tp_dd;
        }

        this.sortingOrder(response.data.record.sorting_order);
      });
  }

  sortingOrder(order) {
    if (order == 1) {
      $(".desc").removeClass("hide").show();
      $('.decending').show();
      $('.ascending').hide();

      this.galleryUseCase(1);
      return;
    } else if (order == 0) {
      $(".asc").removeClass("hide").show();
      $('.decending').hide();
      $('.ascending').show();
      this.galleryUseCase(0);
      return;
    } else if (order == 2) {
      $(".asc")
        .removeClass("hide")
        .show();
      this.galleryUseCase(2);
      return;
    }
  }

  galleryUseCase = function (order) {
    let that = this;
    let prop = "value";
    let asc = Number(order);
    let pass_data = this.seriesData;

    that.use_case_get_data = [];

    let objData = {};

    if (asc !== 2) {
      pass_data = pass_data.sort(function (a, b) {
        if (asc == 1) {
          //return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
          return a[prop] - b[prop];
        } else if (asc == 0) {
          //return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
          return b[prop] - a[prop];
        }
      });
    }
    // pass_data =_.sortBy(pass_data, "value"));
    setTimeout(function () {
      that.all_ius = [];
      that.all_indicator = [];
      that.all_unit = [];
      that.all_subgroup = [];
      that.all_area = [];
      that.all_time_period = [];
      that.all_seriesData = [];
      if (that.checkedRecivedTp.toLowerCase() == "mrd") {
        pass_data.forEach(element => {
          if (
            that.all_ius.indexOf(
              element.indicator + "|" + element.unit + "|" + element.subgroup
            ) == -1
          ) {
            that.all_ius.push(
              element.indicator + "|" + element.unit + "|" + element.subgroup
            );
          }

          element.ius =
            element.indicator + "|" + element.unit + "|" + element.subgroup;

          if (that.all_indicator.indexOf(element.indicator) == -1) {
            that.all_indicator.push(element.indicator);
          }

          if (that.all_unit.indexOf(element.unit) == -1) {
            that.all_unit.push(element.unit);
          }

          if (that.all_subgroup.indexOf(element.subgroup) == -1) {
            that.all_subgroup.push(element.subgroup);
          }

          if (that.all_area.indexOf(element.area_name) == -1) {
            that.all_area.push(element.area_name);
          }

          if (that.all_time_period.indexOf(element.time_period) == -1) {
            that.all_time_period.push(element.time_period);
          }

          if (that.all_seriesData.indexOf(element.value) == -1) {
            that.all_seriesData.push(element.value);
          }
        });

       
      } else {
       
        
        pass_data.forEach(element => {

          if(element.indicator == undefined) {
            element.indicator = element.allius.indicator;
            element.unit = element.allius.unit;
            element.subgroup = element.allius.subgroup;
          }

          if(element.area_name == undefined) {
            element.area_name = element.area.area_name;
            element.area_code = element.area.area_code;
            element.area_level = element.area.area_level;
            element.parent_id = element.area.parent_id;
          }

          if (
            that.all_ius.indexOf(
              element.indicator +
              "|" +
              element.unit +
              "|" +
              element.subgroup
            ) == -1
          ) {
            that.all_ius.push(
              element.indicator +
              "|" +
              element.unit +
              "|" +
              element.subgroup
            );
          }

          if (that.all_indicator.indexOf(element.indicator) == -1) {
            that.all_indicator.push(element.indicator);
          }

          if (that.all_unit.indexOf(element.unit) == -1) {
            that.all_unit.push(element.unit);
          }

          if (that.all_subgroup.indexOf(element.subgroup) == -1) {
            that.all_subgroup.push(element.subgroup);
          }

          if (that.all_area.indexOf(element.area_name) == -1) {
            that.all_area.push(element.area_name);
          }

          if (that.all_time_period.indexOf(element.time_period) == -1) {
            that.all_time_period.push(element.time_period);
          }

          if (that.all_seriesData.indexOf(element.value) == -1) {
            that.all_seriesData.push(element.value);
          }

          element.area_code = element.area_code;
          element.area_level = element.area_level;
          element.area_name = element.area_name;
          element.indicator = element.indicator;
          element.ius =
            element.indicator +
            "|" +
            element.unit +
            "|" +
            element.subgroup;
          element.parent_id = element.parent_id;
          element.subgroup = element.subgroup;
          element.unit = element.unit;
        });
      }

      
     

      // response.data.report_object[i]['useCase']
      let currentChatType;

      if (that.visualizationType == "treemap") {
        currentChatType = "pie";
      } else {
        currentChatType = that.visualizationType;
      }
      let func_name = that.useCase.findUseCase(
        currentChatType,
        that.all_indicator.length,
        that.all_unit.length,
        that.all_subgroup.length,
        that.all_time_period.length,
        that.all_area.length
      );

     
      objData["time_period"] = that.all_time_period;
      objData["area"] = that.all_area;
      objData["data"] = pass_data;
      objData["ius"] = that.all_ius;

      if (
        that.visualizationType == "pie" ||
        that.visualizationType == "treemap"
      ) {
        that.use_case_get_data = that.pieUseCase[func_name](objData);
      } else {
        that.use_case_get_data = that.columnUseCase[func_name](objData);

      }





      // this.findUseCase(this.visualizationType,pass_data);
      // this.UseCaseService.findUseCase(this.visualization_type);
      var find_chart_type =
        that.gelleryApiResponse.data.record.visualization_type;
      

      if (find_chart_type == "pie") {
        // $('#swap_charts').css("display", "none");

        that.renderResultChartsColumn(
          "chartid" + that.gelleryApiResponse.data.record.id
        );
      } else if (find_chart_type == "treemap") {
        // $('#swap_charts').css("display", "none");
        that.resultgallaryrenderTreemap(
          "chartid" + that.gelleryApiResponse.data.record.id
        );
      } else if (find_chart_type == "radar") {
        //$('#swap_charts').css("display", "none");
        that.resultgallaryrenderRadar(
          "chartid" + that.gelleryApiResponse.data.record.id
        );
      } /* else if(find_chart_type == 'stack_column' || find_chart_type == 'stack_100_column' || find_chart_type == 'stack_bar' || find_chart_type == 'stack_100_bar'){
                
                that.renderColumnStackChart(find_chart_type,'chartid'+that.gelleryApiResponse.data.record.id );
              }else if(find_chart_type=='area'){
                
                that.renderAreaChart(find_chart_type,'chartid'+that.gelleryApiResponse.data.record.id );
              }else if(find_chart_type == 'stack_area' || find_chart_type == 'stack_100_area' ){
                
                that.renderAreaStackChart(find_chart_type,'chartid'+that.gelleryApiResponse.data.record.id );
              }*/ else {
        // $('#swap_charts').css("display", "block");

        that.renderResultChartsColumn(
          "chartid" + that.gelleryApiResponse.data.record.id
        );

        // need to call this this for render chart
      }

      if (that.gelleryApiResponse.data.record.swap_status == 1) {
        that.toggleGrouping();
      }
      //populate time period
    }, 0);
  };

  renderResultChartsColumn(container) {
    // alert('seriesData');
    let rChartSetting = $.parseJSON(
      this.gelleryApiResponse.data.record.visualization_data
    );
    rChartSetting.xAxis.categories = this.use_case_get_data.xaxis;
    let seriesDataPlot = [];

    let data_get: any;
    rChartSetting.legend.itemStyle = {
      fontWeight: "normal",
      fontSize: "9px",
      color: "#666666"
    };

    if (rChartSetting.legend.align == "right") {
      rChartSetting.legend.itemWidth = 100;
      rChartSetting.legend.itemMarginBottom = 4;
      rChartSetting.legend.x = 10;
      rChartSetting.legend.y = 5;
    }

    /* $rChartSetting.legend.layout= 'vertical';
     $rChartSetting.legend.itemMarginTop= 0;
     $rChartSetting.legend.itemMarginBottom = 2;
     $rChartSetting.legend.align = 'right';
     $rChartSetting.legend.verticalAlign = 'top';*/

    
    let getpieChart = this.gelleryApiResponse.data.record.visualization_type;

    if (getpieChart == "pie") {
      seriesDataPlot = [{ data: this.use_case_get_data.series }];
    } else seriesDataPlot = this.use_case_get_data.series;

   /* if (rChartSetting.legend.enabled == true) {
      rChartSetting.plotOptions.pie = {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: { enabled: false },
        showInLegend: true
      };
    } else {
      rChartSetting.plotOptions.pie = {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: { enabled: false },
        showInLegend: false
      };
    }*/

    
    //$series_data_source_str;

    if (
      typeof rChartSetting.colors == "undefined" ||
      rChartSetting.colors == ""
    ) {
      let colorValue = [
        "#00AEF0",
        "#ffcc00",
        "#10cf92",
        "#dd509c",
        "#008E8E",
        "#D64646",
        "#8E468E",
        "#588526",
        "#B3AA00",
        "#008ED6",
        "#9D080D",
        "#A186BE",
        "#CC6600",
        "#FDC689",
        "#ABA000",
        "#F26D7D",
        "#FFF200",
        "#0054A6",
        "#F7941C",
        "#CC3300",
        "#006600",
        "#663300",
        "#6DCFF6"
      ];
    } else {
      let colorValue = rChartSetting.colors;
    }



    let optsResult: any = {
      title: rChartSetting.title,
      subtitle: {
        text: rChartSetting.subtitle.text,
        style: rChartSetting.subtitle.style
      },
      xAxis: rChartSetting.xAxis,
      yAxis: rChartSetting.yAxis,
      legend: rChartSetting.legend,
      plotOptions: rChartSetting.plotOptions,
      exporting: {
        enabled:false,
        chartOptions: {
                   
                    chart: {
                        height: 600,
                        marginBottom: 220,
                        width: 1000,
                        marginLeft : 250,
                        marginRight : 200,
                        
                    },
                },      
        
      },
      credits: { enabled: false },
      series: seriesDataPlot
    };

    if (this.chartTargetUseCase && this.chartTargetUseCase.nativeElement) {
      optsResult.chart = {
        type: this.gelleryApiResponse.data.record.visualization_type,
        renderTo: this.chartTargetUseCase.nativeElement
      };

      this.viewResultObj = new Highcharts.Chart(optsResult);
      $("#myModal")
        .find(".loaders >  ol > li")
        .fadeOut();
    }

    /* if($('.gallery-colorPicker').val()) {
       viz_view_obj.update({
                         colors: returnColorArray($('.gallery-colorPicker').val())
                     });
     }*/
  }

  resultgallaryrenderTreemap(container) {
    let rChartSetting = $.parseJSON(
      this.gelleryApiResponse.data.record.visualization_data
    );
    rChartSetting.xAxis.categories = this.use_case_get_data.xaxis;
    let seriesDataPlot;

    // let isEmpty = (this.seriesData || []).length === 0;
    let resultTreeData: any = [];
    /* if(!isEmpty){
         delete this.seriesData[0].colorByPoint;
         delete this.seriesData[0].name;
         $.each(this.seriesData[0].data, function(){
             delete this.y;
         });

          resultTreeData = this.seriesData[0].data;

     }*/

    /*    $rChartSetting.legend.itemStyle =  {
                    fontWeight: 'normal',
                    fontSize: '9px',
                    color : '#666666',
                };
        $rChartSetting.legend.layout= 'vertical';
        $rChartSetting.legend.itemMarginTop= 2;
        $rChartSetting.legend.itemMarginBottom = 2;
        $rChartSetting.legend.align = 'right';
        $rChartSetting.legend.verticalAlign = 'top';
  
        
  
        $rChartSetting.plotOptions.pie.showInLegend = true;
  
    */

    if (
      typeof rChartSetting.colors == "undefined" ||
      rChartSetting.colors == ""
    ) {
      let colorValue = [
        "#00AEF0",
        "#ffcc00",
        "#10cf92",
        "#dd509c",
        "#008E8E",
        "#D64646",
        "#8E468E",
        "#588526",
        "#B3AA00",
        "#008ED6",
        "#9D080D",
        "#A186BE",
        "#CC6600",
        "#FDC689",
        "#ABA000",
        "#F26D7D",
        "#FFF200",
        "#0054A6",
        "#F7941C",
        "#CC3300",
        "#006600",
        "#663300",
        "#6DCFF6"
      ];
    } else {
      let colorValue = rChartSetting.colors;
    }

    //findUseCase('column');

    let optsResult: any = {
      title: rChartSetting.title,
      subtitle: {
        text: rChartSetting.subtitle.text,
        style: rChartSetting.subtitle.style
      },
      // xAxis: rChartSetting.xAxis,
      // yAxis: rChartSetting.yAxis,
      // legend: rChartSetting.legend,
      credits: { enabled: false },
      series: [
        {
          type: "treemap",
          layoutAlgorithm: "squarified",
          colorByPoint: true,
          data: this.use_case_get_data.series
        }
      ],
      exporting: {
        enabled:false,
        chartOptions: { 
                    chart: {
                        height: 600,
                        marginBottom: 220,
                        width: 1000,
                        marginLeft : 250,
                        marginRight : 200,   
                    },
                },
      },
    };

    if (this.chartTargetUseCase && this.chartTargetUseCase.nativeElement) {
      optsResult.chart = {
        type: this.gelleryApiResponse.data.record.visualization_type,
        renderTo: this.chartTargetUseCase.nativeElement
      };

      this.viewResultObj = new Highcharts.Chart(optsResult);
      $("#myModal")
        .find(".loaders >  ol > li")
        .fadeOut();
    }
  }

  resultgallaryrenderRadar(container) {
    let rChartSetting = $.parseJSON(
      this.gelleryApiResponse.data.record.visualization_data
    );

    //$rChartSetting.xAxis.categories = xaxis;

    let colorValue = [];

    rChartSetting.legend.itemStyle = {
      fontWeight: "normal",
      fontSize: "9px",
      color: "#666666"
    };
    /*$rChartSetting.legend.layout= 'vertical';
    $rChartSetting.legend.itemMarginTop= 2;
    $rChartSetting.legend.itemMarginBottom = 2;
    $rChartSetting.legend.align = 'right';
    $rChartSetting.legend.verticalAlign = 'top';*/
    rChartSetting.xAxis.tickmarkPlacement = "on";
    rChartSetting.xAxis.lineWidth = 0;
    rChartSetting.xAxis.categories = this.use_case_get_data.xaxis;

    if (
      typeof rChartSetting.colors == "undefined" ||
      rChartSetting.colors == ""
    ) {
      colorValue = [
        "#00AEF0",
        "#ffcc00",
        "#10cf92",
        "#dd509c",
        "#008E8E",
        "#D64646",
        "#8E468E",
        "#588526",
        "#B3AA00",
        "#008ED6",
        "#9D080D",
        "#A186BE",
        "#CC6600",
        "#FDC689",
        "#ABA000",
        "#F26D7D",
        "#FFF200",
        "#0054A6",
        "#F7941C",
        "#CC3300",
        "#006600",
        "#663300",
        "#6DCFF6"
      ];
    } else {
      colorValue = rChartSetting.colors;
    }

    let optsResult: any = {
      title: rChartSetting.title,
      subtitle: {
        text: rChartSetting.subtitle.text,
        style: rChartSetting.subtitle.style
      },
      xAxis: rChartSetting.xAxis,
      pane: {
        size: "80%"
      },
      legend: rChartSetting.legend,
      credits: { enabled: false },
      yAxis: {
        gridLineInterpolation: "polygon",
        lineWidth: 0,
        min: 0
      },
      exporting: {
        enabled:false,
        chartOptions: {
                   
                    chart: {
                        height: 600,
                        marginBottom: 220,
                        width: 1000,
                        marginLeft : 250,
                        marginRight : 200,
                        
                    },
                },      
        
      },
      series: this.use_case_get_data.series
    };

    if (this.chartTargetUseCase && this.chartTargetUseCase.nativeElement) {
      optsResult.chart = {
        type: "line",
        renderTo: this.chartTargetUseCase.nativeElement,
        polar: true
      };

      this.viewResultObj = new Highcharts.Chart(optsResult);
      $("#myModal")
        .find(".loaders >  ol > li")
        .fadeOut();
    }

    /* viz_view_obj =  Highcharts.chart('result-chart-container', {

         chart: {
             polar: true,
             type: 'line',            
             style: {
                 fontFamily: '"SF Pro Text",sans-serif'
             },
            
         },
         colors :colorValue,

         title: { 
                 text:$rChartSetting.title.text,
                 style: $rChartSetting.title.style,
                 x: -80},
         subtitle: {text:$rChartSetting.subtitle.text,style:$rChartSetting.subtitle.style},
         pane: {size: '80%'},

         xAxis: {
             categories: xaxis,
             tickmarkPlacement: 'on',
             lineWidth: 0
         },

         yAxis: {
             gridLineInterpolation: 'polygon',
             lineWidth: 0,
             min: 0
         },

         tooltip: {
             shared: true,
             pointFormat: '<span style="color:{series.color}">Value :  <b>{point.y:,.0f}</b><br/>'
         },

         legend: $rChartSetting.legend,

         series: seriesData,
          credits: {
             enabled: false
         },
         exporting: {
             enabled: false
         }

     });*/
  }

  // user-wise sidebar

  isAdmin(): boolean {
    let authDetails = JSON.parse(localStorage.getItem("user_details"));
    return authDetails.role == 1 ? true : false;
  }
  downloadChart(viz_name) {

    this.viewResultObj.exportChartLocal({
      type: "image/png",
      filename: viz_name
    });
  }
  publishOnSocialMedia(social) {
    let prepareNewWindow: any = window.open("", "_blank");
    let that = this;
    let dataURL: any = {};
    let downLoadSection = document.getElementById("visualization_container");
    html2canvas(downLoadSection).then(function (canvas) {
      dataURL["img"] = canvas.toDataURL("image/png");
      that.dataService
        .add("api/share-img-store", dataURL)
        .subscribe((res: any) => {
          that.shareUrlLink = res.image_name;
          if (social == "facebook") {
            let tempUrl =
              "https://www.facebook.com/sharer.php?s=100&&p[url]=" +
              that.shareUrlLink;

            prepareNewWindow.location = tempUrl;
          } else {
            let tempUrl = "https://twitter.com/share?url=" + that.shareUrlLink;
            prepareNewWindow.location = tempUrl;
          }
        });
    });
  }

  change_from_to(time_period, type) {
    if (type == "from") {
      this.changed_from_timeperiod = time_period;
    } else if (type == "to") {
      this.changed_to_timeperiod = time_period;
    }
  }

  // from_to_button(id) {
  //   $("#loaders_" + id + " ol li").fadeIn();
  //   if (this.changed_from_to_timeperiod[id]['from'] == '' || typeof this.changed_from_to_timeperiod[id]['from'] == 'undefined' || this.changed_from_to_timeperiod[id]['to'] == '' || typeof this.changed_from_to_timeperiod[id]['to'] == 'undefined') {

  //     this.toastr.error('From and To ,both should be selected.');
  //     $("#loaders_" + id + " ol li").fadeOut();

  //   } else if (this.changed_from_to_timeperiod[id]['from'] > this.changed_from_to_timeperiod[id]['to']) {
  //     this.toastr.error('The To date must be greater than the From date.');
  //     $("#loaders_" + id + " ol li").fadeOut();
  //   } else {

  //     this.loadData(id);
  //   }

  // }

  from_to_button() {
    if (
      this.changed_from_timeperiod == "" ||
      this.changed_to_timeperiod == ""
    ) {
      this.toastr.error("From and To both should be selected.");
    } else if (this.changed_from_timeperiod > this.changed_to_timeperiod) {
      this.toastr.error("To date must be greater than the From date.");
    } else {
      //alert(from_time_period+"-"+to_time_period);
      this.view_viz_time_period = "";
      this.show_data_by_ius_area_tp(this.view_viz_Ius_id, this.view_viz_area);
    }
  }

  toggleGrouping() {
    $("#myModal")
      .find(".loaders >  ol > li")
      .fadeIn();
    let newLabels = [];
    let newCategories = [];
    let newData = [];
    var chart = this.viewResultObj;
    let swapCardType = this.visualizationType;
    // var chart = $('#visualization_container').highcharts();

    if (
      swapCardType == "treemap" ||
      swapCardType == "pie" ||
      swapCardType == "map" ||
      swapCardType == "pyramid" ||
      swapCardType == "bubble" ||
      swapCardType == "table" ||
      swapCardType == ""
    ) {
      $("#myModal")
        .find(".loaders >  ol > li")
        .fadeOut();
      this.toastr.error("Swap not allowed for this chart type.");
      return false;
    }

    setTimeout(function () {
      // var chart = $('#visualization_container').highcharts();


      let seriez = chart.series;

      $.each(chart.xAxis[0].categories, function (i, name) {
        newLabels.push(name);
      });

      $.each(seriez, function (x, serie) {
        newCategories.push(serie.name);
        $.each(serie.data, function (z, point) {
          if (newData[z] == undefined) {
            newData[z] = [];
          }
          if (newData[z][x] == undefined) {
            newData[z][x] = "";
          }


          newData[z][x] = point.y == undefined ? "" : point.y;
        });
      });

      while (chart.series.length > 0) {
        chart.series[0].remove(true);
      }
      chart.xAxis[0].setCategories(newCategories, false);
      $.each(newData, function (key, newSeries) {
        chart.addSeries(
          {
            name: newLabels[key],
            data: newSeries
          },
          false
        );
      });
      // chart.inverted = true;

      chart.redraw();
      // remove_loader('result-chart-container');

      $("#myModal")
        .find(".loaders >  ol > li")
        .fadeOut();
    }, 0);
  }

  getSelectedIus() {
    let that = this;
    // this.checkedIusList = [];
    this.addtitleIus = [];
    let ius = "";
  
    let tempIus2 = [];
    this.checkedIusList = [];

    $(".all-ius-class:checkbox:checked").each(function () {


      let subgroup = $(this);

      if(that.checkedIusList.indexOf(Number(subgroup.attr('data-ius_id'))) == -1){
        that.checkedIusList.push(Number(subgroup.attr('data-ius_id')));
      }
      
     
      ius =
        subgroup.attr("data-ind") +
        "|" +
        subgroup.attr("data-unit") +
        "|" +
        subgroup.attr("data-subgroup");
      tempIus2.push(ius);
    });

    console.log(that.checkedIusList)
    this.addtitleIus = tempIus2;
  }

  navigateToPreviousUrl() {
    this.router.navigate(["/gallery-management"]);
  }
  nextStep() {
    if (this.tabIndex != 4) {
      this.tabIndex = this.tabIndex + 1;
    }
  
  }
  previousStep() {
    if (this.tabIndex != 0) {
      this.tabIndex = this.tabIndex - 1;
    }

  }
  editViz(rowData) {
    this.visualizationList.forEach(element => {
      element.showSaveIcon = false;
    });
    rowData.showSaveIcon = true;
  }
  noEditViz(rowData) {
    rowData.showSaveIcon = false;
  }
  saveVizName(vizDetail) {
    let vizObjData = {
      'id': vizDetail.id,
      'viz_name': vizDetail.visualization_name
    };
    this.dataService
      .add("api/gallery/visualization/updatename", vizObjData)
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == 1) {
            this.toastr.success(response.msg);
          }
          setTimeout(() => {
            this.ngOnInit();
          }, 200);
        },
        (error: any) => {
          console.log(error);
        }
      );
  }
  searchIndicator(obj) {
   
      let searchfor = obj.target.value.toLowerCase();
      //$(".tree-parent").hide();  

      $(".allSelectAdvancelevel3").each(function (index) {

        // if (index !== 0) {
        let row = $(this);
        // var id = row.data('ius_name').toLowerCase();
         var patt = new RegExp(searchfor.toLowerCase());
         var res = patt.test(row.data('ius_name').toLowerCase());
         if(res==false){

          row.parent().parent().parent().parent().hide();
        }
        else {
          row.parent().parent().parent().parent().show();
          
        }
        //  }
      });

    
  }
  selectAllIndicator(e) {
 
   
    if (e.target.checked==true) {
      $(' .allSelectAdvanceIuscl').parentsUntil('ul').siblings().filter(':visible').find(':checkbox').not('#view_selected').prop('checked', true);

      $('[name="add_select_all_ius"]').prop('checked', true);
      $('.tree-parent').removeClass('non-selected-ius');
    } else {
      $(' .allSelectAdvanceIuscl').parentsUntil('ul').siblings().filter(':visible').find(':checkbox').not('#view_selected').prop('checked', false);

      $('[name="add_select_all_ius"]').prop('checked', false);
      $('.tree-parent').addClass('non-selected-ius');
    }

    this.getSelectedIus();
    return;

  }
  viewSelectedFilter(e){
      if(e.target.checked==false){   
          $('.non-selected-ius').css('display','block');       
      }else{        
          $('.non-selected-ius').css('display','none');
      }
  
  
  }

  addIusList(event) {


    // console.log(event.target.dataset.ius);return;

    // let showListIus = event.target.dataset.ius;
    let showListIus = event.target.value;
    

    $('.iu_list_by_sector').map(function() {
        let row = $(this);
        var subsector = row.data('subsector');       
     
        if(subsector == showListIus){        
          row.css('display','block');
        } else{          
          row.css('display','none');
        }
    });
  }


  showAllAddIndicator(){
    // $('.tree-parent').addClass('non-selected-ius');
    $('.viwSelected').prop('checked',false);
    $('.iu_list_by_sector').css('display','block');
  }

  checkAllChildSubgroup(e){
   
    let ind = e.target.dataset.ind;
    if(e.target.checked==true){
      $(".ind-subgroup-"+ind).prop('checked',true);
      $('.parentclass-'+ind).removeClass('non-selected-ius');
    }else{
      $(".ind-subgroup-"+ind).prop('checked',false);
      $('.parentclass-'+ind).addClass('non-selected-ius');
    }
    
  }

  getFormatedTimeperiod(timePeriod){
    if (timePeriod.indexOf(',') > -1) {
    let  noOfTimePeriods = 1+ timePeriod.split(',').length-1;
    let boxTimePeriod = ""
    for(let i=0;i<noOfTimePeriods;i++){
      let tempStr = timePeriod.split(',')[i];
      boxTimePeriod += this.commonService.covertTimePeriod(tempStr)+",";
    }
    return boxTimePeriod.substring(0, boxTimePeriod.length - 1)
    }
    else {return this.commonService.covertTimePeriod(timePeriod);}
  }
  
  

}
