import { Component, OnInit, Injectable, AfterViewInit } from "@angular/core";
import { HeaderService } from "../../services/header.service";
import { Configuration } from "../../app.constant";
import { ApiService } from "../../services/api.service";
import { ToastrService } from "ngx-toastr";
import { Fileupload } from "blueimp-file-upload/js/jquery.fileupload";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";

declare var $: any;

@Component({
  selector: "app-data-import-new",
  templateUrl: "./data-import-new.component.html",
  styleUrls: ["./data-import-new.component.css"]
})
@Injectable()
export class DataImportComponentNew implements OnInit {
  selectedFiles: FileList;
  fileName: string;
  fileTypeErrorMsg: boolean = false;
  area_error=[];
  update=[];

  constructor(
    private headerService: HeaderService,
    private conf: Configuration,
    private dataService: ApiService,
    private toastr: ToastrService
    
  ) {}

  ngOnInit() {

    this.headerService.setTitle("Upload Data - NFHS");
    this.headerService.setLogo(
      "assets/lib/images/svg/header-menu/data-import.svg"
    );
  }

  ngAfterViewInit() {
    $(".loaders ol li").fadeOut();
    let that = this;
    $(document).on("change", "#fileupload", function(e) {
      that.detectFiles(e);
    });
   
    var url = that.conf.serverWithApiUrl + 'datamanager/data/upload';

    $("#fileupload")
      .fileupload({
        maxChunkSize: 10000000,
        acceptFileTypes: /(\.|\/)(csv)$/i,
        url: url,
        dataType: "json",
        formData: {
          import_for: 9
        },
        add: function(e, data) {
          $("#upload")
            .off("click")
            .on("click", function() {
              $(".loaders ol li").fadeIn();
              data.submit();
            });
        },
        beforeSend: function(xhr, data) {
         
        
          xhr.setRequestHeader("Accept", "application/json");
          xhr.setRequestHeader(
            "Authorization",
            `Bearer ${localStorage.getItem("auth_token")}`
          );
        },
        done: function(e, data) {
          $.each(data.result.files, function(index, file) {
            $("<p/>")
              .text(file.name)
              .appendTo("#files");
          });

          $(".loaders ol li").fadeOut();

          that.update =data.result.data[0].target_error;
          that.area_error =data.result.data[0].area_error;
          that.fileUploadMessage();
        },
       
        error: function(jqXHR, textStatus, errorThrown) {
          $(".loaders ol li").fadeOut();
          if (errorThrown == "Bad Request") {
            that.inValidFileUploadMsg();
          }
        },
        progressall: function(e, data) {
          var loaded: number = data.loaded;
          var total: number = data.total;
          var tempCalc = (loaded / total) * 100;

          var progress = parseInt(tempCalc.toString(), 10);

          $("#progress .progress-bar").css("width", progress + "%");
        }
      })
      .prop("disabled", !$.support.fileInput)
      .parent()
      .addClass($.support.fileInput ? undefined : "disabled");

    //});
  }
  validFilesType = ["csv"];
  detectFiles(event) {
    this.selectedFiles = event.target.files;
    this.fileName = this.selectedFiles[0].name;
    var ext = this.selectedFiles[0].name
      .split(".")
      .pop()
      .toLowerCase();

    if (this.validFilesType.indexOf(ext) != -1) {
      // this.detectFiles(event);
      this.fileTypeErrorMsg = false;
    } else {
      console.log("invalid ");
      $("#fileupload").val("");
      this.fileName = "";

      this.toastr.error(
        "Invalid File. Valid extensions are:\n\n" +
          this.validFilesType.join(", ")
      );
      this.fileTypeErrorMsg = true;
   
    }
  }

  getObjectKey(object){
    if(object){
      return Object.keys(object);
    }

    return [];
    
  }


  fileUploadMessage() {
    this.toastr.success(
      "File uploaded successfully. We will send you mail once file is imported"
    );
    this.fileName = "";
  }

  saveFile() {
    const headers = new Headers();
    headers.append("Accept", "text/plain");
  }

  downloadFile(type) {
    this.dataService
      .getAll("api/datamanager/data/download/1/" + type)
      .subscribe((response: any) => {
        let a = document.createElement("a");
        let rest = response.file.substring(
          0,
          response.file.lastIndexOf("/") + 1
        );
        let last = response.file.substring(
          response.file.lastIndexOf("/") + 1,
          response.file.length
        );
        a.href = response.file;
        a.download = last;
        document.body.appendChild(a);
        setTimeout(() => {
          a.click();
        }, 1000);
        document.body.removeChild(a);
      });
  }



  chooseFile() {
    document.getElementById("fileupload").click();
  }
  inValidFileUploadMsg() {
    this.toastr.error('Invalid File Format');
  }
}
