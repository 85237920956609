import { Component, OnInit, AfterViewInit, Input, ViewChild,} from "@angular/core";
import { AuthService } from "../services/auth/auth.service";
import { Router } from "@angular/router";
import { HeaderService } from "../services/header.service";
import { ApiService } from "../services/api.service";
import { TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions,} from "angular-tree-component";
import { SharedataService } from "../services/sharedata.service";
import { Subscription } from "rxjs";
import { TreeModel, TreeNode } from "angular-tree-component";
import { ToastrService } from "ngx-toastr";
import { Configuration } from "../app.constant";
import { Fileupload } from "blueimp-file-upload/js/jquery.fileupload";
import { ChangeDetectorRef } from "@angular/core";
declare var $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  @Input() public isUserLoggedIn: boolean = false;
  @ViewChild("tree") tree;
  title = "";
  sectorTitle;
  subscription: Subscription;
  logo_img = "";
  loggedUserName = "";
  loggedUserAreaName = "";
  router: string;
  states = [];
  nodes = [];
  authDetails;
  areaDetails;
  SectorName: string;
  selectedFiles: FileList;
  fileName: string;
  fileTypeErrorMsg: boolean = false;
  filteredArray = [];
  area_code = "IND";
  options: ITreeOptions = {
    actionMapping: {
      mouse: {
        click: (tree, node, $event) => {
          this.sendData(node.data);
          $("#select_area").html(node.data.name);
          $(".dropdown").removeClass("open");
        },
      },
    },
  };

  constructor(
    private dataService: ApiService,
    private auth: AuthService,
    private route: Router,
    private headerService: HeaderService,
    private shareData: SharedataService,
    public toastr: ToastrService,
    private conf: Configuration,
    private cdr: ChangeDetectorRef,
  ) {
    this.router = route.url;
    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "sidebar":
          this.sectorTitle = message.data.data.parentData.level1;
          break;
        case "header":
          this.area_code = message.data.data.code;
          $("#select_area").html(message.data.data.name);
          break;
        default:
          break;
      }
    });
  }

  ngAfterContentChecked(): void {   
        this.cdr.markForCheck();
}

  ngOnInit() {
    
    let user_area_detail = JSON.parse(localStorage.getItem("area_details"));
    this.loggedUserAreaName = user_area_detail.area_name;

    this.headerService.title.subscribe((title) => {
      this.title = title;
    });
    this.headerService.logo_img.subscribe((logo_img) => {
      this.logo_img = logo_img;
      this.cdr.markForCheck();
    });

    this.headerService.loggedUserName.subscribe((loggedUserName) => {
      this.loggedUserName = loggedUserName;
    });
    this.authDetails = JSON.parse(localStorage.getItem("user_details"));
    this.areaDetails = JSON.parse(localStorage.getItem("area_details"));
    this.shareData.currentMessage.subscribe(
      (SectorName) => (this.SectorName = SectorName)
    );

    //page reload event handler
    //ngOnit call once in page reload
    this.getAreaList();

    if (this.isLoggednIn()) {
      let areaDetails = JSON.parse(localStorage.getItem("area_details"));
      let authDetails = JSON.parse(localStorage.getItem("user_details"));

      localStorage.setItem("areaCode", areaDetails.area_code);
      localStorage.setItem("areaLevel", areaDetails.area_level);
      localStorage.setItem("areaName", areaDetails.area_name);
    }
  }
  getAreaList() {
    this.dataService.getAll("api/get-area-list").subscribe((response: any) => {
      this.nodes = response.data.area_list;
      let obj = this;
      setTimeout(function () {
        obj.expandArea();
      }, 1000);
    });
  }

  expandArea() {
    if (typeof this.tree != "undefined") {
      const firstNode = this.tree.treeModel.getNodeById("1");
      firstNode.expand();
    }
  }

  ngAfterViewInit() {

    let areaDetails = JSON.parse(localStorage.getItem("area_details"));
    let authDetails = JSON.parse(localStorage.getItem("user_details"));
    if (authDetails.role > 2) {
      if (areaDetails !== null) {
        this.area_code = areaDetails.area_code;
      }
    }
    let that = this;
    $(document).on("change", "#generate-flipbook", function (e) {
      that.detectFiles(e);
    });

    $(document).on(
      "click",
      ".tree-select-indicator-header .dropdown-menu",
      function (e) {
        e.stopPropagation();
        $(this).next().slideToggle();
        $("tree-node-content:last-child").click(function () {
          $(".tree-select-indicator-header > .dropdown").removeClass("open");
          $(".area-map > button").text($(this).text());
        });
      }
    );
    $(document).on(
      "click",
      ".tree-select-indicator-header .dropdown-menu",
      function (e) {
        e.stopPropagation();
        $(this).next().slideToggle();
        $("tree-node-content:last-child").click(function () {
          $(".tree-select-indicator-header > .dropdown").removeClass("open");
          $(".area-map > button").text($(this).text());
        });
      }
    );

    // sidebar menu
    $(".right-mob-menu > ul > li > a").click(function () {
      $(".right-mob-menu").removeClass("open");
      $(".appsIcon1").html($(this).html());
    });
    $("#new_tab").click(function () {});
  }

  logout() {
    this.auth.logout().subscribe((resp: any) => {
      localStorage.removeItem("auth_token");
      localStorage.getItem("user_details");
      localStorage.removeItem("area_details");
      this.route.navigate(["login"]);
    });
    // this.route.navigate(["login"]);
    // localStorage.setItem('remember_me','false');
    //  console.log(localStorage, 'header')
  }
  isLoggednIn(): boolean {
    return localStorage.getItem("auth_token") !== null;
  }

  downloadReport() {
    this.shareData.sendData({ ctype: "header", data: [], isDashboard: 1 });
  }
  sendData(data): void {
    localStorage.setItem("areaCode", data.code);
    localStorage.setItem("areaLevel", data.level);
    localStorage.setItem("areaName", data.name);
    this.shareData.sendData({ ctype: "header", data: data, isDashboard: 0 });
  }

  isAdmin(): boolean {
    let authDetails = JSON.parse(localStorage.getItem("user_details"));
    return authDetails.role == 1 ? true : false;
  }

  isNationalUser(): boolean {
    let authDetails = JSON.parse(localStorage.getItem("user_details"));
    return authDetails.role == 2 ? true : false;
  }

  isStateUser(): boolean {
    let authDetails = JSON.parse(localStorage.getItem("user_details"));
    return authDetails.role == 3 ? true : false;
  }

  navigateFlipbook() {
    if (this.area_code != undefined || this.area_code != "") {
      window.open(
        "assets/flipbook/index.html?area=" + this.area_code,
        "_blank"
      );
      return true;
    } else {
      this.toastr.error("Please select Area.");
      return false;
    }
  }
  generateFlipbook() {
    $("#flipbookModal").modal("show");
    this.fileName = "";
    let that = this;
    var url = this.conf.server + "api/report/upload-flipbook";
    setTimeout(function () {
      $("#generate-flipbook")
        .fileupload({
          maxChunkSize: 20000000,
          acceptFileTypes: /(\.|\/)(pdf)$/i,
          url: url,
          dataType: "json",

          add: function (e, data) {
            $("#uploadFlipbook")
              .off("click")
              .on("click", function () {
                $(".loaders ol li").fadeIn();
                data.submit();
              });
          },
          beforeSend: function (xhr, data) {
            xhr.setRequestHeader("Accept", "application/json");
            xhr.setRequestHeader(
              "Authorization",
              `Bearer ${localStorage.getItem("auth_token")}`
            );
          },
          done: function (e, data) {
            $.each(data.result.files, function (index, file) {
              $("<p/>").text(file.name).appendTo("#files");
            });
          },
          stop: function () {
            that.toastr.success("Flipbook generated successfully.");
            setTimeout(() => {
              $(".loaders ol li").fadeOut();
              document.getElementById("generate-flipbook-close").click();
            }, 1000);
          },
          progressall: function (e, data) {
            var loaded: number = data.loaded;
            var total: number = data.total;
            var tempCalc = (loaded / total) * 100;

            var progress = parseInt(tempCalc.toString(), 10);

            $("#progress .progress-bar").css("width", progress + "%");
          },
        })
        .prop("disabled", !$.support.fileInput)
        .parent()
        .addClass($.support.fileInput ? undefined : "disabled");
    }, 2000);
  }
  chooseFile() {
    document.getElementById("generate-flipbook").click();
  }
  validFilesType = ["pdf"];
  detectFiles(event) {
    console.log(event.target.files, "xvbcb");
    console.log(event.target.files[0].name, "xcbcvb");
    this.selectedFiles = event.target.files;
    this.fileName = this.selectedFiles[0].name;
    var ext = this.selectedFiles[0].name.split(".").pop().toLowerCase();

    if (this.validFilesType.indexOf(ext) != -1) {
      // this.detectFiles(event);
      this.fileTypeErrorMsg = false;
    } else {
      console.log("invalid ");
      $("#generate-flipbook").val("");
      this.fileName = "";

      this.toastr.error(
        "Invalid File. Valid extensions are:\n\n" +
          this.validFilesType.join(", ")
      );
      this.fileTypeErrorMsg = true;
    }
  }
  fileUploadMessage() {
    this.toastr.success(
      "File uploaded successfully. We will send you mail once file is imported"
    );
  }

  isDisplayTitle(url) {
    let urls = [
      "/fs/nfhs-factsheet",
      "/fs/immunization",
      "/fs/administeredvaccine",
      "/fs/state-profile",
      "/fs/mr-sheet",
      "/fs/dataSummary-sheet"
    ];
    return urls.includes(url);
  }
  isHeaderMenuShow(url) {
    let urls = [
      "/fs/nfhs-factsheet",
      "/fs/immunization",
      "/fs/administeredvaccine",
      "/fs/state-profile",
      "/fs/mr-sheet",
      "/fs/dataSummary-sheet",
    ];
    return urls.includes(url);
  }
  isDisplayAreaCode(url) {
    let urls = [
      "/fs/nfhs-factsheet",
      "/fs/immunization",
      "/fs/administeredvaccine",
      "/fs/mr-sheet",
      "/fs/dataSummary-sheet",
    ];
    return urls.includes(url);
  }
}
