import { Component, OnInit, Input } from "@angular/core";
import { HeaderService } from "src/app/services/header.service";
import { HttpClient } from "@angular/common/http";
import { _ } from "underscore";
import { Configuration } from "../../../app.constant";

import { Subscription } from "rxjs";
import { SharedataService } from "src/app/services/sharedata.service";

declare var L: any;
declare var $: any;
var locations = [];
@Component({
  selector: "app-dashboard-map",
  templateUrl: "./dashboard-map.component.html",
  styleUrls: ["./dashboard-map.component.css"],
})
export class DashboardMapComponent implements OnInit {
  @Input() item: any;
  @Input() id: any;
  @Input() mapLevel;
  @Input() areaID = "IND";

  mapOptions = {
    zoom: 10,
    zoomSnap: 0.15,
    center: L.latLng(47.482019, -1),
    zoomControl: false,
    scrollWheelZoom: false,
  };
  center = L.latLng(21.7679, 78.8718);
  datas = [];
  subscription: Subscription;
  mapData1: any = [];
  dataArr: any = [];
  map: any;
  fitBounds = {};
  geoJson;
  MapJson;
  mapLink;

  source;
  data = [];
  map_global;
  changed_from_to_timeperiod = [];
  map_data_table = [];
  mapProperties;
  exist: boolean = true;
  private url: any;

  mapData: any;
  constructor(
    private headerService: HeaderService,
    private http: HttpClient,
    private shareData: SharedataService,
    private configuration: Configuration
  ) {
    switch (this.mapLevel) {
      case 1:
        this.mapLink = "assets/lib/map/IND_l02_2011_Census.json";
        break;
      case 2:
        // this.mapLink = "assets/lib/map/IND_l03_2011_Census.json";
        this.mapLink = "assets/lib/map/newJson_India-district_2023.json";
        break;
      case 3:
        this.mapLink = "assets/lib/map/IND_l04_2020.json"; //load block level map
      default:
        this.mapLink = "assets/lib/map/IND_l02_2011_Census.json";
        break;
    }

    this.http.get(this.mapLink).subscribe((res) => {
      this.mapData = res;
      localStorage.setItem("map", JSON.stringify(this.mapData));
    });
  }

  // ngAfterViewInit(): void {
  //   // this.initMap();
  // }

  ngOnInit() {
    this.fitBounds = L.LatLngBounds([
      [2.74031066894531, 11.102173805236816],
      [44.0, -119.0],
    ]);
  }

  getZoom() {
    if (this.mapLevel != 1) {
      return 6;
    }
    return 4.1;
  }

  onEachFeature(feature, layer) {
    let that = this;
    locations[feature.properties.code] = {
      lat: layer.getBounds().getCenter().lat,
      lng: layer.getBounds().getCenter().lng,
      properties: feature.properties,
    };
    var out = [];
    var label = [];

    if (feature.properties.DATA_VALUE != undefined) {
      out.push("Area Name : " + feature.properties.name);
      out.push(
        "Value : " +
          (feature.properties.DATA_VALUE ? feature.properties.DATA_VALUE : "")
      );
    }

    if (feature.properties) {
      label.push(
        '<span class="map_label_area_name_class" style="visibility:hidden;">' +
          feature.properties.name +
          '<br /></span> <span class="map_label_data_value_class" style="visibility:hidden;">' +
          (typeof feature.properties.DATA_VALUE != "undefined" &&
          feature.properties.DATA_VALUE != null
            ? feature.properties.DATA_VALUE
            : "") +
          "</span>"
      );
      layer.bindPopup(out.join("<br />"));
    }
    layer
      .bindTooltip(label.join("<br />"), {
        direction: "center",
        permanent: true,
        className: "map_label_class",
      })
      .addTo(that.map_global);
    $(".loaders ol li").fadeOut();
  }

  ngOnChanges() {
    //this.item = this.item;
    let that = this;
    if (this.item.length > 0) {
      var data = [];
      this.item.forEach(function (ele) {
        data.push({
          area_name: ele[0],
          value: ele[1],
          area_code: ele[2],
        });
      });
     
      that.updateMap(data);
    }
  }

  extractGeoJson(geojson) {
    //geojson extraction not required for level 1 as it loads all area in level2 map
    if (this.mapLevel == 1) return geojson;
    let extractedJson = [];
    for (var i = 0; i < geojson.features.length; i++) {
      if (geojson.features[i].properties.code.indexOf(this.areaID) !== -1) {
        extractedJson.push(geojson.features[i]);
      }
    }

    for (var i = 0; i < geojson.features.length; i++) {
      if (geojson.features[i].properties.code == this.areaID) {
        extractedJson.push(geojson.features[i]);
      }
    }
    return extractedJson;
  }

  updateMap(data) {
    this.MapJson = JSON.parse(localStorage.getItem("map"));
   
    if (this.MapJson != null ) {
      let self = this;
      // this.http.get(this.MapJson).subscribe((response: any) => {
      self.geoJson = self.extractGeoJson(this.MapJson);
        if (self.mapLevel != 1) {
        self.MapJson = { type: "FeatureCollection", features: self.MapJson };
        }
        self.populateMapData(data);
        self.createMapStructure();
      // });

      if (self.map_global) {
        self.map_global.setView(
          this.configuration.location_center[this.areaID],
          self.getZoom()
        );
      }
    } else {
      let self = this;
      this.getLevelMap();
      this.http.get(this.mapLink).subscribe((response: any) => {
        self.geoJson = self.extractGeoJson(response);
        if (self.mapLevel != 1) {
          self.geoJson = { type: "FeatureCollection", features: self.geoJson };
        }
        self.populateMapData(data);
        self.createMapStructure();
      });

      if (self.map_global) {
        self.map_global.setView(
          this.configuration.location_center[this.areaID],
          self.getZoom()
        );
      }
    }
  }

  getLevelMap() {
    switch (this.mapLevel) {
      case 1:
        this.mapLink = "assets/lib/map/IND_l02_2011_Census.json";

        break;
      case 2:
        // this.mapLink = "assets/lib/map/IND_l03_2011_Census.json";
        this.mapLink = "assets/lib/map/newJson_India-district_2023.json";
        break;
      case 3:
        this.mapLink = "assets/lib/map/IND_l04_2020.json"; //load block level map

        break;
      default:
        this.mapLink = "assets/lib/map/IND_l02_2011_Census.json";

        break;
    }
  }

  onMapReady(map: L.Map) {
    let self = this;
    self.map_global = map;
    this.getLevelMap();
    // this.http.get(this.mapLink).subscribe((response: any) => {
    //   self.geoJson = response;
    //   self.mapProperties = L.geoJSON(this.geoJson, {
    //     onEachFeature: this.onEachFeature.bind(this)
    //   }).addTo(self.map_global);
    // });
  }

  setMapZoomLevel() {
    this.map_global.options.minZoom = this.map_global.getZoom();
  }

  getColor(value) {
    if (value < 74.9) {
      return "#ff0000";
    } else if (value >= 75 && value <= 79.9) {
      return "#fac08f";
    } else if (value >= 80 && value <= 84.9) {
      return "#ffff00";
    } else if (value >= 85 && value <= 89.9) {
      return "#92d050";
    } else if (value >= 90) {
      return "#548235";
    } else {
      return "#d4d4d4";
    }
  }
  createMapStructure() {
    let map = this.map_global;
    map.eachLayer(function (layer) {
      map.removeLayer(layer);
    });

    let that = this;
    this.map_data_table = [];

    this.mapProperties = L.geoJSON(this.geoJson, {
      onEachFeature: this.onEachFeature.bind(this),
    }).addTo(this.map_global);

    this.mapProperties.setStyle(function (feature) {
      let area_code = feature.properties.code;
      let value = feature.properties.DATA_VALUE;
      let color = that.getColor(value);
      return {
        fillColor: color,
        color: color,
      };
    });
    $("#map .leaflet-control-container").css("display", "block");
    $("#map .leaflet-control-container").css("margin-left", "10px ");
    $("#map .leaflet-control-container").addClass("show-compass");
    $(".leaflet-control-container .leaflet-top.leaflet-right").html("");

    let north = new (L.Control.extend({
      options: { position: "topright" },
    }))();

    north.onAdd = function (m) {
      var div = L.DomUtil.create("div", "info legend");
      div.innerHTML =
        "<img id='map_north_symbol_show_remove_img' src='assets/lib/images/ns.png' style='width:40px; display:block;'>";
      return div;
    };
    north.addTo(this.map_global);
  }

  populateMapData(item) {
    let that = this;
    for (var j = 0; j < that.geoJson.features.length; j++) {
      let get_data_area = $.grep(item, function (ele) {
        return ele.area_code == that.geoJson.features[j].properties.code;
      });

      if (get_data_area.length > 0) {
        that.geoJson.features[j].properties.AREA_CODE =
          get_data_area[0].area_code;
        that.geoJson.features[j].properties.DATA_VALUE = get_data_area[0].value;
        //that.geoJson.features[j].properties.INDICATOR = get_data_area[0].indicator;
        //console.log(that.geoJson.features[j]);
        that.mapData1.push(that.geoJson.features[j]);
        that.dataArr.push(Number(get_data_area[0].value));
      }
    }
  }
}
