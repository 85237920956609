import { Component, OnInit, AfterViewInit } from "@angular/core";
import { HeaderService } from "../../services/header.service";
import { ApiService } from "../../services/api.service";
import { ActivatedRoute } from "@angular/router";
declare var $: any;

@Component({
  selector: "app-flipbook",
  templateUrl: "./flipbook.component.html",
  styleUrls: ["./flipbook.component.css"]
})
export class FlipbookComponent implements OnInit {
  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private activateRoute: ActivatedRoute
  ) {}

  flipBookReportData;
  elem: any;
  area_code;

  ngOnInit() {
    this.area_code = window.location.href.substr(window.location.href.lastIndexOf('/') + 1)
    this.headerService.setTitle("Flipbook");
    this.headerService.setLogo(
      "assets/lib/images/svg/header-menu/report-color.svg"
    );
  }

  // closeFullscreen() {
  //   this.elem = document;
  //   if (document.exitFullscreen) {
  //     document.exitFullscreen();
  //   } else if (this.elem.mozCancelFullScreen) {
  //     this.elem.mozCancelFullScreen();
  //   } else if (this.elem.webkitExitFullscreen) {
  //     this.elem.webkitExitFullscreen();
  //   } else if (this.elem.msExitFullscreen) {
  //     this.elem.msExitFullscreen();
  //   }
  // }

  // openFullscreen() {
  //   this.elem = document.documentElement;
  //   if (this.elem.requestFullscreen) {
  //     this.elem.requestFullscreen();
  //   } else if (this.elem.mozRequestFullScreen) { /* Firefox */
  //     this.elem.mozRequestFullScreen();
  //   } else if (this.elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
  //     this.elem.webkitRequestFullscreen();
  //   } else if (this.elem.msRequestFullscreen) { /* IE/Edge */
  //     this.elem.msRequestFullscreen();
  //   }
  // }

  openExitFullscreen(type) {
    if (type == 1) {
      $(".bbv").hide();
      $(".bbv1").show();
      $("header").hide();

      this.elem = document.documentElement;
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari & Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    } else if (type == 2) {
      $(".bbv").show();
      $(".bbv1").hide();
      $("header").show();
      this.elem = document;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (this.elem.mozCancelFullScreen) {
        this.elem.mozCancelFullScreen();
      } else if (this.elem.webkitExitFullscreen) {
        this.elem.webkitExitFullscreen();
      } else if (this.elem.msExitFullscreen) {
        this.elem.msExitFullscreen();
      }
    }
  }

  ngAfterViewInit() {
    this.dataService
      .getAll("api/report/flipbook/" + this.area_code)
      .subscribe((response: any) => {
        $(".viz-loader ol li").fadeIn();

      
        this.flipBookReportData = response.flipbook_images;

        if(response.status==0){
          $(".viz-loader ol li").fadeOut();
          $("main").html("<center><h2>"+response.error+"</h2></center>")
        }

        setTimeout(function() {
          /*
           * Turn.js responsive book
           */

          /*globals window, document, $*/

          (function() {
            "use strict";

            var module = {
              ratio: 1.38,
              init: function(id) {
                var me = this;

                // if older browser then don't run javascript
                if (document.addEventListener) {
                  this.el = document.getElementById(id);
                  this.resize();
                  this.plugins();

                  // on window resize, update the plugin size
                  window.addEventListener("resize", function(e) {
                    var size = me.resize();
                    $(me.el).turn("size", size.width, size.height);
                  });
                }
              },
              resize: function() {
                // reset the width and height to the css defaults
                this.el.style.width = "";
                this.el.style.height = "";

                var width = this.el.clientWidth,
                  height = Math.round(width / this.ratio),
                  padded = Math.round(window.outerHeight * 0.9);

                // if the height is too big for the window, constrain it
                if (height > padded) {
                  height = padded;
                  width = Math.round(height * this.ratio);
                }

                // set the width and height matching the aspect ratio
                this.el.style.width = width + "px";
                this.el.style.height = height + "px";

                return {
                  width: width,
                  height: height
                };
              },
              plugins: function() {
                // run the plugin
                $(this.el).turn({
                  gradients: true,
                  acceleration: true,
                  autocenter: true
                });
                // hide the body overflow
                document.body.className = "hide-overflow";
              }
            };

            module.init("magazine");
          })();
        }, 500);
        $(".loaders ol li").fadeOut();
      }, error => {
       console.log(error);
      },
      () => {
        // 'onCompleted' callback.
        // No errors, route to new page here
      });

    $(document).ready(function() {
      $("#prev").click(function(e) {
        e.preventDefault();
        $("#magazine").turn("previous");
      });

      $("#next").click(function(e) {
        e.preventDefault();
        $("#magazine").turn("next");
      });

      //     $("#magazine").turn({page: 1, acceleration: true, gradients: true});
      // $("#magazine").bind("zooming", function (event, newZoomValue, currentZoomValue) {
      //     alert("New zoom: " + currentZoomValue);
      // });

      // $("#magazine").turn("gradients");

      // Shows a peeling page at the bottom right corner
      // $("#magazine").turn("peel", "br");
    });
  }
}
