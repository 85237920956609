import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../services/api.service';
import { Component, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { HeaderService } from '../services/header.service';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DialogMaterialComponent } from '../dialog-material/dialog-material.component';
import { SelectionModel } from '@angular/cdk/collections';
import { TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions } from 'angular-tree-component';
import { SharedataService } from "../services/sharedata.service";
import { _ } from 'underscore';
import { NgModel } from '@angular/forms'
import { element } from '@angular/core/src/render3';
import { DatatableComponent } from '@swimlane/ngx-datatable';

declare var $: any;

@Component({
  selector: 'app-import-log',
  templateUrl: './import-log.component.html',
  styleUrls: ['./import-log.component.css']
})
export class ImportLogComponent implements OnInit {

  rows: any = [];
   public selectedItems: any = [];
  
   columns =[];
   page: any = {
     size: 0,
     totalElements: 0,
     totalPages: 0,
     pageNumber: 0,
     filterKeyWord: ''
   };


   selectedRows: any = [];
   filterData: any = [];

   dataSource: MatTableDataSource<any>;
   @ViewChild(MatPaginator) paginator: MatPaginator;
   @ViewChild(MatSort) sort: MatSort;
   @ViewChild(DatatableComponent) table: DatatableComponent;
   checkAllChild: boolean = false;

   constructor(private dataService: ApiService,
     private headerService: HeaderService,
     private formBuilder: FormBuilder,
     private toastr: ToastrService,
     public dialog: MatDialog,
     private route: ActivatedRoute,
     private shareData: SharedataService) {

     this.page.pageNumber = 0;
     this.page.size = 20;
   }

   ngOnInit() {
     // this.getAllData();
     this.headerService.setTitle('Log Management');
     this.headerService.setLogo('assets/lib/images/svg/sidebar/data-import.svg');
     this.setPage({ offset: 0 });
   }

   setPage(pageInfo) {
     this.rows = [];
     this.page.pageNumber = pageInfo.offset;
     const start = this.page.pageNumber * this.page.size;
     const end = Math.min((start + this.page.size), this.page.totalElements);
     this.page.startOffset = start;
     this.page.endOffset = end;
   
     this.dataService.add('api/datamanager/get-log-list', this.page).subscribe((response: any) => {
       this.page.totalElements = response.data.totalElements;
       this.page.totalPages = response.data.totalPages;
       this.rows = response.data.diet_data;
       this.filterData = [...response.data.diet_data];
       // if(response.data.diet_data.length > 0){
       //   response.data.diet_data.forEach(element => {
       //     this.rows.push({'area' : element.area.area_name, 'indicator' : element.ius_data_id.indicator, 'unit': element.ius_data_id.unit, 'subgroup': element.ius_data_id.subgroup, 'source': element.source, 'time_period' : element.time_period, 'value': element.value});
       //   });
       // }
       $(".loaders ol li").fadeOut();
       
     });
   }


   downloadExcel(row) {
     // console.log(row);return;
    
     this.dataService
       .getAll<any[]>('api/datamanager/download-log/' + row)
       .subscribe(
         (res: any) => {
         	if(res.status==0){
         		this.toastr.error(res.error);
         	}else{
         		if (res.file_download_path != '') {
         		  window.location.href = res.file_download_path;
         		}	
         	}
           
         });
   }
  
   deleteDataValue(data) {
     const dialog = this.dialog.open(DialogMaterialComponent, {
       width: '350px',
       data: { messageDialog: 'Are you sure you want to delete this record ?', delete: true }
     });
     dialog.afterClosed().subscribe(selection => {
       if (selection) {
         this.dataService.delete('api/datamanager/log/delete', data).subscribe((response: any) => {
           if (response.status == 1) {
             this.toastr.success('Record deleted successfully');
           } else {
             this.toastr.error(response.message);
           }
           this.setPage({ offset: 0 });
         });
       } else {
         // User clicked 'Cancel' or clicked outside the dialog
       }
     })

   }

   public confirmDeleteAll() {
    
    if(this.selectedItems.length <= 0) {
        return false;
    }
     
     const dialog = this.dialog.open(DialogMaterialComponent, {
       width: '350px',
       data: { messageDialog: 'Are you sure you want to delete all selected records', delete: true }
     });
     dialog.afterClosed()
       .subscribe(selection => {
         if (selection) {
           this.dataService.delete('api/datamanager/multiple/log/delete',this.selectedItems.join()).subscribe((response: any) => {
             if (response.status == 1) {
               this.toastr.success("Record deleted successfully")
             }
             this.selectedItems = [];
             setTimeout(() => {
               this.ngOnInit();
             }, 200);

           }, (error: any) => {
             console.log(error);
           });
         } else {
           // User clicked 'Cancel' or clicked outside the dialog
         }
       });
   }
   onSelect({ selected }) {
     this.selectedItems = [];
     this.selectedItems.splice(0, this.selectedItems.length);
     if(selected.length == 1){
       this.selectedItems.push(selected[0].id);
     }else if(selected.length > 1){
       for(let i=0; i<selected.length; i++){
         this.selectedItems.push(selected[i].id);
       }
     }
   }

   updateFilter() {
     this.page.filterKeyWord = $('#searchData').val();
     this.setPage({ offset: 0 });
     return;
     // const val = event.target.value.toLowerCase();

     // // filter our data
     // const temp = this.filterData.filter(function (d) {
     //   return Object.keys(d).some(function (key) {
     //     return d[key].toString().toLowerCase().indexOf(val) !== -1 || !val;
     //   });
     // });
     // // update the rows
     // this.rows = temp;
     // // Whenever the filter changes, always go back to the first page
     // this.table.offset = 0;
   }


}
