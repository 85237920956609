import {
  Component,
  OnInit,
  Input,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { HeaderService } from "../../services/header.service";
import { ApiService } from "../../services/api.service";
import { HttpClient } from "@angular/common/http";
import { SharedataService } from "../../services/sharedata.service";
import { CommonService } from "../../services/common.service";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { Configuration } from "../../app.constant";
import * as L from "leaflet";
import * as htmlToImage from "html-to-image";

import { _ } from "underscore";
import * as Highcharts from "highcharts";
import { DialogMaterialComponent } from "../../dialog-material/dialog-material.component";
import { VisualizationRendererService } from "../../services/visualization-renderer";
import { UseCaseService } from "../../services/use-case.service";
import { GetCaseColumnService } from "../../services/get-case-column.service";
import { GetCaseTableService } from "../../services/get-case-table.service";
import { GetCasePieService } from "../../services/get-case-pie.service";
import html2canvas from "html2canvas";
import { MatDialog } from "@angular/material";

declare var $: any;
var locations = {};
var rangeIusList = require("src/mapIusLegend.json");
@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.css"],
})
export class MapComponent implements OnInit {
  @Input("mapData") mapData: string;
  @ViewChildren("iusSelectList") iusSelectList: QueryList<any>;
  public out = [];
  geoJson;
  sourceOfMap: any;
  subSector;
  sourceData = [];
  map: L.Map;
  range = { minRange: "", maxRange: "" };
  splittedUnit: any = "";
  splittedIndicator: any = "";
  options = {
    zoom: 7,
    zoomSnap: 0.15,
    center: L.latLng(47.482019, -1),
  };
  graphData: any;
  selected = "";
  selectedst = "Stratifier";
  selectedtp = "";
  subscription: Subscription;
  data = [];
  areaID = "IND";
  iusID = [];
  indicator;
  unit;
  subgroup;
  mapLevel = 1;
  mapLink;
  mapRecentData = [];
  rangeArr = [];
  dataArr: any = [];
  setIusLegendlevel: any = [];
  public new = [];
  public newLevel: any = [];
  // mapLegendColor:any = [];
  mapLegendColor = [
    "#ff0000",
    "#ffc000",
    "#ffff00",
    "#92d050",
    "#548235",
    "#808080",
    "ff3333",
  ];
  v = ["#548235", "#92d050", "#ffff00", "#fac08f", "#ff0000"];
  noDataMapColor = "#4e5649";
  mapLegendRounded = 0;
  // mapLegendColor = ["ff3333","#ff1a1a","#ffbc00","#009a28","#2196f3","#2196f3"];
  map_global;
  mapRecentStatifier = [];
  mapRecentTimePeriod = [];
  mapCalculationType = ["Percent", "Number"];
  selectedCalculationType = "Percent";
  mapValueCalculationType = ["Cumulative FY", "Monthly"];
  selectedValueCalculationType = "Cumulative FY";
  selectedCategory = 'undefined';
  mapData1: any = [];
  iusDropDownAll = [];
  iusDropDown = [];
  map_data_table = [];
  labelOff: boolean = false;
  labelOn: boolean = true;
  mapProperties;
  dataTableHeader = "";
  dataTableHeaderSource = "";
  tempIusList = [];

  source = 1;
  rangeCount: any = {};
  private url: any;
  onPageLoad = 1;

  percentLegendFirstLoad = 1;
  checkFirstLoad = 1;
  changed_from_to_timeperiod = [];
  all_ius = [];
  all_indicator = [];
  all_unit = [];
  all_subgroup = [];
  all_area = [];
  all_time_period = [];
  all_seriesData = [];
  timePeriod: any = 1;
  areaForChart: any = [];
  nlength = 5;
  all_dataSource = [];
  dataSource = {};
  chartContainer = [];
  area_code;
  selectedChartType = "map";
  labelSwitchChartsOn: any = true;
  labelSwitchChartsOff: any = false;
  labelShow = false;
  legendSwitch = false;
  areaChildList: any = "";
  selectedFrom = "";
  selectedTo = "";
  allRangeLength;
  subSectorId = 1;
  ius_details_high_is_good = 0;
  dummyList = [];
  maplabelToggle: boolean = false;

  one: any = [];
  two: any = [];
  rows: any = [];
  mapcolor: any = [];
  getcol: any = [];
  IndiaData:any;
  Statedata: any;
  category =[];
  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private http: HttpClient,
    private shareData: SharedataService,
    private commonService: CommonService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private configuration: Configuration,
    public vizService: VisualizationRendererService,
    private useCase: UseCaseService,
    private columnUseCase: GetCaseColumnService,
    private pieUseCase: GetCasePieService,
    private tableUseCase: GetCaseTableService
  ) {
    this.url = configuration.server;
    // this.getTimeperiods();
    if (this.timePeriod == "mrd") {
      this.getTimeperiods();
    }
    // subscribe to home component messages
    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "sidebar":
          $(".loaders ol li").fadeIn();
          this.source = 1;
          this.subSector = message.data.data.level2;
          this.subSectorId = message.data.data.id;
          let iusTemp = this.commonService.extractIus(message.data.data);
          this.iusDropDownAll = iusTemp;
          let iusParent = [];
          try {
            for (let i = 0; i < iusTemp.length; i++) {
              if (iusTemp[i]["unit"] == "Percent") {
                if (iusTemp[i].id == iusTemp[i].parent_id) {
                  iusParent.push(iusTemp[i]);
                }
              }
            }
             iusParent = iusTemp;
            this.iusDropDown = iusParent;
            this.tempIusList = this.iusDropDown;
            this.iusID = this.iusDropDown[0].id;
            this.selected = this.iusDropDown[0].id;
            this.splittedIndicator = this.iusDropDown[0].indicator;
            this.splittedUnit = this.iusDropDown[0].unit;

            //first time page load set indicator given by client

            if (
              this.onPageLoad == 1 ||
              this.subSector.toLowerCase() == "reported coverage"
            ) {
              this.onPageLoad = 0;

              for (let i = 0; i < this.iusDropDown.length; i++) {
                let iusList =
                  this.iusDropDown[i]["indicator"] +
                  " | " +
                  this.iusDropDown[i]["unit"];

                if (
                  iusList ==
                  "Children aged 9-11 months fully immunization | Percent"
                ) {
                  this.iusID = this.iusDropDown[i].id;
                  this.selected = this.iusDropDown[i].id;
                  this.splittedIndicator = this.iusDropDown[i].indicator;
                  this.splittedUnit = this.iusDropDown[i].unit;
                }
              }
            }

            this.selectedtp = "mrd";
            this.mapLevel = Number(this.commonService.getAreaLevel());
            this.areaID = this.commonService.getAreaID();
            this.getChildArea(this.iusID, this.selectedtp, "ind");
            if (this.timePeriod == "mrd") {
              this.getTimeperiods();
            }
          } catch (e) {
            $(".loaders ol li").fadeOut();

            console.log(e);
          }

          break;
        case "header":
          this.source = 1;
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.map_global.options.minZoom = 0;
          this.getChildArea(this.iusID, this.selectedtp, "area");
          if (this.timePeriod == "mrd") {
            this.getTimeperiods();
          }
          break;
        default:
          break;
      }
    });
  }

  onMapReady(map: L.Map) {
    this.map_global = map;
    map.doubleClickZoom.disable();
  }

  getChildArea(iusId, selectedtp, type) {
    this.changed_from_to_timeperiod["from"] = "";
    this.changed_from_to_timeperiod["to"] = "";
    this.dataService
      .getAll("api/area-sublevel/" + this.areaID)
      .subscribe((response: any) => {
        $(".loaders ol li").fadeOut();
        this.data = response;

        let tempAreaChild = [];
        response.data.area.forEach((element) => {
          if (tempAreaChild.indexOf(element.id) == -1) {
            tempAreaChild.push(element.id);
          }
        });

        this.areaChildList = tempAreaChild.join(",");
        if (this.selectedChartType == "map") {
          this.getIusData(iusId, selectedtp, type);
        } else {
          this.change_chart_type(this.selectedChartType, selectedtp);
        }
      });
  }

  loadMap(mapdata) {
    let map = this.map_global;
    setTimeout(() => {
      map.invalidateSize();
    }, 0);
    // let that = this;
    // map.doubleClickZoom.disable();
    $(".leaflet-control-zoom").css("visibility", "hidden");
    if (
      this.changed_from_to_timeperiod["from"] != "" &&
      typeof this.changed_from_to_timeperiod["from"] != "undefined" &&
      this.changed_from_to_timeperiod["to"] != "" &&
      typeof this.changed_from_to_timeperiod["to"] != "undefined"
    ) {
      let pass_data2 = [];
      let that = this;
      let dataVal;
      let groupedData = _.groupBy(mapdata, function (d) {
        return d.area_id + "-" + d.ius_id;
      });
      _.each(groupedData, function ($row) {
        let tempData = [];
        let dataAddVal = 0;
        let denominator = 0;
        let numerator = 0;
        _.each($row, function ($val) {
          if ($val.unit == "Percent" || $val.unit == "percent") {
            denominator = denominator + Number($val.denominator);
            numerator = numerator + Number($val.numerator);
          } else if ($val.unit == "Number" || $val.unit == "number") {
            dataAddVal = dataAddVal + Number($val.value);
          }
        });
        if (dataAddVal > 0) {
          dataVal = dataAddVal;
        } else if (denominator > 0 && numerator > 0) {
          dataVal = (numerator / denominator) * 100;
          dataVal = dataVal.toFixed(2);
        } else {
          dataVal = "";
        }
        pass_data2.push({
          area_code: $row[0].area_code,
          area_id: $row[0].area_id,
          area_name: $row[0].area_name,
          id: $row[0].id,
          indicator: $row[0].indicator,
          ius_id: $row[0].ius_id,
          source: $row[0].source,
          subgroup: $row[0].subgroup,
          unit: $row[0].unit,
          ius: $row[0].indicator + "|" + $row[0].unit + "|" + $row[0].subgroup,
          time_period:
            that.changed_from_to_timeperiod["from"] +
            "-" +
            that.changed_from_to_timeperiod["to"],
          numerator: numerator,
          denominator: denominator,
          value: dataVal,
        });
      });
      mapdata = pass_data2;

      this.mapRecentData = pass_data2;
    }
    this.getLevelMap();
    this.http.get(this.mapLink).subscribe((response: any) => {
      this.geoJson = this.extractGeoJson(response);
      if (this.mapLevel != 1) {
        this.geoJson = { type: "FeatureCollection", features: this.geoJson };
      }
      this.populateMapData(mapdata);
      this.createMapStructure();
    });
  }

  setMapZoomLevel() {
    this.map_global.options.minZoom = this.map_global.getZoom();
  }

  createMapStructure() {
    let that = this;
    let map = this.map_global;
    map.eachLayer(function (layer) {
      map.removeLayer(layer);
    });

    // that.map_data_table = [];

    this.mapProperties = L.geoJSON(this.geoJson, {
      onEachFeature: this.onEachFeature.bind(this),
    }).addTo(this.map_global);

    this.map_global.fitBounds(this.mapProperties.getBounds());
    setTimeout(function () {
      that.setMapZoomLevel();
    }, 1000);

    this.mapProperties.setStyle(function (feature) {
      let area_code = feature.properties.code;
      let value;
      if (typeof that.mapRecentData != "undefined") {
        let area_value = that.mapRecentData.filter(function (element) {
          return element.area_code == area_code;
        });
        if (area_value.length > 0) {
          value = area_value[0].value;
          if (that.selectedCalculationType == "Number") {
            value = area_value[0].numerator;
          }
        } else {
          value = null;
        }
        if (isNaN(value) == true || value == null) {
          return {
            fillColor: "#d4d4d4",
            color: "black",
          };
        }
        if (value === 0) {
          let index = 0;
          return {
            fillColor: that.mapLegendColor[index],
            color: that.mapLegendColor[index],
          };
        } else if (value != "") {
          let index = that.getAreaColor(value);
          return {
            fillColor: that.mapLegendColor[index],
            color: that.mapLegendColor[index],
          };
        } else {
          return {
            fillColor: "#d4d4d4",
            color: "black",
          };
        }
      } else {
        return {
          fillColor: "#d4d4d4",
          color: "black",
        };
      }
    });
    $("#dashboard-map .leaflet-control-container").css("display", "block");
    $("#dashboard-map .leaflet-control-container").addClass("show-compass");
    $(".leaflet-control-container .leaflet-top.leaflet-right").html("");

    let north = new (L.Control.extend({
      options: { position: "topright" },
    }))();

    north.onAdd = function (m) {
      var div = L.DomUtil.create("div", "info legend");
      div.innerHTML =
        "<img id='map_north_symbol_show_remove_img' src='assets/lib/images/ns.png' style='width:30px; display:block;'>";
      return div;
    };
    north.addTo(this.map_global);
  }

  onEachFeature(feature, layer) {
    let that = this;
    locations[feature.properties.code] = {
      lat: layer.getBounds().getCenter().lat,
      lng: layer.getBounds().getCenter().lng,
      properties: feature.properties,
    };

    var out = [];
    var label = [];
    out.push("Area Name : " + feature.properties.name);
    if (feature.properties.DATA_VALUE != undefined) {
      out.push("Data Value : " + feature.properties.DATA_VALUE);
      out.push(
        "Time Period : " + that.getFormatedTimeperiod(feature.properties.YEAR)
      );
      if (that.selectedCalculationType != "Number") {
        if (feature.properties.DENOMINATOR != null) {
          out.push("Coverage : " + feature.properties.NUMERATOR);
          out.push("Target : " + feature.properties.DENOMINATOR);
        }
      }
      //  that.map_data_table.push({ 'area_name': feature.properties.name, 'data_value': feature.properties.DATA_VALUE, 'year': feature.properties.YEAR, 'coverage': feature.properties.NUMERATOR, 'target': feature.properties.DENOMINATOR });
    }
    //   that.map_data_table = that.map_data_table.sort(function (a, b) {
    //     return a.area_name.localeCompare(b.area_name);
    //   });

    if (feature.properties) {
      if (that.labelOn) {
        label.push(
          '<span class="map_label_area_name_class" style="visibility:hidden;">' +
            feature.properties.name +
            '<br /></span> <span class="map_label_data_value_class" style="visibility:hidden;">' +
            (typeof feature.properties.DATA_VALUE != "undefined" &&
            feature.properties.DATA_VALUE != null
              ? feature.properties.DATA_VALUE
              : "") +
            "</span>"
        );
      } else {
        label.push(
          '<span class="map_label_area_name_class" style="visibility:visible;">' +
            feature.properties.name +
            '<br /></span> <span class="map_label_data_value_class" style="visibility:visible;">' +
            (typeof feature.properties.DATA_VALUE != "undefined" &&
            feature.properties.DATA_VALUE != null
              ? feature.properties.DATA_VALUE
              : "") +
            "</span>"
        );
      }
      layer.bindPopup(out.join("<br />"));
    }
    layer
      .bindTooltip(label.join("<br />"), {
        direction: "center",
        permanent: true,
        className: "map_label_class",
      })
      .addTo(that.map_global);

    layer.on("dblclick", function (e) {
      that.areaID = feature.properties.code;
      that.mapLevel = that.mapLevel + 1;
      that.map_global.options.minZoom = 0;
      localStorage.setItem("areaCode", feature.properties.code);
      localStorage.setItem("areaLevel", that.mapLevel + "");
      localStorage.setItem("areaName", feature.properties.name);

      var data = {
        code: feature.properties.code,
        level: that.mapLevel,
        name: feature.properties.name,
      };
      if (that.mapLevel > 2) {
        $(".loaders ol li").fadeOut();
        that.selectedCalculationType = "Number";
        that.change_chart_type("column", this.timePeriod);
        return;
      }
      that.shareData.sendData({ ctype: "header", data: data, isDashboard: 0 });
    });

    $(".loaders ol li").fadeOut();
  }
  // indicators dropdown api
  getAllIndicators() {
    this.dataService
      .getAll("api/gallery/getsetindicator")
      .subscribe((response: any) => {
        this.data = response.data;
      });
  }

  ngOnInit() {
    // var jsonObject = $.getJSON('assets/mapIusLegend.json');
    /*this.http.get('./assets/mapIusLegend.json').subscribe(
          data => {
            this.rangeIusList = data as string [];   // FILL THE ARRAY WITH DATA.
            //  console.log(this.arrBirds[1]);
          }
        );*/
    $(".loaders ol li").fadeIn();
    this.changed_from_to_timeperiod["from"] = "";
    this.changed_from_to_timeperiod["to"] = "";
    this.headerService.setTitle("Data visualization / Map View");
    this.headerService.setLogo(
      "assets/lib/images/svg/header-menu/area-dashboard.svg"
    );
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.getAllIndicators();
  }

  ngAfterViewInit() {
    this.iusSelectList.changes.subscribe((t) => {
      this.ngForRendred();
    });

    // multiselect
    $(document).ready(function () {
      $(".time-period").multiselect({
        enableCollapsibleOptGroups: true,
        collapseOptGroupsByDefault: true,
        maxHeight: 200,
        nonSelectedText: "Time Period",
      });
      $(".subgroup").multiselect({
        enableCollapsibleOptGroups: true,
        collapseOptGroupsByDefault: true,
        maxHeight: 200,
        nonSelectedText: "Statifier",
      });
    }, 1000);
  }

  getLevelMap() {
    switch (this.mapLevel) {
      case 1:
        this.mapLink = "assets/lib/map/IND_l02_2011_Census.json";
        break;

      case 2:
        // this.mapLink = "assets/lib/map/india-district.json";
        this.mapLink = "assets/lib/map/newJson_India-district_2023.json";
        break;

      case 3:
     
        this.mapLink = "assets/lib/map/IND_l04_2020.json"; //load block level map
        break;

      default:
        this.mapLink = "assets/lib/map/IND_l02_2011_Census.json";
        break;
    }
  }

  extractGeoJson(geojson) {
    //geojson extraction not required for level 1 as it loads all area in level2 map
    if (this.mapLevel == 1) return geojson;
    let extractedJson = [];
    for (var i = 0; i < geojson.features.length; i++) {
      if (geojson.features[i].properties.code.indexOf(this.areaID) !== -1) {
        extractedJson.push(geojson.features[i]);
      }
    }
    return extractedJson;
  }

  ZoomMap(coords) {
    // this.map_global.doubleClickZoom.disable();
    this.map_global.setView([coords.lat, coords.lng], 5);
  }

  changeIus(event, type) {
    let splitVal = event.source.triggerValue.split("|");
    this.splittedIndicator = $.trim(splitVal[0]);
    this.splittedUnit = $.trim(splitVal[1]);
    this.iusID = event.value;
    this.changed_from_to_timeperiod["from"] = "";
    this.changed_from_to_timeperiod["to"] = "";
    this.selectedFrom = "";
    this.selectedTo = "";
    this.source = 1;
    // this.selectedtp = "mrd";

    if (this.subSectorId == 4) {
      this.selectedValueCalculationType = "Monthly";
    }

    this.timePeriod = 1;
    if (this.selectedChartType == "map") {
      this.getIusData(event.value, this.selectedtp, type);
    } else {
      this.change_chart_type(this.selectedChartType, this.selectedtp);
    }
  }

  changeStratifier(event, type) {
    this.iusID = event.value;
    this.source = 1;
    if (this.selectedChartType == "map") {
      this.getIusData(event.value, this.selectedtp, type);
    } else {
      this.change_chart_type(this.selectedChartType, this.selectedtp);
    }
  }

  changeTimePeriod(event, type) {
    this.source = 1;
    this.selectedtp = event.value;
    this.changed_from_to_timeperiod["from"] = "";
    this.changed_from_to_timeperiod["to"] = "";
    this.selectedFrom = "";
    this.selectedTo = "";
    if (this.selectedChartType == "map") {
      this.getIusData(this.iusID, event.value, type);
    } else {
      this.change_chart_type(this.selectedChartType, event.value);
    }
  }

  changeCalculationType(event, type) {
    // ['percent','number']
    this.source = 1;
    this.selectedCalculationType = event.value;
    // put filter logic
    let iusTemp = this.iusDropDownAll;
    let iusParent = [];

    if (event.value === "Number") {
      for (let i = 0; i < iusTemp.length; i++) {
        iusTemp[i]["level"] = event.value;
        if (iusTemp[i].id == iusTemp[i].parent_id) {
          iusParent.push(iusTemp[i]);
        }
      }
    } else {
      for (let i = 0; i < iusTemp.length; i++) {
        if (iusTemp[i]["unit"] == "Percent") {
          iusTemp[i]["level"] = event.value;
          if (iusTemp[i].id == iusTemp[i].parent_id) {
            iusParent.push(iusTemp[i]);
          }
        }
      }
    }

    this.iusDropDown = iusParent;
    this.tempIusList = this.iusDropDown;

    this.loadMap(this.mapRecentData);
    this.loadData(this.selectedtp);
  }

  changeValueCalculationType(event, type) {
    //['Cumulative','Monthly']
    this.selectedValueCalculationType = event.value;
    if (this.selectedChartType == "map") {
      this.getIusData(this.iusID, this.timePeriod, type);
    } else {
      this.change_chart_type(this.selectedChartType, this.timePeriod);
    }
  }

  changeCategory(event, type) {
    this.source = 1;
    this.selectedCategory = event.value;
    if (this.selectedChartType == "map") {
      this.getIusData(this.iusID, this.timePeriod, type);
    } else {
      this.change_chart_type(this.selectedChartType, this.timePeriod);
    }
  }

  getIusData(iusID, timePeriod, type) {
    if (timePeriod == "mrd") {
      this.getTimeperiods();
    }

    if (this.mapLevel > 2) {
      $(".loaders ol li").fadeOut();
      this.selectedCalculationType = "Number";
      this.mapCalculationType = ["Number"];
      this.change_chart_type("column", timePeriod);
      return;
    }
    if (this.splittedUnit == "Number" || this.splittedUnit == "Number") {
      this.selectedCalculationType = "Number";
    } else {
      this.selectedCalculationType = "Percent";
    }
    if (this.subSectorId == 4) {
      this.selectedValueCalculationType = "Monthly";
    }

    if (this.subSectorId == 3) {
      this.selectedCalculationType = "Percent";
      this.mapCalculationType = ["Percent"];
    } else {
      this.mapCalculationType = ["Percent", "Number"];
    }

    $(".loaders ol li").fadeIn();
    this.dataArr = [];

    if (
      this.changed_from_to_timeperiod["from"] != "" &&
      typeof this.changed_from_to_timeperiod["from"] != "undefined" &&
      this.changed_from_to_timeperiod["to"] != "" &&
      typeof this.changed_from_to_timeperiod["to"] != "undefined"
    ) {
      this.timePeriod = 0;
      timePeriod = 0;
    } else {
      this.changed_from_to_timeperiod["from"] = undefined;
      this.changed_from_to_timeperiod["to"] = undefined;
    }

    this.dataService
      .getAll(
        "api/get-map-data/" +
          iusID +
          "/" +
          timePeriod +
          "/" +
          this.areaID +
          "/" +
          this.changed_from_to_timeperiod["from"] +
          "/" +
          this.changed_from_to_timeperiod["to"] +
          "/" +
          this.subSectorId +
          "/" +
          this.selectedValueCalculationType + "/" + this.selectedCategory

      )
      .subscribe((response: any) => {
        this.mapRecentData = response.data;
     
        this.Statedata = response.Statedata[0];     
        this.IndiaData = response.Indiadata[0];
        this.sourceData = [];
        this.sourceOfMap = "";
        this.ius_details_high_is_good = response.ius_details.high_is_good;

        this.category = response.category;
        this.mapRecentData.forEach((element) => {
          if (this.sourceData.indexOf(element.source) == -1) {
            this.sourceData.push(element.source);
            this.sourceOfMap = this.sourceData.join("");
          }
        });
        if (response.error != undefined) {
          this.toastr.error("No data available.");
          $(".loaders ol li").fadeOut();
          return;
        }

        if (
          (typeof response.statifier == "undefined" || response.statifier == "") &&  type == "ind") {
          // this.toastr.error("No data available.");
          this.mapRecentStatifier = [];
          this.mapRecentTimePeriod = [];
          this.loadMap([]);
          // this.loadData();
          $(".loaders ol li").fadeOut();
          // return;
        }

        this.dataTableHeaderSource = this.sourceOfMap;
        if (type == "ind" || type == "area") {
          try {
            this.dataTableHeader =
              response.statifier[0].indicator +
              " | " +
              response.statifier[0].unit +
              " | " +
              response.statifier[0].subgroup;
            this.mapRecentStatifier = response.statifier;
            this.mapRecentTimePeriod = response.time_period;
          } catch (e) {}
        }

        if (
          this.ius_details_high_is_good == 0 &&
          (type == "ind" || type == "stratifier")
        ) {
          this.mapLegendColor.reverse();
        }
        this.selectedst = iusID;
        this.mapData1 = [];
        this.map_data_table = [];
        this.percentLegendFirstLoad = 1;
        this.loadMap(response.data);
      });
  }

  populateMapData(mapData) {
    let that = this;
    that.map_data_table = [];
    mapData.forEach((row) => {
      if (row.value != undefined) {
        that.map_data_table.push({
          area_name: row.area_name,
          data_value: row.value,
          year: row.time_period,
          coverage: row.numerator,
          target: row.denominator,
        });
      }
    });
    that.map_data_table = that.map_data_table.sort(function (a, b) {
      return a.area_name.localeCompare(b.area_name);
    });
    for (var j = 0; j < this.geoJson.features.length; j++) {
      let get_data_area = $.grep(mapData, function (ele) {
        return ele.area_code == that.geoJson.features[j].properties.code;
      });
      if (get_data_area.length > 0) {
        that.geoJson.features[j].properties.AREA_CODE =
          get_data_area[0].area_code;
        that.geoJson.features[j].properties.YEAR = get_data_area[0].time_period;
        that.geoJson.features[j].properties.INDICATOR =
          get_data_area[0].indicator;
        if (that.selectedCalculationType == "Number") {
          that.geoJson.features[j].properties.DATA_VALUE =
            get_data_area[0].numerator;
        } else {
          that.geoJson.features[j].properties.DATA_VALUE =
            get_data_area[0].value;
          that.geoJson.features[j].properties.NUMERATOR =
            get_data_area[0].numerator;
          that.geoJson.features[j].properties.DENOMINATOR =
            get_data_area[0].denominator;
        }
        that.mapData1.push(that.geoJson.features[j]);

        if (that.selectedCalculationType == "Number") {
          that.dataArr.push(Number(get_data_area[0].numerator));
        } else {
          that.dataArr.push(Number(get_data_area[0].value));
        }
      } else {
        that.geoJson.features[j].properties.DATA_VALUE = null;
        that.mapData1.push(that.geoJson.features[j]);
      }
    }

    if (mapData.length <= 0) {
      $(".map_legend_div ul").empty();
      // this.mapRecentTimePeriod = [];
      this.toastr.error("No data available.");
      $(".loaders ol li").fadeOut();
      that.map_global.closePopup();
      $(".map_legend_div ul").html("");
      that.map_global.closePopup();
      this.equalSizeBreak(this.dataArr);
      return;
    }
    this.dataArr.sort(function (a, b) {
      return a - b;
    });

    if (this.source == 1) {
      this.equalSizeBreak(this.dataArr);
    }
  }

  round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  getRoundedLagendLavel(value) {
    if (this.mapLegendRounded == 1) {
      if (value > 1000) {
        return this.round(value / 1000, 2) + "k";
      }
    }

    return this.round(value, 2);
  }

  equalSizeBreak(data_arr) {
    this.nlength = 5;
    let that = this;
    let rcvCurrentIusLegend = [];
    let setIusLegend = [];
    $.each(rangeIusList, function (k, v) {
      if (that.splittedIndicator == Object.keys(v)[0]) {
        that.nlength = Object.keys(rangeIusList[k][Object.keys(v)[0]]).length;
        rcvCurrentIusLegend = v[Object.keys(v)[0]];
      }
    });

    this.v = ["#548235", "#92d050", "#ffff00", "#fac08f", "#ff0000"];

    this.new;
    // rcvCurrentIusLegend.length =0;
    this.mapLegendColor = this.v;
    this.allRangeLength = that.nlength;
    console.log("old", this.mapLegendColor);

    if (that.nlength == 6) {
      setIusLegend["r1_max"] = Number(rcvCurrentIusLegend[0].max);
      setIusLegend["r1_label"] = rcvCurrentIusLegend[0].label;

      setIusLegend["r2_min"] = Number(rcvCurrentIusLegend[1].min);
      setIusLegend["r2_max"] = Number(rcvCurrentIusLegend[1].max);
      setIusLegend["r2_label"] = rcvCurrentIusLegend[1].label;

      setIusLegend["r3_min"] = Number(rcvCurrentIusLegend[2].min);
      setIusLegend["r3_max"] = Number(rcvCurrentIusLegend[2].max);
      setIusLegend["r3_label"] = rcvCurrentIusLegend[2].label;

      setIusLegend["r4_min"] = Number(rcvCurrentIusLegend[3].min);
      setIusLegend["r4_max"] = Number(rcvCurrentIusLegend[3].max);
      setIusLegend["r4_label"] = rcvCurrentIusLegend[3].label;

      setIusLegend["r5_min"] = Number(rcvCurrentIusLegend[4].min);
      setIusLegend["r5_max"] = Number(rcvCurrentIusLegend[4].max);
      setIusLegend["r5_label"] = rcvCurrentIusLegend[4].label;

      setIusLegend["r6_min"] = Number(rcvCurrentIusLegend[5].min);
      setIusLegend["r6_label"] = rcvCurrentIusLegend[5].label;

      this.dummyList = setIusLegend;

      this.mapLegendColor = [
        "#1f497d",
        "#548235",
        "#92d050",
        "#ffff00",
        "#fac08f",
        "#ff0000",
      ];

      if (this.ius_details_high_is_good == 0) {
        this.mapLegendColor.reverse();
      }
    } else if (that.nlength == 5 && rcvCurrentIusLegend.length != 0) {
      setIusLegend["r1_max"] = Number(rcvCurrentIusLegend[0].max);
      setIusLegend["r1_label"] = rcvCurrentIusLegend[0].label;

      setIusLegend["r2_min"] = Number(rcvCurrentIusLegend[1].min);
      setIusLegend["r2_max"] = Number(rcvCurrentIusLegend[1].max);
      setIusLegend["r2_label"] = rcvCurrentIusLegend[1].label;

      setIusLegend["r3_min"] = Number(rcvCurrentIusLegend[2].min);
      setIusLegend["r3_max"] = Number(rcvCurrentIusLegend[2].max);
      setIusLegend["r3_label"] = rcvCurrentIusLegend[2].label;

      setIusLegend["r4_min"] = Number(rcvCurrentIusLegend[3].min);
      setIusLegend["r4_max"] = Number(rcvCurrentIusLegend[3].max);
      setIusLegend["r4_label"] = rcvCurrentIusLegend[3].label;

      setIusLegend["r5_min"] = Number(rcvCurrentIusLegend[4].min);
      setIusLegend["r5_label"] = rcvCurrentIusLegend[4].label;

      this.dummyList = setIusLegend;

      this.mapLegendColor = [
        "#1f497d",
        "#548235",
        "#92d050",
        "#ffff00",
        "#fac08f",
        "#ff0000",
      ];

      if (
        rcvCurrentIusLegend[4].max == "reverse" ||
        this.ius_details_high_is_good == 0
      ) {
        this.mapLegendColor.reverse();
      }
    } else {
      setIusLegend["r1_max"] = 74.99;
      setIusLegend["r1_label"] = "< 75";
      this.setIusLegendlevel[0] = "< 75";
      setIusLegend["r2_min"] = 75;
      setIusLegend["r2_max"] = 79.99;
      setIusLegend["r2_label"] = "75-80";

      this.setIusLegendlevel[1] = "75-80";

      setIusLegend["r3_min"] = 80;
      setIusLegend["r3_max"] = 84.99;
      setIusLegend["r3_label"] = "80-85";

      this.setIusLegendlevel[2] = "80-85";

      setIusLegend["r4_min"] = 85;
      setIusLegend["r4_max"] = 89.99;
      setIusLegend["r4_label"] = "85-90";

      this.setIusLegendlevel[3] = "85-90";

      setIusLegend["r5_min"] = 90;
      setIusLegend["r5_label"] = "> 90";

      this.setIusLegendlevel[4] = "> 90";
      console.log(setIusLegend);
      this.dummyList = setIusLegend;

      if (this.ius_details_high_is_good == 0) {
        this.mapLegendColor.reverse();
      }
    }

    let n = that.nlength;
    let min = Math.min.apply(Math, data_arr);
    let max = Math.max.apply(Math, data_arr);

    if (that.selectedCalculationType == "Number") {
      var temp = max / 5;
      var value = temp;
      setIusLegend["r1_max"] = value;
      setIusLegend["r1_label"] = "< " + that.getRoundedLagendLavel(value);
      value = temp + 1;
      var max_value = value + temp;

      setIusLegend["r2_min"] = value;
      setIusLegend["r2_max"] = max_value;
      setIusLegend["r2_label"] =
        that.getRoundedLagendLavel(value) +
        "-" +
        that.getRoundedLagendLavel(max_value);

      value = max_value + 1;
      var max_value = value + temp;

      setIusLegend["r3_min"] = value;
      setIusLegend["r3_max"] = max_value;
      setIusLegend["r3_label"] =
        that.getRoundedLagendLavel(value) +
        "-" +
        that.getRoundedLagendLavel(max_value);

      value = max_value + 1;
      var max_value = value + temp;

      setIusLegend["r4_min"] = value;
      setIusLegend["r4_max"] = max_value;
      setIusLegend["r4_label"] =
        that.getRoundedLagendLavel(value) +
        "-" +
        that.getRoundedLagendLavel(max_value);

      value = max_value + 1;

      setIusLegend["r5_min"] = value;
      setIusLegend["r5_label"] = "> " + that.getRoundedLagendLavel(value);
    }

    if (that.subSectorId == 3 || that.subSectorId == 6) {

      that.nlength = 3;

      this.allRangeLength = that.nlength;

      this.mapLegendColor = ["#00e040", "#fe8d02", "#f50102"];

      setIusLegend["r1_max"] = 2.99;
      setIusLegend["r1_label"] = "< 3";
      this.setIusLegendlevel[0] = "< 3";

      setIusLegend["r2_min"] = 3;
      setIusLegend["r2_max"] = 5;
      setIusLegend["r2_label"] = "3-5";
      this.setIusLegendlevel[1] = "3-5";

      setIusLegend["r3_min"] = 5;
      setIusLegend["r3_label"] = "> 5";
      this.setIusLegendlevel[2] = "> 5";
    }

    this.rangeArr = [];
    $("#map_legend_max_value").val(max);
    $(".map_legend_div ul").html("");
    $(".map_legend_div ul").append("<li>" + this.splittedUnit + "</li>");
    if (this.subSectorId == 3 || this.subSectorId == 6) {
      if (min < 0) {
        $("#map_legend_min_value").val(min);
        this.rangeArr.push([min, setIusLegend["r1_max"]]);
        this.getCountInRange(this.dataArr, min, setIusLegend["r1_max"]);
        $(".map_legend_div ul").append(
          "<li class='mapLegend'><span class='legend_box' style='background-color:" +
            this.mapLegendColor[0] +
            "'></span>" +
            setIusLegend["r1_label"] +
            " </li>"
        );
      } else {
        $("#map_legend_min_value").val(0);
        this.rangeArr.push([0, setIusLegend["r1_max"]]);
        this.getCountInRange(this.dataArr, 0, setIusLegend["r1_max"]);
        $(".map_legend_div ul").append(
          "<li class='mapLegend'><span class='legend_box' style='background-color:" +
            this.mapLegendColor[0] +
            "'></span>" +
            setIusLegend["r1_label"] +
            " </li>"
        );
      }
      this.rangeArr.push([setIusLegend["r2_min"], setIusLegend["r2_max"]]);
      this.getCountInRange(
        this.dataArr,
        setIusLegend["r2_min"],
        setIusLegend["r2_max"]
      );
      $(".map_legend_div ul").append(
        "<li class='mapLegend'><span class='legend_box' style='background-color:" +
          this.mapLegendColor[1] +
          "'></span>" +
          setIusLegend["r2_label"] +
          "</li>"
      );

      this.rangeArr.push([setIusLegend["r3_min"], max]);
      this.getCountInRange(this.dataArr, setIusLegend["r3_min"], max);

      $(".map_legend_div ul").append(
        "<li class='mapLegend'><span class='legend_box'  style='background-color:" +
          this.mapLegendColor[2] +
          "'></span> " +
          setIusLegend["r3_label"] +
          " </li>"
      );
      $(".map_legend_div ul").append(
        "<li class='mapLegend'><span class='legend_box' style='background-color:#d4d4d4'></span>Missing Values</li>"
      );
      return;
    } else if (this.percentLegendFirstLoad == 1 &&
      (this.splittedUnit == "percent" || this.splittedUnit == "Percent")
    ) {
      if (min < 0) {
        $("#map_legend_min_value").val(min);
        this.rangeArr.push([min, setIusLegend["r1_max"]]);
        this.getCountInRange(this.dataArr, min, setIusLegend["r1_max"]);
        $(".map_legend_div ul").append(
          "<li class='mapLegend'><span class='legend_box' style='background-color:" +
            this.mapLegendColor[0] +
            "'></span>" +
            setIusLegend["r1_label"] +
            " </li>"
        );
      } else {
        $("#map_legend_min_value").val(0);
        this.rangeArr.push([0, setIusLegend["r1_max"]]);
        this.getCountInRange(this.dataArr, 0, setIusLegend["r1_max"]);
        $(".map_legend_div ul").append(
          "<li class='mapLegend'><span class='legend_box' style='background-color:" +
            this.mapLegendColor[0] +
            "'></span>" +
            setIusLegend["r1_label"] +
            " </li>"
        );
      }

      this.rangeArr.push([setIusLegend["r2_min"], setIusLegend["r2_max"]]);
      this.getCountInRange(
        this.dataArr,
        setIusLegend["r2_min"],
        setIusLegend["r2_max"]
      );
      $(".map_legend_div ul").append(
        "<li class='mapLegend'><span class='legend_box' style='background-color:" +
          this.mapLegendColor[1] +
          "'></span>" +
          setIusLegend["r2_label"] +
          "</li>"
      );

      this.rangeArr.push([setIusLegend["r3_min"], setIusLegend["r3_max"]]);
      this.getCountInRange(
        this.dataArr,
        setIusLegend["r3_min"],
        setIusLegend["r3_max"]
      );
      $(".map_legend_div ul").append(
        "<li class='mapLegend'><span class='legend_box' style='background-color:" +
          this.mapLegendColor[2] +
          "'></span>" +
          setIusLegend["r3_label"] +
          "</li>"
      );

      this.rangeArr.push([setIusLegend["r4_min"], setIusLegend["r4_max"]]);
      this.getCountInRange(
        this.dataArr,
        setIusLegend["r4_min"],
        setIusLegend["r4_max"]
      );
      $(".map_legend_div ul").append(
        "<li class='mapLegend'><span class='legend_box' style='background-color:" +
          this.mapLegendColor[3] +
          "'></span>" +
          setIusLegend["r4_label"] +
          "</li>"
      );

      if (that.nlength == 6) {
        this.rangeArr.push([setIusLegend["r5_min"], setIusLegend["r5_max"]]);
        this.getCountInRange(
          this.dataArr,
          setIusLegend["r5_min"],
          setIusLegend["r5_max"]
        );
        $(".map_legend_div ul").append(
          "<li class='mapLegend'><span class='legend_box' style='background-color:" +
            this.mapLegendColor[4] +
            "'></span>" +
            setIusLegend["r5_label"] +
            "</li>"
        );

        this.rangeArr.push([setIusLegend["r6_min"], max]);
        this.getCountInRange(this.dataArr, setIusLegend["r6_min"], max);
        $(".map_legend_div ul").append(
          "<li class='mapLegend'><span class='legend_box'  style='background-color:" +
            this.mapLegendColor[5] +
            "'></span> " +
            setIusLegend["r6_label"] +
            " </li>"
        );
      }
      if (that.nlength == 5) {
        this.rangeArr.push([setIusLegend["r5_min"], max]);
        this.getCountInRange(this.dataArr, setIusLegend["r5_min"], max);
        $(".map_legend_div ul").append(
          "<li class='mapLegend'><span class='legend_box'  style='background-color:" +
            this.mapLegendColor[4] +
            "'></span> " +
            setIusLegend["r5_label"] +
            " </li>"
        );
      }

      //   $(".map_legend_div ul").append(
      //    "<li class='mapLegend'><span class='legend_box' style='background-color:#d4d4d4'></span>Missing Values</li>"
      //  );
    } else {
      $("#map_legend_min_value").val(min);
      if (min == max) {
        for (let counter = 0; counter < n; counter++) {
          let val1 = min;
          let val2 = max;
          this.rangeArr.push([val1, val2]);
        }
      } else {
        if (min == max) {
          min = 0;
        }

        let dist = ((max - min) / n).toFixed(2);
        let dist_number = Number(dist);
        let inc = 0;
        if ((max - min) % n != 0) {
          inc = 1;
        }
        let lowLimit;
        let highLimit;

        lowLimit = +min.toFixed(2);
        highLimit = +max.toFixed(2);
        let difference = (highLimit - lowLimit) / that.nlength;
        n = that.nlength;

        let value = lowLimit;
        let arr = [];
        let counter = 0;

        while (value < highLimit) {
          if (counter == that.nlength - 1) {
            value = max;
          } else {
            value += difference;
          }
          value = +value.toFixed(2);
          let arr = [lowLimit, value];
          if (this.splittedUnit == "number" || this.splittedUnit == "Number") {
            $(".map_legend_div ul").append(
              "<li class='mapLegend'><span class='legend_box' style='background-color:" +
                this.mapLegendColor[counter] +
                "'></span>" +
                Math.floor(Number(lowLimit)) +
                " - " +
                Math.floor(Number(value)) +
                "</li>"
            );
            this.rangeArr.push([
              Math.floor(Number(lowLimit)),
              Math.floor(Number(value)),
            ]);
            this.getCountInRange(
              this.dataArr,
              Math.floor(Number(lowLimit)),
              Math.floor(Number(value))
            );
          } else {
            $(".map_legend_div ul").append(
              "<li class='mapLegend'><span class='legend_box' style='background-color:" +
                this.mapLegendColor[counter] +
                "'></span>" +
                Number(lowLimit).toFixed(2) +
                " - " +
                Number(value).toFixed(2) +
                "</li>"
            );
            this.rangeArr.push([lowLimit.toFixed(2), value.toFixed(2)]);
            this.getCountInRange(
              this.dataArr,
              lowLimit.toFixed(2),
              value.toFixed(2)
            );
          }
          counter++;
          lowLimit = value + 0.01;
        }
        $(".map_legend_div ul").append(
          "<li class='mapLegend'><span class='legend_box' style='background-color:#d4d4d4'></span>Missing Values</li>"
        );
      }
    }
  }

  getAreaColor(dataValue) {
    let n = Number(this.allRangeLength);
    for (let i = 0; i < n; i++) {
      if (
        Number(dataValue) >= Number(this.rangeArr[i][0]) &&
        Number(dataValue) <= Number(this.rangeArr[i][1])
      ) {
        return i;
      }
    }
    return n;
  }

  mapLabelToggle() {
    if (this.maplabelToggle == true) {
      this.maplabelToggle = false;
      this.labelOn = true;
      this.labelOff = false;
      $(".map_label_class").css("display", "none");
      $(".map_label_area_name_class").css("visibility", "hidden");
      $(".map_label_data_value_class").css("visibility", "hidden");
      $(".show_level_map").show();
      $(".hide_level_map").hide();
    } else {
      this.maplabelToggle = true;
      this.labelOn = false;
      this.labelOff = true;
      $(".map_label_class").css("display", "block");
      $(".map_label_area_name_class").css("visibility", "visible");
      $(".map_label_data_value_class").css("visibility", "visible");
      $(".map_label_class").each(function (i, k) {
        $(this).attr("style", function (i, s) {
          let temp = s.split(";");
          temp[1] = temp[1] + " !important";
          temp.push("-webkit-" + temp[1].trim());
          return temp.join(";").replace(";;", ";");
        });
      });
      $(".show_level_map").hide();
      $(".hide_level_map").show();
    }
  }

  // zoom in function
  leaflet_zoom_in() {
    this.map_global.setZoom(this.map_global.getZoom() + 1);
  }

  // zoom out function
  leaflet_zoom_out() {
    this.map_global.setZoom(this.map_global.getZoom() - 1);
  }
  ngForRendred() {
    let that = this;
    $(".map-ius-list").multiselect({
      onChange: function (option, checked, select) {
        that.iusID = $(option).val();
        that.selectedtp = "Time Period";
        that.getIusData($(option).val(), "mrd", "ind");
      },
    });
    $(" .break-map-modal .demoPanel .colorpicker1").colorpicker({
      color: "#31859b",
    });
  }

  resetMapZoom() {
    this.map_global.fitBounds(this.mapProperties.getBounds());
  }

  showCompass() {
    if (
      $("#dashboard-map .leaflet-control-container").hasClass("show-compass")
    ) {
      $("#dashboard-map .leaflet-control-container").css("display", "none");
      $("#dashboard-map .leaflet-control-container").removeClass(
        "show-compass"
      );
    } else {
      $("#dashboard-map .leaflet-control-container").css("display", "block");
      $("#dashboard-map .leaflet-control-container").addClass("show-compass");
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // download csv file of outlier
  downloadTableDataExcel(data) {
    this.export_table_to_csv();
  }

  getDate() {
    let d = new Date();
    let datestring =
      d.getDate() +
      "-" +
      (d.getMonth() + 1) +
      "-" +
      d.getFullYear() +
      " " +
      d.getHours() +
      ":" +
      d.getMinutes();
    return datestring.replace(":", ".");
  }

  export_table_to_csv() {
    var csv = [];
    var tempTableData: any;
    var container = document.querySelector("#table");
    var rows = container.querySelectorAll("table tr");
    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");
      for (var j = 0; j < cols.length; j++) {
        tempTableData = cols[j];
        row.push(tempTableData.innerText.replace(",", ""));
      }
      csv.push(row.join(","));
    }

    csv.push("");
    csv.push("");
    csv.push("source : " + this.dataTableHeaderSource);
    // Download CSV
    this.download_csv(
      csv.join("\n"),
      this.dataTableHeader.replace("|", "_") + "_" + this.getDate()
    );
  }

  download_csv(csv, filename) {
    let csvFile;
    let downloadLink;

    // CSV FILE
    csvFile = new Blob([csv], { type: "text/csv" });

    // Download link
    downloadLink = document.createElement("a");

    // File name
    downloadLink.download = filename + ".csv";

    // We have to create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Make sure that the link is not displayed
    downloadLink.style.display = "none";

    // Add the link to your DOM
    document.body.appendChild(downloadLink);

    // Lanzamos
    downloadLink.click();
  }

  checkValidNumber(obj) {
    if (this.splittedUnit == "number" || this.splittedUnit == "Number") {
      if ($(obj).val().indexOf(".") !== -1) {
        let temp = $(obj).val().split(".");
        $(obj).val(temp[0]);
        this.toastr.error("Decimal value is not allowed");
        return false;
      }
    }
  }

  changeContinousLegendLavel(obj, index) {
    this.setIusLegendlevel[index] = $(obj).val();
  }

  changeContinousLegend(obj) {
    if (this.splittedUnit == "number" || this.splittedUnit == "Number") {
      let temp = $(obj).val().split(".");
      if (temp.length >= 2) {
        this.toastr.error("Decimal value is not allowed");
        return false;
      }
    }

    let mapLegendContinous = [];
    let i = $(obj).data("index");
    let total = $(obj).data("length");
    if (
      Number($(obj).val()) <
        Number($("#map_legend_continous_value_" + i + "_1").val()) ||
      Number($(obj).val()) ==
        Number($("#map_legend_continous_value_" + i + "_1").val())
    ) {
      this.toastr.error(
        "Value should be greater than corresponding minimum value"
      );
      return false;
    } else if (Number($(obj).val()) >= Math.max.apply(Math, this.dataArr)) {
      this.toastr.error(
        "Value should not be greater than or equal to maximum value"
      );
      return false;
    } else {
      let min = Number($(obj).val());
      let max = Math.max.apply(Math, this.dataArr);
      let n = total - i;
      this.rangeArr[i][1] = $("#map_legend_continous_value_" + i + "_2").val();
      this.getCountInRange(
        this.dataArr,
        this.rangeArr[i][0],
        this.rangeArr[i][1]
      );
      if (min == max) {
        for (let counter = i + 1; counter < n; counter++) {
          let val1 = min;
          let val2 = max;
          this.rangeArr[counter] = [val1, val2];
        }
      } else {
        if (min == max) {
          min = 0;
        }

        let dist = ((max - min) / n).toFixed(2);
        let dist_number = Number(dist);
        let inc = 0;
        if ((max - min) % n != 0) {
          inc = 1;
        }
        let lowLimit;
        let highLimit;
        lowLimit = +min.toFixed(2);
        highLimit = +max.toFixed(2);
        let difference = (highLimit - lowLimit) / n;
        let value = lowLimit;
        let arr = [];
        let counter = i + 1;
        while (value < highLimit) {
          if (counter == 4) {
            value = max;
          } else {
            value += difference;
          }
          value = +value.toFixed(2);
          if (counter == i + 1) {
            let temp = $(obj).val().split(".");

            if (typeof temp[1] == "undefined") {
              lowLimit = Number(temp[0]) + 1;
            } else if (temp[1].length == 1) {
              if (Number(temp[0]) != 0) {
                lowLimit = Number($(obj).val()) + 0.1;
              } else {
                lowLimit = min + 0.1;
              }
            } else if (temp[1].length == 2) {
              if (Number(temp[0]) != 0) {
                lowLimit = Number($(obj).val()) + 0.01;
              } else {
                lowLimit = min + 0.01;
              }
            }
          }

          lowLimit = Number(lowLimit);

          if (value < lowLimit) {
            value = lowLimit;
          }

          if (this.splittedUnit == "number" || this.splittedUnit == "Number") {
            this.rangeArr[counter] = [
              Math.floor(Number(lowLimit)),
              Math.floor(Number(value)),
            ];
            this.getCountInRange(
              this.dataArr,
              Math.floor(Number(lowLimit)),
              Math.floor(Number(value))
            );
          } else {
            this.rangeArr[counter] = [lowLimit.toFixed(2), value.toFixed(2)];
            this.getCountInRange(
              this.dataArr,
              lowLimit.toFixed(2),
              value.toFixed(2)
            );
          }
          counter++;
          lowLimit = value + 0.01;
        }
      }
    }

    let object = this;
    setTimeout(function () {
      for (let i = 0; i < object.mapLegendColor.length; i++) {
        $("#colorpicker_" + i).val(object.mapLegendColor[i]);
        $("#colorpicker_" + i)
          .colorpicker()
          .on("change.color", function (evt, color) {
            let selectedColor = color;
            //let currentElement = evt.currentTarget.id;
            //self.storeLegendColor(currentElement, selectedColor);
            object.mapLegendColor[i] = selectedColor;
          });
      }
      // $("#colorpicker_0").val(object.mapLegendColor[0]);
      // $("#colorpicker_1").val(object.mapLegendColor[1]);
      // $("#colorpicker_2").val(object.mapLegendColor[2]);
      // $("#colorpicker_3").val(object.mapLegendColor[3]);
      // $("#colorpicker_4").val(object.mapLegendColor[4]);
      // $("#colorpicker_5").val(object.mapLegendColor[5]);
      // $(
      //   "#colorpicker_0, #colorpicker_1, #colorpicker_2, #colorpicker_3, #colorpicker_4,#colorpicker_5"
      // )
      //   .colorpicker()
      //   .on("change.color", function (evt, color) {
      //     let selectedColor = color;
      //     let currentElement = evt.currentTarget.id;
      //     //object.storeLegendColor(currentElement, selectedColor);

      //     object.mapLegendColor[i] = selectedColor;
      //   });
    }, 500);
    //reinit legend color
  }

  reloadMap(status) {
    // this.changeContinousLegend()
    this.newLevel = [];
    this.one = [];
    this.two = [];
    this.nlength = this.rangeArr.length;
    let that = this;
    this.percentLegendFirstLoad = 0;
    // this.mapLegendColor[0] = $("#colorpicker_0").val();
    // this.mapLegendColor[1] = $("#colorpicker_1").val();
    // this.mapLegendColor[2] = $("#colorpicker_2").val();
    // this.mapLegendColor[3] = $("#colorpicker_3").val();
    // this.mapLegendColor[4] = $("#colorpicker_4").val();
    // this.mapLegendColor[5] = $("#colorpicker_5").val();

    for (let i = 0; i < that.v.length; i++) {
      this.new[i] = $("#colorpicker_" + i).val();
      this.mapLegendColor = this.new;
    }

    $("#map-legend-edit-modal").modal(status);
    if (this.splittedUnit == "number" || this.splittedUnit == "Number") {
      for (let i = 0; i < this.rangeArr.length; i++) {
        var level =
          Math.floor(Number(this.rangeArr[i][0])) +
          " - " +
          Math.floor(Number(this.rangeArr[i][1]));
        if (that.setIusLegendlevel[i]) {
          level = that.setIusLegendlevel[i];
        }
        $("li.mapLegend")
          .eq(i)
          .html(
            "<span class='legend_box' style='background-color:" +
              this.mapLegendColor[i] +
              "'></span>" +
              level
          );
      }
    } else {
      for (let i = 0; i < this.rangeArr.length; i++) {
        // console.log(that.setIusLegendlevel);
        var level =
          Number(this.rangeArr[i][0]).toFixed(2) +
          " - " +
          Number(this.rangeArr[i][1]).toFixed(2);
        if (that.setIusLegendlevel[i]) {
          level = that.setIusLegendlevel[i];
        }

        $("li.mapLegend")
          .eq(i)
          .html(
            "<span class='legend_box' style='background-color:" +
              this.mapLegendColor[i] +
              "'></span>" +
              level
          );
        this.newLevel.push(level);
      }
    }

    for (const value of this.rangeArr) {
      this.one.push(value[0]);
      this.two.push(value[1]);
    }
    // this.dataService
    //   .add("api/addlegend", {
    //     level: this.newLevel,
    //     data: this.mapLegendColor,
    //     from: this.one,
    //     to: this.two,
    //   })
    //   .subscribe((response: any) => {});
    // this.getList();
    this.source = 2;
    this.loadMap(this.mapRecentData);
  }

  openColorBreakModal() {
    let self = this;
    for (let i = 0; i < this.mapLegendColor.length; i++) {
      $("#colorpicker_" + i).val(self.mapLegendColor[i]);
      $("#colorpicker_" + i)
        .colorpicker()
        .on("change.color", function (evt, color) {
          let selectedColor = color;
          let currentElement = evt.currentTarget.id;
          //self.storeLegendColor(currentElement, selectedColor);
          self.mapLegendColor[i] = selectedColor;
        });
    }

    this.initLegendColor();
  }

  initLegendColor() {
    let self = this;
    for (let i = 0; i < self.mapLegendColor.length; i++) {
      $("#colorpicker_" + i)
        .val(self.mapLegendColor[i])
        .next(".evo-pointer")
        .css("background-color", self.mapLegendColor[i]);
    }
  }

  // storeLegendColor(id, color) {
  //   switch (id) {
  //     case "colorpicker_0":
  //       this.mapLegendColor[0] = color;
  //       break;
  //     case "colorpicker_1":
  //       this.mapLegendColor[1] = color;
  //       break;
  //     case "colorpicker_2":
  //       this.mapLegendColor[2] = color;
  //       break;

  //     case "colorpicker_3":
  //       this.mapLegendColor[3] = color;
  //       break;
  //     case "colorpicker_4":
  //       this.mapLegendColor[4] = color;
  //       break;
  //     case "colorpicker_5":
  //       this.mapLegendColor[5] = color;
  //       break;

  //     default:
  //       // code...
  //       break;
  //   }
  // }

  getCountInRange(array, min, max) {
    this.rangeCount[min + "-" + max] = 0;
    for (let iCntr = 0; iCntr < array.length; iCntr++) {
      if (array[iCntr] >= Number(min) && array[iCntr] <= Number(max)) {
        this.rangeCount[min + "-" + max]++;
      }
    }
  }

  filterListCareUnit(val) {
    this.iusDropDown = this.tempIusList.filter(
      (item) =>
        (item.indicator + "|" + item.unit)
          .toLowerCase()
          .indexOf(val.toLowerCase()) > -1
    );
  }

  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodChar(timePeriod);
  }

  change_chart_type(chartType, timePeriod) {
    $(".loaders ol li").fadeIn();
    this.selectedChartType = chartType;
    if (timePeriod == "mrd") {
      this.getTimeperiods();
    }
    if (chartType == "map") {
      $("#dashboard-map").show();
      $(".show_with_map").css("visibility", "visible");
      $(".show_with_charts").hide();
      $("#viz_container").hide();
      this.getIusData(this.iusID, this.timePeriod, "");
    } else {
      // this.labelSwitchChartsOn = true;
      // this.labelSwitchChartsOff = false;
      $("#dashboard-map").hide();
      $(".show_with_map").css("visibility", "hidden");
      $(".show_with_charts").show();
      $("#viz_container").show();
      this.loadData(timePeriod);
    }
    if (chartType == "treemap" || chartType == "table") {
      $("#legend_").css({ display: "none" });
      $("#swap_").css({ display: "none" });
      $("#labelid_").css({ display: "none" });
    } else {
      $("#legend_").css({ display: "block" });
      $("#swap_").css({ display: "block" });
      $("#labelid_").css({ display: "block" });
    }
  }
  loadData(timePeriod) {
    // this.map_data = [];
    if (
      this.changed_from_to_timeperiod["from"] != "" &&
      typeof this.changed_from_to_timeperiod["from"] != "undefined" &&
      this.changed_from_to_timeperiod["to"] != "" &&
      typeof this.changed_from_to_timeperiod["to"] != "undefined"
    ) {
      timePeriod = 0;
    }
    // this.labelOn = true;
    // this.labelOff = false;
    let tempArea = [];
    /*$("#ius_sub_area :selected").each(function () {
      tempArea.push($(this).val());
    });*/

    tempArea.push(this.areaID);

    if (tempArea.length > 0) {
      this.areaForChart["area"] = tempArea;
    } else {
      $("#viz_container").html('<div class="no-data">No data available</div>');
      $(".loaders ol li").fadeOut();
      return;
    }

    this.dataService
      .getAll<any[]>(
        "api/viz/data?area_id=" +
          this.areaID +
          "&ius_id=" +
          this.iusID +
          "&time_period=" +
          timePeriod +
          "&from_time=" +
          this.changed_from_to_timeperiod["from"] +
          "&to_time=" +
          this.changed_from_to_timeperiod["to"] +
          "&type=" +
          this.selectedValueCalculationType
      )
      .subscribe(
        (res: any) => {
          $(".loaders ol li").fadeOut();
          this.all_ius = [];
          this.all_indicator = [];
          this.all_unit = [];
          this.all_subgroup = [];
          this.all_area = [];
          this.all_time_period = [];
          this.all_seriesData = [];

          let prop = "area_name";
          this.graphData = res.data;

          if (res.data.length <= 0) {
            $("#viz_container").html(
              '<div class="no-data">No data available</div>'
            );
            // this.chartContainer[containerId] = null;
            return;
          }
          this.map_data_table = [];

          if (this.selectedCalculationType == "Percent") {
            this.graphData.forEach((row) => {
              if (row.value != undefined) {
                this.map_data_table.push({
                  area_name: row.area_name,
                  data_value: row.value,
                  year: row.time_period,
                  coverage: row.numerator,
                  target: row.denominator,
                });
              }
            });
          } else {
            this.graphData.forEach((row) => {
              if (row.value != undefined) {
                this.map_data_table.push({
                  area_name: row.area_name,
                  data_value: row.numerator,
                  year: row.time_period,
                });
              }
            });
          }
          this.map_data_table = this.map_data_table.sort(function (a, b) {
            return a.area_name.localeCompare(b.area_name);
          });
          this.mapRecentTimePeriod = res.time_period;
          // res.data = res.data.sort(function (a, b) {
          //   return a[prop].localeCompare(b[prop]);
          // });

          res.data.forEach((elem) => {
            elem.time_period = this.commonService.covertTimePeriodChar(
              elem.time_period
            );
          });
          let objData: any = [];
          this.all_dataSource["source"] = [];
          let that = this;
          if (
            this.changed_from_to_timeperiod["from"] != "" &&
            typeof this.changed_from_to_timeperiod["from"] != "undefined" &&
            this.changed_from_to_timeperiod["to"] != "" &&
            typeof this.changed_from_to_timeperiod["to"] != "undefined"
          ) {
            let pass_data2 = [];
            let that = this;
            let dataVal;
            let groupedData = _.groupBy(res.data.records, function (d) {
              return d.area_id + "-" + d.ius_id;
            });
            _.each(groupedData, function ($row) {
              let tempData = [];
              let dataAddVal = 0;
              let denominator = 0;
              let numerator = 0;
              _.each($row, function ($val) {
                if ($val.unit == "Percent" || $val.unit == "percent") {
                  denominator = denominator + Number($val.denominator);
                  numerator = numerator + Number($val.numerator);
                } else if ($val.unit == "Number" || $val.unit == "number") {
                  dataAddVal = dataAddVal + Number($val.value);
                }
              });

              if (dataAddVal > 0) {
                dataVal = dataAddVal;
              } else if (denominator > 0 && numerator > 0) {
                dataVal = (numerator / denominator) * 100;
                dataVal = dataVal.toFixed(2);
              } else {
                dataVal = "";
              }

              pass_data2.push({
                area_code: $row[0].area_code,
                area_id: $row[0].area_id,
                numerator: $row[0].numerator,
                denominator: $row[0].denominator,
                area_name: $row[0].area_name,
                id: $row[0].id,
                indicator: $row[0].indicator,
                ius_id: $row[0].ius_id,
                source: $row[0].source,
                subgroup: $row[0].subgroup,
                unit: $row[0].unit,
                ius:
                  $row[0].indicator +
                  "|" +
                  $row[0].unit +
                  "|" +
                  $row[0].subgroup,
                time_period: that.getFormatedTimeperiod(
                  that.changed_from_to_timeperiod["from"] +
                    "-" +
                    that.changed_from_to_timeperiod["to"]
                ),
                value: dataVal,
              });
            });
            res.data = pass_data2;
            that.mapRecentData = pass_data2;
          }
          // this.mapRangeArrLegend[containerId] = [];

          let chartType = this.selectedChartType;
          let valueType = this.selectedCalculationType;

          res.data.forEach((element) => {
            if (
              that.all_ius.indexOf(
                element.indicator + "|" + element.unit + "|" + element.subgroup
              ) == -1
            ) {
              that.all_ius.push(
                element.indicator + "|" + element.unit + "|" + element.subgroup
              );
            }
            element.ius =
              element.indicator + "|" + element.unit + "|" + element.subgroup;
            if (that.all_indicator.indexOf(element.indicator) == -1) {
              that.all_indicator.push(element.indicator);
            }
            if (that.all_dataSource["source"].indexOf(element.source) == -1) {
              that.all_dataSource["source"].push(element.source);
              that.dataSource = that.all_dataSource["source"].join("");
            }
            if (that.all_unit.indexOf(element.unit) == -1) {
              that.all_unit.push(element.unit);
            }
            if (that.all_subgroup.indexOf(element.subgroup) == -1) {
              that.all_subgroup.push(element.subgroup);
            }
            if (that.all_area.indexOf(element.area_name) == -1) {
              that.all_area.push(element.area_name);
            }
            if (that.all_time_period.indexOf(element.time_period) == -1) {
              that.all_time_period.push(element.time_period);
            }
            if (that.all_seriesData.indexOf(element.value) == -1) {
              that.all_seriesData.push(element.value);
            }
          });

          if (
            chartType == "map" ||
            chartType == "treemap" ||
            chartType == "table"
          ) {
            $("#ius_timeperiod").val("");
            $("#toggleLegend").css({ display: "none" });
            $("#toggleLabel").css({ display: "none" });
            $("#toggleSwap").css({ display: "none" });
          } else {
            $("#toggleLegend").css({ display: "block" });
            $("#toggleLabel").css({ display: "block" });
            $("#toggleSwap").css({ display: "block" });
          }
          if (chartType == "treemap" || chartType == "table") {
            $("#label").css({ display: "none" });
          } else {
            $("#label").css({ display: "block" });
          }

          if (chartType != "map") {
            objData["funcName"] = this.useCase.findUseCase(
              chartType,
              this.all_indicator.length,
              this.all_unit.length,
              this.all_subgroup.length,
              this.all_time_period.length,
              this.all_area.length
            );
            objData["chartType"] = chartType;
            objData["indicator"] = this.splittedIndicator;
            objData["unit"] = this.splittedUnit;
            objData["time_period"] = this.getFormatedTimeperiod(
              this.all_time_period
            );
            objData["area"] = this.all_area;
            objData["data"] = res.data;
            objData["ius"] = this.all_ius;
            objData["type"] = valueType;
            objData["id"] = "viz_container";
            let tempData = this.getVizData(objData);
            let vizData = [];
            vizData["renderId"] = "viz_container";
            vizData["data"] = tempData["data"];

            vizData["legend"] = this.legendSwitch;
            vizData["chartType"] = tempData["chartType"];
            vizData["indicator"] = tempData["indicator"];
            vizData["unit"] = tempData["unit"];
            vizData["ius"] = tempData["ius"];
            vizData["type"] = valueType;
            // vizData['data_source'] = $("#data_source_" + containerId).html();
            vizData["data_source"] = this.all_dataSource["source"];
            if (objData["chartType"] != "table") {
              this.chartContainer["data"] = new Highcharts.Chart(
                this.vizService[tempData["vizFunc"]](vizData)
              );
              if (
                (objData["chartType"] == "area" ||
                  objData["chartType"] == "stackedarea" ||
                  objData["chartType"] == "stackedareapercent") &&
                objData["data"]["xaxis"].length == 1
              ) {
                this.toggleGrouping();
              }
            } else {
              $("#viz_container").html(vizData["data"]);
            }

            if (this.labelShow == true) {
              this.chartContainer["data"].update({
                plotOptions: {
                  series: {
                    dataLabels: {
                      enabled: this.labelShow,
                    },
                  },
                },
              });
            }
          } else {
            // this.renderMap(containerId);
          }
        },
        (error) => () => {
          console.log("Error");
        }
      );
  }

  getVizData(vizObj) {
    let vizData = [];

    switch (vizObj["chartType"]) {
      case "line":
      case "bar":
      case "column":
      case "stackedcolumn":
      case "stackedcolumnpercent":
      case "stackedbar":
      case "stackedbarpercent":
      case "area":
      case "stackedarea":
      case "stackedareapercent":
        vizData["vizFunc"] = "loadColumnChart";
        vizData["data"] = this.columnUseCase[vizObj["funcName"]](vizObj);
        break;
      case "pie":
        vizData["vizFunc"] = "loadPieChart";
        vizData["data"] = this.pieUseCase[vizObj["funcName"]](vizObj);
        break;
      case "treemap":
      case "tree":
        vizData["vizFunc"] = "loadTreeMap";
        vizData["data"] = this.pieUseCase[vizObj["funcName"]](vizObj);
        break;
      case "table":
        vizObj["vizFunc"] = "";
        vizData["data"] = this.tableUseCase[vizObj["funcName"]](vizObj);
        break;
      default:
        break;
    }
    vizData["chartType"] = vizObj["chartType"];
    vizData["indicator"] = vizObj["indicator"];
    vizData["unit"] = vizObj["unit"];
    vizData["ius"] = vizObj["ius"];
    vizData["id"] = vizObj["id"];
    return vizData;
  }

  toggleGrouping() {
    $(".loaders ol li").fadeIn();
    let newLabels = [];
    let newCategories = [];
    let newData = [];
    let swapCardType = this.selectedChartType;
    if (
      swapCardType == "treemap" ||
      swapCardType == "pie" ||
      swapCardType == "map" ||
      swapCardType == "pyramid" ||
      swapCardType == "bubble" ||
      swapCardType == "table" ||
      swapCardType == ""
    ) {
      $(".loaders ol li").fadeOut();
      this.toastr.error("Swap not allowed for this chart type.");
      return false;
    }
    var chart = this.chartContainer["data"];
    setTimeout(function () {
      let seriez = chart.series;
      $.each(chart.xAxis[0].categories, function (i, name) {
        newLabels.push(name);
      });
      $.each(seriez, function (x, serie) {
        newCategories.push(serie.name);
        $.each(serie.data, function (z, point) {
          if (newData[z] == undefined) {
            newData[z] = [];
          }
          if (newData[z][x] == undefined) {
            newData[z][x] = "";
          }
          newData[z][x] = point.y == undefined ? "" : point.y;
        });
      });
      while (chart.series.length > 0) {
        chart.series[0].remove(true);
      }
      chart.xAxis[0].setCategories(newCategories, false);
      $.each(newData, function (key, newSeries) {
        chart.addSeries(
          {
            name: newLabels[key],
            data: newSeries,
          },
          false
        );
      });
      chart.redraw();
      $(".loaders ol li").fadeOut();
    }, 0);
  }

  toggleCahrtLabel() {
    //prevent chart from autoload again

    if (this.labelSwitchChartsOff) {
      this.labelSwitchChartsOn = true;
      this.labelSwitchChartsOff = false;
    } else {
      this.labelSwitchChartsOn = false;
      this.labelSwitchChartsOff = true;
    }
    this.labelShow = !this.labelShow;
    let chartType = this.selectedChartType;
    if (chartType == "pie") {
      var opt = this.chartContainer["data"].series[0].options;
      opt.dataLabels.enabled = !opt.dataLabels.enabled;
      this.chartContainer["data"].series[0].update(opt);
    } else {
      this.chartContainer["data"].update({
        plotOptions: {
          series: {
            dataLabels: {
              enabled: this.labelShow,
            },
          },
        },
      });
    }
  }

  toggleLegend() {
    $(".loaders ol li").fadeIn();
    let swapCardType = this.selectedChartType;
    if (
      swapCardType == "treemap" ||
      swapCardType == "table" ||
      swapCardType == ""
    ) {
      $(".loaders ol li").fadeOut();
      return false;
    }

    if (this.legendSwitch) {
      this.legendSwitch = false;
    } else {
      this.legendSwitch = true;
    }

    this.chartContainer["data"].update({
      legend: {
        enabled: this.legendSwitch,
        itemStyle: {
          fontWeight: 100,
          fontSize: "11px",
        },
        align: "right",
        verticalAlign: "top",
        layout: "vertical",
        itemMarginTop: 0,
        itemMarginBottom: 2,
      },
    });
    $(".loaders ol li").fadeOut();
  }
  downloadMap() {
    var container = document.getElementById("downlaod_map_button");
    htmlToImage.toJpeg(container).then(function (dataUrl) {
      var link = document.createElement("a");
      link.download = "Map.jpeg";
      link.href = dataUrl;
      link.click();
    });
  }
  downloadChart() {
    $(".loaders ol li").fadeIn();
    if (this.selectedChartType == "table") {
      var html = $("#viz_container").html();
      // this.export_table_to_csv(html, 'filename' + this.getDate() + '.csv', item);
    } else {
      this.chartContainer["data"].exportChartLocal({
        type: "image/jpeg",
      });
    }
    $(".loaders ol li").fadeOut();
  }

  change_from_to(time_period, type) {
    if (type == "from") {
      this.changed_from_to_timeperiod["from"] = time_period;
    } else if (type == "to") {
      this.changed_from_to_timeperiod["to"] = time_period;
    }
  }

  from_to_button() {
    $(".loaders ol li").fadeIn();
    if (
      this.changed_from_to_timeperiod["from"] == "" ||
      typeof this.changed_from_to_timeperiod["from"] == "undefined" ||
      this.changed_from_to_timeperiod["to"] == "" ||
      typeof this.changed_from_to_timeperiod["to"] == "undefined"
    ) {
      this.toastr.error("Please select From date and To date.");
      $(".loaders ol li").fadeOut();
    } else if (
      this.changed_from_to_timeperiod["from"] >
      this.changed_from_to_timeperiod["to"]
    ) {
      this.toastr.error("To date must be greater than the From date.");
      $(".loaders ol li").fadeOut();
    } else {
      if (this.selectedChartType == "map") {
        this.getIusData(this.iusID, this.timePeriod, "");
      } else {
        this.loadData(this.timePeriod);
      }
    }
  }

  mouseoverVizList(idname) {
    $("#" + idname).show();
  }
  mouseoutVizList(idname) {
    $("#" + idname)
      .delay(5000)
      .hide();
  }

  /**
   * Add button for legends dynamic color with value
   */
  addRow() {
    let that = this;
    // console.log(that.dummyList)
    that.rangeArr.push([0, 0]);
    that.nlength = that.nlength + 1;
    that.setIusLegendlevel.push("");
    that.mapLegendColor.push("#ffffff");

    this.reloadMap("show");
    that.initLegendColor();

    let lenght = that.mapLegendColor.length - 1;

    setTimeout(() => {
      $("#colorpicker_" + lenght)
        .colorpicker()
        .on("change.color", function (evt, color) {
          let selectedColor = color;
          let currentElement = evt.currentTarget.id;
          //self.storeLegendColor(currentElement, selectedColor);
          that.mapLegendColor[lenght] = selectedColor;
        });
      $(".map_legend_div ul").append(
        "<li class='mapLegend'><span class='legend_box' style='background-color:#d4d4d4'></span>Missing Values</li>"
      );
      for (let i = 0; i < this.rangeArr.length; i++) {
        $("li.mapLegend")
          .eq(i)
          .html(
            "<span class='legend_box' style='background-color:" +
              this.mapLegendColor[i] +
              "'></span>"
          );
        // $(".map_legend_div ul").append(
        //   "<li class='mapLegend'><span class='legend_box' style='background-color:#d4d4d4'></span>Missing Values</li>"
        // );
      }
      // that.changeContinousLegend('show');
    }, 500);
  }

  /**
   * Delete button for legends dynamic color with value
   */
  deleteRow(nlength) {
    let that = this;
    // that.nlength = that.nlength-1;
    // this.rangeArr.splice(that.nlength, 1);
    that.rangeArr.splice(this.rangeArr.indexOf(nlength), 1);
    that.setIusLegendlevel.splice(this.setIusLegendlevel.indexOf(nlength), 1);
  }
  /**
   * api call to get all the records from dataService
   */
  getList() {
    let self = this;
    $(".loaders ol li").fadeIn();
    this.dataService.getAll<any[]>("api/legend/list").subscribe(
      (res: any) => {
        $(".loaders ol li").fadeOut();
        this.rows = res.data.records;
        // this.openBreakModal();
        for (let i = 0; i < self.rows.length; i++) {
          self.getcol.push(self.rows[i].color);

          $(".demoPanel .colorpicker1").colorpicker({
            color: "#31859b",
          });

          self.init();
          setTimeout(() => {
            $("#colorpicker_" + i)
              .colorpicker()
              .on("change.color", function (evt, color) {
                let selectedColor = color;
                let currentElement = evt.currentTarget.id;
                //self.storeLegendColor(currentElement, selectedColor);
                self.rows[i].color = selectedColor;
              });
          }, 500);
        }
        this.mapcolor = res.data.records;
        this.openBreakModal();
      },
      (error) => () => {
        console.log("Error");
      },
      () => {}
    );
  }

  openBreakModal() {
    let self = this;
    for (let i = 0; i < self.getcol.length; i++) {
      $("#colorpicker_" + i).val(self.getcol[i]);
      $("#colorpicker_" + i)
        .colorpicker()
        .on("change.color", function (evt, color) {
          let selectedColor = color;
          let currentElement = evt.currentTarget.id;
          //self.storeLegendColor(currentElement, selectedColor);
          self.getcol[i] = selectedColor;
        });
    }
    this.init();
  }
  init() {
    let self = this;
    for (let i = 0; i < self.getcol.length; i++) {
      $("#colorpicker_" + i)
        .val(self.getcol[i])
        .next(".evo-pointer")
        .css("background-color", self.getcol[i]);
    }
  }
  deleteUserData(id) {
    const dialog = this.dialog.open(DialogMaterialComponent, {
      width: "350px",
      data: { messageDialog: "Are you sure to delete legend?", delete: true },
    });
    dialog.afterClosed().subscribe((selection) => {
      if (selection) {
        this.dataService.delete("api/delete", id).subscribe((response: any) => {
          this.toastr.success(response.message);
          this.getList();
        });
      }
    });
  }

  getTimeperiods() {
    this.dataService
      .getAll("api/month/" + this.areaID + "/")
      .subscribe((response: any) => {
        // this.mapRecentTimePeriod = response.time_periods;
        this.selectedtp = response.time_periods[0].time_period;
        
      });
  }
}
