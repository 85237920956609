import { Component, OnInit, Injectable, AfterViewInit, Input } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import * as MarkerClusterer from "@google/markerclusterer"
import {
  TreeviewI18n, TreeviewItem, TreeviewConfig, TreeviewHelper, TreeviewComponent,
  TreeviewEventParser, OrderDownlineTreeviewEventParser, DownlineTreeviewItem
} from 'ngx-treeview';

import { HeaderComponent } from '../../header/header.component';
import { SharedataService } from "../../services/sharedata.service";
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router'


declare var $: any;
declare const google: any;
var locations = [

];


@Component({
  selector: 'app-facility-locator',
  templateUrl: './facility-locator.component.html',
  styleUrls: ['./facility-locator.component.css']
})
export class FacilityLocatorComponent implements OnInit {

  @Input() selectedArea: HeaderComponent;
  message: any;
  addForm: FormGroup;
  subscription: Subscription;
  areaID = null;
  centerId = null;
  facilityCenter = [{
    id: 1,
    name: 'Rural'
  },
  {
    id: 2,
    name: 'Urban'
  },
  ]

  constructor(private headerService: HeaderService, private shareData: SharedataService, private dataService: ApiService,
    public formBuilder: FormBuilder, private route:ActivatedRoute) {
    // subscribe to home component messages
    this.subscription = this.shareData.getData().subscribe(message => {
      switch (message.data.ctype) {
        case 'header':
          this.areaID = message.data.data.code;
          this.getFacilityByState(this.areaID);
          break;
        default:
          break;
      }
    });
  }

  formatLabel(value: number | null) {
    if (!value) {
      return 0;
    }

    return value;
  }

  ngOnInit() {
    this.headerService.setTitle('Facility Locator');
    this.headerService.setLogo('assets/lib/images/svg/header-menu/facility-locator-color.svg');
    this.addForm = this.formBuilder.group({
      facility_center: ['', Validators.required],
    });
  }

  initMap() {

    var map = new google.maps.Map(document.getElementById('map'), {
      zoom: 5,
      center: { lat: 20.5937, lng: 78.9629 }
    });

    var infoWin = new google.maps.InfoWindow();
    // Add some markers to the map.
    // Note: The code uses the JavaScript Array.prototype.map() method to
    // create an array of markers based on a given "locations" array.
    // The map() method here has nothing to do with the Google Maps API.
    var markers = locations.map(function (location, i) {
      var marker = new google.maps.Marker({
        position: location
      });
      google.maps.event.addListener(marker, 'click', function (evt) {
        infoWin.setContent(location.label);
        infoWin.open(map, marker);
      })
      return marker;
    });


    // Add a marker clusterer to manage the markers.
    var markerCluster = new MarkerClusterer(map, markers,
      { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });
  }

  ngAfterViewInit() {
    let area = this.route.params.subscribe(params => {
      this.getFacilityByState(params['id']);
        this.areaID = params['id'];
        });
    
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }

  dataByCenter(param) {
    this.centerId = param.value;
    this.getFacilityByState(this.areaID);
  }

  getFacilityByState(area) {
    $(".loaders ol li").fadeIn();
    this.dataService
      .getAll<any[]>('api/facility/by/center/' + area + '/' + this.centerId)
      .subscribe(
        (res: any) => {
          $(".loaders ol li").fadeOut();

          locations = [];
          $.each(res.data.records, function (i, val) {

            locations.push({
              lat: Number(val.latitude),
              lng: Number(val.longitude),
              label: res.data.areaIds[val.district].area_name,
              draggable: false
            });
          });

          this.initMap();
        },
        error => () => {
          console.log('Error')
        },
        () => {
          //this.toasterService.pop('success', 'Complete', 'Getting all values complete');
          //this.slimLoadingBarService.complete();
        }
      );

  }
}


