import { Component, OnInit, Input } from "@angular/core";
import { HeaderService } from "src/app/services/header.service";

import { _ } from "underscore";

import { Subscription } from "rxjs";
import { Configuration } from "../../../../app.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-graph-page3",
  templateUrl: "./graph-page3.component.html",
  styleUrls: ["./graph-page3.component.css"],
})
export class GraphPage3Component implements OnInit {
  @Input() timePeriod: any;
  subscription: Subscription;
  private url: any;

  public penta = [];
  public mr1 = [];
  public JE1 = [];

  public mapLevel = 1;
  public source = 1;
  public mapRecentTimePeriod = [];
  areaID = "IND";
  area_name = "IND";
  // timePeriod: any;
  mr1_value: [];
  JE1_value: [];
  loading=true;
  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private configuration: Configuration,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.url = configuration.server;

    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "header":
          this.source = 1;
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.getTable();
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {}

  ngOnChanges() {
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getTable();
  }
  getRoundValue(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  getPerValue(numerator, denominator) {
    var value = this.getRoundValue((numerator / denominator) * 100, 1);

    if (value) {
      return value;
    }

    return 0;
  }

  getTable() {
    let that = this;

    this.dataService
      .getAll("api/page12/" + this.timePeriod + "/" + this.areaID + "/")
      .subscribe((response: any) => {
        this.loading=false;
        that.penta = response.data;

        that.area_name = that.penta[0].area_name;

        let mr1 = [];
        let JE1 = [];

        that.penta.forEach((row) => {
          mr1.push([
            row.area_name,
            that.getPerValue(row.mr1_num, row.mr1_dem),
            row.area_code, 'MR 1st Dose'
          ]);
          JE1.push([
            row.area_name,
            that.getPerValue(row.JE1_num, row.JE1_dem),
            row.area_code,'JE 1st Dose'
          ]);
        });
        this.mr1 = mr1;
        this.JE1 = JE1;

        for (let i = 0; i < this.mr1.length; i++) {
          let val = this.mr1[0];
          this.mr1_value = val[1];
        }

        for (let i = 0; i < this.JE1.length; i++) {
          let val = this.JE1[0];
          this.JE1_value = val[1];
        }
      });
  }

  getObjectKey(object) {
    if (object) {
      return Object.keys(object);
    }

    return [];
  }
  ngAfterViewInit(): void {}
}
