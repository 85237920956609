import { Component, OnInit, Input } from "@angular/core";
import { HeaderService } from "src/app/services/header.service";
import { _ } from "underscore";

import { Subscription } from "rxjs";
import { Configuration } from "../../../../app.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-graph-page6",
  templateUrl: "./graph-page6.component.html",
  styleUrls: ["./graph-page6.component.css"],
})
export class GraphPage6Component implements OnInit {
  @Input() timePeriod: any;
  subscription: Subscription;
  private url: any;

  public penta = [];
  public IPV2 = [];
  public IPV3 = [];
 

  public mapLevel = 1;
  public source = 1;
  public mapRecentTimePeriod = [];

  areaID = "IND";
  area_name = "IND";
  // timePeriod: any;
  IPV2_value: [];
  IPV3_value: [];
 
  loading=true;
  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private configuration: Configuration,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.url = configuration.server;

    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "header":
          this.source = 1;
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.getTable();
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {}
  ngOnChanges() {
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getTable();
  }
  getRoundValue(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  getPerValue(numerator, denominator) {
    var value = this.getRoundValue((numerator / denominator) * 100, 1);

    if (value) {
      return value;
    }

    return 0;
  }

  getTable() {
    let that = this;

    this.dataService
      .getAll("api/page15/" + this.timePeriod + "/" + this.areaID + "/")
      .subscribe((response: any) => {
        this.loading=false;
        that.penta = response.data;
        that.area_name = that.penta[0].area_name;

        let IPV2 = [];
        let IPV3 = [];
       

        that.penta.forEach((row) => {
          IPV2.push([
            row.area_name,
            that.getPerValue(row.IPV2_num, row.IPV2_dem),
            row.area_code,'IPV 2nd Dose'
          ]);
          IPV3.push([
            row.area_name,
            that.getPerValue(row.IPV3_num, row.IPV3_dem),
            row.area_code,'IPV 3rd Dose'
          ]);
        
        });
        this.IPV2 = IPV2;
        this.IPV3 = IPV3;
       

        for (let i = 0; i < this.IPV2.length; i++) {
          let val = this.IPV2[0];
          this.IPV2_value = val[1];
        }

        for (let i = 0; i < this.IPV3.length; i++) {
          let val = this.IPV3[0];
          this.IPV3_value = val[1];
        }
      });
  }

  getObjectKey(object) {
    if (object) {
      return Object.keys(object);
    }

    return [];
  }
  ngAfterViewInit(): void {}
}
