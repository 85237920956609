import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../services/header.service';
@Component({
    selector: 'app-user-support',
    templateUrl: './user-support.component.html',
    styleUrls: ['./user-support.component.css']
})
export class UserSupportComponent implements OnInit {


    constructor(private headerService: HeaderService) { }

    ngOnInit() {
        this.headerService.setTitle('Support');
        this.headerService.setLogo('assets/lib/images/svg/header-menu/user1.svg')
    }

}
