import { Component, OnInit, Injectable, AfterViewInit, Input } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { Configuration } from '../../app.constant';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { SharedataService } from 'src/app/services/sharedata.service';
import { Subscription, SubscriptionLike } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';

import { SafeUrl, DomSanitizer } from '@angular/platform-browser';



declare var L: any;
declare var $: any;
var locations = [];




@Component({
  selector: 'app-report-mr',
  templateUrl: './report-mr.component.html',
  styleUrls: ['./report-mr.component.css']
})
export class ReportMrComponent implements AfterViewInit {
  subscription: Subscription;
  data = [];
  public areaID = 'IND';
  public current_url: SafeUrl;
  public mapRecentTimePeriod = [];
  public year: any;

  constructor(private headerService: HeaderService,
    private configuration: Configuration,
    private dataService: ApiService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private shareData: SharedataService,
    private sanitizer: DomSanitizer

  ) {

    this.subscription = this.shareData.getData().subscribe(message => {
      switch (message.data.ctype) {
        case 'header':
          this.areaID = message.data.data.code;
          this.getData();
          break;
        default:
          break;
      }
    })
  }

  ngAfterViewInit(): void {
    $(".loaders ol li").fadeOut();
  }


  getData() {
    this.current_url = this.sanitizer.bypassSecurityTrustResourceUrl("assets/lib/nfhs-5/html/" + this.areaID + ".html");
  }

  getAreaPath() {
    return "assets/lib/nfhs-5/html/" + this.areaID + ".html";
  }



  getObjectKey(object) {
    if (object) {
      return Object.keys(object);
    }
    return [];
  }
  ngOnInit() {
    this.headerService.setTitle('NFHS - 5 fact sheet');
    this.headerService.setLogo('assets/lib/images/svg/sidebar/data-import.svg');
    this.getData();
  }

  public captureScreen() {
    window.open("assets/lib/nfhs-5/pdf/" + this.areaID + ".pdf", "_blank");
  }
}
