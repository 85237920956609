import { Component, Input, Injectable, AfterViewInit } from "@angular/core";
import { HeaderService } from "src/app/services/header.service";
import { Subscription } from "rxjs";
import { Configuration } from "../../../app.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { CommonService } from "src/app/services/common.service";


@Component({
  selector: 'app-executive-dash',
  templateUrl: './executive-dash.component.html',
  styleUrls: ['./executive-dash.component.css']
})
export class ExecutiveDashComponent implements AfterViewInit {
  @Input() timePeriod: any;
  subscription: Subscription;
  private url: any;

  data = [];
  public penta = [];
  public mapLevel = 1;
  public source = 1;
  public mapRecentTimePeriod = [];
  infant = {};

  loading=true;

  areaID = "IND";
  area_name = "IND";
  // timePeriod: any;

  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private configuration: Configuration,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.url = configuration.server;

    // this.penta=[];
    this.subscription = this.shareData.getData().subscribe((message) => {
      //  console.log(message);

      switch (message.data.ctype) {
        case "header":
          this.source = 1;
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.getTable();
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {}

  ngOnChanges() {
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getTable();
  }
  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodChar(timePeriod);
  }

  getFinancialYear(timePeriod) {
    return this.commonService.getFinancialYear(timePeriod);
  }

  getRoundValue(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  getPerValue(numerator, denominator) {
    var value = this.getRoundValue((numerator / denominator) * 100, 1);
    if (value) {
      return value;
    }
    return 0;
  }

  getTable() {
    let that = this;
    this.dataService
      .getAll("api/getExecutiveDash/" + this.timePeriod + "/" + this.areaID + "/")
      .subscribe((response: any) => {

        this.loading=false;
        that.penta = response.data;
        this.mapRecentTimePeriod = response.time_period;

       
        that.area_name = that.penta[0].area_name;
       

     

        that.penta.forEach((row) => {
          var dem = that.infant[row.area_name];

        });
       

        if (!that.timePeriod || that.timePeriod == "mrd") {
          that.timePeriod = this.mapRecentTimePeriod[0].time_period;
        }
      });
  }
 
 
  getObjectKey(object) {
    if (object) {
      return Object.keys(object);
    }

    return [];
  }
  ngAfterViewInit(): void {}

}
