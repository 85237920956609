import { Component, OnInit, Input } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { CommonService } from "src/app/services/common.service";
declare var $: any;
@Component({
  selector: "app-immu-dashboard-page3",
  templateUrl: "./immu-dashboard-page3.component.html",
  styleUrls: ["./immu-dashboard-page3.component.css"],
})
export class ImmuDashboardPage3Component implements OnInit {
  @Input() timePeriod: any;
  constructor(
    private commonService: CommonService,
    private dataService: ApiService
  ) {}
 
  ngOnInit() {
    let data = $("#dash3").html();
    this.dataService
      .add("api/report/add-objects", {
        data: data,
        report_id: 3,
        page_no: 3,
        type: 3,
      })
      .subscribe((response: any) => {});
  }

  getYear(timePeriod) {
    return this.commonService.getFinancialFirstYear(timePeriod);
  }
 
  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodFullChar(timePeriod);
  }
}
