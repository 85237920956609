import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '../app.constant';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedataService {
    private subject = new Subject<any>();
    private url: string;
    private messageSource = new BehaviorSubject("Early Child Education");
    currentMessage = this.messageSource.asObservable();
    constructor(private http: HttpClient, private configuration: Configuration) {
        this.url = configuration.server;
    }
    changeMessage(SectorName: string) {
        this.messageSource.next(SectorName)
    }
    sendData(data: any) {
        this.subject.next({ data: data });
    }
    clearData() {
        this.subject.next();
    }
    getData(): Observable<any> {
        return this.subject.asObservable();
    }
    public getAll<T>(urlParam: string): Observable<T> {
        return this.http.get<T>(this.url + '/' + urlParam);
    }
}