import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dialog-material',
  templateUrl: './dialog-material.component.html',
  styleUrls: ['./dialog-material.component.css']
})
export class DialogMaterialComponent implements OnInit {

  constructor(
    public dialogref: MatDialogRef<DialogMaterialComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    console.log(this.data, 'sfgsd')
    this.data.messageDialog = this.sanitizer.bypassSecurityTrustHtml(this.data.messageDialog);
  }
  public confirmSelection() {
    this.dialogref.close(true);
  }

}
