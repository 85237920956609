import { Component, OnInit, Output, EventEmitter, QueryList, AfterViewInit, ViewChildren } from '@angular/core';
import { SharedataService } from "../../services/sharedata.service";
import { Router } from '@angular/router';

declare var $: any;
@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {
    @ViewChildren('allTheseThings') things: QueryList<any>;
    @Output() IusChange = new EventEmitter();
    imgUrl;
    sectorDataLevel = [];
    levelTwo = [];
    sideBarType: any = 1;
    hideList: boolean = false;
    showModal: boolean = false;
    indicator;
    subgroup;
    unit;
    ius = [];
    levelOne: any;
    levelImg: any;
    hideme = {};
    hideIcon = {};
    iusTitle = [];
    showIUSList: boolean = false;
    currentUrl: any;
    images = [
        {
            image: 'assets/lib/images/svg/sidebar/coverage.svg',
            // tooltip: 'Coverage',
            name: 'Coverage',
            bgcolor: '#F24051',
        },
        {
            image: 'assets/lib/images/svg/sidebar/vaccine.svg',
            // tooltip: 'New Vaccine',
            name: 'New Vaccines & Campaigns',
            bgcolor: '#F6921E',
        },
        {
            image: 'assets/lib/images/svg/sidebar/vaccine_protection.svg',
            // tooltip: 'Vaccine Safety',
            name: 'AEFI',
            bgcolor: '#EDD918',
        },
        {
            image: 'assets/lib/images/svg/sidebar/cold-chain.svg',
            // tooltip: 'Cold Chain and Vaccine Logistics',
            name: 'Cold Chain and Vaccine Logistics',
            bgcolor: '#1E75BB',
        },

        {
            image: 'assets/lib/images/svg/sidebar/urban_immunization.svg',
            // tooltip: 'Cold Chain and Vaccine Logistics',
            name: 'Urban Immunization',
            bgcolor: '#22AA5C',
        },
        {
            image: 'assets/lib/images/svg/sidebar/immunization_campaign.svg',
            name: 'Concurrent monitoring data  - WHO',
            bgcolor:'#A5D62C'
        },
        {
            image: 'assets/lib/images/svg/sidebar/comm.svg',
            name: 'Communication Monitoring - UNICEF',
            bgcolor:'#fb8a24'
        }
    ]

    constructor(private shareData: SharedataService,
        public route: Router) {
        this.currentUrl = route.url;
        this.hideme = {};
    }
    ngOnInit() {

        var self= this;
        $("side-nav-menu").on('mouseover', function (e) {
        });
        if (this.currentUrl == '/outlier/monitoring') {
            this.sideBarType = 2;
        }

        this.shareData.getAll('api/get-ius-list/' + this.sideBarType).subscribe((response: any) => {
            self.sectorDataLevel = response.data;
            self.sectorDataLevel.splice(1, 1); // for hide Urban Immunization
            for (let i = 0; i < self.sectorDataLevel.length; i++) {
                let sectorName = self.sectorDataLevel[i].level1;
                var index = self.images.findIndex(obj => obj.name == sectorName);
                if(index>=0){
                    self.sectorDataLevel[i].imgurl = self.images[index].image;
                    self.sectorDataLevel[i].bgcolor = self.images[index].bgcolor;
                }
               
            }
        })

    }
    sendSidebarFirstTimeLoad() {
        this.shareData.sendData({ 'ctype': 'sidebar', 'data': [] });

    }
    ngAfterViewInit() {
        setTimeout(function () {
            $('#side-nav-menu').find('li').css('display', 'block');
        }, 100);
        setTimeout(function () {
            $('#sidebar-menu-user').find('li').css('display', 'block');
        }, 100);
        this.things.changes.subscribe(t => {
            this.ngForRendred();
        })
        $(document).ready(function () {
            $('.search_div_main input').click(function () {
                $(this).siblings().find('.search-start').hide();
                $(this).siblings().find('.search-stop').show();
            });

            $('.search-stop').click(function () {
                $('.search_div_main input').val('');
                $(this).siblings().show();
                $(this).hide();
                $('.search_div_main input').trigger('keyup');
            });

            /*tree-dropdown*/
            $('.tree-parent').click(function () {
                $('.open > ul').not(this).removeClass("in pl-3");
                $('.open').not(this).removeClass("open");
                if ($(this).hasClass("open")) {
                    $(this).removeClass("open");
                    $(this).find('ul').removeClass("in pl-3");
                } else {
                    $(this).addClass("open");
                    $(this).find('ul').addClass("in pl-3");
                }
            });
            $('.search_div input').click(function () {
                $(this).siblings().find('.search-start').hide();
                $(this).siblings().find('.search-stop').show();
            });

            $('.search-stop').click(function () {
                $('.search_div input').val('');
                $(this).siblings().show();
                $(this).hide();
            });
            /*dropdown on click*/
            $('#sidebar a').on("click", function (e) {
                $(this).next('ul').toggleClass("animated fadeInLeft");
            });
            $('a.test').on("click", function (e) {
                $(this).next('div').toggleClass("animated fadeInLeft");
            });

            $('.dropdown-submenu a.test').on("click", function (e) {
                $(this).next('ul').toggle();
                $(this).next('div').toggle();
                e.stopPropagation();
                e.preventDefault();
            });
        });
    }
    isAdmin(): boolean {
        let authDetails = JSON.parse(localStorage.getItem('user_details'));
        if(authDetails.role == 1){
            return true;
        }
        if(authDetails.role == 1){
            return true;
        }
        return false;
    }
    isNationalUser(): boolean {
        let authDetails = JSON.parse(localStorage.getItem("user_details"));
        return authDetails.role == 2 ? true : false;
      }


    modalData(data) {
        this.levelOne = data.level1;
        this.levelOne = data.tooltip;
        this.levelImg = data.imgurl;
        this.levelTwo = data.leveltwo;
        this.levelTwo.forEach(element => {
            element.hideIcon = false;
            element.ius_classification.forEach(element => {
                this.ius = element.ius;
                element.ius.forEach(element => {
                    this.indicator = element.indicator
                    this.subgroup = element.subgroup
                    this.unit = element.unit
                    this.iusTitle.push(this.indicator + ' ' + this.subgroup + ' ' + this.unit);
                });

            });
        });
    }
    selectedIusdata(ius) {
        document.getElementById('close-btn').click();
    }
    sendData(parentData, childData,event): void {
        
        let tempData = childData;
        tempData['parentData'] = parentData;
        
        let targetId;

        if(event.target.id === null  || event.target.id === ''){
            targetId = event.target.closest('li').id;
        } else {
            targetId = event.target.id;
        }
        localStorage.setItem("sideBarData-id", targetId);

        this.shareData.sendData({ 'ctype': 'sidebar', 'data': tempData });
    }
    ngForRendred() {
        if(this.currentUrl  == '/user/area-map') {
            $("#sector_0_0").trigger('click');
            return false;
        }

        if (localStorage.getItem("sideBarData-id")) {
          $("#"+localStorage.getItem("sideBarData-id")).trigger('click')
        } else {           
          $("#sector_0_0").trigger('click')
        }        
    }
}
