import { Component, OnInit, Input } from "@angular/core";
import { HeaderService } from "src/app/services/header.service";
import { _ } from "underscore";

import { Subscription } from "rxjs";
import { Configuration } from "../../../../app.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-graph-page4",
  templateUrl: "./graph-page4.component.html",
  styleUrls: ["./graph-page4.component.css"],
})
export class GraphPage4Component implements OnInit {
  @Input() timePeriod: any;
  subscription: Subscription;
  private url: any;

  data = [];
  public penta = [];
  public rvv1 = [];
  public IPV1 = [];
  public rvv3 = [];

  public mapLevel = 1;
  public source = 1;
  public mapRecentTimePeriod = [];

  areaID = "IND";
  area_name = "IND";
  // timePeriod: any;
  rvv1_value: [];
  rvv3_value: [];
  IPV1_value: [];
  loading=true;
  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private configuration: Configuration,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.url = configuration.server;

    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "header":
          this.source = 1;
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.getTable();
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {}
  ngOnChanges() {
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getTable();
  }
  getRoundValue(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  getPerValue(numerator, denominator) {
    var value = this.getRoundValue((numerator / denominator) * 100, 1);

    if (value) {
      return value;
    }

    return 0;
  }

  getTable() {
    let that = this;

    this.dataService
      .getAll("api/page13/" + this.timePeriod + "/" + this.areaID + "/")
      .subscribe((response: any) => {
        this.loading=false;
        that.penta = response.data;

        that.area_name = that.penta[0].area_name;

        let rvv1 = [];
        let rvv3 = [];
        let IPV1 = [];

        that.penta.forEach((row) => {
          rvv1.push([
            row.area_name,
            that.getPerValue(row.rvv1_num, row.rvv1_dem),
            row.area_code, 'RVV 1st Dose'
          ]);
          rvv3.push([
            row.area_name,
            that.getPerValue(row.rvv3_num, row.rvv3_dem),
            row.area_code,'RVV 3rd Dose'
          ]);

          IPV1.push([
            row.area_name,
            that.getPerValue(row.IPV1_num, row.IPV1_dem),
            row.area_code,'IPV 1st Dose'
          ]);
        });

        this.rvv1 = rvv1;
        this.rvv3 = rvv3;
        this.IPV1 = IPV1;

        for (let i = 0; i < this.rvv1.length; i++) {
          let val = this.rvv1[0];
          this.rvv1_value = val[1];
        }

        for (let i = 0; i < this.rvv3.length; i++) {
          let val = this.rvv3[0];
          this.rvv3_value = val[1];
        }
        for (let i = 0; i < this.IPV1.length; i++) {
          let val = this.IPV1[0];
          this.IPV1_value = val[1];
        }
      });
  }

  getObjectKey(object) {
    if (object) {
      return Object.keys(object);
    }

    return [];
  }
  ngAfterViewInit(): void {}
}
