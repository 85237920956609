import { Component } from "@angular/core";
import { HeaderService } from './services/header.service';
import { AuthService } from './services/auth/auth.service';
import { CommonService } from './services/common.service';
import { Router } from "@angular/router";

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'jsiF';
  router;
  public login;

  constructor(private headerService: HeaderService,
    private auth: AuthService,
    private commonService: CommonService,
    public route: Router
     ) {
       
    this.router = route.url
    }

  ngOnInit() {
    this.headerService.title.subscribe(title => {
      this.title = title;
    });
  }
  isLoggednIn(): boolean {
    return (localStorage.getItem('auth_token') != null);
  }
}
