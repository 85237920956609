import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { ApiService } from "../../services/api.service";
import { UseCaseService } from "../../services/use-case.service";
import { GetCaseColumnService } from "../../services/get-case-column.service";
import { GetCasePieService } from "../../services/get-case-pie.service";
import { VisualizationRendererService } from '../../services/visualization-renderer';

import * as Highcharts from 'highcharts';
import * as Highmaps from "highcharts/highmaps";
import { Subscription } from 'rxjs';
import { SharedataService } from "../../services/sharedata.service";
import WordCloud from 'highcharts/modules/wordcloud';
import { CommonService } from "../../services/common.service";
import { element } from '@angular/core/src/render3';

declare var $: any;
var rangeIusList = require('src/mapIusLegend.json');
WordCloud(Highcharts);


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

 
  report_data;
  zero: any = [];
  selectedItem: any;
  subscription: Subscription;
  areaID: any = 1;
  areaCode: any = 'IND';
  map_data: any = [];
  dataArr: any = [];
  iusTp: any = '';
  minColor: any = '';
  maxColor: any = '';
  outlierData_1;
  outlierData_2;
  outlierData_3;
  outlierData_4;
  outlierData_5;
  outlierData_6;
  outlierData_7;
  outlierData_8;
  outlierData_9;
  outlierData_10;
  outlierData_11;
  outlierData_12;
  outlierData_13;
  outlierData_14;
  keyFactsDataValue15;
  keyFactsDataSource15;
  pageNo: any = '';
  areaGeoJson: any = 'assets/lib/map/IND_l02_2011_Census.json';
  areaLevel : any= 1;
  indicator_tab=[];
  subgroup_tab=[];
  time_period_tab=[];
  unit_tab=[];
  source_tab = [];
  chartData = [];
  val1 = "";
  loadedPage :any = [];

  loadPageByDownload = 0;
  keyfactTimeperiod = '';
  visualizationSource = '';
  mapGeojson;
  areaName = '';
  levelval = '';


  
  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private useCase: UseCaseService,
    private columnUseCase: GetCaseColumnService,
    private pieUseCase: GetCasePieService,
    public vizService: VisualizationRendererService,
    private shareData: SharedataService,
    private commonService: CommonService,
  ) {

    let areaDetails = JSON.parse(localStorage.getItem('area_details'));
    this.areaName = areaDetails.area_name;

  }

  ngOnInit() {

    this.headerService.setTitle('Dashboard');
    this.headerService.setLogo('assets/lib/images/svg/header-menu/report-color.svg');
    
    $(document).ready(function(){
      $('#firstContent').click();
    });
    
    
    this.areaLevel = Number(this.commonService.getAreaLevel());
    this.areaCode = this.commonService.getAreaID();
    this.areaID = this.commonService.getAreaID();
    
    if(this.areaLevel == 1){
     this.areaGeoJson = 'assets/lib/map/IND_l02_2011_Census.json';
    }else if(this.areaLevel == 2){
     this.areaGeoJson = 'assets/lib/map/IND_l03_2011_Census.json';
    }
    
   //this.getGeoJson();
    $(".loaders ol li").fadeOut();

  }

 

  downloadExcel()
  {
    let obj = this;
    this.areaLevel = Number(this.commonService.getAreaLevel());
   
    
    if (this.areaLevel == 1) {
      let value = (<HTMLSelectElement>document.getElementById('state-list')).value;
      if (value == "1")
      {
        this.val1 = "hmis_data_mar20.xls";

      }
      
      if (value == "2") {
        this.val1 = "hmis_data_feb20.xls";

      }
      if (value == "3") {
        this.val1 = "hmis_data_jan20.xls";


      }
      if (value == "4") {
        this.val1 = "hmis_data_dec19.xls";

        
      }
      if (value == "5") {
        this.val1 = "hmis_data_nov19.xls";

      }
      if (value == "6") {
        this.val1 = "hmis_data_oct19.xls";

      }
      if (value == "7") {
        this.val1 = "hmis_data_sep19.xls";

      }
      if (value == "8") {
        this.val1 = "hmis_data_aug19.xls";

      }
      if (value == "9") {
        this.val1 = "hmis_data_july19.xls";

      }
      if (value == "10") {
        this.val1 = "hmis_data_june19.xls";

      }
      if (value == "11") {
        this.val1 = "hmis_data_may19.xls";

      }
      if (value == "12") {
        this.val1 = "hmis_data_april19.xls";

      }
      var path = "http://www.iqip.in/dashboard/excel/" + this.val1; //relative-path
      window.location.href = path;
    }
  else
    {
      alert("No data found");

    }
  }

  downloadExcel1() {
    let obj = this;
    this.areaLevel = Number(this.commonService.getAreaLevel());

    if (this.areaLevel == 1) {
      let value = (<HTMLSelectElement>document.getElementById('state-list')).value;
      if (value == "1") {
        this.val1 = "hmis_feedback_mar20.pdf";

      }

      if (value == "2") {
        this.val1 = "hmis_feedback_feb20.pdf";

      }
      if (value == "3") {
        this.val1 = "hmis_feedback_jan20.pdf";


      }
      if (value == "4") {
        this.val1 = "hmis_feedback_dec19.pdf";


      }
      if (value == "5") {
        this.val1 = "hmis_feedback_nov19.pdf";

      }
      if (value == "6") {
        this.val1 = "hmis_feedback_oct19.pdf";

      }
      if (value == "7") {
        this.val1 = "hmis_feedback_sep19.pdf";

      }
      if (value == "8") {
        this.val1 = "hmis_feedback_aug19.pdf";

      }
      if (value == "9") {
        this.val1 = "hmis_feedback_july19.pdf";

      }
      if (value == "10") {
        this.val1 = "hmis_feedback_june19.pdf";

      }
      if (value == "11") {
        this.val1 = "hmis_feedback_may19.pdf";

      }
      if (value == "12") {
        this.val1 = "hmis_feedback_april19.pdf";

      }
      var path = "http://www.iqip.in/dashboard/pdf/" + this.val1; 
      window.location.href = path;
    }
    else
    {
      alert("No data found");
      }
  }

 

  getGeoJson(){

    let obj = this;
    
    $.getJSON(obj.areaGeoJson, function (geojson) {
        let map = geojson;
        let extractedJson = [];
        if(obj.areaLevel == 2) {
           for (var i = 0; i < geojson.features.length; i++) {
           if (geojson.features[i].properties.code.indexOf(obj.areaCode) !== -1) {
             extractedJson.push(geojson.features[i]);
           }
         }
         map = {type: "FeatureCollection",features:extractedJson};
        }

        obj._asignGeojson(map)
    });
  }

  _asignGeojson(map){
    this.mapGeojson = map;
  }


  ngAfterViewInit() {

    $(document).ready(function () {
      /*
        $('.app-multiselect-dropdown').multiselect({
          enableCollapsibleOptGroups: true,
          collapseOptGroupsByDefault: true,
          maxHeight: 200,
          nonSelectedText: 'Time Period',

        });

        $(".dashboardThumbnail").click(function(){
          $(this).prev().show();
           $(this).parent().siblings().find('.curl').hide();
        });
        */
    });
  
  }

  getReportObject(data) {
    $(".loaders ol li").fadeIn();
    this.selectedItem = data;
    this.dataService.add('c', { 'report': data.report_id, 'object': data.report_sequence_objects.id, 'type': data.report_sequence_objects.type }).subscribe((response: any) => {
      this.map_data = [];
      this.dataArr = [];
      this.iusTp = '';
      this.minColor = '';
      this.maxColor = '';
      $(".loaders ol li").fadeOut();
      switch(response.data.report_object[0].id){
        case 2:
          this.minColor = '#f23f52';
          this.maxColor = '#fba8b1';
          break;
        case 3:
          this.minColor = '#f6931e';
          this.maxColor = '#f4c184';
          break;
        case 4:
          this.minColor = '#edd918';
          this.maxColor = '#efe582';
          break;
        case 5:
          this.minColor = '#22ab5d';
          this.maxColor = '#87e2ae';
          break;
        case 6:
          this.minColor = '#1e75bc';
          this.maxColor = '#8fc6f2';
          break;
        case 7:
          this.minColor = '#a5d62c';
          this.maxColor = '#dbf795';
          break;
        case 8:
          this.minColor = '#f54a6a';
          this.maxColor = '#f9b0bd';
          break;
        case 9:
          this.minColor = '#fb8a24';
          this.maxColor = '#fac89b';
          break;
        case 10:
          this.minColor = '#f7bb1d';
          this.maxColor = '#f9e3a8';
          break;
        case 11:
          this.minColor = '#35afba';
          this.maxColor = '#a8eaf0';
          break;
        default:
          this.minColor = '#f7378d';
          this.maxColor = '#feb92e';
          break;
      }
      if (data.report_sequence_objects.type == 1) {
        for (let i = 0; i < response.data.report_object.length; i++) {
          let report_chart_type = response.data.report_object[i].chartType;
          let reportData = response.data.report_object[i].data;
          let ius = [];
          let indicator = [];
          let unit = [];
          let subgroup = [];
          let area = [];
          let time_period = [];
          let visualizationData = {};


          reportData.forEach(element => {
            if (ius.indexOf(element.indicator + '|' + element.unit + '|' + element.subgroup) == -1) {
              ius.push(element.indicator + '|' + element.unit + '|' + element.subgroup);
            }
            element['ius'] = element.indicator + '|' + element.unit + '|' + element.subgroup;
            if (indicator.indexOf(element.indicator) == -1) {
              indicator.push(element.indicator);
            }
            if (unit.indexOf(element.unit) == -1) {
              unit.push(element.unit);
            }
            if (subgroup.indexOf(element.subgroup) == -1) {
              subgroup.push(element.subgroup);
            }
            if (area.indexOf(element.area_name) == -1) {
              area.push(element.area_name);
            }
            if (time_period.indexOf(element.time_period) == -1) {
              time_period.push(element.time_period);
            }
            this.dataArr.push(Number(element.value));
            this.map_data.push({'code': element.area_code, 'value' : Number(element.value)});
            this.iusTp = element.indicator + '|' + element.unit + '|' + element.subgroup+', '+element.time_period;
          });

          let indicator_count = indicator.length;
          let unit_count = unit.length;
          let subgroup_count = subgroup.length;
          let area_count = area.length;
          let time_period_count = time_period.length;

          if(report_chart_type != 'map'){
            let useCase = this.useCase.findUseCase(report_chart_type, indicator_count, unit_count, subgroup_count, time_period_count, area_count);
            visualizationData['ius'] = ius;
            visualizationData['area'] = area;
            visualizationData['time_period'] = time_period;
            visualizationData['data'] = reportData;
            visualizationData['useCase'] = useCase;
            visualizationData['chartType'] = report_chart_type;

            let report_chart_data = this.getVizData(visualizationData);
            report_chart_data['data'] = {};
            report_chart_data['data']['xaxis'] = report_chart_data['xaxis'];
            report_chart_data['data']['series'] = report_chart_data['series'];
            report_chart_data['vizid'] = response.data.report_object[i].id;
            report_chart_data['chartType'] = response.data.report_object[i].chartType;
            this.createViz(report_chart_data);
          }else{
            
          }

          
        }
      } else {
        $('#reportViz').html(response.data.report_object);
      }
    });
  }

  getVizData(vizObj) {
    let vizData;
    
    switch (vizObj.chartType) {
      case "line":
        vizData = this.columnUseCase[vizObj.useCase](vizObj);
        break;
      case "bar":
        vizData = this.columnUseCase[vizObj.useCase](vizObj);
        break;
      case "column":
        vizData = this.columnUseCase[vizObj.useCase](vizObj);
        break;
      case "donut":
      case "pie":
        vizData = this.pieUseCase[vizObj.useCase](vizObj);
        break;
      case "radar":
        vizData = this.columnUseCase[vizObj.useCase](vizObj);
        break;

      default:
        // code...
        break;
    }

    return vizData;
  }

  createViz(vizData) {
    var chart_render;

    

    vizData['renderId'] = document.getElementById(vizData['vizid']);
    vizData['legend'] = true;
    
    switch (vizData['chartType']) {
      case "line":
        chart_render = new Highcharts.Chart(this.vizService.loadColumnChart(vizData));
        break;
      case "bar":
        chart_render = new Highcharts.Chart(this.vizService.loadColumnChart(vizData));
        break;
      case "column":
        chart_render = new Highcharts.Chart(this.vizService.loadColumnChart(vizData));
        break;
      case "pie":
        chart_render = new Highcharts.Chart(this.vizService.loadPieChart(vizData));
        break;
      case "radar":
        chart_render = new Highcharts.Chart(this.vizService.loadRadarChart(vizData));
        break;
      case "donut":
        chart_render = new Highcharts.Chart(this.vizService.loadDonutChart(vizData));
        break;


      default:
        // code...
        break;
    }

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    $(document).ready(function(){
      // $('main').addClass('mein-section nnmk');
 })
  }

  showPageContent(page_no){

    if(this.loadPageByDownload==0){
      $(".loaders ol li").fadeIn();
      $('.tab-pane').hide();
    }
    
    this.pageNo = page_no;
    let obj =this;
    switch(page_no){
      case 1: 
         
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
           this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+null+'/'+null).subscribe((response: any) => {
            $('#report_object_'+page_no).html('<img src="'+response.data.reportObject.image+'" alt="Cover Image" class="img-responsive" style="width: 100% !important;height: 1000px !important"/>');
           if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
            
          this.loadedPage.push({page_no:page_no});
           });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
         } 
        }
       
        break;
      case 2:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
        this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+51+'/'+this.areaID).subscribe((response: any) => {
            this.convertTimeFormat(response)
            this.outlierData_1 = response.data.reportObject.getOutlierData;
            this.chartData = response.data.reportObject.getChartData;
            this.getChartData(response.data.reportObject.getChartData, 'map', page_no);
           if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
            this.loadedPage.push({page_no:page_no});
          });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
        }

      break;
      case 3:
        // page 16
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
          this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+54+'/'+this.areaID).subscribe((response: any) => {
          this.convertTimeFormat(response)
          this.outlierData_2 = response.data.reportObject.getOutlierData;
          this.chartData = response.data.reportObject.getChartData;
          this.getChartData(response.data.reportObject.getChartData, 'map', page_no);
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
          this.loadedPage.push({page_no:page_no});
        });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            } 
        }
      
      break;
      case 4:
        // page no 7
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
          this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+57+'/'+this.areaID).subscribe((response: any) => {
            this.convertTimeFormat(response)
            this.outlierData_3 = response.data.reportObject.getOutlierData;
            this.chartData = response.data.reportObject.getChartData;
            this.getChartData(response.data.reportObject.getChartData, 'map', page_no);
           if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
          this.loadedPage.push({page_no:page_no});
        });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
        }
      
      break;
      case 5:
        // page no 
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
          this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+60+'/'+this.areaID).subscribe((response: any) => {
          this.convertTimeFormat(response)
          this.outlierData_4 = response.data.reportObject.getOutlierData;
          this.chartData = response.data.reportObject.getChartData;
          this.getChartData(response.data.reportObject.getChartData, 'map', page_no);
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
         this.loadedPage.push({page_no:page_no});
        });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
        }
     
      
      break;
      case 6:
          if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){   
            this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+64+'/'+this.areaID).subscribe((response: any) => {
            this.convertTimeFormat(response)
            this.outlierData_5 = response.data.reportObject.getOutlierData;
            this.chartData = response.data.reportObject.getChartData;
            this.getChartData(response.data.reportObject.getChartData, 'map', page_no);
           if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
           this.loadedPage.push({page_no:page_no});
         });
         }else{
           if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
         }
      
      break;
      case 7:
         if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
           this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+68+'/'+this.areaID).subscribe((response: any) => {
              this.convertTimeFormat(response)
               this.outlierData_6 = response.data.reportObject.getOutlierData;
               this.chartData = response.data.reportObject.getChartData;
               setTimeout(function(){
                 obj.getChartData(response.data.reportObject.getChartData, 'map', page_no);
               },100);
               
           if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
            
            this.loadedPage.push({page_no:page_no});
          });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
        }
      
       
      break;
      case 8:
         if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){   
          this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+57+'/'+this.areaID).subscribe((response: any) => {
            this.convertTimeFormat(response)
            this.outlierData_7 = response.data.reportObject.getOutlierData;
            this.chartData = response.data.reportObject.getChartData;
            setTimeout(function(){
              obj.getChartData(response.data.reportObject.getChartData, 'map', page_no);
            },100);
           if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
            this.loadedPage.push({page_no:page_no});
          });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
        }
        
      
      break;
      case 9:
         if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){     
             this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+33+'/'+this.areaID).subscribe((response: any) => {
              this.convertTimeFormat(response)
              this.outlierData_7 = response.data.reportObject.getOutlierData;
              this.chartData = response.data.reportObject.getChartData;
              setTimeout(function(){
                obj.getChartData(response.data.reportObject.getChartData, 'map', page_no);
              },100);
              if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
              this.loadedPage.push({page_no:page_no});
            });
          }else{
            if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
          }
              
      break;
      case 10:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){       
           this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+10+'/'+this.areaID).subscribe((response: any) => {
            this.convertTimeFormat(response)
            this.outlierData_9 = response.data.reportObject.getOutlierData;
            this.chartData = response.data.reportObject.getChartData;
            this.getChartData(response.data.reportObject.getChartData, 'map', page_no);
           if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
            this.loadedPage.push({page_no:page_no});
          });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
        }
      break;
      case 11:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
                  
           this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+23+'/'+this.areaID).subscribe((response: any) => {
            this.convertTimeFormat(response)
             this.outlierData_10 = response.data.reportObject.getOutlierData;
             this.chartData = response.data.reportObject.getChartData;
             this.getChartData(response.data.reportObject.getChartData, 'map', page_no);
            if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
             this.loadedPage.push({page_no:page_no});
          });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            } 
        }
        
      break;
      case 12:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
           this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+41+'/'+this.areaID).subscribe((response: any) => {
            this.convertTimeFormat(response)
               this.outlierData_11 = response.data.reportObject.getOutlierData;
               this.chartData = response.data.reportObject.getChartData;
               this.getChartData(response.data.reportObject.getChartData, 'map',page_no);
              if(this.loadPageByDownload==0){
                $('#tab_'+page_no).show();
                $(".loaders ol li").fadeOut();
              }
             this.loadedPage.push({page_no:page_no});
          });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            } 
        }
      break;
      case 13:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){  
           this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+43+'/'+this.areaID).subscribe((response: any) => {
            this.convertTimeFormat(response)
               this.outlierData_12 = response.data.reportObject.getOutlierData;
               this.chartData = response.data.reportObject.getChartData;
               this.getChartData(response.data.reportObject.getChartData, 'map', page_no);
               if(this.loadPageByDownload==0){
                $('#tab_'+page_no).show();
                $(".loaders ol li").fadeOut();
              }
             this.loadedPage.push({page_no:page_no});
          });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
        }
      break;
      case 14:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){ 
           this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+45+'/'+this.areaID).subscribe((response: any) => {
            this.convertTimeFormat(response)
             this.outlierData_13 = response.data.reportObject.getOutlierData;
             this.chartData = response.data.reportObject.getChartData;
             this.getChartData(response.data.reportObject.getChartData, 'map', page_no);
            if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            } 
             this.loadedPage.push({page_no:page_no});
          });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
        }
      
      break;
      case 15:      
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
           this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+null+'/'+null).subscribe((response: any) => {
            $('#report_object_'+page_no).html(response.data.reportObject.narration);
            // if(this.loadPageByDownload==0){}
           if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            } 
          this.loadedPage.push({page_no:page_no});
           });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
         }
            
        }
      break;
      case 16:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){   
           this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+57+'/'+this.areaID).subscribe((response: any) => {
            
            this.convertTimeFormat(response)
               $('#keyfact-indicator-'+page_no).html(response.data.reportObject.parentData[0].indicator);
               $('#keyfact-subgroup-'+page_no).html(response.data.reportObject.parentData[0].subgroup);
               $('#key-fact-data-value-'+page_no).html(response.data.reportObject.parentData[0].value+' <span class="fo-14 specific_left_space">'+response.data.reportObject.parentData[0].unit+'</span>');
               $('#key-fact-data-source-'+page_no).html('Source: '+response.data.reportObject.parentData[0].source);
               let keyfactTime = this.formatedTimeForKeyfact(response.data.reportObject.parentData[0].time_period)
               $('#keyfact-timeperiod-'+page_no).html(keyfactTime);
               this.getChartData(response.data.reportObject.getChartData, 'wordcloud', page_no);
               if(this.loadPageByDownload==0){
                   $('#tab_'+page_no).show();
                   $(".loaders ol li").fadeOut();
                 }
             this.loadedPage.push({page_no:page_no});
          });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
            
        }

      break;
      case 17: 
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
            this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+null+'/'+null).subscribe((response: any) => {
            $('#report_object_'+page_no).html('<img src="'+response.data.reportObject.image+'" alt="Cover Image" class="img-responsive"/>');
           if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
            
            this.loadedPage.push({page_no:page_no});
          });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
            
        }
       
        break; 
      case 18: 
       if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
        this.loadedPage.push({page_no:page_no});
        
        break;
      case 19: 
       if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
        this.loadedPage.push({page_no:page_no});
            
        break ; 
      case 20:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
            this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+10+'/'+this.areaID).subscribe((response: any) => {
              this.convertTimeFormat(response)
              $('#keyfact-indicator-'+page_no).html(response.data.reportObject.parentData[0].indicator);
              $('#keyfact-subgroup-'+page_no).html(response.data.reportObject.parentData[0].subgroup);
              $('#key-fact-data-value-'+page_no).html(response.data.reportObject.parentData[0].value+' <span class="fo-14 specific_left_space">'+response.data.reportObject.parentData[0].unit+'</span>');
              $('#key-fact-data-source-'+page_no).html('Source: '+response.data.reportObject.parentData[0].source);
              let keyfactTime = this.formatedTimeForKeyfact(response.data.reportObject.parentData[0].time_period)
              $('#keyfact-timeperiod-'+page_no).html(keyfactTime);
              this.getChartData(response.data.reportObject.getChartData, 'wordcloud', page_no);
              this.chartData = response.data.reportObject.getChartData;
              if(this.loadPageByDownload==0){
                $('#tab_'+page_no).show();
                $(".loaders ol li").fadeOut();
              }
             this.loadedPage.push({page_no:page_no});
          });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
        }

  
      break; 
      case 21:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
           this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+13+'/'+this.areaID).subscribe((response: any) => {
            this.convertTimeFormat(response)
             $('#keyfact-indicator-'+page_no).html(response.data.reportObject.parentData[0].indicator);
             $('#keyfact-subgroup-'+page_no).html(response.data.reportObject.parentData[0].subgroup);
               $('#key-fact-data-value-'+page_no).html(response.data.reportObject.parentData[0].value+' <span class="fo-14 specific_left_space">'+response.data.reportObject.parentData[0].unit+'</span>');
               $('#key-fact-data-source-'+page_no).html('Source: '+response.data.reportObject.parentData[0].source);
               let keyfactTime = this.formatedTimeForKeyfact(response.data.reportObject.parentData[0].time_period)
               $('#keyfact-timeperiod-'+page_no).html(keyfactTime);
               this.getChartData(response.data.reportObject.getChartData, 'wordcloud', page_no);
               this.chartData = response.data.reportObject.getChartData;
               if(this.loadPageByDownload==0){
                  $('#tab_'+page_no).show();
                  $(".loaders ol li").fadeOut();
                }
             this.loadedPage.push({page_no:page_no});
          });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            } 
        }

      break; 

      case 22:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){     
            this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+25+'/'+this.areaID).subscribe((response: any) => {
              this.convertTimeFormat(response)
              $('#keyfact-indicator-'+page_no).html(response.data.reportObject.parentData[0].indicator);
              $('#keyfact-subgroup-'+page_no).html(response.data.reportObject.parentData[0].subgroup);
                $('#key-fact-data-value-'+page_no).html(response.data.reportObject.parentData[0].value+' <span class="fo-14 specific_left_space">'+response.data.reportObject.parentData[0].unit+'</span>');
                $('#key-fact-data-source-'+page_no).html('Source: '+response.data.reportObject.parentData[0].source);
                let keyfactTime = this.formatedTimeForKeyfact(response.data.reportObject.parentData[0].time_period)
                $('#keyfact-timeperiod-'+page_no).html(keyfactTime);
                this.getChartData(response.data.reportObject.getChartData, 'wordcloud', page_no);
                this.chartData = response.data.reportObject.getChartData;
                if(this.loadPageByDownload==0){
                  $('#tab_'+page_no).show();
                  $(".loaders ol li").fadeOut();
                }
             this.loadedPage.push({page_no:page_no});
          });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }   
        }
      
      break;
      case 23:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){    
           this.dataService.getAll('api/report/get-page-content/'+page_no+'/15,17/'+this.areaID).subscribe((response: any) => {     
              response.data.reportObject.getChartData.forEach( elem => {
              elem.forEach(ele => {ele.time_period = this.commonService.covertTimePeriod(ele.time_period);})})
              this.getChartData(response.data.reportObject.getChartData[0], 'radar',page_no+'_0');
              this.getChartData(response.data.reportObject.getChartData[1], 'radar',page_no+'_1');
              this.chartData = response.data.reportObject.getChartData;
              if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
             this.loadedPage.push({page_no:page_no});
          });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
            
        }
      break;
      case 24:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){  
          this.dataService.getAll('api/report/get-page-content/'+page_no+'/21,23/'+this.areaID).subscribe((response: any) => {
              response.data.reportObject.getChartData.forEach( elem => {
              elem.forEach(ele => {ele.time_period = this.commonService.covertTimePeriod(ele.time_period);})})          
               this.getChartData(response.data.reportObject.getChartData[0], 'donut',page_no+'_0');
              this.getChartData(response.data.reportObject.getChartData[1], 'donut',page_no+'_1');
              this.chartData = response.data.reportObject.getChartData;
              if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
               this.loadedPage.push({page_no:page_no});
            });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }   
        }

      break;
      case 25:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
         this.dataService.getAll('api/report/get-page-content/'+page_no+'/29,31/'+this.areaID).subscribe((response: any) => {  
          response.data.reportObject.getChartData.forEach( elem => {
          elem.forEach(ele => {ele.time_period = this.commonService.covertTimePeriod(ele.time_period);})})                         
          this.getChartData(response.data.reportObject.getChartData[0], 'radar',page_no+'_0');
          this.getChartData(response.data.reportObject.getChartData[1], 'radar',page_no+'_1');
          this.chartData = response.data.reportObject.getChartData;
          if(this.loadPageByDownload==0){
          $('#tab_'+page_no).show();
          $(".loaders ol li").fadeOut();
          }
          this.loadedPage.push({page_no:page_no});
          });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
        }
     
      break;
      case 26:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
         this.dataService.getAll('api/report/get-page-content/'+page_no+'/37,39/'+this.areaID).subscribe((response: any) => {  
            response.data.reportObject.getChartData.forEach( elem => {
            elem.forEach(ele => {ele.time_period = this.commonService.covertTimePeriod(ele.time_period);})})                     
             this.getChartData(response.data.reportObject.getChartData[0], 'donut',page_no+'_0');
             this.getChartData(response.data.reportObject.getChartData[1], 'donut',page_no+'_1');
            //  this.chartData = response.data.reportObject.getChartData[0];
            //  this.chartData = response.data.reportObject.getChartData[1];
             if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
              this.loadedPage.push({page_no:page_no});
            });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
            
        }
      break;
      case 27:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){  
        this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+28+'/'+this.areaID).subscribe((response: any) => {                   
            this.getChartData(response.data.reportObject.getChartData, 'column',page_no);
            this.chartData = response.data.reportObject.getChartData;
            if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
              this.loadedPage.push({page_no:page_no});
            });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
        }
      break;
      case 28:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){  
         this.dataService.getAll('api/report/get-page-content/'+page_no+'/47,49/'+this.areaID).subscribe((response: any) => {   
          response.data.reportObject.getChartData.forEach( elem => {
          elem.forEach(ele => {ele.time_period = this.commonService.covertTimePeriod(ele.time_period);})})                    
          this.getChartData(response.data.reportObject.getChartData[0], 'radar',page_no+'_0');
          this.getChartData(response.data.reportObject.getChartData[1], 'radar',page_no+'_1');
          this.chartData = response.data.reportObject.getChartData;
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
            
           this.loadedPage.push({page_no:page_no});
            });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }  
        }
     
      break;
      case 29:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){  
           this.dataService.getAll('api/report/get-page-content/'+page_no+'/64,66/'+this.areaID).subscribe((response: any) => {
            response.data.reportObject.getChartData.forEach( elem => {
            elem.forEach(ele => {ele.time_period = this.commonService.covertTimePeriod(ele.time_period);})})
             this.getChartData(response.data.reportObject.getChartData[0], 'donut',page_no+'_0');                       
             this.getChartData(response.data.reportObject.getChartData[1], 'donut',page_no+'_1');
             this.chartData = response.data.reportObject.getChartData;
             
             if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
             this.loadedPage.push({page_no:page_no});
            });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
            
        }
      break;
      case 30:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
          this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+107+'/'+this.areaID).subscribe((response: any) => { 
            
            let prop = "area_name";
            this.chartData  = response.data.reportObject.getChartData.sort(function (a, b) {
              return a[prop].localeCompare(b[prop]);
            });
            this.convertTimeFormat(response)                            
            this.getChartData(response.data.reportObject.getChartData, 'bar',page_no);

            if(this.loadPageByDownload==0){
            $('#tab_'+page_no).show();
            $(".loaders ol li").fadeOut();
            }
            this.loadedPage.push({page_no:page_no});
            });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
        }
      
      break;
      case 31:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
          this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+115+'/'+this.areaID).subscribe((response: any) => {   
            this.convertTimeFormat(response)                   
            this.getChartData(response.data.reportObject.getChartData, 'bar',page_no);
            this.chartData = response.data.reportObject.getChartData;
            if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
            this.loadedPage.push({page_no:page_no});
            });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
        }

      break;
      case 32:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){ 
          this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+119+'/'+this.areaID).subscribe((response: any) => {       
            this.convertTimeFormat(response)                 
              this.getChartData(response.data.reportObject.getChartData, 'bar',page_no);
              this.chartData = response.data.reportObject.getChartData;
              if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
            this.loadedPage.push({page_no:page_no});
            });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            } 
        }

      break;
      case 33:
        if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
            this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+130+'/'+this.areaID).subscribe((response: any) => {   
              
              let prop = "area_name";
              this.chartData  = response.data.reportObject.getChartData.sort(function (a, b) {
                return a[prop].localeCompare(b[prop]);
              });
              this.convertTimeFormat(response)                            
              this.getChartData(response.data.reportObject.getChartData, 'bar',page_no);
              this.convertTimeFormat(response)                   
                if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
            this.loadedPage.push({page_no:page_no});
            });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
        }

      break;
      case 34:
     
         if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
           this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+null+'/'+null).subscribe((response: any) => {
            // response.data.reportObject.getChartData.forEach( elem => {
            // elem.forEach(ele => {ele.time_period = this.commonService.covertTimePeriod(ele.time_period);})})
            $('#report_object_'+page_no).html(response.data.reportObject.narration);
           if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            } 
          this.loadedPage.push({page_no:page_no});
           });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
         }  
        }

       
      break;
      case 35:
      
         if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
           this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+null+'/'+null).subscribe((response: any) => {
            $('#report_object_'+page_no).html(response.data.reportObject.narration);
            // if(this.loadPageByDownload==0){}
           if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
          this.loadedPage.push({page_no:page_no});
           });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
         }
        }
            
       
      break;
      case 36:
      
         if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
           this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+null+'/'+null).subscribe((response: any) => {
            $('#report_object_'+page_no).html(response.data.reportObject.narration);
            // if(this.loadPageByDownload==0){}
           if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
          this.loadedPage.push({page_no:page_no});
           });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
         }  
        } 
       
      break;
      case 37:
      
         if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
           this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+null+'/'+null).subscribe((response: any) => {
            $('#report_object_'+page_no).html(response.data.reportObject.narration);
            // if(this.loadPageByDownload==0){}
           if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
          this.loadedPage.push({page_no:page_no});
           });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
         }   
        }

      break;
      case 38:
      
         if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){
           this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+null+'/'+null).subscribe((response: any) => {
            $('#report_object_'+page_no).html(response.data.reportObject.narration);
            // if(this.loadPageByDownload==0){}
           if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
            
          this.loadedPage.push({page_no:page_no});
           });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
         }
        }
      break;
      case 39:

       if(this.loadedPage.find(function(el){ return el.page_no === page_no }) == undefined){ 
           this.dataService.getAll('api/report/get-page-content/'+page_no+'/'+71+'/'+this.areaID).subscribe((response: any) => {
            this.convertTimeFormat(response)
             this.outlierData_14 = response.data.reportObject.getOutlierData;
             this.getChartData(response.data.reportObject.getChartData, 'map', page_no);
             this.chartData = response.data.reportObject.getChartData;
            if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
             this.loadedPage.push({page_no:page_no});
          });
        }else{
         if(this.loadPageByDownload==0){
              $('#tab_'+page_no).show();
              $(".loaders ol li").fadeOut();
            }
        }
      break;
    }
  }
 
  getChartData(reportData, chartType, mapDiv){
    let ius = [];
    let indicator = [];
    let unit = [];
    let subgroup = [];
    let area = [];
    let time_period = [];
    let wordCloudSeries = [];
    let vizData = {};
    let objData: any = [];
    let source = [];
    let mapDiv_id = mapDiv;
    this.map_data = [];
    this.dataArr = [];
    
    if(reportData.length > 0){
      reportData.forEach(element => {
        if (ius.indexOf(element.indicator + '|' + element.unit + '|' + element.subgroup) == -1) {
          ius.push(element.indicator + '|' + element.unit + '|' + element.subgroup);
        }
        element['ius'] = element.indicator + '|' + element.unit + '|' + element.subgroup;
        if (indicator.indexOf(element.indicator) == -1) {
          indicator.push(element.indicator);
        }
        if (unit.indexOf(element.unit) == -1) {
          unit.push(element.unit);
        }
        if (subgroup.indexOf(element.subgroup) == -1) {
          subgroup.push(element.subgroup);
        }
        if (area.indexOf(element.area_name) == -1) {
          area.push(element.area_name);
        }
        if (time_period.indexOf(element.time_period) == -1) {
          time_period.push(element.time_period);
        }
        if(source.indexOf(element.source) == -1){
          source.push(element.source);
        }
        this.dataArr.push(Number(element.value));
        this.map_data.push({'code': element.area_code, 'value' : Number(element.value)});
        this.iusTp = element.indicator + ' | ' + element.unit + ' | ' + element.subgroup+', '+element.time_period;
        wordCloudSeries.push({'name':element.area_name, 'weight':Number(element.value)+10});
      });
      this.indicator_tab[mapDiv_id] = indicator.join(',');
      this.subgroup_tab[mapDiv_id] =  subgroup.join(',');
      this.time_period_tab[mapDiv_id] = time_period.join(',');
      this.unit_tab[mapDiv_id] = unit.join(',');

      if(source.length ==1){
        this.visualizationSource = source[0];
        this.source_tab[mapDiv_id] = source[0];
      }else{
        this.visualizationSource = source.join(',');
        this.source_tab[mapDiv_id] = source.join(',');
      }    
      let chartTypeToSend ='column';
      let mapData= [];
      mapData['indicator'] = indicator[0];
      if(chartType == 'map'){
        this.renderMap('report_object_'+mapDiv,mapData);
      }else if(chartType == 'wordcloud'){
        vizData['renderId'] = document.getElementById('report_object_'+mapDiv);
        vizData['data'] = wordCloudSeries;
        vizData['title'] = this.iusTp;
        let chart_render = new Highcharts.Chart(this.vizService.loadWordCloud(vizData));
      }else if(chartType=='column' || chartType=='bar' || chartType=='radar' || chartType=='donut'){
        
        if(chartType=='donut'){
          chartTypeToSend = 'pie'
        }else{
          chartTypeToSend = chartType
        }

        objData['funcName'] = this.useCase.findUseCase(chartTypeToSend, indicator.length, unit.length, subgroup.length, time_period.length, area.length);
        objData['chartType'] = chartType;
        objData['indicator'] = indicator[0];
        objData['unit'] = '';
        objData['time_period'] = time_period;
        objData['area'] = area;
        objData['data'] = reportData;
        objData['ius'] = ius;
        objData['useCase'] =  objData['funcName'];
        let report_chart_data = this.getVizData(objData);
        report_chart_data['data'] = {};
        report_chart_data['data']['xaxis'] = report_chart_data['xaxis'];
        report_chart_data['data']['series'] = report_chart_data['series'];
        report_chart_data['vizid'] =  'chart_container_'+mapDiv;
        report_chart_data['chartType'] =chartType;
        this.createViz(report_chart_data);
      }
    }else{

      if(chartType=='column' || chartType=='bar' || chartType=='radar' || chartType=='donut'){
      $('#chart_container_'+mapDiv).html("<span class='no-data'>No data available.</span>")

      }else{
      $('#report_object_'+mapDiv).html("<span class='no-data'>No data available.</span>")

      }
    }
  }

  renderMap(mapDiv,mapData) {
    
    $('#'+mapDiv).html('');
    let chartTarget = document.getElementById(mapDiv);
     chartTarget.style.height = '710px';
    let obj = this;
    
    // $.getJSON(obj.areaGeoJson, function (geojson) {
    //   let map = geojson;
    //   let extractedJson = [];
    //   if(obj.areaLevel == 2) {
    //      for (var i = 0; i < geojson.features.length; i++) {
    //      if (geojson.features[i].properties.code.indexOf(obj.areaCode) !== -1) {
    //        extractedJson.push(geojson.features[i]);
    //      }
    //    }
    //    map = {type: "FeatureCollection",features:extractedJson};
    //   }
    let nlength ;
    let rcvCurrentIusLegend ;
    
    $.each(rangeIusList,function (k,v) {      
      if(mapData['indicator'] == Object.keys(v)[0]){        
        nlength =Object.keys(rangeIusList[k][Object.keys(v)[0]]).length;
        rcvCurrentIusLegend = v[Object.keys(v)[0]];
      }
      
    });


    let mapRenageSet ;

    if(nlength==6){
     
    
      mapRenageSet= [{
                      to: rcvCurrentIusLegend[0].max,
                      color: '#ff0000'
                  }, {
                      from: rcvCurrentIusLegend[1].min,
                      to: rcvCurrentIusLegend[1].max,
                      color: '#ffc000'
                  }, {
                      from: rcvCurrentIusLegend[2].min,
                      to: rcvCurrentIusLegend[2].max,
                      color: '#ffff00'
                  }, {
                      from: rcvCurrentIusLegend[3].min,
                      to: rcvCurrentIusLegend[3].max,
                      color: '#92d050'
                  },  {
                      from: rcvCurrentIusLegend[4].min,
                      to: rcvCurrentIusLegend[4].max,
                      color: '#548235'
                  }, {
                      from: rcvCurrentIusLegend[5].min,
                      color: '#808080'
                  }];
    }else if(nlength==5){
      

      mapRenageSet=  [{
                      to: rcvCurrentIusLegend[0].max,
                      color: '#ff0000'
                  }, {
                      from: rcvCurrentIusLegend[1].min,
                      to: rcvCurrentIusLegend[1].max,
                      color: '#ffff00'
                  }, {
                      from: rcvCurrentIusLegend[2].min,
                      to: rcvCurrentIusLegend[2].max,
                      color: '#92d050'
                  }, {
                      from: rcvCurrentIusLegend[3].min,
                      to: rcvCurrentIusLegend[3].max,
                      color: '#548235'
                  }, {
                      from: rcvCurrentIusLegend[4].min,
                      color: '#808080'
                  }];
    }else{
      

      mapRenageSet= [{
                      to: 75,
                      color: '#ff0000'
                  }, {
                      from: 75,
                      to: 80,
                      color: '#ffff00'
                  }, {
                      from: 80,
                      to: 85,
                      color: '#92d050'
                  }, {
                      from: 85,
                      to: 90,
                      color: '#548235'
                  }, {
                      from: 90,
                      color: '#808080'
                  }];
    }
    
      let opts: any = {
        credits: {
          enabled: false
        },
        chart: {
          map: obj.mapGeojson,
        },
        
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            verticalAlign: 'bottom'
          }
        },
        colorAxis: {
          dataClasses: mapRenageSet
        },

        series: [{
          data: obj.map_data,
          mapData: obj.mapGeojson,
          joinBy: 'code',
          name: obj.areaName,
          dataLabels: {
            enabled:true,
            format: '{point.value}',
            
            color: 'black',
          }
        }],
        legend: {
            align: 'center',
            //verticalAlign: 'bottom',
            //x: 0,
            //y: -100,
            //floating: true,
            //layout: 'vertical',
            valueDecimals: 0,
            symbolRadius: 0
        },
      };
      Highmaps.mapChart(chartTarget, opts);
    //});
  }

  downloadReport(){

    let allPage = [1,15,16,20,21,22,4,8,9,10,11,12,13,14,2,3,5,6,7,39,23,24,25,26,28,29,30,31,32,33,34,35,36,37,38,17];
    let obj =this;


    this.loadPageByDownload =1; 
    $(".loaders ol li").fadeIn();
    // $('main').removeClass('mein-section nnmk');
   setTimeout(function(){
      allPage.forEach(element => {
       if(obj.loadedPage.find(function(el){ return el.page_no === element }) == undefined){
        obj.showPageContent(element);
   
       }
      });
      //this.loadPageByDownload =0;
   },1000);
    let that = this;
    var interval = setInterval(function(){ 
      if(that.checkAllChartLoaded()){
        $(".loaders ol li").fadeOut();
        window.print();
        clearInterval(interval);
        that.clearLoadedChart();
      }
    }, 100000);
 
  }

  checkAllChartLoaded() {
 
    if(this.loadedPage.length == 36) {
      this.loadPageByDownload = 0;
      return 1;
    } else {
      return 0;
    }
  }

  clearLoadedChart(){
    this.loadedPage = [];
  }

  convertTimeFormat(response){

    response.data.reportObject.getChartData.forEach( elem => {
    elem.time_period = this.commonService.covertTimePeriod(elem.time_period);
  })}

  formatedTimeForKeyfact(timePeriod){
    return this.commonService.covertTimePeriod(timePeriod);
  }
}                                                                                                                                                                                                                                              