import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class UseCaseService {
  seriesData = [];
  xaxis = [];
  chartFirstGroup = ['bar', 'line', 'column', 'radar', 'stackbar', 'stackedcolumn', 'area', 'stackedcolumnpercent', 'stackedareapercent', 'stackedarea'
    , 'stackedbar', 'stackedbarpercent'
  ];
  chartSecondGroup = ['pie', 'treemap'];

  constructor() { }
  public findUseCase<T>(type: any, indicator_length: number, unit_length: number, subgroup_length: number, time_period_length: number, area_length: number) {
    //clear footnotes variable
    if (this.chartFirstGroup.indexOf(type) !== -1) {
      type = 'column';
    } else if (this.chartSecondGroup.indexOf(type) !== -1) {
      type = 'pie';
    }
    let func = '';
    if (indicator_length == 1 && unit_length == 1 && subgroup_length == 1 && area_length == 1 && time_period_length == 1) {
      func = type + 'Case1';
    } else if (indicator_length == 1 && unit_length == 1 && subgroup_length == 1 && area_length == 1 && time_period_length > 1) {
      func = type + 'Case2';
    } else if (indicator_length == 1 && unit_length == 1 && subgroup_length == 1 && area_length > 1 && time_period_length == 1) {
      func = type + 'Case3';
    } else if (indicator_length == 1 && unit_length == 1 && subgroup_length == 1 && area_length > 1 && time_period_length > 1) {
      func = type + 'Case4';
    } else if (indicator_length == 1 && unit_length == 1 && subgroup_length > 1 && area_length == 1 && time_period_length == 1) {
      func = type + 'Case5';
    } else if (indicator_length == 1 && unit_length == 1 && subgroup_length > 1 && area_length > 1 && time_period_length == 1) {
      func = type + 'Case6';
    } else if (indicator_length == 1 && unit_length == 1 && subgroup_length > 1 && area_length > 1 && time_period_length > 1) {
      func = type + 'Case7';
    } else if (indicator_length > 1 && unit_length == 1 && subgroup_length == 1 && area_length > 1 && time_period_length > 1) {
      func = type + 'Case8';
    } else if (indicator_length > 1 && unit_length > 1 && subgroup_length == 1 && area_length > 1 && time_period_length > 1) {
      func = type + 'Case9';
    } else if (indicator_length > 1 && unit_length > 1 && subgroup_length > 1 && area_length > 1 && time_period_length > 1) {
      func = type + 'Case10';
    } else if (indicator_length == 1 && unit_length > 1 && subgroup_length == 1 && area_length > 1 && time_period_length > 1) {
      func = type + 'Case11';
    } else if (indicator_length == 1 && unit_length > 1 && subgroup_length == 1 && area_length == 1 && time_period_length > 1) {
      func = type + 'Case12';
    } else if (indicator_length == 1 && unit_length > 1 && subgroup_length == 1 && area_length == 1 && time_period_length == 1) {
      func = type + 'Case13';
    } else if (indicator_length > 1 && unit_length == 1 && subgroup_length == 1 && area_length == 1 && time_period_length == 1) {
      func = type + 'Case14';
    } else if (indicator_length == 1 && unit_length > 1 && subgroup_length > 1 && area_length == 1 && time_period_length == 1) {
      func = type + 'Case15';
    } else if (indicator_length > 1 && unit_length > 1 && subgroup_length == 1 && area_length == 1 && time_period_length == 1) {
      func = type + 'Case16';
    } else if (indicator_length == 1 && unit_length == 1 && subgroup_length > 1 && area_length == 1 && time_period_length > 1) {
      func = type + 'Case17';
    } else if (indicator_length > 1 && unit_length == 1 && subgroup_length == 1 && area_length == 1 && time_period_length > 1) {
      func = type + 'Case18';
    } else if (indicator_length > 1 && unit_length == 1 && subgroup_length == 1 && area_length > 1 && time_period_length == 1) {
      func = type + 'Case19';
    } else if (indicator_length > 1 && unit_length == 1 && subgroup_length > 1 && area_length == 1 && time_period_length == 1) {
      func = type + 'Case20';
    } else if (indicator_length == 1 && unit_length > 1 && subgroup_length > 1 && area_length > 1 && time_period_length > 1) {
      func = type + 'Case21';
    } else if (indicator_length == 1 && unit_length > 1 && subgroup_length == 1 && area_length > 1 && time_period_length == 1) {
      func = type + 'Case22';
    } else if (indicator_length == 1 && unit_length > 1 && subgroup_length > 1 && area_length == 1 && time_period_length > 1) {
      func = type + 'Case23';
    } else if (indicator_length == 1 && unit_length > 1 && subgroup_length > 1 && area_length > 1 && time_period_length == 1) {
      func = type + 'Case24';
    } else if (indicator_length > 1 && unit_length == 1 && subgroup_length > 1 && area_length > 1 && time_period_length == 1) {
      func = type + 'Case25';
    } else if (indicator_length > 1 && unit_length == 1 && subgroup_length > 1 && area_length > 1 && time_period_length > 1) {
      func = type + 'Case26';
    } else if (indicator_length > 1 && unit_length == 1 && subgroup_length > 1 && area_length == 1 && time_period_length > 1) {
      func = type + 'Case27';
    } else if (indicator_length > 1 && unit_length > 1 && subgroup_length == 1 && area_length == 1 && time_period_length > 1) {
      func = type + 'Case28';
    } else if (indicator_length > 1 && unit_length > 1 && subgroup_length == 1 && area_length > 1 && time_period_length == 1) {
      func = type + 'Case29';
    } else if (indicator_length > 1 && unit_length > 1 && subgroup_length > 1 && area_length == 1 && time_period_length == 1) {
      func = type + 'Case30';
    } else if (indicator_length > 1 && unit_length > 1 && subgroup_length > 1 && area_length > 1 && time_period_length == 1) {
      func = type + 'Case31';
    } else if (indicator_length > 1 && unit_length > 1 && subgroup_length > 1 && area_length == 1 && time_period_length > 1) {
      func = type + 'Case32';
    }
    return func;
  }
}

