import { Component, OnInit, Injectable, AfterViewInit, Input } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { ApiService } from '../../services/api.service';
import { Subscription, SubscriptionLike } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
declare var L: any;
declare var $: any;
var locations = [];

@Component({
  selector: 'app-state-profile-fact-sheet',
  templateUrl: './state-profile-fs.component.html',
  styleUrls: ['./state-profile-fs.component.css']
})
export class StateProfileFactSheeetComponent implements OnInit {
  subscription: Subscription;
  private url: any;
  areaID: 1;
  area_name = 'INDIA';
  timePeriod: any;
  data = [];
  district = 'NA';
  area_list = [];
  geoJson: any;
  values: any;
  map_global: any;
  mapProperties;
  mapOptions = {
    zoom: 3.7,
    zoomSnap: 0.08,
    center: L.latLng(21.7679, 78.8718),
    zoomControl: false,
    scrollWheelZoom: false
  };

  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private http: HttpClient
  ) {

  }

  ngOnInit() {
    this.headerService.setTitle('State/national profiles');
    this.headerService.setLogo('assets/lib/images/svg/sidebar/data-import.svg');
    this.getAllData();
  }
  getAllData() {
    let that = this;
    $(".loaders ol li").fadeIn();
    this.dataService.getAll('api/get-state-profile').subscribe((response: any) => {
      that.data = response.data;
      $(".loaders ol li").fadeOut();
      that.data.forEach((row) => {
        that.area_list.push(row.area_name);
        if (row.area_name == that.area_name) {
          console.log(row)
          that.values = row;

          that.getGeoJson();
        }
      });
    })

  }

  onMapReady(map: L.Map) {
    let self = this;
    self.map_global = map;
    console.log( self.map_global);
  }



  getGeoJson() {
    let that = this;
    if (this.geoJson) {
      this.renderMap();

    } else {
      this.http.get('assets/lib/map/IND_l02_2011_Census.json').subscribe((response: any) => {
        this.geoJson = response;
        that.renderMap();

      });
    }
  }

  renderMap() {
    this.createMapStructure();
  }

  createMapStructure() {
    let map = this.map_global;
    map.eachLayer(function (layer) {
      map.removeLayer(layer);
    });
    let that = this;
    this.mapProperties = L.geoJSON(this.geoJson, {
      onEachFeature: this.onEachFeature.bind(this)
    }).addTo(this.map_global);

    this.mapProperties.setStyle(function (feature) {
      let area_code = feature.properties.code;
      let value = feature.properties.DATA_VALUE;
      let color = '#d4d4d4';
      if(area_code== that.values.area_code){
        color ='#548235';
      }
      else if(that.values.area_code== 'IND'){
        color ='#548235';
      }
      return {
        fillColor: color,
        color: color
      }
    })
  }
  onEachFeature(feature, layer) {
    let that = this;
    locations[feature.properties.code] = {
      'lat': layer.getBounds().getCenter().lat,
      'lng': layer.getBounds().getCenter().lng,
      'properties': feature.properties
    };
    var out = [];
    var label = [];
  }

  getTitle(){
    if(this.values.area_name== 'INDIA'){
      return 'INDIA';
    }
    else {
      return 'INDIA/'+ this.values.area_name;
    }

  }
  changeArea(event) {
    let that = this;
    $(".loaders ol li").fadeIn();
    that.data.forEach((row) => {
      if (row.area_name == that.area_name) {
        that.values = row;
        $(".loaders ol li").fadeOut();
        that.renderMap();
        return;
      }
    });
  }

  public captureScreen() {
    let downLoadSection = document.getElementById('state') as HTMLElement;
    
     html2canvas(downLoadSection,{scale:6}).then(function (canvas) {
     
      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      canvas.getContext('2d');
     
      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], 'p');
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      pdf.save("state.pdf");
    });
  
}
}
