import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customFilterPipe'
})
export class CustomFilterPipePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.filter((x: any) =>
      x.visualization_name.toLowerCase().includes(args.toLowerCase()))
  }


}
