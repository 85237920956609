import { Component, OnInit, Input } from "@angular/core";
import { HeaderService } from "../../../../services/header.service";
import { Configuration } from "../../../../app.constant";
import { ApiService } from "../../../../services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { Subscription } from "rxjs";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-graph-dpt-b",
  templateUrl: "./graph-dpt-b.component.html",
  styleUrls: ["./graph-dpt-b.component.css"],
})
export class GraphDptBComponent implements OnInit {
  @Input() timePeriod: any;
  subscription: Subscription;
  private url: any;

  public penta = [];
  public DPT1 = [];
  public mapLevel = 1;
  public source = 1;
  areaID = "IND";
  area_name = "IND";
  DPT1_value: [];
  loading = true;
  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private configuration: Configuration,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.url = configuration.server;

    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "header":
          this.source = 1;
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.getGraphData();
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {}

  ngOnChanges() {
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getGraphData();
  }
  getRoundValue(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  getPerValue(numerator, denominator) {
    var value = this.getRoundValue((numerator / denominator) * 100, 1);
    if (value) {
      return value;
    }
    return 0;
  }

  getGraphData() {
    let that = this;
    this.dataService
      .getAll("api/getGraphDptBooster/" + this.timePeriod + "/" + this.areaID + "/" )
      .subscribe((response: any) => {
        this.loading = false;
        that.penta = response.data;
        that.area_name = that.penta[0].area_name;

        let DPT1 = [];
        that.penta.forEach((row) => {
          DPT1.push([
            row.area_name,
            that.getPerValue(row.DPT_B_num, row.DPT_B_dem),
            row.area_code,
          ]);
        });
        this.DPT1 = DPT1;

        for (let i = 0; i < this.DPT1.length; i++) {
          let val = this.DPT1[0];
          this.DPT1_value = val[1];
        }
      });
  }

  getObjectKey(object) {
    if (object) {
      return Object.keys(object);
    }
    return [];
  }
}
