import { Component, OnInit } from '@angular/core';

import { HeaderService } from '../../services/header.service';
import { ApiService } from "../../services/api.service";
import { UseCaseService } from "../../services/use-case.service";
import { GetCaseColumnService } from "../../services/get-case-column.service";
import { GetCasePieService } from "../../services/get-case-pie.service";
import { VisualizationRendererService } from '../../services/visualization-renderer';

import * as Highcharts from 'highcharts';
import * as Highmaps from "highcharts/highmaps";
import { Subscription } from 'rxjs';
import { SharedataService } from "../../services/sharedata.service";
import WordCloud from 'highcharts/modules/wordcloud';
import { CommonService } from "../../services/common.service";
import { element } from '@angular/core/src/render3';
declare var $: any;
@Component({
  selector: 'app-user-manual',
  templateUrl: './user-manual.component.html',
  styleUrls: ['./user-manual.component.css']
})
export class UserManualComponent implements OnInit {

  
  constructor(private headerService: HeaderService) { }

  ngOnInit() {
    this.headerService.setTitle('user manual');
    this.headerService.setLogo('assets/lib/images/svg/header-menu/manuals.svg')
    $(".loaders ol li").fadeOut();
  }

}
