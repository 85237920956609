import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { HeaderService } from '../../services/header.service';
import { ApiService } from '../../services/api.service';
import { Router } from "@angular/router";
declare var $: any;


export interface PeriodicElement {
  name: number;
  position: string;
  weight: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  { position: 'Mid Media Planning', name: 4, weight: '1/2/2019' },
  { position: 'Social Mobilization', name: 5, weight: '1/2/2019' },
  { position: 'Coverage', name: 4, weight: '1/2/2019' },
];

@Component({
  selector: 'app-user-gallery-management',
  templateUrl: './user-gallery-management.component.html',
  styleUrls: ['./user-gallery-management.component.css']
})
export class UserGalleryManagementComponent implements OnInit {
  displayedColumns: string[] = ['name', 'created_at'];
  dataSource: any;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.headerService.setTitle('Report Gallery');
    this.headerService.setLogo('assets/lib/images/svg/header-menu/gallery-color.svg');
    $(".loaders ol li").fadeIn();
    this.dataService
      .getAll<any[]>('api/gallery/all')
      .subscribe(
        (res: any) => {
          $(".loaders ol li").fadeOut();
          this.dataSource = new MatTableDataSource(res.data.records);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        },
        error => () => {
          console.log('Error')
        },
        () => {
        }
      );

  }


  navigateByUrl(row) {
    this.router.navigate(['gallery-management/visualization/', row.id, row.name])
  }

}
