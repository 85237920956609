import { Component, OnInit } from "@angular/core";
import { _ } from "underscore";
import * as Highcharts from "highcharts";
import { Input } from "@angular/core";
import patternFill from "highcharts/modules/pattern-fill";

patternFill(Highcharts);

@Component({
  selector: "app-dashboard-multi-series-graph",
  templateUrl: "./dashboard-multi-series-graph.component.html",
  styleUrls: ["./dashboard-multi-series-graph.component.css"],
})
export class DashboardMultiSeriesGraphComponent implements OnInit {
  @Input() categories: any;
  @Input() series: any;
  @Input() title: any;
  @Input() chartId: any;
  @Input() type: any;
  chart: any;
  svg: any;
  constructor() {}

  ngOnInit() {}
  ngOnChanges() {
    
    this.options.chart.type = this.type;
    this.options.xAxis.categories = this.categories;
    this.options.series = this.series;
    this.chart = new Highcharts.Chart(this.chartId, this.options);
    this.svg = this.chart.getSVG();


    this.series.forEach((row, index) => {
      if (row.area_name === "India") {
        this.options.series = {
          useHTML: true,
          style: {
            border: "1px solid red",
          },
        };
      }
    });
  }

  options: any = {
    chart: {
      type: "column",
    },
    colors: [{ patternIndex: 3 }, { patternIndex: 1, color: "#000000" }],
    xAxis: {
      crosshair: true,
    },
    yAxis: {
      title: {
        text: "",
      },
      stackLabels: {
        enabled: true,
      },
    },
    legend: {
      enabled: true,
    },

    plotOptions: {
      series: {
        animation: false,
        groupPadding: 0.2,
        pointPadding: 0,
        borderWidth: 1,
        borderColor: "black",
        dataSorting: {
          enabled: true,
          sortKey: "name",
        },
        type: "column",
        dataLabels: {
          enabled: true,
          format: "{point.y:.1f}", // one decimal
          y: -20, // 20 pixels down from the top
          x: 0,
          rotation: -90,
          style: {
            fontSize: "8px",
            fontFamily: "Verdana, sans-serif",
          },
          matchByName: true,
        },
      },
    },
    title: {
      text: null,
    },
    exporting: {
      enabled: false,
    },
    credits: { enabled: false },
    series: [
      {
        data: [0],
      },
    ],
  };
}
