import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Configuration } from '../../../app/app.constant';
@Injectable({
  providedIn: 'root'
})
 
export class AuthService {
  
  private url: string;
  constructor(private http: HttpClient,private router: Router,private configuration: Configuration) { 
    this.url = configuration.server;
  }

  
  login(email: string, password: string, rememberMe: string) {
   return this.http.post( this.url+ '/api/login', {email: email,password: password, remember:rememberMe});
  }

  logout() {    
    let login_time_row = localStorage.getItem('login_time_row');
 
    return this.http.post(this.url + '/api/logout', { login_time_row: login_time_row });

    }
 
    isLoggednIn(): boolean {
      return (localStorage.getItem('auth_token') !== null);
    }
 
}