import { Component, OnInit,AfterViewInit,ElementRef,ViewChild } from '@angular/core';
import { HeaderService } from '../../services/header.service';
declare var $ : any;
import { chart } from 'highcharts';
import * as Highcharts from 'highcharts';
declare var $ : any;
 
@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.css']
})
export class CommunicationComponent implements OnInit {

  //@ViewChild('chartTarget') chartTarget: ElementRef;
  
  chart: Highcharts.Chart;
  chartOptions;
  mapData = 'Data from Master';

  constructor(private headerService: HeaderService) { }

  ngOnInit() {
  	this.headerService.setTitle('Coverage');	
  }

  ngAfterViewInit() {
    
    this.chartOptions = {
      chart: {
        type: 'line'
      },
      title: {
        text: 'Fruit Consumption'
      },
      xAxis: {
        categories: ['Apples', 'Bananas', 'Oranges']
      },
      yAxis: {
        title: {
          text: 'Fruit eaten'
        }
      },
      series: [{
        name: 'Jane',
        data: [1, 0, 4]
      }, {
        name: 'John',
        data: [5, 7, 3]
      }]
    };
    


    //this.chart = chart(this.chartTarget.nativeElement, this.chartOptions);

     $(document).ready(function(){
         $('.app-multiselect-dropdown').multiselect({
                enableCollapsibleOptGroups: true,
                collapseOptGroupsByDefault: true,
                maxHeight: 200
            });
     });

    //this.chart = chart(this.chartTarget.nativeElement, this.chartOptions);
  }

  test() {
    this.mapData = "change Data";
  }

}
