import { Component, OnInit } from "@angular/core";
import { _ } from "underscore";
import * as Highcharts from "highcharts";
import { Input } from "@angular/core";
import patternFill from "highcharts/modules/pattern-fill";
import { data } from "jquery";
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let Highcharts3D = require('highcharts/highcharts-3d');

Boost(Highcharts);
noData(Highcharts);
Highcharts3D(Highcharts);
More(Highcharts);

@Component({
  selector: "app-pie-chart-graph",
  templateUrl: "./pie-chart-graph.component.html",
  styleUrls: ["./pie-chart-graph.component.css"],
})
export class PieChartGraphComponent implements OnInit {
  @Input() categories: any;
  @Input() series: any;
  @Input() title: any;
  @Input() chartId: any;
  @Input() type: any;
  chart: any;
  svg: any;
  constructor() {}
  pieData = [];
  public listseries = [];
  ngOnInit() {}
  ngOnChanges() {
    let that = this;

    // this.options.chart.type = this.type;
    // this.options.xAxis.categories = this.categories;
    that.pieData;
    that.series.forEach((row) => {
      that.pieData.push({
        name: row.indicator,
        y: parseFloat(row.value),
      });
    });
    that.chart = new Highcharts.Chart(that.chartId, this.options);
    that.svg = that.chart.getSVG();
  }

  options: any = {
    chart: {
      type: "pie",
     options3d: {
      enabled: true,
      alpha: 60,
      beta: 0,
      depth: 60,
      viewDistance: 25
    }
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      valueSuffix: "%",
    },

    plotOptions: {
      pie: {
        depth: 50
        // showInLegend: false,
    
      },
      accessibility: {
        announceNewData: {
            enabled: true
        },
        point: {
            valueSuffix: '%'
        }
    },
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        borderRadius: 5,
        dataLabels: [
          {
            enabled: true,
            distance: 20,
            format: '{point.y}'
          },
          {
            enabled: true, inside: true,
            distance: 10,
            format: "{point.y}",
            style: {
              fontSize: "1.2em",
              textOutline: "none",
        
            },
            filter: {
              operator: ">",
              property: "percentage",
              value: 10,
            },
            
            
          },
          
        ],
      },
    },
    title: {
      text: null,
    },
    exporting: {
      enabled: false,
    },
    credits: { enabled: false },
    series: [
      {
        name: 'Percentage',
        colorByPoint: true,
        data: this.pieData,
      },
    ],
  };
}
