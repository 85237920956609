import { Component, OnInit, Injectable, AfterViewInit } from "@angular/core";
import { HeaderService } from "src/app/services/header.service";
import { HttpClient } from "@angular/common/http";
import { _ } from "underscore";
import { ApiService } from "src/app/services/api.service";
import { Configuration } from "../../app.constant";
import * as Highcharts from "highcharts";
import { Input } from "@angular/core";
import { VisualizationRendererService } from "../../services/visualization-renderer";

declare var $: any;

@Component({
  selector: "app-graph-sheet",
  templateUrl: "./graph-sheet.component.html",
  styleUrls: ["./graph-sheet.component.css"],
})
export class GraphSheetComponent implements OnInit {
  @Input() item: any;
  @Input() chartId: any;
  @Input() type: any;
  @Input() color: any;

  chart: any;
  category = [];
  datas = [];
  penta: any;
  barColor = [];


  areaID = "IND";
  private url: any;
  timePeriod: any;


  constructor(
    private headerService: HeaderService,
    private http: HttpClient,
    private configuration: Configuration,
    private dataService: ApiService,
    public vizService: VisualizationRendererService
  ) {
    //this.url = configuration.server;
  }

  ngOnInit() {

  }


  ngOnChanges() {
    // const parent_div = document.getElementById('chart');
    // parent_div.setAttribute('id', this.chartId);

    let that = this;
    if (that.item) {
      this.item;
      //console.log(this.item)

      //this.options.xAxis.type = this.category;


      let mr2 = [];

      that.item.forEach((row, index) => {

        let value = parseFloat(row[1]);
        if (index == 0) {
          mr2.push({
            name: row[0], y: value, color: '#ffc000'
          });
         
        } else if 
          (value < 0) {
            mr2.push({
              name: row[0], y: value, color: '#72ae4a'
            });
        
        } else {
          mr2.push({
            name: row[0], y: value
          });
        }

      });

      that.options.chart.type = that.type;
      //   that.options.series.color= that.color;

      that.barColor = that.color;
      if (that.options.chart.type == 'bar') {
        that.options.colors = ["#BC0000"];
        that.options.plotOptions.series =
        {
          negativeColor: '#92D050',
          dataLabels: {
            format: '{point.y:.1f}', // one decimal
           // y: -10, // 10 pixels down from the top
            style: {
            fontSize: '10px',
            fontFamily: 'Verdana, sans-serif'
        },
        }
        };
      

      }

      that.options.series = [
        {
          data: mr2,
          showInLegend: false,
          dataSorting: {
            enabled: true,
            sortKey: 'y'
          },
          dataLabels: {
            enabled: true,
            // format: '{point.y:.1f}', // one decimal
            // y: -10, // 10 pixels down from the top
            // rotation: -60,
            // style: {
            //     fontSize: '10px',
            //     fontFamily: 'Verdana, sans-serif'
            // }
        }
        }
      ];
      that.chart = new Highcharts.Chart(that.chartId, that.options);
    }
  }

  options: any = {
    chart: {
      type: "column",
    },
    colors: ['#002060'],

    xAxis: {
      type: 'category',
      labels: {
        enabled: true
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      stackLabels: {
        enabled: true
      }
    },
    legend: {
      enabled: false
   },

    plotOptions: {
      colors: ['#002060'],
      series: {
        animation: false,
        groupPadding: 0,
        pointPadding: 0.1,
        borderWidth: 0,
        colorByPoint: true,
        dataSorting: {
          enabled: true,
          sortKey: 'y'
        },
        type: 'column',
        dataLabels: {
          enabled: true,
          format: '{point.y:.1f}', // one decimal
          y: -20, // 10 pixels down from the top
          rotation: -45,
          style: {
              fontSize: '9px',
              fontFamily: 'Verdana, sans-serif'
          },
          matchByName: true
        }
      }
    },
    title: {
      text: null
    },
    exporting: {
      enabled: false
    },
    credits: { enabled: false },
    series: [
      {

        data: [0],

      },
    ],
  };
}
