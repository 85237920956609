import { Component, OnInit, Input } from "@angular/core";
import { HeaderService } from "src/app/services/header.service";
import { Subscription } from "rxjs";
import { Configuration } from "../../../../app.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: 'app-hmistable2',
  templateUrl: './hmistable2.component.html',
  styleUrls: ['./hmistable2.component.css']
})
export class Hmistable2Component implements OnInit {
  @Input() timePeriod: any;
  subscription: Subscription;
  private url: any;
  public penta = [];
  loading =true;
  public mapLevel = 1;
  public source = 1;
  infant = {};
  areaID = "IND";
  area_name = "IND";

  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private configuration: Configuration,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.url = configuration.server;
    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "header":
          this.source = 1;
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.getTable();
          break;
          default:
          break;
      }
    });
  }

  ngOnInit() {}

  ngOnChanges() {   
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getTable();
  }

  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodChar(timePeriod);
  }

  getFinancialYear(timePeriod) {
    return this.commonService.getFinancialYear(timePeriod);
  } 

  getRoundValue(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  getPerValue(numerator, denominator) {
    if(denominator == 0){
      return 0
    }
    var value = this.getRoundValue((numerator / denominator) * 100, 1);
    if (value) {
      return value;
    }
    return 0;
  }

  getTable() {
    let that = this;
    this.dataService
      .getAll("api/getHMISTable2/" + this.timePeriod + "/" + this.areaID + "/")
      .subscribe((response: any) => {
        that.loading =false;
        that.penta = response.data;
        that.area_name = that.penta[0].area_name;       

        response.infant.forEach((row) => {
          that.infant[row.area_name] = row.target;
        });
      });
  }

  arr: any;
  getinfant(area_name) {
    if (this.infant[area_name]) {
      this.arr = this.infant[area_name];
      return this.arr;
    } else {
      return "NA";
    }
  }
 
  getObjectKey(object) {
    if (object) {
      return Object.keys(object);
    }
    return [];
  }
}
