import { Component, OnInit} from "@angular/core";
import { HeaderService } from "../../services/header.service";
import { Configuration } from "../../app.constant";
import { ApiService } from "../../services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { Subscription, } from "rxjs";
import { CommonService } from "src/app/services/common.service";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
declare var $: any;

@Component({
  selector: "app-administered-vaccine",
  templateUrl: "./administered-vaccine.component.html",
  styleUrls: ["./administered-vaccine.component.css"],
})
export class AdministeredVaccineComponent implements OnInit {
  subscription: Subscription;
  private url: any;
  areaID = "IND";
  area_name = "";
  timePeriod: any;
  selectedtp: "mrd";
  public mapRecentTimePeriod = [];
  public categories = [];
  public series = [];
  public indicators = [];

  public categories2 = [];
  public series2 = [];
  public indicators2 = [];

  public categories3 = [];
  public series3 = [];
  public indicators3 = [];

  public categories4 = [];
  public series4 = [];
  public indicators4 = [];

  public mapLevel = 1;
  constructor(
    private headerService: HeaderService,
    private configuration: Configuration,
    private dataService: ApiService,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.getTimeperiods();
    this.url = configuration.server;
    this.subscription = this.shareData.getData().subscribe((message) => {     
      switch (message.data.ctype) {
        case "sidebar":
          this.getGraphData();
          break;
        case "header":
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.area_name = message.data.data.name;
          this.getGraphData();
          break;
        default:
          break;
      }
    });
  }
  ngOnInit() {
    this.headerService.setTitle("Co-administered Vaccine Doses Status");
    this.headerService.setLogo("assets/lib/images/svg/sidebar/data-import.svg");
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getGraphData();
  }
  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodChar(timePeriod);
  }
  changeTimePeriod(event, type) {
    this.timePeriod = event.value;
    this.getGraphData();
  }
  getValue(data, indicator, area_name) {
    let value = "0";
    data.forEach((row) => {
      if (row.indicator == indicator && row.area_name == area_name) {
        value = row.value;
        return;
      }
    });
    return parseFloat(value);
  }

  getSeries(data, indicators, category) {
    let that = this;
    let series = [];
    indicators.forEach((indicator) => {
      let temp = [];
      category.forEach((area_name) => {
        temp.push(that.getValue(data, indicator, area_name));
      });
      series.push({
        name: indicator,
        data: temp,
      });
    });
    return series;
  }

  getGraphData() {
    let that = this;
    $(".loaders ol li").fadeIn();
    this.dataService.getAll("api/administeredvaccine/" + this.timePeriod + "/" + this.areaID + "/").subscribe((response: any) => {
        this.mapRecentTimePeriod = response.time_period;
        // graph 1
        let category = [];
        response.data.forEach((row) => {
          if (category.indexOf(row.area_name) == -1) {
            category.push(row.area_name);
          }
          if (that.indicators.indexOf(row.indicator) == -1) {
            that.indicators.push(row.indicator);
          }
        });
        that.series = that.getSeries(response.data, that.indicators, category);
        that.categories = category;

        // graph 2
        let category2 = [];
        response.data1.forEach((row) => {
          if (category2.indexOf(row.area_name) == -1) {
            category2.push(row.area_name);
          }
          if (that.indicators2.indexOf(row.indicator) == -1) {
            that.indicators2.push(row.indicator);
          }
        });
        that.series2 = that.getSeries(
          response.data1,that.indicators2,category2);
        that.categories2 = category2;

        // graph 3
        let category3 = [];
        response.data2.forEach((row) => {
          if (category3.indexOf(row.area_name) == -1) {
            category3.push(row.area_name);
          }
          if (that.indicators3.indexOf(row.indicator) == -1) {
            that.indicators3.push(row.indicator);
          }
        });
        that.series3 = that.getSeries(
          response.data2,
          that.indicators3,
          category3
        );
        that.categories3 = category3;

        // graph 4
        let category4 = [];
        response.data3.forEach((row) => {
          if (category4.indexOf(row.area_name) == -1) {
            category4.push(row.area_name);
          }
          if (that.indicators4.indexOf(row.indicator) == -1) {
            that.indicators4.push(row.indicator);
          }
        });
        that.series4 = that.getSeries(
          response.data3,
          that.indicators4,
          category4
        );
        that.categories4 = category4;

        $(".loaders ol li").fadeOut();
        if (!that.timePeriod || that.timePeriod == "mrd") {
          that.timePeriod = this.mapRecentTimePeriod[0].time_period;
        }
      });
  }

  public captureScreen() {
    let downLoadSection = document.getElementById("administer") as HTMLElement;
    html2canvas(downLoadSection, { scale: 1 }).then(function (canvas) {
      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + top_left_margin * 2;
      let PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      canvas.getContext("2d");
      let imgData = canvas.toDataURL("image/svg", 1.0);
      let pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
      pdf.addImage(
        imgData,
        "SVG",
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height
      );
      pdf.save("administer.pdf");
    });
  }
  getTimeperiods() {
    this.dataService.getAll("api/month/" + this.areaID + "/").subscribe((response: any) => {
        this.mapRecentTimePeriod = response.time_periods;
        this.selectedtp = this.mapRecentTimePeriod[0].time_period;
      });
  }
}
