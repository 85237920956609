import { ApiService } from '../../services/api.service';
import { Component, ViewChild, OnInit, ViewContainerRef, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { FormControl, FormGroup, ReactiveFormsModule, FormsModule, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { HeaderService } from '../../services/header.service';
import { DialogMaterialComponent } from '../../dialog-material/dialog-material.component';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { element } from '@angular/core/src/render3';
import { SelectionModel } from '@angular/cdk/collections';
import { TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions, TreeComponent, ITreeState } from 'angular-tree-component';
import { SharedataService } from '../../services/sharedata.service';
import { TreeModel, TreeNode } from 'angular-tree-component';
import { DatatableComponent } from '@swimlane/ngx-datatable';
declare var $: any;

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})


export class UserComponent implements OnInit {
  state: ITreeState;
  isDisabled = true;
  nodes = [];
  rowId;
  area;
  options: ITreeOptions = {
    actionMapping: {
      mouse: {

        click: (tree, node, $event) => {
          
          this.sendData(node.data);
          this.addUserForm.get('areaName').patchValue(node.data.name);
          this.area = node.data.id
          $('#select_area').html(node.data.id);
          $('.area-list').removeClass('open');
        }
      },
    },
  }

  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  selectedDevice;
  disableArea = true;
  disableDistrict = true;
  disableEmail = false;
  areaCode;
  filterData: any [];
  editUserArea;
  roleName;
  editUserDistrict;
  form_open: boolean = false;
  showEdit: boolean = false;
  titlePage: string = 'Add';
  addUserForm: FormGroup;
  states = [];
  roles: object;
  userRole = [];
  districts = [];
  state_code: any;
  displayedColumns = ['select', 'name', 'email', 'role', 'state', 'district'];
  dataSource: MatTableDataSource<any>;
  validationError: any;
  selection = new SelectionModel<any>(true, []);
  selectedItemsIds = [];
  selectedItems: any = [];
  checkAllChild: boolean = false;
  list = [];

  rows: any = [];
  columns = [
    {
      name: 'Name',
      prop: 'area'
    },
    {
      name: 'Email',
      prop: 'indicator'
    },
    {
      name: 'Role',
      prop: 'unit'
    },
    {
      name: 'State',
      prop: 'subgroup'
    },
    {
      name: 'District',
      prop: 'source'
    }
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('selectElem') selectElem: ElementRef;
  @ViewChild('tree') tree: TreeComponent;
  /**
   * constructor to intialise all the required libraries
   * @param dataService 
   * @param formBuilder 
   * @param headerService 
   * @param dialog 
   * @param matsnack 
   * @param toastr 
   */
  constructor(
    private dataService: ApiService,
    public formBuilder: FormBuilder,
    private headerService: HeaderService,
    public dialog: MatDialog,
    public matsnack: MatSnackBar,
    private toastr: ToastrService,
    private shareData: SharedataService,
  ) {
  }
  ngOnInit() {

    

    
    // let node = this.tree.treeModel.getFocusedNode().data;
   
    this.headerService.setTitle('User Management');
    this.headerService.setLogo('assets/lib/images/svg/header-menu/user.svg');
    this.getAreaList();
    this.createAddUserForm();
    //this.headerService.setTitle('Data Summary');
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('need-head');
    /**
     * api call to get all the records from dataService
     */
    this.getAllUsers();

  }
  /**
 * Set the paginator and sort after the view init since this component will
 * be able to query its view for the initialized paginator and sort.
 */
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }
  /**
   * method that execute on changing the value of matInput
   * @param filterValue 
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  /**
   * function to create addUser form
   */
  createAddUserForm() {
    this.addUserForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required,
      Validators.pattern('[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})')]],
      password:['', [Validators.required,
        Validators.pattern('[A-Za-z0-9]+')]],
      contact_no: ['', [Validators.required,
      Validators.pattern('[0-9]+')]],
      // role: ['', Validators.required],
      area: [],
      areaName:[],

      level: [],
      // district: [''],
    });


  }

  /**
   * function to add a new user 
   * @param formVal // data of the form
   */
  public addNewUser(formVal) {

    this.validationError = [];
    this.dataService.add('api/user/add', formVal).subscribe((response: any) => {
      if (response.status == 0) {
        this.validationError = response.error;
        
      }
      else {
        this.toastr.success(response.message);
        // reset the form after submitting data
        this.addUserForm.reset();
        this.clearFormGroupDirective();
        setTimeout(() => {
          this.form_open = !this.form_open;
        }, 2000);
        //function to get the list of users after adding a user
        this.getAllUsers();
      }
    });
  }

  /**
   * function to get form value data
   */
  getFormValuesData() {
    this.dataService.getAll('api/get-form-values').subscribe((response: any) => {
      this.states = response.data.states;
      this.roles = response.data.userRoles;
      this.states.forEach(element => {
        this.state_code = element.area_code;
      });

    })
  }
  /**
   * function to get the list of all users
   */
  getAllUsers() {
    $(".loaders ol li").fadeIn();
    this.getFormValuesData();
    // api call to get the users from service
    this.dataService
      .getAll<any[]>('api/user/all')
      .subscribe(
        (res: any) => {
          $(".loaders ol li").fadeOut();
          this.rows = res.data.records;
          // this.filterData = [...res.data.records];
         
        },
        error => () => {
          console.log('Error')
        },
        () => {
          //this.toasterService.pop('success', 'Complete', 'Getting all values complete');
          //this.slimLoadingBarService.complete();
        }
      );
  }
  /**
   * function to edit the user data
   * @param id // id of selected user
   */
  // editUserData(id) {
  //   this.dataService.getUserById( '',id).subscribe((response: any) => {

  //   })
  // }
  deleteUserData(id) {
    const dialog = this.dialog.open(DialogMaterialComponent, {
      width: '350px',
      data: { messageDialog: 'Are you sure to delete user?', delete: true }
    });
    dialog.afterClosed()
      .subscribe(selection => {
        if (selection) {
          this.dataService.delete('api/user/delete', id).subscribe((response: any) => {
            // show toaster message on successfully deleting the user
            this.toastr.success(response.message);
            // get all the users after deleting the user
            this.getAllUsers();
          })
        } else {
          // User clicked 'Cancel' or clicked outside the dialog
        }
      });

  }
  /**
   * function to open the form popup to edit the selected the user
   * @param id // id of selected user
   */
  openForm(rowData) {
  
    $('#error_text').html('');
    this.form_open = !this.form_open;
    if (rowData !== undefined) {
      this.titlePage = 'Edit';
      this.showEdit = true;
      this.disableEmail = true;
      // patch the form value  form the response to  form fields
      this.addUserForm.patchValue({
        id: rowData.id,
        name: rowData.name,
        email: rowData.email,
        password:rowData.password,
        contact_no: rowData.contact_no,
        role: rowData.role.toString(),
        area: rowData.current_area.area_code,
        areaName:rowData.current_area.area_name,
        district: rowData.area_id,
        level:rowData.current_area.area_level

      });
      this.rowId = rowData.id

    }

    else {
      this.titlePage = 'Add'
      this.showEdit = false;
      this.disableEmail = false;

      this.addUserForm.reset();
      $('#select_area').html('Select location');

    }
  }

  editSelectedUser(formVal) {
    
    this.dataService.update('api/user/update', this.rowId, formVal).subscribe((response: any) => {
      this.toastr.success(response.message);
      // reset the form after submitting data
      this.addUserForm.reset();
      this.clearFormGroupDirective();
      setTimeout(() => {
        this.form_open = !this.form_open;
      }, 2000);
      //function to get the list of users after adding a user
      this.getAllUsers();
    })
  }

  /**
   * function to delete all the selected rows
   * @param event 
   */
  public confirmDeleteAll() {
   let selectedRows = {
      selectedIds: this.selectedItems
    }
    const dialog = this.dialog.open(DialogMaterialComponent, {
      width: '350px',
      data: { messageDialog: 'Are you sure to delete user?', delete: true }
    });
    dialog.afterClosed()
      .subscribe(selection => {
        if (selection) {
          this.dataService.add('api/user/delete-all-user', selectedRows).subscribe((response: any) => {
            if (response.status == 1) {
              this.toastr.success("User deleted successfully")
            }
            this.selectedItems = [];
            setTimeout(() => {
              this.ngOnInit();
            }, 200);

          }, (error: any) => {
            console.log(error);
          });
        } else {
          // User clicked 'Cancel' or clicked outside the dialog
        }
      });
  }


  clearFormGroupDirective() {
    this.formGroupDirective.resetForm();
  }
  getAreaList() {
    let that=this;
    this.dataService.getAll('api/get-area-list').subscribe((response: any) => {
      this.nodes = response.data.area_list;
      for (let i = 0; i < that.nodes[0].children.length; i++) {
        that.nodes[0].children[i].children=[];
      }
    })
  }
  sendData(data): void {
    
    // this.state.selectedNodeIds = data.code;
    this.addUserForm.get('area').setValue(data.code)
    this.addUserForm.get('level').setValue(data.level);
    // this.shareData.sendData({'ctype':'header','data':data});
  }
  
  onSelect({ selected }) {
    this.selectedItems = [];
    this.selectedItems.splice(0, this.selectedItems.length);
    if (selected.length == 1) {
      this.selectedItems.push(selected[0].id);
    } else if (selected.length > 1) {
      for (let i = 0; i < selected.length; i++) {
        this.selectedItems.push(selected[i].id);
      }
    }
  }

  public onScrollEvent(event: any): void {
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.rows.filter(function (d) {
     
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
      
    });
    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }
  expandArea() {
    //this.tree.treeModel.expandAll(); 
    if (typeof this.tree != 'undefined') {
      const firstNode =
        this.tree.treeModel.getNodeById('1');
      firstNode.expand();
    }
    //const firstRoot = this.tree.treeModel.roots[0]; 
    //firstRoot.setActiveAndVisible(); 
  }
}

