import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { Component, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { HeaderService } from '../../services/header.service';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DialogMaterialComponent } from '../../dialog-material/dialog-material.component';
import { SelectionModel } from '@angular/cdk/collections';
import { DatatableComponent } from '@swimlane/ngx-datatable';
declare var $: any;


@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.css']
})

export class FacilityComponent implements OnInit {
  disableField = true;
  showFacilityForm: boolean = false;
  state_code: any;
  district_code: any;
  states = [];
  districts = [];
  subDistricts = [];
  dashboardId;
  form_open: boolean = false;
  addForm: FormGroup;
  dashboardRecords = [];
  ourFile: File;
  base64Image = '';
  showEdit: boolean = false;
  titlePage: string;
  validationError: any;
  facilityId: number;
  selection = new SelectionModel<any>(true, []);
  public selectedItems: any = [];
  checkAllChild: boolean = false;

  facilityCenter = [{
    id: 1,
    name: 'Rural'
  },
  {
    id: 2,
    name: 'Urban'
  },
  ]
  displayedColumns = ['select', 'facility_name', 'facility_center', 'state', 'district', 'sub_district', 'latitude', 'longitude', 'created_at'];
  dataSource: MatTableDataSource<any>;

  rows: any =[];
  columns = [
    { 
      name: 'Facility Name',
      prop: 'area'
    },
    { 
      name: 'Facilty Centre',
      prop: 'indicator'
    },
    { 
      name: 'State',
      prop: 'unit' 
    },
    { 
      name: 'District',
      prop: 'subgroup'
    },
    { 
      name: 'Sub District',
      prop: 'source'
    },
    { 
      name: 'Latitude',
      prop: 'time_period' 
    },
    { 
      name: 'Longitude',
      prop: 'value'
    },
    {
      name: 'Created On',
      prop: 'id'
    },
    {
      name: 'Options'
    }
  ];

  filterData: any = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private dataService: ApiService,
    private headerService: HeaderService,
    private form: FormBuilder,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.dashboardId = params['id'];
    });
    this.headerService.setTitle('Facility Management');
    this.headerService.setLogo('assets/lib/images/svg/sidebar/home-u.svg');
    //this.headerService.setTitle('Data Summary');
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('need-head');
    this.addForm = this.form.group({
      facility_name: ['', Validators.required],
      facility_address: ['', Validators.required],
      latitude: ['', Validators.required,],
      longitude: ['', Validators.required],
      state: [{ value: '', disabled: true }, Validators.required],
      district: [{ value: '', disabled: true }, Validators.required],
      sub_district: [{ value: '', disabled: true }, Validators.required],
      contact_person: ['', Validators.required],
      contact_address: ['', Validators.required],
      facility_center: ['', Validators.required]
    });
    this.getFormValuesData();
    this.getAll();
  }


  /**
   * method that execute on changing the value of matInput
   * @param filterValue 
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  /**
   * method that executes  to intiliaze the table with data
   */
  getAll() {
  	/**
     * api call to get all the records from dataService
     */
    $(".loaders ol li").fadeIn();
    this.dataService
      .getAll<any[]>('api/facility/all')
      .subscribe(
        (res: any) => {
          $(".loaders ol li").fadeOut();
          this.filterData = [...res.data.records];
          this.rows=res.data.records;
          // this.dataSource = new MatTableDataSource(res.data.records);
          // setTimeout(() => {
          //   this.dashboardRecords = res.data.records;
          //   this.dataSource.sort = this.sort;
          //   this.dataSource.paginator = this.paginator;
          // });
        },
        error => () => {
          console.log('Error')
        },
        () => {
          //this.toasterService.pop('success', 'Complete', 'Getting all values complete');
          //this.slimLoadingBarService.complete();
        }
      );
  }



  /**
   *  method that executes on updating form
   */
  update() {
    this.validationError = [];
    //make an api call to update the form
    this.dataService
      .update<any[]>('api/facility/update', this.facilityId, this.addForm.value)
      .subscribe(
        (res: any) => {
          if (res.status == 0) {
            this.validationError = res.error;
          } else {
            // toaster message on successfully submitting data to the table
            this.toastr.success('Facility updated successfully');
            // reset the form after submitting form data
            this.addForm.reset();
            this.clearFormGroupDirective();

            setTimeout(() => {

              this.form_open = !this.form_open;
              this.showFacilityForm = !this.showFacilityForm
            }, 500);

            // get all the data  in the table
            this.getAll();
          }
        },
        error => () => {
          console.log('Error')
        },
        () => {
          //this.toasterService.pop('success', 'Complete', 'Getting all values complete');
          //this.slimLoadingBarService.complete();
        }
      );
  }
  clearFormGroupDirective() {
    this.formGroupDirective.resetForm();
  }

  /**
   * method that executes on  deletion of record
   * @param id // id of deleted record
   */
  deleteFacility(id) {
    const dialog = this.dialog.open(DialogMaterialComponent, {
      width: '350px',
      data: { messageDialog: 'Are you sure to delete record?', delete: true }
    });
    dialog.afterClosed()
      .subscribe(selection => {
        if (selection) {
          this.dataService.delete('api/facility/delete', id).subscribe((response: any) => {
            // show toaster message on successfully deleting the facility
            this.toastr.success('Facility deleted successfully');
            setTimeout(() => {
              this.getAll();
            }, 1000);
            // get all the facilities after deleting the facility

          })
        } else {
          // User clicked 'Cancel' or clicked outside the dialog
        }
      });
  }
  /**
   * function that open form popup
   * @param rowData // data of a record in mat table
   */
  openForm(rowData) {
    this.showFacilityForm = !this.showFacilityForm
    
    this.validationError = [];

    if (rowData !== undefined) {
      this.form_open = !this.form_open;
      this.titlePage = 'Edit'
      this.showEdit = true;
      this.addForm.patchValue({
        facility_name: rowData.name,
        facility_address: rowData.address,
        latitude: rowData.latitude,
        longitude: rowData.longitude,
        state: rowData.state.area_name,
        district: rowData.district.area_name,
        // sub_district: rowData.subdistrict.area_name,
        contact_person: rowData.contact_person,
        contact_address: rowData.contact_address,
        facility_center: rowData.facility_center
      });
      this.facilityId = rowData.id;
      if (rowData.state !== undefined) {
        this.dataService.getById('api/get-districts', rowData.state.area_code).subscribe((response: any) => {
          this.districts = response.data.districts;
        });
      }
      this.dataService.getById('api/get-districts', rowData.district.area_code).subscribe((response: any) => {
        this.subDistricts = response.data.districts;
      });
    }

  }
  closeForm() {
    this.form_open = !this.form_open;
    this.showFacilityForm = !this.showFacilityForm
  }
  /**
   * function to get the states and districts data
   */
  getFormValuesData() {
    //make an api call to get states and districts data
    this.dataService.getAll('api/get-form-values').subscribe((response: any) => {
      this.states = response.data.states;
      this.states.forEach(element => {
        this.state_code = element.area_code;
      });
    })
  }
  /**
   * function to get the data of districts
   * @param event 
   */
  public getDistricts(event) {
    this.states.forEach(element => {
      if (element.id == event.value)
        this.state_code = element.area_code;
    });
    // api call to get districts
    this.dataService.getById('api/get-districts', this.state_code).subscribe((response: any) => {
      this.districts = response.data.districts;
    });
  }
  public getSubDistricts(event) {
    this.districts.forEach(element => {
      if (element.id == event.value)
        this.district_code = element.area_code;
    });
    // api call to get districts
    this.dataService.getById('api/get-districts', this.district_code).subscribe((response: any) => {
      this.subDistricts = response.data.districts;
    });
  }

  /**
   * function to delete all the selected records
   * @param event 
   */
  public confirmDeleteAll() {
    let selectedRows = {
      selectedIds: this.selectedItems
    }
    const dialog = this.dialog.open(DialogMaterialComponent, {
      width: '350px',
      data: { messageDialog: 'Are you sure to delete records?', delete: true }
    });
    dialog.afterClosed()
      .subscribe(selection => {
        if (selection) {
          this.dataService.add('api/facility/delete/all', selectedRows).subscribe((response: any) => {
            if (response.status == 1) {
              this.toastr.success("Facility deleted successfully")
            }
            this.selectedItems = [];
            setTimeout(() => {
              this.ngOnInit();
            }, 200);

          }, (error: any) => {
            console.log(error);
          });
        } else {
          // User clicked 'Cancel' or clicked outside the dialog
        }
      });
  }
 



  onSelect({ selected }) {
    this.selectedItems = [];
    this.selectedItems.splice(0, this.selectedItems.length);
    if(selected.length == 1){
      this.selectedItems.push(selected[0].id);
    }else if(selected.length > 1){
      for(let i=0; i<selected.length; i++){
        this.selectedItems.push(selected[i].id);
      }
    }
  }

  updateFilter(event) {
      const val = event.target.value.toLowerCase();

      // filter our data
      const temp = this.filterData.filter(function(d) {
        return Object.keys(d).some(function(key){
          if(d[key] != null){
            return d[key].toString().toLowerCase().indexOf(val) !== -1 || !val;
          }          
        });
      });
      // update the rows
      this.rows = temp;
      // Whenever the filter changes, always go back to the first page
      this.table.offset = 0;
    }
}
