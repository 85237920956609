import { Component, OnInit, Injectable, AfterViewInit, Input } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { Configuration } from '../../app.constant';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { SharedataService } from 'src/app/services/sharedata.service';
import { Subscription, SubscriptionLike } from 'rxjs';
import { CommonService } from 'src/app/services/common.service';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
declare var $: any;

@Component({
  selector: 'app-immunization',
  templateUrl: './immunization.component.html',
  styleUrls: ['./immunization.component.css']
})
export class ImmunizationComponent implements OnInit {
  subscription: Subscription;
  private url: any;
  areaID = 'IND';
  area_name = 'IND';
  timePeriod: any;
  data = [];

  district = 'NA';
  nfhs5mcv1 = 'NA';
  infantTarget = 'NA';
  nfhs5fic ='NA';
  selectedtp:'mrd';
  public penta = [];
  public BCG = [];
  public OPV1 = [];
  public OPV3 = [];
  public Penta1 = [];
  public Penta3 = [];
  public MCV1 = [];
  public MCV2 = [];
  public DPT = [];
  public immu = [];
  public session = [];
  public bcg_mcv1 = [];
  public penta1_penta3 = [];
  public penta1_mcv1 = [];
  public penta1_rvv1 = [];
  public penta3_rvv3 = [];
  public Td10 =[];
  public Td16 =[];
  public mapLevel = 1;
  public nh = [];
  public source = 1;
  public mapRecentTimePeriod = [];
  public year: any;
  public infant = [];
  constructor(
    private headerService: HeaderService,
    private configuration: Configuration,
    private dataService: ApiService,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
        this.getTimeperiods();
        this.url = configuration.server;
    this.subscription = this.shareData.getData().subscribe(message => {
      switch (message.data.ctype) {
        case 'header':
          this.source = 1
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.getAllPenta();
          break;
        default:
          break;
      }
    })
  }

  ngOnInit() {
    this.headerService.setTitle('Routine Immunization Fact Sheet');
    this.headerService.setLogo('assets/lib/images/svg/sidebar/data-import.svg');
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getAllPenta();
  }

  getDate(timePeriod) {
    if (timePeriod.indexOf('-') > -1) {
      let index = timePeriod.indexOf("-");
      let firstDate = timePeriod.substr(0, index).split(".").reverse().join(".");
      let secondDate = timePeriod.substr(index + 1).split(".").reverse().join(".");
      return (firstDate + " " + secondDate);
    }
    else {
      return timePeriod;
    }
  }
  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodChar(timePeriod);
  }

  getFinancialYear(timePeriod) {
    return this.commonService.getFinancialYear(timePeriod);
  }

  getRoundValue(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  getPerValue(numerator, denominator) {
    var value = this.getRoundValue(((numerator / denominator) * 100), 1);
    if (value) {
      return value;
    }
    return 0;
  }

  changeTimePeriod(event, type) {
    this.timePeriod = event.value;
    this.getAllPenta();
  }

  getAllPenta() {
    let that = this;
    $(".loaders ol li").fadeIn();
    this.dataService.getAll('api/immunization/' + this.timePeriod + '/' + this.areaID + '/').subscribe((response: any) => {
      this.penta = response.data;
      that.area_name = that.penta[0].area_name;
      this.mapRecentTimePeriod = response.time_period;
      let BCG = [];
      let OPV1 = [];
      let OPV3 = [];
      let Penta1 = [];
      let Penta3 = [];
      let MCV1 = [];
      let MCV2 = [];
      let DPT = [];
      let immu = [];
      let session = [];
      let bcg_mcv1 = [];
      let penta1_penta3 = [];
      let penta1_mcv1 = [];
      let penta1_rvv1 = [];
      let penta3_rvv3 = [];
      let Td10 =[];
      let Td16 =[];

      that.penta.forEach((row) => {
        BCG.push([
          row.area_name, that.getPerValue(row.BCG_num, row.BCG_dem), row.area_code
        ]);
        OPV1.push([
          row.area_name, that.getPerValue(row.OPV1_num, row.OPV1_dem), row.area_code
        ]);
        OPV3.push([
          row.area_name, that.getPerValue(row.OPV3_num, row.OPV3_dem), row.area_code
        ]);
        Penta1.push([
          row.area_name, that.getPerValue(row.Penta1_num, row.Penta1_dem), row.area_code
        ]);
        Penta3.push([
          row.area_name, that.getPerValue(row.Penta3_num, row.Penta3_dem), row.area_code
        ]);
        MCV1.push([
          row.area_name, that.getPerValue(row.mcv1_num, row.mcv1_dem), row.area_code
        ]);
        MCV2.push([
          row.area_name, that.getPerValue(row.mcv2_num, row.mcv2_dem), row.area_code
        ]);
        DPT.push([
          row.area_name, that.getPerValue(row.DPT1_num, row.DPT1_dem), row.area_code
        ]);
        immu.push([
          row.area_name, that.getPerValue(row.immu_num, row.immu_dem), row.area_code
        ]);
        session.push([
          row.area_name, that.getPerValue(row.session_num, row.session_dem), row.area_code
        ]);
        bcg_mcv1.push([
          row.area_name, that.getPerValue(row.bcg_mcv1_num, row.bcg_mcv1_dem), row.area_code
        ]);
        penta1_penta3.push([
          row.area_name, that.getPerValue(row.penta1_penta3_num, row.penta1_penta3_dem), row.area_code
        ]);
        penta1_mcv1.push([
          row.area_name, that.getPerValue(row.penta1_mcv1_num, row.penta1_mcv1_dem), row.area_code
        ]);
        penta1_rvv1.push([
          row.area_name, that.getPerValue(row.penta1_rvv1_num, row.penta1_rvv1_dem), row.area_code
        ]);
        penta3_rvv3.push([
          row.area_name, that.getPerValue(row.penta3_rvv3_num, row.penta3_rvv3_dem), row.area_code
        ]);
        Td10.push([
          row.area_name, that.getPerValue(row.TD10_num, row.TD10_dem), row.area_code
        ]);
        Td16.push([
          row.area_name, that.getPerValue(row.TD16_num, row.TD16_dem), row.area_code
        ]);
      });


      $(".loaders ol li").fadeOut();
      this.BCG = BCG;
      this.OPV1 = OPV1;
      this.OPV3 = OPV3;
      this.Penta1 = Penta1;
      this.Penta3 = Penta3;
      this.MCV1 = MCV1;
      this.MCV2 = MCV2;
      this.DPT = DPT;
      this.immu = immu;
      this.session = session;
      this.bcg_mcv1 = bcg_mcv1;
      this.penta1_penta3 = penta1_penta3;
      this.penta1_mcv1 = penta1_mcv1;
      this.penta1_rvv1 = penta1_rvv1;
      this.penta3_rvv3 = penta3_rvv3;
      this.Td10 = Td10;
      this.Td16 = Td16;

      if (!that.timePeriod || that.timePeriod == 'mrd') {
        that.timePeriod = this.mapRecentTimePeriod[0].time_period
      }

      try {
        that.infant = response.infant[0].target;
        that.district = response.district[0].district;       
        that.nfhs5mcv1 = response.nfhs5mcv1[0].value;
        that.nfhs5fic = response.nfhs5fic[0].value;       
      } catch (e) {
        console.log(e);
      }
    })
  }

  getObjectKey(object) {
    if (object) {
      return Object.keys(object);
    }
    return [];
  }

  public captureScreen() {
    let downLoadSection = document.getElementById('immunization') as HTMLElement;
    html2canvas(downLoadSection).then(function (canvas) {
      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      canvas.getContext('2d');
      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], 'p');
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      pdf.save("Immunization_Sheet.pdf");
    });
  }
  getTimeperiods() {
    this.dataService.getAll("api/month/" + this.areaID + "/").subscribe((response: any) => {
        this.mapRecentTimePeriod = response.time_periods;
        this.selectedtp = this.mapRecentTimePeriod[0].time_period;
      });
  }
}
