import { Component, OnInit, AfterViewInit,ElementRef, ViewChild  } from '@angular/core';
import { HeaderService } from '../../services/header.service';

declare var $: any;

import { chart } from 'highcharts';
import * as Highcharts from 'highcharts';
import { SharedataService } from "../../services/sharedata.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-coverage',
  templateUrl: './coverage.component.html',
  styleUrls: ['./coverage.component.css']
})
export class CoverageComponent implements OnInit {

   checked : boolean = false;
  chart: Highcharts.Chart;
  chartOptions;
  subscription: Subscription;
	message: any;

  constructor(private headerService: HeaderService,private shareData: SharedataService) { 
      // subscribe to home component messages
      this.subscription = this.shareData.getData().subscribe(message => { this.message = message; 
        console.log(this.message)
            //this.getFacilityByState(this.message);
      });
  }

  changed(){
    this.checked=! this.checked;
  }
  
  ngOnInit() {
    //header title change
    this.headerService.setTitle('Coverage');	
  }

  ngAfterViewInit() {
     // multiselect
      $(document).ready(function(){
         $('.app-multiselect-dropdown').multiselect({
                enableCollapsibleOptGroups: true,
                collapseOptGroupsByDefault: true,
                maxHeight: 200,
                nonSelectedText: 'Time Period'
            });
     });    
  }

}
