import { Component, OnInit } from "@angular/core";
import { HeaderService } from "../../../services/header.service";
import { Configuration } from "../../../app.constant";
import { ApiService } from "../../../services/api.service";
import { ToastrService } from "ngx-toastr";
import { SharedataService } from "src/app/services/sharedata.service";
import { Subscription, SubscriptionLike } from "rxjs";
import { CommonService } from "src/app/services/common.service";
import * as htmlToImage from "html-to-image";
import { toSvg } from 'html-to-image';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ChangeEvent } from "@ckeditor/ckeditor5-angular/ckeditor.component";
declare var $: any;
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: "app-dashboard-main",
  templateUrl: "./dashboard-main.component.html",
  styleUrls: ["./dashboard-main.component.css"],
})
export class DashboardMainComponent implements OnInit {
  subscription: Subscription;
  private url: any;
  areaID = "IND";
  area_name = "IND";
  selectedtp: "mrd";
  public mapLevel = 1;
  public nh = [];
  public source = 1;
  timePeriod: any;
  public mapRecentTimePeriod = [];
  name: any = [];
  public penta = [];
  public imghtml = [];
  pageNo: any = "";
   elems = [];

  public Editor = ClassicEditor;
  constructor(
    private headerService: HeaderService,
    private configuration: Configuration,
    private dataService: ApiService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.getTable();
    this.url = configuration.server;
    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "header":
          this.source = 1;
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {

    var data=[]
    for (let i = 32; i <= 65; i++) { 
      data.push("page"+i);
    }
    this.elems=data;
    this.headerService.setTitle("DashBoard");
    this.headerService.setLogo("assets/lib/images/svg/sidebar/data-import.svg");
    this.areaID = this.commonService.getAreaID();
    this.getTable();

    $("#sidebarCollapse").on("click", function () {
      $("#sidebar").toggleClass("active");
      $(this).toggleClass("active");
    });

      //  this.create_images();
  }

  get_page_id(index){

    return "page" + (32+index);

  }
  ngAfterViewInit() {
    $(".loaders ol li").fadeOut();
  }

  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodChar(timePeriod);
  }

  changeTimePeriod(event, type) {
    this.timePeriod = event.value;

    this.getTable();
  }
  getTable() {
    let that = this;
    this.dataService.getAll("api/month/" + this.areaID + '/').subscribe((response: any) => {
      let name = [];
      that.penta = response.record;
      this.mapRecentTimePeriod = response.time_periods;
      this.selectedtp= this.mapRecentTimePeriod[0].time_period;
      if (!that.timePeriod) {
        that.timePeriod = this.mapRecentTimePeriod[0].time_period;
      }
      that.penta.forEach((row) => {
        name.push(row.area_code);
      });
      this.name = name;

    });
  }

  get_image_id(element) {
    return "thumb_" + element;
  }

  

  create_images() {
    this.elems.forEach((elem, index) => {
      htmlToImage.toSvg(document.getElementById(elem),{ quality: 0.2,height:150,cacheBust: true,})
          .then(function (dataUrl) {
            $("#thumb_" + elem).attr("src", dataUrl);
          })
          .catch(function (error) {
            console.error("oops, something went wrong!");
          });
    });
  }
  go_to_page(id: any) {
    const element: HTMLElement = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }


  public captureScreen() {
    let downLoadSection = document.getElementById('main_dashboard') as HTMLElement;
    html2canvas(downLoadSection,{scale:1}).then(function (canvas) {
      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + (top_left_margin * 2);
      let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      canvas.getContext('2d');
     
      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
      pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], 'p');
        pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
      }
      pdf.save("state.pdf");
    });
  
}




showNarrationModal(clear_value) {
  if (clear_value == 1) {
    //console.log(clear_value, "cvvbvn");
    // this.report_id = '';
    // this.object_id = "";
  }
  if (this.pageNo == 1 || this.pageNo == 73) {
    $("#myModal_pop").modal("show");
  } else {
    $("#narration_modal").modal("show");
}
}

public onChange({ editor }: ChangeEvent) {
  // this.editorHtml = "";
  const data = editor.getData();
  // this.editorHtml = data;
}
}

