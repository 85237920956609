import { Component, OnInit, Input } from "@angular/core";
import * as Highcharts from "highcharts";
import { VisualizationRendererService } from "../../../services/visualization-renderer";

@Component({
  selector: "app-dashboard-column-graph",
  templateUrl: "./dashboard-column-graph.component.html",
  styleUrls: ["./dashboard-column-graph.component.css"],
})
export class DashboardColumnGraphComponent implements OnInit {
  @Input() item: any;
  @Input() chartId: any;
  @Input() type: any;
  @Input() color: any;

  chart: any;
  category = [];
  datas = [];
  penta: any;
  barColor = [];

  areaID = "IND";
  private url: any;
  timePeriod: any;

  constructor(
    public vizService: VisualizationRendererService
  ) {
  
  }

  ngOnInit() {}

  ngOnChanges() {
    let that = this;
    if (that.item) {
      this.item;

      let mr2 = [];

      that.item.forEach((row) => {
        let value = parseFloat(row[1]);
        if (value < 0) {
          mr2.push({
            name: row[0],
            y: value,
            color: "#595959",
          });
        } else if (value >= 0 && value <= 4.9) {
          mr2.push({
            name: row[0],
            y: value,
            color: "#FFFF00",
          });
        } else if (value >= 5 && value < 9.9) {
          mr2.push({
            name: row[0],
            y: value,
            color: "#FFC000",
          });
        } else {
          mr2.push({
            name: row[0],
            y: value,
            color: "#EE1300",
          });
        }
      });

      that.options.chart.type = that.type;

      that.barColor = that.color;

      that.options.series = [
        {
          data: mr2,
          showInLegend: false,
          dataSorting: {
            enabled: true,
            sortKey: "y",
          },
          dataLabels: {
            enabled: true,
          },
        },
      ];
      that.chart = new Highcharts.Chart(that.chartId, that.options);
    }
  }

  options: any = {
    chart: {
      type: "bar",
    },
    colors: ["#D9D9D9"],

    xAxis: {
      type: "category",
      labels: {
        enabled: true,
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      stackLabels: {
        enabled: true,
      },
    },
    legend: {
      enabled: false,
    },

    plotOptions: {
      colors: ["#D9D9D9"],
      series: {
        animation: false,
        groupPadding: 0.1,
        pointPadding: 0.1,
        borderWidth: 1,
        borderColor: "black",
        colorByPoint: true,
        dataSorting: {
          enabled: true,
          sortKey: "y",
        },
        type: "bar",
        dataLabels: {
          enabled: true,
          format: "{point.y:.1f}", // one decimal
          style: {
            fontSize: "9px",
            fontFamily: "Verdana, sans-serif",
          },
          matchByName: true,
        },
      },
    },
    title: {
      text: null,
    },
    exporting: {
      enabled: false,
    },
    credits: { enabled: false },
    series: [
      {
        data: [0],
      },
    ],
  };
}
