import { Component, OnInit, Injectable, AfterViewInit } from '@angular/core';
import { Fileupload } from 'blueimp-file-upload/js/jquery.fileupload';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";
import { HeaderService } from '../../services/header.service';
import { Configuration } from '../../app.constant';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';


declare var $: any;

@Component({
    selector: 'app-flipbook-management',
    templateUrl: './flipbook-management.component.html',
    styleUrls: ['./flipbook-management.component.css'],
})
export class FlipbookManagementComponent implements OnInit {
    selectedFiles: FileList;
    fileName: string;

    constructor(
        private headerService: HeaderService,
        private conf: Configuration,
        private toastr: ToastrService,
    ) { }



    ngOnInit() {
        $(".loaders ol li").fadeOut();

        this.headerService.setTitle("Flipbook Management");
        this.headerService.setLogo("assets/lib/images/svg/report-mgm.svg");
        //this.headerService.setTitle('Data Summary');

        // $(function () {

        //     'use strict';
            // // Change this to the location of your server-side upload handler:
            // var url = that.conf.server+'api/report/upload-flipbook';

            // $('#flipbook_pdf').fileupload({
            //     maxChunkSize: 2000000,
            //     acceptFileTypes: /(\.|\/)(pdf)$/i,
            //     url: url,
            //     dataType: 'json', 
            //     add: function (e, data) {            
            //       $("#upload").off('click').on('click', function () {
            //           data.submit();
            //       });
            //     },           
            //     beforeSend: function(xhr, data) {
            //         xhr.setRequestHeader('Accept', 'application/json');
            //         xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('auth_token')}`);
            //     },
            //     done: function (e, data) {

            //     },
            //     progressall: function (e, data) {
            //         var loaded : number = data.loaded;
            //         var total : number = data.total;
            //         var tempCalc  = (loaded / total * 100);

            //         var progress = parseInt( tempCalc.toString(), 10);

            //         $('#progress .progress-bar').css(
            //             'width',
            //             progress + '%'
            //         );
            //     }
            // }).prop('disabled', !$.support.fileInput)
            //     .parent().addClass($.support.fileInput ? undefined : 'disabled');
        // });



    }
    ngAfterViewInit() {
        let that = this;
        $(document).on('change', '#flipbook_pdf', function (e) {
            console.log(e, 'xcvb')
            that.detectFiles(e);
        });
        // Change this to the location of your server-side upload handler:
        var url = that.conf.server + 'api/report/upload-flipbook';
        $(".viz-loader ol li").fadeIn();
        $('#flipbook_pdf').fileupload({
            maxChunkSize: 2000000,
            acceptFileTypes: /(\.|\/)(pdf)$/i,
            url: url,
            dataType: 'json',
            add: function (e, data) {
                console.log(data, 'cvb vn')
                $("#upload").off('click').on('click', function () {
                    alert();
                    data.submit();
                });
            },
            beforeSend: function (xhr, data) {
                xhr.setRequestHeader('Accept', 'application/json');
                xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('auth_token')}`);
            },
            done: function (e, data) {

            },
            stop: function() {
                $(".viz-loader ol li").fadeOut();
                that.fileUploadMessage();               
              },
            progressall: function (e, data) {
                var loaded: number = data.loaded;
                var total: number = data.total;
                var tempCalc = (loaded / total * 100);

                var progress = parseInt(tempCalc.toString(), 10);

                $('#progress .progress-bar').css(
                    'width',
                    progress + '%'
                );
            }
        }).prop('disabled', !$.support.fileInput)
            .parent().addClass($.support.fileInput ? undefined : 'disabled');

    }
    detectFiles(event) {
        console.log(event, 'xcvb')
        this.selectedFiles = event.target.files;
        this.fileName = this.selectedFiles[0].name;
    }
    fileUploadMessage() {
        this.toastr.success('Flipbook generated successfully.')    
      }
    

}
