import { Component, OnInit } from "@angular/core";
import { ApiService } from "../services/api.service";
import { HeaderService } from "../services/header.service";
import { CommonService } from "../services/common.service";

@Component({
  selector: "app-home-table",
  templateUrl: "./home-table.component.html",
  styleUrls: ["./home-table.component.css"],
})
export class HomeTableComponent implements OnInit {
  loading = true;
  data_info = [];
  constructor(
    private dataService: ApiService,
    private headerService: HeaderService,
    private commonService: CommonService,
   
  ) {}

  ngOnInit() {
   
    this.getDataSummary();
  }

  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodChar(timePeriod);
  }

  getDataValue(value) {
    if (value === "WHO") {
      return "Concurrent Monitoring Data - WHO";
    }

    if (value === "UNICEF") {
      return "Communication Monitoring Indicators - UNICEF";
    }
    return value;
  }

  getDataSummary() {
       this.dataService.getAll("api/dataSummaryTable/data/summary").subscribe((res: any) => {
      this.loading = false;
      this.data_info = res.data;

      this.data_info.push({ source: "VPD Surveillance - WHO" });
      //this.data_info.push({'source': 'Concurrent Monitoring Data - WHO'});
      //this.data_info.push({'source': 'Communication Monitoring Indicators - UNICEF'});
      this.data_info.push({ source: "1068 NUHM Cities - HMIS" });
    });
  }
}
