import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HostListener } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { SharedataService } from '../../services/sharedata.service';
import { ApiService } from '../../services/api.service';
import { _ } from 'underscore';
import * as Highcharts from 'highcharts';
import Tree from 'highcharts/modules/treemap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { VisualizationRendererService } from '../../services/visualization-renderer';
import { UseCaseService } from "../../services/use-case.service";
import { GetCaseColumnService } from "../../services/get-case-column.service";
import { GetCaseTableService } from "../../services/get-case-table.service";
import Heatmap from 'highcharts/modules/heatmap';
import More from 'highcharts/highcharts-more';
import { GetCasePieService } from '../../services/get-case-pie.service';
import exporting from 'highcharts/modules/exporting';
import offline from 'highcharts/modules/offline-exporting';
//import * as html2canvas from "html2canvas";
import html2canvas from 'html2canvas';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import MapModule from 'highcharts/modules/map';
import { CommonService } from "../../services/common.service";

exporting(Highcharts);
offline(Highcharts);

Tree(Highcharts);
Heatmap(Highcharts);
More(Highcharts);
MapModule(Highcharts);
declare var $: any;
var rangeIusList = require('src/mapIusLegend.json');
@Component({
  selector: 'app-area-profile',
  templateUrl: './area-profile.component.html',
  styleUrls: ['./area-profile.component.css']
})

export class AreaProfileComponent implements OnInit {
  @ViewChildren('vizBox') vizBox: QueryList<any>;
  @ViewChildren('timePeriodKeyFacts') timePeriodKeyFacts: QueryList<any>;
  @ViewChildren('select') select;

  selectFrom: any[] = [];
  selectTo: any[] = [];
  areaName = '';
  visualizationMetaData: any;
  checked: boolean = true;
  sectorId = '';
  selectionType: any = 'multiple';
  indicatorList = [];
  subgroupDimension = [];
  selectedTime = [];
  selectedRecenttime = [];
  displayViz = 0;
  areaID;
  areaLevel;
  iusID = [];
  timePeriod: any = [];
  areaForChart: any = [];
  subGroup = [];
  area = [];
  unit = [];
  chartContainer = [];
  showFullScreen: boolean = true;
  fullScreenExit: boolean = false;
  subscription: Subscription;
  all_ius = [];
  all_indicator = [];
  all_unit = [];
  all_subgroup = [];
  all_area = [];
  timePeriodList = [];
  areaNameList = [];
  all_time_period = [];
  all_seriesData = [];
  iusTimePeriod = [];
  label: any = {};
  iusBreadCrum;
  levelTwo;
  subscriptionData;
  all_dataSource = [];
  labelOff: any = {};
  labelOn: any = {};
  dataSource = {};
  fullScreenMode = false;
  shareUrlLink: any;
  tempVar = 1;
  areaSubLevelList: any = []
  areaSubLevelArr: any = [];
  legendSwitch: any = [];
  keyFactBoxLoader = [];
  noSelectedVisualization = true;
  maptoggle: any = {};
  ius_id;
  
  autoReloadViz = false;
  keyFactData: any = [];

  subSectorWiseKeyFacts: any;
  subSector: any = '';

  keyFactsTimePeriodList1: any;
  keyFactsTimePeriodList2: any;

  box_mrd_check: any = {};

  //infinite scroll requirement/variables
  partialIndicatorList;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 1;
  direction = '';

  noOfVizContainer = 0;
  noOfLoadedVizContainer = 0;
  tableDownloadCol: any;

  lowerFontSize :any = [];  
  
  keyFactSelectedTimePeriod1;
  keyFactSelectedTimePeriod2;
  maptitleMrd: boolean = true;

  constructor(
    private dataService: ApiService,
    private headerService: HeaderService,
    private shareData: SharedataService,
    public vizService: VisualizationRendererService,
    private useCase: UseCaseService,
    private columnUseCase: GetCaseColumnService,
    private pieUseCase: GetCasePieService,
    private tableUseCase: GetCaseTableService,
    private toastr: ToastrService,
    private commonService: CommonService,


  ) {

    let areaDetails = JSON.parse(localStorage.getItem('area_details'));
    this.areaID = areaDetails.area_code;

    // subscribe to home component messages

    this.subscription = this.shareData.getData().subscribe(message => {

      $(".viz-loader ol li").fadeIn();

      switch (message.data.ctype) {

        case 'sidebar':

          $("#my_breadcrumb1").html(message.data.data.parentData.level1);
          $("#my_breadcrumb2").html(message.data.data.level2);
          this.sectorId = message.data.data.id;//store sector id
          this.subSector = message.data.data.level2.trim();
          this.resetdeletedVisuals()     
          
          this.areaLevel = Number(this.commonService.getAreaLevel());
          this.areaID = this.commonService.getAreaID();
          this.areaName = this.commonService.getAreaName();

          this.getKeyFactTimePeriod()
          this.findSubLevelArea(this.areaID, 1);
          break;

        case 'header':
          window.scrollTo(0, 0)
          this.areaID = message.data.data.code;
          this.areaName = message.data.data.name;
          this.areaLevel = message.data.data.level
          this.getKeyFactTimePeriod()
          this.findSubLevelArea(this.areaID, 2);
          this.resetdeletedVisuals()
          break;

        default:
          break;
      }
    });
  }
  @HostListener("document:scroll", [])
  onWindowScroll() {
    if (this.displayViz == 0) {
      this.displayViz = 1;
    }
  }
  changed() {
    this.checked = !this.checked;
  }
  ngOnInit() {
    if (this.areaName == '') {
      let areaDetail = JSON.parse(localStorage.getItem('area_details'));
      this.areaName = areaDetail.area_name;
    }
    this.keyFactBoxLoader[0] = true;
    this.keyFactBoxLoader[1] = true;
    $(".loaders ol li").fadeIn();
    this.headerService.setTitle('Data Intelligence');
    this.headerService.setLogo('assets/lib/images/svg/header-menu/area-dashboard.svg');
    let userAreaDetail = JSON.parse(localStorage.getItem('area_details'));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    let that = this;
    $.getJSON('assets/lib/keyfacts.json', function (response) {
      that.subSectorWiseKeyFacts = response;
    })
  }

  ngAfterViewInit() {
    this.vizBox.changes.subscribe(t => {
      if (this.autoReloadViz) {
        this.ngForRendred();
        this.loadData(this.indicatorList[0].id);
        this.loadData(this.indicatorList[1].id);
        this.autoReloadViz = false;
      }
    })
    $(function () {
      $("#sortable").sortable();
      $("#sortable").disableSelection();
    });
  }
  objectKeys(obj) {
    return Object.keys(obj);
  }
  isSubgroupSelected(option, containerId) {
    if (this.selectedSubgroup[containerId].indexOf(option.id) == -1) {
      return false;
    } else {
      return true;
    }
  }
  changed_from_to_timeperiod: any = {};
  selectedSubgroup = [];
  generateBox() {
    this.indicatorList = [];
    this.partialIndicatorList = [];
    $(".loaders ol li").fadeIn();
    this.dataService
      .getAll<any[]>('api/sector/indicator/' + this.sectorId)
      .subscribe(
        (res: any) => {
          $(".loaders ol li").fadeOut();
          let selected_subgroup = [];
          this.subgroupDimension = res.data.subgroup_dimension;
          this.indicatorList = _.uniq(res.data.indicatorList, function (item) { return item.id; });
          this.indicatorList.forEach(element => {
            element.selectedFrom = undefined;
            element.selectedTo = undefined;
            this.selectedSubgroup[element.id] = [];
            element.default_chart.default_viz_subgroup.forEach(element1 => {
              selected_subgroup.push(element1.ius_id);
              this.selectedSubgroup[element.id].push(element1.ius_id);
            });
            element.ius_subgroup.forEach(element => {
              if (selected_subgroup.indexOf(element.id) == -1) {
                element.selected = false;
              } else {
                element.selected = true;
              }
            });
            let ele = element;
            this.box_mrd_check[element.id] = {};
            this.box_mrd_check[element.id].selected = false;
            if (element.default_chart.time_period == 1) {
              this.box_mrd_check[element.id].selected = true;
            } else { 
              ele.ius_timeperiod.forEach(tp => {
                tp.selected = true;
              });
            }
            this.label[element.id] = false;
            this.labelOff[element.id] = false;
            this.labelOn[element.id] = true;
            this.legendSwitch[element.id] = false;
            this.timePeriod['timeperiod_' + element.id] = [element.default_chart.time_period];
            this.areaForChart['area_' + element.id] = this.areaSubLevelArr;
            this.changed_from_to_timeperiod[element.id] = {};
            this.changed_from_to_timeperiod[element.id] = {};
            this.maptoggle[element.id] = {};
            this.changed_from_to_timeperiod[element.id]['from'] = '';
            this.changed_from_to_timeperiod[element.id]['to'] = '';
            this.maptoggle[element.id]['label'] = false;
          });
          let that = this;
          
          this.noOfVizContainer = this.indicatorList.length;
          this.noOfLoadedVizContainer = 2; //first time we are fixing to load only 2 view viz box
          this.autoReloadViz = true;
          this.partialIndicatorList.push(this.indicatorList[0]);
          this.partialIndicatorList.push(this.indicatorList[1]);
        },
        error => () => {
          console.log('Error')
        }
      );
  }
  onScrollDown() {
    let temp = this.noOfVizContainer - this.noOfLoadedVizContainer;
    let tempIus = [];
    if (temp <= 0) {
      return;
    }
    if (temp >= 2) {
      this.noOfLoadedVizContainer = this.noOfLoadedVizContainer + 2;
      if (this.indicatorList[this.noOfLoadedVizContainer - 1] !== undefined) {
        this.partialIndicatorList.push(this.indicatorList[this.noOfLoadedVizContainer - 1]);
        tempIus.push(this.indicatorList[this.noOfLoadedVizContainer - 1].id);
      }
      if (this.indicatorList[this.noOfLoadedVizContainer] !== undefined) {
        this.partialIndicatorList.push(this.indicatorList[this.noOfLoadedVizContainer]);
        tempIus.push(this.indicatorList[this.noOfLoadedVizContainer].id);
      }
    } else {
      this.noOfLoadedVizContainer = this.noOfLoadedVizContainer + 1;
      this.partialIndicatorList.push(this.indicatorList[this.noOfLoadedVizContainer - 1]);
      tempIus.push(this.indicatorList[this.noOfLoadedVizContainer - 1].id);
    }
    let obj = this;
    setTimeout(function () {
      for (let i = 0; i < tempIus.length; i++) {
        obj.ngForRendred();
        obj.loadData(tempIus[i])
      }
    }, 100);
  }
  loadVisualisation(data: any, elementIndex: number) {
    if (this.fullScreenMode) {
      return;
    }
    if (this.displayViz == 0) {
      return;
    }
    //dont load viz if div is loaded already visualisation
    if (typeof this.chartContainer[data.id] == 'undefined') {
      //assign ius
      this.iusID['ius_' + data.id] = [];
      for (let i = 0; i < data.ius_subgroup.length; i++) {
        if (data.ius_subgroup[i].default_subgroup == 1) {
          this.iusID['ius_' + data.id].push(data.ius_subgroup[i].id);
        }
      }
      this.loadData(data.id);
    }
  }
  checkFirstLoad = 1;
  map_data: any = [];
  mapRangeArrLegend: any = {};
  mapdataArr: any = [];
  mapiusTp: any = [];
  mapLegendArr: any = [];
  mapTimePeriod: any = [];
  loadData(containerId) {
    
    this.mapTimePeriod[containerId] = [];
    this.map_data[containerId] = [];
    if (this.changed_from_to_timeperiod[containerId]['from'] != '' && typeof this.changed_from_to_timeperiod[containerId]['from'] != 'undefined' && this.changed_from_to_timeperiod[containerId]['to'] != '' && typeof this.changed_from_to_timeperiod[containerId]['to'] != 'undefined') {
      this.timePeriod['timeperiod_' + containerId] = [0];
    }
    //convert checkbox to radio button for map
    if ($("#viz_container_" + containerId).data('charttype') == 'map' && this.checkFirstLoad != 1) {
      this.timePeriod['timeperiod_' + containerId] = this.timePeriod['timeperiod_' + containerId][0];
    }
    //get all selected area
    let tempArea = [];
    $("#ius_sub_area_" + containerId + " :selected").each(function () {
      tempArea.push($(this).val());
    });
    if (tempArea.length > 0) {
      this.areaForChart['area_' + containerId] = tempArea;
    } else {
      $("#viz_container_" + containerId).html('<div class="no-data">No data available</div>');
      $("#loaders_" + containerId + " ol li").fadeOut();
      return;
    }
    // show no data if no time period is selected
    let timeperiodSelectedList = [];
    $("#ius_timeperiod_"+containerId + " :selected").each(function (){
      timeperiodSelectedList.push($(this).val());
    });
    if(timeperiodSelectedList[0] != '1'){this.maptitleMrd = false} else { this.maptitleMrd = true}
    if (timeperiodSelectedList.length<=0 && this.changed_from_to_timeperiod[containerId]['from'] == '' && this.changed_from_to_timeperiod[containerId]['to'] == ''){
      $("#viz_container_" + containerId).html('<div class="no-data">No data available</div>');
      $("#loaders_" + containerId + " ol li").fadeOut();
      return;
    }
    //get all selected area
    this.iusID['ius_' + containerId] = [];
    let tempIus = [];
    $("#ius_subgroup_" + containerId + " :selected").each(function () {
      tempIus.push($(this).val());
    });
    if (tempIus.length > 0) {
      this.iusID['ius_' + containerId] = tempIus;
    } else {
      $("#viz_container_" + containerId).html('<div class="no-data">No data available</div>');
      $("#loaders_" + containerId + " ol li").fadeOut();
      return;
    }
    this.dataService
      .getAll<any[]>('api/viz/data?area_id=' + this.areaForChart['area_' + containerId].join(',') + '&ius_id=' + this.iusID['ius_' + containerId].join(',') + '&time_period=' + this.timePeriod['timeperiod_' + containerId].join(',') + '&from_time=' + this.changed_from_to_timeperiod[containerId]['from'] + '&to_time=' + this.changed_from_to_timeperiod[containerId]['to'])
      .subscribe(
        (res: any) => {
         
          $("#loaders_" + containerId + " ol li").fadeOut();
          this.all_ius = [];
          this.all_indicator = [];
          this.all_unit = [];
          this.all_subgroup = [];
          this.all_area = [];
          this.all_time_period = [];
          this.all_seriesData = [];
          if (res.data.records.length <= 0) {
            $("#viz_container_" + containerId).html('<div class="no-data">No data available</div>');
            this.chartContainer[containerId] = null;
            return;
          }
           let prop = "area_name";
          res.data.records = res.data.records.sort(function (a, b) {
                       
                       // return a[prop] - b[prop];
                       return a[prop].localeCompare(b[prop]);
                     
                   });
          let subgroupTemp = [];
          let tempMapTimeperiod = []
          res.data.records.forEach( elem => {
            elem.time_period = this.commonService.covertTimePeriod(elem.time_period);
            if(subgroupTemp.indexOf(elem.subgroup) < 0){
              subgroupTemp.push(elem.subgroup);
            }
            if(tempMapTimeperiod.indexOf(elem.time_period) < 0){
              tempMapTimeperiod.push(elem.time_period);
            }
          })
          
          this.mapTimePeriod[containerId] = tempMapTimeperiod;

          let objData: any = [];
          this.all_dataSource['source_' + containerId] = [];
          let that = this;
          if (this.changed_from_to_timeperiod[containerId]['from'] != '' && typeof this.changed_from_to_timeperiod[containerId]['from'] != 'undefined' && this.changed_from_to_timeperiod[containerId]['to'] != '' && typeof this.changed_from_to_timeperiod[containerId]['to'] != 'undefined') {
            let pass_data2 = [];
            let that = this;
            let dataVal;
            let groupedData = _.groupBy(res.data.records, function (d) {
              return d.area_id + '-' + d.ius_id;
            });
            _.each(groupedData, function ($row) {
              let tempData = [];
              let dataAddVal = 0;
              let denominator = 0;
              let numerator = 0;
              _.each($row, function ($val) {
                if ($val.unit == 'Percent' || $val.unit == 'percent') {
                  denominator = denominator + Number($val.denominator);
                  numerator = numerator + Number($val.numerator);
                } else if ($val.unit == 'Number' || $val.unit == 'number') {
                  dataAddVal = dataAddVal + Number($val.value);
                }
              });
              if (dataAddVal > 0) {
                dataVal = dataAddVal;
              } else if (denominator > 0 && numerator > 0) {
                dataVal = (numerator / denominator) * 100;
                dataVal = dataVal.toFixed(2);
              } else {
                dataVal = '';
              }
              pass_data2.push({
                area_code: $row[0].area_code,
                area_id: $row[0].area_id,
                area_name: $row[0].area_name,
                id: $row[0].id,
                indicator: $row[0].indicator,
                ius_id: $row[0].ius_id,
                source: $row[0].source,
                subgroup: $row[0].subgroup,
                unit: $row[0].unit,
                ius: $row[0].indicator + '|' + $row[0].unit + '|' + $row[0].subgroup,
                time_period: that.changed_from_to_timeperiod[containerId]['from'] + '-' + that.changed_from_to_timeperiod[containerId]['to'],
                value: dataVal,
              });
            });
            res.data.records = pass_data2;
          }
          this.mapRangeArrLegend[containerId] = [];

          let chartType= $("#viz_container_" + containerId).data('charttype');


          res.data.records.forEach(element => {
            if (that.all_ius.indexOf(element.indicator + '|' + element.unit + '|' + element.subgroup) == -1) {
              that.all_ius.push(element.indicator + '|' + element.unit + '|' + element.subgroup);
            }
            element.ius = element.indicator + '|' + element.unit + '|' + element.subgroup;
            if (that.all_indicator.indexOf(element.indicator) == -1) {
              that.all_indicator.push(element.indicator);
            }
            if (that.all_dataSource['source_' + containerId].indexOf(element.source) == -1) {
              that.all_dataSource['source_' + containerId].push(element.source);
              that.dataSource = that.all_dataSource['source_' + containerId].join('');
            }
            if (that.all_unit.indexOf(element.unit) == -1) {
              that.all_unit.push(element.unit);
            }
            if (that.all_subgroup.indexOf(element.subgroup) == -1) {
              that.all_subgroup.push(element.subgroup);
            }
            if (that.all_area.indexOf(element.area_name) == -1) {
              that.all_area.push(element.area_name);
            }
            if (that.all_time_period.indexOf(element.time_period) == -1) {
              that.all_time_period.push(element.time_period);  
            }
            if (that.all_seriesData.indexOf(element.value) == -1) {
              that.all_seriesData.push(element.value);
            }
            this.mapdataArr.push(Number(element.value));
            this.map_data[containerId].push({ 'code': element.area_code, 'value': Number(element.value), 'time_period': element.time_period });
            this.mapRangeArrLegend[containerId].push(element.value);
            this.mapRangeArrLegend[containerId].sort(function (a, b) { return a - b });
            
           
          });


          let mapDataSet = res.data.records;
          if(chartType=='map' && mapDataSet.length>0){
              this.mapLegendArr["#viz_container_" + containerId] = this.equalCountMapLegend(this.mapRangeArrLegend[containerId],mapDataSet[0].indicator, mapDataSet[0].unit);
              this.mapiusTp = mapDataSet[0].indicator + '|' + mapDataSet[0].unit + '|' + mapDataSet[0].subgroup + ', ' + mapDataSet[0].time_period;
            }



          if (chartType == 'map' || chartType == 'treemap' || chartType=='table') {
            // $('#ius_timeperiod_' + containerId).val('');
            $('#legend_' + containerId).css({ 'display': 'none' });
            $('#swap_' + containerId).css({ 'display': 'none' });

          } else {
            $('#legend_'+containerId).css({ 'display': 'block' });
            $('#swap_' + containerId).css({ 'display': 'block' });
            
          }
          if (chartType == 'treemap' || chartType=='table') {    
            $('#label_' + containerId).css({ 'display': 'none' });     
          } else {
            $('#label_' + containerId).css({ 'display': 'block' });
          }


          if ($("#viz_container_" + containerId).data('charttype') != 'map') {
            objData['funcName'] = this.useCase.findUseCase($("#viz_container_" + containerId).data('charttype'), this.all_indicator.length, this.all_unit.length, this.all_subgroup.length, this.all_time_period.length, this.all_area.length);
            objData['chartType'] = $("#viz_container_" + containerId).data('charttype');
            objData['indicator'] = $("#viz_container_" + containerId).data('indicator');
            objData['unit'] = $("#viz_container_" + containerId).data('unit');
            objData['time_period'] = this.all_time_period;
            objData['area'] = this.all_area;
            objData['data'] = res.data.records;
            objData['ius'] = this.all_ius;
            objData['id'] = $("#viz_container_" + containerId).data('id');
            objData['subgroup'] = (subgroupTemp.length == 1)?subgroupTemp[0]:'';
            let tempData = this.getVizData(objData);
            let vizData = [];
            vizData['renderId'] = document.getElementById("viz_container_" + containerId);
            vizData['data'] = tempData['data'];
            vizData['showInLegend'] = false;
            vizData['chartType'] = tempData['chartType']
            vizData['indicator'] = tempData['indicator']
            vizData['unit'] = tempData['unit']
            vizData['ius'] = tempData['ius']
            vizData['time_period'] = tempData['time_period']
            vizData['subgroup'] = tempData['subgroup']
            // vizData['data_source'] = $("#data_source_" + containerId).html();
            vizData['data_source'] = this.all_dataSource['source_' + containerId];


            if (objData['chartType'] != 'table') {

              
              this.chartContainer[containerId] = new Highcharts.Chart(this.vizService[tempData['vizFunc']](vizData));
              Highcharts.setOptions({lang: {thousandsSep: ','}})
              if ((objData['chartType'] == 'area' || objData['chartType'] == 'stackedarea' || objData['chartType'] == 'stackedareapercent') && objData['data']['xaxis'].length==1) {
                this.toggleGrouping(containerId);
              }
            } else {
              $('#viz_container_' + containerId).html(vizData['data']);
            }
          } else {
            this.renderMap(containerId);
          }
        },
        error => () => {
          console.log('Error')
        }
      );
  }
  getVizData(vizObj) {
    let vizData = [];
    switch (vizObj['chartType']) {
      case "line":
      case "bar":
      case "column":
      case "stackedcolumn":
      case "stackedcolumnpercent":
      case "stackedbar":
      case "stackedbarpercent":
      case "area":
      case "stackedarea":
      case "stackedareapercent":
        vizData['vizFunc'] = "loadColumnChart";
        vizData['data'] = this.columnUseCase[vizObj['funcName']](vizObj);
        break;
      case "pie":
        vizData['vizFunc'] = "loadPieChart";
        vizData['data'] = this.pieUseCase[vizObj['funcName']](vizObj);
        break;
      case "treemap":
      case "tree":
        vizData['vizFunc'] = "loadTreeMap";
        vizData['data'] = this.pieUseCase[vizObj['funcName']](vizObj);
        break;
      case "table":
        vizObj['vizFunc'] = '';
        vizData['data'] = this.tableUseCase[vizObj['funcName']](vizObj);
        break;
      default:
        break;
    }
    vizData['chartType'] = vizObj['chartType'];
    vizData['indicator'] = vizObj['indicator'];
    vizData['unit'] = vizObj['unit'];
    vizData['ius'] = vizObj['ius'];
    vizData['id'] = vizObj['id'];
    vizData['time_period'] = vizObj['time_period'];
    vizData['subgroup'] = vizObj['subgroup'];
    return vizData;
  }
  changeSubgroup(obj, id) {
    if (this.iusID['ius_' + id].indexOf(obj.source.value) == -1) {
      if (obj.source.selected) {
        this.iusID['ius_' + id].push(obj.source.value)
      }
    }
    else {
      let indexOfPopedElem = this.iusID['ius_' + id].indexOf(obj.source.value);
      this.iusID['ius_' + id].splice(indexOfPopedElem, 1);
    }
    this.loadData(id)
  }
  changeTimeperiod(obj, id, all_time_period) {
    this.checkFirstLoad = 0;
    this.timePeriod['timeperiod_' + id] = [];
    if (this.timePeriod['timeperiod_' + id].indexOf(obj.source.value) == -1) {
      if (obj.source.selected) {
        this.timePeriod['timeperiod_' + id].push(obj.source.value)
      }
    }
    else {
      let indexOfPopedElem = this.timePeriod['timeperiod_' + id].indexOf(obj.source.value);
      this.timePeriod['timeperiod_' + id].splice(indexOfPopedElem, 1);
    }
    this.loadData(id)
  }

  mouseoverVizList(idname, obj) {
    $('#' + idname + '' + obj).find('> ul').show();
  }
  mouseoutVizList(idname, obj) {
    $('#' + idname + '' + obj).find('> ul').delay(4000).hide();
  }
  changeChartType(chartType, indicatorObj) {
    $("#loaders_" + indicatorObj.id + " ol li").fadeIn();
    $('#ius_timeperiod_' + indicatorObj.id).attr('multiple', function () {
      return (chartType != 'map') ? 'multiple' : null;
    });
    $('#ius_subgroup_' + indicatorObj.id).attr('multiple', function () {
      return (chartType != 'map') ? 'multiple' : null;
    });

    if (chartType == 'map'){
      $('#ius_timeperiod_' + indicatorObj.id).val(1);
      this.box_mrd_check[indicatorObj.id].selected = true;
      $('#ius_timeperiod_' + indicatorObj.id).multiselect('rebuild');

    }
    /*if (chartType == 'map' || chartType == 'treemap' || chartType=='table') {
      $('#ius_timeperiod_' + indicatorObj.id).val('');
      $('#legend_' + indicatorObj.id).css({ 'display': 'none' });
      $('#swap_' + indicatorObj.id).css({ 'display': 'none' });

    } else {
      $('#legend_' + indicatorObj.id).css({ 'display': 'block' });
      $('#swap_' + indicatorObj.id).css({ 'display': 'block' });
      
    }
    if (chartType == 'treemap' || chartType=='table') {    
      $('#label_' + indicatorObj.id).css({ 'display': 'none' });     
    } else {
      $('#label_' + indicatorObj.id).css({ 'display': 'block' });
    }*/
    $('#ius_timeperiod_' + indicatorObj.id).multiselect('rebuild');
    $('#ius_subgroup_' + indicatorObj.id).multiselect('rebuild');
    this.label[indicatorObj.id] = false;
    this.labelOff[indicatorObj.id] = false;
    this.labelOn[indicatorObj.id] = true;
    this.legendSwitch[indicatorObj.id] = false;
    let id = indicatorObj.id;
    let indicator = indicatorObj.indicator + ' | ' + indicatorObj.unit;
    let tempTimeperiod = [];
    let tempIus = [];
    this.timePeriod['timeperiod_' + id] = [];
    $("#ius_timeperiod_" + id + " option:selected").each(function () {
      tempTimeperiod.push($(this).val());
    });
    $("#ius_subgroup_" + id + " option:selected").each(function () {
      tempIus.push($(this).val());
    });
    //check if default timeperiod is set. if not set then push 1 as 1 is consider mrd
    if (tempTimeperiod.length <= 0) {
      tempTimeperiod.push(1);
    }
    this.iusID['ius_' + id] = tempIus;
    this.timePeriod['timeperiod_' + id] = tempTimeperiod;
    $("#viz_container_" + id).data('charttype', chartType);
    $("#viz_container_" + id).data('indicator', indicator);
    $("#viz_container_" + id).data('id', id);
    $("#viz_container_" + id).html('');
    this.loadData(id);
  }
  export_table_to_csv(html, filename, item) {
    var csv = [];
    var parser = new DOMParser();
    var doc = parser.parseFromString(html, "text/html");
    var rows = doc.querySelectorAll('table tr');
    for (var i = 0; i < rows.length; i++) {
      var row = [], cols = rows[i].querySelectorAll("td, th");
      for (var j = 0; j < cols.length; j++) {
        this.tableDownloadCol = cols[j];
        row.push(this.tableDownloadCol.innerText.replace(",", ""));
      }
      csv.push(row.join(","));
    }
    this.download_csv(csv.join("\n"), filename);
  }
  download_csv(csv, filename) {
    var csvFile;
    var downloadLink;
    // CSV FILE
    csvFile = new Blob([csv], { type: "text/csv" });
    // Download link
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
  getDate() {
    let d = new Date();
    let datestring = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear() + " " +
      d.getHours() + ":" + d.getMinutes();
    return (datestring.replace(':', '.'))
  }
  downloadChart(item) {
    if ($("#viz_container_" + item.id).data('charttype') == 'table') {
      var html = $("#viz_container_" + item.id).html();
      this.export_table_to_csv(html, $("#viz_container_" + item.id).data('indicator') + '_' + this.getDate() + '.csv', item);
    } else {
      this.chartContainer[item.id].exportChartLocal({
        type: "image/jpeg",
      })
    }
  }
  publishOnSocialMedia(shareMedia, id) {
    $("#loaders_" + id + " ol li").fadeIn();
    let that = this;
    let dataURL: any = {};
    let downLoadSection = document.getElementById('vizSection_' + id);
    setTimeout(function () {
      html2canvas(downLoadSection,
        {
          onclone: function (clonedDoc) {
            clonedDoc.getElementById('indicator-list_' + id).style.display = 'block';
          }
        }).then(function (canvas) {
          dataURL['img'] = canvas.toDataURL('image/png');
          let prepareNewWindow: any = window.open('', '', '');
          that.dataService.add('api/share-img-store', dataURL).subscribe((res: any) => {
            that.shareUrlLink = res.image_name;
            $("#loaders_" + id + " ol li").fadeOut();
            if (shareMedia == 'facebook') {
              let tempUrl = 'https://www.facebook.com/sharer.php?s=100&&p[url]=' + that.shareUrlLink;
              prepareNewWindow.location = tempUrl;
            }
            else {
              let tempUrl = 'https://twitter.com/share?url=' + that.shareUrlLink;
              prepareNewWindow.location = tempUrl;
            }
            $(".loaders.app-loader ol li").fadeOut();
          })
        });
    }, 0);
  }
  openIndicatorData(indicatorData) {
    $('#loaders_metadata ol li').fadeIn();
    //prevent chart from autoload again
    this.autoReloadViz = false;
    //call ius meta data
    let iusdata = {
      iu: indicatorData.indicator + '|' + indicatorData.unit
    }
    this.dataService.add('api/get-chart-metadata', iusdata).subscribe((res: any) => {
      if (res) {
        this.visualizationMetaData = res.data;
      
        $('#loaders_metadata ol li').fadeOut();
      }
    });
  }
  expandToFullScreen(id) {
    this.autoReloadViz = false;
    this.showFullScreen = false;
    this.fullScreenExit = true;
    this.fullScreenMode = true;
    $('#vizSection_' + id).parent().addClass('full-screen');
    this.chartContainer[id].reflow();
  }

  offFullScreenMode(id) {
    this.fullScreenMode = false;
    this.chartContainer[id].reflow();
  }

  exitFullScreen(id) {
    this.autoReloadViz = false;
    this.showFullScreen = true;
    this.fullScreenExit = false;
    let obj = this;
    $('#vizSection_' + id).parent().removeClass('full-screen');
    setTimeout(function () {
      obj.offFullScreenMode(id);
    }, 500);
  }
  /***flyto function***/
  hide_div_cout = 0;
  //@ViewChild("hide_charts_list") hide_charts_list: ElementRef;
  flyto(vizID, indicator, unit) {
    this.noSelectedVisualization = false;
    var cart = $('#card-bin');
    let that = this;
    var itemtodrag = $('#chartContainer_' + vizID);
    if (itemtodrag) {
      var itemclone = itemtodrag.clone()
        .offset({
          top: itemtodrag.offset().top,
          left: itemtodrag.offset().left
        })
        .css({
          'opacity': '1',
          'position': 'absolute',
          'height': '300px',
          'width': '300px',
          'z-index': '100',
          'box-shadow': '0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.2), 0 1px 8px 0 rgba(0,0,0,.12)',
          'background-color': 'white',
          'border-top': '5px solid #7d7d7d',
          'padding-left': '5px',
          'padding-right': '5px'

        })
        .appendTo($('body'))
        .animate({
          'top': cart.offset().top + 10,
          'left': cart.offset().left + 10,
          'width': 75,
          'height': 75
        }, 800);
      itemtodrag.hide();
      itemclone.animate({
        'width': 0,
        'height': 0,
        'bottom': 20,
        'right': 20
      }, function () {
        $(this).detach();
        that.hide_div_cout++;
        $('#card-bin').find('svg text').html(that.hide_div_cout);
        var str = '<tr data-row="' + vizID + '"><td>' + indicator + ', ' + unit + '</td><td><i  data-parentelement="' + vizID + '" class=" vizRemove show_hidden_chart fa fa-2x fa-eye pull-right pointer" data-placement="bottom" aria-hidden="true" data-toggle="tooltip" title="" data-original-title="Show Chart"></i></td></tr>';
        $('#hide_charts_list').append(str);
        let children = document.getElementsByClassName("vizRemove");
        for (let i = 0; i < children.length; i++) {
          children[i].addEventListener("click", (event: Event) => {
            let id = event.target['dataset']['parentelement'];
            that.showHiddenChart(id);
          });
        }
      });
    }
  }

  /***flyto function***/
  toggleLabel(indicatorObj) {
    //prevent chart from autoload again
    this.autoReloadViz = false;
    if (this.labelOff[indicatorObj.id]) {
      this.labelOn[indicatorObj.id] = true
      this.labelOff[indicatorObj.id] = false
    }
    else {
      this.labelOn[indicatorObj.id] = false
      this.labelOff[indicatorObj.id] = true
    }
    this.label[indicatorObj.id] = !this.label[indicatorObj.id];
    let chartType = $("#viz_container_" + indicatorObj.id).data('charttype')
    if (chartType == 'pie' ) {
      var opt = this.chartContainer[indicatorObj.id].series[0].options;
      opt.dataLabels.enabled = !opt.dataLabels.enabled;
      this.chartContainer[indicatorObj.id].series[0].update(opt);
    } else if (chartType == 'map') {
      if (this.maptoggle[indicatorObj.id]['label'] == true) {
        this.maptoggle[indicatorObj.id]['label'] = false;
      } else {
        this.maptoggle[indicatorObj.id]['label'] = true;
      }
      this.renderMap(indicatorObj.id)
    } else {
      this.chartContainer[indicatorObj.id].update({
        plotOptions: {
          series: {
            dataLabels: {
              enabled: this.label[indicatorObj.id],
            }
          }
        }
      })
    }
  }
  toggleLegend(indicatorObj) {
    let swapCardType = $("#viz_container_" + indicatorObj.id).data('charttype')
    if (swapCardType == 'treemap' || swapCardType == 'table' || swapCardType == '') {
      $("#loaders_" + indicatorObj.id + " ol li").fadeOut();
      return false;
    }

    if (this.legendSwitch[indicatorObj.id]) {
      this.legendSwitch[indicatorObj.id] = false
    }
    else {
      this.legendSwitch[indicatorObj.id] = true
    }
    
    // if (swapCardType != 'pie') {
      this.chartContainer[indicatorObj.id].update({
        legend: {
          enabled: this.legendSwitch[indicatorObj.id],
          itemStyle: {
            fontWeight: 100,
            fontSize: '11px'
          },
          align: 'right',
          verticalAlign: 'top',
          layout: 'vertical',
          itemMarginTop: 0,
          itemMarginBottom: 2
        }
      });
    
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  countDeletedvisuals() {
    this.noSelectedVisualization = ((this.hide_div_cout == 0) ? true : false)
    return (this.noSelectedVisualization)
  }
  
  resetdeletedVisuals(){
    this.hide_div_cout = 0;
    $('#hide_charts_list').html('');
    $('#card-bin').find('svg text').html(this.hide_div_cout);
    this.countDeletedvisuals()
  }
  showHiddenChart(id) {
    //prevent repeated calling as click event trigger multiple times
    if ($('#hide_charts_list tr[data-row="' + id + '"]').length > 0) {
      $('#chartContainer_' + id).show();
      $('#hide_charts_list tr[data-row="' + id + '"]').remove();
      // $('#hidden_card_Modal').modal('hide');
      this.hide_div_cout--;
      if (this.hide_div_cout == 0) {
        // $('#card-bin').removeClass('bin');
        // $('#hidden_card_Modal').modal('hide');
        this.countDeletedvisuals()
      }
      $('#card-bin').find('svg text').html(this.hide_div_cout);
    }
  }
  ngForRendred() {
    let that = this;
    $('.chart_subgroup').multiselect(
      {
        allSelectedText: 'All Subgroup',
        includeSelectAllOption: true,
        enableClickableOptGroups: true,
        numberDisplayed: 1,
        nonSelectedText: 'Subgroup',
        nSelectedText: '-Subgroup',
        onSelectAll: function () {
          let containerId = this.options.parentid;
          that.iusID['ius_' + containerId] = [];
          $("#loaders_" + containerId + " ol li").fadeIn();
          that.changed_from_to_timeperiod[containerId]['from'] = '';
          that.changed_from_to_timeperiod[containerId]['to'] = '';
          that.loadData(containerId);
        },
        onDeselectAll: function () {
          let containerId = this.options.parentid;
          $("#loaders_" + containerId + " ol li").fadeIn();
          that.timePeriod['ius_' + containerId] = [];
          $("#viz_container_" + containerId).html('<div class="no-data">No data available</div>');
          $("#loaders_" + containerId + " ol li").fadeOut();
        },
        onChange: function (option, checked, select) {
          let optionId = Array.isArray(option)
            ? option[0][0].dataset.parentid
            : $(option).attr("data-parentid");
          $("#loaders_" + optionId + " ol li").fadeIn();
          that.loadData(optionId);
        }
      }
    );
    $('.chart_time_period').multiselect(
      {
        allSelectedText: 'All Time Period',
        enableClickableOptGroups: true,
        numberDisplayed: 1,
        nonSelectedText: '0 Time Periods',
        nSelectedText: ' Time Periods',
        onSelectAll: function () {
          let containerId = this.options.parentid;
          $('#ius_timeperiod_' + containerId).multiselect('deselect', ['1']);
          that.timePeriod['timeperiod_' + containerId] = [];
          $("#loaders_" + containerId + " ol li").fadeIn();
          $("#ius_timeperiod_" + containerId).each(function () {
            that.timePeriod['timeperiod_' + containerId].push($(this).val());
          });
          that.changed_from_to_timeperiod[containerId]['from'] = '';
          that.changed_from_to_timeperiod[containerId]['to'] = '';
          that.loadData(containerId);
        },
        onDeselectAll: function () {
          let containerId = this.options.parentid;
          $("#loaders_" + containerId + " ol li").fadeIn();
          that.timePeriod['timeperiod_' + containerId] = [1];
          that.loadData(containerId);
        },
        onChange: function (option, checked, select) {
          let optionId = Array.isArray(option)
            ? option[0][0].dataset.parentid
            : $(option).attr("data-parentid");
          var selopt = $(':selected', this).closest('optgroup').attr('label');

          that.indicatorList.forEach(element => {
            element.selectedFrom = undefined;
            element.selectedTo = undefined
          });
          $("#loaders_" + optionId + " ol li").fadeIn();
          that.timePeriod['timeperiod_' + optionId] = [];
          if (option[0].value == 1) {
            let temp = [];
            $("#ius_timeperiod_" + optionId + " :selected").each(function () {
              if ($(this).val() != 1) {
                temp.push($(this).val());
              }
            });
            $('#ius_timeperiod_' + optionId).multiselect('deselect', temp);
            that.timePeriod['timeperiod_' + optionId].push('1');
          } else {
            $('#ius_timeperiod_' + optionId).multiselect('deselect', [1]);
            $("#ius_timeperiod_" + optionId + " :selected").each(function () {
              that.timePeriod['timeperiod_' + optionId].push($(this).val());
            });
          }
          if (that.timePeriod['timeperiod_' + optionId].length <= 0) {
            $('#ius_timeperiod_' + optionId).multiselect('select', '1');
            that.timePeriod['timeperiod_' + optionId] = ['1'];
          }
          that.changed_from_to_timeperiod[optionId]['from'] = '';
          that.changed_from_to_timeperiod[optionId]['to'] = '';
          that.loadData(optionId);
        }
      }
    );
    $('.chart_sub_area').multiselect(
      {
        allSelectedText: 'All Areas',
        includeSelectAllOption: true,
        numberDisplayed: 1,
        nonSelectedText: 'Area',
        nSelectedText: '- Area',
        onSelectAll: function () {
          let containerId = this.options.parentid;
          $("#loaders_" + containerId + " ol li").fadeIn();
          that.loadData(containerId);
        },
        onDeselectAll: function () {
          let containerId = this.options.parentid;
          $("#loaders_" + containerId + " ol li").fadeIn();
          that.loadData(containerId);
        },
        onChange: function (option, checked, select) {
          $("#loaders_" + $(option).attr("data-parentid") + " ol li").fadeIn();
          that.loadData($(option).attr("data-parentid"));
        }
      }
    );
  }
  findSubLevelArea(area, reloadData) {
    this.partialIndicatorList = [];
    //check data coming from sidebar
    if (reloadData == 1) {
      if (this.areaSubLevelList.length <= 0) {
        this.getAreaChild(area);
      } else {
        this.generateBox();
      }
    } else {
      this.getAreaChild(area);
    }
  }
  getAreaChild(area) {
    let that = this;
    this.dataService
      .getById<any[]>('/api/area-sublevel', area)
      .subscribe((res: any) => {
        this.areaSubLevelList = res.data.area;
        this.areaSubLevelList.forEach(element => {
          that.areaSubLevelArr.push(element.id);
        });
        //setTimeout(function () {
        that.generateBox();
        //}, 2000);
      });
  }
  changeTimePeriod(selectedTimePeiod, containerId) {
    this.timePeriod['timeperiod_' + containerId] = selectedTimePeiod;
    this.checkFirstLoad = 2;
  }
  
  keyFectResult: any = [];
  
  generateKeyFacts(keyFactData, type) {

    let apiParam = {};
    apiParam['area_id'] = this.areaID;
    apiParam['time_period'] = keyFactData.time_period;

    this.keyFactBoxLoader[type] = true;

    if (type == 0) {
        apiParam['ius'] = this.subSectorWiseKeyFacts[this.subSector]["keyfact1"]['ius'];
    } else if (type == 1) {
      apiParam['ius'] = this.subSectorWiseKeyFacts[this.subSector]["keyfact2"]['ius'];
      
    }


    this.dataService.add('api/keyfacts-data', apiParam).subscribe((res: any) => {
      
      if (res.data.keyFactData) {         
         let tempData = {'source':res.data.keyFactData.source,'value':(res.data.keyFactData.value)?Number(res.data.keyFactData.value).toLocaleString():'No Data'};
         
         this.lowerFontSize[type] = (tempData.value == 'No Data')?'lowerFontSize':'';

         this.keyFectResult[type] = tempData

         this.keyFactBoxLoader[type] = false;
      }
        
    })
  }
  
  changeKeyFactTime(event, type) {
    this.generateKeyFacts({time_period:event.value}, type)
  }

  getTimePeriod() {
    this.dataService.getAll('api/time-period-list').subscribe((res: any) => {
      this.timePeriodList = res.data;
      this.timePeriodList.forEach(element => {
        element.selected = false;
      });
    });
  }
  change_from_to(time_period, type, id) {
    if (type == "from") {
      this.changed_from_to_timeperiod[id]['from'] = time_period;
    } else if (type == "to") {
      this.changed_from_to_timeperiod[id]['to'] = time_period;
    }
  }
  from_to_button(id) {
    $("#loaders_" + id + " ol li").fadeIn();
    if (this.changed_from_to_timeperiod[id]['from'] == '' || typeof this.changed_from_to_timeperiod[id]['from'] == 'undefined' || this.changed_from_to_timeperiod[id]['to'] == '' || typeof this.changed_from_to_timeperiod[id]['to'] == 'undefined') {
      this.toastr.error('Please select From date and To date.');
      $("#loaders_" + id + " ol li").fadeOut();
    } else if (this.changed_from_to_timeperiod[id]['from'] > this.changed_from_to_timeperiod[id]['to']) {
      this.toastr.error('To date must be greater than the From date.');
      $("#loaders_" + id + " ol li").fadeOut();
    } else {
      this.loadData(id);
      $("#ius_timeperiod_"+id).multiselect('clearSelection');
    }
  }
  renderMap(containerId) {
    let chartTarget = document.getElementById('viz_container_' + containerId);
    let obj = this;
    let mapJson = 'assets/lib/map/IND_l02_2011_Census.json';
    if (this.areaLevel == 1) {
      mapJson = 'assets/lib/map/IND_l02_2011_Census.json';
    } else if (this.areaLevel == 2) {
      mapJson = 'assets/lib/map/IND_l03_2011_Census.json';
    } else if (this.areaLevel == 3) {
      mapJson = 'assets/lib/map/IND_l04_2020.json';
    }

   
    let objData = {};
    objData['chartType'] = $("#viz_container_" + containerId).data('charttype');
    objData['indicator'] = $("#viz_container_" + containerId).data('indicator');
    objData['unit'] = $("#viz_container_" + containerId).data('unit');
    objData['subgroup'] = $("#ius_subgroup_" + containerId +" option:selected").html();
    // objData['data_source'] = $("#data_source_" + containerId).html();
    objData['data_source'] = this.all_dataSource['source_' + containerId];
    objData['timePeriod'] = (this.maptitleMrd ? "Most recent data" : this.mapTimePeriod[containerId][0])
    // objData['timePeriod'] = (this.mapTimePeriod[containerId].length == 1)?this.mapTimePeriod[containerId][0]:"";
    
    $.getJSON(mapJson, function (response) {
      //this.http.get('assets/lib/map/IND_l02_2011_Census.json').subscribe((response: any) => {
      // Load the drilldown map

      let map = response;   
      let extractedJson = [];
      if (obj.areaLevel == 2 || obj.areaLevel == 3) {
        for (var i = 0; i < response.features.length; i++) {
          if (response.features[i].properties.code.indexOf(obj.areaID) !== -1) {
            extractedJson.push(response.features[i]);
          }
        }
        map = { type: "FeatureCollection", features: extractedJson };
      }

      let opts: any = {
        credits: {
          enabled: false
        },
        chart: {
          map: map,
        },
        exporting: {
          enabled: false,
          chartOptions: {
              title: {
                text: objData['indicator'] + ' | '+objData['subgroup']+ ' | '+objData['timePeriod'],
                  style: { fontSize: '20px' }, align: 'center',
                  margin: 20,
              },
              legend: {
                  // y: -180,
                  verticalAlign: 'bottom',
              },
              chart: {
                  height: 650,
                  width: 1250,
                  marginBottom: 70,
                  events: {
                      load: function () {
                          var renderer = this.renderer;
                          renderer.text('<i>Source : '+objData['data_source']+'</i>', 1150, 640).css({
                              width: '400px',
                              align: 'center',
                          }).add();
                      }
                  }
              },
          },
          filename: objData['indicator'] + '_' + obj.getDate()
      },

        title: {
          text: ''
        },
        tooltip: {
          pointFormat: '<span >{point.name} ({point.time_period})</span><span> <b> : {point.value}</b><br/>',
          useHTML: true
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            verticalAlign: 'bottom'
          }
        },
        colorAxis: {
          dataClasses: obj.mapLegendArr["#viz_container_" + containerId]
        },

        series: [{
          data: obj.map_data[containerId],
          mapData: map,
          joinBy: 'code',
          name: obj.areaName,
          dataLabels: {
            enabled: obj.maptoggle[containerId]['label'],
            formatter: function () {
              return this.point.value;
            },
            color: 'black',
          }
        }]
      };
      obj.chartContainer[containerId] = Highcharts.mapChart('viz_container_' + containerId, opts);
    });
  }
  mapLegendColorArrayGallery = ["#ff0000","#ffff00","#92d050","#548235","#808080"];
  equalCountMapLegend($data_arr,indicator, unit) {

    let rangeArr = [];
    let nlength ;
    let rcvCurrentIusLegend ;
    
    $.each(rangeIusList,function (k,v) {      
      if(indicator == Object.keys(v)[0]){        
        // console.log('eee', Object.keys(that.rangeIusList[k][Object.keys(v)[0]]).length)
        nlength =Object.keys(rangeIusList[k][Object.keys(v)[0]]).length;
        rcvCurrentIusLegend = v[Object.keys(v)[0]];
      }
      
    });


   

    if(nlength==6){
     
    
      rangeArr= [{
                      to: rcvCurrentIusLegend[0].max,
                      color: '#ff0000'
                  }, {
                      from: rcvCurrentIusLegend[1].min,
                      to: rcvCurrentIusLegend[1].max,
                      color: '#ffc000'
                  }, {
                      from: rcvCurrentIusLegend[2].min,
                      to: rcvCurrentIusLegend[2].max,
                      color: '#ffff00'
                  }, {
                      from: rcvCurrentIusLegend[3].min,
                      to: rcvCurrentIusLegend[3].max,
                      color: '#92d050'
                  },  {
                      from: rcvCurrentIusLegend[4].min,
                      to: rcvCurrentIusLegend[4].max,
                      color: '#548235'
                  }, {
                      from: rcvCurrentIusLegend[5].min,
                      color: '#808080'
                  }];
    }else if(nlength==5){
      

      rangeArr=  [{
                      to: rcvCurrentIusLegend[0].max,
                      color: '#ff0000'
                  }, {
                      from: rcvCurrentIusLegend[1].min,
                      to: rcvCurrentIusLegend[1].max,
                      color: '#ffff00'
                  }, {
                      from: rcvCurrentIusLegend[2].min,
                      to: rcvCurrentIusLegend[2].max,
                      color: '#92d050'
                  }, {
                      from: rcvCurrentIusLegend[3].min,
                      to: rcvCurrentIusLegend[3].max,
                      color: '#548235'
                  }, {
                      from: rcvCurrentIusLegend[4].min,
                      color: '#808080'
                  }];
    }else{
      


      if ($data_arr.length > 0) {
        let n = 4;
        let min = Math.min.apply(Math, $data_arr);
        let max = Math.max.apply(Math, $data_arr);
        if(min == 0 &&  max==0){
          rangeArr.push({ from: 0, to: 0, color: this.mapLegendColorArrayGallery[0], name: 0 + ' - ' + 0 });

        }else{


          if (min == max) {
            min = 0;
          }
          let dist1 = ((max - min) / n).toFixed(2);
          let dist = Number(dist1);
          let inc = 0;
          if ((max - min) % n != 0) {
            inc = 1;
          }
          let lowLimit;
          let highLimit;
          lowLimit = +min.toFixed(2);
          highLimit = +max.toFixed(2);
          let difference = (highLimit - lowLimit) / 4;
          n = 4;
          let value = lowLimit;
          let arr = [];
          let counter = 0;
          while (value < highLimit) {
            if (counter == 3) {
              value = max;
            } else {
              value += difference;
            }
            value = +value.toFixed(2);
            let arr = [lowLimit, value];
            if (unit == "Number" || unit == "number") {
              rangeArr.push({ from: Math.floor(Number(lowLimit)), to: Math.floor(Number(value)), color: this.mapLegendColorArrayGallery[counter], name: Math.floor(Number(lowLimit)) + ' - ' + Math.floor(Number(value)) });
            } else {
              rangeArr.push({ from: Number(lowLimit).toFixed(2), to: Number(value).toFixed(2), color: this.mapLegendColorArrayGallery[counter], name: Number(lowLimit).toFixed(2) + ' - ' + Number(value).toFixed(2) });
            }
            counter++;
            lowLimit = value + 0.01;
          }
        }
      }else{
          rangeArr.push({ from: 0, to: 0, color: this.mapLegendColorArrayGallery[0], name: 0 + ' - ' + 0 });

      }
    }

    return rangeArr;
  }

  downloadKeyFacts(indObj, areaname, id) {
    let that = this;
    that.keyFactBoxLoader[id] = true;
    setTimeout(() => {
      let downLoadSection = document.getElementById('keyfact-section_' + id);
      html2canvas(downLoadSection, {
        onclone: function (clonedDoc) {
          clonedDoc.getElementById('area-name_' + id).style.display = 'block';
          that.keyFactBoxLoader[id] = false;
        }
      }).then(function (canvas) {
        let that = this;
        var dataURL = canvas.toDataURL('image/png').replace("image/jpeg", "image/octet-stream");
        let a = document.createElement('a')
        a.href = dataURL;
        let d = new Date();
        let datestring = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear() + " " +
          d.getHours() + ":" + d.getMinutes();
        a.download = ('DI' + '_' + indObj['indicator'] + '_' + indObj['unit'] + '_' + indObj['subgroup'] + '_' + areaname + '_' + datestring.replace(':', '.') +'.png');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    }, 100);
  }

  publishonKeyFacts(shareMedia, id) {
    let that = this;
    that.keyFactBoxLoader[id] = true;
    let dataURL: any = {};
    let downLoadSection = document.getElementById('keyfact-section_' + id);
    setTimeout(function () {
      html2canvas(downLoadSection).then(function (canvas) {
        dataURL['img'] = canvas.toDataURL('image/png');
        let prepareNewWindow: any = window.open('', '_blank');
        that.dataService.add('api/share-img-store', dataURL).subscribe((res: any) => {
          that.shareUrlLink = res.image_name;
          that.keyFactBoxLoader[id] = false;
          // $('.loaders ol li').fadeOut();
          $("#keyFacts-loader_" + id + " ol li").fadeOut();
          if (shareMedia == 'facebook') {
            let tempUrl = 'https://www.facebook.com/sharer.php?s=100&&p[url]=' + that.shareUrlLink + ',sharer' + 'target=_blank';
            prepareNewWindow.location = tempUrl;
          }
          else if (shareMedia == 'twitter') {
            let tempUrl = 'https://twitter.com/share?url=' + that.shareUrlLink;
            prepareNewWindow.location = tempUrl;

          } else {
            this.toastr.error('Please click on any social media icon.');

          }
        })
      });

    }, 100);
  }
  toggleGrouping(containerId) {
    $("#loaders_" + containerId + " ol li").fadeIn();
    let newLabels = [];
    let newCategories = [];
    let newData = [];
    let swapCardType = $("#viz_container_" + containerId).data('charttype')
    if (swapCardType == 'treemap' || swapCardType == 'pie' || swapCardType == 'map' || swapCardType == 'pyramid' || swapCardType == 'bubble' || swapCardType == 'table' || swapCardType == '') {
      $("#loaders_" + containerId + " ol li").fadeOut();
      this.toastr.error('Swap not allowed for this chart type.');
      return false;
    }
    var chart = this.chartContainer[containerId];
    setTimeout(function () {
      let seriez = chart.series;
      $.each(chart.xAxis[0].categories, function (i, name) {
        newLabels.push(name);
      });
      $.each(seriez, function (x, serie) {
        newCategories.push(serie.name);
        $.each(serie.data, function (z, point) {
          if (newData[z] == undefined) {
            newData[z] = [];
          }
          if (newData[z][x] == undefined) {
            newData[z][x] = '';
          }
          newData[z][x] = (point.y == undefined) ? '' : point.y;
        });
      });
      while (chart.series.length > 0) {
        chart.series[0].remove(true);
      }
      chart.xAxis[0].setCategories(newCategories, false);
      $.each(newData, function (key, newSeries) {
        chart.addSeries({
          name: newLabels[key],
          data: newSeries
        }, false);
      });
      chart.redraw();
      $("#loaders_" + containerId + " ol li").fadeOut();
    }, 0);
  }
  getKeyFactTimePeriod() {

    let keyFactData = {};
    keyFactData['ius1'] = this.subSectorWiseKeyFacts[this.subSector]["keyfact1"]['ius'];
    keyFactData['ius2'] = this.subSectorWiseKeyFacts[this.subSector]["keyfact2"]['ius'];
    keyFactData['area_id'] = this.areaID;
    
    this.dataService.add('api/keyfacts-timeperiod', keyFactData).subscribe((res: any) => {
      
      this.keyFactsTimePeriodList1 = res.data.time_period1;
      this.keyFactSelectedTimePeriod1 = '';

      if(this.keyFactsTimePeriodList1.length > 0) {
        this.keyFactSelectedTimePeriod1 = this.keyFactsTimePeriodList1[0].time_period;
        this.generateKeyFacts(this.keyFactsTimePeriodList1[0],0);

      } else {
        let tempData = {'source':'','value':'No Data'};         
         this.lowerFontSize[0] = 'lowerFontSize';
         this.keyFectResult[0] = tempData
         this.keyFactBoxLoader[0] = false;
      }


      this.keyFactsTimePeriodList2 = res.data.time_period2;
      this.keyFactSelectedTimePeriod2 = '';

      if(this.keyFactsTimePeriodList2.length > 0) {
        this.keyFactSelectedTimePeriod2 = this.keyFactsTimePeriodList2[0].time_period;
        this.generateKeyFacts(this.keyFactsTimePeriodList2[0],1);

      } else {        
        let tempData = {'source':'','value':'No Data'};         
        this.lowerFontSize[1] = 'lowerFontSize';
        this.keyFectResult[1] = tempData
        this.keyFactBoxLoader[1] = false;
      }


    });
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.indicatorList, event.previousIndex, event.currentIndex);
  }

getFormatedTimeperiod(timePeriod){
    return this.commonService.covertTimePeriod(timePeriod);
  }
  
  

}
