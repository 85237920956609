import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { Component, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { HeaderService } from '../../services/header.service';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DialogMaterialComponent } from '../../dialog-material/dialog-material.component';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.css']
})

export class SectionComponent implements OnInit {
  
  dashboardId;	
  form_open: boolean = false;
  addForm: FormGroup;
  dashboardRecords= [];
  ourFile: File;
  base64Image = '';
  showEdit: boolean = false;
  titlePage: string;
  validationError : any;
  sectionId: number;

  displayedColumns = ['id', 'section_name', 'status','created_at','actions'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private dataService: ApiService, 
     private headerService: HeaderService,
     private form: FormBuilder,
     private toastr: ToastrService,
     public dialog: MatDialog,
     private route: ActivatedRoute) {
  }

  ngOnInit() {

  	this.route.params.subscribe(params => {
		this.dashboardId = params['id'];
  	});

  	this.headerService.setTitle('Dashboard Section Management');
    //this.headerService.setTitle('Data Summary');
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('need-head');
    
    this.addForm = this.form.group({
       section_name: ['', Validators.required ],
    });    

    this.getAll();

  }

  
  /**
   * method that execute on changing the value of matInput
   * @param filterValue 
   */
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  /**
   * method that executes  to intiliaze the table with data
   */
  getAll() {
  	/**
     * api call to get all the records from dataService
     */
    this.dataService
      .getById<any[]>('api/dashboard/section',this.dashboardId)
      .subscribe(
        (res: any) => {
          this.dataSource = new MatTableDataSource(res.data.records);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
           this.dashboardRecords = res.data.records
        },
        error => () => {
          console.log('Error')
        },
        () => {
          //this.toasterService.pop('success', 'Complete', 'Getting all values complete');
          //this.slimLoadingBarService.complete();
        }
      );
  }

  /**
   *  method that executes on submitting form
   */
  add() {
    //api call to add a dashboard
    this.addForm.value.dashboard_id = this.dashboardId;

    this.validationError = [];
    this.dataService
      .add<any[]>('api/dashboard/section/add',this.addForm.value)
      .subscribe(
        (res: any) => {

          if(res.status == 0) {
            this.validationError = res.error; 
          } else {
            // toaster message on successfully submitting data to the table
            this.toastr.success('Section added successfully');
           
            // reset the form after submitting form data
            this.addForm.reset();
             // close the form modal
             this.form_open = !this.form_open;
            // get all the data  in the table
            this.getAll();
  
          }
                  },
        error => () => {
          console.log('Error')
        },
        () => {
          //this.toasterService.pop('success', 'Complete', 'Getting all values complete');
          //this.slimLoadingBarService.complete();
        }
      );
  }


  /**
   *  method that executes on submitting form
   */
  update() {
    //api call to add a dashboard
    this.addForm.value.dashboard_id = this.dashboardId;

    this.validationError = [];
    this.dataService
      .update<any[]>('api/dashboard/section/update',this.sectionId,this.addForm.value)
      .subscribe(
        (res: any) => {

          if(res.status == 0) {
            this.validationError = res.error; 
          } else {
            // toaster message on successfully submitting data to the table
            this.toastr.success('Section updated successfully');
           
            // reset the form after submitting form data
            this.addForm.reset();
             // close the form modal
             this.form_open = !this.form_open;
            // get all the data  in the table
            this.getAll();
  
          }
                  },
        error => () => {
          console.log('Error')
        },
        () => {
          //this.toasterService.pop('success', 'Complete', 'Getting all values complete');
          //this.slimLoadingBarService.complete();
        }
      );
  }


  /**
   *  method that trigger the click event on input type file on button click
   */
  openInput(){
    document.getElementById("logo").click();
  }
  
  /**
   * method that executes on  deletion of record
   * @param id // id of deleted record
   */
  deleteDashboard(id){
    const dialog = this.dialog.open(DialogMaterialComponent, {
      width: '350px',
      data: { messageDialog: 'Are you sure for the deletion of record ?', delete: true }
    });
    dialog.afterClosed()
      .subscribe(selection => {
        if (selection) {
          this.dataService.delete('api/user/delete', id).subscribe((response: any) => {
            // show toaster message on successfully deleting the user
            this.toastr.success('Selected dashboard deleted successfully');
            // get all the users after deleting the user
            this.getAll();
          })
        } else {
          // User clicked 'Cancel' or clicked outside the dialog
        }
      });
  }


  openForm(rowData){

  	this.validationError = [];
    
    this.form_open = !this.form_open;   
    if(rowData !== undefined){
      this.titlePage = 'Edit'
      this.showEdit = true;
      this.addForm.patchValue({
        section_name: rowData.name,
      });

      this.sectionId = rowData.id;
    }
    else{
      this.titlePage = 'New'
      this.showEdit = false;
    }
       
  }

}
