import { Component, OnInit, Input } from "@angular/core";
import { ApiService } from "src/app/services/api.service";
import { CommonService } from "src/app/services/common.service";
declare var $: any;
@Component({
  selector: "app-immu-dashboard-title",
  templateUrl: "./immu-dashboard-title.component.html",
  styleUrls: ["./immu-dashboard-title.component.css"],
})
export class ImmuDashboardTitleComponent implements OnInit {
  @Input() timePeriod: any;
  constructor(
    private commonService: CommonService,
    private dataService: ApiService
  ) {}

  ngOnInit() {
    let data = $("#title").html();
    this.dataService
      .add("api/report/add-objects", {
        data: data,
        report_id: 2,
        page_no: 2,
        type: 3,
      })
      .subscribe((response: any) => {});
  }

  getYear(timePeriod) {
    return this.commonService.getFinancialFirstYear(timePeriod);
  }
  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodFullChar(timePeriod);
  }
}
