import { Component, OnInit } from "@angular/core";
import { ApiService } from "../services/api.service";
import { HeaderService } from "../services/header.service";
import { Router } from "@angular/router";
declare var $: any;
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  public blog: any[];
  dataSummaryList = [];
  constructor(
    private dataService: ApiService,
    private headerService: HeaderService,

    private route: Router
  ) {}

  ngOnInit() {
    this.headerService.setTitle("Data Summary");
    this.headerService.setLogo("assets/lib/images/svg/data-mgm.svg");
    const authDetails = JSON.parse(localStorage.getItem("user_details"));

    // if (authDetails.role > 2) {
    //   this.route.navigate(["user/dashboard"]);
    // }
    this.getDataSummary();
  }

  getDataSummary() {
      $(".loaders ol li").fadeOut();
     
    this.dataService
      .getAll("api/datamanager/data/summary")
      .subscribe((res: any) => {
        this.dataSummaryList.push(
          {
            title: "Indicator",
            value: res.data.indicator,
            date: res.data.indicator_date.created_at,
            icon: "fa-calendar",
            card_class: "card-header-warning",
      },
        {
            title: "Geographical    \n  Representation" ,
            value:  res.data.area ,
            date: res.data.area_date.created_at,
            icon: "fa-globe",
            card_class: "card-header-info",
        },
       
        {
            title: "Data Sources",
            value: res.data.source,
            date: res.data.source_date.created_at,
            icon: "fa-book",
            card_class: "card-header-danger",
        },
        {
          title: "Total States",
          value:  res.data.area_state ,
          date: res.data.area_date.created_at,
          icon: "fa-globe",
          card_class: "card-header-success",
      },
        {
          title: "Total Districts",
          value: res.data.area_district,
          date: res.data.area_date.created_at,
          icon: "fa-globe",
          card_class: "card-header-success",
      },
       
        // {
        //     title: "Data Value",
        //     value: res.data.values,
        //     date: res.data.values_date.created_at,
        //     icon: "fa-database",
        //     card_class: "card-header-info",
        // }
        );
      });
  }
}
