import { ApiService } from "../../services/api.service";
import {
  Component,
  ViewChild,
  OnInit,
  ElementRef
} from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  FormsModule,
  FormBuilder,
  Validators,
  FormGroupDirective,
} from "@angular/forms";
import { HeaderService } from "../../services/header.service";
import { DialogMaterialComponent } from "../../dialog-material/dialog-material.component";
import { MatSnackBar, MatDialog } from "@angular/material";
import { ToastrService } from "ngx-toastr";
import { SelectionModel } from "@angular/cdk/collections";
import {
  TREE_ACTIONS,
  KEYS,
  IActionMapping,
  ITreeOptions,
  TreeComponent,
  ITreeState,
} from "angular-tree-component";
import { DatatableComponent } from "@swimlane/ngx-datatable";
declare var $: any;
import { ActivatedRoute } from '@angular/router';
import { _ } from 'underscore';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective
} from 'ngx-perfect-scrollbar';

declare var $: any;


@Component({
  selector: "app-preload-data",
  templateUrl: "./preload-data.component.html",
  styleUrls: ["./preload-data.component.css"],
})
export class PreloadDataComponent implements OnInit {
  areaIds = [];
  iusIds = [];
  categoryIds = [];
  selected: any = '';
  selectedTimePeriodList:any = 0;
  selectedIusList:any = 0 ;
  selectedAreaList:any = 0;
  checkedTimePeriodItems = [];
  rows: any = [];
  checkTimeLength = []
  count = 0;
  selectedItems = [];
  filterData = [];

  public config: PerfectScrollbarConfigInterface = {};
  @ViewChild(PerfectScrollbarComponent) componentRef?: PerfectScrollbarComponent;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  constructor(private dataService: ApiService,
    private headerService: HeaderService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    public matsnack: MatSnackBar,
  ) {
  }

  dashboardId;
  form_open: boolean = false;
  showEdit: boolean = false;
  titlePage: string;
  validationError: any;
  selection = new SelectionModel<any>(true, []);
  dataSource: MatTableDataSource<any>;
  list = [];
  disableEmail = false;
  addUserForm: FormGroup;
  states = [];
  roles: object;
  state_code: any;
  area_code;
  parentname;
  parentcode;
  parentlistcode;
  source = [];
  parentlist = [];
  focusFrouplist: any = [];
  sent_Category: any = "";
  rowId;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  @ViewChild("selectElem") selectElem: ElementRef;
  @ViewChild("tree") tree: TreeComponent;
  ngOnInit() {
  this.area_code = window.location.href.substr(
      window.location.href.lastIndexOf("/") + 1
    );

    this.pre();
    this.getAllUsers();
    this.getAreaList();

    $(document).on({
      click: function () {
        $('body').toggleClass('fcd-ie8'); //For the stupid ie8
        $(this).toggleClass('open');
        return false;
      }
    }, ".cbxTree-swicth");
    
    $(document).on({
      change: function () {
        $('[data-childid=' + $(this).data('childid') + ']').prop({ checked: $(this).is(':checked') });
        if ($(this).is(':checked')) {
          $('[data-childid=' + $(this).data('childid') + ']').parents('.cbxTree-node').removeClass('not-selected-area-level');
        } else {
          $('[data-childid=' + $(this).data('childid') + ']').parents('.cbxTree-node').addClass('not-selected-area-level');
        }
      }
    }, ".selectChild");
    $('.tree li.parent > span').click(function () {
      $(this).parent().toggleClass('active');
      $(this).parent().children('ul').slideToggle('fast');
    });
    this.route.params.subscribe(params => {
      this.dashboardId = params['id'];
    });

     this.headerService.setTitle("Preload List Data ");
    this.headerService.setLogo("assets/lib/images/svg/header-menu/user.svg"); 
    this.createAddUserForm();
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('need-head');
   
    
  }
  getAreaList() {
    this.dataService.getAll('api/get-area-list').subscribe((response: any) => {
      this.list = response.data.area_list;
    })
  }

  clearFormGroupDirective() {
    this.formGroupDirective.resetForm();
  }

  showHideAreaClass(obj) {
    let areaLevel = $(obj).data('arealevel');
     if ($('.area_check_list').find('.area-list:checked').length == 0) {
         this.selectedAreaList = 0;
       }else{
         this.selectedAreaList = 1;      
       }
    
    if (obj.checked == true) {
      for (let i = 1; i <= areaLevel; i++) {
        $(obj).parents('.area-level' + i + '-parent').removeClass('not-selected-area-level');
      }
    } else if (obj.checked == false) {
      $(obj).parents('.area-level' + areaLevel + '-parent').addClass('not-selected-area-level');
      for (let i = 1; i <= areaLevel; i++) {
        if ($(obj).parents('.area-level' + i + '-parent').find($('input[data-arealevel="' + areaLevel + '"]:checked')).length == 0) {
          $(obj).parents('.area-level' + i + '-parent').addClass('not-selected-area-level');
        }
      }
    }
  }

  
  onSelect({ selected }) {
    this.selectedItems = [];    
    this.selectedItems.splice(0, this.selectedItems.length);
    if (selected.length == 1) {
      this.selectedItems.push(selected[0].id);
    } else if (selected.length > 1) {
      for (let i = 0; i < selected.length; i++) {
        this.selectedItems.push(selected[i].id);
      }
    }
  }

  public onScrollEvent(event: any): void {
  } 
 
  area;
  options: ITreeOptions = {
    actionMapping: {
      mouse: {
        click: (tree, node, $event) => {
          this.sendData(node.data);
          this.addUserForm.get("areaName").patchValue(node.data.name);
          this.area = node.data.id;
          $("#select_area").html(node.data.id);
          $(".area-list").removeClass("open");
        },
      },
    },
  };

  sendData(data): void {
    
    // this.state.selectedNodeIds = data.code;
    this.addUserForm.get('area').setValue(data.code)
    this.addUserForm.get('level').setValue(data.level);
    // this.shareData.sendData({'ctype':'header','data':data});
  }
  changeIus(event) {
    this.parentname = event.source.triggerValue;
    this.parentcode = event.value;
    this.addUserForm = this.formBuilder.group({
      data: this.addUserForm.get("data"),
      label: this.addUserForm.get("label"),
      list_id: this.area_code,
      list_name: this.parentname,
      list_code: this.parentcode,
      field_id: [""],
      field_value: [""],
      focus_category: [""],
    });
  }

  pre() {
    let that = this;
    this.dataService
      .getAll<any[]>("api/preloadlistdata/pre/" + that.area_code)
      .subscribe((res: any) => {
        that.source = res.preload;
        res.preload.forEach((row) => {
          that.parentname = row.name;
          that.parentcode = row.code;
          that.parentlistcode = row.parent_list;
        });
        that.createAddUserForm();
        that.focusFrouplist = res.focusGroup;

        setTimeout(function () {
          $(".Category-list").multiselect("rebuild");
        }, 10000);
      });
  }

  getAllUsers() {
    let that = this;
    that.sent_Category;
    $(".loaders ol li").fadeIn();
    this.getFormValuesData();
    // api call to get the users from service
    this.dataService
      .getAll<any[]>(
        "api/preloadlistdata/all/" + that.area_code + "/" + that.parentlistcode
      )
      .subscribe(
        (res: any) => {
          $(".loaders ol li").fadeOut();
          this.rows = res.data.records;
          that.parentlist = res.list;
        },
        (error) => () => {
          console.log("Error");
        },
        () => {}
      );
  }

  /**
   * function to create addUser form
   */
  createAddUserForm() {
    this.addUserForm = this.formBuilder.group({
      data: ["", Validators.required],
      label: ["", Validators.required],
      list_id: this.area_code,
      list_name: this.parentname,
      list_code: this.parentcode,
      field_id: [""],
      field_value: [""],
      focus_category: this.sent_Category,
    });
    this.getAllUsers();
  }

  /**
   * function to add a new user
   * @param formVal // data of the form
   */
  public addNewUser(formVal) {

    //api call to add a dashboard
    this.areaIds = [];
    this.iusIds = [];
    this.categoryIds = [];

    var element = (<HTMLInputElement[]><any>document.getElementsByName("areaIds"));
    for (let i = 0; i < element.length; i++) {
      if (element[i].checked) {
        this.areaIds.push(element[i].value);
      }
    }

    var element = (<HTMLInputElement[]><any>document.getElementsByName("categoryIds"));
    for (let i = 0; i < element.length; i++) {
      if (element[i].checked) {
        this.categoryIds.push(element[i].value);
      }
    }

    let formData = {
      'data': this.areaIds.join(','),
      'label': this.areaIds.join(','),
      'list_id': this.area_code,
      'list_name': this.parentname,
      'list_code': this.parentcode,
      'field_id': "",
      'field_value': "",
      'focus_category': this.categoryIds.join(',')
    };
    this.validationError = [];
    this.dataService
      .add("api/preloadlistdata/add", formData)
      .subscribe((response: any) => {
        if (response.status == 0) {
          this.validationError = response.error;
        } else {
          this.toastr.success(response.message);
          // reset the form after submitting data
          this.addUserForm.reset();
          this.clearFormGroupDirective();
          setTimeout(() => {
            this.form_open = !this.form_open;
          }, 2000);
          //function to get the list of users after adding a user
          this.pre();
          this.getAllUsers();
          this.createAddUserForm();
        }
      });
  }
  checkTimeperiod(event) {
    if (event.target.checked) {    
      if (this.checkedTimePeriodItems.indexOf(event.target.value) == -1) {
        this.checkedTimePeriodItems.push(event.target.value);
      }
      $(event.target).parents('.timeperiod-list').removeClass('not-selected-timeperiod');
    }
    else {    
      let indexOfPopedElem = this.checkedTimePeriodItems.indexOf(event.target.value);
      this.checkedTimePeriodItems.splice(indexOfPopedElem, 1);
      $(event.target).parents('.timeperiod-list').addClass('not-selected-timeperiod');
    }


    if ($('.outlier_timeperiod_list:checked').length == 0) {
             this.selectedTimePeriodList = 0;
           }else{
             this.selectedTimePeriodList = 1;      
           }


  }
  /**
   * function to get form value data
   */
  getFormValuesData() {
    this.dataService
      .getAll("api/get-form-values")
      .subscribe((response: any) => {
        this.states = response.data.states;
        this.roles = response.data.userRoles;
        this.states.forEach((element) => {
          this.state_code = element.area_code;
        });
      });
  }

  deleteUserData(id) {
    const dialog = this.dialog.open(DialogMaterialComponent, {
      width: "350px",
      data: { messageDialog: "Are you sure to delete ?", delete: true },
    });
    dialog.afterClosed().subscribe((selection) => {
      if (selection) {
        this.dataService
          .delete("api/preloadlistdata/delete", id)
          .subscribe((response: any) => {
            // show toaster message on successfully deleting the user
            this.toastr.success(response.message);
            // get all the users after deleting the user
            this.getAllUsers();
          });
      } else {
      }
    });
  }

  openForm(rowData) {
    $("#error_text").html("");
    this.form_open = !this.form_open;
    if (rowData !== undefined) {
      this.titlePage = "Edit";
      this.showEdit = true;
      this.disableEmail = true;
      // patch the form value  form the response to  form fields
      this.addUserForm.patchValue({
        id: rowData.id,
        data: rowData.data,
        label: rowData.label,
        list_id: this.area_code,
        list_name: rowData.list_name,
        list_code: rowData.list_code,
        field_id: rowData.field_id,
        field_value: rowData.field_value,
        focus_category: rowData.focus_category,
      });

      this.rowId = rowData.id;
    } else {
      this.titlePage = "Add";
      this.addUserForm.patchValue({
        list_id: this.area_code,
      });
      this.showEdit = false;
      this.disableEmail = true;
      // this.addUserForm.reset();
      $("#select_area").html("Select location");
    }
    this.selected = "";
  }

  editSelectedUser(formVal) {
    this.areaIds = [];
    this.iusIds = [];
    this.categoryIds = [];
    var element = (<HTMLInputElement[]><any>document.getElementsByName("areaIds"));
    for (let i = 0; i < element.length; i++) {
      if (element[i].checked) {
        this.areaIds.push(element[i].value);
      }
    }

    var element = (<HTMLInputElement[]><any>document.getElementsByName("categoryIds"));
    for (let i = 0; i < element.length; i++) {
      if (element[i].checked) {
        this.categoryIds.push(element[i].value);
      }
    }

    let formData = {
      data: this.areaIds.join(','),
      label: this.areaIds.join(','),
      'list_id': this.area_code,
      'list_name': this.parentname,
      'list_code': this.parentcode,
      'field_id': '',
      'field_value':'',
      'focus_category': this.categoryIds.join(',')
    };
    this.dataService
      .update("api/preloadlistdata/update", this.rowId, formData)
      .subscribe((response: any) => {
        this.toastr.success(response.message);
        // reset the form after submitting data
        this.addUserForm.reset();
        this.clearFormGroupDirective();
        setTimeout(() => {
          this.form_open = !this.form_open;
        }, 2000);
        //function to get the list of users after adding a user
        this.getAllUsers();
      });
  }

  showSelected(obj) {
    if (obj.checked == true) {
      // $('.not-selected-ius-subgroup').hide();
      $('.not-selected-ius').hide();
      $('.not-selected-ius-subsector').hide();
      $('.not-selected-ius-sector').hide();
      $('.not-selected-area-level').hide();
      $('.not-selected-timeperiod').hide();
    } else if (obj.checked == false) {
      // $('.not-selected-ius-subgroup').show();
      $('.not-selected-ius').show();
      $('.not-selected-ius-subsector').show();
      $('.not-selected-ius-sector').show();
      $('.not-selected-area-level').show();
      $('.not-selected-timeperiod').show();
    }
  }
 
  /**
   * method that execute on changing the value of matInput
   * @param filterValue
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.rows.filter(function (d) {
      return d.data.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }
 
}
