import { HttpHeaders, HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Configuration } from '../app.constant';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})

export class ApiService {

    private url: string;

    constructor(private http: HttpClient, private configuration: Configuration) {
        this.url = configuration.server;
    }

    public getAll<T>(urlParam: string): Observable<T> {
        return this.http.get<T>(this.url + '/' + urlParam).pipe(catchError(this.erroHandler));
    }

    public getById<T>(urlParam: string, id: number): Observable<T> {
        return this.http.get<T>(this.url + urlParam + '/' + id);
    }

    erroHandler(error: HttpErrorResponse) {
        return throwError(error.message || 'server Error');
    }


    public getByArea<T>(urlParam: string): Observable<T> {
        return this.http.get<T>(this.url + '/' + urlParam);
    }
    public add<T>(urlParam: string, data: any): Observable<T> {
        return this.http.post<T>(this.url + '/' + urlParam, data);
    }

    public addWithImage<T>(urlParam: string, data: any): Observable<T> {
        //avoid adding content type if image is uploading
        const httpHeaders = new HttpHeaders({
            'isImageUpload': '1'
            // 'Accept': '' 
        });

        return this.http.post<T>(this.url + '/' + urlParam, data, { headers: httpHeaders });
    }

    public update<T>(urlParam: string, id: number, itemToUpdate: any): Observable<T> {
        return this.http
            .put<T>(this.url + '/' + urlParam + '/' + id, itemToUpdate);
    }

    public delete<T>(urlParam: string, id: number): Observable<T> {
        return this.http.delete<T>(this.url + '/' + urlParam + '/' + id);
    }

}

@Injectable()
export class CustomInterceptor implements HttpInterceptor {

    constructor(private route: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const re = /login/gi;

        // Exclude interceptor for login request:
        if (req.url.search(re) === -1) {

            //avoid content type set if image upload from formdata

            if (req.headers.get('isImageUpload') == '1') {
                //req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
            } else {
                if (!req.headers.has('Content-Type')) {
                    req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
                }
            }

            //console.log(localStorage.getItem('auth_token'));

            req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
            req = req.clone({ headers: req.headers.set('Authorization', `Bearer ${localStorage.getItem('auth_token')}`) });

            return next.handle(req).pipe(
                //retry(1),
                catchError((error: HttpErrorResponse) => {

                    switch (error.status) {
                        case 401:
                            localStorage.removeItem('auth_token');
                            this.route.navigate(["login"]);
                            break;
                        case 404:
                            // code block
                            break;
                        case 500:
                            // code block
                            break;
                        default:
                        // code block
                    }

                    if (error.status != undefined) {
                        let errorMessage = '';
                        if (error.error instanceof ErrorEvent) {
                            // client-side error
                            errorMessage = `Error: ${error.error.message}`;
                        } else {
                            // server-side error
                            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                        }
                        //window.alert(errorMessage);
                        return throwError(errorMessage);
                    }


                })
            )
        }

        return next.handle(req);

    };
}