import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';
import { DesignerComponent } from './dashboard_management/designer/designer.component';
import { UserComponent } from './user_management/user/user.component';
import { DataImportComponent } from './data_management/data-import/data-import.component';
import { IndicatorImportComponent } from './data_management/indicator-import/indicator-import.component';
import { IndicatorManagementComponent } from './data_management/indicator-management/indicator-management.component';
import { AreaImportComponent } from './data_management/area-import/area-import.component';
import { GalleryComponent } from './gallery_management/gallery/gallery.component';
import { VisualizationComponent } from './gallery_management/visualization/visualization.component';
import { SectionComponent } from './dashboard_management/section/section.component';
import { FacilityComponent } from './facility_management/facility/facility.component';
import { FacilityLocatorComponent } from './facility_management/facility-locator/facility-locator.component';
import { ExcelImportComponent } from './data_management/excel-import/excel-import.component';
import { CoverageComponent } from './user-dashboard/coverage/coverage.component';
import { CommunicationComponent } from './user-dashboard/communication/communication.component';
import { ColdChainComponent } from './user-dashboard/cold-chain/cold-chain.component';
import { UserOutlierComponent } from './outlier_management/user-outlier/user-outlier.component';
import { ReportComponent } from './report_management/report/report.component';
import { UserhomeComponent } from './user-dashboard/userhome/userhome.component';
import { ClassificationInformationComponent } from './user-dashboard/classification-information/classification-information.component';
import { FlipbookComponent } from './user-dashboard/flipbook/flipbook.component';
import { AreaProfileComponent } from './user-dashboard/area-profile/area-profile.component';
import { UserGalleryManagementComponent } from './gallery_management/user-gallery-management/user-gallery-management.component';
import { OutlierComponent } from './dashboard_management/outlier/outlier.component';
import { ReportMgmComponent } from './dashboard_management/report-mgm/report-mgm.component';
import { RankComponent } from './user-dashboard/rank/rank.component';
import { RankAreaComponent } from './user-dashboard/rank_Area/rank_Area.component';
import { BubbleComponent } from './user-dashboard/bubble/bubble.component';
import { DefaultchartImportComponent } from './data_management/defaultchart-import/defaultchart-import.component';
import { MetadataImportComponent } from './data_management/metadata-import/metadata-import.component';
import { DataViewComponent } from './data_management/data-view/data-view.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { FlipbookManagementComponent } from './dashboard_management/flipbook-management/flipbook-management.component';
import { ImportLogComponent } from './import-log/import-log.component';
import { UserManualComponent } from './user-dashboard/user-manual/user-manual.component';
import { UserSupportComponent } from './user-dashboard/user-support/user-support.component';
import { TargetImportComponent } from './data_management/target-import/target-import.component';

import { ReportMrComponent } from './fact_sheets/report-mr/report-mr.component';
import { DataImportComponentNew } from './data_management/data-import-new/data-import-new.component';
import { MrSheetComponent } from './fact_sheets/mr-sheet/mr-sheet.component';
import { ZeroDoseComponent } from './fact_sheets/zero-dose/zero-dose.component';
import { ImmunizationComponent } from './fact_sheets/immunization/immunization.component';
import { AdministeredVaccineComponent } from './fact_sheets/administered-vaccine/administered-vaccine.component';
import { StateProfileFactSheeetComponent } from './fact_sheets/state-profile-fs/state-profile-fs.component'
import { DashboardMainComponent } from './report_management/dashBoard/dashboard-main/dashboard-main.component';
import { DataSummarySheetComponent } from './fact_sheets/data-summary-sheet/data-summary-sheet.component';
import { PreloadlistComponent } from './Preload/preloadlist/preloadlist.component';
import { PreloadDataComponent } from './Preload/preload-data/preload-data.component';
import { AreaMappingComponent } from './Mapping_management/area-mapping/area-mapping.component';
import { ItemMappingComponent } from './Mapping_management/item-mapping/item-mapping.component';


const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2, 3, 4] } },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2, 3, 4] } },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard/designer', component: DesignerComponent },
  { path: 'user-management', component: UserComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'import-indicator', component: IndicatorImportComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },

  { path: 'preloadlist', component:PreloadlistComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] }},
  { path: 'preloadlistdatalist/:id', component: PreloadDataComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'import-areaMapping', component: AreaMappingComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'import-itemMapping', component: ItemMappingComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'import-target', component: TargetImportComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'fs/nfhs-factsheet', component: ReportMrComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'fs/mr-sheet', component: MrSheetComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'fs/zero-dose', component: ZeroDoseComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'fs/immunization', component: ImmunizationComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'fs/administeredvaccine', component: AdministeredVaccineComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'report', component: DashboardMainComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'fs/state-profile', component: StateProfileFactSheeetComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'fs/dataSummary-sheet', component: DataSummarySheetComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'manage-indicator', component: IndicatorManagementComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'import-area', component: AreaImportComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'data-import', component: DataImportComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'data-import-new', component: DataImportComponentNew, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'dashboard/section/:id', component: SectionComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'facility', component: FacilityComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'gallery-management', component: GalleryComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2, 3, 4] } },
  { path: 'gallery-management/visualization/:id/:name', component: VisualizationComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2, 3, 4] } },
  { path: 'user/dashboard/classification', component: ClassificationInformationComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2, 3, 4] } },
  { path: 'outlier', component: OutlierComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'user/dashboard/rank', component: RankComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2, 3, 4] } },
  { path: 'user/dashboard/rank_Area', component: RankAreaComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2, 3, 4] } },
  { path: 'user/dashboard/bubble', component: BubbleComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2, 3, 4] } },

  //{path: 'blog-details/:title', component: DesignerComponent }
  { path: 'excel-import', component: ExcelImportComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  // {path: 'facility/locator', component: FacilityLocatorComponent, canActivate: [AuthGuard],data:{allowRole:[1,2]}},

  //{path: 'blog-details/:title', component: DesignerComponent }
  { path: 'gallery-management', component: GalleryComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  //{path: 'blog-details/:title', component: DesignerComponent }
  { path: 'coverage', component: CoverageComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2, 3, 4] } },
  { path: 'communication', component: CommunicationComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2, 3, 4] } },
  { path: 'cold-chain', component: ColdChainComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2, 3, 4] } },
  // {path: 'facility/locator', component: FacilityLocatorComponent, canActivate: [AuthGuard],data:{allowRole:[1,2]}},    
  { path: 'usergallery', component: UserGalleryManagementComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2, 3, 4] } },


  { path: 'facility/locator/:id', component: FacilityLocatorComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2, 3, 4] } },
  { path: 'outlier/monitoring', component: UserOutlierComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2, 3, 4] } },
  //{ path: 'report', component: ReportComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2, 3, 4] } },
  //{path: 'blog-details/:title', component: DesignerComponent }
  // {path: 'signup', component: SignupComponent},
  { path: 'user/area-map', component: UserhomeComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2, 3, 4] } },
  { path: 'report/flipbook/:id', component: FlipbookComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2, 3, 4] } },
  { path: 'user/dashboard', component: AreaProfileComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2, 3, 4] } },
  { path: 'report-management', component: ReportMgmComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'import-defaultchart', component: DefaultchartImportComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'import-metadata', component: MetadataImportComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'data-view', component: DataViewComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'flipbook-management', component: FlipbookManagementComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'import-log', component: ImportLogComponent, canActivate: [AuthGuard], data: { allowRole: [1, 2] } },
  { path: 'manual', component: UserManualComponent },
  { path: 'support', component: UserSupportComponent },
 


];


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule { }
