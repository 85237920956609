import { Component, OnInit } from "@angular/core";
declare var $: any;
import { HeaderService } from "../../services/header.service";
import { ApiService } from "../../services/api.service";
import { VisualizationRendererService } from "../../services/visualization-renderer";
import { SharedataService } from "../../services/sharedata.service";
import { CommonService } from "../../services/common.service";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { _ } from "underscore";
import * as d3 from "d3v4";
var yearLabel;

@Component({
  selector: "app-bubble",
  templateUrl: "./bubble.component.html",
  styleUrls: ["./bubble.component.css"]
})
export class BubbleComponent implements OnInit {
  subscription: Subscription;
  iusDropDown = [];
  iusselect: any;
  areaID: any = "";
  elemnId: any;
  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private vizService: VisualizationRendererService,
    private shareData: SharedataService,
    private commonService: CommonService,
    private toastr: ToastrService
  ) {
    this.subscription = this.shareData.getData().subscribe(message => {
      switch (message.data.ctype) {
        case "sidebar":
          
          break;
        case "header":
          this.areaID = message.data.data.code;
          this.getBubbleChart();
          break;
        default:
          break;
      }
    });
  }
  xaxis: any = [];
  yaxis: any = "";
  seriesData: any = [];
  vizData: any = {};
  iusList;
  pageLoad = 1;
  bubble_play_pause = "fa fa-play";

  axisData:any=[];
  selectedAxisModal:any = '';
  
  ngOnInit() {
  $(".loaders ol li").fadeIn();

    this.headerService.setTitle("");
    this.headerService.setLogo(
      "assets/lib/images/svg/header-menu/area-dashboard.svg"
    );
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
   
    
    

    /***BUBBLE***/
    function clicked($this, type) {
      $(".MainDivLoader").css("display", "block");
      switch (type) {
        case "ius":
          $('div[data-target="#myModal"] > h5').text($($this).data("name"));
          $('div[data-target="#myModal"] > h5').attr(
            "title",
            $($this).data("name")
          );
          $('div[data-target="#myModal"] > h5').data(
            "value",
            $($this).data("value")
          );
          $("#myModal").modal("hide");
          break;
      }
    }

    $(document).ready(function($) {
      $(".ap_toggle_title").click(function() {
        $(this)
          .next(".ap_toggle_content")
          .slideToggle();
        $(this).toggleClass("active");

        if (
          $(this)
            .parent(".ap_toggle")
            .hasClass("open")
        ) {
          $(this)
            .parent(".ap_toggle")
            .removeClass("open");
          $(this)
            .parent(".ap_toggle")
            .addClass("close");
        } else if (
          $(this)
            .parent(".ap_toggle")
            .hasClass("close")
        ) {
          $(this)
            .parent(".ap_toggle")
            .removeClass("close");
          $(this)
            .parent(".ap_toggle")
            .addClass("open");
        }
      });
      $(document).on("click", ".dir", function(e) {
        e.stopPropagation();
        $(this)
          .children()
          .slideToggle();
      });
      $(document).on("click", ".selection > li > a", function() {
        $("ul.root").hide();
        $("ul#" + $(this).attr("id") + "-tab").show();
        $(
          '.tree-select-indicator-header #custom-search-input input[type="text"]'
        ).trigger("keyup");
      });
    });

    this.areaID = this.commonService.getAreaID();
    this.getIusList();
    
    
    // alert(this.areaID )
    // this.getBubbleChart();
  
   
  }

  ngAfterViewInit() {
    $(document).ready(function(){
    $(".modal-backdrop").css('display', 'none');
    });
  }



  getIusList() {
    this.shareData
      .getAll("api/get-indicator-classifications")
      .subscribe((response: any) => {
        this.iusList = response.data;
        this.iusList.forEach((element, key) => {
          this.elemnId = key;
        });
         this.getBubbleChart();
      });
  }

  datasetToGetaData: any = {};
  chart_render_bubble: any;
  bubbleDataFinal: any = {};
  selctedx: any = 12;
  selctedy: any = 17;
  selctedz: any = 79;
  iusname_x: any = "";
  iusname_y: any = "";
  iusname_z: any = "";

  clickedIndicator(ius_id, ind, unit, subgroup, type) {

  
    // return;
    $("#myModal").modal("hide");
    $("#AxisModal").modal("hide");
    $("#AxisModal_Y").modal("hide");


    if (type == "x") {
      this.selctedx = ius_id;
      this.iusname_x = ind + " | " + unit + " | " + subgroup;
      // $("#iusname_x").html(ind + " | " + unit + " | " + subgroup);
    } else if (type == "y") {
      this.selctedy = ius_id;
      this.iusname_y = ind + " | " + unit + " | " + subgroup;
      // $("#iusname_y").html(ind + " | " + unit + " | " + subgroup);
    } else if (type == "z") {
      this.selctedz = ius_id;
      this.iusname_z = ind + " | " + unit + " | " + subgroup;
      // $("#iusname_z").html(
      //   "<span>Bubble Size :</span> " + ind + "|" + unit + "|" + subgroup
      // );
    }

    this.getBubbleChart()
    
  }




    getBubbleChart() {
      $(".loaders ol li").fadeIn();
      let bubbleTempDataMain = [];
      let iusIds = [this.selctedx, this.selctedy, this.selctedz];
      this.datasetToGetaData["ius"] = iusIds.toString();
      let userDetails = JSON.parse(localStorage.getItem("user_details"));
     
      this.datasetToGetaData["area"] = this.areaID;
      this.dataService
        .add("api/get-bubble-chart-data", this.datasetToGetaData)
        .subscribe((response: any) => {
          $(".loaders ol li").fadeOut();
          this.xaxis = [];
          this.yaxis = "";
          this.seriesData = [];
          let bubbleData = response.data.records;
          
        
            let get_axis = response.data.ius;
            if (this.pageLoad == 1) {
              this.iusname_x =
                get_axis[0].indicator +
                " | " +
                get_axis[0].unit +
                " | " +
                get_axis[0].subgroup;
              this.iusname_y =
                get_axis[1].indicator +
                " | " +
                get_axis[1].unit +
                " | " +
                get_axis[1].subgroup;
              this.iusname_z =
                get_axis[2].indicator +
                " | " +
                get_axis[2].unit +
                " | " +
                get_axis[2].subgroup;
             /* $("#iusname_x").html(this.iusname_x);
              $("#iusname_y").html(this.iusname_y);
              $("#iusname_z").html(
                '<span class="bubble_name">Bubble Size: </span> ' + this.iusname_z
              );*/
              this.pageLoad = 0;
            }
            
            var data = response.data.records;
            var areaList = response.data.areaList;
            var dataLength = data.length;
            var areaCodeList = response.data.areaCodeList;

            this.axisData = [];
            let seriesIndex = 0;
            let dataExist = 0;
            let tempValue;
            let indicatorsName = [this.iusname_x, this.iusname_y, this.iusname_z];
            let allTimePeriod = [];

            let axiDataMaxGroup = {};          
            axiDataMaxGroup['xAxisData'] = 0; 
            axiDataMaxGroup['yAxisData'] = 0; 
            axiDataMaxGroup['zAxisData'] = 0; 
                                
            let timePeriod = _.uniq(data, function(d) {
                return d.time_period;
              });


            let bubbleAreaList = [];

            let tempAxisData= [];
       
            $.each(areaList, function(key,areaName) {           

                tempAxisData.push({ name: areaName,region:areaCodeList[key]});
                bubbleAreaList.push(areaName);

                let tempData = [];


                $.each(iusIds, function(iusIndex, ius) {
                    let tempData1 = [];

                    $.each(timePeriod, function(timeperiodIndex, timeRow) {

                          dataExist = 0;
                          tempValue;
                          for (let i = 0; i < dataLength; i++) {

                            if (ius == data[i].ius_parent_id && data[i].time_period == timeRow.time_period && data[i].area_id == key) {
                              dataExist = 1;
                              
                              if (allTimePeriod.indexOf(timeRow.time_period) < 0) {
                                allTimePeriod.push(timeRow.time_period);
                              }
                              tempValue = Number(data[i].value);
                              break;
                            }
                          }
                          if (dataExist) {
                            tempData1.push([timeperiodIndex,tempValue]);                            
                          }

                    });

                    tempData.push(tempData1);

                });

     

                tempAxisData[seriesIndex]["xaxis"] = tempData[0];
                tempAxisData[seriesIndex]["yaxis"] = tempData[1];
                tempAxisData[seriesIndex]["zaxis"] = tempData[2];

                seriesIndex++;

            });

            this.axisData =tempAxisData;

            allTimePeriod.sort();
            let formattedAllTimePeriod = []
            allTimePeriod.forEach(res => {
              formattedAllTimePeriod.push(this.commonService.covertTimePeriod(res))
            })

            if (bubbleData.length <= 0) {
              this.axisData= [];
              this.toastr.error("No data available.");
            }
            this.motionChart('motionTab',formattedAllTimePeriod)
            // this.generateMotionBubble(axisData,axiDataMaxGroup,allTimePeriod,bubbleAreaList);
            //this.timeSeriesBubble(axisData, allTimePeriod, indicatorsName);
            
        });
    }

    changeIus(event, type) {
      this.iusselect = event.value;
      this.getBubbleChart();
    }
  
    searchIus(obj, modalVal) {

      $(".elem-visible").each(function() {
        if ($(this).prop("tagName") == "LI") {
          $(this).removeClass("rotate");
        } else {
          $(this).removeAttr("style");
        }
      });
      $(".parentClass").removeClass(".elem-visible");
      $(".parentClass").removeAttr("style");
      let str2 = obj.target.value;
      if (str2.length <= 3) {
        return;
      }
      let str1 = "";
      $("#sector-tab_" + modalVal + " .search-li").each(function() {
        str1 =
          $(this).attr("data-level1") +
          " " +
          $(this).attr("data-level2") +
          " " +
          $(this).attr("data-indicator") +
          " " +
          $(this).attr("data-unit") +
          " " +
          $(this).attr("data-subgroup");
        if (str1.toLowerCase().indexOf(str2.toLowerCase()) != -1) {
          $(this).show();
          $(this)
            .parents(".parentClass")
            .addClass("elem-visible");
        } else {
          $(this)
            .parent()
            .parent()
            .hide();
        }
      });
      $(".elem-visible").each(function() {
        if ($(this).prop("tagName") == "LI") {
          $(this).addClass("rotate");
        } else {
          $(this).css({ display: "block" });
        }
      });
    }


//new bubble from sdg
/*
getBubbleData(){

  let iusIds = [2, 3, 5];
     this.datasetToGetaData["ius"] = iusIds.toString();
  this.datasetToGetaData["area"] = 1;


this.dataService
      .add("api/get-bubble-chart-data", this.datasetToGetaData)
      .subscribe((response: any) => {
        $(".loaders ol li").fadeOut();

        this.motionChart("motionTab");
      });
}*/

  // this.motionChart("motionTab");
  motionChart(container,allTimePeriod) {
   var that = this;

  /********** Custom Ticks Format ************/
   var formatNumber = d3.format("d");
    var  formatFloat = d3.format(".1f");
     var formatBillion = function(x) { return x % 1 === 0 ? formatNumber(x / 1e9) + "B" : formatFloat(x / 1e9) + "B"; };
     var formatMillion = function(x) { return x % 1 === 0 ? formatNumber(x / 1e6) + "M" : formatFloat(x / 1e6) + "M"; };
     var formatThousand = function(x) { return x % 1 === 0 ? formatNumber(x / 1e3) + "K" : formatFloat(x / 1e3) + "K"; };
      // formatThousand = function(x) { return x % 1 === 0 ? formatNumber(x) : formatFloat(x); },
     var formatHundred = function(x) { return x % 1 === 0 ? formatNumber(x) : formatFloat(x); };
     var formatAbbreviation = function(x) {
       var v = Math.abs(x);

       return (v >= .9995e9 ? formatBillion
           : v >= .9995e6 ? formatMillion
           : v >= 1000 ? formatThousand
           : formatHundred)(x);
     }


     var chartDuration = 750;
      var chartDelay = 100;
      var marginSize = 30;
      var opacity = 0.08;
      var g;
      var sliderScale, yearScale;

      var loadAxisData = this.axisData;
     

    d3.select('#'+container).node().innerHTML = '';

    let outer = d3.select('#'+container).node().getBoundingClientRect();
        let outer_width = outer.width;
        let outer_height = outer.height-40;
        let player = { width: 50, height: 50 };
        let margin = { top: 0, right: 20, bottom: 120, left: 85 };
        let duration_time = 30000;
        let dots, action, play, xScale, yScale, radiusScale, colorScale, motionSource;

    // Chart dimensions.
    var width = outer_width - margin.right - margin.left,
        height = outer_height - margin.top - margin.bottom;

    var area_modal = document.getElementById(`${container}Areas`);      
    updateChart();
    
    var tooltip = d3.select("body").append("div")
                  .style("position", "absolute")
                  .style("z-index", "999999")
                  .style("opacity", "0")
                  .style("color", "white")
                  .style("padding", "5px 15px")
                  .style("background-color", "rgba(0, 0, 0, 0.75)")
                  .style("border-radius", "6px")
                  .style("font", "12px sans-serif")
                  .style("text-align", "center")
                  .style("letter-spacing", ".6px");

    $('body').on('hidden.bs.modal', '.modal', function (e) {
      // $('#'+container).css("filter", "blur(0)");
      $('#searchBox').val('')
    });

    function updateChart(){

      motionSource = '';//response.source.x.concat(response.source.y).concat(response.source.z);
      motionSource = '';//motionSource.filter(function(item, i, ar){ return ar.indexOf(item) === i; }).join(', ');
      var opacity = 0.08;

      var areas = loadAxisData.map(function(d){ return { code: d.region, name: d.name }; });

      $('#populateArea').html('<li><label for="selectall"><div><input type="checkbox" checked id="selectall" value="selectall"><i></i></div><span>Select all</span></label></li>'); 
      let areaList = '';
      $.each(areas,function(index,row){
        areaList += '<li data-areaname="'+row.name+'"><label for="'+row.code+'"><div><input class="selectArea" type="checkbox" checked name="motion_areas[]" id="'+row.code+'" value="'+row.code+'"><i></i></div><span>'+row.name+'</span></label></li>';
      });
      $('#populateArea').append(areaList);
      
      $(document).on('click','.selectArea',function(){
        if($(this).prop("checked") == false){
        
          d3.select("#motionTab svg circle.dot[data-area='"+$(this).val()+"']").style('fill-opacity', .2);
        } else {
          d3.select("#motionTab svg circle.dot[data-area='"+$(this).val()+"']").style('fill-opacity', 1);
        }

      });
      
      // $(document).on('click','#selectall',function(){
      //   if($(this).prop("checked") == true){
      //     d3.select("#motionTab svg circle.dot").style('fill-opacity', 1);
      //   } else {
      //     d3.select("#motionTab svg circle.dot").style('fill-opacity', .2);
      //   }
      //   ////////////////
        
      // });

                // add multiple select / deselect functionality
          $(document).on('click','#selectall',function () {
            if($(this).prop("checked") == true){
              $(".selectArea").prop("checked", true);
              $("circle.dot").css('fill-opacity', 1);

            }
            else{
              $(".selectArea").prop("checked", false);
              $("circle.dot").css('fill-opacity', .2);
            }
            
          });

          // if all checkbox are selected, check the selectall checkbox
          // and viceversa
          $(document).on('click','.selectArea',function(){
            if($(".selectArea").length != $(".selectArea:checked").length) {
              // $("#selectall").removeAttr("checked");
              $("#selectall").prop("checked", false);

             
            } else {
              $("#selectall").prop("checked", true);
            }

          });


        getRange(loadAxisData, function(axis_range){
          renderChart(loadAxisData, axis_range);
        });
        return;
    }
    

    function getRange(dataset, done) {
      let all_axis = [];
      let xaxis_all = [];
      let yaxis_all = [];
      let zaxis_all = [];
  

      dataset.forEach(function (d) {
        all_axis = all_axis.concat(d.xaxis);
        all_axis = all_axis.concat(d.yaxis);
        all_axis = all_axis.concat(d.zaxis);
      });

      dataset.forEach(function (d) {
        xaxis_all = xaxis_all.concat(d.xaxis);
      });

      dataset.forEach(function (d) {
        yaxis_all = yaxis_all.concat(d.yaxis);
      });

      dataset.forEach(function (d) {
        zaxis_all = zaxis_all.concat(d.zaxis);
      });

      var axis_range = {
                          year: {
                            all: d3.extent(all_axis, function (d) { return d[0]; }),
                            x: d3.extent(xaxis_all, function (d) { return d[0]; }),
                            y: d3.extent(yaxis_all, function (d) { return d[0]; }),
                            z: d3.extent(zaxis_all, function (d) { return d[0]; })
                          },
                          value: {
                            all: d3.extent(all_axis, function (d) { return d[1]; }),
                            x: d3.extent(xaxis_all, function (d) { return d[1]; }),
                            y: d3.extent(yaxis_all, function (d) { return d[1]; }),
                            z: d3.extent(zaxis_all, function (d) { return d[1]; })
                          }
                        };
      

      done(axis_range);
    }
   
    function renderChart(dataset, axis_range) {
    // Various scales. These domains make assumptions of data, naturally.
      var radiusScale = d3.scaleSqrt().domain(axis_range.value.z).range([0, 20]),
      colorScale = d3.scaleOrdinal(d3.schemeCategory20);

      // Label for X and Y
      var x_label = labelname(that.iusname_x, 100);
      var y_label = labelname(that.iusname_y, 20);
      var z_label = labelname(that.iusname_z, 100);
      var tempSelectedAxisModal ='';
      // Create the SVG container and set the origin.
      var svg = d3.select('#'+container)
              .append("svg")
              .attr("viewBox", `0 -30 ${outer_width} ${outer_height+10}`)
              .attr("preserveAspectRatio", "xMinYMin meet")
              .attr("id", "motion")
              .style("cursor", "pointer");

      if (/android|ipad|iphone|ipod/i.test(navigator.userAgent.toLowerCase())) {
        var xScale = d3.scaleLinear().domain([axis_range.value.x[0] - radiusScale(axis_range.value.z[1]), axis_range.value.x[1] + radiusScale(axis_range.value.z[1])]).range([0, outer_width]),
            yScale = d3.scaleLinear().domain([axis_range.value.y[0] - radiusScale(axis_range.value.z[1]), axis_range.value.y[1] + radiusScale(axis_range.value.z[1])]).range([outer_height-margin.top-margin.bottom, 0]);
        make_gridlines({ x: { left: margin.left, top: height + margin.top, scale: xScale }, y: { left: margin.left, top: margin.top, scale: yScale } }, outer_width, height);
      } else {

         /*var xScale = d3.scaleLinear().domain([axis_range.value.x[0] - radiusScale(axis_range.value.z[1]), axis_range.value.x[1] + radiusScale(axis_range.value.z[1])]).range([0, width]),
            yScale = d3.scaleLinear().domain([axis_range.value.y[0] - radiusScale(axis_range.value.z[1]), axis_range.value.y[1] + radiusScale(axis_range.value.z[1])]).range([outer_height-margin.bottom-margin.top, 0]);*/


        var xScale = d3.scaleLinear().domain([axis_range.value.x[0] , axis_range.value.x[1] ]).range([0, width]),
            yScale = d3.scaleLinear().domain([axis_range.value.y[0], axis_range.value.y[1]]).range([outer_height-margin.bottom-margin.top, 0]);



        make_gridlines({ x: { left: margin.left, top: height + margin.top, scale: xScale }, y: { left: margin.left, top: margin.top, scale: yScale } }, width, height);
      }

      var motion_source = svg.append("g").attr("transform", `translate(${outer_width-margin.right}, ${outer_height-5})`);
      motion_source.append("text")
        .attr('text-anchor', 'end')
        .style('font-size', '10px')
        .style('display', 'none')
        .text(`Source: ${motionSource.substr(0, 100)}`);
      motion_source.append("title")
        .text(motionSource);

      // Add an x-axis label.
      var x_indicator = svg.append("g")
        .attr("transform", "translate(0," + (height + 50) + ")")
        .style('cursor', 'pointer')
        .attr("data-toggle", "modal")
        .attr("data-target", "#myModal")
        .on('click', function (d, i) {
          // $('#'+container).css("filter", "blur(3px)");
          svg.transition().duration(0);
          d3.selectAll("#"+container+" svg image").attr('xlink:href', 'assets/lib/images/svg/play-button.svg');
          that.setCurrentAxisModal('x')
          

       
        });

      x_indicator.append("title")
        .text(that.iusname_x);

      x_indicator.append("text")
        .attr("class", "x label text-caret text-grey")
        .attr("text-anchor", "middle")
        .attr("transform", "translate(" + ((outer_width / 2)+10) + ",0)")
        // .attr("data-toggle", "modal")
        // .attr("data-target", "#myModal")
        .text(x_label);
      // Add an y-axis label.
      var y_indicator = svg.append("g")
        .attr("transform", "translate(" + (margin.left - 50) + "," + (margin.top+50) + ")")
        .style('cursor', 'pointer')
        .attr("data-toggle", "modal")
        .attr("data-target", "#myModal")
        .on('click', function (d, i) {
          // $('#'+container).css("filter", "blur(3px)");
          svg.transition().duration(0);
          d3.selectAll("#"+container+" svg image").attr('xlink:href', 'assets/lib/images/svg/play-button.svg');

          
          that.setCurrentAxisModal('y')

         
        });

      y_indicator.append("title")
        .text(that.iusname_y);

      y_indicator.append("text")
        .attr("class", "y label text-caret text-grey")
        .attr("text-anchor", "middle")
        .attr("transform", "translate(" + 0 + "," + ((height / 2)-6) + ") rotate(-90)")
        // .attr("data-toggle", "modal")
        // .attr("data-target", "#myModal")
        .text(y_label);


      var z_indicator = svg.append("g")
        .attr("transform", "translate(20,-10)")
        .style('cursor', 'pointer')
        .attr("data-toggle", "modal")
        .attr("data-target", "#myModal")
        .on('click', function (d, i) {
          // $('#'+container).css("filter", "blur(3px)");
          svg.transition().duration(0);
          d3.selectAll("#"+container+" svg image").attr('xlink:href', 'assets/lib/images/svg/play-button.svg');
          
          that.setCurrentAxisModal('z')
          
        });

      z_indicator.append("title")
        .text(z_label);

      z_indicator.append("text")
        .attr("class", "z label text-caret text-grey")
        .attr("text-anchor", "middle")
        .attr("transform", "translate(" + ((outer_width / 2)+16) + ",0)")
        // .attr("data-toggle", "modal")
        // .attr("data-target", "#myModal")
        
        .text("Bubble Size: "+z_label);

        
      if (/android|ipad|iphone|ipod/i.test(navigator.userAgent.toLowerCase())) {
        var year_translate = (width / 6) + "," + (height / 2);
        var ticksCount = 6;
        var diff = 3;
        var xarrow_x = (outer_width / 2) + (x_indicator.select('text').node().getComputedTextLength() / 2) + 40;
        var yarrow_x = height / 2 - (y_indicator.select('text').node().getComputedTextLength() / 2 + 30);
      } else {
        var year_translate = (width / 2 - 400) + "," + (height / 2 + 100);
        var ticksCount = 12;
        var diff = 3;
        var xarrow_x = (outer_width / 2) + (x_indicator.select('text').node().getComputedTextLength() / 2) + 40;
        var yarrow_x = height / 2 - (y_indicator.select('text').node().getComputedTextLength() / 2 + 30);
        var zarrow_x = (outer_width / 2) + (z_indicator.select('text').node().getComputedTextLength() / 2) +40;

      }

      // The x & y axes.
      var xAxis = d3.axisBottom(xScale).ticks(ticksCount).tickFormat(function (d) { return formatAbbreviation(d); });
      var yAxis = d3.axisLeft(yScale).ticks(ticksCount).tickFormat(function (d) { return formatAbbreviation(d); });

      x_indicator.append("path")
        .style("stroke", "#607889")
        .style("fill", "#607889")
        .attr("d", "M " + xarrow_x + ",-5, L " + (xarrow_x + diff) + ",-1, L " + (xarrow_x + diff * 2) + ",-5 Z");

      y_indicator.append("path")
        .style("stroke", "#607889")
        .style("fill", "#607889")
        .attr("d", "M -5," + yarrow_x + ", L -1," + (yarrow_x - diff) + ", L -5," + (yarrow_x - diff * 2) + " Z");

      z_indicator.append("path")
        .style("stroke", "#607889")
        .style("fill", "#607889")
        .attr("d", "M " + zarrow_x + ",-5, L " + (zarrow_x + diff) + ",-1, L " + (zarrow_x + diff * 2) + ",-5 Z");


      // Action grouping
      action = svg.append("g")
        .attr("id", "player")
        .attr("transform", "translate(" + margin.left + "," + (height + player.height) + ")");

      // Play and Pause image
      action.append("svg:image")
        .attr('xlink:href', 'assets/lib/images/svg/pause-button.svg')
        .attr("x", -18)
        .attr("y", 0)
        .attr("style", "cursor:pointer")
        .attr("width", player.width)
        .attr("height", player.height)
        .attr("width", player.width)
        .on('click', function () {
          var self = d3.select(this);
          if (self.attr('href').indexOf('pause') >= 0) {
            self.attr('xlink:href', 'assets/lib/images/svg/play-button.svg');
            svg.transition().duration(0);
          } else {
            self.attr('xlink:href', 'assets/lib/images/svg/pause-button.svg');
            play(duration_time);
          }
        });

      sliderScale = d3.scaleLinear().domain(axis_range.year.all).range([0, width - 60]);
      yearScale = d3.scaleLinear().domain([0, width - 60]).range(axis_range.year.all);

      let slider = action.append('g')
        .attr("transform", "translate(" + player.width + "," + +player.height / 2 + " )");

      slider.append("line")
        .attr("id", "player_slider")
        .attr("x1", sliderScale.range()[0])
        .attr("x2", sliderScale.range()[1])
        .attr("fill", "#ccc")
        .attr("stroke", "#000")
        .attr("stroke-opacity", 0.1)
        .attr("stroke-width", "10px");

      var handle = slider.insert("circle", ".track-overlay")
        .attr("class", "handle")
        .attr("r", 12)
        .call(d3.drag().on("drag", dragged));

      // Add the x-axis.
      svg.append("g")
        .attr("class", "x axis")
        .attr("transform", "translate("+margin.left+ ","+(outer_height-margin.bottom)+")")
        .style('cursor', 'pointer')
      //   .on('click', function (d, i) {
      //   alert();
      //       $('#myModal').modal({ show: true });
        
      // });
      
        .call(xAxis);

      // Add the y-axis.
      svg.append("g")
        .attr("class", "y axis")
        .attr("transform", "translate("+margin.left+","+margin.top+")")
        
        .call(yAxis);

      // Add the year label; the value is set on transition.
      var label = svg.append("text")
        .attr("class", "year label")
        .attr("text-anchor", "start")
        .attr("transform", "translate(" + year_translate + ")")
      .style("fill", "#e1e1e1e0")
      .style("font-size", "12vmax")
      .style("font-weight", "500")
      .style("letter-spacing", "1.6rem")
      .style("margin-left", "1%")
      .style("vertical-align", "middle")
      .style("white-space", "nowrap")
      .style("position", "fixed")
      .style("cursor", "default")
        .text(allTimePeriod[0]);

      svg.append('defs')
          .append('clipPath')
          .attr('id', 'motionChartClip')
          .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', outer_width-margin.left-margin.right)
            .attr('height', outer_height-margin.top-margin.bottom);

      let canvas = svg.append("g")
                  .attr("transform", "translate(" + (margin.left) + "," + margin.top + ")")
                  .attr("clip-path", "url(#motionChartClip)")
                  .attr("id", "canvas")
                  .style("cursor", "pointer");

      play(duration_time);

      // Start a transition that interpolates the data based on year.
      function play(duration) {
        updateCircles(function () {
          action.select('image').attr('xlink:href', 'assets/lib/images/svg/pause-button.svg');
          svg.transition()
            .duration(duration)
            .ease(d3.easeLinear)
            .tween("year", tweenYear)
            .on("end", endSlide);
        });
      }

      function dragged(d) {
        if (d3.event.x >= sliderScale.range()[0] && d3.event.x <= sliderScale.range()[1]) {
          d3.select(this).attr("cx", d3.event.x);
          displayYear(sliderScale.invert(d3.event.x));
        }
      }

      function updateCircles(done) {
        if (d3.select('circle.handle').attr('cx')) {
          var min_year = sliderScale.invert(d3.select('circle.handle').attr('cx'));
        } else {
          var min_year = axis_range.year.all[0];
        }

        var pointsLine = d3.line().x(function(d) { return d.x; }).y(function(d) { return d.y; });

        dots = canvas.selectAll("circle.dot").data(interpolateData(min_year));

        dots.exit().remove();

        dots = dots.enter().append("circle")
          .attr("class", "dot")
          .merge(dots)
          .attr("data-area", function (d) { return d.region })
          .style("fill", function (d) { return colorScale(d.region); })
          .call(position)
          .sort(order)
          .style("fill-opacity", 1)
          .style("stroke", "#333")
          .style('stroke-opacity', '.4')
          .style('cursor', 'pointer')
          .on("mouseover", function (d) {
            svg.transition().duration(0);
            tooltip.transition().style("opacity", 1);

            tooltip.html((d.name) + "<br>X : " + (d.xaxis.toLocaleString())
              + "<br>Y : " + (d.yaxis.toLocaleString())
              + "<br>Size : " + (d.zaxis.toLocaleString()))
              .style("left", (d3.event.pageX) + "px")
              .style("text-align", "left")
              .style("top", (d3.event.pageY + "px"));

            d3.select(this).style("opacity", 0.5);

            var pointdata = [{x: 0, y: yScale(d.yaxis)},{x: xScale(d.xaxis), y: yScale(d.yaxis)},{x: xScale(d.xaxis), y: height}];

            canvas.append('path')
              .attr('id','pointsline')
              .style("stroke-width", 1)
              .style("stroke", "#333")
              .style("fill", "none")
              .style("stroke-dasharray",("3, 3"))
              .attr('d', pointsLine(pointdata));
          })
          .on("mouseout", function (d) {
            canvas.select("#pointsline").remove();
            tooltip.transition().style("opacity", 0);
            d3.select(this).style("opacity", 1);

            if (action.select('image').attr('href') == 'assets/lib/images/svg/pause-button.svg') {
              play(duration_time);
            }
          });

        // Add a title.
        if (typeof done == "function") {
          done();
        } else {
          return;
        }
      }

      // Positions the dots based on data.
      function position(dot) {
        dot.attr("cx", function (d) { return xScale(d.xaxis); })
          .attr("cy", function (d) { return yScale(d.yaxis); })
          .attr("r", function (d) { return radiusScale(d.zaxis); });
      }

      // Defines a sort order so that the smallest dots are drawn on top.
      function order(a, b) {
        return b.zaxis - a.zaxis;
      }

      // After the transition finishes, you can mouseover to change the year.
      function endSlide() {
        action.select('image').attr('xlink:href', 'assets/lib/images/svg/pause-button.svg');
        // Cancel the current transition, if any.
        svg.transition().duration(0);
        d3.select('circle.handle').attr("cx", null);
      }

      // Tweens the entire chart by first tweening the year, and then the data.
      // For the interpolated data, the dots and label are redrawn.
      function tweenYear() {
        if (d3.select('circle.handle').attr('cx')) {
          var year = d3.interpolateNumber(sliderScale.invert(d3.select('circle.handle').attr('cx')), axis_range.year.all[1]);
        } else {
          var year = d3.interpolateNumber(axis_range.year.all[0], axis_range.year.all[1]);
        }
        return function (t) { displayYear(year(t)); d3.select('circle.handle').attr("cx", sliderScale(year(t))); };
      }

      function key(d) { return d.name; }

      // Updates the display to show the specified year.
      function displayYear(year) {
        dots.data(interpolateData(year), key).call(position).sort(order);
        let yearRound = allTimePeriod[Math.round(year)]
        label.text(yearRound);
      }

      // Interpolates the dataset for the given (fractional) year.
      function interpolateData(year) {
        let data =  dataset.map(function (d) {
          return {
            name: d.name,
            region: d.region,
            xaxis: interpolateValues(d.xaxis, year),
            yaxis: interpolateValues(d.yaxis, year),
            zaxis: interpolateValues(d.zaxis, year)
          };
        });

        
        return data;
      }

      // Finds (and possibly interpolates) the value for the specified year.
      function interpolateValues(values, year) {

        // A bisector since many nation's data is sparsely-defined.
        var bisect = d3.bisector(function (d) { return d[0]; });
       
        var i = parseInt(bisect.left(values, year, 0, values.length - 1)),
          a = values[i];
         
        if (i > 0) {
          var b = values[i - 1],
            t = (year - a[0]) / (b[0] - a[0]);
           
          return a[1] * (1 - t) + b[1] * t;
        }
              
        return a[1];
      }

      function make_gridlines(gridposition, gridwidth, gridheight) {
        if (gridposition.hasOwnProperty("x")) {
          // add the X gridlines
          var g = svg.append("g")
            .attr("class", "grid")
            .attr("transform", "translate(" + gridposition.x.left + "," + gridposition.x.top + ")")
            .call(make_x_gridlines(gridposition.x.scale)
              .tickSize(-gridheight)
              .tickFormat("")
            );
          g.select(".domain").remove();
        }

        if (gridposition.hasOwnProperty("y")) {
          // add the Y gridlines
          var g = svg.append("g")
            .attr("class", "grid")
            .attr("transform", "translate(" + gridposition.y.left + "," + gridposition.y.top + ")")
            .call(make_y_gridlines(gridposition.y.scale)
              .tickSize(-gridwidth)
              .tickFormat("")
            );
            g.select(".domain").remove();
        }
        svg.selectAll(".tick line").attr("stroke", "#777").attr("stroke-dasharray", "2,2");
        svg.selectAll(".tick text").attr("x", 4).attr("dy", -4);
      }
    }

    function dragged(d) {
      if (d3.event.x >= sliderScale.range()[0] && d3.event.x <= sliderScale.range()[1]) {
        d3.select(this).attr("cx", d3.event.x);
        this.displayYear(sliderScale.invert(d3.event.x));
      }
    }

    // gridlines in x axis function
    function make_x_gridlines(scale) {
      return d3.axisBottom(scale).ticks(12);
    }

    // gridlines in y axis function
    function make_y_gridlines(scale) {
      return d3.axisLeft(scale).ticks(12);
    }

    function labelname(label_str, length) {
      var label_str = label_str.split(' | ');
      var indicator_str = label_str[0].substring(0, length);
      if (label_str[0].length > length) {
        indicator_str += '..';
      }
      return indicator_str + ' | ' + label_str[1] + ' | ' + label_str[2];
    }
    $(".loaders ol li").fadeOut();
  }

  setCurrentAxisModal(param) {
    this.selectedAxisModal =  param;
  }

  filterAreaList(param) {
    var searchText = param.toLowerCase();
    $('#populateArea > li').each(function(){
        var currentLiText = $(this).text().toLowerCase(),
            showCurrentLi = currentLiText.indexOf(searchText) !== -1;
        $(this).toggle(showCurrentLi);
    }); 
  } 

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
