import { Component, OnInit } from "@angular/core";
import { HeaderService } from "../../services/header.service";
import { Configuration } from "../../app.constant";
import { ApiService } from "../../services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { Subscription } from "rxjs";
import { CommonService } from "src/app/services/common.service";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
declare var $: any;

@Component({
  selector: "app-data-summary-sheet",
  templateUrl: "./data-summary-sheet.component.html",
  styleUrls: ["./data-summary-sheet.component.css"],
})
export class DataSummarySheetComponent implements OnInit {
  subscription: Subscription;
  private url: any;
  penta;
  areaID = "IND";
  area_name = "";
  timePeriod: any;
  selectedtp: "mrd";
  head1;
  head2;
  areaLevel
  public fic = [];
  public Mr = [];
  public mapRecentTimePeriod = [];
  public categories = [];
  public series = [];
  public indicators = [];

  public categories2 = [];
  public series2 = [];
  public indicators2 = [];

  public mapLevel = 1;
  constructor(
    private headerService: HeaderService,
    private configuration: Configuration,
    private dataService: ApiService,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.getTimeperiods();
    this.url = configuration.server;
    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "sidebar":
          this.getGraphData();
          break;
        case "header":
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.area_name = message.data.data.name;
          this.areaLevel = localStorage.getItem("areaLevel")          
          this.getGraphData();
          break;
        default:
          break;
      }
    });
  }
  ngOnInit() {
    $(".loaders ol li").fadeOut();
    this.headerService.setTitle("Data Summary Sheet");
    this.headerService.setLogo("assets/lib/images/svg/sidebar/data-import.svg");
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.areaLevel = userAreaDetail.area_level
    this.getGraphData();
  }
  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodChar(timePeriod);
  }
  changeTimePeriod(event, type) {
    this.timePeriod = event.value;
    this.getGraphData();
  }

  getValue(data, indicator, time_period) {
    let value = [];
    data.forEach((row) => {
      if (row.indicator == indicator && row.time_period == time_period) {
        value["data"] = row.value;
        value["time"] = row.time_period;
        return;
      }
    });
    return value;
  }

  getSeries(data, indicators, category) {
    let that = this;
    let series = [];

    indicators.forEach((indicator) => {
      let temp = [];
      category.forEach((time_period) => {
        temp.push(that.getValue(data, indicator, time_period));
      });
      series.push({
        name: indicator,
        data: temp,
      });      
    });
    return series;
  }

  getGraphData() {
    let that = this;
    if (this.area_name == "") {
      this.area_name = "India";
    }
    $(".loaders ol li").fadeIn();
    this.dataService
      .getAll(
        "api/DataSummarySheet/" + this.timePeriod + "/" + this.areaID + "/"
      )
      .subscribe((response: any) => {
        this.mapRecentTimePeriod = response.time_period;
        this.penta = response.data;
        this.fic = response.dataFic
        this.Mr = response.dataMR
        
        let category = [];
        response.data.forEach((row) => {
          if (category.indexOf(row.time_period) == -1) {
            category.push(row.time_period);
          }
          if (that.indicators.indexOf(row.indicator) == -1) {
            that.indicators.push(row.indicator);
          }
        });
        that.series = that.getSeries(response.data, that.indicators, category);
        that.categories = category;

        //table 1
        let category2 = [];
        response.data1.forEach((row) => {
          if (category2.indexOf(row.time_period) == -1) {
            category2.push(row.time_period);
          }
          if (that.indicators2.indexOf(row.indicator) == -1) {
            that.indicators2.push(row.indicator);
          }
        });
        that.series2 = that.getSeries(
          response.data1,
          that.indicators2,
          category2
        );
        that.categories2 = category2;

        if (!that.timePeriod || that.timePeriod == "mrd") {
          that.timePeriod = this.mapRecentTimePeriod[0].time_period;
        }
        $(".loaders ol li").fadeOut();
      });
    for (let i = 0; i <= this.categories.length; i++) {
      this.head1 = this.categories[0];
      this.head2 = this.categories[1];
    }
  }

  // public captureScreen() {
  //   let downLoadSection = document.getElementById("administer") as HTMLElement;
  //   html2canvas(downLoadSection, { scale: 1 }).then(function (canvas) {
  //     let HTML_Width = canvas.width;
  //     let HTML_Height = canvas.height;
  //     let top_left_margin = 15;
  //     let PDF_Width = HTML_Width + top_left_margin * 2;
  //     let PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
  //     let canvas_image_width = HTML_Width;
  //     let canvas_image_height = HTML_Height;
  //     let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
  //     canvas.getContext("2d");
  //     let imgData = canvas.toDataURL("image/svg", 1.0);
  //     let pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
  //     pdf.addImage(
  //       imgData,
  //       "SVG",
  //       top_left_margin,
  //       top_left_margin,
  //       canvas_image_width,
  //       canvas_image_height
  //     );
  //     pdf.save("administer.pdf");
  //   });
  // }
  getTimeperiods() {
    this.dataService
      .getAll("api/month/" + this.areaID + "/")
      .subscribe((response: any) => {
        this.mapRecentTimePeriod = response.time_periods;
        this.selectedtp = this.mapRecentTimePeriod[0].time_period;
      });
  }
}
