import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, Pipe } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomInterceptor } from './services/api.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { DesignerComponent } from './dashboard_management/designer/designer.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { UserComponent } from './user_management/user/user.component';
import { FacilityComponent } from './facility_management/facility/facility.component';
import { DataImportComponent } from './data_management/data-import/data-import.component';
import { DataImportComponentNew } from './data_management/data-import-new/data-import-new.component';
import { IndicatorImportComponent } from './data_management/indicator-import/indicator-import.component';
import { IndicatorManagementComponent } from './data_management/indicator-management/indicator-management.component';
import { AreaImportComponent } from './data_management/area-import/area-import.component';
import { HeaderService } from './services/header.service';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { TreeModule } from 'angular-tree-component';
// import { ChartModule } from 'angular2-highcharts';
// import {DropdownTreeviewModule} from 'ng2-dropdown-treeview';
import { UserhomeComponent } from './user-dashboard/userhome/userhome.component';
import { OwlModule } from 'ngx-owl-carousel';

import { SharedataService } from "./services/sharedata.service";
import * as d3 from "d3v4";
import {
  PerfectScrollbarModule, PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


// https://v7.material.angular.io
import {
  //MatAutocompleteModule,
  //MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCheckboxModule,
  //MatDialogModule,
  //MatExpansionModule,
  //MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatPaginatorModule,
  MatProgressBarModule,
  //MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  //MatFormFieldControl,
  MatSlideToggleModule,
  MatSortModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  MatOptionModule,
  MatTableModule,
  MatSnackBarModule,
  MatFormFieldModule,
  //MatSelectionList,
  MatSliderModule,
  //MatListOption,


} from '@angular/material';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DialogMaterialComponent } from './dialog-material/dialog-material.component';
import { SectionComponent } from './dashboard_management/section/section.component';
import { GalleryComponent } from './gallery_management/gallery/gallery.component';
import { VisualizationComponent } from './gallery_management/visualization/visualization.component';
import { FacilityLocatorComponent } from './facility_management/facility-locator/facility-locator.component';


import { ExcelImportComponent } from './data_management/excel-import/excel-import.component';
import { CoverageComponent } from './user-dashboard/coverage/coverage.component';
import { UserOutlierComponent } from './outlier_management/user-outlier/user-outlier.component';
import { CommunicationComponent } from './user-dashboard/communication/communication.component';
import { ColdChainComponent } from './user-dashboard/cold-chain/cold-chain.component';
import { ReportComponent } from './report_management/report/report.component';
import { MapComponent } from './user-dashboard/map/map.component';
import { ClassificationInformationComponent } from './user-dashboard/classification-information/classification-information.component';
import { FlipbookComponent } from './user-dashboard/flipbook/flipbook.component';
import { AreaProfileComponent } from './user-dashboard/area-profile/area-profile.component';
import { UserGalleryManagementComponent } from './gallery_management/user-gallery-management/user-gallery-management.component';
import { OutlierComponent } from './dashboard_management/outlier/outlier.component';
import { ReportMgmComponent } from './dashboard_management/report-mgm/report-mgm.component';
// import { BubbleComponent } from './outlier_management/bubble/bubble.component';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ParticlesModule } from 'angular-particle'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { RankComponent } from './user-dashboard/rank/rank.component';
import { RankAreaComponent } from './user-dashboard/rank_Area/rank_Area.component';
import { BubbleComponent } from './user-dashboard/bubble/bubble.component';
import { InViewportModule } from 'ng-in-viewport';
import 'intersection-observer';
import { DefaultchartImportComponent } from './data_management/defaultchart-import/defaultchart-import.component';
import { CommonService } from './services/common.service';
import { MetadataImportComponent } from './data_management/metadata-import/metadata-import.component';
import { DataViewComponent } from './data_management/data-view/data-view.component';
import { CustomFilterPipePipe } from './services/custom-filter-pipe.pipe';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
// import { CustomFilterPipePipe } from './services/custom-filter-pipe.pipe';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FlipbookManagementComponent } from './dashboard_management/flipbook-management/flipbook-management.component';
import { ImportLogComponent } from './import-log/import-log.component';
import { UserManualComponent } from './user-dashboard/user-manual/user-manual.component';
import { UserSupportComponent } from './user-dashboard/user-support/user-support.component';
import { TargetImportComponent } from './data_management/target-import/target-import.component';
import { ReportMrComponent } from './fact_sheets/report-mr/report-mr.component';
import { MrSheetComponent } from './fact_sheets/mr-sheet/mr-sheet.component';
import { MapSheetComponent } from './fact_sheets/map-sheet/map-sheet.component';
import { GraphSheetComponent } from './fact_sheets/graph-sheet/graph-sheet.component';
import { ZeroDoseComponent } from './fact_sheets/zero-dose/zero-dose.component';
import { NFHSMapComponent } from './fact_sheets/nfhs-map/nfhs-map.component';
import { ImmunizationComponent } from './fact_sheets/immunization/immunization.component';
import { AdministeredVaccineComponent } from './fact_sheets/administered-vaccine/administered-vaccine.component';
import { StateProfileFactSheeetComponent } from './fact_sheets/state-profile-fs/state-profile-fs.component'
import { ColumnGraphComponent } from './fact_sheets/components/column-graph.component';
import { DashboardGraphComponent } from './report_management/dashBoard/dashboard-graph/dashboard-graph.component';
import { DashboardMapComponent } from './report_management/dashBoard/dashboard-map/dashboard-map.component';
import { ComparisanTableComponent } from './report_management/dashBoard/comparisan_dash/comparisan-table/comparisan-table.component';
import { GraphPage1Component } from './report_management/dashBoard/hmis_dash/graph-page1/graph-page1.component';
import { GraphPage2Component } from './report_management/dashBoard/hmis_dash/graph-page2/graph-page2.component';
import { GraphPage3Component } from './report_management/dashBoard/hmis_dash/graph-page3/graph-page3.component';

import { ConcurrentMonTableComponent } from './report_management/dashBoard/concurrent-mon_dash/concurrent-mon-table/concurrent-mon-table.component';
import { ConmmunicationTableComponent } from './report_management/dashBoard/communication_dash/conmmunication-table/conmmunication-table.component';
import { MrPage1Component } from './report_management/dashBoard/mr_dash/mr-page1/mr-page1.component';
import { MrPage2Component } from './report_management/dashBoard/mr_dash/mr-page2/mr-page2.component';
import { ConcurrentMonDataComponent } from './report_management/dashBoard/concurrent-mon_dash/concurrent-mon-data/concurrent-mon-data.component';
import { GraphChartPage1Component } from './report_management/dashBoard/graph-charts/graph-chart-page1/graph-chart-page1.component';
import { GraphChartPage2Component } from './report_management/dashBoard/graph-charts/graph-chart-page2/graph-chart-page2.component';
import { GraphFICComponent } from './report_management/dashBoard/graph-charts/graph-fic/graph-fic.component';
import { GraphHepBComponent } from './report_management/dashBoard/graph-charts/graph-hep-b/graph-hep-b.component';
import { GraphPenta1Component } from './report_management/dashBoard/graph-charts/graph-penta1/graph-penta1.component';
import { GraphMr1Component } from './report_management/dashBoard/graph-charts/graph-mr1/graph-mr1.component';
import { GraphMr2Component } from './report_management/dashBoard/graph-charts/graph-mr2/graph-mr2.component';
import { GraphDptBComponent } from './report_management/dashBoard/graph-charts/graph-dpt-b/graph-dpt-b.component';
import { GraphPage4Component } from './report_management/dashBoard/hmis_dash/graph-page4/graph-page4.component';
import { GraphPage5Component } from './report_management/dashBoard/hmis_dash/graph-page5/graph-page5.component';
import { GraphPage6Component } from './report_management/dashBoard/hmis_dash/graph-page6/graph-page6.component';
import { GraphPage7Component } from './report_management/dashBoard/hmis_dash/graph-page7/graph-page7.component';
import { Penta1Penta3Component } from './report_management/dashBoard/graph-charts/penta1-penta3/penta1-penta3.component';
import { Penta1Mr1Component } from './report_management/dashBoard/graph-charts/penta1-mr1/penta1-mr1.component';
import { Mr1Mr2Component } from './report_management/dashBoard/graph-charts/mr1-mr2/mr1-mr2.component';
import { SessionComponent } from './report_management/dashBoard/graph-charts/session/session.component';
import { ConcurrentMonPage3Component } from './report_management/dashBoard/concurrent-mon_dash/concurrent-mon-page3/concurrent-mon-page3.component';
import { DashboardColumnGraphComponent } from './report_management/dashBoard/dashboard-column-graph/dashboard-column-graph.component';
import { Page35Component } from './report_management/dashBoard/graph-charts/page35/page35.component';
import { DashboardLineGraphComponent } from './report_management/dashBoard/dashboard-line-graph/dashboard-line-graph.component';
import { DashboardMainComponent } from './report_management/dashBoard/dashboard-main/dashboard-main.component';
import { DashboardMultiSeriesGraphComponent } from './report_management/dashBoard/dashboard-multi-series-graph/dashboard-multi-series-graph.component';
import { ImmuDashboardComponent } from './report_management/dashBoard/immu-dashboard/immu-dashboard.component';
import { ImmuDashboardTitleComponent } from './report_management/dashBoard/immu-dashboard-title/immu-dashboard-title.component';
import { ImmuDashboardPage3Component } from './report_management/dashBoard/immu-dashboard-page3/immu-dashboard-page3.component';
import { ImmuDashboardLastpageComponent } from './report_management/dashBoard/immu-dashboard-lastpage/immu-dashboard-lastpage.component';
import { DashboardMultiLineGraphComponent } from './report_management/dashboard-multi-line-graph/dashboard-multi-line-graph.component';
import { MultiLineGraphComponent } from './report_management/dashBoard/multi-line-graph/multi-line-graph.component';
import { HomeTableComponent } from './home-table/home-table.component';
import { ExecutiveDashComponent } from './report_management/dashBoard/executive-dash/executive-dash.component';
import { HmistableComponent } from './report_management/dashBoard/hmis_dash/hmistable/hmistable.component';
import { Hmistable2Component } from './report_management/dashBoard/hmis_dash/hmistable2/hmistable2.component';
import { Hmistable3Component } from './report_management/dashBoard/hmis_dash/hmistable3/hmistable3.component';
import { Hmistable4NumberComponent } from './report_management/dashBoard/hmis_dash/hmistable4-number/hmistable4-number.component';
import { Hmistable5DropoutComponent } from './report_management/dashBoard/hmis_dash/hmistable5-dropout/hmistable5-dropout.component';
import { Hmistable6DreComponent } from './report_management/dashBoard/hmis_dash/hmistable6-dre/hmistable6-dre.component';
import { Hmistable7DptComponent } from './report_management/dashBoard/hmis_dash/hmistable7-dpt/hmistable7-dpt.component';
import { GraphPage8Component } from './report_management/dashBoard/hmis_dash/graph-page8/graph-page8.component';
import { Table1Component } from './report_management/dashBoard/aefi_dash/table1/table1.component';
import { DistrictCountTableComponent } from './report_management/dashBoard/mr_dash/district-count-table/district-count-table.component';
import { DataSummarySheetComponent } from './fact_sheets/data-summary-sheet/data-summary-sheet.component';
import { PreloadlistComponent } from './Preload/preloadlist/preloadlist.component';
import { PreloadDataComponent } from './Preload/preload-data/preload-data.component';
import { ItemMappingComponent } from './Mapping_management/item-mapping/item-mapping.component';
import { AreaMappingComponent } from './Mapping_management/area-mapping/area-mapping.component';
import { PieChartGraphComponent } from './report_management/dashBoard/pie-chart-graph/pie-chart-graph.component';
// import { Table1Component } from './report_management/dashBoard/hmis_dash/table1/table1.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    DesignerComponent,
    SidebarComponent,
    CustomFilterPipePipe,
    UserComponent,
    FacilityComponent,
    FacilityLocatorComponent,
    DataImportComponent,
    DataImportComponentNew,
    IndicatorImportComponent,
    TargetImportComponent,
    ReportMrComponent,
    IndicatorManagementComponent,
    AreaImportComponent,
    DialogMaterialComponent,
    SectionComponent,
    ExcelImportComponent,
    GalleryComponent,
    VisualizationComponent,
    CoverageComponent,
    UserOutlierComponent,
    StateProfileFactSheeetComponent,
    CommunicationComponent,
    ColdChainComponent,
    MapComponent,
    UserhomeComponent,
    ClassificationInformationComponent,
    FlipbookComponent,
    AreaProfileComponent,
    UserGalleryManagementComponent,
    OutlierComponent,
    ReportMgmComponent,
    ReportComponent,
    RankComponent,
    RankAreaComponent,
    BubbleComponent,
    DefaultchartImportComponent,
    MetadataImportComponent,
    DataViewComponent,
    ResetPasswordComponent,
    FlipbookManagementComponent,
    ImportLogComponent,
    UserManualComponent,
    UserSupportComponent,
    MrSheetComponent,
    MapSheetComponent,
    GraphSheetComponent,
    ZeroDoseComponent,
    NFHSMapComponent,
    ImmunizationComponent,
    AdministeredVaccineComponent,
    ColumnGraphComponent,
    DashboardGraphComponent,
    DashboardMapComponent,
    ComparisanTableComponent,
    GraphPage1Component,
    GraphPage2Component,
    GraphPage3Component,
    Table1Component,
    ConcurrentMonTableComponent,
    ConmmunicationTableComponent,
    MrPage1Component,
    MrPage2Component,
    ConcurrentMonDataComponent,
    GraphChartPage1Component,
    GraphChartPage2Component,
    GraphFICComponent,
    GraphHepBComponent,
    GraphPenta1Component,
    GraphMr1Component,
    GraphMr2Component,
    GraphDptBComponent,
    GraphPage4Component,
    GraphPage5Component,
    GraphPage6Component,
    GraphPage7Component,
    Penta1Penta3Component,
    Penta1Mr1Component,
    Mr1Mr2Component,
    SessionComponent,
    ConcurrentMonPage3Component,
    DashboardColumnGraphComponent,
    Page35Component,
    DashboardLineGraphComponent,
    DashboardMainComponent,
    DashboardMultiSeriesGraphComponent,
    ImmuDashboardComponent,
    ImmuDashboardTitleComponent,
    ImmuDashboardPage3Component,
    ImmuDashboardLastpageComponent,
    DashboardMultiLineGraphComponent,
    MultiLineGraphComponent,
    HomeTableComponent,
    ExecutiveDashComponent,
    HmistableComponent,
    Hmistable2Component,
    Hmistable3Component,
    Hmistable4NumberComponent,
    Hmistable5DropoutComponent,
    Hmistable6DreComponent,
    Hmistable7DptComponent,
    GraphPage8Component,
    DistrictCountTableComponent,
    DataSummarySheetComponent,
    PreloadlistComponent,
    PreloadDataComponent,
    ItemMappingComponent,
    AreaMappingComponent,
    PieChartGraphComponent,
  
    // ZoreDoseComponent,
    // CustomFilterPipePipe

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatRippleModule,
    MatPaginatorModule,
    MatSortModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatIconModule,
    MatDatepickerModule,
    MatTableModule,
    NgxMatSelectSearchModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatOptionModule,
    MatCheckboxModule,
    MatSelectModule,
    MatCheckboxModule,
    MatSnackBarModule,
    NgSelectModule,
    MatSliderModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MatProgressBarModule,
    TreeModule.forRoot(),
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatListModule,
    TreeModule.forRoot(),
    MatInputModule,
    MatTreeModule,
    OwlModule,
    LeafletModule.forRoot(),
    ParticlesModule,
    PerfectScrollbarModule,
    ParticlesModule,
    CKEditorModule,
    InViewportModule,
    NgxDatatableModule,
    ToastrModule.forRoot(),
    InfiniteScrollModule
  ],
  providers: [HeaderService,

    CommonService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true
    }, SharedataService,
  ],
  entryComponents: [DialogMaterialComponent],
  bootstrap: [AppComponent],
  schemas : [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
