import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-graph-chart-page1',
  templateUrl: './graph-chart-page1.component.html',
  styleUrls: ['./graph-chart-page1.component.css']
})
export class GraphChartPage1Component implements OnInit {
  @Input() timePeriod: any;
  constructor(private commonService: CommonService,) { }

  ngOnInit() {}

  getYear(timePeriod) {
    return this.commonService.getFinancialFirstYear(timePeriod);
  }
  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodFullChar(timePeriod);
  }
}
