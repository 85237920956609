import { Component, OnInit } from "@angular/core";
import { Fileupload } from "blueimp-file-upload/js/jquery.fileupload";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { HeaderService } from "../../services/header.service";
import { Configuration } from "../../app.constant";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../services/api.service";

declare var $: any;

@Component({
  selector: "app-metadata-import",
  templateUrl: "./metadata-import.component.html",
  styleUrls: ["./metadata-import.component.css"]
})
export class MetadataImportComponent implements OnInit {
  selectedFiles: FileList;
  fileName: string;
  fileTypeErrorMsg: boolean = false;

  constructor(
    private headerService: HeaderService,
    private conf: Configuration,
    private dataService: ApiService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.headerService.setTitle("Import Metadata");
    this.headerService.setLogo("assets/lib/images/svg/sidebar/data-import.svg");
  }

  ngAfterViewInit() {
    $(".loaders ol li").fadeOut();
    $(document).on("change", "#fileupload_metadata", function(e) {
      that.detectFiles(e);
    });

    let that = this;

    //$(function () {

    //'use strict';
    // Change this to the location of your server-side upload handler:
    var url = that.conf.server + "api/datamanager/data/upload";
    $("#fileupload_metadata")
      .fileupload({
        maxChunkSize: 2000000,
        acceptFileTypes: /(\.|\/)(csv)$/i,
        url: url,
        dataType: "json",
        formData: {
          import_for: 7
        },
        add: function(e, data) {
          $("#upload")
            .off("click")
            .on("click", function() {
              data.submit();
            });
        },
        beforeSend: function(xhr, data) {
          xhr.setRequestHeader("Accept", "application/json");
          xhr.setRequestHeader(
            "Authorization",
            `Bearer ${localStorage.getItem("auth_token")}`
          );
        },
        done: function(e, data) {
          $.each(data.result.files, function(index, file) {
            $("<p/>")
              .text(file.name)
              .appendTo("#files");
          });
          that.fileUploadMessage();
        },
        error: function(jqXHR, textStatus, errorThrown) {
          if (errorThrown == "Bad Request") {
           that.inValidFileUploadMsg();
          }
        },
        progressall: function(e, data) {
          var loaded: number = data.loaded;
          var total: number = data.total;
          var tempCalc = (loaded / total) * 100;

          var progress = parseInt(tempCalc.toString(), 10);

          $("#progress .progress-bar").css("width", progress + "%");
        }
      })
      .prop("disabled", !$.support.fileInput)
      .parent()
      .addClass($.support.fileInput ? undefined : "disabled");
    //});
  }

  fileUploadMessage() {
    this.toastr.success(
      "File uploaded successfully. We will send you mail once file is imported"
    );
  }
  validFilesType = ["csv"];
  detectFiles(event) {
    this.selectedFiles = event.target.files;
    this.fileName = this.selectedFiles[0].name;
    var ext = this.selectedFiles[0].name
      .split(".")
      .pop()
      .toLowerCase();

    if (this.validFilesType.indexOf(ext) != -1) {
      // this.detectFiles(event);
      this.fileTypeErrorMsg = false;
    } else {
      console.log("invalid ");
      $("#fileupload_metadata").val("");
      this.fileName = "";

      this.toastr.error(
        "Invalid File. Valid extensions are:\n\n" +
          this.validFilesType.join(", ")
      );
      this.fileTypeErrorMsg = true;
    }
  }

  downloadFile(type) {
     this.dataService
        .getAll("api/datamanager/data/download/7/" + type)
        .subscribe((response: any) => {
          let a = document.createElement("a");
          a.href = response.file;
          let last = response.file.substring(
            response.file.lastIndexOf("/") + 1,
            response.file.length
          );
          a.href = response.file;
          a.download = last;
            document.body.appendChild(a);
          setTimeout(() => {
            a.click();
          }, 1000);
          document.body.removeChild(a);
        });
  }
  chooseFile() {
    document.getElementById("fileupload_metadata").click();
  }
  inValidFileUploadMsg() {
    this.toastr.error('Invalid File Format');
  }
}
