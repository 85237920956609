import { ApiService } from '../../services/api.service';
import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import {  FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { HeaderService } from '../../services/header.service';
import { DialogMaterialComponent } from '../../dialog-material/dialog-material.component';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { SelectionModel } from '@angular/cdk/collections';
import { ITreeOptions, TreeComponent, ITreeState } from 'angular-tree-component';
import { DatatableComponent } from '@swimlane/ngx-datatable';
declare var $: any;


@Component({
  selector: 'app-item-mapping',
  templateUrl: './item-mapping.component.html',
  styleUrls: ['./item-mapping.component.css']
})
export class ItemMappingComponent implements OnInit {

  state: ITreeState;
  isDisabled = true;
  nodes = [];
  rowId;
  area;
  options: ITreeOptions = {
    actionMapping: {
      mouse: {
        click: (tree, node, $event) => {          
          this.sendData(node.data);
          this.addUserForm.get('areaName').patchValue(node.data.name);
          this.area = node.data.id
          $('#select_area').html(node.data.id);
          $('.area-list').removeClass('open');
        }
      },
    },
  }

  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  @ViewChild(DatatableComponent) table: DatatableComponent;
 
  disableEmail = false;
  form_open: boolean = false;
  showEdit: boolean = false;
  titlePage: string = 'Add';
  addUserForm: FormGroup;
  states = [];
  roles: object;
  state_code: any;
  dataSource: MatTableDataSource<any>;
  validationError: any;
  selection = new SelectionModel<any>(true, []);
  selectedItems: any = [];
  selected = "";
  rows: any = [];
  parentid
  parentName
 

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('selectElem') selectElem: ElementRef;
  @ViewChild('tree') tree: TreeComponent;
  /**
   * constructor to intialise all the required libraries
   * @param dataService 
   * @param formBuilder 
   * @param headerService 
   * @param dialog 
   * @param matsnack 
   * @param toastr 
   */
  constructor(
    private dataService: ApiService,
    public formBuilder: FormBuilder,
    private headerService: HeaderService,
    public dialog: MatDialog,
    public matsnack: MatSnackBar,
    private toastr: ToastrService,
  ) {
  }
  ngOnInit() {
   
    this.headerService.setTitle('Item Mapping');
    this.headerService.setLogo('assets/lib/images/svg/header-menu/user.svg');
    this.createAddUserForm();
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('need-head');
    this.getAllUsers();

  }
  
  
  /**
   * method that execute on changing the value of matInput
   * @param filterValue 
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  /**
   * function to create addUser form
   */
  createAddUserForm() {
    this.addUserForm = this.formBuilder.group({
      item_name: [''],
      item_code: ['', Validators.required],
      old_item_code: [''],
      old_item_name: [''],
  
    });
  }


  /**
   * function to add a new user 
   * @param formVal // data of the form
   */
  public addNewUser(formVal) {
    this.validationError = [];
    this.dataService.add('api/itemmapping/add', formVal).subscribe((response: any) => {
      if (response.status == 0) {
        this.validationError = response.error;
        
      }
      else {
        this.toastr.success(response.message);
        this.addUserForm.reset();
        this.clearFormGroupDirective();
        setTimeout(() => {
          this.form_open = !this.form_open;
        }, 2000);
        this.getAllUsers();
      }
    });
  }

  /**
   * function to get form value data
   */
  getFormValuesData() {
    this.dataService.getAll('api/get-form-values').subscribe((response: any) => {
      this.states = response.data.states;
      this.roles = response.data.userRoles;
      this.states.forEach(element => {
        this.state_code = element.area_code;
      });
    })
  }
  /**
   * function to get the list of all users
   */
  getAllUsers() {
    $(".loaders ol li").fadeIn();
    this.getFormValuesData();
    this.dataService
      .getAll<any[]>('api/itemmapping/all')
      .subscribe(
        (res: any) => {
          $(".loaders ol li").fadeOut();
          this.rows = res.data.records;       
        },
        error => () => {
          console.log('Error')
        },
        () => { }
      );
  }
 
  /**
   * function to open the form popup to edit the selected the user
   * @param id // id of selected user
   */
  openForm(rowData) {  
    $('#error_text').html('');
    this.form_open = !this.form_open;
    if (rowData !== undefined) {
      this.titlePage = 'Edit';
      this.showEdit = true;
      this.disableEmail = true;
      // patch the form value  form the response to  form fields
      this.addUserForm.patchValue({
        id: rowData.id,
        item_name: rowData.item_name,
        item_code: rowData.item_code,        
        old_item_code: rowData.old_item_code,
        old_item_name: rowData.old_item_name
           
      });
      this.rowId = rowData.id
    } 
    else {
      this.titlePage = 'Add'
      this.showEdit = false;
      this.disableEmail = false;
      this.addUserForm.reset();
      $('#select_area').html('Select location');
    }
    this.selected = "";
  }

 
  editSelectedData(formVal) {
    this.dataService.update('api/itemmapping/update', this.rowId, formVal).subscribe((response: any) => {
      this.toastr.success(response.message);
      this.addUserForm.reset();
      this.clearFormGroupDirective();
      setTimeout(() => {
        this.form_open = !this.form_open;
      }, 2000);
      //function to get the list of users after adding a user
      this.getAllUsers();
    })
  }

  clearFormGroupDirective() {
    this.formGroupDirective.resetForm();
  }


  sendData(data): void {    
    this.addUserForm.get('area').setValue(data.code)
    this.addUserForm.get('level').setValue(data.level);
  }
  
  onSelect({ selected }) {
    this.selectedItems = [];
    this.selectedItems.splice(0, this.selectedItems.length);
    if (selected.length == 1) {
      this.selectedItems.push(selected[0].id);
    } else if (selected.length > 1) {
      for (let i = 0; i < selected.length; i++) {
        this.selectedItems.push(selected[i].id);
      }
    }
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.rows.filter(function (d) {     
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;      
    });

    // update the rows
    this.rows = temp;

    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }
 
   /**
   * function to edit the user data
   * @param id // id of selected user
   */
   deleteData(id) {
    const dialog = this.dialog.open(DialogMaterialComponent, {
      width: '350px',
      data: { messageDialog: 'Are you sure to delete ?', delete: true }
    });
    dialog.afterClosed()
      .subscribe(selection => {
        if (selection) {
          this.dataService.delete('api/itemmapping/delete', id).subscribe((response: any) => {           
            this.toastr.success(response.message);
          
            this.getAllUsers();
          })
        } else {
        }
      });
  }

}
