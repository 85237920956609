import { Component, OnInit,Injectable,AfterViewInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {HttpClient} from "@angular/common/http";
import { _ } from 'underscore';
import { HeaderService } from '../../services/header.service';
import { map } from 'rxjs/operators';



declare var $:any;
declare var GridStackUI:any;


import { chart } from 'highcharts';
import * as Highcharts from 'highcharts';

var chartObj: {[k: string]: any} = {};

@Component({
  selector: 'app-designer',
  templateUrl: './designer.component.html',
  styleUrls: ['./designer.component.css']
})

@Injectable()
export class DesignerComponent implements OnInit {
  
  
  chartOptions;
  selfObj: any;

  constructor(private route: ActivatedRoute , private headerService: HeaderService) { }
  
  // onclick sidebar call
    gallery_open : boolean = false;
    gallery_open1 : boolean = false;
    gallery_open2 : boolean = true;

    design_sidebar(){
      this.gallery_open = !this.gallery_open;
    }
    gallery_drag(){
      this.gallery_open1 = !this.gallery_open1;
      this.gallery_open2 = !this.gallery_open2;
    }
        

  ngOnInit() {
    
     this.headerService.setTitle('Customize Dashboard');
  // 	this.route.params.subscribe(params => {
		// this.blogParams = params['title'];
  //   });

  // 	console.log(this.blogParams);

  
  }

  ngAfterViewInit(){
     
     var selfObj = this;

     $(function () {
            // thanks to http://stackoverflow.com/a/22885503
            var waitForFinalEvent=function(){var b={};return function(c,d,a){a||(a="I am a banana!");b[a]&&clearTimeout(b[a]);b[a]=setTimeout(c,d)}}();
            var fullDateString = new Date();
            function isBreakpoint(alias) {
                return $('.device-' + alias).is(':visible');
            }


            var options = {
                
                float: false,
                animate: true,
                removable: '.trash',
                removeTimeout: 100,
                acceptWidgets: '.grid-stack-item'
            };

            
            $('.grid-stack').gridstack(options);

            function resizeGrid() {
                var grid = $('.grid-stack').data('gridstack');
                if (isBreakpoint('xs')) {
                    $('#grid-size').text('One column mode');
                } else if (isBreakpoint('sm')) {
                    grid.setGridWidth(3);
                    $('#grid-size').text(3);
                } else if (isBreakpoint('md')) {
                    grid.setGridWidth(6);
                    $('#grid-size').text(6);
                } else if (isBreakpoint('lg')) {
                    grid.setGridWidth(12);
                    $('#grid-size').text(12);
                }
            };

            $(window).resize(function () {
                waitForFinalEvent(function() {
                    resizeGrid();
                }, 300, fullDateString.getTime());
            });

            $('.sidebar .grid-stack-item').draggable({
                revert: 'invalid',
                handle: '.grid-stack-item-content',
                scroll: false,
                appendTo: 'body'
            });


            new function () {
                
                this.serializedData = selfObj.getLayout();

                this.grid = $('.grid-stack').data('gridstack');

                this.loadGrid = function () {
                    this.grid.removeAll();
                    var items = GridStackUI.Utils.sort(this.serializedData);
                    _.each(items, function (node, i) {
                        this.grid.addWidget($('<div data-chartId="' + node.chartId + '"><div class="grid-stack-item-content" id="' + node.chartId + '">' + node.chartId + '</div></div>'),
                            node.x, node.y, node.width, node.height, true, null, null, null, null,node.chartId);
                    }.bind(this));
                    return false;
                }.bind(this);

                this.loadGrid();
                resizeGrid();
                selfObj.loadChart();
                

            };

            $('.sidebar1 .grid-stack-item').draggable({
                helper: 'clone',
                revert: 'invalid',
                handle: '.grid-stack-item-content',
                scroll: false,
                appendTo: 'body',
                start: function (event, ui) {

                    $(this).addClass('editcontent');
                   // $(this).addClass('addContent');

                    //console.log($('.design-start .editableDiv').html());

                    //$(this).find('.editableDiv').css({"bottom":"5px"});
                },
                stop: function (event, ui) {
                    
                    
                    $(ui.helper[0]).children().attr('contenteditable', true);

                    //renderCharts($chartid);
                    //$(this).next().addClass('my_class'); 
                    $('.design-start .editableDiv').attr('contenteditable', 'true');
                    $('.design-start .editableDiv').addClass('addContent');
                    $('.gallery-sidebar').removeClass('width250');
                    return;
                }

            });

            $('.grid-stack-item').on('resizestop', function() {

                var gridCell = $(this),
                node = $(this).data('_gridstack_node');

                chartObj[node.id].reflow();

              
            });
     });
    
  }


  loadChart() {
   ///dashboard grid chart populate
   let elem= document.getElementById("hi-1")
   let elem2= document.getElementById("hi-2")
   let elem3= document.getElementById("hi-3")



    this.chartOptions = {
      chart: {
        type: 'bar'
      },
      title: {
        text: 'Fruit Consumption'
      },
      xAxis: {
        categories: ['Apples', 'Bananas', 'Oranges']
      },
      yAxis: {
        title: {
          text: 'Fruit eaten'
        }
      },
      series: [{
        name: 'Jane',
        data: [1, 0, 4]
      }, {
        name: 'John',
        data: [5, 7, 3]
      }]
    };
    

    chartObj['hi-1'] = chart(elem, this.chartOptions);
    chartObj['hi-2'] = chart(elem2, this.chartOptions);
    chartObj['hi-3'] = chart(elem3, this.chartOptions);
    
  }

  getLayout() {
      return [
                    {x: 0, y: 0, width: 4, height: 2,chartId:'hi-1'},
                    {x: 3, y: 1, width: 4, height: 2,chartId:'hi-2'},
                    {x: 4, y: 1, width: 4, height: 1,chartId:'hi-3'},     
                ];
  }
}
