import { Component, OnInit, Input } from "@angular/core";
import { _ } from "underscore";

import { Subscription } from "rxjs";
import { Configuration } from "../../../../app.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-page35",
  templateUrl: "./page35.component.html",
  styleUrls: ["./page35.component.css"],
})
export class Page35Component implements OnInit {
  @Input() areaID: any;
  @Input() area_name: any;
  @Input() timePeriod: any;
  subscription: Subscription;
  private url: any;
  data = [];
  public penta = [];
  public penta1 = [];
  public mr1 = [];
  public mr2 = [];
  public Fic = [];

  public datapenta1 = [];
  public datamr1 = [];
  public datamr2 = [];

  public mapLevel = 1;
  public source = 1;
  loading=true;
  graph = "graph";
  graph1 = "graph1";
  graph2 = "graph2";
  graph3 = "graph3";
  graph4 = "graph4";

  // timePeriod: any;
  constructor(
    private dataService: ApiService,
    private configuration: Configuration,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.url = configuration.server;
    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "header":
          this.source = 1;
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.getAllPenta();
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {}

  ngOnChanges() {
    this.getAllPenta();

    this.graph = "graph" + this.area_name;

    this.graph1 = this.area_name + "_graph1";
    this.graph2 = this.area_name + "_graph2";
    this.graph3 = this.area_name + "_graph3";
    this.graph4 = this.area_name + "_graph4";
  }
  ngAfterViewInit(): void {}


  getFinancialYear(timePeriod){
    return this.commonService.getFinancialYear(timePeriod);
  }
  getAllPenta() {
    let that = this;
    this.dataService
      .getAll("api/page35/" + this.timePeriod + "/" + this.area_name + "/")
      .subscribe((response: any) => {

        this.loading=false;
        that.penta = response.datafic;
        that.datapenta1 = response.datapenta1;
        that.datamr1 = response.datamr1;
        that.datamr2 = response.datamr2;

        that.area_name = that.penta[0].area_name;
        let penta1 = [];
        let mr1 = [];
        let mr2 = [];
        let Fic = [];
        let district;
        let pentaIn = "Pentavalent 1st Dose",
          FicIn = "Full Immunization Coverage",
          mr1In = "MR 1st Dose",
          mr2In = "MR-2nd Dose";
        that.penta.forEach((row) => {
          Fic.push([row.time_period, row.value, row.area_code, FicIn]);
        });
        that.datapenta1.forEach((row) => {
          penta1.push([row.time_period, row.value, row.area_code, pentaIn]);
        });
        that.datamr1.forEach((row) => {
          mr1.push([row.time_period, row.value, row.area_code, mr1In]);
        });
        that.datamr2.forEach((row) => {
          mr2.push([row.time_period, row.value, row.area_code, mr2In]);
        });

        this.Fic = Fic;
        this.penta1 = penta1;
        this.mr1 = mr1;
        this.mr2 = mr2;
      });
  }

  getObjectKey(object) {
    if (object) {
      return Object.keys(object);
    }

    return [];
  }
}
