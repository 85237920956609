import {
  Component,
  OnInit,
  Injectable,
  AfterViewInit,
  Input,
} from "@angular/core";
import { HeaderService } from "../../services/header.service";
import { Configuration } from "../../app.constant";
import { ApiService } from "../../services/api.service";
import { ToastrService } from "ngx-toastr";
import { SharedataService } from "src/app/services/sharedata.service";
import { Subscription, SubscriptionLike } from "rxjs";
import { CommonService } from "src/app/services/common.service";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

declare var L: any;
declare var $: any;
var locations = [];

@Component({
  selector: "app-zero-dose",
  templateUrl: "./zero-dose.component.html",
  styleUrls: ["./zero-dose.component.css"],
})
export class ZeroDoseComponent implements AfterViewInit {
  // @Input() zerograph: any;
  // @Input() id: any;
  chart: any;
  mapOptions = {
    zoom: 13,
    zoomSnap: 0.15,
    center: L.latLng(2.74031066894531, 11.102173805236816),
  };
  map: any;
  subscription: Subscription;
  private url: any;
  data = [];
  public penta = [];
  public penta1 = [];
  public zero = [];
  public zeroNum = [];
  public nfhs5 = [];
  public nfhs4 = [];
  nfhs5fic = "NA";
  nfhs5zero = "NA";
  areaID = "IND";
  area_name = "IND";
  num;
  district = "NA";
  timePeriod: "mrd";
  selectedtp: "mrd";
  public mapLevel = 1;
  public nh = [];
  public source = 1;
  public mapRecentTimePeriod = [];
  public year: any;
  infant = {};
  constructor(
    private headerService: HeaderService,
    private configuration: Configuration,
    private dataService: ApiService,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.getTimeperiods();
    this.url = configuration.server;
    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "header":
          this.source = 1;
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.getAllPenta();
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {
    $(".loaders ol li").fadeIn();
    this.headerService.setTitle("Zero dose children fact sheet");
    this.headerService.setLogo("assets/lib/images/svg/sidebar/data-import.svg");
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getAllPenta();
  }
  ngAfterViewInit(): void {}
  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodChar(timePeriod);
  }

  getFinancialYear(timePeriod) {
    return this.commonService.getFinancialYear(timePeriod);
  }

  changeIus(event, type) {
    alert(event);
  }

  getColorClass(value) {
    if (value < 3) {
      return "#00E040";
    }
    if (value > 5) {
      return "#F50102";
    }
    return "#FE8D02";
  }

  getRoundValue(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  getPerValue(numerator, denominator) {
    var value = this.getRoundValue((numerator / denominator) * 100, 1);
    if (value) {
      return value;
    }
    return 0;
  }

  changeTimePeriod(event, type) {
    this.timePeriod = event.value;
    this.getAllPenta();
  }

  getAllPenta() {
    let that = this;
    $(".loaders ol li").fadeIn();
    this.dataService
      .getAll("api/zero-dose/" + this.timePeriod + "/" + this.areaID + "/")
      .subscribe((response: any) => {
        that.penta = response.data;
        that.area_name = that.penta[0].area_name;
        that.num = that.penta[0].zero + "(" + that.penta[0].zeroper + " %)";

        that.nh = response.nfhs5;
        that.mapRecentTimePeriod = response.time_period;
        let penta1 = [];
        let zero = [];
        let zeroNum = [];
        let nfhs5 = [];
        let nfhs4 = [];

        response.infant.forEach((row) => {
          that.infant[row.area_name] = row.target;
        });

        that.penta.forEach((row) => {
          var dem = that.infant[row.area_name];
          penta1.push([
            row.area_name,
            that.getPerValue(row.penta, dem),
            row.area_code,
          ]);
          zero.push([row.area_name, row.zeroper, row.area_code]);
          zeroNum.push([row.area_name, row.zero, row.area_code]);
        });
        response.nfhs5.forEach((row) => {
          nfhs5.push([row.area_name, row.value, row.area_code]);
        });

        response.nfhs4.forEach((row) => {
          nfhs4.push([row.area_name, row.value, row.area_code]);
        });

        that.penta1 = penta1;
        that.zero = zero;
        that.zeroNum = zeroNum;
        that.nfhs5 = nfhs5;
        that.nfhs4 = nfhs4;

        if (!that.timePeriod || that.timePeriod == "mrd") {
          that.timePeriod = this.mapRecentTimePeriod[0].time_period;
        }

        try {
          that.district = response.district[0].district;
          that.nfhs5fic = response.nfhs5fic[0].value;
          that.nfhs5zero = response.nfhs5zero[0].value;
        } catch (e) {
          console.log(e);
        }
      });
  }

  arr: any;
  getinfant(area_name) {
    if (this.infant[area_name]) {
      this.arr = this.infant[area_name];
      return this.arr;
    } else {
      return "NA";
    }
  }
  getObjectKey(object) {
    if (object) {
      return Object.keys(object);
    }
    return [];
  }

  public captureScreen() {
    let downLoadSection = document.getElementById("zeropdf") as HTMLElement;
    html2canvas(downLoadSection, { scale: 1 }).then(function (canvas) {
      let HTML_Width = canvas.width;
      let HTML_Height = canvas.height;
      let top_left_margin = 15;
      let PDF_Width = HTML_Width + top_left_margin * 2;    
      let PDF_Height = PDF_Width * 1.5 + top_left_margin * 5.5;
      let canvas_image_width = HTML_Width;
      let canvas_image_height = HTML_Height;
      let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
      canvas.getContext("2d");
      let imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
      pdf.addImage(
        imgData,
        "JPG",
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height
      );
      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage([PDF_Width, PDF_Height], "p");
        pdf.addImage(
          imgData,
          "JPG",
          top_left_margin,
          -(PDF_Height * i) + top_left_margin * 4,
          canvas_image_width,
          canvas_image_height
        );
      }
      pdf.save("ZeroDose.pdf");
    });
  }

  getTimeperiods() {
    this.dataService
      .getAll("api/month/" + this.areaID + "/")
      .subscribe((response: any) => {
        this.mapRecentTimePeriod = response.time_periods;
        this.selectedtp = this.mapRecentTimePeriod[0].time_period;
      });
  }
}
