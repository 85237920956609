import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { Component, ViewChild, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { HeaderService } from '../../services/header.service';
import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DialogMaterialComponent } from '../../dialog-material/dialog-material.component';
import { HttpHeaders,HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-excel-import',
  templateUrl: './excel-import.component.html',
  styleUrls: ['./excel-import.component.css']
})
export class ExcelImportComponent implements OnInit {

  dashboardId;	
  form_open: boolean = false;
  addForm: FormGroup;
  dashboardRecords= [];
  ourFile: File;
  base64Image = '';
  showEdit: boolean = false;
  titlePage: string;
  validationError : any;
  importId: number;
  selectedFile: File;
url;
  displayedColumns = ['id', 'section_name', 'status','created_at','actions'];
  dataSource: MatTableDataSource<any>;

  optionType = [{'id':0,'name':'Default'},{'id':1,'name':'Row'},{'id':2,'name':'Column'}];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private dataService: ApiService, 
     private headerService: HeaderService,
     private form: FormBuilder,
     private toastr: ToastrService,
     public dialog: MatDialog,
     private route: ActivatedRoute,
     private http: HttpClient) {
  }

  ngOnInit() {

  	this.route.params.subscribe(params => {
		this.dashboardId = params['id'];
  	});

  	this.headerService.setTitle('Dashboard Section Management');
    //this.headerService.setTitle('Data Summary');
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('need-head');
    
    this.addForm = this.form.group({
       excel_file: ['', Validators.required ],
       data_start: ['', Validators.required ],
       indicator: ['', Validators.required ],
       indicator_data_type: ['', Validators.required ],
       unit: ['', Validators.required ],
       unit_data_type: ['', Validators.required ],
       subgroup: ['', Validators.required ],
       subgroup_data_type: ['', Validators.required ],
       area: ['', Validators.required ],
       area_data_type: ['', Validators.required ],
       timeperiod: ['', Validators.required ],
       timeperiod_data_type: ['', Validators.required ],
       source: ['', Validators.required ],
       source_data_type: ['', Validators.required ],
       data_value: ['', Validators.required ],
       data_value_type: ['', Validators.required ],

    });    

    this.getAll();

  }

  
  /**
   * method that execute on changing the value of matInput
   * @param filterValue 
   */
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  /**
   * method that executes  to intiliaze the table with data
   */
  getAll() {
  	/**
     * api call to get all the records from dataService
     */
    this.dataService
      .getById<any[]>('api/dashboard/section',this.dashboardId)
      .subscribe(
        (res: any) => {
          this.dataSource = new MatTableDataSource(res.data.records);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
           this.dashboardRecords = res.data.records
        },
        error => () => {
          console.log('Error')
        },
        () => {
          //this.toasterService.pop('success', 'Complete', 'Getting all values complete');
          //this.slimLoadingBarService.complete();
        }
      );
  }

  /**
   *  method that executes on submitting form
   */
  add() {
    
    //avoid adding content type if image is uploading
    const httpHeaders = new HttpHeaders ({
     'isImageUpload': '1'
     // 'Accept': '' 
   	});

    var uploadData = new FormData();
    uploadData.append('excel_file', this.selectedFile);
    
    for ( var key in this.addForm.value ) {
	    uploadData.append(key, this.addForm.value[key]);
	}

    this.validationError = [];
    this.dataService
      .addWithImage<any[]>('api/datamanager/data/excel/upload',uploadData)
      .subscribe(
        (res: any) => {

          if(res.status == 0) {
            this.validationError = res.error; 
          } else {
            // toaster message on successfully submitting data to the table
            this.toastr.success('Section added successfully');
           
            // reset the form after submitting form data
            this.addForm.reset();
             // close the form modal
             this.form_open = !this.form_open;
            // get all the data  in the table
            this.getAll();
  
          }
                  },
        error => () => {
          console.log('Error')
        },
        () => {
          //this.toasterService.pop('success', 'Complete', 'Getting all values complete');
          //this.slimLoadingBarService.complete();
        }
      );
  }


  /**
   *  method that executes on submitting form
   */
  update() {
    //api call to add a dashboard
    this.addForm.value.dashboard_id = this.dashboardId;

    this.validationError = [];
    this.dataService
      .update<any[]>('api/dashboard/section/update',this.importId,this.addForm.value)
      .subscribe(
        (res: any) => {

          if(res.status == 0) {
            this.validationError = res.error; 
          } else {
            // toaster message on successfully submitting data to the table
            this.toastr.success('Section updated successfully');
           
            // reset the form after submitting form data
            this.addForm.reset();
             // close the form modal
             this.form_open = !this.form_open;
            // get all the data  in the table
            this.getAll();
  
          }
                  },
        error => () => {
          console.log('Error')
        },
        () => {
          //this.toasterService.pop('success', 'Complete', 'Getting all values complete');
          //this.slimLoadingBarService.complete();
        }
      );
  }


  /**
   *  method that trigger the click event on input type file on button click
   */
  openInput(){
    document.getElementById("logo").click();
  }
  
  /**
   * method that executes on  deletion of record
   * @param id // id of deleted record
   */
  deleteDashboard(id){
    const dialog = this.dialog.open(DialogMaterialComponent, {
      width: '350px',
      data: { messageDialog: 'Are you sure for the deletion of record ?', delete: true }
    });
    dialog.afterClosed()
      .subscribe(selection => {
        if (selection) {
          this.dataService.delete('api/user/delete', id).subscribe((response: any) => {
            // show toaster message on successfully deleting the user
            this.toastr.success('Selected dashboard deleted successfully');
            // get all the users after deleting the user
            this.getAll();
          })
        } else {
          // User clicked 'Cancel' or clicked outside the dialog
        }
      });
  }


  openForm(rowData){

  	this.validationError = [];
    
    this.form_open = !this.form_open;   
    if(rowData !== undefined){
      this.titlePage = 'Edit'
      this.showEdit = true;
      this.addForm.patchValue({
        section_name: rowData.name,
      });

      this.importId = rowData.id;
    }
    else{
      this.titlePage = 'New'
      this.showEdit = false;
    }
       
  }


  fileChange(event): void {
	  	
  	this.selectedFile = event.target.files[0];
  }
    
}
