import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class GetCaseTableService {

    constructor() { }

    public tableCase1<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        let ius_split = ius.split('|');
        tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px">';
        tableHtml += '</tr>';
        tableHtml += '<tr>'
        tableHtml += '<th class="pointer">Area</th>';
        tableHtml += '<th class="pointer">Data value</th>';
        tableHtml += '</tr>';
        for (let j = 0; j < data.length; j++) {
            if (data[j].area_name == area[0] && data[j].ius == ius[0] && data[j].time_period == time_period[0]) {
                tableHtml += '<tr>'
                tableHtml += '<td>' + data[j].area_name + '</td>';
                tableHtml += '<td>' + Number(data[j].value).toLocaleString() + '</td>';
                tableHtml += '</tr>';
            }
        }
        tableHtml += '</table>';
        return tableHtml;
    }

    public tableCase2<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        let ius_split = ius[0].split('|');
        tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
        tableHtml += '<tr>';
        tableHtml += '<th class="pointer">Time Period</th>';
        tableHtml += '<th class="pointer">Data Value</th>';
        tableHtml += '</tr>';
        for (let i = 0; i < time_period.length; i++) {
            let exist = 0;
            for (var j = 0, len = data.length; j < len; j++) {
                if (data[j].area_name == area[0] && data[j].ius == ius[0] && data[j].time_period == time_period[i]) {
                    exist = 1;//return myArray[i]; // Return as soon as the object is found
                    break;
                }
            }
            var table_data = data[j];
            if (exist == 1) {
                tableHtml += '<tr>'
                tableHtml += '<td>' + table_data.time_period + '</td>';
                tableHtml += '<td>' + Number(table_data.value).toLocaleString() + '</td>';
                tableHtml += '</tr>';
            }
        }
        tableHtml += '</table>';
        return tableHtml;
    }

    public tableCase3<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        let ius_split = ius[0].split('|');
        tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
        tableHtml += '</tr>';
        tableHtml += '<tr>'
        tableHtml += '<th class="pointer">Area</th>';
        tableHtml += '<th class="pointer">Data Value</th>';
        tableHtml += '</tr>';
        for (let i = 0; i < area.length; i++) {
            let exist = 0;
            for (var j = 0, len = data.length; j < len; j++) {
                if (data[j].area_name == area[i] && data[j].ius == ius[0] && data[j].time_period == time_period[0]) {
                    exist = 1;//return myArray[i]; // Return as soon as the object is found
                    break;
                }
            }
            var table_data = data[j];
            if (exist == 1) {
                tableHtml += '<tr>'
                tableHtml += '<td>' + table_data.area_name + '</td>';
                tableHtml += '<td>' + Number(table_data.value).toLocaleString() + '</td>';
                tableHtml += '</tr>';

            }
        }
        tableHtml += '</table>';
        return tableHtml;
    }

    public tableCase4<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        let ius_split = ius[0].split('|');
        tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
        tableHtml += '<tr>'
        tableHtml += '<th class="pointer">Area</th>';
        for (let k = 0; k < time_period.length; k++) {
            tableHtml += '<th class="pointer">' + time_period[k] + '</th>';
        }
        tableHtml += '</tr>';
        for (let i = 0; i < area.length; i++) {
            tableHtml += '<tr>';
            tableHtml += '<td>' + area[i] + '</td>';
            for (let k = 0; k < time_period.length; k++) {
                let exist = 0;
                for (var j = 0, len = data.length; j < len; j++) {
                    if (data[j].area_name == area[i] && data[j].ius == ius[0] && data[j].time_period == time_period[k]) {
                        exist = 1;//return myArray[i]; // Return as soon as the object is found
                        break;
                    }
                }

                if (exist == 1) {
                    var table_data = data[j];
                    tableHtml += '<td>' + Number(table_data.value).toLocaleString() + '</td>';
                }
                else {
                    tableHtml += '<td>-</td>';
                }
            }
            tableHtml += '</tr>';
        }
        tableHtml += '</table>';
        return tableHtml;
    }

    public tableCase5<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';

        var ind_unit = [];
        var ind_unit_subgroup = [];
        var ind_temp = [];
        var unit_temp = [];
        var sub_temp = [];
        for (let i = 0; i < ius.length; i++) {
            let temp_ius_split = ius[i].split('|');
            if (ind_temp.indexOf(temp_ius_split[0]) == -1) {
                ind_temp.push(temp_ius_split[0]);
            }
            if (unit_temp.indexOf(temp_ius_split[1]) == -1) {
                unit_temp.push(temp_ius_split[1]);
            }
            if (sub_temp.indexOf(temp_ius_split[2]) == -1) {
                sub_temp.push(temp_ius_split[2]);
            }
            if (ind_unit.indexOf(temp_ius_split[0] + '|' + temp_ius_split[1]) == -1) {
                ind_unit.push(temp_ius_split[0] + '|' + temp_ius_split[1]);
            }
            if (ind_unit_subgroup.indexOf(ius[i]) == -1) {
                ind_unit_subgroup.push(ius[i]);
            }
        }
        if (ind_temp.length == 1 && unit_temp.length == 1 && sub_temp.length > 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px">';
            tableHtml += '<tr>';
            tableHtml += '<th class="pointer">Area</th>';
            for (var i = 0; i < ind_unit_subgroup.length; i++) {
                let ius_full = ind_unit_subgroup[i].split('|');
                let subgroup = ius_full[2];
                tableHtml += '<th class="pointer">' + subgroup + '</th>';
            }
            tableHtml += '</tr>';
            tableHtml += '<tr>'
            tableHtml += '<td>' + area[0] + '</td>';
            for (var i = 0; i < ind_unit_subgroup.length; i++) {
                let exist = 0;
                for (var j = 0, len = data.length; j < len; j++) {
                    if (data[j].area_name == area[0] && data[j].ius == ind_unit_subgroup[i] && data[j].time_period == time_period[0]) {
                        exist = 1;//return myArray[i]; // Return as soon as the object is found
                        break;
                    }
                }
                var table_data = data[j];
                if (exist == 1) {
                    tableHtml += '<td>' + Number(table_data.value).toLocaleString() + '</td>';

                }
                else {
                    tableHtml += '<td>-</td>';
                }
            }
            tableHtml += '</tr>';
            tableHtml += '</table>';
        }

        return tableHtml;
    }

    public tableCase6<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var ind_unit = [];
        var ind_unit_subgroup = [];
        var ind_temp = [];
        var unit_temp = [];
        var sub_temp = [];
        for (let i = 0; i < ius.length; i++) {
            let temp_ius_split = ius[i].split('|');
            if (ind_temp.indexOf(temp_ius_split[0]) == -1) {
                ind_temp.push(temp_ius_split[0]);
            }
            if (unit_temp.indexOf(temp_ius_split[1]) == -1) {
                unit_temp.push(temp_ius_split[1]);
            }
            if (sub_temp.indexOf(temp_ius_split[2]) == -1) {
                sub_temp.push(temp_ius_split[2]);
            }
            if (ind_unit.indexOf(temp_ius_split[0] + '|' + temp_ius_split[1]) == -1) {
                ind_unit.push(temp_ius_split[0] + '|' + temp_ius_split[1]);
            }
            if (ind_unit_subgroup.indexOf(ius[i]) == -1) {
                ind_unit_subgroup.push(ius[i]);
            }
        }
        if (ind_temp.length == 1 && unit_temp.length == 1 && sub_temp.length > 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>';
            tableHtml += '<th class="pointer">Area</th>';
            for (var i = 0; i < sub_temp.length; i++) {
                tableHtml += '<th class="pointer">' + sub_temp[i] + '</th>';
                tableHtml += '<th class="pointer  hide" ></th>';
            }
            tableHtml += '</tr>';
            var str = '';
            var $globalTimeOut = 0;
            for (let i = 0; i < area.length; i++) {
                str = '';
                let filter = 0;
                str += '<tr>';
                str += '<td>' + area[i] + '</td>';
                for (let k = 0; k < sub_temp.length; k++) {
                    let exist = 0;
                    for (var j = 0, len = data.length; j < len; j++) {
                        if (data[j].area_name == area[i] && data[j].ius == ind_unit[0] + '|' + sub_temp[k] && data[j].time_period == time_period[0]) {
                            exist = 1;//return myArray[i]; // Return as soon as the object is found
                            break;
                        }
                    }
                    var table_data = data[j];
                    if (exist == 1) {
                        let filter = 1;
                        str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';
                    }
                    else {
                        str += '<td>-</td>';
                    }
                }
                str += '</tr>';
                if (filter == 1) {
                    tableHtml += str;
                }
            }
            tableHtml += '</table>';
        }

        return tableHtml;
    }

    public tableCase7<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';

        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length == 1 && unit_table.length == 1 && subgroup_table.length > 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Area</th>';
            tableHtml += '<th class="pointer">Time Period</th>';
            for (var i = 0; i < subgroup_table.length; i++) {
                tableHtml += '<th class="pointer">' + subgroup_table[i] + '</th>';
                tableHtml += '<th class="hide" ></th>';
            }
            tableHtml += '</tr>';
            for (let m = 0; m < area.length; m++) {
                for (let k = 0; k < time_period.length; k++) {
                    let str = '';
                    let filter = 0;
                    str += '<tr>'
                    str += '<td>' + area[m] + '</td>';
                    str += '<td>' + time_period[k] + '</td>';
                    for (var i = 0; i < subgroup_table.length; i++) {
                        let exist = 0;
                        for (var j = 0, len = data.length; j < len; j++) {
                            if (data[j].area_name == area[m] && data[j].ius == indicator_table[0] + '|' + unit_table[0] + '|' + subgroup_table[i] && data[j].time_period == time_period[k]) {
                                exist = 1;//return myArray[i]; // Return as soon as the object is found
                                break;
                            }
                        }
                        var table_data = data[j];
                        if (exist == 1) {
                            filter = 1;
                            str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';
                        }
                        else {
                            str += '<td>-</td>';
                        }
                    }
                    str += '</tr>';
                    if (filter == 1) {
                        tableHtml += str;
                    }
                }
            }
            tableHtml += '</table>';
        }

        return tableHtml;
    }

    public tableCase8<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length > 1 && unit_table.length == 1 && subgroup_table.length == 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Area</th>';
            tableHtml += '<th class="pointer">Time Period</th>';
            for (var i = 0; i < indicator_table.length; i++) {
                tableHtml += '<th class="pointer">' + indicator_table[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            for (let m = 0; m < area.length; m++) {
                for (let k = 0; k < time_period.length; k++) {
                    let str = '';
                    let filter = 0;
                    str += '<tr>'
                    str += '<td>' + area[m] + '</td>';
                    str += '<td>' + time_period[k] + '</td>';
                    for (var i = 0; i < indicator_table.length; i++) {
                        let exist = 0;
                        for (var j = 0, len = data.length; j < len; j++) {
                            if (data[j].area_name == area[m] && data[j].ius == indicator_table[i] + '|' + unit_table[0] + '|' + subgroup_table[0] && data[j].time_period == time_period[k]) {
                                exist = 1;//return myArray[i]; // Return as soon as the object is found
                                break;
                            }
                        }
                        var table_data = data[j];
                        if (exist == 1) {
                            filter = 1;
                            str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';
                        }
                        else {
                            str += '<td>-</td>';
                        }
                    }
                    str += '</tr>';

                    if (filter == 1) {
                        tableHtml += str;
                    }
                }
            }
            tableHtml += '</table>';
        }

        return tableHtml;
    }

    public tableCase9<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }

        if (indicator_table.length > 1 && unit_table.length > 1 && subgroup_table.length == 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Area</th>';
            tableHtml += '<th class="pointer">Time Period</th>';
            for (var i = 0; i < main_iu.length; i++) {
                tableHtml += '<th class="pointer">' + main_iu[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            for (let m = 0; m < area.length; m++) {
                for (let k = 0; k < time_period.length; k++) {
                    let str = '';
                    let filter = 0;
                    str += '<tr>'
                    str += '<td>' + area[m] + '</td>';
                    str += '<td>' + time_period[k] + '</td>';
                    for (var i = 0; i < main_iu.length; i++) {
                        let exist = 0;
                        for (var j = 0, len = data.length; j < len; j++) {
                            if (data[j].area_name == area[m] && data[j].ius == main_iu[i] + '|' + subgroup_table[0] && data[j].time_period == time_period[k]) {
                                exist = 1;//return myArray[i]; // Return as soon as the object is found
                                break;
                            }
                        }
                        var table_data = data[j];
                        if (exist == 1) {
                            filter = 1;
                            str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';
                        }
                        else {
                            str += '<td>-</td>';
                        }
                    }
                    str += '</tr>';

                    if (filter == 1) {
                        tableHtml += str;
                    }
                }
            }
            tableHtml += '</table>';
        }

        return tableHtml;
    }

    public tableCase10<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length > 1 && unit_table.length > 1 && subgroup_table.length > 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Area</th>';
            tableHtml += '<th class="pointer">Time Period</th>';
            for (var i = 0; i < main_ius.length; i++) {
                tableHtml += '<th class="pointer">' + main_ius[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            for (let m = 0; m < area.length; m++) {
                for (let k = 0; k < time_period.length; k++) {
                    let str = '';
                    let filter = 0;
                    str += '<tr>'
                    str += '<td>' + area[m] + '</td>';
                    str += '<td>' + time_period[k] + '</td>';
                    for (var i = 0; i < main_ius.length; i++) {
                        let exist = 0;
                        for (var j = 0, len = data.length; j < len; j++) {
                            if (data[j].area_name == area[m] && data[j].ius == main_ius[i] && data[j].time_period == time_period[k]) {
                                exist = 1;//return myArray[i]; // Return as soon as the object is found
                                break;
                            }
                        }
                        var table_data = data[j];
                        if (exist == 1) {

                            filter = 1;
                            str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';
                        }
                        else {
                            str += '<td>-</td>';
                        }
                    }
                    str += '</tr>';

                    if (filter == 1) {
                        tableHtml += str;
                    }
                }
            }
            tableHtml += '</table>';
        }

        return tableHtml;

    }

    public tableCase11<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length == 1 && unit_table.length > 1 && subgroup_table.length == 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Area, Time Period</th>';
            for (var i = 0; i < unit_table.length; i++) {
                tableHtml += '<th class="pointer">' + unit_table[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            for (let m = 0; m < area.length; m++) {
                for (let k = 0; k < time_period.length; k++) {
                    let str = '';
                    let filter = 0;
                    str += '<tr>'
                    str += '<td>' + area[m] + ', ' + time_period[k] + '</td>';
                    for (var i = 0; i < unit_table.length; i++) {
                        let exist = 0;
                        for (var j = 0, len = data.length; j < len; j++) {
                            if (data[j].area_name == area[m] && data[j].ius == indicator_table[0] + '|' + unit_table[i] + '|' + subgroup_table[0] && data[j].time_period == time_period[k]) {
                                exist = 1;//return myArray[i]; // Return as soon as the object is found
                                break;
                            }
                        }
                        var table_data = data[j];
                        if (exist == 1) {
                            filter = 1;
                            str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';
                        }
                        else {
                            str += '<td>-</td>';
                        }
                    }
                    str += '</tr>';

                    if (filter == 1) {
                        tableHtml += str;
                    }
                }
            }
            tableHtml += '</table>';
        }

        return tableHtml;

    }

    public tableCase12<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length == 1 && unit_table.length > 1 && subgroup_table.length == 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Time Period</th>';
            for (var i = 0; i < unit_table.length; i++) {
                tableHtml += '<th class="pointer" onclick="sortTable(this);">' + unit_table[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            for (let k = 0; k < time_period.length; k++) {
                let str = '';
                let filter = 0;
                str += '<tr>'
                str += '<td>' + time_period[k] + '</td>';
                for (var i = 0; i < unit_table.length; i++) {
                    let exist = 0;
                    for (var j = 0, len = data.length; j < len; j++) {
                        if (data[j].area_name == area[0] && data[j].ius == indicator_table[0] + '|' + unit_table[i] + '|' + subgroup_table[0] && data[j].time_period == time_period[k]) {
                            exist = 1;//return myArray[i]; // Return as soon as the object is found
                            break;
                        }
                    }
                    var table_data = data[j];
                    if (exist == 1) {
                        filter = 1;
                        str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';

                    }
                    else {
                        str += '<td>-</td>';
                    }
                }
                str += '</tr>';

                if (filter == 1) {
                    tableHtml += str;
                }
            }
            tableHtml += '</table>';
        }

        return tableHtml;

    }

    public tableCase13<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var ind_unit = [];
        var ind_unit_subgroup = [];
        var ind_temp = [];
        var unit_temp = [];
        var sub_temp = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (ind_temp.indexOf(ind) == -1) {
                ind_temp.push(ind);
            }
            if (unit_temp.indexOf(unit) == -1) {
                unit_temp.push(unit);
            }
            if (sub_temp.indexOf(subgroup) == -1) {
                sub_temp.push(subgroup);
            }
            if (ind_unit.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                ind_unit.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (ind_unit_subgroup.indexOf(ius[i]) == -1) {
                ind_unit_subgroup.push(ius[i]);
            }
        }
        if (ind_temp.length == 1 && unit_temp.length > 1 && sub_temp.length == 1) {
            var split_ius = ind_unit_subgroup[0].split('|');
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Area</th>';
            for (var i = 0; i < ind_unit.length; i++) {
                var split_iu = ind_unit[i].split('|');
                tableHtml += '<th class="pointer">' + split_iu[1] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            let str = '';
            let filter = 0;
            str += '<tr>'
            str += '<td>' + area[0] + '</td>';
            for (var i = 0; i < ind_unit_subgroup.length; i++) {
                let exist = 0;
                for (var j = 0, len = data.length; j < len; j++) {
                    if (data[j].area_name == area[0] && data[j].ius == ind_unit_subgroup[i] && data[j].time_period == time_period[0]) {
                        exist = 1;//return myArray[i]; // Return as soon as the object is found
                        break;
                    }
                }
                var table_data = data[j];
                if (exist == 1) {
                    filter = 1;
                    str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';
                }
                else {
                    str += '<td>-</td>';
                }
            }
            str += '</tr>';

            if (filter == 1) {
                tableHtml += str;
            }
            tableHtml += '</table>';
        }
        return tableHtml;

    }

    public tableCase14<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length > 1 && unit_table.length == 1 && subgroup_table.length == 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>';
            tableHtml += '<th class="pointer">Area</th>';
            for (var i = 0; i < indicator_table.length; i++) {
                tableHtml += '<th class="pointer">' + indicator_table[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            let str = '';
            let filter = 0;
            str += '<tr>'
            str += '<td>' + area[0] + '</td>';
            for (var i = 0; i < indicator_table.length; i++) {
                let exist = 0;
                for (var j = 0, len = data.length; j < len; j++) {
                    if (data[j].name == area[0] && data[j].ius == indicator_table[i] + '|' + unit_table[0] + '|' + subgroup_table[0] && data[j].time_period == time_period[0]) {
                        exist = 1;//return myArray[i]; // Return as soon as the object is found
                        break;
                    }
                }
                var table_data = data[j];
                if (exist == 1) {
                    filter = 1;
                    str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';

                }
                else {
                    str += '<td>-</td>';
                }
            }
            str += '</tr>';

            if (filter == 1) {
                tableHtml += str;
            }
            tableHtml += '</table>';
        }
        return tableHtml;
    }

    public tableCase15<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_is = [];
        var main_us = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_is.indexOf(ind + '|' + subgroup) == -1) {
                main_is.push(ind + '|' + subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_us.indexOf(unit + '|' + subgroup) == -1) {
                main_us.push(unit + '|' + subgroup);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length == 1 && unit_table.length > 1 && subgroup_table.length > 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Area</th>';
            for (var i = 0; i < main_us.length; i++) {
                tableHtml += '<th class="pointer">' + main_us[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            let str = '';
            let filter = 0;
            str += '<tr>'
            str += '<td>' + area[0] + '</td>';
            for (var i = 0; i < main_us.length; i++) {
                let exist = 0;
                for (var j = 0, len = data.length; j < len; j++) {
                    if (data[j].area_name == area[0] && data[j].ius == indicator_table[0] + '|' + main_us[i] && data[j].time_period == time_period[0]) {
                        exist = 1;//return myArray[i]; // Return as soon as the object is found
                        break;
                    }
                }
                var table_data = data[j];
                if (exist == 1) {
                    filter = 1;
                    str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';

                }
                else {
                    str += '<td>-</td>';
                }
            }
            str += '</tr>';

            if (filter == 1) {
                tableHtml += str;
            }
            tableHtml += '</table>';
        }
        return tableHtml;
    }

    public tableCase16<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length > 1 && unit_table.length > 1 && subgroup_table.length == 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Area</th>';
            for (var i = 0; i < main_iu.length; i++) {
                tableHtml += '<th class="pointer">' + main_iu[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            let str = '';
            let filter = 0;
            str += '<tr>'
            str += '<td>' + area[0] + '</td>';
            for (var i = 0; i < main_iu.length; i++) {
                let exist = 0;
                for (var j = 0, len = data.length; j < len; j++) {
                    if (data[j].area_name == area[0] && data[j].ius == main_iu[i] + '|' + subgroup_table[0] && data[j].time_period == time_period[0]) {
                        exist = 1;//return myArray[i]; // Return as soon as the object is found
                        break;
                    }
                }
                var table_data = data[j];
                if (exist == 1) {
                    filter = 1;
                    str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';
                }
                else {
                    str += '<td>-</td>';
                }
            }
            str += '</tr>';

            if (filter == 1) {
                tableHtml += str;
            }
            tableHtml += '</table>';
        }

        return tableHtml;

    }

    public tableCase17<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length == 1 && unit_table.length == 1 && subgroup_table.length > 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Time Period</th>';
            for (var i = 0; i < subgroup_table.length; i++) {
                tableHtml += '<th class="pointer">' + subgroup_table[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            var $globalTimeOut1 = 0;
            for (let k = 0; k < time_period.length; k++) {
                let str = '';
                let filter = 0;
                str += '<tr>'
                str += '<td>' + time_period[k] + '</td>';
                for (var i = 0; i < subgroup_table.length; i++) {
                    let exist = 0;
                    for (var j = 0, len = data.length; j < len; j++) {
                        if (data[j].area_name == area[0] && data[j].ius == indicator_table[0] + '|' + unit_table[0] + '|' + subgroup_table[i] && data[j].time_period == time_period[k]) {
                            exist = 1;//return myArray[i]; // Return as soon as the object is found
                            break;
                        }
                    }
                    var table_data = data[j];
                    if (exist == 1) {
                        filter = 1;
                        str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';

                    }
                    else {
                        str += '<td>-</td>';
                    }
                }
                str += '</tr>';
                if (filter == 1) {
                    tableHtml += str;
                }
            }
            tableHtml += '</table>';
        }

        return tableHtml;

    }

    public tableCase18<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length > 1 && unit_table.length == 1 && subgroup_table.length == 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Time Period</th>';
            for (var i = 0; i < indicator_table.length; i++) {
                tableHtml += '<th class="pointer">' + indicator_table[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';

            for (let k = 0; k < time_period.length; k++) {
                let str = '';
                let filter = 0;
                str += '<tr>'
                str += '<td>' + time_period[k] + '</td>';
                for (var i = 0; i < indicator_table.length; i++) {
                    let exist = 0;
                    for (var j = 0, len = data.length; j < len; j++) {
                        if (data[j].area_name == area[0] && data[j].ius == indicator_table[i] + '|' + unit_table[0] + '|' + subgroup_table[0] && data[j].time_period == time_period[k]) {
                            exist = 1;//return myArray[i]; // Return as soon as the object is found
                            break;
                        }
                    }
                    var table_data = data[j];
                    if (exist == 1) {
                        filter = 1;
                        str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';

                    }
                    else {
                        str += '<td>-</td>';

                    }
                }
                str += '</tr>';

                if (filter == 1) {
                    tableHtml += str;
                }
            }
            tableHtml += '</table>';
        }

        return tableHtml;

    }

    public tableCase19<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length > 1 && unit_table.length == 1 && subgroup_table.length == 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Area</th>';
            for (var i = 0; i < indicator_table.length; i++) {
                tableHtml += '<th class="pointer">' + indicator_table[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            for (let m = 0; m < area.length; m++) {
                let str = '';
                let filter = 0;
                str += '<tr>'
                str += '<td>' + area[m] + '</td>';
                for (var i = 0; i < indicator_table.length; i++) {
                    let exist = 0;
                    for (var j = 0, len = data.length; j < len; j++) {
                        if (data[j].area_name == area[m] && data[j].ius == indicator_table[i] + '|' + unit_table[0] + '|' + subgroup_table[0] && data[j].time_period == time_period[0]) {
                            exist = 1;//return myArray[i]; // Return as soon as the object is found
                            break;
                        }
                    }
                    var table_data = data[j];
                    if (exist == 1) {
                        filter = 1;
                        str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';
                    }
                    else {
                        str += '<td>-</td>';

                    }
                }
                str += '</tr>';

                if (filter == 1) {
                    tableHtml += str;
                }
            }
            tableHtml += '</table>';
        }
        return tableHtml;
    }

    public tableCase20<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_is = [];
        var main_us = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_is.indexOf(ind + '|' + subgroup) == -1) {
                main_is.push(ind + '|' + subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_us.indexOf(unit + '|' + subgroup) == -1) {
                main_us.push(unit + '|' + subgroup);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length > 1 && unit_table.length == 1 && subgroup_table.length > 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Area</th>';
            for (var i = 0; i < main_is.length; i++) {
                tableHtml += '<th class="pointer">' + main_is[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            let str = '';
            let filter = 0;
            str += '<tr>'
            str += '<td>' + area[0] + '</td>';
            for (var i = 0; i < main_is.length; i++) {

                var split_is = main_is[i].split('|');
                let exist = 0;
                for (var j = 0, len = data.length; j < len; j++) {
                    if (data[j].area_name == area[0] && data[j].ius == split_is[0] + '|' + unit_table[0] + '|' + split_is[1] && data[j].time_period == time_period[0]) {
                        exist = 1;//return myArray[i]; // Return as soon as the object is found
                        break;
                    }
                }
                var table_data = data[j];
                if (exist == 1) {
                    filter = 1;
                    str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';

                }
                else {
                    str += '<td>-</td>';
                }
            }
            str += '</tr>';

            if (filter == 1) {
                tableHtml += str;
            }
            tableHtml += '</table>';
        }

        return tableHtml;

    }

    public tableCase21<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_is = [];
        var main_us = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_is.indexOf(ind + '|' + subgroup) == -1) {
                main_is.push(ind + '|' + subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_us.indexOf(unit + '|' + subgroup) == -1) {
                main_us.push(unit + '|' + subgroup);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length == 1 && unit_table.length > 1 && subgroup_table.length > 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Area, Time period</th>';
            for (var i = 0; i < main_us.length; i++) {
                tableHtml += '<th class="pointer">' + main_us[i] + '</th>';
            }
            tableHtml += '</tr>';
            for (let m = 0; m < area.length; m++) {
                for (let k = 0; k < time_period.length; k++) {
                    let str = '';
                    let filter = 0;
                    str += '<tr>'
                    str += '<td>' + area[m] + ',' + time_period[k] + '</td>';
                    for (var i = 0; i < main_us.length; i++) {
                        let exist = 0;
                        for (var j = 0, len = data.length; j < len; j++) {
                            if (data[j].area_name == area[m] && data[j].ius == indicator_table[0] + '|' + main_us[i] && data[j].time_period == time_period[k]) {
                                exist = 1;//return myArray[i]; // Return as soon as the object is found
                                break;
                            }
                        }
                        var table_data = data[j];
                        if (exist == 1) {
                            filter = 1;
                            str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';

                        }
                        else {
                            str += '<td>-</td>';
                        }
                    }
                    str += '</tr>';

                    if (filter == 1) {
                        tableHtml += str;
                    }
                }
            }
            tableHtml += '</table>';
        }
        return tableHtml;
    }

    public tableCase22<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length == 1 && unit_table.length > 1 && subgroup_table.length == 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Area</th>';
            for (var i = 0; i < unit_table.length; i++) {
                tableHtml += '<th class="pointer">' + unit_table[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            for (let m = 0; m < area.length; m++) {
                let str = '';
                let filter = 0;
                str += '<tr>'
                str += '<td>' + area[m] + '</td>';
                for (var i = 0; i < unit_table.length; i++) {
                    let exist = 0;
                    for (var j = 0, len = data.length; j < len; j++) {
                        if (data[j].area_name == area[m] && data[j].ius == indicator_table[0] + '|' + unit_table[i] + '|' + subgroup_table[0] && data[j].time_period == time_period[0]) {
                            exist = 1;//return myArray[i]; // Return as soon as the object is found
                            break;
                        }
                    }
                    var table_data = data[j];
                    if (exist == 1) {
                        filter = 1;
                        str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';
                    }
                    else {
                        str += '<td>-</td>';
                    }
                }
                str += '</tr>';

                if (filter == 1) {
                    tableHtml += str;
                }
            }
            tableHtml += '</table>';
        }
        return tableHtml;
    }

    public tableCase23<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_is = [];
        var main_us = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_is.indexOf(ind + '|' + subgroup) == -1) {
                main_is.push(ind + '|' + subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_us.indexOf(unit + '|' + subgroup) == -1) {
                main_us.push(unit + '|' + subgroup);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length == 1 && unit_table.length > 1 && subgroup_table.length > 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Time period</th>';
            for (var i = 0; i < main_us.length; i++) {
                tableHtml += '<th class="pointer">' + main_us[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            for (let k = 0; k < time_period.length; k++) {
                let str = '';
                let filter = 0;
                str += '<tr>'
                str += '<td>' + time_period[k] + '</td>';
                for (var i = 0; i < main_us.length; i++) {
                    let exist = 0;
                    for (var j = 0, len = data.length; j < len; j++) {
                        if (data[j].area_name == area[0] && data[j].ius == indicator_table[0] + '|' + main_us[i] && data[j].time_period == time_period[k]) {
                            exist = 1;//return myArray[i]; // Return as soon as the object is found
                            break;
                        }
                    }
                    var table_data = data[j];
                    if (exist == 1) {
                        filter = 1;
                        str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';
                    }
                    else {
                        str += '<td>-</td>';

                    }
                }
                str += '</tr>';

                if (filter == 1) {
                    tableHtml += str;
                }
            }
            tableHtml += '</table>';
        }

        return tableHtml;

    }

    public tableCase24<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_is = [];
        var main_us = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_is.indexOf(ind + '|' + subgroup) == -1) {
                main_is.push(ind + '|' + subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_us.indexOf(unit + '|' + subgroup) == -1) {
                main_us.push(unit + '|' + subgroup);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length == 1 && unit_table.length > 1 && subgroup_table.length > 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Area</th>';
            for (var i = 0; i < main_us.length; i++) {
                tableHtml += '<th class="pointer">' + main_us[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            for (let m = 0; m < area.length; m++) {
                let str = '';
                let filter = 0;
                str += '<tr>'
                str += '<td>' + area[m] + '</td>';
                for (var i = 0; i < main_us.length; i++) {
                    let exist = 0;
                    for (var j = 0, len = data.length; j < len; j++) {
                        if (data[j].area_name == area[m] && data[j].ius == indicator_table[0] + '|' + main_us[i] && data[j].time_period == time_period[0]) {
                            exist = 1;//return myArray[i]; // Return as soon as the object is found
                            break;
                        }
                    }
                    var table_data = data[j];
                    if (exist == 1) {
                        filter = 1;
                        str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';

                    }
                    else {
                        str += '<td>-</td>';
                    }
                }
                str += '</tr>';

                if (filter == 1) {
                    tableHtml += str;
                }
            }
            tableHtml += '</table>';
        }

        return tableHtml;

    }

    public tableCase25<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_is = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_is.indexOf(ind + '|' + subgroup) == -1) {
                main_is.push(ind + '|' + subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length > 1 && unit_table.length == 1 && subgroup_table.length > 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Area</th>';
            for (var i = 0; i < main_is.length; i++) {
                tableHtml += '<th class="pointer" onclick="sortTable(this);">' + main_is[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            for (let m = 0; m < area.length; m++) {
                let str = '';
                let filter = 0;
                str += '<tr>'
                str += '<td>' + area[m] + '</td>';
                for (var i = 0; i < main_is.length; i++) {
                    var split_is = main_is[i].split('|');
                    let exist = 0;
                    for (var j = 0, len = data.length; j < len; j++) {
                        if (data[j].area_name == area[m] && data[j].ius == split_is[0] + '|' + unit_table[0] + '|' + split_is[1] && data[j].time_period == time_period[0]) {
                            exist = 1;//return myArray[i]; // Return as soon as the object is found
                            break;
                        }
                    }
                    var table_data = data[j];
                    if (exist == 1) {
                        filter = 1;
                        str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';

                    }
                    else {
                        str += '<td>-</td>';
                    }
                }
                str += '</tr>';

                if (filter == 1) {
                    tableHtml += str;
                }
            }
            tableHtml += '</table>';
        }

        return tableHtml;

    }

    public tableCase26<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_is = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_is.indexOf(ind + '|' + subgroup) == -1) {
                main_is.push(ind + '|' + subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length > 1 && unit_table.length == 1 && subgroup_table.length > 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Area, Time period</th>';
            for (var i = 0; i < main_is.length; i++) {
                tableHtml += '<th class="pointer">' + main_is[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            for (let m = 0; m < area.length; m++) {
                for (let k = 0; k < time_period.length; k++) {
                    let str = '';
                    let filter = 0;
                    str += '<tr>'
                    str += '<td>' + area[m] + ', ' + time_period[k] + '</td>';
                    for (var i = 0; i < main_is.length; i++) {

                        var split_is = main_is[i].split('|');
                        let exist = 0;
                        for (var j = 0, len = data.length; j < len; j++) {
                            if (data[j].area_name == area[m] && data[j].ius == split_is[0] + '|' + unit_table[0] + '|' + split_is[1] && data[j].time_period == time_period[k]) {
                                exist = 1;//return myArray[i]; // Return as soon as the object is found
                                break;
                            }
                        }
                        var table_data = data[j];
                        if (exist == 1) {
                            filter = 1;
                            str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';

                        }
                        else {
                            str += '<td>-</td>';
                        }
                    }
                    str += '</tr>';

                    if (filter == 1) {
                        tableHtml += str;
                    }
                }
            }
            tableHtml += '</table>';
        }
        return tableHtml;
    }

    public tableCase27<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_is = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_is.indexOf(ind + '|' + subgroup) == -1) {
                main_is.push(ind + '|' + subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length > 1 && unit_table.length == 1 && subgroup_table.length > 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Time period</th>';
            for (var i = 0; i < main_is.length; i++) {
                tableHtml += '<th class="pointer" onclick="sortTable(this);">' + main_is[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            for (let k = 0; k < time_period.length; k++) {
                let str = '';
                let filter = 0;
                str += '<tr>'
                str += '<td>' + time_period[k] + '</td>';
                for (var i = 0; i < main_is.length; i++) {
                    var split_is = main_is[i].split('|');
                    let exist = 0;
                    for (var j = 0, len = data.length; j < len; j++) {
                        if (data[j].area_name == area[0] && data[j].ius == split_is[0] + '|' + unit_table[0] + '|' + split_is[1] && data[j].time_period == time_period[k]) {
                            exist = 1;//return myArray[i]; // Return as soon as the object is found
                            break;
                        }
                    }
                    var table_data = data[j];
                    if (exist == 1) {
                        filter = 1;
                        str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';

                    }
                    else {
                        str += '<td>-</td>';
                    }
                }
                str += '</tr>';

                if (filter == 1) {
                    tableHtml += str;
                }
            }
            tableHtml += '</table>';
        }
        return tableHtml;
    }

    public tableCase28<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length > 1 && unit_table.length > 1 && subgroup_table.length == 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Time Period</th>';
            for (var i = 0; i < main_iu.length; i++) {
                tableHtml += '<th class="pointer">' + main_iu[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';

            for (let k = 0; k < time_period.length; k++) {
                let str = '';
                let filter = 0;
                str += '<tr>'
                str += '<td>' + time_period[k] + '</td>';
                for (var i = 0; i < main_iu.length; i++) {
                    let exist = 0;
                    for (var j = 0, len = data.length; j < len; j++) {
                        if (data[j].area_name == area[0] && data[j].ius == main_iu[i] + '|' + subgroup_table[0] && data[j].time_period == time_period[k]) {
                            exist = 1;//return myArray[i]; // Return as soon as the object is found
                            break;
                        }
                    }
                    var table_data = data[j];
                    if (exist == 1) {
                        filter = 1;
                        str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';

                    }
                    else {
                        str += '<td>-</td>';
                    }
                }
                str += '</tr>';
                if (filter == 1) {
                    tableHtml += str;
                }
            }
            tableHtml += '</table>';
        }

        return tableHtml;

    }

    public tableCase29<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length > 1 && unit_table.length > 1 && subgroup_table.length == 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Area</th>';
            for (var i = 0; i < main_iu.length; i++) {
                tableHtml += '<th class="pointer">' + main_iu[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            for (let m = 0; m < area.length; m++) {
                let str = '';
                let filter = 0;
                str += '<tr>'
                str += '<td>' + area[m] + '</td>';
                for (var i = 0; i < main_iu.length; i++) {
                    let exist = 0;
                    for (var j = 0, len = data.length; j < len; j++) {
                        if (data[j].area_name == area[m] && data[j].ius == main_iu[i] + '|' + subgroup_table[0] && data[j].time_period == time_period[0]) {
                            exist = 1;//return myArray[i]; // Return as soon as the object is found
                            break;
                        }
                    }
                    var table_data = data[j];
                    if (exist == 1) {
                        filter = 1;
                        str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';

                    }
                    else {
                        str += '<td>-</td>';
                    }
                }
                str += '</tr>';
                if (filter == 1) {
                    tableHtml += str;
                }
            }
            tableHtml += '</table>';
        }
        return tableHtml;
    }

    public tableCase30<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length > 1 && unit_table.length > 1 && subgroup_table.length > 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Area</th>';
            for (var i = 0; i < main_ius.length; i++) {
                tableHtml += '<th class="pointer">' + main_ius[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            for (let m = 0; m < area.length; m++) {
                let str = '';
                let filter = 0;
                str += '<tr>'
                str += '<td>' + area[0] + '</td>';
                for (var i = 0; i < main_ius.length; i++) {
                    let exist = 0;
                    for (var j = 0, len = data.length; j < len; j++) {
                        if (data[j].area_name == area[0] && data[j].ius == main_ius[i] && data[j].year == time_period[0]) {
                            exist = 1;//return myArray[i]; // Return as soon as the object is found
                            break;
                        }
                    }
                    var table_data = data[j];
                    if (exist == 1) {
                        filter = 1;
                        str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';
                    }
                    else {
                        str += '<td>-</td>';
                    }
                }
                str += '</tr>';

                if (filter == 1) {
                    tableHtml += str;
                }
            }
            tableHtml += '</table>';
        }
        return tableHtml;
    }

    public tableCase31<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length > 1 && unit_table.length > 1 && subgroup_table.length > 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Area</th>';
            for (var i = 0; i < main_ius.length; i++) {
                tableHtml += '<th class="pointer">' + main_ius[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            for (let m = 0; m < area.length; m++) {
                let str = '';
                let filter = 0;
                str += '<tr>'
                str += '<td>' + area[m] + '</td>';
                for (var i = 0; i < main_ius.length; i++) {
                    let exist = 0;
                    for (var j = 0, len = data.length; j < len; j++) {
                        if (data[j].area_name == area[m] && data[j].ius == main_ius[i] && data[j].time_period == time_period[0]) {
                            exist = 1;//return myArray[i]; // Return as soon as the object is found
                            break;
                        }
                    }
                    var table_data = data[j];
                    if (exist == 1) {
                        filter = 1;
                        str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';
                    }
                    else {
                        str += '<td>-</td>';
                    }
                }
                str += '</tr>';

                if (filter == 1) {
                    tableHtml += str;
                }
            }
            tableHtml += '</table>';
        }
        return tableHtml;
    }

    public tableCase32<T>(objData): any {
        let time_period = objData['time_period'];
        let area = objData['area'];
        let data = objData['data'];
        let ius = objData['ius'];
        let tableHtml = '';
        var indicator_table = [];
        var unit_table = [];
        var subgroup_table = [];
        var main_iu = [];
        var main_ius = [];
        for (let i = 0; i < ius.length; i++) {
            let ius_full = ius[i].split('|');
            let ind = ius_full[0];
            let unit = ius_full[1];
            let subgroup = ius_full[2];
            if (indicator_table.indexOf(ind) == -1) {
                indicator_table.push(ind);
            }
            if (unit_table.indexOf(unit) == -1) {
                unit_table.push(unit);
            }
            if (subgroup_table.indexOf(subgroup) == -1) {
                subgroup_table.push(subgroup);
            }
            if (main_iu.indexOf(ius_full[0] + '|' + ius_full[1]) == -1) {
                main_iu.push(ius_full[0] + '|' + ius_full[1]);
            }
            if (main_ius.indexOf(ius[i]) == -1) {
                main_ius.push(ius[i]);
            }
        }
        if (indicator_table.length > 1 && unit_table.length > 1 && subgroup_table.length > 1) {
            tableHtml += '<table class=" table table-striped table-hover pl-25px pr-25px"  >';
            tableHtml += '<tr>'
            tableHtml += '<th class="pointer">Time period</th>';
            for (var i = 0; i < main_ius.length; i++) {
                tableHtml += '<th class="pointer">' + main_ius[i] + '</th>';
                tableHtml += '<th class="hide"></th>';
            }
            tableHtml += '</tr>';
            for (let k = 0; k < time_period.length; k++) {
                let str = '';
                let filter = 0;
                str += '<tr>'
                str += '<td>' + time_period[k] + '</td>';
                for (var i = 0; i < main_ius.length; i++) {
                    let exist = 0;
                    for (var j = 0, len = data.length; j < len; j++) {
                        if (data[j].area_name == area[0] && data[j].ius == main_ius[i] && data[j].time_period == time_period[k]) {
                            exist = 1;//return myArray[i]; // Return as soon as the object is found
                            break;
                        }
                    }
                    var table_data = data[j];
                    if (exist == 1) {
                        filter = 1;
                        str += '<td>' + Number(table_data.value).toLocaleString() + '</td>';
                    }
                    else {
                        str += '<td>-</td>';
                    }
                }
                str += '</tr>';

                if (filter == 1) {
                    tableHtml += str;
                }
            }
            tableHtml += '</table>';
        }
        return tableHtml;
    }
}
