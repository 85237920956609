import { Injectable, ViewChild, ElementRef } from '@angular/core';
import * as Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
//require('src/assets/lib/js/highchartMotion/motion.js')(Highcharts);
More(Highcharts);
@Injectable({
    providedIn: 'root'
})
export class VisualizationRendererService {
    constructor() { }
    chart_colors = ['#00AEF0', '#ffcc00', '#10cf92', '#dd509c', '#008E8E', '#D64646', '#8E468E', '#588526', '#B3AA00', '#008ED6', '#9D080D', '#A186BE', '#CC6600', '#FDC689', '#ABA000', '#F26D7D', '#FFF200', '#0054A6', '#F7941C', '#CC3300', '#006600', '#663300', '#6DCFF6'];
    
    private getDate() {
        let d = new Date();
        let datestring = d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear() + " " +
            d.getHours() + ":" + d.getMinutes();
        return (datestring.replace(':', '.'))
    }
    public loadColumnChart<T>(vizObj): any {
        let stack_chart_type;
        let type = vizObj['chartType'];
        let id = vizObj['id'];
        let stack_type;
        let dataSortingEnable = true;
        let rotation = 270;
        let rotationLabel = -90;
        let yRotationLabel= -8;
        let xRotationLabel= 0;
        let isLegendEnable = false;
        let isLabelEnable = false;
        let time_period = ""
        let subgroup = "";
        let chartTitle = "";
        let exportTitle='';
        let exportImageName ='';
        if (vizObj['time_period'] !== undefined) {
            if (vizObj['time_period'].length > 1) {time_period = ""}
            else {time_period = " | "+vizObj['time_period'].toString()}
        }
        if (vizObj['subgroup'] !== undefined) {
            if (vizObj['subgroup'].length == 0) {subgroup = ""}
            else {subgroup = " | "+vizObj['subgroup'].toString()}
        }
        if (vizObj['legend'] !== undefined) {
            isLegendEnable = vizObj['legend'];
        }

        if (vizObj['label'] !== undefined) {
            isLabelEnable = vizObj['label'];exportTitle
        }

        if (vizObj['title'] !== undefined) {
            chartTitle = vizObj['title'];
        }

        if (type == 'column') {
            stack_chart_type = vizObj['chartType'];
            stack_type = false;
            xRotationLabel= 0;
            yRotationLabel =-15;
        } else if (type == 'bar') {
            stack_chart_type = vizObj['chartType'];
            stack_type = false;
            rotation = 0;
            rotationLabel=0;
            yRotationLabel =-3;
            
        } else if (type == 'line') {
            stack_chart_type = vizObj['chartType'];
            stack_type = false;
            yRotationLabel =-20;
        } else if (type == 'stackedcolumn') {
            stack_chart_type = 'column';
            stack_type = 'normal';
            xRotationLabel= 0;
            // yRotationLabel =-3;

        } else if (type == 'stackedcolumnpercent') {
            stack_chart_type = 'column';
            stack_type = 'percent';
            xRotationLabel= 0;

        } else if (type == 'stackedbar') {
            stack_chart_type = 'bar';
            stack_type = 'normal';
            rotation = 0;
            rotationLabel=0;
            yRotationLabel =-3;
            // xRotationLabel = 180;


            // rotationLabel=0;
            // yRotationLabel =0;


        } else if (type == 'stackedbarpercent') {
            stack_chart_type = 'bar';
            stack_type = 'percent';
            rotation = 0;
            rotationLabel=0;
            yRotationLabel =-2;
            xRotationLabel = 0;

            // rotationLabel=0;
            // yRotationLabel =0;
        }
        else if (type == 'area') {
            stack_chart_type = vizObj['chartType'];
            stack_type = false;
            yRotationLabel =-19;
        }
        else if (type == 'stackedarea') {
            stack_chart_type = 'area';
            stack_type = 'normal';
            yRotationLabel =-19;
        }
        else if (type == 'stackedareapercent') {
            stack_chart_type = 'area';
            stack_type = 'percent';
            xRotationLabel=0;
            yRotationLabel=0;
            rotationLabel=0;
        }


        if(vizObj['moduleType']=='rank'){
            rotation = 270;
            dataSortingEnable = false;
            exportTitle= vizObj['title'];
            exportImageName = vizObj['title'] + '_' + this.getDate();
           
        }else{
            exportTitle = vizObj['indicator']+" | "+vizObj['unit']+subgroup+time_period;
            exportImageName = vizObj['indicator'] + '_' + this.getDate()
        }
        return {
            chart: {
                type: stack_chart_type,
                renderTo: vizObj.renderId
            },
            exporting: {
                width: 1000,

                enabled: false,
                chartOptions: {
                    title: {
                        text: exportTitle,
                        style: { fontSize: '14' }, align: 'center',
                        margin: 20,
                    },
                    chart: {
                        height: 600,
                        marginBottom: 190,
                        width: 1000,
                        events: {
                            load: function () {
                                var renderer = this.renderer;
                                renderer.text('<i>Source : '+vizObj['data_source']+'</i>', 900, 580).css({
                                    width: '400px',
                                    align: 'right',
                                    fontSize: '10px',
                                }).add();
                            }
                        }
                    },
                },
                filename: exportImageName
            },
            colors: this.chart_colors,
            title: {
                text: chartTitle
            },
            subtitle: {
                text: ''
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: vizObj['data']['xaxis'],

                labels: {
                    style: {
                        fontSize: '9px',
                    },
                    formatter: function () {
                        var text = this.value,
                            formatted = text.length > 9 ? text.substring(0, 9) + '...' : text;
                        return '<div class="js-ellipse" style="overflow:hidden" title="' + text + '">' + text + '</div>';
                    },
                    useHTML: true,
                    rotation: rotation,
                    enabled: true
                },
            },
            yAxis: {
                title: {
                    text: vizObj['type'],
                },
            },
            plotOptions: {
                series: {
                    stacking: stack_type,
                    dataSorting: {
                        enabled: dataSortingEnable,
                        // sortKey: 'y'
                      },
                    dataLabels: {
                        enabled: isLabelEnable,
                        style: {
                            fontSize: '9px !important',
                            fontWeight: 100,
                            textOutline: false
                        },
                        matchByName: true,
                        color: '#333333',
                        x: xRotationLabel,
                        y: yRotationLabel,
                        rotation: rotationLabel,
                    },
                }
            },
            tooltip: {
                // backgroundColor: "rgba(255,255,255,1)",
                // zIndex:'9999!important',
              },
            
            series: vizObj['data']['series'],
            legend: { enabled: isLegendEnable,pointFormat: "{point.y:,.0f}" }
        };
    }
    public loadPieChart<T>(vizObj): any {
        let time_period = "";
        let subgroup = "";
        if (vizObj['time_period'] !== undefined) {
            if (vizObj['time_period'].length > 1) {time_period = ""}
            else {time_period = " | "+vizObj['time_period'].toString()}
        }
        if (vizObj['subgroup'] !== undefined) {
            if (vizObj['subgroup'].length == 0) {subgroup = ""}
            else {subgroup = " | "+vizObj['subgroup'].toString()}
        }
        return {
            chart: {
                type: 'pie',
                renderTo: vizObj.renderId
            },
            title: {
                text: ''
            },
            colors: this.chart_colors,

            exporting: {
                enabled: false,
                chartOptions: {
                    title: {
                        text: vizObj['indicator']+subgroup+time_period,
                        style: { fontSize: '14' }, align: 'center',
                        margin: 20,
                    },
                    chart: {
                        height: 700,
                        marginBottom: 75,
                        width: 1000,
                        events: {
                            load: function () {
                                var renderer = this.renderer;
                                renderer.text('<i>Source : '+vizObj['data_source']+'</i>', 900, 670).css({
                                    width: '400px',
                                    align: 'right',
                                    fontSize: '10px',
                                }).add();
                            }
                        }
                    },
                },
                filename: vizObj['indicator'] + '_' + this.getDate()
            },
            plotOptions: {
                pie: {
                    turboThreshold: 20000,
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false,
                        style: {
                            fontSize: '10px',
                            fontWeight: 100
                        },
                        color: '#666666',
                        formatter: function () {
                            return this.y
                        }
                    },
                    showInLegend: true
                },
                series: {
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + this.point.name + '</b>: ' + this.y;
                }
                
            },
            legend: { enabled: false },
            credits: { enabled: false },
            series: [{
                name: '',
                colorByPoint: true,
                data: vizObj['data']['series']
            }]
        };
    }
    public loadTreeMap<T>(vizObj): any {
        let time_period = "";
        let subgroup = "";
        if (vizObj['time_period'] !== undefined) {
            if (vizObj['time_period'].length > 1) {time_period = ""}
            else {time_period = " | "+vizObj['time_period'].toString()}
        }
        if (vizObj['subgroup'] !== undefined) {
            if (vizObj['subgroup'].length == 0) {subgroup = ""}
            else {subgroup = " | "+vizObj['subgroup'].toString()}
        }
        return {
            chart: {
                renderTo: vizObj.renderId
            },
            title: {
                text: ''
            },
            colors: this.chart_colors,

            colorAxis: {
                minColor: '#FFFFFF',
                // maxColor: Highcharts.getOptions().colors[0]
            },
            exporting: {
                enabled: false,
                chartOptions: {
                    title: {
                        text: vizObj['indicator']+subgroup+time_period,
                        style: { fontSize: '20px' }, align: 'center',
                        margin: 20,
                    },
                    legend: {
                        y: -180
                    },
                    chart: {
                        height: 850,
                        width: 1250,
                        marginBottom: 30,
                        events: {
                            load: function () {
                                var renderer = this.renderer;
                                renderer.text("Source : "+vizObj['data_source'], 1150, 840).css({
                                    width: '400px',
                                    align: 'center',
                                }).add();
                            }
                        }
                    },
                },
                filename: vizObj['indicator'] + '_' + this.getDate()
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: '10px',
                            fontWeight: 100
                        },
                        color: '#666666',
                        formatter: function () {
                            return this.y
                        }
                    }
                }
            },
            legend: { enabled: false },
            credits: { enabled: false },
            series: [{
                levels: [{
                    level: 1,
                    dataLabels: {
                        style: {
                            fontSize: '12px',
                            textOutline: false
                        }
                    },
                }],
                type: 'treemap',
                layoutAlgorithm: 'squarified',
                colorByPoint: true,
                data: vizObj['data']['series']
            }]
        };
    }
    public loadRankChart<T>(vizObj): any {
        return {
            chart: {
                type: 'column',
                renderTo: vizObj.renderId
            },
            exporting: {
                enabled: false,
                chartOptions: {
                    title: {
                        text: vizObj['indicator'],
                        style: { fontSize: '14' }, align: 'center',
                        margin: 20,
                    },
                    legend: {
                        y: -180
                    },
                    chart: {
                        height: 580,
                        marginBottom: 290,
                        events: {
                            load: function () {
                                var renderer = this.renderer;
                                renderer.text('Source : '+vizObj['data_source'], 30, 550).css({
                                    width: '400px',
                                    align: 'center'
                                }).add();
                            }
                        }
                    },

                },
                filename: vizObj['indicator']
            },
            title: {
                text: ''
            },
            colors: this.chart_colors,

            subtitle: {
                text: ''
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: vizObj['data']['xaxis'],
                crosshair: true,
                labels: {
                    autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90]
                  }
            },
            yAxis: {
                title: {
                    text: vizObj['data']['yaxis']
                }
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: true,

                    }
                }
            },
            series: vizObj['data']['series'],
            legend: { enabled: false,pointFormat: "{point.y:,.0f}" }
        };
    }
    public loadWordCloud<T>(vizObj): any {
        let tooltip = 0;
        return {
            chart: {
                renderTo: vizObj.renderId
            },
            series: [{
                type: 'wordcloud',
                data: vizObj['data'],
                name: 'Value'
            }],
            exporting: {
                enabled: false
            },
            title: {
                text: vizObj['title']
            },
            credits: {
                enabled: false
            },
            tooltip: {

                formatter: function () {
                    tooltip = Number(this.point.weight) - 10;
                    return '<b>' + this.point.name + '</b>: ' + tooltip;
                }
            },
        }


    }
    public loadRadarChart<T>(vizObj): any {

      return  {
        chart:{
            type: 'line',
            renderTo: vizObj.renderId,
            polar: true
        },
        credits: {
          enabled: false
        },
        exporting: {
                enabled: false
            },

            title: {
                text: vizObj['indicator']
            },

            subtitle: {
                text: ''
            },
            xAxis: {
                categories: vizObj['data']['xaxis'],
                tickmarkPlacement: 'on',
                lineWidth: 0,
            },
            yAxis: {
                gridLineInterpolation: 'polygon',
                lineWidth: 0,
                min: 0
            },
            series: vizObj['data']['series']
        }

    }
    public loadDonutChart<T>(vizObj): any {
        return {
            chart: {
                renderTo: vizObj.renderId,
                type: 'pie'
            },
            title: {
                text: vizObj['indicator']
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            plotOptions: {
                pie: {
                    shadow: false
                }
            },
            tooltip: {
                formatter: function () {
                    return this.point.name + ':<b> ' + this.y + '</b>';
                }
            },
            series: [{
                name: '',
                data: vizObj['data']['series'],
                innerSize: '60%',
                showInLegend: false,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return this.point.name + ': ' + this.y;
                    },

                }
            }]
        }
    }

}
