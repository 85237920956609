import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {Router} from '@angular/router';
import { AuthService } from './services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  
  constructor(private auth: AuthService,
    private myRoute: Router){
  }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if(this.auth.isLoggednIn()){
      let expectedRoleArray = next.data.allowRole;
      const authDetails = JSON.parse(localStorage.getItem('user_details'));

      if(expectedRoleArray.indexOf(authDetails.role) >= 0) {
        return true;
      }

      localStorage.removeItem('auth_token');
      localStorage.removeItem('user_details');
      this.myRoute.navigate(["login"]);
      return false;
      
    }else{
      this.myRoute.navigate(["login"]);
      return false;
    }
  }
}