import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetCasePieService {
  seriesData = [];
  plotOptions: any;

  constructor() { }
  public pieCase1<T>(objData: any) {
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];
    let sum;
    let returnData;

    this.plotOptions = {
      pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          }
      }
    }
    var tempData=[];
    returnData = data.filter(item => item.area_name == area[0] && item.ius == ius[0] && item.time_period == time_period[0]);
    if(returnData.length >= 1){
      returnData.forEach(element => {
        sum = Number(element.value);
        this.seriesData.push({ name: element.area_name, y:sum, value:sum });
      });
    }
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase2<T>(objData: any) {
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          }
      }
    }
    
    var tempData=[];
    let a = area[0];
    for(let i=0; i<time_period.length; i++){
      let exist = 0;
      for (var j = 0, len = data.length; j < len; j++) {
        if (data[j].area_name == a && data[j].ius == ius[0] && data[j].time_period == time_period[i]){
          exist = 1;//return myArray[i]; // Return as soon as the object is found
          break;
        }
      }
      if(exist){
        tempData.push({name:data[j].time_period, y:Number(data[j].value),value:Number(data[j].value)});
      }
    }
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase3<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];
    let valueType = objData['type'];
    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }
    var tempData=[];
    let y = time_period[0];
    for(let i=0; i<area.length; i++){
      let exist = 0;
      for (var j = 0, len = data.length; j < len; j++){
        if (data[j].area_name == area[i] && data[j].ius == ius[0] && data[j].time_period == y){
          exist = 1;//return myArray[i]; // Return as soon as the object is found
          break;
        }
      }
      if(exist){
       
         if(valueType == 'percent'){
        tempData.push({name:data[j].area_name, y:Number(data[j].value),value:Number(data[j].value)});
        }else {
          tempData.push({name:data[j].area_name, y:Number(data[j].numerator),value:Number(data[j].numerator)});
        }
        
      }
      // if(exist){
      //   tempData.push({name:data[j].area_name, y:Number(data[j].value),value:Number(data[j].value)});
      // }
    }

    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase4<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }
    var tempData=[];
    for(let i=0; i<area.length; i++){
      //in case of most recent year filter
      for(let k=0; k<time_period.length; k++){
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++){
          if (data[j].area_name == area[i] && data[j].ius == ius[0] && data[j].time_period == time_period[k]){
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if(exist){
          tempData.push({name:data[j].area_name+', '+time_period[k], y:Number(data[j].value),value:Number(data[j].value)});
        }
      }
    }
    
    this.seriesData = tempData;

    return { series: this.seriesData, plotOptions: this.plotOptions};

  }

  public pieCase5<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }

    let ind_unit = [];
    let ind_unit_subgroup = [];
    var tempData=[];

    for(let i=0; i<ius.length; i++){
      var ius_spilt = ius[i].split('|');
      if(ind_unit.indexOf(ius_spilt[0]+'|'+ius_spilt[1]) == -1){
        ind_unit.push(ius_spilt[0]+'|'+ius_spilt[1]);
      }
      if(ind_unit_subgroup.indexOf(ius_spilt[2]) == -1){
        ind_unit_subgroup.push(ius_spilt[2]);
      }
    }
    let a = area[0];
    let y = time_period[0];
    if(ind_unit.length == 1 && ind_unit_subgroup.length > 1){
      for(var i=0; i<ind_unit_subgroup.length; i++){
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++){
          if (data[j].area_name == a && data[j].ius ==ind_unit+'|'+ind_unit_subgroup[i] && data[j].time_period == y){
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if(exist){
          var array = data[j].ius.split('|');
          tempData.push({name:array.pop()+', '+a, y:Number(data[j].value),value:Number(data[j].value)});
        }
      }
    }

    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase6<T>(objData: any){
    
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }
    var ind_unit = [];
    var ind_unit_subgroup = [];
    var tempData=[];

    for(let i=0; i<ius.length; i++){
      var ius_spilt = ius[i].split('|');
      if(ind_unit.indexOf(ius_spilt[0]+'|'+ius_spilt[1]) == -1){
        ind_unit.push(ius_spilt[0]+'|'+ius_spilt[1]);
      }
      if(ind_unit_subgroup.indexOf(ius_spilt[2]) == -1){
        ind_unit_subgroup.push(ius_spilt[2]);
      }
    }
    
    let y = time_period[0];
    if(ind_unit.length == 1 && ind_unit_subgroup.length > 1){
      for(let k=0; k<area.length; k++){
        for(let i=0; i<ind_unit_subgroup.length; i++){
          let exist = 0;
          for (var j = 0, len = data.length; j < len; j++){

            if (data[j].area_name == area[k] && data[j].ius == ind_unit[i]+'|'+ind_unit_subgroup[i] && data[j].time_period == y){
              exist = 1;//return myArray[i]; // Return as soon as the object is found
              break;
            }
          }
          if(exist){
            var array = data[j].ius.split('|');                        
            tempData.push({name:array.pop()+', '+area[k]+', '+y, y:Number(data[j].value),value:Number(data[j].value)});
          }
        }
      }
    }
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};

  }

  public pieCase7<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }

    var ind_unit = [];
    var ind_unit_subgroup = [];
    var tempData=[];
    for(let i=0; i<ius.length; i++){
      var ius_spilt = ius[i].split('|');
      if(ind_unit.indexOf(ius_spilt[0]+'|'+ius_spilt[1]) == -1){
        ind_unit.push(ius_spilt[0]+'|'+ius_spilt[1]);
      }
      if(ind_unit_subgroup.indexOf(ius_spilt[2]) == -1){
        ind_unit_subgroup.push(ius_spilt[2]);
      }
    }
    for(let k=0; k<area.length; k++){
      for(let m=0; m<time_period.length; m++){
        for(var i=0; i<ind_unit_subgroup.length; i++){
          let exist = 0;
          for (var j = 0, len = data.length; j < len; j++){
            if (data[j].area_name == area[k] && data[j].ius == ind_unit[0]+'|'+ind_unit_subgroup[i] && data[j].time_period == time_period[m]){
              exist = 1;//return myArray[i]; // Return as soon as the object is found
              break;
            }
          }
          if(exist){
            var array = data[j].ius.split('|');                        
            tempData.push({name:array.pop()+', '+area[k]+', '+time_period[m], y:Number(data[j].value),value:Number(data[j].value)});
          }
        }
      }
    }

    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase8<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }
    var tempData=[];
    for(let k=0;k<area.length;k++){
      for(let m=0;m<time_period.length;m++){
        for(let i=0;i<ius.length; i++){
          let exist = 0;
          for (var j = 0, len = data.length; j < len; j++){
            if (data[j].area_name == area[k] && data[j].ius == ius[i] && data[j].time_period == time_period[m]){
              exist = 1;//return myArray[i]; // Return as soon as the object is found
              break;
            }
          }
          if(exist){
            var array = ius[i].split('|');                        
            tempData.push({name:array[0]+', '+area[k]+', '+time_period[m], y:Number(data[j].value),value:Number(data[j].value)});
          }
        }
      }
    }
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};

  }

  public pieCase9<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }

    var array = ius[0].split('|');
    let subgroup = array.pop();
    var tempData=[];    
    var indicator_table = [];
    var unit_table = [];
    var subgroup_table = [];
    var ind_unit = [];
    var ind_unit_subgroup = [];
    for(let i=0; i<ius.length; i++){
      var ius_spilt = ius[i].split('|');
      if(ind_unit.indexOf(ius_spilt[0]+'|'+ius_spilt[1]) == -1){
        ind_unit.push(ius_spilt[0]+'|'+ius_spilt[1]);
      }
    }
    for(let k=0; k<area.length;k++){
      for(var i=0; i<ind_unit.length; i++){
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++){
          if (data[j].area_name == area[k] && data[j].ius == ind_unit[i]+'|'+subgroup && data[j].time_period == time_period[0]){
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if(exist){
          tempData.push({name:ind_unit[i]+', '+area[k]+', '+time_period[0],y:Number(data[j].value),value:Number(data[j].value)});
        }
      }
    }
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase10<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }
    var tempData=[];
    for(let k=0; k<area.length; k++){
      for(let m=0; m<time_period.length;m++){
        for(let i=0; i<ius.length; i++){
          let val = ius[i];
          let exist = 0;
          for (var j = 0, len = data.length; j < len; j++){
            if (data[j].area_name == area[k] && data[j].ius == val && data[j].time_period == time_period[m]){
              exist = 1;//return myArray[i]; // Return as soon as the object is found
              break;
            }
          }
          if(exist){
            var array = val.split('|');                        
            tempData.push({name:val+', '+area[k]+', '+time_period, y:Number(data[j].value),value:Number(data[j].value)});
          }
        }
      }
    }
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};

  }

  public pieCase11<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }

    var array = ius[0].split('|');
    let subgroup = array.pop();
    var tempData=[];    
    var indicator_table = [];
    var unit_table = [];
    var subgroup_table = [];
    var ind_unit = [];
    for(let i=0; i<ius.length; i++){
      var ius_spilt = ius[i].split('|');
      if(ind_unit.indexOf(ius_spilt[0]+'|'+ius_spilt[1])){
        ind_unit.push(ius_spilt[0]+'|'+ius_spilt[1]);
      }
    }
    for(let k=0; k<area.length; k++){
      for(let m=0; m<time_period.length; m++){
        for(var i=0; i<ind_unit.length; i++){
          let exist = 0;
          for (var j = 0, len = data.length; j < len; j++){
            if (data[j].area_name == area[k] && data[j].ius == ind_unit[i]+'|'+subgroup && data[j].time_period == time_period){
              exist = 1;//return myArray[i]; // Return as soon as the object is found
              break;
            }
          }
          if(exist){
            var split_iu = ind_unit[i].split('|');
            tempData.push({name:split_iu[1]+', '+area[k]+', '+time_period[m],y:Number(data[j].value),value:Number(data[j].value)});
          }
        }
      }
    }
    this.seriesData = tempData; 
    return { series: this.seriesData, plotOptions: this.plotOptions};

  }

  public pieCase12<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }

    var array = ius[0].split('|');
    let subgroup = array.pop();
    var tempData=[];        
    var indicator_table = [];
    var unit_table = [];
    var subgroup_table = [];
    var ind_unit = [];
    var ind_unit_subgroup = [];
    for(let i=0; i<ius.length; i++){
      var ius_spilt = ius[i].split('|');
      if(ind_unit.indexOf(ius_spilt[0]+'|'+ius_spilt[1])){
        ind_unit.push(ius_spilt[0]+'|'+ius_spilt[1]);
      }
      if(ind_unit_subgroup.indexOf(ius_spilt[2])){
        ind_unit_subgroup.push(ius_spilt[2]);
      }
    }
    for(let m=0; m<time_period.length; m++){
      for(var i=0; i<ind_unit.length; i++){
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++) {
          if (data[j].area_name == area[0] && data[j].ius == ind_unit[i]+'|'+subgroup && data[j].time_period == time_period){
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if(exist){
          var split_iu = ind_unit[i].split('|');
          tempData.push({name:split_iu[1]+', '+time_period[m], y:Number(data[j].value),value:Number(data[j].value)});
        }
      }
    }
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase13<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }
    var array = ius[0].split('|');
    let subgroup = array.pop();
    var tempData=[];
    var indicator_table = [];
    var unit_table = [];
    var subgroup_table = [];
    var ind_unit = [];
     
    for(let i=0; i<ius.length; i++){
      var ius_spilt = ius[i].split('|');
      if(ind_unit.indexOf(ius_spilt[0]+'|'+ius_spilt[1])){
        ind_unit.push(ius_spilt[0]+'|'+ius_spilt[1]);
      }
    }
    for(var i=0; i<ind_unit.length; i++){
      let exist = 0;
      for (var j = 0, len = data.length; j < len; j++){
        if (data[j].area_name == area[0] && data[j].ius == ind_unit[i]+'|'+subgroup && data[j].time_period == time_period[0]){
          exist = 1;//return myArray[i]; // Return as soon as the object is found
          break;
        }
      }
      if(exist){
        var split_iu = ind_unit[i].split('|');
        tempData.push({name:area[0]+', '+split_iu[1], y:Number(data[j].value),value:Number(data[j].value)});
      }
    }
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase14<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }
   
    var split_iu = ius[0].split("|");
    let unit = split_iu[1];
    let subgroup = split_iu[2];
    let tempData = [];    
    var main_iu = [];
    var main_ius = [];

    for(let i=0; i<ius.length; i++){
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if(main_iu.indexOf(ind+'|'+unit) == -1){
        main_iu.push(ind+'|'+unit);
      }
      if(main_ius.indexOf(ius[i]) == -1){
        main_ius.push(ius[i]);
      }
    }
    for(var i=0; i<main_iu.length; i++){
      let exist = 0;
      for (var j = 0, len = data.length; j < len; j++){
        if (data[j].area_name == area[0] && data[j].ius == main_iu[i]+'|'+subgroup && data[j].time_period == time_period[0]){
          exist = 1;//return myArray[i]; // Return as soon as the object is found
          break;
        }
      }
      if(exist){
        var array = main_iu[i].split('|');
        tempData.push({name:array[0],y:Number(data[j].value),value:Number(data[j].value)});
      }
    }
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase15<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }
    var split_iu = ius[0].split('|');
    let indicator = split_iu[0];
    let subgroup = split_iu[2];
    let tempData = [];    
    var main_iu = [];
    var main_ius = [];
    for(let i=0; i<ius.length; i++){
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if(main_iu.indexOf(ind+'|'+unit) == -1){
        main_iu.push(ind+'|'+unit);
      }
      if(main_ius.indexOf(ius[i]) == -1){
        main_ius.push(ius[i]);
      }
    }
       
    for(var i=0; i<main_ius.length; i++){
      let exist = 0;
      for (var j = 0, len = data.length; j < len; j++){
        if (data[j].area_name == area[0] && data[j].ius == main_ius[i] && data[j].time_period == time_period[0]){
          exist = 1;//return myArray[i]; // Return as soon as the object is found
          break;
        }
      }
      if(exist){
        var array = main_ius[i].split('|');
        tempData.push({name:array[1]+','+array[2],y:Number(data[j].value),value:Number(data[j].value)});
      }
    }
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};

  }

  public pieCase16<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }
    var split_iu = ius[0].split('|');
    
    let subgroup = split_iu[2];
    let tempData = [];    
    var main_iu = [];
    var main_ius = [];
    for(let i=0; i<ius.length; i++){
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if(main_iu.indexOf(ind+'|'+unit) == -1){
        main_iu.push(ind+'|'+unit);
      }
      if(main_ius.indexOf(ius[i]) == -1){
        main_ius.push(ius[i]);
      }
    }
       
    for(var i=0; i<main_ius.length; i++){            
      let exist = 0;
      for (var j = 0, len = data.length; j < len; j++){
        if (data[j].area_name == area[0] && data[j].ius == main_ius[i] && data[j].time_period == time_period[0]){
          exist = 1;//return myArray[i]; // Return as soon as the object is found
          break;
        }
      }

      if(exist){
        var array = main_ius[i].split('|');
        tempData.push({name:array[0]+','+array[1],y:Number(data[j].value),value:Number(data[j].value)});
      }
    }
       
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase17<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }   
        
    var split_iu = ius[0].split('|');    
    let indicator = split_iu[0];
    let unit = split_iu[1];
    let tempData = [];
    var main_iu = [];
    var main_ius = [];

    for(let i=0; i<ius.length; i++){
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if(main_iu.indexOf(ind+'|'+unit) == -1){
        main_iu.push(ind+'|'+unit);
      }
      if(main_ius.indexOf(ius[i]) == -1){
        main_ius.push(ius[i]);
      }
    }
       
    for(var i=0; i<main_ius.length; i++){
      for(let m=0;m<time_period.length; m++){
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++){
          if (data[j].area_name == area[0] && data[j].ius == main_ius[i] && data[j].time_period == time_period[m]){
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if(exist){
          var array = main_ius[i].split('|');
          tempData.push({name:array[2]+', '+time_period[m],y:Number(data[j].value),value:Number(data[j].value)});
        }
      }
    }
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase18<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }
    var split_iu = ius[0].split('|');
    let unit = split_iu[1];
    let subgroup = split_iu[2];
    let tempData = [];    
    var main_iu = [];
    var main_ius = [];
    for(let i=0; i<ius.length; i++){
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if(main_iu.indexOf(ind+'|'+unit) == -1){
        main_iu.push(ind+'|'+unit);
      }
      if(main_ius.indexOf(ius[i]) == -1){
        main_ius.push(ius[i]);
      }
    }
       
    for(var i=0; i<main_ius.length; i++){
      for(let m=0;m<time_period.length; m++){

        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++){

            if (data[j].area_name == area[0] && data[j].ius == main_ius[i] && data[j].time_period == time_period[m]){
              exist = 1;//return myArray[i]; // Return as soon as the object is found
              break;
            }
        }

        if(exist){
            var array = main_ius[i].split('|');
            tempData.push({name:array[0]+', '+time_period[m],y:Number(data[j].value),value:Number(data[j].value)});
        }

      }
    }
           
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase19<T>(objData: any){
    console.log(objData, 'xcvcbv1')
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }
    var split_iu = ius[0].split('|');    
    let unit = split_iu[1];
    let subgroup = split_iu[2];
    let tempData = [];    
    var main_iu = [];
    var main_ius = [];

    for(let i=0; i<ius.length; i++){
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if(main_iu.indexOf(ind+'|'+unit) == -1){
        main_iu.push(ind+'|'+unit);
      }
      if(main_ius.indexOf(ius[i]) == -1){
        main_ius.push(ius[i]);
      }
    }
       
    for(var i=0; i<main_ius.length; i++){
      for(let k=0; k<area.length; k++){
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++){
          if (data[j].area_name == area[k] && data[j].ius == main_ius[i] && data[j].time_period == time_period[0]){
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if(exist){
          var array = main_ius[i].split('|');
          tempData.push({name:array[0]+', '+area[k],y:Number(data[j].value),value:Number(data[j].value)});
        }
      }
    }       
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase20<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }
    var split_iu = ius[0].split('|');
    
    let unit = split_iu[1];
    let tempData = [];
    var main_iu = [];
    var main_ius = [];

    for(let i=0; i<ius.length; i++){
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if(main_iu.indexOf(ind+'|'+unit) == -1){
        main_iu.push(ind+'|'+unit);
      }
      if(main_ius.indexOf(ius[i]) == -1){
        main_ius.push(ius[i]);
      }
    }
       
    for(var i=0; i<main_ius.length; i++){
      let exist = 0;
      for (var j = 0, len = data.length; j < len; j++){
        if (data[j].area_name == area[0] && data[j].ius == main_ius[i] && data[j].time_period == time_period[0]){
          exist = 1;//return myArray[i]; // Return as soon as the object is found
          break;
        }
      }

      if(exist){
          var array = main_ius[i].split('|');
          tempData.push({name:array[0]+','+array[2],y:Number(data[j].value),value:Number(data[j].value)});
      }
    }
       
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase21<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }
    var split_iu = ius[0].split("|");
    
    let indicator = split_iu[0];
    let tempData = [];    
    var main_iu = [];
    var main_ius = [];

    for(let i=0; i<ius.length; i++){
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if(main_iu.indexOf(ind+'|'+unit) == -1){
        main_iu.push(ind+'|'+unit);
      }
      if(main_ius.indexOf(ius[i]) == -1){
        main_ius.push(ius[i]);
      }
    }
       
    for(var i=0; i<main_ius.length; i++) {

      for(let k=0; k<area.length; k++){
              
        for(let m=0; m<time_period.length; m++){
          let exist = 0;
          for (var j = 0, len = data.length; j < len; j++){
            if (data[j].area_name == area[k] && data[j].ius == main_ius[i] && data[j].time_period == time_period[m]){
              exist = 1;//return myArray[i]; // Return as soon as the object is found
              break;
            }
          }
          if(exist){
            var array = main_ius[i].split('|');
            tempData.push({name:array[1]+','+array[2]+', '+area[k]+', '+time_period[m],y:Number(data[j].value),value:Number(data[j].value)});
          }
        }
      }
    }
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase22<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }

    var split_iu = ius[0].split("|");
    
    let indicator = split_iu[0];
    let subgroup = split_iu[2];

    let tempData = [];
    
    var main_iu = [];
    var main_ius = [];

    for(let i=0; i<ius.length; i++){
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if(main_iu.indexOf(ind+'|'+unit) == -1){
        main_iu.push(ind+'|'+unit);
      }
      if(main_ius.indexOf(ius[i]) == -1){
        main_ius.push(ius[i]);
      }
    }
       
    for(var i=0; i<main_ius.length; i++) {

      for(let k=0; k<area.length; k++){
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++){
          if (data[j].area_name == area[k] && data[j].ius == main_ius[i] && data[j].time_period == time_period[0]){
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if(exist){
          var array = main_ius[i].split('|');
          tempData.push({name:area+', '+array[1],y:Number(data[j].value),value:Number(data[j].value)});
        }
      }
    }           
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase23<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }

    var split_iu = ius[0].split("|");
    
    let indicator = split_iu[0];
    let tempData = [];
    
    var main_iu = [];
    var main_ius = [];

    for(let i=0; i<ius.length; i++){
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if(main_iu.indexOf(ind+'|'+unit) == -1){
        main_iu.push(ind+'|'+unit);
      }
      if(main_ius.indexOf(ius[i]) == -1){
        main_ius.push(ius[i]);
      }
    }
       
    for(var i=0; i<main_ius.length; i++) {
      for(let m=0; m<time_period.length; m++){
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++){
          if (data[j].area_name == area[0] && data[j].ius == main_ius[i] && data[j].time_period == time_period[m]){
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if(exist){
          var array = main_ius[i].split('|');
          tempData.push({name:array[1]+','+array[2]+', '+time_period[m],y:Number(data[j].value),value:Number(data[j].value)});
        }
      }
    }
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase24<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }
    var split_iu = ius[0].split("|");
    
    let indicator = split_iu[0];    
    let tempData = [];    
    var main_iu = [];
    var main_ius = [];

    for(let i=0; i<ius.length; i++){
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if(main_iu.indexOf(ind+'|'+unit) == -1){
        main_iu.push(ind+'|'+unit);
      }
      if(main_ius.indexOf(ius[i]) == -1){
        main_ius.push(ius[i]);
      }
    }
       
    for(var i=0; i<main_ius.length; i++) {
      for(let m=0; m<area.length; m++){                
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++) 
        {

            if (data[j].area_name == area[m]
                    && data[j].ius == main_ius[i]
                    && data[j].time_period == time_period[0])
            {
                exist = 1;//return myArray[i]; // Return as soon as the object is found
                break;
            }
        }

        if(exist){
            var array = main_ius[i].split('|');
            tempData.push({name:array[1]+','+array[2]+', '+area[m],y:Number(data[j].value),value:Number(data[j].value)});
        }
      }
    }
           
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};

  }

  public pieCase25<T>(objData: any){
    console.log(objData, 'xcbnb cvb')
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }
    var split_iu = ius[0].split("|");
    let unit = split_iu[1];
    let tempData = [];    
    var main_iu = [];
    var main_ius = [];

    for(let i=0; i<ius.length; i++){
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if(main_iu.indexOf(ind+'|'+unit) == -1){
        main_iu.push(ind+'|'+unit);
      }
      if(main_ius.indexOf(ius[i]) == -1){
        main_ius.push(ius[i]);
      }
    }
       
    for(var i=0; i<main_ius.length; i++) {

      for(let m=0; m<area.length; m++){              
          let exist = 0;
          for (var j = 0, len = data.length; j < len; j++) 
          {

              if (data[j].area_name == area[m]
                      && data[j].ius == main_ius[i]
                      && data[j].time_period == time_period[0])
              {
                  exist = 1;//return myArray[i]; // Return as soon as the object is found
                  break;
              }
          }

          if(exist){
              var array = main_ius[i].split('|');
              tempData.push({name:array[0]+','+array[2]+', '+area[m],y:Number(data[j].value),value:Number(data[j].value)});
          }
      }
    }
           
    this.seriesData = tempData;
    console.log(this.seriesData, 'ncbncv')
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase26<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }  
        
    var split_iu = ius[0].split("|");        
    let unit = split_iu[1];
    let tempData = [];    
    var main_iu = [];
    var main_ius = [];

    for(let i=0; i<ius.length; i++){
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if(main_iu.indexOf(ind+'|'+unit) == -1){
        main_iu.push(ind+'|'+unit);
      }
      if(main_ius.indexOf(ius[i]) == -1){
        main_ius.push(ius[i]);
      }
    }
       
    for(var i=0; i<main_ius.length; i++) {
      for(let m=0; m<area.length; m++){
        for(let k=0; k<time_period.length; k++){
          let exist = 0;
          for (var j = 0, len = data.length; j < len; j++){
            if (data[j].area_name == area[m] && data[j].ius == main_ius[i] && data[j].time_period == time_period[k]){
              exist = 1;//return myArray[i]; // Return as soon as the object is found
              break;
            }
          }
          if(exist){
            var array = main_ius[i].split('|');
            tempData.push({name:array[0]+','+array[2]+', '+area[m]+', '+time_period[k],y:Number(data[j].value),value:Number(data[j].value)});
          }
        }
      }
    }
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase27<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }  

    var split_iu = ius[0].split("|");    
    let subgroup = split_iu[2];
    let tempData = [];    
    var main_iu = [];
    var main_ius = [];

    for(let i=0; i<ius.length; i++){
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if(main_iu.indexOf(ind+'|'+unit) == -1){
        main_iu.push(ind+'|'+unit);
      }
      if(main_ius.indexOf(ius[i]) == -1){
        main_ius.push(ius[i]);
      }
    }
       
    for(var i=0; i<main_ius.length; i++) {            
      for(let k=0; k<time_period.length; k++){
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++){
          if (data[j].area_name == area[0] && data[j].ius == main_ius[i] && data[j].time_period == time_period[k]){
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if(exist){
          var array = main_ius[i].split('|');
          tempData.push({name:array[0]+','+array[1]+', '+time_period[k],y:Number(data[j].value),value:Number(data[j].value)});
        }
      }
    }           
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase28<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }
    var split_iu = ius[0].split("|");    
    let subgroup = split_iu[2];    
    let tempData = [];    
    var main_iu = [];
    var main_ius = [];
    for(let i=0; i<ius.length; i++){
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if(main_iu.indexOf(ind+'|'+unit) == -1){
        main_iu.push(ind+'|'+unit);
      }
      if(main_ius.indexOf(ius[i]) == -1){
        main_ius.push(ius[i]);
      }
    }
       
    for(var i=0; i<main_ius.length; i++) {
      for(let k=0; k<time_period.length; k++){
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++){
          if (data[j].area_name == area[0] && data[j].ius == main_ius[i] && data[j].time_period == time_period[k]){
              exist = 1;//return myArray[i]; // Return as soon as the object is found
              break;
          }
        }
        if(exist){
          var array = main_ius[i].split('|');
          tempData.push({name:array[0]+','+array[1]+', '+time_period[k],y:Number(data[j].value),value:Number(data[j].value)});
        }
      }
    }           
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase29<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }
    var split_iu = ius[0].split("|");    
    let subgroup = split_iu[2];
    let tempData = [];    
    var main_iu = [];
    var main_ius = [];
    for(let i=0; i<ius.length; i++){
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if(main_iu.indexOf(ind+'|'+unit) == -1){
        main_iu.push(ind+'|'+unit);
      }
      if(main_ius.indexOf(ius[i]) == -1){
        main_ius.push(ius[i]);
      }
    }
       
    for(var i=0; i<main_ius.length; i++) {
      for(let m=0; m<area.length; m++){
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++){
          if (data[j].area_name == area[m] && data[j].ius == main_ius[i] && data[j].time_period == time_period[0]){
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if(exist){
          var array = main_ius[i].split('|');
          tempData.push({name:array[0]+','+array[1]+', '+area[m],y:Number(data[j].value),value:Number(data[j].value)});
        }
      }
    }           
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase30<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }        
    var split_iu = ius[0].split("|");    
    let unit = split_iu[1];    
    let tempData = [];    
    var main_iu = [];
    var main_ius = [];
    for(let i=0; i<ius.length; i++){
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if(main_iu.indexOf(ind+'|'+unit) == -1){
        main_iu.push(ind+'|'+unit);
      }
      if(main_ius.indexOf(ius[i]) == -1){
        main_ius.push(ius[i]);
      }
    }
       
    for(var i=0; i<main_ius.length; i++){            
      let exist = 0;
      for (var j = 0, len = data.length; j < len; j++){
        if (data[j].area_name == area[0] && data[j].ius == main_ius[i] && data[j].time_period == time_period[0]){
          exist = 1;//return myArray[i]; // Return as soon as the object is found
          break;
        }
      }
      if(exist){
        var array = main_ius[i].split('|');
        tempData.push({name:array[0]+','+array[1]+','+array[2],y:Number(data[j].value),value:Number(data[j].value)});
      }
    }       
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase31<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }
    let tempData = [];    
    var main_iu = [];
    var main_ius = [];
    for(let i=0; i<ius.length; i++){
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if(main_iu.indexOf(ind+'|'+unit) == -1){
        main_iu.push(ind+'|'+unit);
      }
      if(main_ius.indexOf(ius[i]) == -1){
        main_ius.push(ius[i]);
      }
    }      
    for(var i=0; i<main_ius.length; i++) {
      for(var z=0;z<area.length;z++){
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++){
          if (data[j].area_name == area[z] && data[j].ius == main_ius[i] && data[j].time_period == time_period[0]){
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if(exist){
            var array = main_ius[i].split('|');
            tempData.push({name:array[0]+','+array[1]+','+array[2]+', '+area[z],y:Number(data[j].value),value:Number(data[j].value)});
        }
      }
    }           
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }

  public pieCase32<T>(objData: any){
    this.seriesData = [];
    let time_period = objData['time_period'];
    let area = objData['area'];
    let data = objData['data'];
    let ius = objData['ius'];

    this.plotOptions ={
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
        }
      }
    }
    let tempData = [];    
    var main_iu = [];
    var main_ius = [];
    for(let i=0; i<ius.length; i++){
      let ius_full = ius[i].split('|');
      let ind = ius_full[0];
      let unit = ius_full[1];
      let subgroup = ius_full[2];
      if(main_iu.indexOf(ind+'|'+unit) == -1){
        main_iu.push(ind+'|'+unit);
      }
      if(main_ius.indexOf(ius[i]) == -1){
        main_ius.push(ius[i]);
      }
    }       
    for(var i=0; i<main_ius.length; i++) {            
      for(let k=0; k<time_period.length; k++){
        let exist = 0;
        for (var j = 0, len = data.length; j < len; j++){
          if (data[j].area_name == area[0] && data[j].ius == main_ius[i] && data[j].time_period == time_period[k]){
            exist = 1;//return myArray[i]; // Return as soon as the object is found
            break;
          }
        }
        if(exist){
          var array = main_ius[i].split('|');
          tempData.push({name:array[0]+','+array[1]+','+array[2]+', '+time_period[k],y:Number(data[j].value),value:Number(data[j].value)});
        }
      }
    }           
    this.seriesData = tempData;
    return { series: this.seriesData, plotOptions: this.plotOptions};
  }
}
