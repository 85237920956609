import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../services/header.service';
@Component({
  selector: 'app-coverage',
  templateUrl: './userhome.component.html',
  styleUrls: ['./userhome.component.css']
})
export class UserhomeComponent implements OnInit {

  head_logo: string = 'assets/lib/images/svg/header-menu/area-dashboard.svg';
  constructor(private headerService: HeaderService) { 
  }
  
  ngOnInit() {  	
    this.headerService.setTitle('Map View');	
    this.headerService.setLogo(this.head_logo);  
  }
}
