import { Component, OnInit ,Input} from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-mr-page1',
  templateUrl: './mr-page1.component.html',
  styleUrls: ['./mr-page1.component.css']
})
export class MrPage1Component implements OnInit {
  @Input() timePeriod: any;
  constructor(private commonService: CommonService,) { }

  ngOnInit() {
  }

  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodFullChar(timePeriod);
  }
  getFinancialYear(timePeriod){
    return this.commonService.getFinancialYear(timePeriod);
  }
  getYear(timePeriod) {
    return this.commonService.getFinancialFirstYear(timePeriod);
  }
}
