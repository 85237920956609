import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { HeaderService } from "src/app/services/header.service";

import { _ } from "underscore";

import { Subscription } from "rxjs";
import { Configuration } from "../../../../app.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-graph-page1",
  templateUrl: "./graph-page1.component.html",
  styleUrls: ["./graph-page1.component.css"],
})
export class GraphPage1Component implements OnInit {
  @Input() timePeriod: any;
  subscription: Subscription;
  private url: any;

  public penta = [];
  public fic = [];
  public Hep_B = [];
  public mapLevel = 1;
  public source = 1;
  public mapRecentTimePeriod = [];
  areaID = "IND";
  area_name = "IND";
  // timePeriod: any;
  value: [];
  Hep_B_value: [];
  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private configuration: Configuration,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.url = configuration.server;

    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "header":
          this.source = 1;
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.getTable();
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {}

  ngOnChanges() {
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getTable();
  }
  getFormatedTimeperiod(timePeriod) {
    return this.commonService.covertTimePeriodFullChar(timePeriod);
  }
  getYear(timePeriod) {
    return this.commonService.getFinancialFirstYear(timePeriod);
  }
  getFinancialYear(timePeriod) {
    return this.commonService.getFinancialYear(timePeriod);
  }
  getRoundValue(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  getPerValue(numerator, denominator) {
    var value = this.getRoundValue((numerator / denominator) * 100, 1);

    if (value) {
      return value;
    }

    return 0;
  }

  getTable() {
    let that = this;

    this.dataService
      .getAll("api/page10/" + this.timePeriod + "/" + this.areaID + "/")
      .subscribe((response: any) => {
        that.penta = response.data;
        this.mapRecentTimePeriod = response.time_period;
        that.area_name = that.penta[0].area_name;

        let fic = [];
        let Hep_B = [];

        that.penta.forEach((row) => {
          fic.push([
            row.area_name,
            that.getPerValue(row.Fic_num, row.Fic_dem),
            row.area_code, 'Full Immunization Coverage'
          ]);
          Hep_B.push([
            row.area_name,
            that.getPerValue(row.Hep_B_num, row.Hep_B_dem),
            row.area_code, 'Hep-B Birth Dose'
          ]);
        });
        this.fic = fic;
        this.Hep_B = Hep_B;

        for (let i = 0; i < this.fic.length; i++) {
          let val = this.fic[0];
          this.value = val[1];
        }

        for (let i = 0; i < this.Hep_B.length; i++) {
          let val = this.Hep_B[0];
          this.Hep_B_value = val[1];
        }

        // if (!that.timePeriod || that.timePeriod == "mrd") {
        //   that.timePeriod = this.mapRecentTimePeriod[0].time_period;
        // }
      });
  }

  getObjectKey(object) {
    if (object) {
      return Object.keys(object);
    }

    return [];
  }
  ngAfterViewInit(): void {}
}
