import { Component, OnInit, Input } from "@angular/core";
import { HeaderService } from "src/app/services/header.service";
import { _ } from "underscore";

import { Subscription } from "rxjs";
import { Configuration } from "../../../../app.constant";
import { ApiService } from "src/app/services/api.service";
import { SharedataService } from "src/app/services/sharedata.service";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-graph-page7",
  templateUrl: "./graph-page7.component.html",
  styleUrls: ["./graph-page7.component.css"],
})
export class GraphPage7Component implements OnInit {
  @Input() timePeriod: any;
  subscription: Subscription;
  private url: any;

  public penta = [];
  public DPT1 = [];
  public DPT2 = [];
  public mr2 = [];
  public JE2 = [];
  public mapLevel = 1;
  public source = 1;
  areaID = "IND";
  area_name = "IND";
  // timePeriod: any;
  mr2_value: [];
  JE2_value: [];

  DPT1_value: [];
  DPT2_value: [];
  loading=true;
  constructor(
    private headerService: HeaderService,
    private dataService: ApiService,
    private configuration: Configuration,
    private commonService: CommonService,
    private shareData: SharedataService
  ) {
    this.url = configuration.server;

    this.subscription = this.shareData.getData().subscribe((message) => {
      switch (message.data.ctype) {
        case "header":
          this.source = 1;
          this.areaID = message.data.data.code;
          this.mapLevel = message.data.data.level;
          this.getTable();
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {}
  ngOnChanges() {
    let userAreaDetail = JSON.parse(localStorage.getItem("area_details"));
    this.headerService.setLoggedUserAreaName(userAreaDetail.area_name);
    this.areaID = this.commonService.getAreaID();
    this.getTable();
  }
  getRoundValue(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  getPerValue(numerator, denominator) {
    var value = this.getRoundValue((numerator / denominator) * 100, 1);

    if (value) {
      return value;
    }

    return 0;
  }

  getTable() {
    let that = this;

    this.dataService
      .getAll("api/getHmisgraph7/" + this.timePeriod + "/" + this.areaID + "/")
      .subscribe((response: any) => {
        this.loading=false;
        that.penta = response.data;

        that.area_name = that.penta[0].area_name;

        let DPT1 = [];
        let DPT2 = [];
        let mr2 = [];
        let JE2 = [];

        that.penta.forEach((row) => {

          mr2.push([
            row.area_name,
            that.getPerValue(row.mr2_num, row.mr2_dem),
            row.area_code,'MR 2nd Dose'
          ]);

          JE2.push([
            row.area_name,
            that.getPerValue(row.JE2_num, row.JE2_dem),
            row.area_code,'JE 2nd Dose'
          ]);
        });
       
        this.mr2 = mr2;
        this.JE2 = JE2;

        for (let i = 0; i < this.mr2.length; i++) {
          let val = this.mr2[0];
          this.mr2_value = val[1];
        }
        for (let i = 0; i < this.JE2.length; i++) {
          let val = this.JE2[0];
          this.JE2_value = val[1];
        }




      //     // DPT1.push([
      //     //   row.area_name,
      //     //   that.getPerValue(row.DPT1_num, row.DPT1_dem),
      //     //   row.area_code,
      //     // ]);
      //     // DPT2.push([
      //     //   row.area_name,
      //     //   that.getPerValue(row.DPT2_num, row.DPT2_dem),
      //     //   row.area_code,
      //     // ]);
      //   });
      //   // this.DPT1 = DPT1;
      //   // this.DPT2 = DPT2;

      //   for (let i = 0; i < this.DPT1.length; i++) {
      //     let val = this.DPT1[0];
      //     this.DPT1_value = val[1];
      //   }

      //   for (let i = 0; i < this.DPT2.length; i++) {
      //     let val = this.DPT2[0];
      //     this.DPT2_value = val[1];
      //   }
      });
      
  }

  getObjectKey(object) {
    if (object) {
      return Object.keys(object);
    }

    return [];
  }
  ngAfterViewInit(): void {}
}
